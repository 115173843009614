import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ExpandableListCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: props.showOpen
        };

        this.toggleSection = this.toggleSection.bind(this);
    }

    toggleSection() {
        const { isExpanded } = this.state;
        const { toggleTooltip, tooltipKey } = this.props;
        this.setState({ isExpanded: !isExpanded }, () => {
            if(toggleTooltip) {
                toggleTooltip(tooltipKey);
            }
        });
    }

    render() {
        const { description, title, children, classes, descriptionInHeader } = this.props;
        const { isExpanded } = this.state;

        const descriptionClasses = `description ${isExpanded ? 'active' : ''}`;
        return (
            <div className={`accordion-content__section ${classes}`} onClick={this.toggleSection}>
                <header>
                    <div className="accordion-content__header-title">
                        <p className="title">{title}</p>
                        <i className={isExpanded ? 'icon-up-open' : 'icon-down-open'} />
                    </div>
                    {descriptionInHeader ? <p className="section-description">{description}</p> : ''}
                </header>
                <div className={descriptionClasses}>
                    {descriptionInHeader ? '' : <p className="section-description">{description}</p>}
                    {children}
                </div>
            </div>
        );
    }
}

ExpandableListCell.propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

ExpandableListCell.defaultProps = {
    description: '',
    title: '',
    children: null,
    classes: ''
};

const Accordion = ({ title, items, classes, descriptionInHeader, toggleTooltip, showOpen }) => (
    <div className={`accordion-container ${classes}`}>
        {title !== '' && <h3 className="accordion-title">{title}</h3>}
        <div className="accordion-content">
            {items.map(({ description, title, children = null, classes: itemClasses = '' }) => (
                <ExpandableListCell
                    classes={itemClasses}
                    description={description}
                    descriptionInHeader={descriptionInHeader}
                    key={title.replace(' ', '-')}
                    showOpen={showOpen}
                    title={title}
                    toggleTooltip={toggleTooltip}
                    tooltipKey={title.replace(' ', '-')}
                >
                    {children}
                </ExpandableListCell>
            ))}
        </div>
    </div>
);

Accordion.propTypes = {
    classes: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    title: PropTypes.string,
    descriptionInHeader: PropTypes.bool,
    showOpen: PropTypes.bool
};

Accordion.defaultProps = {
    classes: '',
    items: [],
    title: '',
    descriptionInHeader: false,
    showOpen: false
};

export default Accordion;
