import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class Grid extends Component {
    constructor(props) {
        super(props);

        const {rows, value} = this.props;

        const selected = {};
        for(const x in rows) {
            selected[rows[x]] = value[rows[x]] ? value[rows[x]] : null;
        }

        this.state = {
            selected: selected,
        };

        this.handelOnChange = this.handelOnChange.bind(this);
    }

    handelOnChange(e) {
        const {id, onChange} = this.props;
        const { selected } = this.state;

        selected[e.target.name] = e.target.value;

        this.setState({selected: selected});
        onChange(id, selected);
    }

    renderTableHeaders() {
        const { columns, id } = this.props;
        return (
            <thead>
                <tr>
                    <th style={{width: `calc(100%/${columns.length + 1})`}}>&nbsp;</th>
                    {columns.map((thisCol, index) => <th key={`${id}_${index}`} style={{width: `calc(100%/${columns.length + 1})`}}>{thisCol}</th>)}
                </tr>
            </thead>
        );
    }

    renderTableBody() {
        const { rows, columns, id } = this.props;
        const { selected } = this.state;

        return (
            <tbody>
                {
                    rows.map((thisRow, rowIndex) => {
                        return (
                            <tr key={`${id}tr_${rowIndex}`} className="questionnaire-grid__row">
                                <td className="questionnaire-grid__row-label" style={{width: `calc(100%/${columns.length + 1})`}}>{thisRow}</td>
                                {columns.map((thisCol, colIndex) => {
                                    return (
                                        <td
                                            className="questionnaire-grid__cell"
                                            key={`${id}_${rowIndex}_${colIndex}`}
                                            onClick={() => this.handelOnChange({target: {name: thisRow, value: thisCol}})}
                                            style={{width: `calc(100%/${columns.length + 1})`}}
                                        >
                                            <input
                                                checked={selected[thisRow] === thisCol}
                                                id={thisCol}
                                                name={thisRow}
                                                onChange={(e) => this.handelOnChange(e)}
                                                type="radio"
                                                value={thisCol}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    render() {
        return (
            <div>
                <table className="questionnaire-grid">
                    {this.renderTableHeaders()}
                    {this.renderTableBody()}
                </table>
            </div>
        );
    }
}

Grid.propTypes = {
    id: PropTypes.number,
    rows: PropTypes.array,
    columns: PropTypes.array
};