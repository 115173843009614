import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import config from '../../config';

import { ProgressSteps } from '../common/progressSteps';
import { latestOrderStatus, orderToProgressSteps, ORDER_STATUS } from './utils';
import OrderCancelModal from './orderCancelModal';

import { getOrders } from '../../services/you/api';
import { updateOrders } from '../../services/you/actions';
import InfoModal from './infoModal';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

const { refundUrl } = config;

const OrderStatus = ({ order, dispatch }) => {
  const steps = orderToProgressSteps(order);
  const currentStep = latestOrderStatus(steps);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const openCancelModal = () => setIsCancelModalOpen(true);
  const closeCancelModal = () => setIsCancelModalOpen(false);

  const openInfoModal = useCallback(() => {
    setIsInfoModalOpen(true);
  }, []);
  const closeInfoModal = useCallback(() => {
    setIsInfoModalOpen(false);
  }, []);

  const isCanceled = order.refunded_at ? 'cancelled' : '';
  const isRefundAfterDispatch = order.can_cancel && order.cancel_message === 'refund_14_days_grace_return';

  const isOrderEditable = currentStep.name === ORDER_STATUS.Placed && order.can_modify;
  const isOrderTrackable = currentStep.name === ORDER_STATUS.Dispatched && order.tracking_url;
  const isOrderCancellable = order.can_cancel && !['cancel_not_allowed', 'refund_14_days_passed'].includes(order.cancel_message);
  const isOrderReturnTrackable = Boolean(order.zigzagUrl);

  function onModalClose(isOrderCancelled) {
    closeCancelModal();
    if (isOrderCancelled) {
      getOrders().then(orders => dispatch(updateOrders(orders)));
    }
  }

  if (order.is_bundle_parent) {
    return (
      <div className="order-status__bundle-parent">
        {order.items[0].title}
        {currentStep.name === ORDER_STATUS.Placed && order.can_cancel && (
          <div>
            <button className="order-status__bundle-parent cancel btn btn--full-width mt-10" onClick={openCancelModal}>
              <span>Cancel order</span>
              <span className="arrow right" />
            </button>
          </div>
        )}
        <Modal open={isCancelModalOpen} onClose={closeCancelModal} center classNames={{ modal: 'custom-modal' }}>
          <OrderCancelModal orderId={order.id} onClose={onModalClose} message={order.cancel_message} />
        </Modal>
      </div>
    );
  }

  return (
    <div className={`order-status__card ${isCanceled}`}>
      <div>
        {order.items.map(({ title, quantity }) => (
          <React.Fragment key={title}>
            <div className="text-bold">
              <label>Item:&nbsp;</label>
              <span>{title}</span>
            </div>
            <div className="text-bold">
              <label>Order date:&nbsp;</label>
              <span>{moment(order.created_at).format('DD MMM YYYY')}</span>
            </div>
            <div className="text-bold">
              <label>QTY:&nbsp;</label>
              <span>{quantity}</span>
            </div>
          </React.Fragment>
        ))}
        <div className="text-bold text-large mt-10">
          <label>Order ID:&nbsp;</label>
          <span>{order.order_number}</span>
        </div>
        {!isCanceled && order.ttr_users.length > 0 && (
          <>
            {order.ttr_users.map((item, index) => (
              <div key={index} className="text-bold text-large mt-10">
                <label>
                  {item.first_name} {item.last_name} passenger locator booking reference number:&nbsp;
                </label>
                <span>{item.booking_reference ? item.booking_reference : item.order_number}</span>
              </div>
            ))}
            <div className="order-status__caption mt-10">
              This passenger locator reference number is unique to each person taking a test to release. You must enter this on the
              passenger locator form corresponding to each person and again when registering the test once you received it.
            </div>
          </>
        )}
        {isCanceled && <div className="cancellation-text">Cancellation requested</div>}
      </div>
      {!isCanceled && (
        <>
          <ProgressSteps steps={steps} />
          <div>
            {isOrderEditable && (
              <Link to={`/dashboard/orders/${order.id}/edit`} className="btn btn--white btn--full-width mt-10">
                Edit order details
              </Link>
            )}
            {isOrderReturnTrackable && (
              <a href={order.zigzagUrl} target="_blank" className="btn btn--white btn--full-width mt-10" rel="noreferrer">
                Track return
              </a>
            )}
            {!cachedPartnerTheming() && (
              <p className="refund">
                If your test kit is faulty or incorrect, please{' '}
                <a href={`${refundUrl}?order_id=${order.order_number}`}>click here to apply for a replacement</a>.
              </p>
            )}
            {isOrderTrackable && (
              <a href={order.tracking_url} target="_blank" className="btn btn--white btn--full-width mt-10" rel="noreferrer">
                Track Shipment
              </a>
            )}
          </div>
          <NextActions actions={order.info}></NextActions>
        </>
      )}
      <Modal open={isCancelModalOpen} onClose={closeCancelModal} center classNames={{ modal: 'custom-modal' }}>
        <OrderCancelModal
          orderId={order.id}
          onClose={onModalClose}
          message={order.cancel_message}
          openInfo={isRefundAfterDispatch ? openInfoModal : null}
        />
      </Modal>
      <Modal open={isInfoModalOpen} onClose={closeInfoModal} center classNames={{ modal: 'custom-modal' }}>
        <InfoModal onClose={closeInfoModal} message={order.cancel_message} orderNumber={order.order_number} />
      </Modal>
    </div>
  );
};

function NextActions({ actions }) {
  if (!actions) return null;

  return (
    <div className="order-status__next-actions">
      <label>What's next</label>
      <ul>
        {actions.map((nextAction, i) => (
          <li key={i}>{nextAction}</li>
        ))}
      </ul>
    </div>
  );
}

export default connect()(OrderStatus);
export { OrderStatus as __TestOrderStatus };
