import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';
import utils from '../../helpers/utils';
import {StripeProvider, injectStripe, Elements, CardNumberElement, CardExpiryElement, 
        CardCVCElement} from 'react-stripe-elements';

export default class AccountUpdateBilling extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stripe: null,
            disableSubmitButton: false,
            submitErrorMessage: false,
            formSuccess: false,
        };

        this.handeFormSubmit = this.handeFormSubmit.bind(this);
        this.handleCardFieldChange = this.handleCardFieldChange.bind(this);
    }

    componentDidMount() {
        //Stripe is loaded aync 
        if(window.Stripe) {
            this.setState({stripe: window.Stripe(config.app.stripe_key)});
        }
        else {
            utils.loadStripeElement().onload = () => {
                this.setState({
                    stripe: window.Stripe(config.app.stripe_key),
                });
            };
        }
    }

    componentWillUnmount() {
        utils.removeStripeElement();
    }

    //for the stripe card elements
    handleCardFieldChange(element) {
        this.setState({submitErrorMessage: false});
        //console.log(element)
    }

    handeFormSubmit(e, stripe) {
        e.preventDefault();
        this.setState({submitErrorMessage: false, disableSubmitButton: true});

        //create the stripe token
        stripe.createPaymentMethod('card').then(result => {
            if(result.paymentMethod) {
                //Call API to complete
                axios.post('user/billing', {payment_method: result.paymentMethod.id})
                .then((response) => {
                    this.setState({formSuccess: true});

                    utils.track('Updated Billing Details');
                })
                .catch((error) => {
                    this.setState({
                        disableSubmitButton: false,
                        submitErrorMessage: utils.getFirstApiError(error, 'Error updating card details.')
                    });
                });
            }
            else {
                this.setState({
                    disableSubmitButton: false,
                    submitErrorMessage: result.error.message
                });
            }
        });
    }



    render() {
        return (
            <div className="layout-2col__box">
                <h2 className="box-title">Update Billing Details</h2>

                    {this.state.formSuccess ? (
                        <div>
                            <p>Card details updated.</p>
                            <p><Link to="/dashboard/account" className="btn">Continue<i className="icon-right-open"/></Link></p>
                        </div>
                    ) : (
                        <StripeProvider stripe={this.state.stripe}>
                            <Elements>
                                <PaymentForm onSubmit={this.handeFormSubmit} onCardFieldChange={this.handleCardFieldChange}
                                    disableSubmitButton={this.state.disableSubmitButton}
                                    submitErrorMessage={this.state.submitErrorMessage}
                                />
                            </Elements>
                        </StripeProvider>
                    )}
            </div>
        );
    }
}

class _PaymentForm extends Component {
    render() {
        const stripeFieldStyle = {
            base: {
                'fontFamily': 'Roboto',
                'fontSize': '15px',
                'color': '#1B1D26',
                '::placeholder': {
                    color: '#a3a4a8',
                }
            }
        };

        const {stripe, onSubmit, onCardFieldChange, disableSubmitButton, submitErrorMessage} = this.props;

        return (
            <form onSubmit={(e) => onSubmit(e, stripe)} className="form checkout-form">
                <div className="form__row form__row--no-error">
                    Accepted cards:
                    <img className="checkout-form__cards" src="/img/accepted-cards.jpeg" alt="Accepted cards: Visa, Mastercard and American Express"/>
                </div>

                <div className="form__row form__row--no-error">
                    <label className="form__label">
                      Card number <span className="form__required">*</span>
                      <CardNumberElement style={stripeFieldStyle} onReady={(el) => el.focus()} onChange={(el) => onCardFieldChange(el)} />
                    </label>
                    {config.app.env !== 'production' &&
                        <p>Test: 4242424242424242</p>
                    }
                </div>

                <div className="form__row form__row--2col form__row--no-error">
                    <div className="form__col2">
                        <label className="form__label">
                            Expiration date <span className="form__required">*</span>
                            <CardExpiryElement style={stripeFieldStyle} onChange={(el) => onCardFieldChange(el)} />
                        </label>
                    </div>

                    <div className="form__col2">
                        <label className="form__label">
                            CVC <span className="form__required">*</span>
                            <CardCVCElement style={stripeFieldStyle} onChange={(el) => onCardFieldChange(el)} />
                        </label>
                    </div>
                </div>

                {/* Doesnt seem to be a stripe element anymore..
                <div className="form__row form__row--2col form__row--no-error">
                    <div className="form__col2">
                        <label className="form__label">
                            Postal code <span className="form__required">*</span>
                            <PostalCodeElement style={stripeFieldStyle} onChange={(el) => onCardFieldChange(el)} />
                        </label>
                    </div>
                </div>
                */}

                <div className="form__row">
                    <button className="btn" disabled={disableSubmitButton}>Update<i className="icon-right-open"/></button>
                    <Link to="/dashboard/account/subscription" className="account-cancel-link">Cancel</Link>
                    {submitErrorMessage &&
                        <div className="form__error form__error--general">{submitErrorMessage}</div>
                    }
                </div>
            </form>
        );
    }
}

const PaymentForm = injectStripe(_PaymentForm);
