import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import withErrorBoundary from '../common/errorBoundary';
import axios from 'axios';
import update from 'immutability-helper';
import utils from '../helpers/utils';
import AccountOverview from './sections/overview';
import AccountSecurity from './sections/security';
import AccountProfile from './sections/profile';
import AccountSubscription from './sections/subscription';
import AccountUpdateBilling from './sections/updateBilling';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';
import { userDetailsSelector } from '../../services/you/selectors';
import { getUserDetails } from '../../services/you/actions';

import { compose } from 'redux';
import { connect } from 'react-redux';
import Loader from '../common/loader';

const SECTIONS = {
  ACCOUNT: 'account',
  SECURITY: 'security',
  PROFILE: 'profile',
  SUBSCRIPTION: 'subscription',
  UPDATEBILLING: 'updatebilling',
};

const LINKS_FOR_SECTIONS = {
  [SECTIONS.ACCOUNT]: {
    url: '/dashboard/account',
    label: 'Account overview',
    imageSource: '/img/dashboard/account/overview.png',
    shouldDisplay: true,
  },
  [SECTIONS.SECURITY]: {
    url: '/dashboard/account/security',
    label: 'Password',
    imageSource: '/img/dashboard/account/security.png',
    shouldDisplay: true,
  },
  [SECTIONS.SUBSCRIPTION]: {
    url: '/dashboard/account/subscription',
    label: 'Subscription',
    imageSource: '/img/dashboard/account/subscription.png',
    shouldDisplay: !cachedPartnerTheming(),
  },
};

const Select = props => {
  let section = props.match.params.section || SECTIONS.ACCOUNT;
  section = section === SECTIONS.UPDATEBILLING ? SECTIONS.SUBSCRIPTION : section === SECTIONS.PROFILE ? SECTIONS.ACCOUNT : section;
  const { imageSource, url: selectedUrl } = LINKS_FOR_SECTIONS[section];

  const handleChange = e => {
    if (!e) return;
    const { value } = e.target;
    props.history.push(value);
  };

  return (
    <div className="account-navigation__device-content">
      <span>
        <img src={imageSource} alt={imageSource} />
      </span>
      <select name="selectLink" onChange={handleChange} value={selectedUrl}>
        {Object.entries(LINKS_FOR_SECTIONS).map(([, sectionAssets]) => {
          const { url, label, shouldDisplay } = sectionAssets;

          return (
            shouldDisplay && (
              <option key={url} value={url}>
                {label}
              </option>
            )
          );
        })}
      </select>
      <i className="icon-down-open" />
    </div>
  );
};

const SelectComponent = withRouter(Select);

const getBaseFields = ({ user, test_user: testUser, subscription }) => {
  const { fname = '', lname = '' } = testUser || {};
  const fields = {
    email: user?.email || '',
    mobile_country: user?.mobile_country || '',
    mobile: user?.mobile || '',
    delivery_fname: subscription?.delivery_fname || '',
    delivery_lname: subscription?.delivery_lname || '',
    delivery_address1: subscription?.delivery_address1 || '',
    delivery_address2: subscription?.delivery_address2 || '',
    delivery_city: subscription?.delivery_city || '',
    delivery_postcode: subscription?.delivery_postcode || '',
    delivery_country: subscription?.delivery_country || '',
  };
  return fname || lname ? { fname, lname, ...fields } : fields;
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: getBaseFields({ user: props.user, test_user: props.test_user, subscription: props.subscription }),
      countrys: [],
      disableSubmitButton: false,
      submitError: false,
      successMessage: '',
      hasSetSubscription: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormValid = this.handleFormValid.bind(this);
  }

  componentDidMount() {
    axios.get('products/shiping_countrys').then(response => {
      this.setState({
        countrys: response.data.data,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const hasTestUserChanged = Boolean(this.props.test_user && !prevProps.test_user);
    const hasUserChanged = Boolean(this.props.user && !prevProps.user);
    const hasSubscriptionChanged = Boolean(this.props.subscription && !prevProps.subscription);
    if (hasTestUserChanged || hasUserChanged || hasSubscriptionChanged) {
      this.setState({
        hasSetSubscription: Boolean(this.props.subscription),
        fields: getBaseFields(this.props),
      });
    }
  }

  handleInputChange(field, value = '') {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
    this.setState({ submitError: false });
  }

  async handleFormValid() {
    const { fields } = this.state;
    this.setState({ submitError: false, successMessage: '', disableSubmitButton: true });
    //Call API to complete

    const mobile = fields.mobile.replace(`+${fields.mobile_country}`, '');

    try {
      await axios.post('user', { ...fields, mobile });
      utils.track('Updated Profile');

      this.props.getUserDetails();

      this.setState({ disableSubmitButton: false, successMessage: 'Profile updated' }, () => {
        window.location = '/dashboard/account';
      });
    } catch (error) {
      this.setState({
        disableSubmitButton: false,
        submitError: utils.getFirstApiError(error, 'Validation error'),
      });
    }
  }

  getHtmlContent() {
    const { countrys, disableSubmitButton, fields, submitError, successMessage, hasSetSubscription } = this.state;
    if (this.props.section === SECTIONS.SECURITY) {
      return <AccountSecurity />;
    } else if (this.props.section === SECTIONS.PROFILE) {
      return this.props.user ? (
        <AccountProfile
          handleFormValid={this.handleFormValid}
          handleInputChange={this.handleInputChange}
          user={this.props.user}
          testUser={this.props.test_user}
          fields={fields}
          countrys={countrys}
          disableSubmitButton={disableSubmitButton}
          submitError={submitError}
          successMessage={successMessage}
          hasSetSubscription={hasSetSubscription}
        />
      ) : (
        <Loader />
      );
    } else if (this.props.section === SECTIONS.SUBSCRIPTION) {
      return <AccountSubscription subscription={this.props.subscription} openChat={this.props.openChat} />;
    } else if (this.props.section === SECTIONS.UPDATEBILLING) {
      return <AccountUpdateBilling subscription={this.props.subscription} />;
    } else {
      return this.props.user ? (
        <AccountOverview subscription={this.props.subscription} user={this.props.user} testUser={this.props.test_user} />
      ) : (
        <Loader />
      );
    }
  }

  render() {
    const { section } = this.props;

    return (
      <section className="dashboard-section content-wrapper-animate">
        <div className="dashboard-section__container">
          <h1 className="dashboard-section__title">Your Account</h1>

          <SelectComponent />
          <div className="layout-2col account-navigation__wrapper">
            <div className="layout-2col__main">{this.getHtmlContent()}</div>

            <div className="layout-2col__sidebar">
              <div className="account-navigation__content">
                {Object.entries(LINKS_FOR_SECTIONS).map(([sectionKey, sectionAssets]) => {
                  const { url, imageSource, label, shouldDisplay } = sectionAssets;
                  const isActive =
                    SECTIONS.UPDATEBILLING === section
                      ? SECTIONS.SUBSCRIPTION === sectionKey
                      : SECTIONS.PROFILE === section
                      ? SECTIONS.ACCOUNT === sectionKey
                      : section === sectionKey;

                  return (
                    shouldDisplay && (
                      <NavLink className={`account-navigation__content__link ${isActive ? 'active' : ''}`} exact={true} key={url} to={url}>
                        <div className="content">
                          <span>
                            <img src={imageSource} alt={label} />
                          </span>
                          <span>{label}</span>
                        </div>
                      </NavLink>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  ...userDetailsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getUserDetails: () => dispatch(getUserDetails()),
});

const enhance = compose(withErrorBoundary, connect(mapStateToProps, mapDispatchToProps), withRouter);
export default enhance(Account);
