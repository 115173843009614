import React, { Component } from 'react';
import axios from 'axios';

export default class Country extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countrys: {},
        };

        this.handelOnChange = this.handelOnChange.bind(this);
    }

    componentDidMount() {
        axios.get('products/shiping_countrys')
        .then(response => {
            this.setState({
                countrys: response.data.data
            });
        });
    }

    handelOnChange(e) {
        const value = e.target.value;
        const {id, onChange} = this.props;

        onChange(id, value);
    }

    render() {
        const {id, value, onChange} = this.props;
        const {countrys} = this.state;

        return (
            <div className="form__select-wrapper custom__select-wrapper">
                <select name={id} id={id} value={value} onChange={this.handelOnChange}>
                    <option key="" value="" />
                    {countrys.length && countrys.map((o, key) => o ? <option key={key} value={o.country_code}>{o.title}</option> : '')}
                </select>
                <i className="icon-down-open" />
            </div>
        );
    }
}