import { useEffect, useState } from 'react';
import config from '../../config';

export const BREAKPOINT_NAMES = {
  TINY_SMALL: 'tinySmall',
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(BREAKPOINT_NAMES.LARGE);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    if (windowSize.width > 0 && windowSize.width < config.breakpoints.tinySmall) {
      setBreakpoint(BREAKPOINT_NAMES.TINY_SMALL);
    } else if (windowSize.width < config.breakpoints.small) {
      setBreakpoint(BREAKPOINT_NAMES.TINY);
    } else if (windowSize.width >= config.breakpoints.small && windowSize.width < config.breakpoints.medium) {
      setBreakpoint(BREAKPOINT_NAMES.SMALL);
    } else if (windowSize.width >= config.breakpoints.medium && windowSize.width < config.breakpoints.large) {
      setBreakpoint(BREAKPOINT_NAMES.MEDIUM);
    } else {
      setBreakpoint(BREAKPOINT_NAMES.LARGE);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);

  return breakpoint;
};
