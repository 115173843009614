import React from 'react';
import { ConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';
import config from '../../config';

const getCookieDomain = (environment) => {
  if (environment === 'local') {
    return 'localhost';
  }
  else {
    const parsedUrl = new URL(window.location.href)
    return parsedUrl.hostname;
  }
};

const { segment_id, env } = config.app;

export default function() {

  // Need a valid segment id to render the consent manager
  if (!segment_id) {
    return null;
  }

  const bannerContent = (
    <span>
      We use cookies (and other similar technologies) to collect data to improve your experience on
      our site. By using our website, you’re agreeing to the collection of data as 
      described in our{' '}
      <a href="https://hurdle.bio/cookie-policy/" target="_blank" rel="noopener noreferrer">
        Cookie Policy
      </a>
      .
    </span>
  );
  const bannerSubContent = 'Customize preferences.';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing, ' +
    'experience, analyze site traffic, deliver personalized advertisements, and increase ' + 
    'the overall performance of our site.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing ' +
    ' to our Website Data Collection Policy.';

  return (
    <div className="cookie-bar">
      <ConsentManager
        writeKey={segment_id}
        cookieDomain={getCookieDomain(env)}
        shouldRequireConsent={inEU}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        bannerActionsBlock={true}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
      />
    </div>
  );
}