import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import utils from '../helpers/utils';
import Slider from 'react-slick';

export default class List extends Component {


    render() {
        const {results} = this.props;
        const carouselSettings = utils.defaultCarouselSettings();
        carouselSettings.infinite = false;

        if(!results || results.length < 1) {
            return (
                <div>
                    <p className="center">No results to show.</p>
                </div>
            );
        }

        /*
        let categoryHtml = results ? results.map((category, index) => {
            let className = category.category.slug===this.state.selectedCategory ? 'active' : '';
            return (<li key={index}><HashLink to={'#'+category.category.slug} className={className} 
                        scroll={el => utils.scrollToHash(el, 50)} onClick={(e) => this.handelSelectCategory(e, category.category.slug)}>{category.category.title}</HashLink></li>);
        }) : '';
        */


        const appsListHtml = results.length ? results.map((category, index) => {
            return(
                <div key={index} className="app-list">
                    {/*
                        <div className="app-list__title" id={category.category.slug}>{category.category.title}</div>
                    */}

                    <Slider {...carouselSettings}>
                        {category.items.map((app, appIndex) => {
                            let link = '#';
                            let linkText = 'Coming Soon';
                            if(!app.coming_soon) {
                                link = '/dashboard/results/'+app.slug;
                                linkText = 'View';
                            }

                            return (
                                <div key={appIndex} className="app-list-item">
                                    <Link to={link}>
                                        <div className="app-list-item__image-container">
                                            <img src={app.image_small_url} alt={app.title} className="app-list-item__image" />
                                            <div className={'app-list-item__image-overlay app-list-item__image-overlay--'+category.category.slug}/>
                                            <div className="app-list-item__title">{app.title}</div>

                                        </div>
                                    </Link>

                                    <div className="app-list-item__content-container">
                                        <div className="app-list-item__excerpt">{app.excerpt}</div>

                                        {link !== '#' ?
                                            <Link to={link} className="btn btn--inverse-pink btn--no-icon btn--disabled">{linkText}</Link>
                                        :
                                            <span className="app-list-item__coming-soon">{linkText}</span>
                                        }

                                    </div>
                                </div>
                            );
                        })}
                    </Slider>


                </div>
            );
        }) : '';

        return (
            <div>
                {appsListHtml}
            </div>
        );
    }
}