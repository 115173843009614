import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

const DropdownMenu = ({ title, deviceLinks, hideHeader, bottomLinks, onToggle }) => {
  const [shouldOpen, setShouldOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = e => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShouldOpen(false);
    }
  };

  const toggleMenu = () => {
    setShouldOpen(!shouldOpen);
  };

  useEffect(() => {
    onToggle && onToggle(shouldOpen);
  }, [shouldOpen]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <div className="dropdown-menu__container" onClick={toggleMenu} ref={menuRef}>
      <div>{title}</div>
      {shouldOpen && (
        <div className={`dropdown-menu__panel ${hideHeader ? 'menu-scroll-up' : ''}`}>
          <div id="dropdown-menu" className="dropdown-menu__content">
            {deviceLinks.map((item, index) => {
              if (item.isMobile || item.shouldDisplay === false) {
                return null;
              }

              if (item.to && item.to.startsWith('http')) {
                return (
                  <div className="dropdown-menu__item" key={`panel-${index}`}>
                    <NavLink to={item.to} onClick={item.onClick ? item.onClick : null}>
                      {item.title}
                    </NavLink>
                  </div>
                );
              }

              return (
                <div className="dropdown-menu__item" key={`panel-${index}`}>
                  <NavLink onClick={item.onClick ? item.onClick : null} to={item.to}>
                    {item.title}
                  </NavLink>
                </div>
              );
            })}
            <hr />
            {bottomLinks.map((item, index) => (
              <div className="dropdown-menu__item" key={`panel-${index}`}>
                <a href={item.href} onClick={item.onClick ? item.onClick : null}>
                  {item.title}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

DropdownMenu.defaultProps = {
  title: '',
  leftOffset: 0,
  deviceLinks: [],
  hideHeader: false,
  theme: undefined,
  onToggle: undefined,
};

DropdownMenu.propTypes = {
  deviceLinks: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  leftOffset: PropTypes.number,
  hideHeader: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default withTheme(withRouter(DropdownMenu));
