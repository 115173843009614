import { get } from '../methods';
import * as API_CONSTANTS from './constants';

const mapSuccess = (result) => {
    return result.data || {};
};

const mapError = (err) => {
    return {
        errorResponse: err.response,
        error: err
    };
};

const mapIndicator = (result, slug) => {
    const { data } = result;

    if(slug == 'chromosome-ageing') {
        return data;
    }

    const tests = [];
    tests.push(
        {status: 'current', date: data.current_test_date},
        {status: 'future', date: data.next_test_date}
    );

    return {
        heroSectionInfo: {
            epigeneticTests: tests,
            title: data.title,
            summaryText: data.summary_text,
            result: data.result,
            resultUnit: data.result_units,
            resultStatus: data.result_status,
            resultStatusText: data.result_status_text,
            analaysisText: data.analysis_text,
            currentTestDateText: data.current_test_date_text,
            resultIntroText: data.result_intro_text,
        },
        slug: data.slug,
        howWorks: data.how_works,
        ageOverTimeGraph: data.your_result_graph,
        healthyRange: data.healthly_range_graph,
        comparisonGraph: data.comparison_graph,
        resultMetaData: data.result_meta_data,
        heatMapData: data.world_map,
        affectingFactors: data.intervenables,
        relatedArticles: data.related_articles,
        moreInfo: data.more_info,
        funFacts: data.fun_facts,
        scientificSteps: data.scientific_steps,
        jsonSchema: data.json_schema && data.json_schema.items,
        uiSchema: data.ui_schema,
        comparisonGraphData: data.comparison_graph_new,
        positionsGraph: data.positions_graph,
        chromosomeGraph: data.chromosome_graph,
        recommendations: data.recommendations,
        latestTest: data.is_latest,
        currentTestDate: data.current_test_date,
        resultDelta: data.result_delta,
        previousTest: data.previous_test,
        diseaseRisk: data.disease_risk,
        healthyRanges: data.healthy_ranges,
        team: data.team,
        customComparisons: data.comparisons_custom,
        genes: data.genes,
        perceivedVsActual: data.percived_vs_atcual,
        whatAffecting: data.what_affecting,
        resultUnit: data.result_units,
    };
};

export const getIndicator = (params, slug) => {
    return get(`test_user/results/epigenetic/${slug}`, params)
        .then((results) => mapIndicator(results, slug))
        .catch(mapError);
};

export const getCompare = (section, id) => {
    let url = API_CONSTANTS.COMPARISON_URL;

    if(section && id) {
        url = `${url}/${section}/${id}`;
    }

    return get(url)
        .then(mapSuccess)
        .catch(mapError);
};

export const getTansposons = (slug) => {
    return get(`${API_CONSTANTS.TRANSPOSONS_URL}/${slug}`)
        .then(mapSuccess)
        .catch(mapError);
};

export const getYou = (testId) => {
    const params = testId ? {test_user_test: testId} : null;
    return get(API_CONSTANTS.YOU_URL, params)
        .then(mapSuccess)
        .catch(mapError);
};

export const getUserDetails = () => {
    return get(API_CONSTANTS.USER_DETAILS_URL)
        .then(mapSuccess)
        .catch(mapError);
};

export const getCovidResults = () => {
    return get('test_user/covid/results')
        .then(mapSuccess)
        .catch(mapError);
};

export const getOrders = () => {
    return get('user/orders')
        .then(mapSuccess)
        .catch(mapError);
};

export const downloadCovidCertificate = () => {
    return get('test_user/covid/:id/download_certificate')
        .then(mapSuccess)
        .catch(mapError);
};

export const getCovidResultByTestId = (testId) => {
    return get(`test_user/covid/${testId}`)
        .then(mapSuccess)
        .catch(mapError);
};
