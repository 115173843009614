import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import utils from '../../../helpers/utils';

import Datamap from '../../../lib/DataMap';
import ChromosomeGraph from '../../../common/chromosomeGraph';
import RelatedArticles from '../../../common/relatedArticles';
import Disclaimer from '../../../common/disclaimer';
import FunFact from '../../../common/funFact';
import Loader from '../../../common/loader';
import Accordion from '../../../common/accordion';
import HeroCTAButtons from '../../../common/heroCtaButtons';
import Question from '../../../common/question';

const heatMapOptions = {
  // countries don't listed in dataset will be painted with this color
  fills: { defaultFill: '#E9E9E9' },
  data: null,
  geographyConfig: {
    borderColor: 'white',
    highlightBorderWidth: 1,
    // don't change color on mouse hover
    highlightFillColor: geo => geo['fillColor'] || '#E9E9E9',
    // only change border
    highlightBorderColor: 'white',
    // show desired information in tooltip
    popupTemplate: function(geo, data) {
      // don't show tooltip if country isn't present in dataset
      if (!data) {
        return;
      }
      // tooltip content
      return ['<div className="hoverinfo">', '<span>', geo.properties.name, '</span>', ' ', data.numberOfThings, '%', '</div>'].join('');
    },
  },
};

const positionColourSchemes = {
  C: '#9BE6DF',
  G: '#5B7BFF',
  A: '#FF9EAF',
  T: '#FDA87F',
};

export default class GeneticTrait extends Component {
  constructor() {
    super();
    this.state = {
      traitInformation: null,
      whySectionInformation: null,
      funFacts: null,
      worldMapData: null,
      geneticVariants: [],
      geneticVariantsDescription: '',
      geneticVariantsTitle: '',
      chromosomeGraphData: null,
      chromosomeGraphTitle: '',
      chromosomeGraphDescription: '',
      scienceTitle: null,
      relatedArticles: null,
      isLoading: true,
      error: null,
      jsonSchema: null,
    };
  }

  componentDidMount() {
    axios
      .get(`test_user/results/genetic/${this.props.slug}`)
      .then(response => {
        const { data: geneticVariants = [], variants_text: geneticVariantsDescription = '', variants_title: geneticVariantsTitle = '' } =
          response.data.variants || {};

        const { data: chromosomeGraphData = null, text: chromosomeGraphDescription = '', title: chromosomeGraphTitle = '' } =
          response.data.chromosome_graph || {};
        this.setState({
          traitInformation: {
            title: response.data.title,
            iconUrl: response.data && response.data.icon_url,
            summary: response.data && response.data.summary_text,
            effectTitle: response.data.effect_title,
            userEffect: response.data.user_effect,
            disclaimerText: response.data.disclaimer_text,
          },
          whySectionInformation: {
            title: response.data.why_title,
            description: response.data.why_text,
          },
          funFacts: response.data.fun_facts,
          worldMapData: response.data.world_map,
          geneticVariants,
          geneticVariantsDescription,
          geneticVariantsTitle,
          chromosomeGraphData,
          chromosomeGraphDescription,
          chromosomeGraphTitle,
          scienceTitle: response.data.science_title,
          relatedArticles: response.data.related_articles,
          jsonSchema: response.data.json_schema && response.data.json_schema.items,
          isLoading: false,
        });
      })
      .catch(err => {
        this.setState(() => {
          throw err;
        });
      });
  }

  getHeatmapOptions() {
    const dataset = {};

    const options = heatMapOptions;
    options.data = dataset;

    return options;
  }

  renderTraitQuestions() {
    const { jsonSchema } = this.state;
    if (!jsonSchema) {
      return null;
    }
    return <Question jsonSchema={jsonSchema} section="GENETIC_TRAITS" />;
  }

  getGeneticTraitAboutSection() {
    const {
      geneticVariants,
      geneticVariantsDescription,
      geneticVariantsTitle,
      chromosomeGraphData,
      chromosomeGraphDescription,
      chromosomeGraphTitle,
      scienceTitle,
    } = this.state;
    let items = [];

    if (!(geneticVariants.length || (chromosomeGraphData && Object.keys(chromosomeGraphData).length))) {
      return null;
    }

    if (geneticVariants.length) {
      const children = (
        <div className="position-section-content">
          {geneticVariants.map((item, index) => {
            const genotypes = [];
            for (let genVar = 0; genVar < item.genotype.length; genVar++) {
              genotypes.push(item.genotype.substr(genVar, 1));
            }
            return (
              <div className="position-item" key={index}>
                <div className="position-information">
                  <h4>
                    {genotypes.map((genotype, index) => (
                      <span style={{ color: positionColourSchemes[genotype] }} key={index}>
                        {genotype}
                      </span>
                    ))}
                  </h4>
                  <p>
                    Position {index + 1}
                    <br />
                    {item.rs_id}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );

      items = [
        {
          description: geneticVariantsDescription,
          title: geneticVariantsTitle,
          key: geneticVariantsTitle.replace(' ', '-'),
          children,
          classes: 'genetic-variants',
        },
      ];
    }

    if (chromosomeGraphData && Object.keys(chromosomeGraphData).length) {
      const children = (
        <div className="section-graph">
          <ChromosomeGraph data={chromosomeGraphData} orientation="vertical" />
        </div>
      );

      items = [
        ...items,
        {
          description: chromosomeGraphDescription,
          title: chromosomeGraphTitle,
          classes: 'graph-section',
          children,
        },
      ];
    }

    return (
      <div className="genetic-trait__section-container">
        <Accordion classes="genetic-trait__accordion" items={items} title={scienceTitle} />
      </div>
    );
  }

  renderWorldMap() {
    const { worldMapData } = this.state;

    if (!worldMapData || !worldMapData.data || !worldMapData.data.length) {
      return null;
    }

    const heatmapOptions = this.getHeatmapOptions();

    return (
      <div className="genetic-trait__section-container">
        <div className="genetic-trait__information-section">
          <h3 className="section-title">{worldMapData.title}</h3>
          <p className="section-information">{worldMapData.text}</p>
        </div>
        <div className="genetic-trait__graph-section">
          <Datamap {...heatmapOptions} />
        </div>
      </div>
    );
  }

  render() {
    const { traitInformation, whySectionInformation, funFacts, worldMapData, relatedArticles, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    return (
      <Fragment>
        <div className="genetic-trait__container">
          <Helmet>
            <title>{traitInformation.title} - Hurdle</title>
          </Helmet>
          <HeroCTAButtons />

          {traitInformation ? (
            <div>
              <h1 className="genetic-trait__title">{traitInformation.title}</h1>
              {traitInformation.summary ? <p className="genetic-trait__information">{traitInformation.summary}</p> : ''}

              {traitInformation.iconUrl || traitInformation.effectTitle || traitInformation.userEffect ? (
                <div className="genetic-trait__trait-section">
                  {traitInformation.iconUrl ? (
                    <div className="genetic-trait__icon-section">
                      <img src={traitInformation.iconUrl} alt={traitInformation.title} />
                    </div>
                  ) : (
                    ''
                  )}
                  {traitInformation.effectTitle ? <p className="trait-description">{traitInformation.effectTitle}:</p> : ''}
                  {traitInformation.userEffect ? <p className="trait-effect">{traitInformation.userEffect}</p> : ''}
                </div>
              ) : (
                ''
              )}
              {traitInformation.disclaimerText && <p className="genetic-trait__tip-content">*{traitInformation.disclaimerText}</p>}
            </div>
          ) : (
            ''
          )}

          <p>
            <Link
              className="btn btn--yellow"
              to="/dashboard/improvements/team"
              onClick={() => utils.track('Talk to health team', { position: 'Overview Hero' })}
            >
              speak to someone
            </Link>
          </p>

          {whySectionInformation && whySectionInformation.description ? (
            <div className="genetic-trait__section-container">
              <div className="genetic-trait__information-section">
                <h3 className="section-title">{whySectionInformation.title}</h3>
                <p className="section-information">{whySectionInformation.description}</p>
              </div>
            </div>
          ) : (
            ''
          )}

          {this.renderTraitQuestions()}
          {this.renderWorldMap()}
          {funFacts && funFacts.data && funFacts.data.length ? (
            <div className="genetic-trait__fun-facts-container">
              <FunFact items={funFacts.data} title={funFacts.title} />
            </div>
          ) : (
            ''
          )}
          {this.getGeneticTraitAboutSection()}
          {relatedArticles && relatedArticles.length ? <RelatedArticles relatedArticles={relatedArticles} /> : ''}
        </div>
        <Disclaimer />
      </Fragment>
    );
  }
}

GeneticTrait.propTypes = {
  slug: PropTypes.string.isRequired,
};
