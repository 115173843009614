import { takeLatest, fork, all, put } from 'redux-saga/effects';

import * as actionTypes from './constants';
import * as api from './api';
import * as partnersApi from './partnersApi';
import * as actions from './actions';

function* fetchAffiliateBranding(action) {
  try {
    const brand = action.params;
    const response = yield api.getAffiliateBranding(brand);
    if (!response || !response.header || !response.section || !response.footer) {
      return;
    }
    yield put(actions.getSiteBrandingSuccess(response));
  } catch (ex) {
    yield put(actions.getSiteBrandingFailure(ex));
  }
}

export function* fetchPartnerBranding(action) {
  const { partnerCode, hideFooter, hideHeader } = action.params;
  try {
    const response = yield partnersApi.getPartnerBranding({ partnerCode, hideHeader, hideFooter });
    if (!response || response.error) {
      yield put(actions.getPartnerBrandingFailure(response));
      return;
    }
    yield put(actions.getPartnerBrandingSuccess(response));
  } catch (ex) {
    yield put(actions.getPartnerBrandingFailure(ex));
  }
}

function* fetchBrandingSaga() {
  yield takeLatest(actionTypes.FETCH_SITE_BRANDING_REQUEST, fetchAffiliateBranding);
}

function* fetchPartnerBrandingSaga() {
  yield takeLatest(actionTypes.FETCH_PARTNER_BRANDING_REQUEST, fetchPartnerBranding);
}

export default function*() {
  yield all([fork(fetchBrandingSaga), fork(fetchPartnerBrandingSaga)]);
}
