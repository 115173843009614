import React, { Component } from 'react';
import update from 'immutability-helper';
import utils from '../../helpers/utils';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ChangePasswordForm from './changePasswordForm';

export default class AccountSecurity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        current_password: '',
        password: '',
        confirm_password: '',
      },
      disableSubmitButton: false,
      submitError: false,
      successMessage: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormValid = this.handleFormValid.bind(this);
  }

  handleInputChange(field, value) {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
    this.setState({ submitError: false });
  }

  handleFormValid() {
    this.setState({ submitError: false, successMessage: '', disableSubmitButton: true });

    //Call API to complete
    axios
      .post('user/password', this.state.fields)
      .then(() => {
        this.setState({ disableSubmitButton: false, successMessage: 'Password changed' });

        utils.track('Updated Password');
      })
      .catch(error => {
        this.setState({
          disableSubmitButton: false,
          submitError: utils.getFirstApiError(error, 'Validation error'),
        });
      });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Security - Hurdle</title>
        </Helmet>

        <ChangePasswordForm
          fields={this.state.fields}
          onChange={this.handleInputChange}
          onValid={this.handleFormValid}
          submitError={this.state.submitError}
          successMessage={this.state.successMessage}
        />
      </div>
    );
  }
}
