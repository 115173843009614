import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyledContainer, StyledLink } from '../../theming/themed-components';
import { getStoreLinkToEpigeneticsProduct } from '../../components/common/storefront';

const Results = ({ actionable, hasResults, processingTests, match: { url }, testUser }) => {
  const isClinicUser = testUser && testUser.clinic_id;

  if (!actionable || !actionable.epigenetic_indicators) {
    //Still loading..
    return null;
  }
  else if (hasResults) {
    // biological-age test results are being omitted here from legacy system, since we are migrating them to test-results-service
    // other epigenetic tests should not be affected

    const itemsToShow = [...actionable.epigenetic_indicators.filter(item => item.slug !== 'biological-age' && item.status == 'viewable')];
    if (itemsToShow.length == 0) {
      return null;
    }

    return (
      <div className="dashboard-section__section-container">
        <h2 className="dashboard-section__sub-title">Epigenetic Health</h2>
        <div className="dashboard-section__results-section">
          <div>
            {itemsToShow.slice(0, 2).map(item => {
              const { name, slug, result_status, result_text } = item;
              const link = `you/indicator/${slug}`;

              return (
                <Link className="dashboard-section__single-result more-padding" key={slug} to={`${url}/${link}`}>
                  <div className="result-header">
                    <h5 className="result-title">{name}</h5>
                    <p className={`result-text ${result_status}`} data-private>
                      {result_text}
                    </p>
                  </div>
                  <p>
                    <span className="link">View</span> <i className="icon-right-open" />
                  </p>
                </Link>
              );
            })}
          </div>
          <div className="result-button">
            <StyledLink className="btn" to={`${url}/you`}>
              View all reports
            </StyledLink>
          </div>
        </div>
      </div>
    );
  } else {
    //Probably waiting on results..
    return null;
  }
};

Results.defaultProps = {
  actionable: {},
  match: { url: '/dashboard' },
};

Results.propTypes = {
  actionable: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default Results;
