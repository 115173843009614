import React from 'react';

export const EditAccountInfoIcon = ({ color }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_215_5010)">
        <path
          d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
          fill={color}
        />
        <path
          d="M27.3901 10.83L10.9401 27.29L10.0601 31.95L14.7201 31.07L31.1701 14.61C32.2101 13.57 32.2101 11.88 31.1701 10.84C30.1301 9.8 28.4401 9.8 27.4001 10.84L27.3901 10.83Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M26.4301 11.8L30.2001 15.57" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_215_5010">
          <rect width="42" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
