import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import { validated } from 'react-custom-validation';
import axios from 'axios';

import validationRules from '../helpers/validationRules';
import withErrorBoundary from '../common/errorBoundary';
import InputField from '../common/inputField';
import getAccessToken from './getAccessToken';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';
import { StyledButton } from '../../theming/themed-components';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: '',
        password: '',
      },
      disableSubmitButton: false,
      submitError: false,
      submitErrorMessage: '',
      formSuccess: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormValid = this.handleFormValid.bind(this);
    this.handleFormInvalid = this.handleFormInvalid.bind(this);
  }

  handleInputChange(field, value) {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  }

  handleFormValid() {
    this.setState({ submitError: false, submitErrorMessage: '', disableSubmitButton: true });
    this.setState({ disableSubmitButton: true });

    //Call API to reset
    const parms = {
      email: this.state.fields.email,
      password: this.state.fields.password,
      password_confirmation: this.state.fields.password, //dont confirm at the moment
      token: this.props.token,
    };
    axios
      .post(`auth/password/reset${getPartnerQueryParams()}`, parms)
      .then(response => {
        if (response.data.status) {
          this.setState({
            disableSubmitButton: false,
            formSuccess: true,
          });

          getAccessToken({
            email: this.state.fields.email,
            password: this.state.fields.password,
            onSuccess: () => {
              window.location = '/dashboard';
            },
          });
        } else {
          this.setState({
            disableSubmitButton: false,
            submitError: true,
            submitErrorMessage: 'Unable to reset password at this time.',
          });
        }
      })
      .catch(error => {
        const errorMsg = error.response.data ? error.response.data.error.message : 'Unable to reset password at this time.';
        this.setState({
          disableSubmitButton: false,
          submitError: true,
          submitErrorMessage: errorMsg,
        });
      });
  }

  handleFormInvalid() {
    this.setState({ submitError: true });
  }

  render() {
    const { submitError, submitErrorMessage } = this.state;

    return (
      <div className="registration__wrapper">
        <div className="registration__container">
          <ResetPasswordForm
            fields={this.state.fields}
            formSuccess={this.state.formSuccess}
            onChange={this.handleInputChange}
            onInvalid={this.handleFormInvalid}
            onValid={this.handleFormValid}
            submitError={submitError}
            submitErrorMessage={submitErrorMessage}
          />
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(ResetPassword);

function resetPasswordValidationConfig(props) {
  const { email, password } = props.fields;

  return {
    fields: ['email', 'password'],
    validations: {
      email: [
        [validationRules.isRequired, email],
        [validationRules.isEmail, email],
      ],
      password: [
        [validationRules.isRequired, password],
        [validationRules.complexPassword, password],
      ],
    },
  };
}

const ResetPasswordFormFields = props => {
  const { fields, onChange, onValid, onInvalid, $validation, submitError, submitErrorMessage, formSuccess, $submit, $fieldEvent } = props;

  return (
    <form
      className="register-form__container"
      onSubmit={e => {
        e.preventDefault();
        $submit(onValid, onInvalid);
      }}
    >
      <h2 className="register-form__title">Reset Password</h2>
      <p className="register-form__description">Enter your email and a new password below.</p>
      <div className="register-form__input-fields">
        <div className="register-form__single-field">
          <InputField
            autoFocus
            error={$validation.email.show ? $validation.email.error.reason : ''}
            label="Your Email"
            name="email"
            onBlur={() => $fieldEvent('blur', 'email')}
            onChange={e => onChange('email', e.target.value)}
            showError={$validation.email.show}
            type="email"
            value={fields.email}
          />
        </div>
        <div className="register-form__single-field">
          <InputField
            error={$validation.password.show ? $validation.password.error.reason : ''}
            iconClass="icon-eye"
            label="New password"
            name="password"
            onBlur={() => $fieldEvent('blur', 'password')}
            onChange={e => onChange('password', e.target.value)}
            showError={$validation.password.show}
            type="password"
            value={fields.password}
          />
        </div>
        <div>
          {formSuccess ? (
            <div className="register-form__single-field">
              <Link className="btn btn--full-width" to={`/login${getPartnerQueryParams()}`}>
                Login
              </Link>
            </div>
          ) : (
            <div>
              <div className="register-form__single-field">
                <StyledButton className="btn btn--full-width">Reset Password</StyledButton>
              </div>
              <div className="register-form__single-field login-form__links single-link">
                <Link to={`/login${getPartnerQueryParams()}`}>Login</Link>
              </div>
            </div>
          )}

          {submitError && !formSuccess && (
            <div className="form__error form__error--general">{submitErrorMessage ? submitErrorMessage : 'Please complete the errors'}</div>
          )}
        </div>
      </div>
    </form>
  );
};

const ResetPasswordForm = validated(resetPasswordValidationConfig)(ResetPasswordFormFields);
