import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DiseaseRisk from '../../../../containers/you/common/diseaseRisk';

import HeroSection from './heroSection';
import AgeAffectors from './ageAffectors';
import Comparison from './comparison';

import HealthyRanges from '../components/healthyRanges';
import TrackEpigeneticAge from '../components/trackEpigeneticAge';
import ResultImprovements from '../components/resultImprovements';
import HealthSpecialist from '../components/healthSpecialist';
import PageTour from '../../../../components/common/pageTour';

const bioPageSteps = [
    {   selector: '[data-tut="health-indicator"]',
        content: ({ goTo }) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                    <div className="page-tour__description-container">
                        <div>
                        Analysing epigenetic data is the most accurate way to measure how fast your body is ageing. Your biological age is the age of your body at the molecular level. It’s a better health indicator than your chronological age (how old you are at each birthday).
                        </div>
                    </div>
                </div>
                <div className="page-tour__footer">
                    <div className="step-number">
                    1 of 7
                    </div>
                    <button className="btn" onClick={() => (goTo(1))}>Next</button>
                </div>
            </div>
        ),
        position: 'top',
    },
    {
        selector: '[data-tut="health-indicator-details"]',
        content: ({ goTo }) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                    <div className="page-tour__description-container">If your biological age is lower than your chronological age this means that you are ageing slower than the average population. We normally observe this in people that have very healthy lifestyles. The opposite is true when your biological age is higher than expected.</div>
                </div>
                <div className="page-tour__footer">
                    <div className="step-number">
                    2 of 7
                    </div>
                    <button className="btn" onClick={() => (goTo(2))}>Next</button>
                </div>
            </div>
        ),
        position: 'bottom',
    },
    {
        selector: '[data-tut="comparison-factor"]',  
        content: ({ goTo }) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                <div className="page-tour__description-container">Here you can compare your biological age with the values that we observe for other human populations, such as super centenarians or people that live in rural and urban areas.</div>
                </div>
                <div className="page-tour__footer">
                    <div className="step-number">
                    3 of 7
                    </div>
                    <button className="btn" onClick={() => (goTo(3))}>Next</button>
                </div>
            </div>
        ),
        position: 'right',
    },
    {   
        selector: '[data-tut="top-tip"]',  
        content: ({ goTo }) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                    <div className="page-tour__description-container">
                        <div className="page-tour__description-container">Knowing your current biological age is only the beginning. See what is having the greatest impact on your biological age and what you can do to lower it.</div>
                    </div>
                </div>
                <div className="page-tour__footer">
                    <div className="step-number">
                        4 of 7
                    </div>
                    <button className="btn" onClick={() => (goTo(4))}>Next</button>
                </div>
            </div>
        ),
    },
    {
        selector: '[data-tut="health-specialist"]',
        content: ({ goTo }) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                    <div className="page-tour__description-container">
                        <div className="page-tour__description-container">Take it one step further by speaking with your Health Team to learn what else you can do to reduce your risk of age-related disease.</div>
                    </div>
                </div>
                <div className="page-tour__footer">
                    <div className="step-number">
                        5 of 7
                    </div>
                    <button className="btn" onClick={() => (goTo(5))}>Next</button>
                </div>
            </div>
        ),
        position: 'top',
    },
    {
        selector: '[data-tut= the-science ]',
        content: ({ goTo }) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                    <div className="page-tour__description-container">
                        <div className="page-tour__description-container">Explore this section to find out how we calculate your biological age, combining the latest DNA sequencing technologies and artificial intelligence algorithms. We assess more than 20,000,0000 epigenetic markers in your DNA!</div>
                    </div>
                </div>
                <div className="page-tour__footer">
                    <div className="step-number">
                        6 of 7
                    </div>
                    <button className="btn" onClick={() => (goTo(6))}>Next</button>
                </div>
            </div>
        ),
    },
    {
        selector: '[ data-tut="current-test-date"]',
        content: ({close}) => (
            <div className="page-tour">
                <div className="page-tour__container">
                    <div className="page-tour__header">
                        <div className="image-name-container">
                            <div className="icon-container">
                                <img src="/img/you/actionable-you/daniel-herranz.png" className="icon"/>
                            </div>
                            <span><strong>Daniel</strong> from Hurdle</span>
                        </div>
                    </div>
                    <div className="page-tour__description-container last-step">
                        Go ahead and find out more about your biological age.
                    </div>
                </div>
                <div className="last-step-button-container">
                    <button className="btn" onClick={(close)}>Get Started</button>
                </div>
            </div>
        ),
    },
  ];

class OverviewBiologicalAge extends Component {
    render() {
        const { heroSectionInfo, diseaseRisk, previousTest, resultDelta, healthyRanges, ageOverTimeGraph,
            breakpoint, recommendations, team, slug, customComparisons } = this.props;

        return (
            <section>
                {
                    (window.location.toString().indexOf('?tour=1') != -1) ? <PageTour steps={bioPageSteps}/> : ''
                }
                {heroSectionInfo && heroSectionInfo.currentTestDateText ? <p data-tut="current-test-date" className="result-hero__current-test-date">{heroSectionInfo.currentTestDateText}</p> : ''}
                <section className="biological-age__overview-container">
                    <HeroSection
                        heroSectionInfo={heroSectionInfo}
                        previousTest={previousTest}
                        resultDelta={resultDelta}
                        slug={slug}
                    />
                </section>
                <section className="biological-age__overview-container">
                    <HealthyRanges healthyRanges={healthyRanges} />
                </section>
                <section className="biological-age__overview-container">
                    <TrackEpigeneticAge
                        biologicalData={ageOverTimeGraph}
                        breakpoint={breakpoint}
                        slug={slug}
                        wrapperClass="no-padding"
                    />
                </section>
                <section className="biological-age__overview-container">
                    <AgeAffectors />
                </section>

                <Comparison breakpoint={breakpoint} customComparisons={customComparisons} />
                
                <section className="biological-age__overview-container">
                    <ResultImprovements recommendations={recommendations} slug={slug} wrapperClass="no-padding" />
                </section>
                
                <section className="biological-age__overview-container">
                    <DiseaseRisk diseaseRisk={diseaseRisk} />
                </section>
                <section className="biological-age__overview-container full-width">
                    <div className="biological-age__graph-section no-padding">
                        <HealthSpecialist team={team} />
                    </div>
                </section>
            </section>
        );
    }
}

OverviewBiologicalAge.propTypes = {
    heroSectionInfo: PropTypes.object,
    diseaseRisk: PropTypes.object,
    previousTest: PropTypes.object,
    resultDelta: PropTypes.any,
    healthyRanges: PropTypes.object,
    ageOverTimeGraph: PropTypes.object,
    breakpoint: PropTypes.string,
};

export default OverviewBiologicalAge;