import React from 'react';
import { StyledButton } from '../../../../theming/themed-components';

export const TestToReleaseStep01 = ({ history }) => {
  const handleBack = () => history.goBack();
  const handleNext = () => history.push('/dashboard/covid/test-timeline/test-to-release/2');
  return (
    <section className="test-dashboard-section content-wrapper-animate">
        <div className="test-dashboard-section__section_content centered">
            <img className="back-button" src="/img/back.svg" alt="" onClick={handleBack} />
            
            <div className="test-dashboard-section__container">
                <div className="content-container">
                <div>
                    <div className="section-title">
                        Testing timeline calculator
                    </div>
                    <div className="section-sub-title">
                        I want to have a Covid test for
                    </div>
                    <div className="item-selection">
                        <input defaultChecked={true} type="radio" name="text-selection" value="test-to-release" />
                        <div>
                            <div className="section-sub-title">
                                Test-to-release
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                You will get
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li>
                            Non-intrusive saliva testing kit at day 5 of your isolation
                        </li>
                        <li>
                            Shipped to your isolation address using Royal Mail Tracked 24
                        </li>
                        <li>
                            A unique booking reference immediately to be used in the passenger locator form.
                        </li>
                        <li>
                            Your result in 24-36 hours of being received at the lab and end quarantine early if negative.
                        </li>
                        <li>
                            A certificate of your result.
                        </li>
                    </ul>
                </div>

                <div className="button-container">
                    <div className="small-back" onClick={handleBack}>
                        <img src="/img/small-back.svg" alt="" />
                        <div>
                            Back
                        </div>
                    </div>
                    <StyledButton className="btn cancel" onClick={handleNext} type="button">
                        Next
                    </StyledButton>
                </div>
                </div>
            </div>
        </div>
    </section>
  );
};
