import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SplitConsumer from '../../../../components/context/splitConsumer';
import CustomLineGraph from '../components/customLineGraph';
import CompareGraph from '../components/compareGraph';

class Comparison extends Component {
  constructor(props) {
    super(props);

    const dietKeys =
      props.customComparisons &&
      props.customComparisons.diet &&
      props.customComparisons.diet.graph &&
      Object.keys(props.customComparisons.diet.graph.tabs);
    const exerciseKeys =
      props.customComparisons &&
      props.customComparisons.exercise &&
      props.customComparisons.exercise.graph &&
      Object.keys(props.customComparisons.exercise.graph.tabs);

    this.state = {
      dietTab: dietKeys && dietKeys[0],
      exerciseTab: exerciseKeys && exerciseKeys[0],
    };

    this.selectTab = this.selectTab.bind(this);
  }

  selectTab(tabName, value) {
    this.setState({ [tabName]: value });
  }

  renderComparingSection(everyone_metabolic) {
    if (!everyone_metabolic) {
      return null;
    }

    const { title, text, count, analysis_text, low_label, high_label } = everyone_metabolic;
    return (
      <div className="comparison__container">
        {title && <h4 className="indicator-section-title inside-title">{title}</h4>}
        {text && <p className="indicator-section-description">{text}</p>}
        <CompareGraph
          data={{ count, analysis_text, low_label, high_label }}
          labels={{
            left: '/img/you/actionable-you/metabolic-status/metabolic-good-label.png',
            right: '/img/you/actionable-you/metabolic-status/metabolic-bad-label.png',
          }}
        />
      </div>
    );
  }

  renderSection(props, tab) {
    if (!props) {
      return null;
    }

    const { title, text, graph, analysis_text } = props;

    return (
      <div className="comparison__container">
        {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
        {text ? <p className="indicator-section-description">{text}</p> : ''}
        {this.renderTabs(graph.tabs, tab)}
        <div>
          <CustomLineGraph data={graph} selectedTab={this.state[tab]} legendWrapperClass="metabolic-comparison" />
          {analysis_text[this.state[tab]] ? (
            <div className="analysis-text analysis-text--wide text-center">
              <div className="comparison__analysis-section">
                <div className="analysis-image">
                  <img alt={title.replace(/ /g, '-')} src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                </div>
                <div className="comparison-analysis-text">{analysis_text[this.state[tab]]}</div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  renderTabs(tabs, tabName) {
    if (!(tabs && Object.keys(tabs).length)) {
      return null;
    }

    return (
      <div className="line-graph__tabs-container">
        {Object.keys(tabs).map((key, index) => {
          return (
            <button
              className={`single-tab${this.state[tabName] === key ? ' active' : ''}`}
              key={index}
              onClick={() => this.selectTab(tabName, key)}
            >
              {tabs[key]}
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const { customComparisons } = this.props;
    if (!customComparisons) {
      return null;
    }

    const { title, text, everyone_metabolic, diet, exercise } = customComparisons;
    return (
      <section className="biological-age__overview-container">
        <section className="biological-age__graph-section no-padding">
          {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
          {text ? <p className="indicator-section-description">{text}</p> : ''}
          {this.renderComparingSection(everyone_metabolic)}
          {this.renderSection(exercise, 'exerciseTab')}
          {this.renderSection(diet, 'dietTab')}
        </section>
        <SplitConsumer name="show_compare_feature">
          {value =>
            value === 'on' ? (
              <div className="biological-age__graph-section no-padding">
                <Link className="btn" to="/dashboard/you/compare">
                  see more comparisons
                </Link>
              </div>
            ) : (
              ''
            )
          }
        </SplitConsumer>
      </section>
    );
  }
}

export default Comparison;
