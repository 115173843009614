import React from 'react';

import CustomRadarChart from '../components/customRadarChart';

const WhatAffecting = (props) => {
    const { whatAffecting, breakpoint } = props;
    if(!whatAffecting || Array.isArray(whatAffecting)) {
        return null;
    }

    const { title, text, negative_impact, positive_impact } = whatAffecting;
    return (
        <div className="biological-age__graph-section no-padding">
            {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
            {text ? <p className="indicator-section-description" dangerouslySetInnerHTML={{__html: text}} /> : ''}
            <div className="comparison__container">
                <CustomRadarChart graph={positive_impact} breakpoint={breakpoint} />
                <CustomRadarChart graph={negative_impact} breakpoint={breakpoint} />
            </div>
        </div>
    );
};

export default WhatAffecting;
