import React from 'react';

const ReferenceLine = ({data}) => {
    if(!data) {
        return null;
    }

    const { range, you, refrence_line, x } = data;

    return (
        <div className="you-page-v3__reference-line-graph">
            <div className="line-bar-container">
                <div className="you-point" style={{left: `calc(${you.percent}% - 3px)`}} />
                <div className="reference-line" style={{left: `calc(${refrence_line.percent}% - 3px)`}} />
                {
                    range.map((item, index) => {
                        const { min_percent, max_percent, in_range } = item;
                        const width = max_percent - min_percent;
                        return (
                            <div
                                className={`single-bar ${in_range ? 'active' : ''}`}
                                key={index}
                                style={{width: `${width}%`, left: `${min_percent}%`}}
                            />
                        );
                    })
                }
            </div>
            <div className="reference-line-labels">
                {x.min_label != '' ? <span>{x.min_label}</span> : ''}
                {x.max_label != '' ? <span>{x.max_label}</span> : ''}
            </div>
        </div>
    );
};

export default ReferenceLine;