import styled from 'styled-components';

const Icon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;
Icon.defaultProps = {
  src: '/img/commentary-icon.png',
  alt: 'Commentary icon',
};

export default Icon;
