import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../common/loader';
import withErrorBoundary from '../common/errorBoundary';
import moment from 'moment';


class Studies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            studies: null,
            selectedTab: 'current',
        };

        this.handelSelectTab = this.handelSelectTab.bind(this);
    }

    handelSelectTab(e, tab) {
        this.setState((prevState, props) => ({
            selectedTab: tab,
        }), () => {
            if(tab==='current' || tab==='joined') {
                this.getStudies();
            }
        });
    }


    componentDidMount() {
        this.getStudies();
    }

    getStudies() {
        //Set so get loader
        this.setState({studies: null});

        const url = (this.state.selectedTab === 'current') ? 'studies' : 'user/studies';
        axios.get(url)
        .then(response => this.setState({
            studies: response.data,
        }));
    }


    render() {
        const {studies, selectedTab} = this.state;

        const studiesListHtml = studies ? studies.data.map((study, index) => {
            const link = study.coming_soon ? '#' : '/dashboard/studies/'+study.slug;
            const linkText = study.coming_soon ? 'Coming Soon' : 'View';
            //const joinedUserClass = study.joined_users >= study.required_users ? 'study-list-item__joined' : 'study-list-item__joined-not-met';

            return (
                <div key={index} className="study-list-item">
                    <Link to={link}>
                        <div className="study-list-item__image-container">
                            <div className={'study-list-item__image-overlay study-list-item__image-overlay--'+study.category_id}/>

                            <div className="study-list-item__external-internal study-list-item__info-item">
                                {study.location_type}
                            </div>

                            <div className="study-list-item__joined study-list-item__info-item">
                                Joined <span>{study.joined_users}/{study.required_users}</span>
                            </div>

                            <img src={study.image_small_url} alt={study.title} className="study-list-item__image" />
                            <div className="study-list-item__title-container">
                                <div className="study-list-item__title">{study.title}</div>
                                <p>{study.excerpt}</p>
                            </div>
                        </div>
                    </Link>

                    <div className="study-list-item__content-container">
                        <div className="study-list-item__info-container">
                            <div className="study-list-item__info-item study-list-item__info-item--left">
                                Earn <div className="price-box price-box--credits">{study.credits_earnt} Credits</div>
                            </div>
                            <div className="study-list-item__info-item study-list-item__info-item--right">
                                <div className="time-icon" />
                                {study.minutes_required} mins
                            </div>
                        </div>

                        {study.coming_soon ?
                            <span className="study-list-item__coming-soon">Coming Soon</span>
                        :
                            <div>
                                <Link to={link} className="btn">Find Out More<i className="icon-right-open"/></Link>
                                <div className="study-list-item__closes">
                                    Closes {moment(study.start_date).format('Do MMM YY')}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            );
        }) : '';

        return (
            <section className="dashboard-section content-wrapper-animate">
                <section className="dashboard-hero">
                    <div className="dashboard-hero__text-container">
                        <h1 className="dashboard-hero__title study-hero__title">Studies</h1>
                        <p className="dashboard-hero__subtitle">Contribute to science and earn credits by taking part in real scientific studies</p>
                    </div>

                    <ul className="menu-list dashboard-hero__menu">
                        <li><a href="#" className={selectedTab=='current' ? 'active' : ''} onClick={(e) => this.handelSelectTab(e, 'current')}>Current</a></li>
                        <li><a href="#" className={selectedTab=='joined' ? 'active' : ''} onClick={(e) => this.handelSelectTab(e, 'joined')}>Joined</a></li>
                       {/*} <li><a href="#" className={selectedTab=='howitworks' ? 'active' : ''} onClick={(e) => this.handelSelectTab(e, 'howitworks')}>How it Works</a></li>*/}
                    </ul>
                </section>

                <section className="dashboard-section__container">
                    {!studies &&
                        <Loader/>
                    }

                    <div className="study-list">
                        {studiesListHtml}
                    </div>
                </section>

            </section>
        );
    }
}

export default withErrorBoundary(Studies);
