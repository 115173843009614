import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import withErrorBoundary from '../common/errorBoundary';
import RestoreScroll from '../common/restoreScroll';
import SubMenuBar from '../common/subMenuBar';

import GenomePage from './genomePage';
import BasicYou from './basic';
import GeneticTrait from './basic/geneticTrait';
import Ancestry from './basic/ancestry';

const breadcrumbConstant = {
    'genetic': { type: 'link', title: 'Genome', link: 'genetic' },
    'trait': { type: 'link', title: 'Trait', link: 'genetic/trait' },
    'wellness': { type: 'link', title: 'Wellness', link: 'genetic/wellness' }
};

class Genome extends Component {
    constructor() {
        super();

        this.state = {
            basic: null,
            isLoading: true,
            breadcrumb: null
        };
    }

    componentDidMount() {
        const { location: {pathname}} = this.props;
        const breadcrumb = this.getBreadcrumb(pathname);
        this.setState({ breadcrumb });

        axios.get('test_user/results')
            .then(({ data = {} }) => {
                this.setState({ basic: data.fixed, isLoading: false });
            }).catch((err) => {
                this.setState({ isLoading: false }, () => {  });
            });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            const { location: {pathname}} = this.props;
            const breadcrumb = this.getBreadcrumb(pathname);
            this.setState({ breadcrumb });
        }
    }

    getBreadcrumb(path) {
        const routeArray = path.split('/');
        const sources = routeArray.reduce((breadcrumb, route, index) => {
            if (breadcrumbConstant[route] && index !== (routeArray.length - 1)) {
                if((path.includes('trait/') || path.includes('wellness/')) && route === 'genetic') {
                    return breadcrumb;
                }
                breadcrumb[route] = breadcrumbConstant[route];
            }
            return breadcrumb;
        }, {});

        return Object.keys(sources).map(i => sources[i]);
    }

    render() {
        const { testUser, match, breakpoint, openChat } = this.props;
        const { basic, isLoading } = this.state;

        const GenomePageComponent = (props) => (
            <div>
                <Helmet>
                    <title>Genetics - Hurdle</title>
                </Helmet>
                <GenomePage
                    {...props}
                    basic={basic}
                    isLoading={isLoading}
                    testUser={testUser}
                    openChat={openChat}
                />
            </div>
        );

        const WellnessPage = (props) => (
            <div>
                <Helmet>
                    <title>Wellness - Hurdle</title>
                </Helmet>
                <RestoreScroll>
                    <BasicYou {...props} section="wellness" />
                </RestoreScroll>
            </div>
        );

        const TraitPage = (props) => (
            <div>
                <Helmet>
                    <title>Traits - Hurdle</title>
                </Helmet>
                <RestoreScroll>
                    <BasicYou {...props} section="trait" />
                </RestoreScroll>
            </div>
        );

        const GeneticTraitPage = (props) => {
            const slug = props.match.params.slug
                ? props.match.params.slug
                : null;
            return (
                <div>
                    <Helmet>
                        <title>{slug} - Hurdle</title>
                    </Helmet>
                    <GeneticTrait slug={slug}/>
                </div>
            );
        };

        const AncestryPage = () => (
            <div>
                <Helmet>
                    <title>Ancestry - Hurdle</title>
                </Helmet>
                <Ancestry breakpoint={breakpoint}/>
            </div>
        );

        return (
            <div className="you-page__wrapper">
                <SubMenuBar
                    hideMenuBar={true}
                    navigation={this.state.breadcrumb}
                    show={window.location.pathname !== '/dashboard/genetic'}
                />
                <Switch>
                    <Route
                        path={`${match.url}/trait/ancestry`}
                        render={AncestryPage}
                    />
                    <Route
                        path={`${match.url}/wellness/:slug`}
                        render={GeneticTraitPage}
                    />
                    <Route
                        path={`${match.url}/trait/:slug`}
                        render={GeneticTraitPage}
                    />
                    <Route
                        path={`${match.url}/wellness`}
                        render={WellnessPage}
                    />
                    <Route
                        path={`${match.url}/trait`}
                        render={TraitPage}
                    />
                    <Route render={GenomePageComponent} />
                </Switch>
            </div>
        );
    }
}

Genome.defaultProps = {
    breakpoint: 'large'
};

Genome.propTypes = {
    testUser: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    breakpoint: PropTypes.string,
};

export default withErrorBoundary(Genome);