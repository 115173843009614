import PropTypes from 'prop-types';
import TimeDetails from './timeDetails';
import { getRanges } from './utils';
import LevelDetails from './levelDetails';
import NormalDetails from './normalDetails';
import CycleDetails from './cycleDetails';

const BiomarkerDetails = ({ interpretationData, unit }) => {
  switch (interpretationData?.rule) {
    case 'CYCLE':
      return <CycleDetails interpretationData={interpretationData} unit={unit} />;
    case 'TIME':
      return <TimeDetails interpretationData={interpretationData} unit={unit} />;
    case 'LEVEL':
      return <LevelDetails interpretationData={interpretationData} unit={unit} />;
  }

  const ranges = getRanges(interpretationData);
  if (Array.isArray(ranges)) {
    return <NormalDetails interpretationData={interpretationData} unit={unit} />;
  }

  return ranges;
};

BiomarkerDetails.propTypes = {
  interpretationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  unit: PropTypes.string,
};

export default BiomarkerDetails;
