import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const ProgressSteps = ({ steps, hideText }) => {
    return (
        <div className="progress-steps__container">
            {steps.map(step => (
                <ProgressStep hideText={hideText} key={step.name} outlined={step.outlined} step={step} />
            ))}
        </div>
    );
};

ProgressSteps.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            dateTime: PropTypes.string,
            icon: PropTypes.elementType,
            name: PropTypes.string,
            outlined: PropTypes.bool,
        })
    ).isRequired,
    hideText: PropTypes.bool
};

const ProgressStepText = ({ name, dateTime }) => {
    return (
        <div className="progress-step-text">
            <label className="progress-step-text__title">{name}</label>
            {dateTime && (
                <div className="progress-step-text__date">
                    <div>{moment(dateTime).format('DD MMM YYYY')}</div>
                </div>
            )}
        </div>
    );
};

function ProgressStep ({ step, outlined, hideText }) {
    const { icon: Icon, dateTime, name } = step;
    return (
        <div className={'progress-steps__step' + (dateTime ? ' progress-steps__step--active' : '')}>
            <div className="progress-steps__step-icon" title={name}>
                {<Icon />}
                {outlined && (
                    <div className="outline"></div>
                )}
            </div>
            {!hideText && <ProgressStepText dateTime={dateTime} name={name} />}
        </div>
    );
}

export { ProgressSteps, ProgressStepText };
