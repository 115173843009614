import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getProductInfoForProductCode } from '../../services/product/api';
import Loader from '../../components/common/loader';

const PROCESSING_STATUS = 'processing';
export const SUPERVISED_STATUS_FAILED = 'failed';
export const SUPERVISED_STATUS_PASSED = 'passed';

const { supervised_test_url } = window.CONFIG;

const renderLftStatus = (result) => {
  if (result.status === PROCESSING_STATUS) {
    return <div className="section-comment">Validation in progress</div>;
  }

  if (result.product.is_supervised && result.supervised_status !== SUPERVISED_STATUS_PASSED) {
    return renderSupervisedTestStatus(result);
  }

  const uploadPhotoLink = `/register/stage/lateralflow/result?test_id=${result.id}&barcode=${result.barcode}&product_code=${result.product.product_code}`;
  return (
    <div className="section-comment">
      <a href={uploadPhotoLink}>Upload Photo Result</a>
    </div>
  );
};

const renderSupervisedTestStatus = result => {
  if (result.supervised_status === SUPERVISED_STATUS_FAILED) {
    return <div className="section-comment">Supervision for this test failed</div>;
  }

  return (
    <div className="section-comment">
      <a href={supervised_test_url} target="_blank" rel="noreferrer">
        Complete this test with video supervision
      </a>
    </div>
  );
};

export const TestProgress = ({ result }) => {
  const [isLft, setIsLft] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProductInfo = async () => {
      try {
        const response = await getProductInfoForProductCode(result.product.product_code);
        setIsLft(response?.data?.productType === 'lft');
      } catch {
        setIsLft(false);
      } finally {
        setIsLoading(false);
      }
    };

    getProductInfo();
  }, []);

  const getRegisteredAt = dateFormat => {
    return result && result.registered_at_timezone === null
      ? moment(result.registered_at).format(dateFormat)
      : result && result.registered_at_timezone !== null
      ? moment(result.localised_registered_at).format(dateFormat)
      : null;
  };
  const timezone =
    result && result.registered_at_timezone !== null
      ? ` (GMT ${result.registered_at_timezone < 0 ? '' : '+'}${result.registered_at_timezone})`
      : '';

  return (
    <div className="test-dashboard-section__container full-width">
      <div className="content-container">
        <div className="section-content split-row-group">
          <div>
            <div className="section-pre-title">Test progress</div>
            <div className="section-large-title">{result.result}</div>
            {isLoading ? <Loader></Loader> : isLft && renderLftStatus(result)}
            {result.kit_id ? <div className="section-comment">Kit ID: {result.kit_id}</div> : ''}
            <div className="section-comment">{`${result.product.product_test_type} - ${result.product.product_purpose}`}</div>
            <div className="section-comment">{getRegisteredAt('DD/MM/YY')}</div>
            <div className="section-comment">
              {getRegisteredAt('H:mm a')}
              {timezone}
            </div>
          </div>
          <div className="test-results row-group">
            <img src="/img/test/register.svg" alt="" />
            <div />
            <img src="/img/test/awaitingresult.svg" alt="" />
            <div />
            <img src="/img/test/resultready.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
