import { Fragment } from 'react';
import PropTypes from 'prop-types';
import textClassnames from '../textClassnames';
import ValidResultContent from './validResultContent';
import FailedResultContent from './failedResultContent';
import FallbackInterpretationContent from './fallbackInterpretationContent';
import { getIsFallbackInterpretationContent } from './utils';
import { StyledButton } from '../../../../theming/themed-components';

const BiomarkerModal = ({ onClose, bm, biomarkerDescription }) => {
  if (!bm?.biomarker) return null;
  const { interpretation, formattedInterpretation, biomarker, failedResult, formattedResult } = bm;
  const isFallbackInterpretationContent = getIsFallbackInterpretationContent(interpretation);

  return (
    <div className="biomarker-modal">
      <div className="biomarker-modal__title">{biomarker.name}</div>
      <div className={`biomarker-modal__text ${failedResult ? 'result' : ''}`}>{formattedResult}</div>
      <div className={`biomarker-modal__text result ${textClassnames[formattedInterpretation] || ''}`}>{formattedInterpretation}</div>
      <br />
      {isFallbackInterpretationContent ? (
        <FallbackInterpretationContent interpretation={interpretation} />
      ) : (
        <Fragment>
          {failedResult ? (
            <FailedResultContent result={failedResult} />
          ) : (
            <ValidResultContent bm={bm} biomarkerDescription={biomarkerDescription} />
          )}
        </Fragment>
      )}
      <div className="biomarker-modal__buttons">
        <StyledButton className="btn" onClick={onClose}>
          Close
        </StyledButton>
      </div>
    </div>
  );
};

BiomarkerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  bm: PropTypes.shape({
    biomarker: PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      unit: PropTypes.string.isRequired,
      interpretationRule: PropTypes.shape({
        rule: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    result: PropTypes.string.isRequired,
    formattedResult: PropTypes.string.isRequired,
    formattedInterpretation: PropTypes.string.isRequired,
    interpretationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    failedResult: PropTypes.oneOfType([
      PropTypes.shape({
        chronomicsStatus: PropTypes.string.isRequired,
        chronomicsExplanation: PropTypes.string.isRequired,
      }),
      PropTypes.bool,
    ]),
  }),
  biomarkerDescription: PropTypes.object,
};

export default BiomarkerModal;
