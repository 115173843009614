import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import SplitConsumer from '../../components/context/splitConsumer';
import HelpBox from '../../components/common/helpBox';
import Loader from '../../components/common/loader';
import withError from '../../components/common/withError';
import withErrorBoundary from '../../components/common/errorBoundary';

import FeaturedFactors from '../../containers/you/common/featuredFactors';

import { getYou } from '../../services/you/actions';
import { youSelector, youLoadingSelector } from '../../services/you/selectors';

import { getAspiration, editAspiration, saveAspiration, toggleAspirationForm, getGoals } from '../../services/improvements/actions';
import {
  aspirationSelector,
  aspirationFormSelector,
  improvementsLoadingSelector,
  goalsSelector,
} from '../../services/improvements/selectors';
import { StyledButton } from '../../theming/themed-components';

const cards = [
  {
    title: 'My Future',
    description: 'See how different lifestyle factors impact your future biological age.',
    link: '/dashboard/improvements/future-predictions/overview',
    icon: 'icon-bolt',
    split_name: 'show_future_predictions',
  },
  {
    title: 'My Team',
    description: 'Add health specialists to your team and set up consultations.',
    link: '/dashboard/improvements/team',
    image: '/img/dashboard/improvement/my-team.png',
    split_name: null,
  },
];

class MyImprovements extends Component {
  componentDidMount() {
    this.props.fetchAspiration();
    this.props.fetchResults();
    this.props.fetchGoals();
  }

  getSelectGoals() {
    const { goals, match } = this.props;
    if (!goals) {
      return;
    }

    return (
      <div className="improvement-container__results-section">
        {goals.map((item, index) => {
          return (
            <Link
              to={{ pathname: `${match.url}/goals/${item.slug}`, state: item.slug }}
              className="improvement-container__single-result"
              key={index}
            >
              <div className="recommendation-image">
                <img src={item.icon_url} alt={item.title} />
              </div>
              <div className="recommendation-header">
                <h5 className="result-title">{item.title}</h5>
                <p className="recommendation-description">{item.sub_title}</p>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }

  renderCard(card) {
    return (
      <Link to={card.link} className="improvement-container__card">
        <div className="information-container">
          <h6 className="card-information-title">{card.title}</h6>
          <p className="card-information-description">{card.description}</p>
          <p to={card.link} className="card-information-link">
            Learn More
          </p>
        </div>
        <div className="card-image-section">
          {card.icon ? <i className={card.icon} /> : card.image ? <img src={card.image} alt={card.title} /> : ''}
        </div>
      </Link>
    );
  }

  render() {
    const { helpSection, goals, isLoading } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        <h3 className="improvement-container__title">Improve</h3>
        <div className="improvement-container__main-description">
          Welcome to your personalised health and wellbeing coach, personalised from your epigenetic profile.
        </div>
        <div className="improvement-container__routes-container"></div>
        {goals && goals.length ? (
          <div className="improvement-container__section-container">
            <h2 className="improvement-container__sub-title">Select a goal</h2>
            {this.getSelectGoals()}
          </div>
        ) : (
          ''
        )}
        <div className="improvement-container__section-container">
          <h2 className="improvement-container__sub-title">Need more help?</h2>
          <div className="improvement-container__routes-container">
            {cards.map((card, index) => (
              <React.Fragment key={index}>
                {card.split_name ? (
                  <SplitConsumer name={card.split_name}>{value => (value == 'on' ? this.renderCard(card) : '')}</SplitConsumer>
                ) : (
                  this.renderCard(card)
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="improvement-container__help-section">
          <HelpBox {...helpSection} />
        </div>
      </div>
    );
  }
}

MyImprovements.defaultProps = {
  showAspirationForm: true,
  aspiration: '',
  results: {},
  isLoading: true,
};

MyImprovements.propTypes = {
  showAspirationForm: PropTypes.bool,
  aspiration: PropTypes.string,
  fetchResults: PropTypes.func,
  fetchAspiration: PropTypes.func,
  editAspiration: PropTypes.func,
  submitAspiration: PropTypes.func,
  toggleAspirationForm: PropTypes.func,
  results: PropTypes.object,
  isLoading: PropTypes.bool,
  goals: PropTypes.array,
};

const mapStateToProps = state => ({
  results: youSelector(state),
  isLoading: youLoadingSelector(state, 'you') || improvementsLoadingSelector(state, 'aspiration'),
  aspiration: aspirationSelector(state),
  showAspirationForm: aspirationFormSelector(state),
  goals: goalsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchResults: () => dispatch(getYou()),
  fetchAspiration: () => dispatch(getAspiration()),
  fetchGoals: () => dispatch(getGoals()),
  editAspiration: aspiration => dispatch(editAspiration(aspiration)),
  submitAspiration: () => dispatch(saveAspiration()),
  toggleAspirationForm: value => dispatch(toggleAspirationForm(value)),
});

const enhance = compose(
  withErrorBoundary,
  withError({ reducer: 'improvements', page: 'aspiration' }),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(MyImprovements);
