import React from 'react';
import useBoolBar from './useBoolBar';
import Bar from './Bar';

const BoolBar = ({ interpretationDataValue, result }) => {
  const { getBoolBarConfig } = useBoolBar({ interpretationDataValue, result });
  const boolBarConfig = getBoolBarConfig();

  return (
    <>
      <Bar boolBarConfig={boolBarConfig} />
    </>
  );
};

export default BoolBar;
