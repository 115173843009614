import React, { Component } from 'react';

export default class IntroText extends Component {
    render() {
        const {id, title, description} = this.props;

        return (
            <div className="questionare-intro-text">
                {title ? <p className="questionare-intro-text__title">{title}</p> : ''}
                {description ? <p dangerouslySetInnerHTML={{__html: description}}></p> : ''}
            </div>
        );
    }
}
