import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';
import { Text } from 'recharts';

import FunFact from '../../../components/common/funFact';
import CustomizeBarChart, { RATINGS_MAP } from '../../../components/common/customizeBarChart';
import Question from '../../../components/common/question';

import config from '../../../config';
import SplitConsumer from '../../../components/context/splitConsumer';

import FAQ from '../../../containers/you/common/faqs';
import DiseaseRisk from '../../../containers/you/common/diseaseRisk';

import LineGraph from './components/lineGraph';
import WorldMap from './components/worldMap';
import TrackEpigeneticAge from './components/trackEpigeneticAge';
import PositionsBreakdown from './components/positionsBreakdown';
import ResultDelta from './components/resultDelta';
import HealthyRanges from './components/healthyRanges';

import utils from '../../../components/helpers/utils';

const ALL_FILTERS = [
    {name : 'Everyone', key: 'everyone', default: true, wgOnly: false, 'biological-age': false},
    {name : 'Age Group', key: 'age_group', default: true, wgOnly: false},
    {name : 'Country', key: 'country', default: false, wgOnly: false, 'biological-age': false},
    {name : 'Genetic Predisposition', key: 'genetic_predisposition', default: false, wgOnly: true}
];

const CustomizedTick = props => {
    const { slugData, width, payload: { value }, y, ...otherProps} = props;
    const { name, icon_url } = slugData[value];
    return <g>
      <image href={icon_url} width="25" y={y-12}/>
      <Text dx={50} { ...otherProps } textAnchor="start" y={y} width={width} fontSize="13px">{name}</Text>
           </g>;
};

export default class Overview extends Component {

    constructor(props) {
        super(props);

        const { slug } = this.props;
        const { wg_test } = config.app;

        //Filter the filters so we only have the ones applicable to this indicator
        const filters = ALL_FILTERS.filter((item) => {
            if(item.wgOnly) {
                if(!wg_test || wg_test.status !== 'processed') return false;
            }
            else if(item.hasOwnProperty(slug) && item[slug] === false) return false;
            return true;
        });

        //Set the state of the filters (on/off)
        const filterStates = {};
        filters.map((item) => {
            filterStates[item.key] = item.default;
        });

        this.state = {
            filters: filters,
            filterStates: filterStates,
        };

        this.handelFilterChange = this.handelFilterChange.bind(this);
    }

    handelFilterChange(e, filterKey) {
        this.setState(update(this.state, {filterStates: {[filterKey]: {$set: e.target.checked}}}));
    }

    renderBarGraphSection() {
        const { ageOverTimeGraph, breakpoint, resultUnit } = this.props;

        if(!ageOverTimeGraph) {
            return null;
        }

        return (
            <TrackEpigeneticAge
                biologicalData={ageOverTimeGraph}
                breakpoint={breakpoint}
                graphClass="compare-tab__compare-graph"
                resultUnit={resultUnit}
            />
        );
    }

    renderAffectingFactorsSection() {
        const { affectingFactors: {title, text, analysis_text, data}, status, isTinySmallDevice } = this.props;
        const graphData = data.reduce((acc, factor) => {
            const { rating, slug } = factor;
            const { value, color } = RATINGS_MAP[rating] || {};
            return {
                data: [ ...acc.data, { value, color, slug, } ],
                slugData: {...acc.slugData, [slug]: factor },
            };
        }, { data: [], slugData: {} });
        const domainAttr = isTinySmallDevice ? 'dataMax + 15' : 'dataMax + 10';

        return (
            <div className="biological-age__factors-container">
                <div className="biological-age__section-information">
                    <h4 className="indicator-section-title">{title}</h4>
                    <p className="indicator-section-information">{text}</p>
                    {analysis_text && <p className={`indicator-section-information indicator-section-information--analysis ${status}`}>{analysis_text}</p>}
                </div>
                <div className="biological-age__factors-section">
                    <div className="biological-age__factors-section__graph">
                        <CustomizeBarChart
                            data={graphData.data}
                            domainAttr={[0, domainAttr]}
                            isTinySmallDevice={isTinySmallDevice}
                            yAxisDataKey="slug"
                            yAxisWidth={isTinySmallDevice ? 100 : 250}
                            yTick={<CustomizedTick slugData={graphData.slugData} width={isTinySmallDevice ? 100 : 250}/>}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderBiologicalAgeGraphs() {
        const { comparisonGraphData, breakpoint, resultUnit } = this.props;

        if(!(comparisonGraphData && comparisonGraphData.graphs)) {
            return null;
        }

        const {title, text, graphs} = comparisonGraphData;

        return (
            <div className="biological-age__graph-section">
                <div className="biological-age__line-graph-section">
                    {
                        (title || text) ?
                            <div className="biological-age__section-information">
                                {title && <h4 className="indicator-section-title">{title}</h4>}
                                {text && <p className="indicator-section-information">{text}</p>}
                            </div> : ''
                    }
                    {
                        graphs.map((graph, index) => {
                            return (
                                <LineGraph
                                    graphData={graph.data}
                                    key={index}
                                    title={graph.title}
                                    text={graph.text}
                                    help={graph.help}
                                    breakpoint={breakpoint}
                                    resultUnit={resultUnit}
                                />
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    renderHeroSection() {
        const { slug, heroSectionInfo, selectedTab, resultDelta, previousTest, healthyRanges } = this.props;

        if(!heroSectionInfo || selectedTab !== 'overview') {
            return null;
        }

        return (
            <div className="result-hero__text-container">
                <div className="result-hero__information-section">
                    <h1 className="result-hero__title study-hero__title">{heroSectionInfo.title}</h1>
                    <SplitConsumer name="indicator_result_bar" attributes={{slug: slug}} data-private>>
                        {(value) => value == 'on' ?
                            <h6 className={`result-hero__result-status ${heroSectionInfo.resultStatus}`}>{heroSectionInfo.resultStatusText}</h6>
                            : ''
                        }
                    </SplitConsumer>
                    <ResultDelta
                        previousTest={previousTest}
                        result={heroSectionInfo.result}
                        resultDelta={resultDelta}
                        resultUnit={heroSectionInfo.resultUnit}
                    />
                    <p className="title-test-date">{heroSectionInfo.currentTestDateText}</p>

                    <p className="result-hero__title-information">{heroSectionInfo.analaysisText}</p>
                    <SplitConsumer name="indicator_result_bar" attributes={{slug: slug}}>
                        {(value) => value == 'on' ?
                            <HealthyRanges healthyRanges={healthyRanges} />
                            : ''
                        }
                    </SplitConsumer>
                    
                </div>
                <div className="result-hero-text-container">
                    <div className="result-hero-inside-container">
                        <p className="summary-text">{heroSectionInfo.summaryText}</p>
                        <Link to="/dashboard/improvements/team" className="talk-to-team" onClick={()=> utils.track('Talk to health team', {position: 'Overview Hero'})}>talk to your health team <i className="icon-right-open-big" /></Link>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { slug, affectingFactors, jsonSchema, uiSchema, heatMapData, howWorks, funFacts, breakpoint,
            recommendations, diseaseRisk, team, positionsGraph } = this.props;

        const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');

        return (
            <section>
                <section className="result-hero">
                    {this.renderHeroSection()}
                </section>
                <div className="biological-age__container">
                    {this.renderBarGraphSection()}
                    {jsonSchema ? <div className="biological-age__question-section"><Question jsonSchema={jsonSchema} section="EPIGENETIC_INDICATORS" uiSchema={uiSchema} /></div> : ''}
                    {this.renderBiologicalAgeGraphs()}
                    {
                        (howWorks && Object.keys(howWorks).length) ?
                            <div className="biological-age__how-works">
                                <div className="how-works-section">
                                    <div className="result-hero__how-works-section">
                                        <h4 className="range__title">{howWorks.title}</h4>
                                        <p className="indicator-section-information">{howWorks.text}</p>
                                        <p className="how-works-section__btn-container"><Link className={`btn${isMobile ? ' btn--full-width' : ''}`} to={`/dashboard/you/indicator/${slug}/science`}>The Science</Link></p>
                                    </div>
                                </div>
                            </div> : ''
                    }
                    <PositionsBreakdown breakpoint={breakpoint} positionsGraph={positionsGraph} />
                    <div className="biological-age__graph-section">
                        <div className="biological-age__disease-risk-section">
                            <DiseaseRisk diseaseRisk={diseaseRisk} slug={slug} />
                        </div>
                    </div>
                    {/*
                        <div className="biological-age__team-section">
                            <HealthSpecialist team={team} />
                        </div>
                    */}
                    {/*recommendations && recommendations.items && <ResultImprovements recommendations={recommendations} slug={slug} />*/}
                    {funFacts && funFacts.data &&
                        <div className="biological-age__fun-facts-container">
                            <FunFact items={funFacts.data} title={funFacts.title} />
                        </div>
                    }
                    <FAQ slug={slug} />
                    {(affectingFactors && Object.keys(affectingFactors).length) && this.renderAffectingFactorsSection()}
                    {heatMapData && <WorldMap heatMapData={heatMapData}/>}
                </div>
            </section>
        );
    }
}

Overview.propTypes = {
    aboutBiologicalAge: PropTypes.object,
    ageOverTimeGraph: PropTypes.object,
    isTinySmallDevice: PropTypes.bool,
    healthyRange: PropTypes.object,
    affectingFactors: PropTypes.object,
    relatedArticles: PropTypes.array,
    status: PropTypes.string,
    moreInfo: PropTypes.object,
    history: PropTypes.object.isRequired,
    jsonSchema: PropTypes.object,
    comparisonGraphData: PropTypes.object,
    howWorks: PropTypes.object,
    diseaseRisk: PropTypes.object
};
