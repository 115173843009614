export default ({ theme }) => (
  <svg height="20px" viewBox="0 0 20 20" width="20px">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={`${theme.header.nav_link_colour}`} fillRule="nonzero" transform="translate(-328.000000, -18.000000)">
        <g>
          <g transform="translate(328.000000, 18.000000)">
            <path d="M10,0 C4.48609375,0 0,4.48609375 0,10 C0,15.5139062 4.48609375,20 10,20 C15.5139062,20 20,15.5139062 20,10 C20,4.48609375 15.5139062,0 10,0 Z M10,2.91667969 C12.6419922,2.91667969 14.7916797,5.06632813 14.7916797,7.70835937 C14.7916797,10.3503906 12.6419922,12.5 10,12.5 C7.35800781,12.5 5.20832031,10.3503516 5.20832031,7.70832031 C5.20832031,5.06628906 7.35800781,2.91667969 10,2.91667969 Z M10,18.3333203 C7.27707031,18.3333203 4.8625,17.0147656 3.34089844,14.9889844 C5.00792969,14.186875 7.38859375,13.3333203 10,13.3333203 C12.6116016,13.3333203 14.9924609,14.1869922 16.6591406,14.9889062 C15.1375391,17.0147266 12.7229687,18.3333203 10,18.3333203 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
