import React from 'react';
import Axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';
import { SplitFactory } from '@splitsoftware/splitio';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import config from './config';
import { configureStore } from './store';
import SplitProvider from './components/context/splitProvider';
import '../sass/app.scss';
import Auth from './components/auth/auth';
import PartnerTheme from './containers/partner-theme/partnerTheme';
import { Theme } from './containers/commonTheming/commonTheme';

const { test_user, user, has_results, api_url, env, split_api_key, segment_id } = config.app;

//Polyfill smooth scroll (in utils.scrollToHash)
smoothscroll.polyfill();

//Setup all the default stuff for the API calls
Axios.defaults.baseURL = api_url;

Axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('access_token')}` };

Axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('access_token');
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

//Segment - identify user.analytics normally defined from window but in testing may not be defined
const analyticsObj = typeof window.analytics !== 'undefined' ? window.analytics : null;
if (analyticsObj && user) {
  analyticsObj.identify(user.id, {
    firstName: test_user ? test_user.fname : null,
    lastName: test_user ? test_user.lname.substring(0, 1) : null,
    gender: test_user && test_user.gender ? test_user.gender : null,
    hasResults: has_results,
    createdAt: user && user.created_at ? user.created_at.toString() : null,
  });
}

/*
    Create the correct react app depending on which section of the site we are on
*/

const getSplitKey = async () => {
  //wait till the promise is resolved
  return await new Promise(resolve => {
    if (user) {
      //resolve user.id if user is present
      resolve(user.id);
    } else if (analyticsObj) {
      //add anonymous id in analytics.ready method
      analyticsObj.ready(() => {
        //resolve anonymous id if the user is not present
        resolve(window.analytics.user().anonymousId());
      });
    } else {
      resolve('');
    }
  });
};

export const getSplitConfig = splitKey => {
  //return split configuration along with the split key
  return {
    core: {
      authorizationKey: split_api_key || 'localhost',
      //Required so use the anylatics id if not logged in
      key: splitKey,
    },
    scheduler: {
      //It pings constantly so set these high..
      featuresRefreshRate: 3600, // 1 hour in secs
      segmentsRefreshRate: 3600, // 1 hour in secs
      metricsRefreshRate: 3600, // 1 hour in secs
      impressionsRefreshRate: 3600, // 1 hour in secs
      eventsPushRate: 3600, // 60 sec
    },
    //Used by dev if no api key set
    features: {
      registration_v2: 'on', // feature to toggle new registration flow
      show_future_predictions: 'off', //feature to hide future predictions section
      show_compare_feature: 'on', //feature to hide compare section
      indicator_result_bar: 'on', //feature to hide healthy ranges on the indicator page
      results_improvments: 'on', //key to toggle result improvements section on indicator page
      transposons: 'off', //key to toggle the tracker and evolution pages
      results_diseases: 'on', //key to toggle the diseases section from the indicator page
      metabolic_page_v2: 'on', //key to toggle between the old and new designs of metabolic status
      alcohol_consumption_page_v2: 'on', //key to toggle between the old and new designs of alcohol consumption
      smoke_exposure_page_v2: 'on', //key to toggle between the old and new designs of smoke exposure
      epi_health_page_v2: 'on', //key to toggle the old and new designs of the you page
    },
  };
};

export const getSplitClient = () => {
  return getSplitKey().then(splitKey => {
    //pass split key to the split config and return split client
    return SplitFactory(getSplitConfig(splitKey)).client();
  });
};

const store = configureStore({});
function App() {
  return (
    <Provider store={store}>
      <SplitProvider>
        <BrowserRouter>
          <Helmet>
            {segment_id && (
              <script>
                {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segment_id}";analytics.SNIPPET_VERSION="4.15.2";
                    }}();`}
              </script>
            )}
          </Helmet>

          <Theme>
            <PartnerTheme>
              <Auth />
            </PartnerTheme>
          </Theme>
        </BrowserRouter>
      </SplitProvider>
    </Provider>
  );
}

export default App;
