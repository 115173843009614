export const CONTINENTAL_COLOR_MAP = {
    EUR: '#67bbb6',
    EAS: '#f49251',
    AMR: '#b894cf',
    AFR: '#68a3dd',
    SAS: '#c8d07b',
    defaultFill: '#dddddd',
};

export const MAPPED_LAT_LONG_VS_COUNTRY = {
    // Europea
    GBR: { center: { lng: -3, lat: 55 }, scaleFactor: 4 },
    FIN: { center: { lng: 26, lat: 65 }, scaleFactor: 4 },
    IBS: { center: { lng: -3, lat: 40 }, scaleFactor: 4 },
    CEU: { center: { lng: 18, lat: 60 }, scaleFactor: 1.5 },
    TSI: { center: { lng: 12, lat: 41 }, scaleFactor: 4 },

    // East Asian
    CHN: { center: { lng: 104, lat: 34 }, scaleFactor: 4 },
    KHV: { center: { lng: 108, lat: 14 }, scaleFactor: 4 },
    JPT: { center: { lng: 138, lat: 36 }, scaleFactor: 4 },

    // American
    PUR: { center: { lng: -66, lat: 18 }, scaleFactor: 5 },
    CLM: { center: { lng: -74, lat: 4 }, scaleFactor: 4 },
    PEL: { center: { lng: -77, lat: -10 }, scaleFactor: 4 },
    MXL: { center: { lng: -99, lat: 25 }, scaleFactor: 4 },

    // African
    ACB: { center: {lng: -76, lat: 15 }, scaleFactor: 2 },
    GWD: { center: {lng: -15, lat: 13 }, scaleFactor: 6 },
    ESN: { center: {lng: 8, lat: 9 }, scaleFactor: 4 },
    MSL: { center: {lng: -8, lat: 9 }, scaleFactor: 5 },
    YRI: { center: {lng: 8, lat: 9 }, scaleFactor: 4 },
    LWK: { center: {lng: 37, lat: -1 }, scaleFactor: 4 },
    ASW: { center: {lng: 34, lat: 8 }, scaleFactor: 2 },

    // South Asian
    PJL: { center: {lng: 78.9629, lat: 23.5937 }, scaleFactor: 4 },
    BEB: { center: {lng: 78.9629, lat: 23.5937 }, scaleFactor: 4 },
    STU: { center: {lng: 78.9629, lat: 23.5937 }, scaleFactor: 4 },
    ITU: { center: {lng: 78.9629, lat: 23.5937 }, scaleFactor: 4 },
    GIH: { center: {lng: 78.9629, lat: 23.5937 }, scaleFactor: 4 },
};
