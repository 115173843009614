import React from 'react';

const calculationData = {
    factors: [
        {
            title: 'Weight loss and the yo-yo effect',
            text: 'It’s well known that it’s hard to lose weight and even harder to keep it off. Around 80% of us who succeed in dropping pounds will see them creep back on within 12 months. We can blame this yo-yo effect on several factors: lower resting metabolic rate, changes in hormones related to weight management and alterations in fat tissue or inflammatory responses. ',
            text2: 'This means that watching the scales won’t necessarily tell you if your metabolism is heading in the right direction. Epigenetic changes happen slower than weight changes and also take into account the biological mechanisms at play, giving you a clearer idea of whether you’re on the path to reducing your metabolic risk.',
            image_url: '/img/you/actionable-you/metabolic-status/yo-yo-dieting.png',
            legends: {bmi: 'BMI measurement', epigenetic_metabolism: 'Epigenetic measurement'},
            read_more_title: 'Read more about the ‘yo-yo effect’',
            read_more_link: 'https://www.nature.com/articles/s41591-019-0632-y',
        },
        {
            title: 'Metabolic memory',
            text: 'Our BMI can easily change depending on what the scales read on that day, but metabolic status measures our ‘adiposity history’. Epigenetics doesn’t show a momentary snapshot but instead captures the overall metabolic risk by looking at how long we’ve lived with a disrupted metabolism. This graph shows how the two measures compare over time.',
            image_url: '/img/you/actionable-you/metabolic-status/metabolic-memory.png',
            legends: {bmi: 'BMI measurement', epigenetic_metabolism: 'Epigenetic measurement'},
            read_more_title: 'Read more about ‘obesity-years’',
            read_more_link: 'https://academic.oup.com/ije/article/40/4/996/683932',
            
        },
    ]
};

const MoreInfo = ({breakpoint}) => {

    const { factors, } = calculationData;
    const isMobile = !(breakpoint == 'large' || breakpoint == 'medium');
    
    return (
        <div className="biological-age__result-calculation__container">
            <div className="biological-age__section-container">
                {
                    factors.map((factor, index) => (
                        <div className="result-calculation-container" key={index}>
                            <h5 className="indicator-section-title inside-title">{factor.title}</h5>
                            <div className="result-calculation-section">
                                <div className="result-calculation-information">
                                    <p className="indicator-section-description">{factor.text}</p>
                                    {
                                        !isMobile ?
                                            <div>
                                                {factor.text2 &&

                                                <p className="indicator-section-description"><br />{factor.text2}</p>
                                                }
                                                {factor.read_more_title && factor.read_more_link &&
                                                <p className="indicator-section-description"><br /><a href={factor.read_more_link} target="_blank" rel="noreferrer">{factor.read_more_title}</a></p>
                                                }
                                            </div> : ''
                                    }
                                </div>
                                <div className="result-calculation-image">
                                    <img alt={factor.title.replace(/ /, '-')} src={factor.image_url} />
                                    <div className="legend-section">
                                        {
                                            Object.keys(factor.legends).map((key) => (
                                                <div className="single-legend" key={key}>
                                                    <div className={`legend-type ${key}`} />
                                                    <p className="legend-name">{factor.legends[key]}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                isMobile ?
                                    <div className="result-calculation-section">
                                        <div className="result-calculation-information">
                                            {factor.text2 && <p className="indicator-section-description no-margin">{factor.text2}</p>}
                                            {factor.read_more_title && factor.read_more_link &&
                                            <p className="indicator-section-description no-margin"><a href={factor.read_more_link} target="_blank" rel="noreferrer">{factor.read_more_title}</a></p>
                                            }
                                        </div>
                                    </div> : ''
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default MoreInfo;