import axios from 'axios';
import React, { Component } from 'react';
import { validated } from 'react-custom-validation';
import PhoneInput from '../common/phoneInput';

import validationRules from '../helpers/validationRules';
import AddressCard from './addressCard';
import {Link} from 'react-router-dom';
import ShippingCountries from './shippingCountries';
import utils from '../helpers/utils';
import States from './states';
import { fetchAddresses } from '../../services/you/addressLookup';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

const getLabelValidClassName = (field, additionalClassNames=[]) => {
    return [
        'form__label',
        ...additionalClassNames,
        field && 'valid'
    ].filter(xs => Boolean(xs)).join(' ');
};

function shippingValidationConfig(props) {
    const {
        email,
        mobile_country,
        mobile,
        delivery_fname,
        delivery_lname,
        delivery_address1,
        delivery_city,
        delivery_county,
        delivery_postcode,
        delivery_country
    } = props.fields;
    return {
        fields: ['email', 'mobile', 'delivery_fname', 'delivery_lname', 'delivery_address1', 'delivery_address2',
            'delivery_city', 'delivery_county', 'delivery_postcode', 'delivery_country'],
        validations: {
            email: [
                [validationRules.isRequired, email],
                [validationRules.isEmail, email],
            ],
            mobile: [
                [validationRules.isRequired,mobile]
            ],
            delivery_fname: [
                [validationRules.isRequired, delivery_fname],
                [validationRules.isName, delivery_fname]
            ],
            delivery_lname: [
                [validationRules.isRequired, delivery_lname],
                [validationRules.isName, delivery_lname]
            ],
            delivery_address1: [
                [validationRules.isRequired, delivery_address1]
            ],
            delivery_address2: [],
            delivery_city: [
                [validationRules.isRequired, delivery_city]
            ],
            delivery_county: [
                [validationRules.isCountyRequired, delivery_county, delivery_country],
                [validationRules.isValidStateCode, delivery_county, delivery_country]
            ],
            delivery_postcode: [
                [validationRules.isRequired, delivery_postcode],
                [validationRules.isPostCode, delivery_postcode, delivery_country]
            ],
            delivery_country: [
                [validationRules.isRequired, delivery_country],
            ]
        },
    };
}

const getShippingCountries = async({ setState, suk, UpdateState }) => {
    try {
        const { data: { data: shippingCountries } } = await axios.get(`products/shipping_countries?suk=${suk}`);
        setState({ shippingCountries });

        const isShippableToGB = shippingCountries.some((country) => country.country_code === 'GB');
        UpdateState({ isShippableToGB });
    } catch(error) {
        console.error(utils.getFirstApiError(error, 'Error retrieving shipping countries'));
    }
};

/**
 * Constructs an email error message either
 * through react-custom-validation library's
 * validation result or by checking the validity
 * of email domain through Levenshtein distance
 * algorithm and suggesting the email address
 * with corrected domain.
 * 
 * Note that email domain validation is performed
 * outside of react-custom-validation because
 * domain validation shouldn't prevent form
 * submission and is just producing a warning
 * message.
 * 
 * @param {*} $validation react-custom-validation lib's validation result
 * @param {*} email 
 * @returns error message if email is not valid
 */
function getEmailError($validation, email) {
    const showError = $validation.email.show;
    const invalidEmailError = $validation.email.error.reason;
    if (showError && invalidEmailError) {
        return invalidEmailError;
    }
    const invalidDomainError = validationRules.isEmailDomainValid(email?.toLowerCase());
    if (showError && invalidDomainError) {
        return invalidDomainError;
    }
    return ''; 
}

class Shipping extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.searchPostCode = this.searchPostCode.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.manuallyEnterAddress = this.manuallyEnterAddress.bind(this);

        this.state = {
            loading: false,
            selectedAddress: undefined,
            addressData: undefined,
            addressError: undefined,
            postCodeFilter: '',
            shippingCountries: [],
            showAddress: false
        };
    }

    componentDidMount() {
        if (this.props.variation?.suk) {
            const { UpdateState } = this.props;
            getShippingCountries({ setState: (props) => this.setState(props), UpdateState, suk: this.props.variation.suk });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { variation: previousVariation } = prevProps;
        const { variation } = this.props;

        if (previousVariation?.suk !== variation?.suk) {
            getShippingCountries({ setState: (props) => this.setState(props), suk: this.props.variation.suk });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const {$submit, onInvalid, onValid, } = this.props;
        $submit(() => onValid(null, 'account'), onInvalid);
    }

    searchPostCode() {
        const { fields } = this.props;
        this.setState({ addressData: undefined, addressError: undefined, loading: true });
        fetchAddresses(fields.delivery_country, this.state.postCodeFilter)
            .then(data => this.setState({ addressData: data, loading: false }))
            .catch(error => this.setState({ loading: false, addressError: error }));
    }

    manuallyEnterAddress() {
        this.setState({ showAddress: true });
    }

    updateAddress(value) {
        const { addressData } = this.state;
        const { onUpdateAddress } = this.props;
        const selectedAddress = addressData.find(({ summaryline }) => summaryline === value);
        this.setState({ selectedAddress: value, showAddress: true });
        if (onUpdateAddress) {
            onUpdateAddress(selectedAddress);
        }
    }

    onEdit() {
        const { UpdateState, history } = this.props;
        const sectionsComplete = {
            account: false,
            delivery: false,
            payment: false
        };
        if (UpdateState) {
            UpdateState({ sectionsComplete });
        }
        if (history) {
            history.push('/join/account');
        }
    }

    render() {
        const {
            branding,
            isCompleted,
            hideStepNumber,
            fields,
            $validation,
            onChange,
            countrys,
            submitError,
            calculatePostageCosts = () => {},
            shippingMethod,
            hasTravelSection,
            submitButtonText,
            isContactInfoDisabled,
            isLoggedIn
        } = this.props;

        const partnerBranding = cachedPartnerTheming();

        const getBrandingStyle = () => (branding && branding.hasBranding && branding.section) ? {
            color: branding.section.highlight_colour,
        } : {};

        const getBrandingButtonStyle = () => {
          if (partnerBranding || branding?.hasBranding) {
            const theme = partnerBranding ? partnerBranding.theme : branding;
            return { background: theme.section.cta_colour };
          }
          return {};
        };

        const isShippingToUS = fields.delivery_country === 'US';

        return <div className="checkout new-checkout-flow">
                <div className="checkout__form-col order-summary">
                    <h4 className="checkout__title checkout__title" style={getBrandingStyle()}>
                        1. Shipping
                        {!hideStepNumber &&
                            <span className="checkout__title--orderstep" onClick={() => isCompleted && this.onEdit()} style={getBrandingStyle()}>
                                {isCompleted  ? 'EDIT' : (shippingMethod === 'default' ? 'Step 1 of 2' : 'Step 1 of 3')}
                            </span>
                        }
                    </h4>
                    <form className="form checkout-form" onSubmit={this.onSubmit}>
                        <div className="card-wrapper slide">
                            <div className="card-container">
                                <div className="card-container__card-display">
                                    <div>Contact Info</div>
                                    {!isCompleted && <div className="card-container__field-information">Your contact information will only be used to contact you about the order</div>}
                                </div>
                                {((isCompleted || isContactInfoDisabled) && <div>
                                        <div>{fields.email}</div>
                                        <div>
                                            {fields.mobile}
                                        </div>
                                    <div className="card-container__field-information">These can be changed in <Link to={'/dashboard/account'}>My Account</Link></div>
                                                                            </div> ) || <div className="card-container__card-info">
                                        <div className="input-label-container">
                                            <input
                                                type="email"
                                                value={fields.email}
                                                name="email"
                                                id="email"
                                                onChange={(e) => onChange('email', e.target.value)}
                                                onBlur={() => this.props.$fieldEvent('blur', 'email')}
                                                disabled={isLoggedIn}
                                            />
                                            <label htmlFor="email" className={getLabelValidClassName(fields.email)}>
                                                Email
                                            </label>
                                            <div data-testid="email_error" className="form__error">
                                                {getEmailError($validation, fields.email)}
                                            </div>
                                        </div>
                                        <div className="input-label-container mobile-input">
                                            <PhoneInput
                                                value={fields.mobile || ''}
                                                onChange={onChange}
                                                onBlur={() => this.props.$fieldEvent('blur', 'mobile')}
                                                countries={countrys}
                                                country={fields.delivery_country}
                                            />
                                            <label htmlFor="mobile" className={getLabelValidClassName(fields.mobile, ['mobile-label'])}>
                                                Mobile number
                                            </label>
                                            <div className="form__error">
                                                {$validation.mobile.show ? $validation.mobile.error.reason : ''}
                                            </div>
                                        </div>
                                                                                        </div>}
                            </div>
                            <div className="card-container">
                                <div className="card-container__card-display">
                                    <div>Shipping Address</div>
                                    {hasTravelSection && <div className="card-container__field-information">This should be the address that you will receive tests before and/or after arrival back in the UK, or the address you are self-isolating at.</div>}
                                </div>
                                {(isCompleted && <div>
                                    <AddressCard fields={fields} countrys={countrys} />
                                                 </div>) || <div className="card-container__card-info">
                                        <div className="form__row form__row--2col">
                                            <div className="form__col2">
                                                <div className="input-label-container">
                                                    <input
                                                        type="text"
                                                        value={fields.delivery_fname}
                                                        name="delivery_fname"
                                                        id="delivery_fname"
                                                        onChange={(e) => onChange('delivery_fname', e.target.value)}
                                                        onBlur={() => this.props.$fieldEvent('blur', 'delivery_fname')}

                                                    />
                                                    <label htmlFor="delivery_fname" className={getLabelValidClassName(fields.delivery_fname)}>
                                                        First name
                                                    </label>
                                                    <div className="form__error">
                                                        {$validation
                                                            .delivery_fname
                                                            .show
                                                            ? $validation
                                                                .delivery_fname
                                                                .error
                                                                .reason
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__col2">
                                                <div className="input-label-container">
                                                    <input
                                                        type="text"
                                                        value={fields.delivery_lname}
                                                        name="delivery_lname"
                                                        id="delivery_lname"
                                                        onChange={(e) => onChange('delivery_lname', e.target.value)}
                                                        onBlur={() => this.props.$fieldEvent('blur', 'delivery_lname')}
                                                    />
                                                    <label htmlFor="delivery_lname" className={getLabelValidClassName(fields.delivery_lname)}>
                                                        Last name
                                                    </label>
                                                    <div className="form__error">
                                                        {$validation
                                                            .delivery_lname
                                                            .show
                                                            ? $validation
                                                                .delivery_lname
                                                                .error
                                                                .reason
                                                            : ''}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="address-search-container">
                                            <div className="address-caption">
                                                <span>Search your address using the country and zip/post code below.</span>
                                            </div>
                                            <div className="form__row form__row--2col">
                                                <div className="form__col2">
                                                    <div className="input-label-container">
                                                        <ShippingCountries fields={fields} onBlur={this.props.$fieldEvent} shippingCountries={this.state.shippingCountries} calculatePostageCosts={calculatePostageCosts} onChange={onChange} />
                                                        <label htmlFor="delivery_country" className={getLabelValidClassName(fields.delivery_country)}>
                                                            Country
                                                        </label>
                                                        { fields.delivery_country === 'GB' && <div className="ni-warning">Please note: we are unable to ship to Northern Ireland</div> }
                                                        <div className="form__error">
                                                            {$validation
                                                                .delivery_country
                                                                .show
                                                                ? $validation
                                                                    .delivery_country
                                                                    .error
                                                                    .reason
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__col2">
                                                    <div className="input-label-container">
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="search_postcode"
                                                                id="search_postcode"
                                                                value={this.state.postCodeFilter}
                                                                onChange={e => this.setState({ postCodeFilter: e.target.value })}
                                                            />
                                                            <div className="primary-text-input-button" style={getBrandingButtonStyle()} onClick={this.searchPostCode}>
                                                                Go
                                                            </div>
                                                        </div>
                                                        <label htmlFor="search_postcode" className={getLabelValidClassName(this.state.postCodeFilter)}>
                                                            { ['US', 'CA'].indexOf(fields.delivery_country) > -1 ? 'Zip Code':'Post Code'}
                                                        </label>
                                                        <div className="form__error">
                                                            {$validation
                                                                .delivery_postcode
                                                                .show
                                                                ? $validation
                                                                    .delivery_postcode
                                                                    .error
                                                                    .reason
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="input-label-container">
                                                {this.state.addressData ? (
                                                    <>
                                                        <div className="form__select-wrapper">
                                                            <select
                                                                name="address"
                                                                id="address"
                                                                disabled={!this.state.addressData}
                                                                value={this.state.selectedAddress}
                                                                onChange={(e) => this.updateAddress(e.target.value)}
                                                            >
                                                                <option key="" value=""></option>
                                                                {
                                                                    this.state.addressData && this.state.addressData.map(address => (
                                                                        <option  key={address.summaryline} value={address.summaryline}>{address.summaryline}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <i className="icon-down-open" />
                                                        </div>
                                                        <label htmlFor="address" className={getLabelValidClassName(this.state.selectedAddress)}>
                                                            Search and select an address to prefill
                                                        </label>
                                                        <div className="form__error">
                                                            {this.state.addressData && this.state.addressData.length === 0 ? 'No addresses found matching, please manually enter an address' : null}
                                                        </div>
                                                    </>
                                                ) : null}

                                                {!this.state.showAddress ? (
                                                    <div className="input-label-container">
                                                        <div className="link-container">
                                                            <span>OR</span>
                                                            <a onClick={this.manuallyEnterAddress}>Manually enter your address</a>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>

                                            {!this.state.showAddress && $validation.delivery_address1.show ? (
                                                <div className="form__error">
                                                    Please search and select OR manually provide your delivery addresss.
                                                </div>
                                            ) : null}
                                        </div>

                                        <div style={{ display: this.state.showAddress || this.state.addressError ? 'block' : 'none', paddingTop: 10 }}>
                                            <div className="input-label-container">
                                                <input
                                                    type="text"
                                                    value={fields.delivery_address1}
                                                    name="delivery_address1"
                                                    id={fields.delivery_address1}
                                                    onChange={(e) => onChange('delivery_address1', e.target.value)}
                                                    onBlur={() => this.props.$fieldEvent('blur', 'delivery_address1')}
                                                    aria-labelledby="delivery_address1"
                                                />
                                                <label htmlFor="delivery_address1" id="delivery_address1" className={getLabelValidClassName(fields.delivery_address1)}>
                                                    Address 1
                                                </label>
                                                <div className="form__error">
                                                    {$validation
                                                        .delivery_address1
                                                        .show
                                                        ? $validation
                                                            .delivery_address1
                                                            .error.reason
                                                        : ''}
                                                </div>
                                            </div>

                                            <div className="input-label-container">
                                                <input
                                                    type="text"
                                                    value={fields.delivery_address2}
                                                    name="delivery_address2"
                                                    id={fields.delivery_address2}
                                                    onChange={(e) => onChange('delivery_address2', e.target.value)}
                                                    onBlur={() => this.props.$fieldEvent('blur', 'delivery_address2')}
                                                />
                                                <label htmlFor="delivery_address2" className={getLabelValidClassName(fields.delivery_address2)}>
                                                    Address 2 (optional)
                                                </label>
                                                <div className="form__error">
                                                    {$validation
                                                        .delivery_address2
                                                        .show
                                                        ? $validation
                                                            .delivery_address2
                                                            .error.reason
                                                        : ''}
                                                </div>
                                            </div>

                                            <div className="input-label-container">
                                                <input
                                                    type="text"
                                                    value={fields.delivery_city}
                                                    name="delivery_city"
                                                    id={fields.delivery_city}
                                                    onChange={(e) => onChange('delivery_city', e.target.value)}
                                                    onBlur={() => this.props.$fieldEvent('blur', 'delivery_city')}
                                                    aria-labelledby="delivery_city"
                                                />
                                                <label htmlFor="delivery_city" id="delivery_city" className={getLabelValidClassName(fields.delivery_city)}>
                                                    City
                                                </label>
                                                <div className="form__error">
                                                    {$validation
                                                        .delivery_city.show
                                                        ? $validation
                                                            .delivery_city
                                                            .error.reason
                                                        : ''}
                                                </div>
                                            </div>

                                            { isShippingToUS &&
                                              <div className="input-label-container">
                                                <States fields={fields} onBlur={this.props.$fieldEvent} onChange={onChange} />
                                                <label htmlFor="delivery_county" className={getLabelValidClassName(fields.delivery_county)}>
                                                    State
                                                </label>
                                                <div className="form__error">
                                                    {$validation
                                                        .delivery_county
                                                        .show
                                                        ? $validation
                                                            .delivery_county
                                                            .error
                                                            .reason
                                                        : ''}
                                                </div>
                                                <p/>
                                              </div>}

                                            <div className="form__row form__row--2col">
                                                <div className="form__col2">
                                                    <div className="input-label-container">
                                                        <input
                                                            type="text"
                                                            value={fields.delivery_postcode}
                                                            name="delivery_postcode"
                                                            id={fields.delivery_postcode}
                                                            data-testid="delivery_postcode"
                                                            onChange={(e) => onChange('delivery_postcode', e.target.value)}
                                                            onBlur={() => this.props.$fieldEvent('blur', 'delivery_postcode')}
                                                        />
                                                        <label htmlFor="delivery_postcode" className={getLabelValidClassName(fields.delivery_postcode)}>
                                                            { ['US', 'CA'].includes(fields.delivery_country) ? 'Zip Code':'Post Code'}
                                                        </label>
                                                        <div className="form__error">
                                                            {$validation
                                                                .delivery_postcode
                                                                .show
                                                                ? $validation
                                                                    .delivery_postcode
                                                                    .error
                                                                    .reason
                                                                : ''}
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="form__col2">
                                                    <div className="input-label-container">
                                                        <ShippingCountries fields={fields} onBlur={this.props.$fieldEvent} shippingCountries={this.state.shippingCountries} calculatePostageCosts={calculatePostageCosts} onChange={onChange} />
                                                        <label htmlFor="delivery_country" className={getLabelValidClassName(fields.delivery_country)}>
                                                            Country
                                                        </label>
                                                        <div className="form__error">
                                                            {$validation
                                                                .delivery_country
                                                                .show
                                                                ? $validation
                                                                    .delivery_country
                                                                    .error
                                                                    .reason
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form__row">
                                            <button className="btn checkout__btn" style={getBrandingButtonStyle()} type="submit">
                                                {submitButtonText}
                                                <i className="icon-right-open" />
                                            </button>
                                            {submitError && <div className="form__error form__error--general form__error--start">
                                                Please complete the
                                                errors above
                                                            </div>}
                                        </div>
                                                            </div>}
                            </div>
                        </div>
                    </form>
                </div>
               </div>;
    }
}

export default validated(shippingValidationConfig)(Shipping);
export { Shipping as __TEST_Shipping };
