import React from 'react';
import { Link } from 'react-router-dom';
import utils from '../helpers/utils';

const HeroCTAButtons = () => {
    return (
        <div className="hero-buttons__container">
            <Link className="btn btn--yellow" to="/dashboard/improvements/team" onClick={()=> utils.track('Talk to health team', {position: 'Hero'})}>Talk to your Health Team</Link>
        </div>
    );
};

export default HeroCTAButtons;
