import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
   This is generic component wrapper component that provide support to stick the
   component to the top of the view port

   Usage:
   <StickToPosition>
      <div> Add stickable component here... </div>
   </StickToPosition>
 */
class StickToPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStickAtPosition: false,
            height: 0,
        };
        this.handleScroll = this.handleScroll.bind(this);

        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        this.setState({ height: this.stickyWrapper.clientHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { isStickable, stickAtPosition } = this.props;
        const { isStickAtPosition } = this.state;
        const { top } = this.stickyWrapper.getBoundingClientRect();

        if (isStickable && top < stickAtPosition) {
            !isStickAtPosition && this.setState({ isStickAtPosition: true});
        } else {
            isStickAtPosition && this.setState({ isStickAtPosition: false});
        }
    }

    render() {
        const { height } = this.state;
        return (
            <div id="sticky-wrapper" ref={element => { this.stickyWrapper = element; }}>
                {/* Added dummy element to avoid glitcher effect */}
                {this.state.isStickAtPosition && <div style={{height}}/>}
                <div className={`${this.state.isStickAtPosition ? 'sticky-wrapper__item' : ''}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

StickToPosition.propTypes = {
    isStickable: PropTypes.bool,
    stickAtPosition: PropTypes.number,
    children: PropTypes.object,
};

StickToPosition.defaultProps = {
    isStickable: false,
    stickAtPosition: 0,
    children: null,
};

export default StickToPosition;
