import React, { Component } from 'react';

class AccordionNew extends Component{
    constructor(){
        super();
        this.state = {
            isActive: false,
        };
        this.toggleAccordion = this.toggleAccordion.bind(this);
    }

    toggleAccordion(){
        const {isActive} = this.state;
        this.setState({isActive: !isActive});
    }


    render(){
        const {title, content} =this.props;
        const {isActive} = this.state;
        return(
            <div className="accordion-section">
                    <div className="accordion-section__title-button" onClick={this.toggleAccordion}>
                        <p className="title">
                            {title}
                        </p>
                        <div className={!isActive ? 'symbol' : 'symbol-rotate'}>
                            <svg width="18px" height="12px" viewBox="0 0 21 14" version="1.1">
                                <title>@2xFill 1</title>
                                <desc>Created with Sketch.</desc>
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Testimonial-04" transform="translate(-1069.000000, -199.000000)" fill="#444444" stroke="#444444" strokeWidth="1.26640602">
                                        <g id="Group" transform="translate(295.000000, 164.000000)">
                                            <polygon id="Fill-1" transform="translate(784.276692, 42.114286) scale(1, -1) rotate(90.000000) translate(-784.276692, -42.114286) " points="779 42.1142857 788.410697 51.2285714 789.553383 50.0768654 781.310602 42.1142857 789.553383 34.1570823 788.410697 33"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="accordion-section__content" style={{maxHeight: !isActive ? '0px' : '500px' }}>
                        <div className="accordion-text" dangerouslySetInnerHTML={{__html: content}} /> 
                    </div>
            </div>
        );
    }
}

export default AccordionNew;