import React from 'react';
import moment from 'moment';
import config from '../../config';
import Loader from '../../components/common/loader';
import { TEST_COLOR } from './PastResultsPanels';
import { Spinner } from '../../../icons';
import { StyledButton } from '../../theming/themed-components';

export const LatestResultPanel = ({ history, result, onDownload, isLoading, isTtrTest = false, showView = true, spinnerDisplay }) => {
  const handleView = () => history.push(`/dashboard/covid/result/${result.id}`);
  const handleNext = () => history.push('/dashboard/covid/test-timeline/select-test');
  const registered_at =
    result && result.registered_at_timezone === null
      ? moment(result.registered_at).format('DD/MM/YY h:mm a')
      : result && result.registered_at_timezone !== null
      ? moment(result.localised_registered_at).format('DD/MM/YY h:mm a')
      : null;
  const timezone =
    result && result.registered_at_timezone !== null
      ? ' (GMT ' + (result.registered_at_timezone < 0 ? '' : '+') + result.registered_at_timezone + ')'
      : '';
  const lab_processed_at =
    result && result.lab_processed_at && result.registered_at_timezone === null
      ? moment(result.lab_processed_at).format('DD/MM/YY h:mm a')
      : result && result.lab_processed_at && result.registered_at_timezone !== null
      ? moment(result.localised_lab_processed_at).format('DD/MM/YY h:mm a')
      : undefined;
  const { can_promote_to } = config.app;

  const displaySpin = spinnerDisplay ? 'block' : 'none';
  return (
    <div className="test-dashboard-section__container full-width">
      <div className="content-container">
        <div className="section-content">
          <div className="section-pre-title">Latest result</div>
          {!result && !isLoading ? <div className="section-empty">Nothing to show</div> : null}
          {!result && isLoading ? <Loader /> : null}

          {result ? (
            <>
              <div className="split-row-group">
                {showView && lab_processed_at ? (
                  <div className="icon-row" onClick={handleView} title="View your test result">
                    <img src="/img/eye.svg" alt="" />
                    View Details
                  </div>
                ) : null}
                <div className="icon-row" onClick={() => onDownload(result)} title="Download your text">
                  <span>
                    <img src="/img/download.svg" alt="" />
                  </span>
                  <span>Download</span>
                  <span id="spinner" style={{ width: '25px', display: displaySpin }}>
                    <Spinner />
                  </span>
                </div>
              </div>

              <div
                className={`test-card ${isTtrTest ? 'ttr-card' : ''}`}
                style={!isTtrTest ? { backgroundColor: TEST_COLOR[result.result.toLowerCase()] || TEST_COLOR.default } : {}}
                onClick={handleView}
              >
                <img src={isTtrTest ? '/img/test/logo_black.svg' : '/img/test/logo.svg'} alt="" />
                <div className="split-row-group split-end">
                  <div>
                    <div className="lighten-text">{result.test_user_full_name}</div>
                    <div
                      className="section-large-title"
                      style={
                        isTtrTest
                          ? { marginTop: 5, color: TEST_COLOR[result.result.toLowerCase()] || TEST_COLOR.default }
                          : { marginTop: 5 }
                      }
                    >
                      {result.result}
                    </div>
                  </div>
                  <div>
                    <div className="lighten-text-small">Collection Date</div>
                    <div className="section-date" style={{ marginTop: 5 }}>
                      {registered_at}
                      {timezone}
                    </div>
                  </div>
                </div>
              </div>

              {can_promote_to ? (
                <StyledButton className="btn btn--full-width" style={{ marginTop: 30 }} onClick={handleNext} type="button">
                  Buy another test
                </StyledButton>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
