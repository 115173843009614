import React from 'react';
import { withRouter } from 'react-router-dom';

/*
    Scroll to top on react router page navigation
*/
class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {
        //Scroll to top, unless its just a hash change
        const pathname = this.props.location.pathname;

        if (pathname !== prevProps.location.pathname) {
            const topScrollPosition = sessionStorage.getItem(this.props.location.pathname) || 0;
            window.scrollTo(0, topScrollPosition);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
