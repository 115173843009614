import * as actionTypes from './constants';
import { LogoAlignment } from '../../constants/default-theme';
import { NAV_LINK_COLOUR } from '../../theming/themed-components';

const initialState = {
  loading: true,
  error: undefined,
  hasBranding: false,
  header: undefined,
  section: undefined,
  footer: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SITE_BRANDING_REQUEST:
      return {
        ...state,
        loading: true,
        hasBranding: false,
      };

    case actionTypes.FETCH_SITE_BRANDING_SUCESS: {
      return {
        ...state,
        isAffiliate: true,
        base_font_size: 16,
        loading: false,
        hasBranding: true,
        header: {
          ...action.params.header,
          // Affiliate logo should be aligned left by default
          logo_alignment: LogoAlignment.START,
        },
        section: action.params.section,
        footer: {
          ...action.params.footer,
          hide_copyright: false,
        },
      };
    }

    case actionTypes.FETCH_SITE_BRANDING_FAILURE:
      return {
        ...state,
        loading: false,
        hasBranding: false,
        error: action.params,
      };

    case actionTypes.FETCH_PARTNER_BRANDING_REQUEST:
      return {
        ...state,
        loading: true,
        hasBranding: false,
      };

    case actionTypes.FETCH_PARTNER_BRANDING_SUCCESS: {
      const branding = action.params;
      return {
        ...state,
        ...mapBrandingToState(branding),
      };
    }

    case actionTypes.FETCH_PARTNER_BRANDING_FAILURE: {
      return {
        ...state,
        loading: false,
        hasBranding: false,
        error: action.params,
      };
    }

    default:
      return state;
  }
};

export const mapBrandingToState = branding => {
  return {
    loading: false,
    hasBranding: Boolean(branding),
    base_font_size: 16,
    header: {
      bg_colour: branding.headerBackground,
      logo_url: branding.headerLogoUrl,
      nav_link_colour: branding.navLinkColour === NAV_LINK_COLOUR.DARK ? '#333333' : '#ffffff',
    },
    section: {
      cta_colour: branding.primaryColour,
      background: branding.pageBackground,
    },
    footer: {
      colour: branding.footerBackground,
      text_colour: branding.footerTextColour,
      powered_by: true,
      hide_copyright: true,
    },
  };
};
