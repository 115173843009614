import React from 'react';
import styled from 'styled-components';
import arrow from '../../../../../img/indicator-arrow.svg';

const ArrowContainer = styled.div`
  position: relative;
  height: 1.5em;
`;

const Arrow = styled.img`
  position: absolute;
  height: 1.5em;
  width: 10px;
  margin-left: -5px;
  left: ${props => props.barPercentage}%;
`;

const BarWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const BarContainer = styled.div`
  width: ${props => props.widthPercentage}%;
`;

const IndividualBar = styled.div`
  height: 0.4em;
  border-radius: 0.3em;
  background-color: ${props => props.barColor};
`;

const IndividualBarValues = styled.div`
  display: flex;
`;

const IndividualBarValue = styled.span`
  flex: 1;
  text-align: right;
  font-size: 0.8em;
  margin-right: ${props => props.marginOffset}rem;
`;

const Bars = ({ barConfig }) => {

  const { resultValues, rangeValues } = barConfig;

  return (
    <>
      <ArrowContainer>
        <Arrow data-testid="resultArrow" barPercentage={resultValues.barPercentage} src={arrow} alt={'Result Arrow'} />
      </ArrowContainer>
      <BarWrapper data-testid="barWrapper">
        {rangeValues.map(({ barPercentage, rangeName, range, barColor }, i) => (
          <BarContainer key={`bar_percent_${barPercentage}_${i}`} widthPercentage={barPercentage}>
            <IndividualBar 
              barColor={barColor}
              title={rangeName} 
            />
            <IndividualBarValues>
              {rangeValues.length - 1 != i && (
                <IndividualBarValue marginOffset={-range[range.length - 1].toString().length * 0.2}>
                  {range[range.length - 1]}
                </IndividualBarValue>
              )}
            </IndividualBarValues>
          </BarContainer>
        ))}
      </BarWrapper>
    </>
  );
};

export default Bars;
