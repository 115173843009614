import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import utils from '../../helpers/utils';
import withErrorBoundary from '../../common/errorBoundary';
import getAccessToken from '../getAccessToken';
import { LoginForm } from './loginFormFields';
import { StyledAuthBackground } from '../../../theming/themed-components';
import { withTheme } from 'styled-components';
import { commonErrors, loginErrors } from '../../../constants';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: '',
        password: '',
      },
      disableSubmitButton: false,
      submitError: false,
      submitErrorMessage: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormValid = this.handleFormValid.bind(this);
    this.handleFormInvalid = this.handleFormInvalid.bind(this);
  }

  componentDidMount() {
    if (utils.isIEBrowser()) {
      this.setState(() => {
        throw new Error('Sorry this browser is not supported. Please upgrade');
      });
    }
  }

  handleInputChange(field, value) {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  }

  offlineOrNetworkError(error) {
    return !navigator.onLine || error.message === commonErrors.NetworkError;
  }

  handleFormValid() {
    this.setState({ submitError: false, submitErrorMessage: '', disableSubmitButton: true });
    this.setState({ disableSubmitButton: true });

    getAccessToken({
      email: this.state.fields.email,
      password: this.state.fields.password,
      onSuccess: () => {
        const redirectTo = this.props.location?.state?.referrer ? 
          `${this.props.location.state.referrer.pathname}${this.props.location.state.referrer.search}` : 
          '/dashboard';
        // TODO: Keep only user data in local storage, remove what is not needed and keep it in state.
        // After that is done, this window.location.href can be replaced with react router navigation.
        window.location.href = redirectTo;
      },
      onError: error => {
        const defaultMessage = this.offlineOrNetworkError(error) ? commonErrors.Offline : loginErrors.Default;
        this.setState({
          disableSubmitButton: false,
          submitError: true,
          submitErrorMessage: utils.getFirstApiError(error, defaultMessage),
        });
      },
    });
  }

  handleFormInvalid() {
    this.setState({ submitError: true });
  }

  render() {
    const { submitError, submitErrorMessage } = this.state;

    // If already logged in redirect to dashboard
    if (utils.userIsLoggedIn()) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <StyledAuthBackground theme={this.props.theme} className="registration__wrapper">
        <div className="registration__container">
          <LoginForm
            fields={this.state.fields}
            onChange={this.handleInputChange}
            onInvalid={this.handleFormInvalid}
            onValid={this.handleFormValid}
            submitError={submitError}
            submitErrorMessage={submitErrorMessage}
          />
        </div>
      </StyledAuthBackground>
    );
  }
}

export { Login as __TEST_Login };
export default withRouter(withErrorBoundary(withTheme(Login)));
