import React, { Component } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, 
        LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import PercentageBar from '../common/percentageBar';
import withErrorBoundary from '../common/errorBoundary';

const overallScore = {month: 5.33, year: 4.25, alltime: 6.78};
const overallScorePercentage = {month: 75, year: 56, alltime: 65};

const pieData = {month:[
                                {name: 'Studies', value: 200}, 
                                {name: 'Data Points', value: 400},
                                {name: 'Publications', value: 100}, 
                                {name: 'Other', value: 300}
                            ],
                            year: [
                                {name: 'Studies', value: 400}, 
                                {name: 'Data Points', value: 300},
                                {name: 'Publications', value: 300}, 
                                {name: 'Other', value: 200}
                            ],
                            alltime: [
                                {name: 'Studies', value: 100}, 
                                {name: 'Data Points', value: 350},
                                {name: 'Publications', value: 200}, 
                                {name: 'Other', value: 500}
                            ]};

const COLORS = ['#d2335c', '#9dbf16', '#0082d5', '#ff9948'];

const lineData = {  studies: [
                          {name: 'Jan 2018', score: 2},
                          {name: 'Feb 2018', score: 5},
                          {name: 'March 2018', score: 4},
                          {name: 'April 2018', score: 8},
                    ],
                    datapoints: [
                          {name: 'Jan 2018', score: 36},
                          {name: 'Feb 2018', score: 45},
                          {name: 'March 2018', score: 55},
                          {name: 'April 2018', score: 57},
                    ],
                    publications: [
                          {name: 'Jan 2018', score: 0},
                          {name: 'Feb 2018', score: 2},
                          {name: 'March 2018', score: 3},
                          {name: 'April 2018', score: 5},
                    ]};



class Stats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedStatsFrequency: 'month',
            selectedGraphTab: 'studies',
        };   

        this.handelStatsFrequencyChange = this.handelStatsFrequencyChange.bind(this);
        this.handelSelectGraphTab = this.handelSelectGraphTab.bind(this);
    }

    handelSelectGraphTab(e, tab) {
        e.preventDefault();

        this.setState({selectedGraphTab: tab});
    }

    handelStatsFrequencyChange(newValue) {
        this.setState({selectedStatsFrequency: newValue});
    }


    render() {
        const {selectedStatsFrequency, selectedGraphTab} = this.state;

        return (
            <section className="dashboard-section content-wrapper-animate">
                <section className="dashboard-hero">
                    <div className="dashboard-hero__text-container">
                        <h1 className="dashboard-hero__title study-hero__title">Your Stats</h1>
                    </div>
                </section>

                <section className="dashboard-section__container">

                    <div className="stats-summary-percentage stats-summary__feature-text">
                        Your score is <span>{overallScorePercentage[selectedStatsFrequency]}%</span> higher than other Hurdle users

                        <PercentageBar percentage={overallScorePercentage[selectedStatsFrequency]} className="stats-summary-percentage__bar"/>
                    </div>

                    <div className="stats-summary">
                        <div className="stats-summary__col right stats-summary__feature-text">
                            {/*Your<br />*/}
                            <span className="stats-summary__score-text">Chrono<br/>
                            Score
                            </span>
                        </div>
                        <div className={'stats-summary__col stats-pie-chart stats-pie-chart--'+selectedStatsFrequency}>
                            <div className="stats-pie-chart__legend_title stats-summary__feature-text">Breakdown</div>
                            <div className="stats-pie-chart__score">{overallScore[selectedStatsFrequency]}</div>

                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                      data={pieData[selectedStatsFrequency]} 
                                      nameKey="name"
                                      dataKey="value"
                                      cx={120} 
                                      cy={200} 
                                      innerRadius={60}
                                      outerRadius={80} 
                                      fill="#8884d8"
                                      paddingAngle={2}
                                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                    >
                                    {
                                        pieData[selectedStatsFrequency].map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                    }
                                    </Pie>
                                    <Legend 
                                        layout="vertical" 
                                        align="right" 
                                        verticalAlign="middle" 
                                        iconType="circle" 
                                        wrapperStyle={{ right: '-101px', bottom: '85px' }}
                                    />
                                    <Tooltip/>
                                </PieChart>
                            </ResponsiveContainer>

                        </div>
                        <div className="stats-summary__col">
                            <form className="form">
                                <div className="stats-summary__frequency form__select-wrapper">
                                    <select name="overall_stats_frequency" id="overall_stats_frequency" value={selectedStatsFrequency} 
                                        onChange={(e) => this.handelStatsFrequencyChange(e.target.value)}
                                    >
                                        <option value="month">This Month</option>
                                        <option value="year">This Year</option>
                                        <option value="alltime">All Time</option>
                                    </select>
                                    <i className="icon-down-open"></i>
                                </div>
                            </form>
                        </div>
                    </div>

                </section>

                <section className="stats-graph-container">
                    <div className="stats-graphs">
                        <ul className="menu-list stats-graphs__menu">
                            <li><a href="#" className={selectedGraphTab=='studies' ? 'active' : ''} onClick={(e) => this.handelSelectGraphTab(e, 'studies')}>Studies <span>3</span></a></li>
                            <li><a href="#" className={selectedGraphTab=='datapoints' ? 'active' : ''} onClick={(e) => this.handelSelectGraphTab(e, 'datapoints')}>Data Points <span>25</span></a></li>
                            <li><a href="#" className={selectedGraphTab=='publications' ? 'active' : ''} onClick={(e) => this.handelSelectGraphTab(e, 'publications')}>Publications <span>3</span></a></li>
                        </ul>

                        <div className="box-divider stats-graphs__divider"/>

                        <div className="stats-graphs__content">
                            <div className="stats-graphs__subtitle stats-summary__feature-text">
                                {selectedGraphTab=='studies' && <span>Number of studies your data was used in</span>}
                                {selectedGraphTab=='datapoints' && <span>Number of times your data has been used in research</span>}
                                {selectedGraphTab=='publications' && <span>Number of publications your data has appeared in</span>}
                            </div>

                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={lineData[selectedGraphTab]}>
                                    <YAxis type="number" allowDecimals={true} stroke="#cad3df" />
                                    <XAxis dataKey="name" stroke="#cad3df" />

                                    <CartesianGrid stroke="#cad3df" strokeDasharray="1 1" vertical={false}/>
                                    <Line type="monotone" dataKey="score" stroke="#ff0096" dot={{ stroke: '#ff0096', strokeWidth: 2 }}  activeDot={{r: 8}}/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </section>

                <section className="dashboard-section__container">
                    <div className="stats-timeline-container">
                        <div className="stats-summary__feature-text">
                            Your<br />
                            <span className="stats-timeline-container__title">Timeline</span>
                        </div>

                        <div className="box-divider"/>

                        <ul className="stats-timeline">
                            <li className="stats-timeline__item"><div className="stats-timeline__icon"></div>Joined the '<span>Help Dementia</span>' study</li>
                            <li className="stats-timeline__item"><div className="stats-timeline__icon"></div>Your data has been used in <span>over 50 studies</span>!</li>
                            <li className="stats-timeline__item"><div className="stats-timeline__icon"></div>Joined the '<span>Lets live longer</span>' study</li>
                            <li className="stats-timeline__item"><div className="stats-timeline__icon"></div>The '<span>Reducing Stress</span>' study was published</li>
                        </ul>
                    </div>


                </section>
            </section>
        );
    }
}

export default withErrorBoundary(Stats);
