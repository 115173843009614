import React from 'react';
import PropTypes from 'prop-types';

import Card from '../improvements/coaches-card';

const coachesData = [
    {
         'id':3,
         'fname':'Daniel Eli\u00adas',
         'lname':'Herranz',
         'organisation':'Hurdle',
         'dob':null,
         'occupation':'Epigeneticist',
         'brief_description':'During my PhD at the University of Cambridge, I focused on researching the role of epigenetics on human ageing using large amounts of biological data. In order to put this into the hands of people for the first time in history, I co-founded Hurdle, where I now serve as the Chief Scientific Officer. I will help you to understand the personalised insights that we have derived from your DNA.',
         'full_description':null,
         'program':null,
         'photo':'https:\/\/s3-eu-west-1.amazonaws.com\/app.chronomics.com\/public\/coach-images\/daniel-herranz.png',
         'specialities':[
            {
               'id':6,
               'slug':'biological-age',
               'title':'Biologicag Age',
               'coach_id':3
            }
         ],
         'category':{
            'id':2,
            'slug':'dna-expert',
            'title':'DNA expert',
            'order':2
         },
         'has_selected':false,
         'accepted_terms':false
      },
      {
         'id':2,
         'fname':'Victoria',
         'lname':'Fenton',
         'organisation':'Victoria Fenton Healthcare',
         'dob':null,
         'occupation':'Functional Medicine & Nutrition Consultant',
         'brief_description':'I am a Functional Medicine and Nutrition Consultant specialising in genetic, connective tissue disorders, though working across a broad spectrum of healthcare: from complex chronic illness to longevity medicine and health optimisation. My work makes healthcare as simple as possible for my clients: personalising plans which blend common sense, evidence-based nutrition and lifestyle recommendations with an in-depth understanding of human biochemistry and systems biology. In so doing, I support my clients\' health journeys on all levels, whatever their goals.',
         'full_description':'DNA expert',
         'program':'Meet DNA Counsellor',
         'photo':'https:\/\/s3-eu-west-1.amazonaws.com\/app.chronomics.com\/public\/coach-images\/victoria-fenton.png',
         'specialities':[
            {
               'id':8,
               'slug':'metabolic-status',
               'title':'Metabolic Staus',
               'coach_id':2
            },
            {
               'id':10,
               'slug':'stress',
               'title':'Stress',
               'coach_id':2
            }
         ],
         'category':{
            'id':1,
            'slug':'health-and-wellbeing',
            'title':'Health & Wellbeing',
            'order':1
         },
         'has_selected':false,
         'accepted_terms':false
      }
];

const MyTeamReport = ({ helpSection , disableLink}) => {
    const getCoaches = () => {
        if (Array.isArray(coachesData)) {
            return coachesData.map((member, index) => <Card
                key={`coach-${index}`}
                {...member}
                coachCount={coachesData.length}
                disableLink={disableLink}
                                                      /> );
        }
    };

    return (
        <div className="improvement-container__coaches-container coaches-report-container">
            <h6 className="improvement-container__title">My Team</h6>
            <p className="improvement-container__description">Our health specialists are available to provide personalised support to help you meet your health goals.</p>
            <div className="improvement-container__coaches-container__cards">
                {getCoaches()}
            </div>

            <div className="improvement-container__invite-section">
                <div className="icon-container"><i className="icon-share" /></div>
                <p>Invite your doctor/coach to share your data</p>
            </div>
        </div>
    );
};

MyTeamReport.propTypes = {
    coachesData: PropTypes.object
};

export default MyTeamReport;
