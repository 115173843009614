import React from 'react';

const KitDisposal = () => (
  <>
    As a socially responsible and environmentally aware business, we understand the need to reduce our carbon footprint whenever we can. Here, you’ll find guidance to help you dispose of your testing devices and their constituent parts in your household waste while minimising harm to the environment.{'\n'}
    This advice will help you, whether you have:
    <ul>
      <li>a fully home-based self-testing kit you’ve already used</li>
      <li>parts of a test left over after you’ve returned a sample to our lab</li>
      <li>received an incomplete testing kit in error</li>
      <li>cancelled a testing kit order that’s already been sent to you.</li>
    </ul>
    The following leftover materials can be fully recycled:
    <ul>
      <li>Cardboard packaging/sleeve</li>
      <li>Cardboard tube holder</li>
      <li>Paper instruction booklet</li>
    </ul>
    If you have a used lateral flow device, please separate it from the above materials and recycle it, if possible.{'\n'}
    Unfortunately the following items, which you may have left over after performing a test, can’t be recycled:
    <ul>
      <li>swab</li>
      <li>test strip</li>
      <li>sachet</li>
      <li>extraction tube</li>
    </ul>
    Please dispose of these items responsibly, in accordance with local facilities and advice.{'\n'}
    <b>And don’t worry</b>: Waste produced as part of the testing process doesn’t present any increased risk compared to personal hygiene waste, so it can be disposed of as normal.{'\n'}
    *Disposal advice may be subject to change in accordance with future waste disposal guidance.
  </>
);

export default KitDisposal;
