import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  getIsFailedInterpretation,
  getIsFailedResult,
  getFailedResultContent,
  formatInterpretation,
  formatBiomarkerResult,
  getIsUnsupportedRule,
} from './utils';
import withErrorBoundary from '../../../components/common/errorBoundary';
// import mockBloodResults from '../../services/you/mock-blood-results.json';
import BiomarkerModal from './biomarkerModal';
import ClinicalCommentary from './clinicalCommentary';
import textClassNames from './textClassnames';
import ResultsBar from '../../../components/common/resultsBar';
import BackButton from '../../../components/common/MaterialUi/backButton';

const BloodResults = ({ fname, currentTest, currentProduct, biomarkerDescriptions }) => {
  const [clickedBm, setClickedBm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onBmClick = useCallback(
    ({ isModalOpen, bm, formattedResult, interpretation, interpretationData, formattedInterpretation, failedResultContent }) => {
      if (bm) {
        setClickedBm({
          ...bm,
          formattedResult,
          interpretation,
          interpretationData,
          formattedInterpretation,
          failedResult: failedResultContent,
        });
      }
      setIsModalOpen(isModalOpen);
    },
    [setClickedBm, setIsModalOpen],
  );

  const { metadata, registeredAt, processedAt, specimens = [], biomarkerResults = [] } = currentTest || {};
  const collectionDate = registeredAt || (specimens.length ? specimens[0].registeredAt : '');
  const reportDate = processedAt || (specimens.length ? specimens[0].processedAt : '');
  const { firstName, lastName, npi } = metadata?.physician || {};
  const { title: productName } = currentProduct || { title: 'Test' };

  return (
    <div>
      <section className="orders-section__container">
        <BackButton />
        <Helmet>
          <title>{productName} Result - Hurdle</title>
        </Helmet>
        {productName && <h2 className="orders-section__sub-title">{productName} Result</h2>}
        {collectionDate && <p>Sample collection date / {moment(collectionDate).format('DD.MM.YYYY, h:mm a')}</p>}
        {reportDate && <p>Sample report date / {moment(reportDate).format('DD.MM.YYYY, h:mm a')}</p>}
        {(firstName || lastName) && <p>{`Physician Name: ${firstName?.trim()} ${lastName?.trim()}`}</p>}
        {npi && <p>NPI (National Provider Identifier): {npi}</p>}
        <ClinicalCommentary currentTest={currentTest} fname={fname} />
        {biomarkerResults.length > 0 && !isEmpty(biomarkerResults[0]) && (
          <div className="dashboard-section__results-section biomarker-list">
            {biomarkerResults.map(bm => {
              const { id, result, interpretation, biomarker, interpretationData } = bm;
              const isUnrecognized = !biomarker?.interpretationRule;
              if (isUnrecognized) return null;
              const biomarkerDescriptionExists = Object.keys(biomarkerDescriptions).find(
                key => key.toLowerCase() === biomarker?.code.toLowerCase(),
              );
              const canClick = Boolean(biomarkerDescriptionExists);
              const formattedInterpretation = formatInterpretation(interpretation) || '';
              const failedResultContent = getFailedResultContent(result);
              const formattedResult = failedResultContent?.chronomicsStatus || formatBiomarkerResult(bm);
              const isFailedResult = getIsFailedResult(result);
              const isFailedInterpretation = getIsFailedInterpretation(interpretation);
              const isUnsupportedRule =
                biomarker && biomarker.interpretationRule ? getIsUnsupportedRule(biomarker.interpretationRule) : true;
              const isInconclusiveTest = isFailedResult || isFailedInterpretation;
              return (
                <div
                  key={id}
                  className={`dashboard-section__single-result ${canClick ? 'clickable' : ''}`}
                  onClick={
                    canClick
                      ? onBmClick.bind(null, {
                          isModalOpen: true,
                          bm,
                          interpretation,
                          interpretationData,
                          formattedInterpretation: isInconclusiveTest ? '' : formattedInterpretation,
                          formattedResult,
                          failedResultContent,
                        })
                      : undefined
                  }
                >
                  <div className="result-content blood-test">
                    <h5 className="result-title blood-test">{biomarker.name && biomarker.name}</h5>
                    <p className="result-text blood-test-result">{formattedResult}</p>
                    <p className={`result-text blood-test-interpretation ${(!isInconclusiveTest && textClassNames[interpretation]) || ''}`}>
                      {isInconclusiveTest ? '' : formattedInterpretation}
                    </p>
                    <div className="result-text bio-bar">
                      {isInconclusiveTest || isUnsupportedRule ? (
                        <span>See Details</span>
                      ) : (
                        <>{interpretationData && result && <ResultsBar interpretationData={interpretationData} result={result} />}</>
                      )}
                    </div>
                    <div className="result-text bio-arrow">{canClick && <img src="/img/bold-arrow-right.svg" alt="Arrow" />}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
      <Modal open={isModalOpen} onClose={onBmClick.bind(null, { isModalOpen: false })} classNames={{ modal: 'custom-modal' }} center>
        <BiomarkerModal
          onClose={onBmClick.bind(null, { isModalOpen: false })}
          bm={clickedBm}
          biomarkerDescription={biomarkerDescriptions[clickedBm?.biomarker?.code]}
        />
      </Modal>
    </div>
  );
};

BloodResults.propTypes = {
  fname: PropTypes.string.isRequired,
  currentTest: PropTypes.shape({
    registeredAt: PropTypes.string,
    processedAt: PropTypes.string,
    specimens: PropTypes.arrayOf(PropTypes.object),
    biomarkerResults: PropTypes.arrayOf(PropTypes.object),
    metadata: PropTypes.object,
  }).isRequired,
  currentProduct: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  biomarkerDescriptions: PropTypes.object,
};

export default withErrorBoundary(BloodResults);
