import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CONTINENTAL_COLOR_MAP } from './mapper';

class ExpandableCountryListCell extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const { ancestry: { code }, expandedContinent } = this.props;
        if (expandedContinent === code) {
            this.handleClick();
        }
    }

    handleClick(e) {
        const { ancestry, onContinentClick } = this.props;
        onContinentClick({ ...ancestry, continentCode: ancestry.code, e });
    }

    static getCells({ continentCode, country, index, onCountryClick, selectedCountry }) {
        const { code, percentage, title } = country;
        const { code: selectedCountryCode } = selectedCountry;
        const style = selectedCountryCode === code
            ? { color: '#FFFFFF',
                background: CONTINENTAL_COLOR_MAP[continentCode] }
            : { color: CONTINENTAL_COLOR_MAP[continentCode] };

        return (
            <div
                className="expandable-list__item"
                key={`${code}-${index}`}
                onClick={(e) => onCountryClick({ ...country, continentCode, e })}
                style={style}
            >
                <div className="expandable-list__item__country-ancestry-percentage">{percentage}%</div>
                <div className="expandable-list__item__country-name">{title}</div>
            </div>
        );

    }

    render() {
        const {
            ancestry,
            ancestry: {
                code: continentCode,
                countries,
                percentage,
                title,
            },
            expandedContinent,
            onCountryClick,
            selectedCountry,
        } = this.props;
        const color = CONTINENTAL_COLOR_MAP[continentCode];
        const className = expandedContinent === continentCode ? 'open' : '';

        if (!Object.keys(ancestry).length) {
            return null;
        }

        return (
            <div className={`expandable-list ${className}`} >
                <header onClick={this.handleClick} style={{color}} >
                    <div className="continental-ancestry-percentage" style={{background: color}}>{percentage}%</div>
                    <div className="continent-name">{title}</div>
                    <div className="chevron-icon"><i className="icon-left-open-big" /></div>
                </header>
                <div className="expandable-list__content">
                    {countries.map(
                        (country, index) => ExpandableCountryListCell.getCells({
                            country, onCountryClick, index, continentCode, selectedCountry
                        })
                    )}
                </div>
            </div>
        );
    }

}

ExpandableCountryListCell.defaultProps = {
    ancestry: {},
    expandedContinent: '',
    onContinentClick: () => {},
    onCountryClick: () => {},
    selectedCountry: {}

};

ExpandableCountryListCell.propTypes = {
    ancestry: PropTypes.object,
    expandedContinent: PropTypes.string,
    onContinentClick: PropTypes.func,
    onCountryClick: PropTypes.func,
    selectedCountry: PropTypes.object,

};

export default ExpandableCountryListCell;
