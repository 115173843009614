import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DiseaseRisk from '../../containers/you/common/diseaseRisk';

import HeroSection from '../../containers/you/indicator/overviewBiologicalAge/heroSection';
import AgeAffectors from '../../containers/you/indicator/overviewBiologicalAge/ageAffectors';
import Comparison from '../../containers/you/indicator/overviewBiologicalAge/comparison';

import HealthyRanges from '../../containers/you/indicator/components/healthyRanges';
import TrackEpigeneticAge from '../../containers/you/indicator/components/trackEpigeneticAge';
import ResultImprovements from '../../containers/you/indicator/components/resultImprovements';
import HealthSpecialist from '../../containers/you/indicator/components/healthSpecialist';

class BiologicalAgeReport extends Component {
    render() {
        const { heroSectionInfo, diseaseRisk, previousTest, resultDelta, healthyRanges, ageOverTimeGraph,
            breakpoint, recommendations, team, slug, disableLink, customComparisons,disablelink } = this.props;
        return (
            <section>
                {heroSectionInfo && heroSectionInfo.currentTestDateText ? <p className="result-hero__current-test-date">{heroSectionInfo.currentTestDateText}</p> : ''}
                <section className="biological-age__overview-container">
                    <HeroSection
                        heroSectionInfo={heroSectionInfo}
                        previousTest={previousTest}
                        resultDelta={resultDelta}
                        slug={slug}
                        disablelink={disableLink}
                    />
                </section>
                <section className="biological-age__overview-container">
                    <HealthyRanges healthyRanges={healthyRanges} />
                </section>
                <section className="biological-age__overview-container">
                    <TrackEpigeneticAge
                        biologicalData={ageOverTimeGraph}
                        breakpoint={breakpoint}
                        slug={slug}
                        wrapperClass="no-padding"
                    />
                </section>
                <section className="biological-age__overview-container">
                    <AgeAffectors disableLink={disablelink}/>
                </section>
 
                <Comparison breakpoint={breakpoint} customComparisons={customComparisons} disableLink={disableLink}/>

                <section className="biological-age__overview-container">
                    <ResultImprovements recommendations={recommendations} slug={slug} wrapperClass="no-padding" />
                </section>
                
                <section className="biological-age__overview-container">
                    <DiseaseRisk diseaseRisk={diseaseRisk} />
                </section>
                <section className="biological-age__overview-container full-width">
                    <div className="biological-age__graph-section no-padding">
                        <HealthSpecialist team={team} />
                    </div>
                </section>
            </section>
        );
    }
}

BiologicalAgeReport.propTypes = {
    heroSectionInfo: PropTypes.object,
    diseaseRisk: PropTypes.object,
    previousTest: PropTypes.object,
    resultDelta: PropTypes.any,
    healthyRanges: PropTypes.object,
    ageOverTimeGraph: PropTypes.object,
    breakpoint: PropTypes.string,
};

export default BiologicalAgeReport;