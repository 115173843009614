import React from 'react';
import { CovidStep01 } from './CovidStep01';
import { CovidStep02 } from './CovidStep02';

export const CovidTest = ({ history, match }) => {
  const step = match.params.step;
  if (step === '2') {
    return <CovidStep02 history={history} />;
  } else {
    return <CovidStep01 history={history} />;
  }
};
