import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import config from '../../config';
import { getCovidResults } from '../../services/you/actions';
import { covidResultSelector } from '../../services/you/selectors';
import { SmallContainer } from '../../components/SmallContainer';
import { LatestResultPanel } from './LatestResultPanel';
import { PastResultsPanels } from './PastResultsPanels';
import { TestProgress } from './TestProgress';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

export const TestingDashboard = ({ history }) => {
  const dispatcher = useDispatch();
  const covidResults = useSelector(covidResultSelector);
  const isBranded = cachedPartnerTheming();

  const [spinnerDisplay, setSpinnerDisplay] = useState(false);

  useEffect(() => {
    dispatcher(getCovidResults(true));
  }, []);

  const downloadCertificate = test => {
    const { id, registered_at, lab_processed_at } = test;
    const { fname, lname } = config.app.test_user;
    setSpinnerDisplay(true);
    axios
      .request({
        url: `/test_user/covid/${id}/download_certificate`,
        method: 'GET',
      })
      .then(({ data }) => {
        setSpinnerDisplay(false);
        if (data) {
          const link = document.createElement('a');
          link.href = data;
          link.setAttribute(
            'download',
            `${fname.toLowerCase()}_${lname.toLowerCase()}_${moment(lab_processed_at ? lab_processed_at : registered_at).format(
              'D_M_YYYY',
            )}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch(err => {
        setSpinnerDisplay(false);
        console.error(err);
      });
  };

  const { can_promote_to } = config.app;
  const latest = covidResults && covidResults.latest_result ? covidResults.latest_result : undefined;
  let allResults = covidResults ? covidResults.all_results : undefined;
  if (allResults && latest) {
    allResults = allResults.filter(({ barcode }) => barcode !== latest.barcode);
  }
  const handleTestSelection = value => {
    history.push(`/dashboard/covid/test-timeline/${value}`);
  };
  return (
    <section className={`test-dashboard-section content-wrapper-animate${!isBranded ? ' default-theme-background' : ''}`}>
      <div className="switch-menu">
        <div className="switch-option active">Summary</div>
        <div className="switch-option" onClick={() => history.push('/dashboard/anti-spike')}>
          Anti-Spike
        </div>
      </div>
      <div className="test-dashboard-section__section_content">
        <div className="main-container mobile">
          <div className="row-container">
            {latest && latest.status !== 'processed' ? (
              <TestProgress result={latest} />
            ) : (
              <LatestResultPanel
                history={history}
                isLoading={!covidResults}
                result={latest}
                onDownload={downloadCertificate}
                spinnerDisplay={spinnerDisplay}
              />
            )}
            <PastResultsPanels
              history={history}
              isLoading={!covidResults}
              results={allResults}
              onDownload={downloadCertificate}
              spinnerDisplay={spinnerDisplay}
            />
          </div>
        </div>

        <div className="main-container">
          <div className="row-container desktop">
            {latest && latest.status !== 'processed' ? (
              <TestProgress result={latest} />
            ) : (
              <LatestResultPanel
                history={history}
                isLoading={!covidResults}
                result={latest}
                onDownload={downloadCertificate}
                spinnerDisplay={spinnerDisplay}
              />
            )}
            <PastResultsPanels
              history={history}
              isLoading={!covidResults}
              results={allResults}
              onDownload={downloadCertificate}
              spinnerDisplay={spinnerDisplay}
            />
          </div>

          {can_promote_to ? (
            <div className="row-container single">
              <SmallContainer
                bgColor="#FEECEB"
                title="Wondering if your loved ones are safe?"
                subtitle="Buy for family member"
                onClick={() => history.push('/dashboard/covid/test-timeline/select-test')}
              />
            </div>
          ) : null}

          <div className="row-container">
            <SmallContainer
              bgColor="#FFF0D7"
              title="How at risk are you from Covid?"
              subtitle="Use the free tool to find out if your health puts you at risk"
              onClick={() => (window.location.href = 'https://covid.chronomics.com/risk-calculator')}
            />
            <SmallContainer
              bgColor="#E2F9FB"
              title="Am I immune to Covid-19 now?"
              subtitle="And how long does immunity last?"
              onClick={() => history.push('/dashboard/article/am-i-immune-to-covid-19')}
            />
          </div>
        </div>

        {can_promote_to ? (
          <div className="test-dashboard-section__container side">
            <div className="content-container">
              <div className="section-title">Testing timeline calculator</div>
              <div className="section-sub-title">I want to have a Covid test for</div>
              <div className="item-selection" title="Test-to-release" onClick={() => handleTestSelection('test-to-release')}>
                <input type="radio" title="Test-to-release" name="text-selection" value="test-to-release" />
                <div>
                  <div className="section-sub-title">Test-to-release</div>
                  <div className="item-tag">For travellers returning to UK and want to reduce self-isolation period</div>
                </div>
              </div>

              <div className="item-selection" title="Fit-to-fly" onClick={() => handleTestSelection('fit-to-fly')}>
                <input type="radio" title="Fit-to-fly" name="text-selection" value="fit-to-fly" />
                <div>
                  <div className="section-sub-title">Fit-to-fly</div>
                  <div className="item-tag">For outbound international travelers to have a certificate for your destination</div>
                </div>
              </div>

              <div className="item-selection" title="Standard COVID-19 test" onClick={() => handleTestSelection('covid')}>
                <input type="radio" title="Standard COVID-19 test" name="text-selection" value="covid" />
                <div>
                  <div className="section-sub-title">Standard COVID-19 test</div>
                  <div className="item-tag">Detect if you currently have COVID-19</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};
