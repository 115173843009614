import React, { Component } from 'react';
import {
    CardCVCElement,
    CardExpiryElement,
    CardNumberElement, injectStripe,
} from 'react-stripe-elements';
import config from '../../config';
import AddressCard from './addressCard';
import {validated} from 'react-custom-validation';
import validationRules from '../helpers/validationRules';
import {cachedPartnerTheming} from '@chronomics/chronomics-registration';

function BillingAddressForm({fields = {}, onChange, countrys, $field, $validation, $fieldEvent }) {
    return <div>
            <div className="input-label-container">
                <input
                    type="text"
                    value={fields.billing_address1}
                    name="billing_address1"
                    id={fields.billing_address1}
                    onChange={(e) => onChange('billing_address1', e.target.value)}
                    onBlur={() => $fieldEvent('blur', 'billing_address1')}
                />
                <label htmlFor="billing_address1" className={`${'form__label'} ${fields.billing_address1? 'valid': ''}`}>
                    Address 1
                </label>
                <div className="form__error">
                    {$validation.billing_address1.show
                        ? $validation.billing_address1.error.reason
                        : ''}
                </div>
            </div>
            <div className="input-label-container">
                <input
                    type="text"
                    value={fields.billing_address2}
                    name="billing_address2"
                    id={fields.billing_address2}
                    onChange={(e) => onChange('billing_address2', e.target.value)}
                    onBlur={() => $fieldEvent('blur', 'billing_address2')}
                />
                <label htmlFor="billing_address2" className={`${'form__label'} ${fields.billing_address2? 'valid': ''}`}>
                    Address 2 (optional)
                </label>
                <div className="form__error">
                    {$validation.billing_address2.show
                        ? $validation.billing_address2.error.reason
                        : ''}
                </div>
            </div>
            <div className="input-label-container">
                <input
                    type="text"
                    value={fields.billing_city}
                    name="billing_city"
                    id={fields.billing_city}
                    onChange={(e) => onChange('billing_city', e.target.value)}
                    onBlur={() => $fieldEvent('blur', 'billing_city')}
                />
                <label htmlFor="billing_city" className={`${'form__label'} ${fields.billing_city? 'valid': ''}`}>
                    City
                </label>
                <div className="form__error">
                    {$validation.billing_city.show
                        ? $validation.billing_city.error.reason
                        : ''}
                </div>
            </div>
            <div className="form__row form__row--2col">
                <div className="form__col2">
                    <div className="input-label-container">
                        <input
                            type="text"
                            value={fields.billing_postcode}
                            name="billing_postcode"
                            id={fields.billing_postcode}
                            onChange={(e) => onChange('billing_postcode', e.target.value)}
                            onBlur={() => $fieldEvent('blur', 'billing_postcode')}
                        />
                        <label htmlFor="billing_postcode" className={`${'form__label'} ${fields.billing_postcode? 'valid': ''}`}>
                            {['US', 'CA'].indexOf(fields.billing_country) > -1 ? 'Zip Code' : 'Post Code'}
                        </label>
                        <div className="form__error">
                            {$validation.billing_postcode.show
                                ? $validation.billing_postcode.error.reason
                                : ''}
                        </div>
                    </div>
                </div>

                <div className="form__col2">
                    <div className="input-label-container">
                        <label htmlFor="billing_country" className={`${'form__label'} ${fields.delivery_postcode? 'valid': ''}`}>
                            Country
                        </label>
                        <div className="form__select-wrapper">
                            <select
                                name="billing_country"
                                id={fields.billing_country}
                                value={fields.billing_country}
                                onChange={(e) => onChange('billing_country', e.target.value)}
                                onBlur={() => $fieldEvent('blur', 'billing_country')}

                            >
                                <option key="" value="" />
                                {countrys.length && countrys.map((o, key) => (
                                        <option
                                            key={key}
                                            value={o.country_code}
                                        >
                                            {o.title}
                                        </option>
                                    ))}
                            </select>
                            <i className="icon-down-open" />
                        </div>
                        <div className="form__error">
                            {$validation.billing_country.show
                                ? $validation.billing_country.error.reason
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
           </div>;
}

class Billing extends Component {
    constructor() {
        super();
        this.submit= this.submit.bind(this);
        this.onChangeAddressMode = this.onChangeAddressMode.bind(this);
    }

     submit(e) {
        e.preventDefault();
        const {
            /* form validation */
            $submit, onInvalid, onValid,
            /* stripe and strip elements (from <ElementsConsumer> injection) */
            stripe, elements
        } = this.props;
        $submit(() => onValid(stripe, {stripeElements: elements}), onInvalid);
    }

    onChangeAddressMode(e) {
        const {UpdateState} = this.props;
        UpdateState({ addressMode: e.target.value });
    }

    getShippingAddress() {
        const {addressMode, fields, onChange, calculatePostageCosts, countrys, $field, $validation, $fieldEvent} = this.props;
        if (addressMode === 'different-address') {
            return <div>
                <BillingAddressForm
                    $field={$field}
                    $validation={$validation}
                    $fieldEvent={$fieldEvent}
                    calculatePostageCosts={calculatePostageCosts}
                    countrys ={countrys}
                    fields={fields}
                    onChange={onChange}
                />
                   </div>;
        }

        return <div className="billing-address-card">
            <AddressCard fields={fields} countrys={countrys} isBillingAddress={true} />
               </div>;
    }

    render() {
        const {
            branding,
            fields,
            $field,
            onChange,
            onCardFieldChange,
            $validation,
            submitError,
            disableSubmitButton,
            addressMode,
            shouldExpand,
            fieldErrors,
            submitErrorMessage,
            hasTravelSection,
            shippingMethod
        } = this.props;
        const stripeFieldStyle = {
            base: {
                fontFamily: 'Roboto',
                fontSize: '15px',
                color: '#1B1D26',
                '::placeholder': {
                    color: '#a3a4a8',
                },
            },
        };

        const partnerBranding = cachedPartnerTheming();

        const getBrandingStyle = () => (branding && branding.hasBranding && branding.section) ? {
            color: branding.section.highlight_colour,
        } : {};

        const getBrandingButtonStyle = () => {
          if (partnerBranding || branding?.hasBranding) {
            const theme = partnerBranding ? partnerBranding.theme : branding;
            return { background: theme.section.cta_colour };
          }
          return {};
        };
        
        return <div className="checkout new-checkout-flow" id="billing-section">
                <div className={`${'checkout__form-col order-summary'}`}>
                    <form className="form checkout-form" onSubmit={this.submit}>
                        <h3 className="checkout__title checkout__title" style={getBrandingStyle()}>
                            {!hasTravelSection && shippingMethod == 'default' ? '2. Billing' : '3. Billing'}
                            <span className="checkout__title--orderstep" style={getBrandingStyle()}>
                                {!hasTravelSection && shippingMethod == 'default' ? 'Step 2 of 2' : 'Step 3 of 3'}
                            </span>
                        </h3>

                         <div className={`card-wrapper ${shouldExpand ? 'slide' : '' }`}>
                                <div className="card-container">
                                    <div className="card-container__card-display">
                                        <div>Card Details</div>
                                        <div className="card-container__card-images">
                                            <img className="checkout-form__cards" src="/img/accepted-cards.jpeg" alt="Accepted cards: Visa, Mastercard and American Express" />
                                            <img className="checkout-form__cards" src="/img/ssl-secure.png" alt="SSL Secured" />
                                        </div>
                                    </div>
                                    <div className="card-container__card-info card-details-section">

                                        <div>
                                            <label htmlFor="card_holder_name" className="form__label--no-transition">Name on card</label>
                                            <input
                                                type="text"
                                                value={fields.card_holder_name}
                                                name="card_holder_name"
                                                id={fields.card_holder_name}
                                                placeholder="Name on card"
                                                onChange={(e) => onChange('card_holder_name', e.target.value)}
                                                onBlur={() => this.props.$fieldEvent('blur', 'card_holder_name')}
                                            />
                                            <div className="form__error">{$validation.card_holder_name.show ? $validation.card_holder_name.error.reason : ''}</div>
                                        </div>

                                        <div className="form__row--no-error">
                                            <div>
                                                <label className="form__label--no-transition" >
                                                    Card number
                                                </label>
                                                <CardNumberElement style={stripeFieldStyle}  onChange={onCardFieldChange}/>
                                                <div className="form__error">{fieldErrors.cardNumber ? fieldErrors.cardNumber : ''}</div>
                                            </div>

                                            {config.app.env !== 'production' && <div >
                                                    Test:
                                                    4242424242424242
                                                                                </div>}
                                        </div>

                                        <div className="form__row form__row--2col">
                                            <div className="form__col2">
                                                <label className="form__label--no-transition">
                                                    Expiration date
                                                </label>
                                                <CardExpiryElement
                                                    style={stripeFieldStyle}
                                                    onChange={onCardFieldChange}
                                                />
                                                <div className="form__error">{fieldErrors.cardExpiry ? fieldErrors.cardExpiry : ''}</div>
                                            </div>

                                            <div className="form__col2">
                                                <label className="form__label--no-transition">
                                                    CVC
                                                </label>
                                                <CardCVCElement
                                                    style={stripeFieldStyle}
                                                    onChange={onCardFieldChange}
                                                />
                                                <div className="form__error">{fieldErrors.cardCvc ? fieldErrors.cardCvc : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-container billing-address-container">
                                    <div className="card-container__card-display">
                                        <div>Billing Address</div>
                                        <div className="form__row">
                                            <input
                                                type="radio"
                                                value="shipping-address"
                                                name="addressMode"
                                                id="shipping-address"
                                                onChange={this.onChangeAddressMode}
                                                checked={addressMode =='shipping-address'}
                                            />
                                            <label className="form__label form__label--checkbox" htmlFor="shipping-address">
                                                Same as shipping
                                            </label>
                                        </div>

                                        <div className="form__row">
                                            <input
                                                type="radio"
                                                value="different-address"
                                                name="addressMode"
                                                id="billing-address"
                                                onChange={this.onChangeAddressMode}
                                                checked={addressMode=='different-address'}
                                            />
                                            <label className="form__label form__label--checkbox" htmlFor="billing-address">
                                            Different address
                                            </label>
                                        </div>
                                    </div>
                                    <div className="card-container__card-info">
                                        {this.getShippingAddress()}
                                    </div>
                                </div>
                                <div className="card-container">
                                    <div className="card-container__card-display">
                                    </div>
                                    <div className="card-details-section">
                                        <div className="form__row">
                                            <input
                                                type="checkbox"
                                                value={fields.newsletter}
                                                name="newsletter"
                                                id="newsletter"
                                                checked={fields.newsletter}
                                                {...$field('newsletter', e => onChange('newsletter', e.target.checked))}
                                            />
                                            <label className= "form__label--checkbox form__label--terms " htmlFor="newsletter">
                                                Keep me updated by email on offers and updates related to Covid-19
                                            </label>
                                        </div>
                                        <div className="form__row">
                                            <input type="checkbox" value={fields.accepted_terms_privacy} name="accepted_terms_privacy" id="accepted_terms_privacy" checked={fields.accepted_terms_privacy} {...$field(
                                                    'accepted_terms_privacy',
                                                    e =>
                                                        onChange(
                                                            'accepted_terms_privacy',
                                                            e.target
                                                                .checked
                                                        )
                                                )}
                                            />
                                            <label className= "form__label--checkbox form__label--terms " htmlFor="accepted_terms_privacy">
                                                I agree to your  <a href={config.app.web_url+ '/terms-conditions'} target="_blank" rel="noreferrer">terms &amp; conditions</a>, <a href={config.app.web_url+'/legal/privacy-policy'} target="_blank" rel="noreferrer">privacy policy</a> and authorise Chronomics Limited to take payments from my card account in accordance with the terms of my agreement with you. I confirm that I am satisfied the type of test I am purchasing meets my needs and that Chronomics cannot be held responsible for delays in results outside of their control.

                                            </label>
                                            <div className="form__error form__error--start">
                                                {$validation
                                                    .accepted_terms_privacy.show
                                                    ? $validation
                                                          .accepted_terms_privacy
                                                          .error.reason
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <button className="btn checkout__btn" style={getBrandingButtonStyle()} disabled={disableSubmitButton}>
                                                Complete Order
                                                <i className="icon-right-open" />
                                            </button>
                                            {submitError &&
                                                <div className="form__error form__error--general form__error--start">
                                                    {submitErrorMessage ? submitErrorMessage : 'Please complete the errors above'}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                         </div>
                    </form>
                </div>
               </div>;
    }
}

function paymentValidationConfig(props) {
    const { accepted_terms_privacy, billing_address1,
        billing_city, billing_postcode, billing_country, card_holder_name} = props.fields;
    const {addressMode} = props;
    let fields = ['accepted_terms_privacy', 'card_holder_name'];
    let validations = {
        accepted_terms_privacy: [
            [validationRules.isTermsChecked, accepted_terms_privacy, 'You must accept the terms & conditions']
            ],
        card_holder_name: [
            [validationRules.isRequired, card_holder_name]
        ],

    };
    const differentShippingAddressFiels = [ 'billing_address1', 'billing_address2',
        'billing_city', 'billing_postcode', 'billing_country'];
    const differentShippingAddressValidations = {
            billing_address1: [
            [validationRules.isRequired, billing_address1]
        ],
            billing_address2: [],
            billing_city: [
            [validationRules.isRequired, billing_city]
        ],
            billing_postcode: [
            [validationRules.isRequired, billing_postcode],
            [validationRules.isBillingPostcode, billing_postcode, billing_country]
        ],
            billing_country: [
            [validationRules.isRequired, billing_country],
        ]
    };

    if (addressMode === 'different-address') {
        fields = [...differentShippingAddressFiels, ...fields];
        validations = {...differentShippingAddressValidations, ...validations};
    }

    return {
        fields,
        validations
    };
}

export { Billing as __TEST_BILLING };
export default injectStripe(validated(paymentValidationConfig)(Billing));
