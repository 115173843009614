import React, { Component } from 'react';
import PropTypes from 'prop-types';

const chromosomeBars = {
    chr1: {height: '100%', bottom: '50.2306%', key: 1},
    chr2: {height: '97.5722%', bottom: '38.5075%', key: 2},
    chr3: {height: '79.4471%', bottom: '45.9039%', key: 3},
    chr4: {height: '76.6916%', bottom: '26.3923%', key: 4},
    chr5: {height: '72.5837%', bottom: '26.7529%', key: 5},
    chr6: {height: '68.6518%', bottom: '35.6485%', key: 6},
    chr7: {height: '63.8468%', bottom: '37.6087%', key: 7},
    chr8: {height: '58.7216%', bottom: '31.1552%', key: 8},
    chr9: {height: '56.6552%', bottom: '34.6992%', key: 9},
    chr10: {height: '54.3769%', bottom: '29.6234%', key: 10},
    chr11: {height: '54.165%', bottom: '39.7388%', key: 11},
    chr12: {height: '53.7017%', bottom: '26.7086%', key: 12},
    chr13: {height: '46.2065%', bottom: '15.5423%', key: 13},
    chr14: {height: '43.0689%', bottom: '16.395%', key: 14},
    chr15: {height: '41.1359%', bottom: '17.7994%', key: 15},
    chr16: {height: '36.2506%', bottom: '40.507%', key: 16},
    chr17: {height: '32.5757%', bottom: '29.5584%', key: 17},
    chr18: {height: '31.3248%', bottom: '22.0295%', key: 18},
    chr19: {height: '23.7227%', bottom: '44.8173%', key: 19},
    chr20: {height: '25.286%', bottom: '43.6331%', key: 20},
    chr21: {height: '19.3098%', bottom: '26.1792%', key: 21},
    chr22: {height: '20.5835%', bottom: '29.3346%', key: 22},
    chrX: {height: '62.295%', bottom: '38.9964%', key: 'X'},
    chrY: {height: '23.8208%', bottom: '21.0531%', key: 'Y'}
};

class ChromosomeGraph extends Component {

    getChromosomeData() {
        const { data } = this.props;

        const chromosomeData = {};
        //Figure out the max count we have. not all graphs have a count...
        let maxCount = 1;

        Object.keys(chromosomeBars).map((key) => {
            chromosomeData[key] = {};
            chromosomeData[key].barDimensions = chromosomeBars[key];

            if(data[key]) {
                data[key].forEach(function(item) {
                    if(item.count && item.count > maxCount) {
                        maxCount = item.count;
                    }
                });

                chromosomeData[key].chromosomeSection = data[key];
            }
        });

        //And add the maxCount back in... needs to be calced for each first
        Object.keys(chromosomeBars).map((key) => {
            chromosomeData[key].maxCount = maxCount;
        });

        return chromosomeData;
    }

    renderHorizontalGraph() {
        const chromosomeGraphData = this.getChromosomeData();
        return (
            <div className="chromosome-graph__horizontal-container">
                {
                    Object.keys(chromosomeGraphData).map((key) => {
                        const position = chromosomeGraphData[key].barDimensions.bottom;
                        const barWidth = chromosomeGraphData[key].barDimensions.height;
                        const barKey = chromosomeGraphData[key].barDimensions.key;
                        const maxCount = chromosomeGraphData[key].maxCount || 1;

                        return (
                            <div className="chromosome-graph__horizontal-section-container" key={key}>
                                <div className="chromosome-graph__horizontal-section">
                                    <div className="chromosome-graph__horizontal-bar-container">
                                        <div className="chromosome-graph__horizontal-bar" style={{width: barWidth}}>
                                            <div className="chromosome-graph__horizontal-notch left" style={{left: position}} />
                                            <div className="chromosome-graph__bar-content" />
                                            {
                                                chromosomeGraphData[key].chromosomeSection && chromosomeGraphData[key].chromosomeSection.map((section, index) => (
                                                    <div
                                                        key={index}
                                                        className="chromosome-graph__horizontal-pink-section"
                                                        style={{width: `${section.height}%`, left: `${section.bottom}%`, opacity: section.count ? (section.count/maxCount)+0.4 : 1}}
                                                    />
                                                ))
                                            }
                                            <div className="chromosome-graph__horizontal-notch right" style={{left: position}} />
                                        </div>
                                    </div>
                                    {
                                        (key !== 'chrX' && key !== 'chrY') &&
                                        <div className="chromosome-graph__horizontal-bar-container">
                                            <div className="chromosome-graph__horizontal-bar" style={{width: barWidth}}>
                                                <div className="chromosome-graph__horizontal-notch left" style={{left: position}} />
                                                <div className="chromosome-graph__bar-content" />
                                                {
                                                    chromosomeGraphData[key].chromosomeSection && chromosomeGraphData[key].chromosomeSection.map((section, index) => (
                                                        <div
                                                            key={index}
                                                            className="chromosome-graph__horizontal-pink-section"
                                                            style={{width: `${section.height}%`, left: `${section.bottom}%`, opacity: section.count ? (section.count/maxCount)+0.4 : 1}}
                                                        />
                                                    ))
                                                }
                                                <div className="chromosome-graph__horizontal-notch right" style={{left: position}} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="chromosome-graph__horizontal-number">{barKey}</div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    renderVerticalGraph() {
        const chromosomeGraphData = this.getChromosomeData();
        return (
            <div className="chromosome-graph__container">
                {
                    Object.keys(chromosomeGraphData).map((key) => {
                        const position = chromosomeGraphData[key].barDimensions.bottom;
                        const barHeight = `${parseFloat(chromosomeGraphData[key].barDimensions.height) * 150 / 100}px`;
                        const barKey = chromosomeGraphData[key].barDimensions.key;
                        const maxCount = chromosomeGraphData[key].maxCount || 1;

                        return (
                            <div className="chromosome-graph__section-container" key={key}>
                                <div className="chromosome-graph__section">
                                    <div className="chromosome-graph__bar-container">
                                        <div className="chromosome-graph__bar" style={{height: barHeight}}>
                                            <div className="chromosome-graph__notch left" style={{bottom: position}} />
                                            <div className="chromosome-graph__bar-content" />
                                            {
                                                chromosomeGraphData[key].chromosomeSection && chromosomeGraphData[key].chromosomeSection.map((section, index) => (
                                                    <div
                                                        key={index}
                                                        className="chromosome-graph__horizontal-pink-section"
                                                        style={{height: `${section.height}%`, bottom: `${section.bottom}%`, opacity: section.count ? (section.count/maxCount)+0.4 : 1}}
                                                    />
                                                ))
                                            }
                                            <div className="chromosome-graph__notch right" style={{bottom: position}} />
                                        </div>
                                    </div>
                                    {
                                        (key !== 'chrX' && key !== 'chrY') &&
                                        <div className="chromosome-graph__bar-container">
                                            <div className="chromosome-graph__bar" style={{height: barHeight}}>
                                                <div className="chromosome-graph__notch left" style={{bottom: position}} />
                                                <div className="chromosome-graph__bar-content" />
                                                {
                                                    chromosomeGraphData[key].chromosomeSection && chromosomeGraphData[key].chromosomeSection.map((section, index) => (
                                                        <div
                                                            key={index}
                                                            className="chromosome-graph__horizontal-pink-section"
                                                            style={{height: `${section.height}%`, bottom: `${section.bottom}%`, opacity: section.count ? (section.count/maxCount)+0.4 : 1}}
                                                        />
                                                    ))
                                                }
                                                <div className="chromosome-graph__notch right" style={{bottom: position}} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="chromosome-graph__number">{barKey}</div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const { orientation } = this.props;

        if(orientation === 'vertical') {
            return this.renderVerticalGraph();
        }

        if(orientation === 'horizontal') {
            return this.renderHorizontalGraph();
        }

        return null;
    }
}

ChromosomeGraph.propTypes = {
    orientation: PropTypes.string.isRequired,
    gender: PropTypes.string,
    data: PropTypes.object
};

export default ChromosomeGraph;
