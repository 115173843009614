import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { PARTNER_CODE } from '../../../../services/partner-config/partner-config';
import { downloadResults } from '../../../../services/you/web-api';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';
import Icon from './styled/Icon';
import Title from './styled/Title';
import { ContentWrap, RelativeWrap, Wrapper } from './styled/wrappers';

const Commentary = ({ fname, currentTest }) => {
  const resultAdvisoryText =
    cachedPartnerTheming()?.partnerCode === PARTNER_CODE.NPH ? (
      <span>
        If you would like to seek professional advice and guidance about your results, you can call us on
        <a href="tel:0191 6053140"> 0191 6053140</a> to book an online appointment.
      </span>
    ) : (
      <span>
        Congratulations on taking control of your health. Here are your test results, which must be interpreted by a doctor alongside your
        medical history and examination.
      </span>
    );
  const reportUrlPaths = currentTest?.reportUrlPaths || [];
  const downloadEnabled = reportUrlPaths.length > 0;

  const downloadPdfs = async () => {
    for (const reportUrl of reportUrlPaths) {
      const downloadLinkRes = await downloadResults(reportUrl);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.title = 'PDF download iFrame';
      iframe.src = downloadLinkRes.downloadLink;
      document.body.appendChild(iframe);
    }
  };

  return (
    <Wrapper>
      <RelativeWrap>
        <ContentWrap>
          <div className="title-wrapper">
            <Title>Dear {fname}</Title>
            {downloadEnabled && (
              <button className="btn" onClick={downloadPdfs}>
                Download Results
              </button>
            )}
          </div>
          <div>
            <p>Thank you for testing with us. {resultAdvisoryText}</p>
          </div>
        </ContentWrap>
        <Icon />
      </RelativeWrap>
    </Wrapper>
  );
};

Commentary.propTypes = {
  fname: PropTypes.string.isRequired,
};

export default withTheme(Commentary);
