import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const initialState = {
  customizedCookieSettings: false
};

const ThemeContext = createContext(initialState);

const Theme = ({ children }) => {
  const location = useLocation();
  const [customized, setCustomized] = useState(initialState.customizedCookieSettings || false);

  useEffect(() => {
    setCustomized(!location.pathname.match(/.*\/register\/./));
  }, [location.pathname]);
  
  const data = {
    customizedCookieSettings: customized,
  };
  return (
    <ThemeContext.Provider value={data}>{children}</ThemeContext.Provider>
  );
};

export { Theme, ThemeContext };