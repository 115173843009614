import PropTypes from 'prop-types';
import fallbackInterpretationContent from '../fallbackInterpretationContent.json';

const FallbackInterpretationContent = ({ interpretation }) => {
  const content = fallbackInterpretationContent[interpretation?.toLowerCase()];

  return content && <div className="biomarker-modal__text">{content}</div>;
};

FallbackInterpretationContent.propTypes = {
  interpretation: PropTypes.string.isRequired,
};

export default FallbackInterpretationContent;
