import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PhoneInput from '../../common/phoneInput';
import Loader from '../../common/loader';

import validationRules from '../../helpers/validationRules';
import { validated } from 'react-custom-validation';
import FormSection from './component/form-section';
import CountryInput from '../../common/countryInput';
import { StyledButton, StyledWhiteButton } from '../../../theming/themed-components';

class AccountProfile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      testUser = {},
      handleInputChange,
      handleFormInvalid,
      handleFormValid,
      submitError,
      successMessage,
      disableSubmitButton,
      countrys,
      hasSetSubscription,
    } = this.props;

    if (!(countrys && countrys.length)) {
      return <Loader />;
    }

    return (
      <div>
        <Helmet>
          <title>Edit Profile - Hurdle</title>
        </Helmet>

        <EditProfileForm
          fields={this.props.fields}
          onChange={handleInputChange}
          onInvalid={handleFormInvalid}
          onValid={handleFormValid}
          submitError={submitError}
          successMessage={successMessage}
          disableSubmitButton={disableSubmitButton}
          countrys={countrys}
          testUser={testUser}
          hasSetSubscription={hasSetSubscription}
        />
      </div>
    );
  }
}

export default withRouter(AccountProfile);

function editProfileValidationConfig(props) {
  const {
    email,
    mobile_country,
    mobile,
    delivery_fname,
    delivery_lname,
    delivery_address1,
    delivery_address2,
    delivery_city,
    delivery_postcode,
    delivery_country,
  } = props.fields;
  const { fname: firstName, lname: lastName } = props.testUser || {};
  let fields = [
    'current_password',
    'password',
    'confirm_password',
    'delivery_fname',
    'delivery_lname',
    'delivery_address1',
    'delivery_address2',
    'delivery_city',
    'delivery_postcode',
    'delivery_country',
  ];

  let validations = {
    email: [
      [validationRules.isRequired, email],
      [validationRules.isEmail, email],
    ],
    mobile_country: [[validationRules.isRequired, mobile_country]],
    mobile: [
      [validationRules.isRequired, mobile],
      [validationRules.isMobile, mobile],
    ],
    delivery_fname: [],
    delivery_lname: [],
    delivery_address1: [],
    delivery_address2: [],
    delivery_city: [],
    delivery_postcode: [[validationRules.isPostCode, delivery_postcode, delivery_country]],
    delivery_country: [],
  };

  if (firstName || lastName) {
    const { fname, lname } = props.fields;
    fields = ['fname', 'lname', ...fields];
    validations = {
      fname: [[validationRules.isRequired, fname]],
      lname: [[validationRules.isRequired, lname]],
      ...validations,
    };
  }

  return {
    fields,
    validations,
  };
}

class EditProfileForm extends Component {
  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
      hasSetSubscription,
      countrys,
      submitError,
      disableSubmitButton,
      successMessage,
      history,
      testUser = {},
    } = this.props;

    const selectedCountry = countrys.filter(item => item.dialing_code == (fields && fields.mobile_country));

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.props.$submit(onValid, onInvalid);
        }}
        className="form account-form checkout-form"
      >
        <div className="edit-profile__wrapper">
          <div className="layout-2col__box">
            <div className="edit-profile">
              {testUser?.fname && testUser?.lname && (
                <Fragment>
                  <FormSection
                    $field={$field}
                    fields={fields}
                    id="fname"
                    isInputElement={true}
                    label="First Name"
                    name="fname"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    required
                    type="text"
                    $validation={$validation}
                  />

                  <FormSection
                    $field={$field}
                    fields={fields}
                    id="lname"
                    isInputElement={true}
                    label="Last Name"
                    name="lname"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    required
                    type="text"
                    $validation={$validation}
                  />
                </Fragment>
              )}

              <FormSection
                preFixClass="edit-profile"
                fields={fields}
                label="E-mail"
                id="email"
                required
                $field={$field}
                onChange={onChange}
                type="email"
                isInputElement={true}
                name="email"
                $validation={$validation}
                placeholder="order@chronomics.co.uk"
              />
              <div className="edit-profile__row">
                <label htmlFor="mobile" className="form__label cell">
                  Mobile Number <span className="form__required">*</span>
                </label>
                <div className="cell edit-profile__mobile-country">
                  <PhoneInput
                    value={fields.mobile || ''}
                    onChange={onChange}
                    placeholder="Mobile Number"
                    className="checkout-form__mobile"
                    countries={countrys}
                    country={
                      fields.mobile.includes(`+${fields.mobile_country}`) ? null : selectedCountry[0] && selectedCountry[0].country_code
                    }
                  />
                  <div className="form__error">{$validation.mobile.show ? $validation.mobile.error.reason : ''}</div>
                </div>
              </div>

              {hasSetSubscription && (
                <Fragment>
                  <div className="edit-profile__row">
                    <p>Shipping Address Details:</p>
                  </div>
                  <FormSection
                    $field={$field}
                    fields={fields}
                    id="delivery_fname"
                    isInputElement={true}
                    label="First Name"
                    name="delivery_fname"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    type="text"
                    $validation={$validation}
                  />

                  <FormSection
                    $field={$field}
                    fields={fields}
                    $validation={$validation}
                    id="delivery_lname"
                    isInputElement={true}
                    label="Last Name"
                    name="delivery_lname"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    type="text"
                    placeholder=""
                  />
                  <FormSection
                    $field={$field}
                    fields={fields}
                    $validation={$validation}
                    id="delivery_address1"
                    isInputElement={true}
                    label="Address Line 1"
                    name="delivery_address1"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    type="text"
                    placeholder=""
                  />
                  <FormSection
                    $field={$field}
                    fields={fields}
                    $validation={$validation}
                    id="delivery_address2"
                    isInputElement={true}
                    label="Address Line 2"
                    name="delivery_address2"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    type="text"
                    placeholder=""
                  />
                  <FormSection
                    $field={$field}
                    fields={fields}
                    $validation={$validation}
                    id="delivery_city"
                    isInputElement={true}
                    label="City"
                    name="delivery_city"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    type="text"
                    placeholder=""
                  />
                  <FormSection
                    $field={$field}
                    fields={fields}
                    $validation={$validation}
                    id="delivery_postcode"
                    isInputElement={true}
                    label="Postcode/Zipcode"
                    name="delivery_postcode"
                    onChange={onChange}
                    preFixClass="edit-profile"
                    type="text"
                    placeholder=""
                  />
                  <div className="edit-profile__row">
                    <label htmlFor="delivery_country" className="form__label cell">
                      Country
                    </label>
                    <div className="cell edit-profile__mobile-country">
                      <CountryInput
                        value={fields && fields.delivery_country ? fields.delivery_country : ''}
                        countries={countrys}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="form__row edit-profile__button-container">
            <StyledButton className="btn" disabled={disableSubmitButton} type="submit">
              {disableSubmitButton ? 'Saving...' : 'Save'}
              <i className="icon-right-open" />
            </StyledButton>
            <StyledWhiteButton className="btn cancel" onClick={() => history.push('/dashboard/account')} type="button">
              Cancel
              <i className="icon-right-open" />
            </StyledWhiteButton>
            {successMessage && <p className="account-form__success">{successMessage}</p>}
            {submitError && <div className="form__error form__error--general">{submitError}</div>}
          </div>
        </div>
      </form>
    );
  }
}

EditProfileForm = withRouter(validated(editProfileValidationConfig)(EditProfileForm));
