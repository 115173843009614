import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import update from 'immutability-helper';
import axios from 'axios';
import { Link } from 'react-router-dom';

import withError from '../../components/common/withError';
import Loader from '../../components/common/loader';
import { getOrders as getOrdersAction , updateOrders } from '../../services/you/actions';
import withErrorBoundary from '../../components/common/errorBoundary';
import { ordersSelector } from '../../services/you/selectors';
import Shipping from '../../components/join/shipping';
import config from '../../config';
import { getOrders } from '../../services/you/api';
import Travel from '../../components/join/travel';
import utils from '../../components/helpers/utils';

const getProductVariationSuk = (order) => {
  if (order?.items && order.items.length > 0) {
     return order.items[0].suk;
  }
};

const OrderEdit = function EditOrder({ match, orders, fetchOrders, dispatch}) {

    const { email, mobile_country, mobile } = config.app.user;
    const order = orders && orders.data.find(order => order.id === +match.params.id) || {
        ttr_details: {
            arrival_date: '',
            departure_country: '',
            departure_date: '',
            flight_number: '',
            transit_countries: '',
            persons: []
        }
    };
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [shippingFields, setFields] = useState(null);
    const [submitError, setSubmitError] = useState(false);
    const [flashMessage, setFlashMessage] = useState();
    const arrivalDate = order && order.ttr_details && order.ttr_details.inbound_to_date && order.ttr_details.inbound_to_date.length ? order.ttr_details.inbound_to_date.split('-') : '';
    let departureDate = '';
    if (order && order.required_outbound_from) {
        departureDate = order && order.ttr_details && order.ttr_details.outbound_from_date && order.ttr_details.outbound_from_date.length ? order.ttr_details.outbound_from_date.split('-') : '';
    } else {
        departureDate = order && order.ttr_details && order.ttr_details.inbound_from_date && order.ttr_details.inbound_from_date.length ? order.ttr_details.inbound_from_date.split('-') : '';
    }

    const fields = {
        arrival_date: (order.ttr_details && order.ttr_details.inbound_to_date) || '',
        arrival_day: arrivalDate ? arrivalDate[2] : '',
        arrival_month: arrivalDate ? arrivalDate[1] : '',
        arrival_year: arrivalDate ? arrivalDate[0] : '',
        departure_country: (order.ttr_details && order.ttr_details.departure_country) || '',
        departure_date: (order.ttr_details && order.ttr_details.inbound_from_date) || '',
        departure_day: departureDate ? departureDate[2] : '',
        departure_month: departureDate ? departureDate[1] : '',
        departure_year: departureDate ? departureDate[0] : '',
        flight_number: (order.ttr_details && order.ttr_details.flight_number) || '',
        transit_countries: (order.ttr_details && order.ttr_details.transit_countries) || '',
        transit_countries_list: (order.ttr_details && order.ttr_details.transit_countries_list) || [],
        transit_country_1: order.ttr_details && order.ttr_details.transit_countries_list && order.ttr_details.transit_countries_list[0] ? order.ttr_details.transit_countries_list[0] : '',
        transit_country_2: order.ttr_details && order.ttr_details.transit_countries_list && order.ttr_details.transit_countries_list[1] ? order.ttr_details.transit_countries_list[1] : '',
        transit_country_3: order.ttr_details && order.ttr_details.transit_countries_list && order.ttr_details.transit_countries_list[2] ? order.ttr_details.transit_countries_list[2] : '',
        persons: [],
    };

    if (order.ttr_details && Array.isArray(order.ttr_details.persons)) {
        order.ttr_details.persons.forEach((item, index) => {
            const firstNameField = `pax_${index + 1}_first_name`;
            const lastNameField = `pax_${index + 1}_last_name`;
            fields[firstNameField] = item.first_name;
            fields[lastNameField] = item.last_name;
        });
    }

    const quantity = order.ttr_details && Array.isArray(order.ttr_details.persons) ? order.ttr_details.persons.length : 1;

    useEffect(() => {
        fetchOrders();
        axios.get('products/shiping_countrys').then(response => setCountries(response.data.data));
    }, []);

    if (isLoading || !orders || !countries.length) {
        return <Loader />;
    }

    if (!shippingFields) {
        setFields(initFields(order.shipping_address, countries, fields));
    }

    function handleInputChange(field, value = '') {
        setFields(fields => update(fields, {[field]: {$set: value}}));
        setSubmitError(false);
    }

    function onSubmit() {
        setSubmitError(false);
        setIsLoading(true);
        const postFields = JSON.parse(JSON.stringify(shippingFields));
        postFields.travelDetails.persons = [];
        for (let personIdx = 0; personIdx < quantity; personIdx++) {
            const firstNameField = `pax_${personIdx + 1}_first_name`;
            const lastNameField = `pax_${personIdx + 1}_last_name`;
            postFields.travelDetails.persons.push({
                first_name: postFields.travelDetails[firstNameField] || '',
                last_name: postFields.travelDetails[lastNameField] || '',
            });
            delete postFields.travelDetails[firstNameField];
            delete postFields.travelDetails[lastNameField];
        }

        postFields.travelDetails.inbound_to_date = postFields.travelDetails.arrival_year ? `${postFields.travelDetails.arrival_year}-${utils.ifSingleDigitReturnDouble(postFields.travelDetails.arrival_month)}-${utils.ifSingleDigitReturnDouble(postFields.travelDetails.arrival_day)}` : '';
        if (order.required_outbound_from) {
            postFields.travelDetails.outbound_from_date = `${postFields.travelDetails.departure_year}-${utils.ifSingleDigitReturnDouble(postFields.travelDetails.departure_month)}-${utils.ifSingleDigitReturnDouble(postFields.travelDetails.departure_day)}`;
            delete postFields.travelDetails.inbound_from_date;
            delete postFields.travelDetails.inbound_to_date;
        } else {
            postFields.travelDetails.inbound_from_date = `${postFields.travelDetails.departure_year}-${utils.ifSingleDigitReturnDouble(postFields.travelDetails.departure_month)}-${utils.ifSingleDigitReturnDouble(postFields.travelDetails.departure_day)}`;
        }

        postFields.travelDetails.transit_countries_list = [];
        if (postFields.travelDetails.transit_country_1) {
            postFields.travelDetails.transit_countries_list.push(postFields.travelDetails.transit_country_1);
        }
        if (postFields.travelDetails.transit_country_2) {
            postFields.travelDetails.transit_countries_list.push(postFields.travelDetails.transit_country_2);
        }
        if (postFields.travelDetails.transit_country_3) {
            postFields.travelDetails.transit_countries_list.push(postFields.travelDetails.transit_country_3);
        }

        if (postFields.travelDetails.transit_countries === 'no' && postFields.travelDetails.transit_countries_list.length) {
            postFields.travelDetails.transit_country_1 = '';
            postFields.travelDetails.transit_country_2 = '';
            postFields.travelDetails.transit_country_3 = '';
        }

        delete postFields.travelDetails.departure_date;
        delete postFields.travelDetails.departure_day;
        delete postFields.travelDetails.departure_month;
        delete postFields.travelDetails.departure_year;
        delete postFields.travelDetails.arrival_date;
        delete postFields.travelDetails.arrival_day;
        delete postFields.travelDetails.arrival_month;
        delete postFields.travelDetails.arrival_year;

        axios
            .put(`user/orders/${order.id}`, {
                ...postFields,
                delivery_country: countryCodeToTitle(countries, shippingFields.delivery_country)
            })
            .then((response) => {
                if (response.data.status) {
                    return getOrders();
                } else {
                    throw new Error();
                }
            })
            .then(orders => {
                window.scrollTo(0,0);
                setIsLoading(false);
                setFlashMessage({ success: true, message: 'Order updated' });
                dispatch(updateOrders(orders));
            })
            .catch((e) => {
                window.scrollTo(0,0);
                setIsLoading(false);
                if (e.response.data.error && e.response.data.error.errors) {
                    const errKeys = Object.keys(e.response.data.error.errors);
                    if (errKeys.length && errKeys[0]) {
                        if (Array.isArray(e.response.data.error.errors[errKeys[0]])) {
                            setFlashMessage({ success: false, message: e.response.data.error.errors[errKeys[0]][0] });
                        } else {
                            setFlashMessage({ success: false, message: e.response.data.error.errors[errKeys[0]] });
                        }
                    }
                } else {
                    setFlashMessage({ success: false, message: 'Failed to update order' });
                }
            });
    }

    const handleTravelInputChange = (field, value = '') => {
        setFields({...shippingFields, travelDetails: {...shippingFields.travelDetails, [field]: value}});
        setSubmitError(false);
    };

    const handleFormValid = () => {
        onSubmit();
    };

    const handleFormInvalid = () => {
        setSubmitError(true);
    };

    return (
        <section className="order-edit__container">
            <div className="order-edit__top">
                <Link to="/dashboard/orders">
                    <span className="arrow left"></span>
                    <span>Back</span>
                </Link>

                {flashMessage && (
                    <div className={'order-edit__flash-message order-edit__flash-message--' + (flashMessage.success ? 'success' : 'fail')}>
                        {flashMessage.message}
                    </div>
                )}
            </div>
            <Shipping
                fields={{ ...shippingFields, ...{ email, mobile_country, mobile } }}
                variation={{ suk: getProductVariationSuk(order) }}
                onChange={handleInputChange}
                countrys={countries}
                submitError={submitError}
                hideStepNumber={order.can_modify}
                submitButtonText="Save"
                isContactInfoDisabled={true}
                isCountryDisabled={order.ttr_users.length > 0}
                isCompleted={!order.can_modify}
                onValid={onSubmit}
                onInvalid={() => setSubmitError(true)}
            />

            {(order.is_gb_travel || (!order.is_gb_travel && order.required_outbound_from)) &&
            <Travel
                submitError={submitError}
                fields={shippingFields ? shippingFields.travelDetails : fields}
                quantity={order.required_outbound_from ? 0 : quantity}
                countrys={countries}
                onValid={handleFormValid}
                onChange={handleTravelInputChange}
                onInvalid={handleFormInvalid}
                isCompleted={!order.can_modify}
                shouldExpand={true}
                hideStepNumber={true}
                requiredOutboundFrom={order.required_outbound_from}
                submitButtonText="Save"
            />}

        </section>
    );
};


const mapStateToProps = (state) => ({
    orders: ordersSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchOrders: () => dispatch(getOrdersAction()),
});

const enhance = compose(
    withErrorBoundary,
    connect(mapStateToProps, mapDispatchToProps),
    withError({reducer: 'you', page: 'you'}),
);

export default enhance(OrderEdit);
export { OrderEdit as __TEST_OrderEdit };

function initFields(shipping_address, countries, fields) {
    return {
        ...shipping_address,
        delivery_country: countryTitleToCode(countries, shipping_address.delivery_country) || '',
        travelDetails: fields,
    };
}

function countryTitleToCode(countries, searchTitle) {
    const country = countries.find(({ title }) => title === searchTitle);
    return country && country.country_code;
}

function countryCodeToTitle(countries, searchCode) {
    const country = countries.find(({ country_code }) => country_code === searchCode);
    return country && country.title;
}
