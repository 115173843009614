import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { StyledSpan } from '../../theming/themed-components';

const ActiveSurveyStatuses = ['INITIALIZED', 'IN_PROGRESS'];

const isReady = test => test.status === 'PROCESSED';
const hasActiveQuestionnaire = test => ActiveSurveyStatuses.includes(test.questionnaire?.status);

const makeTestUrl = (baseUrl, test) => {
  if (hasActiveQuestionnaire(test)) {
    return `/register/questionnaire/introduction?kitId=${test.kitId}`
  }
  if (!isReady(test)) {
    return '#';
  }

  return `${baseUrl}/results/${test.id}`;
};

// TODO:
// -> Add propTypes and defaultProps once 100% sure
const GeneralHealth = ({ tests, match: { url }, displayHeader = true }) => {
  if (!tests || !tests.data || !tests.data.length) return null;

  return (
    <div className="dashboard-section__section-container">
      {displayHeader && <h2 className="dashboard-section__sub-title">Your Tests</h2>}
      <div className="dashboard-section__results-section">
        {tests.data.map(test => {
          const { id, registeredAt, productName } = test;
          const isTestReady = isReady(test);
          const testHasActiveQuestionnaire = hasActiveQuestionnaire(test);

          return (
            <div className={`dashboard-section__single-result more-padding ${!isTestReady ? 'no-link' : ''}`} key={id} >
              <div className="column-container">
                <div className="row-container">
                  <Link className="dashboard-section__single-result__link no-underline" to={makeTestUrl(url, test)}>
                      <div className="result-header">
                        <h5 className="result-title">{registeredAt ? moment(registeredAt).format('Do MMM YYYY') : ''}</h5>
                        <p className="result-text">{productName}</p>
                      </div>
                      { testHasActiveQuestionnaire ? (
                        <button className="btn">Complete questionnaire</button>
                      ) : isTestReady ? (
                        <p className="link">
                          <StyledSpan>View</StyledSpan> <StyledSpan className="icon-right-open" />
                        </p>
                      ) : (
                        <p>Awaiting result</p>
                      )}
                  </Link>
                </div>
                {testHasActiveQuestionnaire && (
                  <span style={{textAlign: 'right'}}>
                    Please complete this short lifestyle questionnaire for us to provide you with your results.
                  </span>
                  )
                }
              </ div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GeneralHealth;
