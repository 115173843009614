import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Modal from 'react-responsive-modal';

// import DataSharePreferences from "./dataSharePreferencesCard";

import utils from '../helpers/utils';
import DataSharePreferences from '../../containers/improvements/team/dataSharePreferencesCard';

const COACHES_URL = 'test_user/coaches';

class Card extends Component {
    constructor(props) {
        super(props);
        const { has_selected: hasSelected, accepted_terms } = props;
        this.state = {
            shouldDisplayDataSharePreferences: false,
            hasSelected,
            acceptedTerms: accepted_terms,
            showModal: false,
        };

        this.handelClick = this.handelClick.bind(this);
        this.deleteCoach = this.deleteCoach.bind(this);
        this.toggleDataSharePreferences = this.toggleDataSharePreferences.bind(this);
        this.setStates = this.setStates.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState((prev) => ({showModal: !prev.showModal}));
    }

    deleteCoach() {
        const { id: coach_id } = this.props;
        const { hasSelected } = this.state;
        axios.delete(COACHES_URL, { data: { coach_id } })
            .then(
                () => {
                    this.setState({
                        hasSelected: !hasSelected,
                        showModal: false,
                    });

                    utils.track('Removed Coach', {coach_id: coach_id});
                }
            );
    }

    toggleDataSharePreferences() {
        const { shouldDisplayDataSharePreferences } = this.state;
        this.setState({
            shouldDisplayDataSharePreferences: !shouldDisplayDataSharePreferences
        });
    }

    handelClick(openChat) {
        const { fname, invitePeople, coach_id } = this.props;
        if(invitePeople) {
            openChat('Leave their name, number and/or email and we will get in touch about joining the Hurdle platform');
            utils.track('Invite Coach');
        }
        else {
            this.state.hasSelected
                ? openChat(`I would like to book a session with ${fname}`)
                : this.toggleDataSharePreferences();

            utils.track('Book Coach Session', {coach_id: coach_id});
        }
    }

    setStates(state) {
        this.setState(state);
    }

    getCard() {
        const { fname, lname, category, photo, occupation, brief_description, locked, buttonText ,disableLink, openChat} = this.props;
        const { hasSelected } = this.state;
        let ButtonText = buttonText ? buttonText : hasSelected ? 'Book session' : 'Add to team';

        if (locked) {
            ButtonText = 'Awaiting results';
        }

        return (
            <Fragment>
                {locked && <img className="locked" src="/img/dashboard/improvement/lock.png" alt="lock" />}
                {!disableLink ? hasSelected && <i className="icon-cancel locked" onClick={this.toggleModal} title="Remove from team"/> : <i className="icon-cancel locked" title="Remove from team"/> }
                {photo ?
                    <div className="card__member-image"><img src={photo} alt={fname} /></div>
                    :
                    <div className="card__member-image card__member-image--no-photo">?</div>
                }
                <p className="card__member-name">{fname} {lname}</p>
                {occupation && <p className="card__occupation">{occupation}</p>}
                <p className="card__description">{brief_description}</p>
                { 
                    !disableLink ? <button className="btn btn--pale-orange" data-tut="Add-to-team-button" onClick={()=>this.handelClick(openChat)}>{ButtonText}</button>:<div className="btn btn--pale-orange">{ButtonText}</div>
                }
                {hasSelected &&
                    <div
                        className="card__edit-data-sharing-preferences"
                        onClick={this.toggleDataSharePreferences}
                    >
                        Edit data sharing preferences
                    </div>
                }
            </Fragment>
        );
    }


    render() {
        const { locked, id, fname, coachPermissions, category, coachCount, occupation } = this.props;
        const { shouldDisplayDataSharePreferences, hasSelected, acceptedTerms, showModal } = this.state;
        const lockCardClass = locked ? 'locked-card' : '';

        return (
            <div data-tut="team-member-card" className={`card ${lockCardClass} _${coachCount}${`${shouldDisplayDataSharePreferences ? ' data-share-preferences' : ''}`}`}>
                {locked && <div className="card__overlay"/>}
                {shouldDisplayDataSharePreferences
                    ? <DataSharePreferences
                        acceptedTerms={acceptedTerms}
                        fname={fname}
                        category={category}
                        coachPermissions={coachPermissions}
                        hasSelected={hasSelected}
                        id={id}
                        occupation={occupation}
                        setStates={this.setStates}
                        shouldDisplayDataSharePreferences={shouldDisplayDataSharePreferences}
                      />
                    : this.getCard()
                }
                <Modal
                    center
                    classNames={{modal: 'improvement-container__modal', closeButton: 'close-button'}}
                    onClose={this.toggleModal}
                    open={showModal}
                >
                    <h3 className="modal-header">Remove Coach?</h3>
                    <p className="modal-description">Are you sure you want to remove this improvement provider?</p>
                    <p className="modal-description">This will remove all access to your data so they can no longer provide personalised improvements.</p>
                    <div className="modal-buttons-container">
                        <button className="btn btn--light-green-1" onClick={this.deleteCoach}>confirm</button>
                        <button className="btn btn--red" onClick={this.toggleModal}>cancel</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

Card.defaultProps = {
    accepted_terms: false,
};

export default Card;
