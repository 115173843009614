import PropTypes from 'prop-types';
import { formatDeltaAge } from './deltaAge';

/**
 * Assembles the message to display in the ResultHero component.
 *
 * @param {number} adjustedDeltaAge
 * @param {string} interpretation
 * @returns string
 */
const assembleHeroDescription = (adjustedDeltaAge, interpretation, statements) => {
  let deltaAgeStatement;

  if (adjustedDeltaAge > 0) {
    deltaAgeStatement = statements.byDeltaAge.whenPositive;
  } else if (adjustedDeltaAge == 0) {
    deltaAgeStatement = statements.byDeltaAge.whenSame;
  } else {
    deltaAgeStatement = statements.byDeltaAge.whenNegative;
  }

  const interpretationStatement = statements.byInterpretations[interpretation];

  return [deltaAgeStatement.replace('$DELTA_AGE', formatDeltaAge(adjustedDeltaAge)), interpretationStatement].join(' ');
};

const ResultHero = ({ adjustedDeltaAge, interpretation, statements }) => {
  return (
    <>
      <p data-tut="health-indicator-details" className="result-hero__biological-description">
        {assembleHeroDescription(adjustedDeltaAge, interpretation, statements)}
      </p>

      <div className="disease-risk__epigenetics-section">
        <img alt="epigenetics" src="/img/you/actionable-you/daniel-herranz.png" />
        <p className="epigenetic-title">Dr. Daniel E. Martín Herranz Epigenetics expert</p>
      </div>
    </>
  );
};

ResultHero.propTypes = {
  adjustedDeltaAge: PropTypes.number.isRequired,
  interpretation: PropTypes.string,
  statements: PropTypes.object.isRequired,
};

export default ResultHero;
