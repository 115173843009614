import React from 'react';
import styled from 'styled-components';
import useResultsHelper from './useRangeBarHelper';
import Bars from './Bars';

const ResultsBarContainer = styled.div`
  width: 100%;
`;

const RangeBar = ({ interpretationDataValue, result }) => {
  const { getRangeBarConfig } = useResultsHelper();
  const barConfig = getRangeBarConfig({ interpretationDataValue, result });

  return (
    <>
      <ResultsBarContainer>{barConfig && <Bars barConfig={barConfig} />}</ResultsBarContainer>
    </>
  );
};

export default RangeBar;
