import PropTypes from 'prop-types';

const FailedResultContent = ({ result }) => (
  <>
    <div
      className="biomarker-modal__text"
      dangerouslySetInnerHTML={{
        __html: result?.chronomicsExplanation,
      }}
    />
    {!result?.chronomicsExplanation && (
      <div className="biomarker-modal__text">
        The laboratory was unable to provide a result for this biomarker, this can happen for many reasons. Please contact us for further
        support.
      </div>
    )}
  </>
);

FailedResultContent.propTypes = {
  result: PropTypes.oneOfType([
    PropTypes.shape({
      chronomicsStatus: PropTypes.string.isRequired,
      chronomicsExplanation: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
};

export default FailedResultContent;
