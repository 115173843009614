import { getBarColorFromRangeName } from './utils';

const useResultsHelper = () => {
  /**
   * Converts reference range limit value to a regular number.
   * e.g. "<1" will be converted to 1, "">=5" will be converted to 5 and so on.
   * @param {*} rangeString
   * @returns number
   */
  const getRangeNumber = rangeString => {
    const numberIndex = rangeString.search(/[0-9]/);

    return parseFloat(rangeString.substring(numberIndex, rangeString.length));
  };

  const largestNumberInArray = numberArray => {
    const allRangesValuesInOneArray = numberArray.reduce((acc, val) => {
      return (acc = [...acc, ...val]);
    });

    return Math.max(...allRangesValuesInOneArray);
  };

  const getBuffer = number => {
    return number / 10;
  };

  /**
   * Sorts keys in the interpretationDataValue object based on the reference ranges associated to them.
   *
   * e.g. if interpretationDataValue has keys sorted as follows
   *
   * {
        "NORMAL": [
            ">=1",
            "<=5"
        ],
        "HIGH": [
            ">5"
        ],
        "LOW": [
            "<1"
        ],
     }
   *
   * keys are not sorted in a correct way, since reference range tied to first interpretation/key (in this
   * case NORMAL) starts with >=1 which is greater than <1 tied to the last interpretation/key (in this
   * case LOW).
   * 
   * After sorting object will look as follows
   * 
   * {
        "LOW": [
            "<1"
        ],
        "NORMAL": [
            ">=1",
            "<=5"
        ],
        "HIGH": [
            ">5"
        ]
     }
   *
   * @param {*} interpretationDataValue
   * @returns interpretationData with keys sorted based on reference ranges associated with them
   */
  const sortInterpretationsByReferenceRange = interpretationDataValue => {
    // Transform interpretationDataValue object to a tuple of interpretation
    // and reference range array e.g. [["LOW", ["<1"], ["NORMAL", [">1", "<5"],...]
    // and sort tuples based on the reference range values
    const sortedInterpretationsByRange = Object.entries(interpretationDataValue).sort((a, b) => {
      const [keyA, rangeA] = a;
      const [keyB, rangeB] = b;

      if (getRangeNumber(rangeA[0]) === getRangeNumber(rangeB[0])) {
        return rangeA.length < rangeB.length ? -1 : 1;
      }
      return getRangeNumber(rangeA[0]) < getRangeNumber(rangeB[0]) ? -1 : 1;
    });

    // Recreate interpretationDataValue from sorted array of tuples
    const sortedInterpretationDataValue = sortedInterpretationsByRange.reduce((acc, current) => {
      const [interpretation, range] = current;
      acc[interpretation] = range;
      return acc;
    }, {});

    return sortedInterpretationDataValue;
  };

  const getRangeBarConfig = ({ interpretationDataValue, result }) => {
    // Keys in interpretationData might not be in expected order and that
    // can cause completePercentageRange to be calculated incorrectly,
    // therefore we have to sort interpretationDataValue keys based on
    // reference ranges associated with them.
    const sortedInterpretationDataValue = sortInterpretationsByReferenceRange(interpretationDataValue);
    const rangeNumbers = Object.keys(sortedInterpretationDataValue).map(rangeKey => {
      const range = sortedInterpretationDataValue[rangeKey].map(item => {
        const rangeNumber = getRangeNumber(item);

        return rangeNumber;
      });
      return range;
    });

    const largestRangeNumber = largestNumberInArray(rangeNumbers);

    const resultNumber = parseFloat(result);

    // In the case where the result is way higher than the highest range by a factor of 10, divide the result by a factor of 10 to prevent the result bar from squashing.
    const barArrowValue = resultNumber > largestRangeNumber * 10 ? resultNumber / 10 : resultNumber;

    const largestNumber = largestRangeNumber < barArrowValue ? barArrowValue : largestRangeNumber;

    const limitValue = largestNumber + getBuffer(largestNumber);

    const rangePercentages = rangeNumbers.map((numberList, i) => {
      // Convert each value in the range to a percentage based on the limit value.
      if (rangeNumbers.length > 1) {
        const percentageList = numberList.map(num => {
          return ((num / limitValue) * 100).toFixed(0);
        });

        if (percentageList.length > 1) {
          if (i === 0) {
            //The first array should by default be a percentage based on the max range value from lower limit (0%) -> n%
            return Math.max(...percentageList);
          } else if (rangeNumbers.length - 1 === i) {
            //The last array should be a percentage based on min range value to the upper limit -> (100%)
            return 100 - Math.min(...percentageList);
          }
          // Array's in between should be a percentage based on their own max value from the lower value (max% - min%)
          return Math.max(...percentageList) - Math.min(...percentageList);
        }

        if ((rangeNumbers.length > 1 && rangeNumbers.length - 1) === i) {
          //The last array should be a percentage based on min range value to the upper limit -> (100%)
          return 100 - Math.max(...percentageList);
        }

        // This should only be the first value if the data format is correct.
        return Math.max(...percentageList);
      } else {
        return 100;
      }
    });

    const resultBarPercentage = parseInt(((barArrowValue / limitValue) * 100).toFixed(0));

    const completePercentageRange = rangePercentages.map((percent, i) => {
      return {
        rangeName: Object.keys(sortedInterpretationDataValue)[i],
        barColor: getBarColorFromRangeName(Object.keys(sortedInterpretationDataValue)[i]),
        range: rangeNumbers[i],
        barPercentage: percent,
      };
    });

    return {
      resultValues: {
        result: resultNumber,
        barPercentage: resultBarPercentage,
      },
      rangeValues: completePercentageRange,
    };
  };

  return { getRangeBarConfig, getBuffer };
};

export default useResultsHelper;
