export default {
  DEFICIENT: 'positive',
  Deficient: 'positive',
  INSUFFICIENT: 'positive',
  Insufficient: 'positive',
  L: 'positive',
  LOW: 'positive',
  Low: 'positive',
  H: 'positive',
  HIGH: 'positive',
  High: 'positive',
  RAISED: 'positive',
  Raised: 'positive',
  VERY_HIGH: 'positive',
  'Very high': 'positive',
  'Pre-Diabetic': 'positive',
  'Pre-diabetic': 'positive',
  Diabetic: 'positive',
  Diabetes: 'positive',
  Positive: 'positive',
  POSITIVE: 'positive',
  Pregnant: 'positive',
  Detected: 'positive',
  Reactive: 'positive',
  Negative: 'negative',
  NEGATIVE: 'negative',
  'Not Pregnant': 'negative',
  N: 'negative',
  Normal: 'negative',
  NORMAL: 'negative',
  Adequate: 'negative',
  ADEQUATE: 'negative',
  Optimal: 'negative',
  OPTIMAL: 'negative',
  IDEAL: 'negative',
  Ideal: 'negative',
  'Not Detected': 'negative',
  'Non Reactive': 'negative',
};
