import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Indicator from './indicator';
import ChromosomeAge from './chromosomeAge';
import Compare from './compare';
import Transposons from './transposons';
import YouPageV3 from './youPage/youPage.v3';

import SubMenuBar from '../../components/common/subMenuBar';
import withErrorBoundary from '../../components/common/errorBoundary';
import RestoreScroll from '../../components/common/restoreScroll';
import withError from '../../components/common/withError';
import SplitConsumer from '../../components/context/splitConsumer';
import { SplitContext } from '../../components/context/splitProvider';

import Intervenable from '../../components/you/actionable/intervenable';
import Search from '../../components/you/search';

import { getYou, setSelectedTest } from '../../services/you/actions';
import { youSelector, youLoadingSelector, selectedTestSelector } from '../../services/you/selectors';

const youLink = [{ type: 'link', title: 'Health', link: 'you' }];

class You extends Component {
  componentDidMount() {
    const {
      location: { search },
      fetchYou,
    } = this.props;
    const searchParams = qs.parse(search);

    const id = searchParams && (searchParams.previous_test || searchParams.test_user_test);
    fetchYou(id);
  }

  render() {
    const { breakpoint, testUser, location, match, fetchYou, isLoading, results, selectedTest, updateSelectedState, openChat } = this.props;
    const YouPageComponent = props => (
      <RestoreScroll>
        <YouPageV3
          breakpoint={breakpoint}
          data={results}
          fetchResults={fetchYou}
          isLoading={isLoading}
          selectedTest={selectedTest}
          testUser={testUser}
          openChat={openChat}
          updateSelectedState={updateSelectedState}
          {...props}
        />
      </RestoreScroll>
    );

    const IndicatorPage = props => {
      let selectedTab = 'overview';
      if (props.location.pathname.includes('faq')) {
        selectedTab = 'faq';
      } else if (props.location.pathname.includes('science')) {
        selectedTab = 'science';
      }

      return (
        <SplitContext.Consumer>
          {({ client, isReady }) =>
            isReady ? <Indicator {...props} breakpoint={breakpoint} client={client} selectedTab={selectedTab} testUser={testUser} /> : ''
          }
        </SplitContext.Consumer>
      );
    };

    const IntervenablePage = props => {
      const slug = props.match.params.slug || null;
      return (
        <div>
          <Intervenable {...props} breakpoint={breakpoint} slug={slug} />
        </div>
      );
    };

    const ChromosomeAgePage = props => <ChromosomeAge {...props} breakpoint={breakpoint} testUser={testUser} />;

    const ComparePage = props => (
      <div>
        <Helmet>
          <title>Compare - Hurdle</title>
        </Helmet>
        <SplitConsumer name="show_compare_feature">
          {value => (value == 'on' ? <Compare {...props} openChat={openChat} /> : <Redirect to={match.url} />)}
        </SplitConsumer>
      </div>
    );

    const TransposonsPage = props => (
      <div>
        <Helmet>
          <title>Epigenetic Evolution - Hurdle</title>
        </Helmet>
        <SplitConsumer name="transposons">
          {value => (value == 'on' ? <Transposons {...props} /> : <Redirect to={match.url} />)}
        </SplitConsumer>
      </div>
    );

    return (
      <div className="you-page__wrapper">
        <SubMenuBar
          hideMenuBar={true}
          navigation={youLink}
          show={
            !(
              location.pathname.includes('search') ||
              location.pathname === '/dashboard/you' ||
              location.pathname.includes('indicator') ||
              location.pathname.includes('compare')
            )
          }
        />
        <Switch>
          <Route path={`${match.url}/transposons`} render={TransposonsPage} />
          <Route path={`${match.url}/compare`} render={ComparePage} />
          <Route path={`${match.url}/actionable/:slug`} render={IntervenablePage} />
          <Route path={`${match.url}/indicator/chromosome-ageing`} render={ChromosomeAgePage} />
          <Route path={`${match.url}/indicator/:slug`} render={IndicatorPage} />
          {/*<Route
                        path={`${match.url}/actionable`}
                        render={ActionableYouPage}
                    />*/}
          <Route path={`${match.url}/search`} component={Search} />
          <Route render={YouPageComponent} />
        </Switch>
      </div>
    );
  }
}

You.propTypes = {
  breakpoint: PropTypes.string,
  testUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetchYou: PropTypes.func,
  results: PropTypes.object,
  isLoading: PropTypes.bool,
  selectedTest: PropTypes.object,
  updateSelectedState: PropTypes.func,
};

const mapStateToProps = state => ({
  results: youSelector(state),
  isLoading: youLoadingSelector(state, 'you'),
  selectedTest: selectedTestSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchYou: testId => dispatch(getYou(testId)),
  updateSelectedState: test => dispatch(setSelectedTest(test)),
});

const enhance = compose(withErrorBoundary, withError({ reducer: 'you', page: 'you' }), connect(mapStateToProps, mapDispatchToProps));

export default enhance(You);
