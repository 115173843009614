import React, { Component } from 'react';
export default class MultipleChoice extends Component {
  constructor(props) {
    super(props);

    const { value, choices, hasOtherOption } = this.props;
    let optionFound = false;

    choices.forEach(choice => {
      if (value === choice.value) {
        optionFound = true;
      }
    });

    let otherSelected = false;
    if (hasOtherOption && value !== '') {
      otherSelected = !optionFound;
    }

    this.state = {
      otherSelected: otherSelected,
    };

    this.handelSelected = this.handelSelected.bind(this);
  }

  handelSelected(e, isOther) {
    this.setState({ otherSelected: isOther });

    this.props.onChange(e);
  }

  render() {
    const { id, hasOtherOption, choices, value, theme } = this.props;
    const { otherSelected } = this.state;

    const options = choices.map((choice, index) => {
      return (
        <div key={index} className="custom__radio-container">
          <input
            type="radio"
            name={id}
            id={id + '_' + index}
            checked={value === choice.value}
            value={choice.value}
            onChange={e => this.handelSelected(e, false)}
            className="radio-button"
          />
          <label style={{ backgroundColor: value === choice.value ? theme.section.cta_colour : '' }} htmlFor={id + '_' + index}>
            {choice.value}
          </label>
        </div>
      );
    });

    return (
      <div className="custom__multiple-choice">
        {options}
        {hasOtherOption && (
          <div className="custom__radio-container">
            <input
              type="radio"
              name={id}
              id={id + '_other'}
              checked={otherSelected}
              value=""
              onChange={e => this.handelSelected(e, true)}
              className="radio-button"
            />
            <label style={{ backgroundColor: otherSelected ? theme.section.cta_colour : '' }} htmlFor={id + '_other'}>
              Other
            </label>
          </div>
        )}
        {otherSelected ? (
          <input
            type="text"
            autoFocus={true}
            name={id}
            value={otherSelected ? value : ''}
            onChange={e => this.handelSelected(e, true)}
            className="questionnaire-form__other-text"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
