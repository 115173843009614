import { CONTINENTAL_COLOR_MAP, MAPPED_LAT_LONG_VS_COUNTRY } from './mapper';

export const defaultZoomOptions = {
    scaleFactor: 1, // no scale
    center: {
        lat: null,
        lng: null
    },
    transition: {
        duration: 1000
    },
    onZoomComplete: function() {}
};

// This helper function is created with the reference of datamap-zoomto NPM package
const zoomPlugin = function(dataMapObj = {}, options = defaultZoomOptions) {
    const { options: { element } } = dataMapObj;

    const {
        scaleFactor,
        center : { lat, lng },
        onZoomComplete,
        transition: { duration }
    } = options;

    // const selection = function() {
    //     return d3.select(element);
    // };

    // const datamapsSubunits = function() {
    //     return selection().selectAll("svg>g");
    // };

    // var datamapsHoverover = function() {
    //     return selection().select('.datamaps-hoverover');
    // };

    const genTranslateStr = function(x, y) {
        return `translate(${x},${y})`;
    };

    const genScaleStr = function(x, y) {
        if(y === undefined) y = x;
        return `scale(${x}, ${y})`;
    };

    /**
     * Apply a d3 transition to animate a zoom effect.
     */
    const animateZoom = function(duration) {
        if (scaleFactor < 0) {
            throw Error('Cannot zoom to a negative scale');
        }

        // Assume that the old center will be at the center of the svg element
        const oldCenterCoords = {
            x: element.offsetWidth / 2,
            y: element.offsetHeight / 2
        };

        const centerCoords = {
            x: oldCenterCoords.x,
            y: oldCenterCoords.y
        };

        if (!!lat && !!lng) {
            const coords = dataMapObj.latLngToXY(lat, lng);

            if (coords === null) {
                throw new Error(
                    `The latitude/longitude coordinates that you tried to use as your
                    center are outside the bounds of your projection (your map).`
                );
            }

            centerCoords.x = coords[0];
            centerCoords.y = coords[1];
        }

        const s = scaleFactor;

        // Calculate the overall desired translation accounting for scale.
        // All we need to do is move from the old center point to the new
        // center point and multiply by the scaling factor.
        const t = {
            x: oldCenterCoords.x - s * (centerCoords.x),
            y: oldCenterCoords.y - s * (centerCoords.y)
        };

        const transformStr = genTranslateStr(t.x, t.y) + genScaleStr(s);

        // datamapsSubunits().transition()
        //     .duration(duration)
        //     .attr("transform", transformStr)
        // ;

        onZoomComplete.call(dataMapObj, {
            translate: t,
            scale: s
        });
    };

    // execute zoom
    animateZoom(duration);
};

const showHighlightedArea = function({highlightArea, selectedContinent, datamap}) {
    const { highlight_countries = [], continentCode, code } = highlightArea;
    let customZoomOptions = defaultZoomOptions;
    const { center = {}, scaleFactor = 1 } = MAPPED_LAT_LONG_VS_COUNTRY[code] || {};
    if (!!center.lng && !!center.lat) {
        customZoomOptions = { ...defaultZoomOptions, center, scaleFactor };
    }

    let highlightedCountries = highlight_countries;

    if(selectedContinent && selectedContinent.highlight_countries) {
        highlightedCountries = highlight_countries.filter((country) => -1 !== selectedContinent.highlight_countries.indexOf(country));

        selectedContinent.highlight_countries.map((continentCountry) => {
            const selectedCountry = document.getElementsByClassName(continentCountry)[0];
            if(selectedCountry) {
                selectedCountry.style.fill = CONTINENTAL_COLOR_MAP[continentCode];
                selectedCountry.style.opacity = 0.5;
            }
        });
    }

    highlightedCountries.map((country) => {
        const path = document.getElementsByClassName(country)[0];
        if (path) {
            path.style.fill = CONTINENTAL_COLOR_MAP[continentCode];
            path.style.opacity = 1;
            zoomPlugin(datamap, { ...customZoomOptions });
        }
    });
};

// The function is use to highlight the color by filling the area on map
const getHighlightedArea = function(datamap, event) {
    // first set the default color to the countries and continent
    const dataMap = document.getElementsByClassName('datamaps-subunit');
    Object.entries(dataMap).forEach(
        ([,path]) => path.style.fill = CONTINENTAL_COLOR_MAP.defaultFill
    );
    // Set the color to the countries and continent
    showHighlightedArea({highlightArea: event.detail.highlightArea, selectedContinent: event.detail.selectedContinent, datamap});
};

export default getHighlightedArea;
