export const aspirationSelector = (state) => {
    return state.services.improvements.aspiration;
};

export const improvementsLoadingSelector = (state, page) => {
    return state.services.improvements[`${page}Loading`];
};

export const coachesSelector = (state) => {
    return state.services.improvements.coaches;
};

export const aspirationFormSelector = (state) => {
    return state.services.improvements.showAspirationForm;
};

export const goalsSelector = (state) => {
    return state.services.improvements.goals;
};

export const goalDetailsSelector = (state) => {
    return state.services.improvements.goalDetails;
};