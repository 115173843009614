import React from 'react';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';

const ShowMessage = ({ message_title, message_text }) => (
  <div className="registration__wrapper">
    <div className="registration__container">
      <div className="register-form__complete-container">
        <div className="register-form__complete-icon">
          <i className="icon-tick_complete" />
        </div>
        <div className="register-form__single-field">
          <h4 className="complete-title">{message_title}</h4>
          <p className="complete-description">{message_text}</p>
        </div>
        <div className="button-container">
          {/* app opens this in a modal window iframe so need to break out with _top */}
          <a href={`/login${getPartnerQueryParams()}`} target="_top" className="btn btn--full-width">
            My Account
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default ShowMessage;
