import React, { Component } from 'react';
import utils from '../../../../helpers/utils';

import UnitSlider from './unitSlider';

const possibleOptions = {
    m: {majorName: 'Metres', minorName: 'cm', metricOrImperial: 'metric', minorConversionFactor: 100},
    ft: {majorName: 'Feet', minorName: 'in',  metricOrImperial: 'imperial', minorConversionFactor: 12, showCombined: true, step: 0.1},
    cm: {majorName: 'cm',  metricOrImperial: 'metric'},
    in: {majorName: 'Inches',  metricOrImperial: 'imperial'},
};

const defaultUnit = 'm';

export default class Length extends Component {
    static convertToUnits(currentUnit, toUnits, value) {
        if(value === '') {
            return false;
        }

        //Convert whatever we have to default (m) first
        const currentMeters = Length.convertToDefault(currentUnit, value);
        //console.log('convertToUnits:', currentUnit, toUnits, major, minor, currentMeters)
        let newValue;


        if(toUnits == 'ft') {
            const totalCm = currentMeters*100;
            newValue = ((totalCm*0.3937) / 12).toFixed(2);
        }
        else if(toUnits == 'in') {
            newValue = Math.round(currentMeters*39.37);
        }
        else if(toUnits == 'cm') {
            newValue = Math.round(currentMeters*100);
        }
        else if(toUnits == 'm') {
            newValue = Math.floor(currentMeters);
        }
        //console.log(newMajor, newMinor)

        return utils.isNumeric(newValue) ? Number(newValue) : newValue;
    }

    //Default units is in meters
    static convertToDefault(currentUnit, value) {
        if(value === '') {
            return false;
        }

        value = utils.isNumeric(value) ? Number(value) : 0;

        let meters = null;

        

        if(currentUnit == 'ft') {
            meters = (value*12);
            meters = (meters*0.0254);
            meters = Number(meters.toFixed(2));
        }
        else if(currentUnit == 'in') {
            meters = value*0.0254;
            meters = Number(meters.toFixed(2));
        }
        else if(currentUnit == 'cm') {
            meters = value/100;
        }
        else {
            meters = value;
        }

        //console.log('convertToDefault', currentUnit, major, minor, meters)
        //console.log('HERE',major, minor, currentUnit, meters)

        return meters;
    }

    render() {
        const { options } = this.props;
        return (
            <UnitSlider
                {...this.props}
                convertToDefault={Length.convertToDefault}
                convertToUnits={Length.convertToUnits}
                defaultUnit={defaultUnit}
                defaultValue={0}
                measurementKey={options.show_hip_info ? 'show_hip_info' : options.show_waist_info ? 'show_waist_info' : ''}
                possibleOptions={possibleOptions}
                showMeasurement={options.show_hip_info || options.show_waist_info}
                step={1}
            />
        );
    }
}