import React, { Component } from 'react';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import BiologicalAgeReport from './biologicalAgeReport';
import data from '../../jest/data.json';
import MyTeamReport from './myTeamReport';
import EpigeneticHealthReport from './epigeneticHealthReport';
import { setSelectedTest } from '../../services/you/actions';
import MetabolicStatusReport from './metabolicStatusReport';

const navBarOptions = [
  {
    optionName: 'Epigenetic Health',
  },
  {
    optionName: 'Biological Age',
    optionLink: 'biological-age',
  },
  {
    optionName: 'Metabolic Status',
    optionLink: 'metabolic-status',
  },
  {
    optionName: 'My Team',
    optionLink: 'my-team',
  },
];

export class InteractiveReport extends Component {
  constructor() {
    super();
    this.state = {
      targetValue: 'Epigenetic Health',
      disableLink: true,
    };
  }

  handleNavbar = optionName => {
    const { targetValue } = this.state;
    this.setState({ targetValue: optionName });
  };

  render() {
    const { breakpoint } = this.props;
    const { disableLink } = this.state;

    const EpigeneticAgeReportPage = () => (
      <EpigeneticHealthReport
        setSelectedTest={setSelectedTest}
        disableLink={disableLink}
        slug="epigenetic-age"
        url={this.props.match.url}
        breakpoint={breakpoint}
      />
    );

    const BiologicalAgeReportPage = () => (
      <BiologicalAgeReport
        diseaseRisk={data.apiResponse['biological-age'].disease_risk}
        recommendations={data.apiResponse['biological-age'].recommendations}
        customComparisons={data.apiResponse['biological-age'].comparisons_custom}
        ageOverTimeGraph={data.apiResponse['biological-age'].your_result_graph}
        healthyRanges={data.apiResponse['biological-age'].healthy_ranges}
        heroSectionInfo={data.apiResponse['biological-age']}
        team={data.apiResponse['biological-age'].team}
        disableLink={disableLink}
        slug="biological-age"
      />
    );

    const MetabolicStatusReportPage = () => (
      <MetabolicStatusReport
        diseaseRisk={data.apiResponse['metabolic-status'].disease_risk}
        recommendations={data.apiResponse['metabolic-status'].recommendations}
        customComparisons={data.apiResponse['metabolic-status'].comparisons_custom}
        ageOverTimeGraph={data.apiResponse['metabolic-status'].your_result_graph}
        healthyRanges={data.apiResponse['metabolic-status'].healthy_ranges}
        heroSectionInfo={data.apiResponse['metabolic-status']}
        team={data.apiResponse['metabolic-status'].team}
        disableLink={disableLink}
        slug="metabolic-status"
      />
    );

    const MyTeamReportPage = () => <MyTeamReport disableLink={disableLink} />;
    return (
      <div className="content-wrapper-animate">
        <section className="report-section__container">
          <h1 className="page-title">Hurdle Report</h1>
          <div className="nav">
            <ul className="nav-bar">
              {navBarOptions.map((item, index) => {
                return (
                  <li className="nav-bar-options" key={index}>
                    <NavLink
                      exact
                      to={item.optionLink ? `${this.props.match.url}/${item.optionLink}` : this.props.match.url}
                      className="inactive"
                      activeClassName="active"
                    >
                      {item.optionName}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <Switch>
            <Route path={`${this.props.match.url}/biological-age`} render={BiologicalAgeReportPage} />
            <Route path={`${this.props.match.url}/my-team`} render={MyTeamReportPage} />
            <Route path={`${this.props.match.url}/metabolic-status`} render={MetabolicStatusReportPage} />
            <Route path={this.props.match.url} render={EpigeneticAgeReportPage} />
          </Switch>
        </section>
      </div>
    );
  }
}

export default withRouter(InteractiveReport);
