import React from 'react';
import { Link } from 'react-router-dom';

const HealthSpecialist = ({team}) => {
    if(!team || Array.isArray(team)) {
        return null;
    }

    return (
        <div data-tut="health-specialist" className="health-specialist__container">
            <h4 className="indicator-section-title inside-title">{team.title}</h4>
            <div className="health-specialist__section-content">
                <div className="content-section">
                    <div className="image-container">
                        <img alt="health-specialist" src={team.image_url} />
                    </div>
                    <div className="information-section">
                        <p className="specialist-name">{team.name}</p>
                        <p className="specialist-designation">{team.description}</p>
                    </div>
                </div>
                <p className="specialist-description">
                    {team.text}
                </p>
            </div>
            {team.cta_title && <Link className="btn" to="/dashboard/improvements/team">{team.cta_title}</Link>}
        </div>
    );
};

export default HealthSpecialist;