import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';

const RenderPointSection = ({showArrow, you, showTooltip}) => {
    if(showArrow) {
        return <div className="result-arrow" style={{left: `calc(${you.percent}% - 1px)`}} />;
    }

    const HTMLContent = <div className="methylation-graph__tooltip-text">{you.label || 'You'}</div>;

    return (
        <div className="methylation-graph__point" style={{left: `calc(${you.percent}% - 4px)`}}>
            <Tooltip
                arrow={true}
                arrowSize="small"
                html={HTMLContent}
                open={showTooltip}
                position="top"
                theme="light"
            >
                <div className="tooltip-child" />
            </Tooltip>
        </div>
    );
};

const MethylationGraph = ({data, you, className, labels, showTooltip, showResultPoint, showArrow, invert}) => (
    <div className={`methylation-graph__container${className ? ` ${className}` : ''}`}>
        <div className="methylation-graph__bars">
            {showResultPoint ? <div className="methylation-graph__result-point" style={{left: `calc(${you.percent}% - 3px)`}} /> : ''}
            {RenderPointSection({showArrow, showTooltip, you})}
            {
                data.map((item, index) => (
                    <div
                        className={`methylation-graph__bar ${item.rating ? item.rating : ''}${invert ? ' invert' : ''}`}
                        key={index}
                        style={{width: `${item.max_percent - item.min_percent}%`}}
                    />
                ))
            }
        </div>
        <div className="methylation-graph__labels">
            <p>{labels.min_label}</p>
            <p>{labels.max_label}</p>
        </div>
    </div>
);

MethylationGraph.defaultProps = {
    showTooltip: true,
    showResultPoint: false,
    showArrow: false,
    invert: false
};

MethylationGraph.propTypes = {
    data: PropTypes.array.isRequired,
    you: PropTypes.object.isRequired,
    className: PropTypes.string,
    labels: PropTypes.object,
    showTooltip: PropTypes.bool,
    showResultPoint: PropTypes.bool,
    showArrow: PropTypes.bool,
    invert: PropTypes.bool,
};

export default MethylationGraph;
