import React, { Component } from 'react';

export default class UnitWidgit extends Component {
  constructor(props) {
    super(props);

    //value will be in meters
    const { value, options, defaultUnit, convertToUnits, possibleOptions, convertFromDefaultToMajorMinor } = this.props;

    //Did they only want specific units?
    let selectedUnits = {};
    let selectedUnit = null;

    if (options.show_units) {
      for (const x in options.show_units) {
        selectedUnits[options.show_units[x]] = possibleOptions[options.show_units[x]];
      }
    } else {
      selectedUnits = possibleOptions;
    }

    for (const i in selectedUnits) {
      //console.log(i, metricOrImperial, selectedUnits[i].metricOrImperial)
      if (options.metricOrImperial === selectedUnits[i].metricOrImperial) {
        selectedUnit = i;
        break;
      }
    }
    //console.log(selectedUnits, selectedUnit)

    const { major, minor } = convertFromDefaultToMajorMinor(value);
    this.state = {
      major: major, //ie meters
      minor: minor, //ie cm
      selectedUnit: defaultUnit,
      allUnits: selectedUnits,
    };

    //Convert major and minor if not in default units
    if (selectedUnit && selectedUnit !== defaultUnit) {
      if (major !== '') {
        const newMajorMinor = convertToUnits(selectedUnit, defaultUnit, this.state.major, this.state.minor);
        this.state.major = newMajorMinor.major;
        this.state.minor = newMajorMinor.minor;
      }
      this.state.selectedUnit = selectedUnit;
    }

    //console.log(this.state)

    this.handelOnChangeUnits = this.handelOnChangeUnits.bind(this);
    this.handelOnChange = this.handelOnChange.bind(this);
  }

  componentDidUpdate() {
    const { major, selectedUnit, allUnits } = this.state;
    //console.log(allUnits[selectedUnit].metricOrImperial, this.props.options.metricOrImperial)

    //only update units if we havent input anything
    if (allUnits[selectedUnit].metricOrImperial !== this.props.options.metricOrImperial && major === '') {
      for (const i in allUnits) {
        if (this.props.options.metricOrImperial === allUnits[i].metricOrImperial) {
          this.setState({ selectedUnit: i });
          break;
        }
      }
    }
  }

  handelOnChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    const { id, onChange, convertToDefault } = this.props;
    const { allUnits } = this.state;

    this.setState({ [name]: value }, () => {
      onChange(id, convertToDefault(this.state.selectedUnit, this.state.major, this.state.minor), {
        metricOrImperial: allUnits[this.state.selectedUnit].metricOrImperial,
      });
    });
  }

  handelOnChangeUnits(e) {
    const { id, onChange, convertToUnits, convertToDefault } = this.props;
    const { allUnits, selectedUnit, major, minor } = this.state;

    //Convert current values if we have them
    const newMeasurments = convertToUnits(selectedUnit, e.target.value, major, minor);
    //console.log(newMeasurments);

    const newstate = { selectedUnit: e.target.value };
    if (newMeasurments) {
      newstate.major = newMeasurments.major;
      newstate.minor = newMeasurments.minor;
    }

    this.setState(newstate, () => {
      onChange(id, convertToDefault(this.state.selectedUnit, this.state.major, this.state.minor), {
        metricOrImperial: allUnits[this.state.selectedUnit].metricOrImperial,
      });
    });
  }

  render() {
    const { id, fieldType } = this.props;
    const { selectedUnit, allUnits } = this.state;

    const labelOptions = [];
    for (const unit in allUnits) {
      labelOptions.push(
        <span key={unit}>
          <input
            type="radio"
            name={id + '_units'}
            id={id + '_units_' + unit}
            checked={selectedUnit === unit}
            value={unit}
            onChange={this.handelOnChangeUnits}
          />
          <label htmlFor={id + '_units_' + unit} className="questionnaire-length__unit">
            {allUnits[unit].majorName}
          </label>
        </span>,
      );
    }

    return (
      <div className="questionnaire-length">
        <div className="questionnaire-length__units-container">{labelOptions.length > 1 ? labelOptions : ''}</div>

        {selectedUnit && (
          <div className="questionnaire-length__input-container">
            {allUnits[selectedUnit].majorName && (
              <span>
                <input
                  type={fieldType || 'text'}
                  name="major"
                  id={id + '_major'}
                  value={this.state.major}
                  onChange={this.handelOnChange}
                  className="questionnaire-length__input-inline"
                />
                <span className="questionnaire-length__length-unit">{allUnits[selectedUnit].majorName}</span>
              </span>
            )}

            {allUnits[selectedUnit].minorName && (
              <span>
                <input
                  type={fieldType || 'text'}
                  name="minor"
                  id={id + '_minor'}
                  value={this.state.minor}
                  onChange={this.handelOnChange}
                  className="questionnaire-length__input-inline"
                />
                <span className="questionnaire-length__length-unit">{allUnits[selectedUnit].minorName}</span>
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}
