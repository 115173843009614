import React, { Component } from 'react';
import axios from 'axios';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import Label from './questionnaires/components/label';
import MultipleChoice from './questionnaires/components/multipleChoice';
import Text from './questionnaires/components/text';
import Age from './questionnaires/components/custom/age';

import utils from '../helpers/utils';

const components = {
    'MULTIPLE_CHOICE': MultipleChoice,
    'TEXT': Text,
    'age': Age
};

const API_INDEX = {
    'GENETIC_TRAITS': 'genetic-traits',
    'EPIGENETIC_INDICATORS': 'epigenetic-indicators',
    'INTERVENABLES': 'intervenables',
};

class Question extends Component {
    constructor() {
        super();
        this.state = {
            fields: {},
            questionOptions: false
        };

        this.showOptions = this.showOptions.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitTraitQuestions = this.submitTraitQuestions.bind(this);
    }

    componentDidMount() {
        const { section } = this.props;
        axios.get(`questionnaires/section/${API_INDEX[section]}`).then((response) => {
            const fields = response.data.answers ? response.data.answers : {};
            this.setState({fields: fields});
        });
    }

    handleInputChange(event, value, options) {
        const { uiSchema, jsonSchema } = this.props;
        let fieldToUpdate;
        if(uiSchema && uiSchema[jsonSchema.id]) {
            fieldToUpdate = {fields: {[event]: {$set: value}}};
            if(options) {
                fieldToUpdate.fields[event+'_options'] = {$set: options};
            }
        } else {
            const target = event.target;
            const name = target.name;
            const newValue = target.value.trim();
            fieldToUpdate = {fields: {[name]: {$set: newValue}}};
        }

        this.setState(update(this.state, fieldToUpdate), () => {
            this.submitTraitQuestions();
        });
    }

    submitTraitQuestions() {
        const { fields } = this.state;
        const { section } = this.props;

        axios.post(`questionnaires/${API_INDEX[section]}`, {
            answers: JSON.stringify(fields),
            current_page: 1,
        }).then((response) => {
            //const fields = response.data.answers ? response.data.answers : {};
            //this.setState({fields: fields});

            utils.track('Completed Question');
        });
    }

    showOptions() {
        this.setState((prevState) => ({
            questionOptions: !prevState.questionOptions
        }));
    }

    renderFormField() {
        const { fields } = this.state;
        const { jsonSchema, uiSchema } = this.props;
        const value = fields && fields.hasOwnProperty(jsonSchema.id) ? fields[jsonSchema.id] : '';

        let valueOptions = (fields && fields[jsonSchema.id+'_options']) ? fields[jsonSchema.id+'_options'] : {};

        let ShowComponent = components[jsonSchema.type];

        if(uiSchema && uiSchema[jsonSchema.id]) {
            ShowComponent = components[uiSchema[jsonSchema.id]['ui:widget']];
            valueOptions = {...valueOptions, ...uiSchema[jsonSchema.id]};
        }

        return (
            <div className={`question-options ${this.state.questionOptions ? 'active' : ''}`}>
                <ShowComponent {...jsonSchema} onChange={this.handleInputChange} options={valueOptions} value={value} />
            </div>
        );
    }

    render() {
        const { jsonSchema } = this.props;
        return (
            <div className="question question--large">
                <Label {...jsonSchema} onClick={this.showOptions} />
                {this.renderFormField()}
            </div>
        );
    }
}

Question.defaultProps = {
    jsonSchema: {},
    section: 'GENETIC_TRAITS',
    uiSchema: {}
};

Question.propTypes = {
    jsonSchema: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    uiSchema: PropTypes.object
};

export default Question;
