import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Datamap from '../../../lib/DataMap';

import StickToPosition from '../../../common/sticky';
import ExpandableCountryListCell from './ExpandableCountryListCell';
import { CONTINENTAL_COLOR_MAP } from './mapper';
import FunFact from '../../../common/funFact';
import Disclaimer from '../../../common/disclaimer';
import Loader from '../../../common/loader';
import getHighlightedArea from './helper';

const HIGHLIGHT_AREA_EVENT = 'EVENT_HIGHLIGHT_AREA';
const SUPPORTED_BREAKPOINTS = ['tinySmall', 'tiny', 'small'];

const createCustomEvent = (data, selectedContinent = {}) => new CustomEvent(
    HIGHLIGHT_AREA_EVENT,
    {
        bubbles: true,
        detail: { highlightArea: { ...data }, selectedContinent: {...selectedContinent} },
        cancelable: true
    }
);

const mapOptions = {
    // countries don't listed in dataset will be painted with this color
    fills: { ...CONTINENTAL_COLOR_MAP },
    margin: 'auto',
    data: {},
    geographyConfig: {
        borderColor: '#ffffff',
        highlightBorderWidth: 1,
        // don't change color on mouse hover
        highlightFillColor: false,
        // only change border
        highlightBorderColor: false,
    },
    done: function(datamap) {
        document.addEventListener(HIGHLIGHT_AREA_EVENT, getHighlightedArea.bind(null, datamap));
    }
};


class Ancestry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: {},
            expandedContinent: 'EUR',
            geneticAncestry: [],
            funFacts: { data: [], title: '' },
            introText: '',
            mapSubTitle: '',
            mapTitle: '',
            menuTitle: '',
            title: '',
        };

        this.handleContinentClick = this.handleContinentClick.bind(this);
        this.handleCountryClick = this.handleCountryClick.bind(this);

    }

    fetchGeneticAncestry() {
        this.mounted = true;
        axios.get('test_user/results/genetic/ancestry')
            .then((response = {}) => {
                if (this.mounted) {
                    const { data = {} } = response;
                    const {
                        data: geneticAncestry,
                        fun_facts: funFacts,
                        intro_text: introText,
                        map_sub_title: mapSubTitle,
                        map_title: mapTitle,
                        menu_title: menuTitle,
                        title,
                    } = data;

                    this.setState({
                        geneticAncestry,
                        funFacts,
                        introText,
                        mapSubTitle,
                        mapTitle,
                        menuTitle,
                        title,
                    });
                }
            })
            .catch((err) => {
                this.setState(() => { throw err; });
            });
    }

    componentDidMount() {
        this.fetchGeneticAncestry();
    }

    componentWillUnmount() {
        document.removeEventListener(HIGHLIGHT_AREA_EVENT, getHighlightedArea, false);
        this.mounted = false;
    }

    handleCountryClick({ e, ...country}) {
        const { geneticAncestry, expandedContinent } = this.state;
        const selectedContinent = geneticAncestry && geneticAncestry.find((continent) => continent.code === expandedContinent);

        if (e) {
            const event = createCustomEvent(country, selectedContinent);
            document.dispatchEvent(event);
            event.preventDefault();
        }
       this.setState({ country });
    }

    handleContinentClick(continent) {
        const event = createCustomEvent(continent);
        document.dispatchEvent(event);
        this.setState({
            country: {},
            expandedContinent: continent.continentCode
        });
    }

    getExpandableCountryListCell() {
        const { country, expandedContinent, geneticAncestry } = this.state;

        return geneticAncestry.map(
            ancestry => <ExpandableCountryListCell
                ancestry={ancestry}
                expandedContinent={expandedContinent}
                key={ancestry.code}
                onContinentClick={this.handleContinentClick}
                onCountryClick={this.handleCountryClick}
                selectedCountry={country}
                        />);
    }

    render() {
        const {
            country: { continentCode, description, title: countryName },
            geneticAncestry,
            funFacts: {
                data: items,
                title: funFactTitle
            },
            introText,
            mapSubTitle,
            mapTitle,
            menuTitle,
            title,
        } = this.state;
        const color = CONTINENTAL_COLOR_MAP[continentCode];
        const isStickable = SUPPORTED_BREAKPOINTS.includes(this.props.breakpoint);

        if (!geneticAncestry.length) {
            return (
                <Loader />
            );
        }

        return (
            <Fragment>
                <div className="ancestry-container">
                    <div className="ancestry-container__your-ancestry-container">
                        <h3 className="ancestry-container__your-ancestry-container__title">{title}</h3>
                        <div className="ancestry-container__your-ancestry-container__description">{introText}</div>
                    </div>

                    <div className="ancestry-container__map-container">
                        <div className="ancestry-container__map-container__header">
                            <div className="ancestry-container__map-container__header__title">{mapTitle}</div>
                            <div className="ancestry-container__map-container__header__border" />
                            <div className="ancestry-container__map-container__header__description">
                                {mapSubTitle}
                            </div>
                        </div>
                        <div className="ancestry-container__map-container__content">
                            <div className="ancestry-container__map-container__content__map-detial">
                                <StickToPosition isStickable={isStickable} stickAtPosition={150}>
                                    <div className="map">
                                        <Datamap {...mapOptions}/>
                                    </div>
                                </StickToPosition>
                                <div className="country-detial">
                                    <h3 className="country-name" style={{color}}>{countryName}</h3>
                                    <div>{description}</div>
                                </div>
                            </div>

                            <div className="ancestry-container__map-container__content__choose-country">
                                <div className="choose-country-header">{menuTitle}</div>
                                <div className="choose-country-body">{this.getExpandableCountryListCell()}</div>
                            </div>
                        </div>
                    </div>
                    <FunFact items={items} title={funFactTitle} />
                </div>
                <Disclaimer />
            </Fragment>
        );
    }
}

export default Ancestry;
