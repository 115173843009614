import React, { Component } from 'react';

import utils from '../../../../helpers/utils';
import UnitSlider from './unitSlider';

const possibleOptions = {
    kg: {majorName: 'KG',  metricOrImperial: 'metric'},
    g: {majorName: 'Grams',  metricOrImperial: 'metric'},
    st: {majorName: 'Stones', minorName: 'LB', metricOrImperial: 'imperial', minorConversionFactor: 14, showCombined: true, step: 0.1},
    lb: {majorName: 'Pounds', minorName: 'Ounces', metricOrImperial: 'imperial', minorConversionFactor: 16, showCombined: true, step: 0.1},
};
const defaultUnit = 'kg';

export default class Weight extends Component {
    static convertToUnits(currentUnit, toUnits, value) {
        if(value === '') {
            return false;
        }

        //Convert whatever we have to KG first
        const currentKg = Weight.convertToDefault(currentUnit, value);
        let newValue;

        if(toUnits == 'kg') {
            newValue = currentKg;
        }
        if(toUnits == 'g') {
            newValue = currentKg*1000;
        }
        else if(toUnits == 'st') {
            const totalLbs = currentKg*2.2046226218;
            newValue = (totalLbs/14).toFixed(2);
        }
        else if(toUnits == 'lb') {
            return currentKg*2.2046226218;
        }

        //console.log(currentKg, newMajor, newMinor, toUnits)
        return utils.isNumeric(newValue) ? Number(newValue) : newValue;
    }

    static convertToDefault(currentUnit, value) {
        if(value === '') {
            return false;
        }

        value = utils.isNumeric(value) ? Number(value) : 0;
        let kg = null;

        if(currentUnit == 'st') {
            kg = (value*14);
            kg = Math.round(kg*0.45359237);
        }
        else if(currentUnit == 'lb') {
            kg = value*0.45359237;
        }
        else if(currentUnit == 'g') {
            kg = value/1000;
        }
        else {
            kg = value;
        }

        return kg;
    }

    render() {
        return (
            <UnitSlider
                {...this.props}
                convertToDefault={Weight.convertToDefault}
                convertToUnits={Weight.convertToUnits}
                defaultUnit={defaultUnit}
                defaultValue={0}
                possibleOptions={possibleOptions}
                step={1}
            />
        );
    }
}