import React from 'react';
import config from '../../../config';
import { Helmet } from 'react-helmet';
import { Registration } from '@chronomics/chronomics-registration';
import { withTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getPartnerUsersBaseUrl } from '../../../services/apiHelpers/partnerServiceRequests';

const RegistrationPage = ({ theme }) => {
  const registrationTheme = theme
    ? {
        primaryColour: theme.section.cta_colour,
        validationErrorColour: theme.section.error_colour || 'red',
        pageBackground: theme.section.background,
      }
    : undefined;
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Register - Hurdle</title>
        <meta content="Hurdle register kit" name="description" />
      </Helmet>
      <div className="registration-page__wrapper">
        <Registration
          apiUrl={config.app.api_url}
          orderServiceApiUrl={config.app.order_service_api}
          partnerServiceApiUrl={getPartnerUsersBaseUrl({})}
          webApiGatewayUrl={config.app.webApiUrl}
          publicApiGatewayUrl={config.app.public_api_url}
          reverseLogisticsApiUrl={config.app.reverse_logistics_api}
          sentryDsn=""
          mapsApiKey={config.app.google_maps_api_key}
          theme={registrationTheme}
          history={history}
        />
      </div>
    </>
  );
};

export default withTheme(RegistrationPage);
