import React from 'react';

export default function AddressCard({fields = {}, countrys=[], isBillingAddress=false}) {
    let deliveryCountry = fields.delivery_country;
    countrys.map(function(value) {
        if(value.country_code == fields.delivery_country) {
            deliveryCountry = value.title;
        }
    });

    return (<div>
        {
            !isBillingAddress ?
                <div>
                    {fields.delivery_fname} {fields.delivery_lname}
                </div> : ''
        }
        <div>
            {fields.delivery_address1}
        </div>
        <div>
             {fields.delivery_address2}
        </div>
        <div>
            {fields.delivery_city}
        </div>
        {
            fields.delivery_county &&
                <div>
                    {fields.delivery_county}
                </div>
        }
        <div>
            {fields.delivery_postcode}
        </div>
        <div>
            {deliveryCountry}
        </div>
            </div>);
}
