import axios from 'axios';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import React, { Component } from 'react';
import { validated } from 'react-custom-validation';
import { Link } from 'react-router-dom';

import { getSiteBrandingRequest } from '../../services/branding/actions';
import { brandingSelector } from '../../services/branding/selectors';

import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements';
import { Tooltip } from 'react-tippy';
import Loader from '../common/loader';
import config from '../../config';
import withErrorBoundary from '../common/errorBoundary';
import withError from '../../components/common/withError';
import ShareIcons from '../common/shareIcons';
import utils from '../helpers/utils';
import validationRules from '../helpers/validationRules';
import Billing from './billing';
import OrderSummary from './orderSummary';
import Shipping from './shipping';
import ShippingMethod from './shippingMethod';
import Travel from './travel';
import getConfirmationScript from '../helpers/tradedoubler/confirmation-script';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';
import { StyledHyperlinkWrapper } from '../../theming/themed-components';

// VARIATION_TYPES
const ONCE = 'oneoff';
const REPEAT = 'subscription';

const LogPaymentApi = (...args) => {
    if (config.app.env !== 'production') {
        console.log(...args);
    }
};

class Join extends Component {
    constructor(props) {
        super(props);

        const user = config.app.user;
        const parsedUrl = qs.parse(window.location.search);

        this.state = {
            product: null,
            allowCouponCodeEntry: true,
            variation: props.variation ? props.variation : null,
            quantity: props.quantity ? props.quantity : 1,
            reference_id: parsedUrl.refId || undefined,
            max_qty: parsedUrl.max_qty || undefined,
            // isOneTimePayment:
            // for subscriptions - false
            // for "buy something now" (e.g. covid test kit) - it's true
            isOneTimePayment: false,
            fields: {
                affiliate_code: '',
                partner_code: '',
                email: user ? config.app.user.email : '',
                password:'',
                mobile_country: user ? config.app.user.mobile_country : '',
                mobile: user ? config.app.user.mobile : '',
                delivery_fname: '',
                delivery_lname: '',
                delivery_address1: '',
                delivery_address2: '',
                delivery_city: '',
                delivery_county: '',
                delivery_postcode: '',
                delivery_country: '',
                accepted_terms_privacy: false,
                coupon_code: props.coupon ? props.coupon : '',
                billing_address1: '',
                billing_address2: '',
                billing_city: '',
                billing_postcode: '',
                billing_country: '',
                card_holder_name:'',
                shipping_method: '',

                flight_number: '',
                arrival_day: '',
                arrival_month: '',
                arrival_year: '',
                departure_day: '',
                departure_month: '',
                departure_year: '',
                departure_country: '',
                transit_countries: '',
                transit_country_1: '',
                transit_country_2: '',
                transit_country_3: '',
                arrival_date: '',
                departure_date: '',
                lab_availability: '',
            },
            //Keep track of the errors in these stripe fields seperately
            fieldErrors: {
                cardNumber: '',
                cardExpiry: '',
                cardCvc: '',
            },
            countrys: [],
            disableSubmitButton: false,
            submitError: false,
            submitErrorMessage: '',
            redirectTo: false,
            sectionsComplete: {
                account: false,
                delivery: false,
                travel: false,
                payment: false,
            },
            isLoggedIn: user ? true : false,
            couponCodeFormOpen: false,
            couponCodeFormError: '',
            couponCodeFormSuccess: false,
            discountTitle: null,
            discountAmount: null,
            discountPercentage: null,
            discountDuration: null,
            discountDurationMonths: null,
            paymentFrequency: 'yearly', //this.props.variation ? this.props.variation.frequency : 'yearly',
            postage: null,
            stripe: null,
            addressMode: 'shipping-address',
            isLoading: true,
            showShareIcon: false,
            encryptedUserId: '',
            error:'',
            //Used to anylatics
            checkoutId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            shippingMethod: 'default',
            showRequiresExtraPaymentConfirmationMessage: false,
            showShippingWarning: false,
            isShippableToGB: false,
        };

        this.calculatePostageCosts = this.calculatePostageCosts.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormValid = this.handleFormValid.bind(this);
        this.handleFormInvalid = this.handleFormInvalid.bind(this);
        this.handleCardFieldChange = this.handleCardFieldChange.bind(this);
        this.handelLogout = this.handelLogout.bind(this);
        this.handelCouponCodeFormOpen = this.handelCouponCodeFormOpen.bind(this);
        this.handelCouponCodeSubmit = this.handelCouponCodeSubmit.bind(this);
        this.handelMonthlyYearlyChange = this.handelMonthlyYearlyChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.UpdateState = this.UpdateState.bind(this);
        this.submitCouponCode = this.submitCouponCode.bind(this);
        this.handleUpdateAddress = this.handleUpdateAddress.bind(this);
        this.loadBranding = this.loadBranding.bind(this);
    }

    async componentDidMount() {
        const {productSuk, slug, frequency, campaignId} = this.props;
        this.handleInputChange('shipping_method', this.state.shippingMethod);

        let productApiUrl = '';

        if (productSuk !== null) {
            productApiUrl = 'products?suk='+productSuk+'&campaign_id='+campaignId+'&display_all=true';
        } else {
            // Redirect old epi products to bio age for now
            if (slug === 'life' || slug === 'basic' || slug === 'epihealth') {
                window.location.href = '/join/account?slug=biological-age';
            }

            productApiUrl = 'products?slug='+slug+'&campaign_id='+campaignId+'&display_all=true';
        }

        const url = qs.parse(window.location.search);
        const affiliateCode = url['affiliate-code'];
        let product = false;
        let productData = false;

        // @see http://app.chronomics.local/join/account?slug=covid-19-day8&affiliate-code=tui-staff
        // @see https://app.stage.chronomics.com/join/account?slug=travel-green-return-en&affiliate-code=british-airways
        // If affiliate code exists in the URL then get the product price from the Affiliate API.
        if (affiliateCode) {
            await axios.get(`products?affiliate_code=${affiliateCode}`)
            .then(response => {

                this.setState(update(this.state, { fields: {['partner_code']: { $set: url['affiliate-code'] }}}));

                product = response ? response.data : undefined;
            });

            // This method returns the api call above, we could change that to merge to one call
            // But as there are multiples products returned its not giving the right one based on
            // the slug
            // productApiUrl += `&affiliate-code=${affiliateCode}`;
            productData = product.data;
        }

        this.loadAffiliate();
        this.loadBranding();

        axios.get(productApiUrl)
            .then(response => {
                if(response.data.data[0]) {
                    const product = response.data.data[0];
                    let variation = null;
                    let isOneTimePayment = false;
                    let isTravelToFlyScenario = false;
                    let affiliateVariation = false;

                    // Find the variation we wanted
                    // @todo change this to a find
                    for (const x in product.variations) {
                        // Single product
                        // @see /join/account?product=covid-19-fit-to-fly-healgen-gbp-standard
                        if (productSuk && product.variations[x].suk === productSuk) {
                            variation = product.variations[x];

                        // Full frequency match or no frequency for product at all ("oneoff" product probably)
                        // @see /join/account?slug=travel-green-return-en
                        } else if (slug && (
                        (product.variations[x].frequency === frequency) || (product.variations[x].frequency === null)
                        ))  {
                            variation = product.variations[x];
                        }

                        if (affiliateCode && product) {
                            affiliateVariation = productData.find(o => o.suk === variation.suk);

                            variation['extra_price'] = affiliateVariation.ticket_price;
                            variation['extra_price_formated'] = affiliateVariation.ticket_price_formated;
                        }

                        isOneTimePayment = (variation.frequency === null);

                        if (product.is_gb_travel || (!product.is_gb_travel && product.required_outbound_from)) {
                            isTravelToFlyScenario = true;
                        }
                    }

                    this.setState({
                        product,
                        variation,
                        paymentFrequency: variation && variation.frequency,
                        isOneTimePayment,
                        isTravelToFlyScenario,
                        isLoading: false
                    }, () => {
                        if (this.state.fields.coupon_code && this.state.variation) {
                            this.submitCouponCode();
                        }
                    });

                    // Checkout Started
                    utils.track('Checkout Started', {
                        revenue: this.calculateTotal(false, true, false, true, true),
                        currency: variation.currency,
                        // Not offical segment parm
                        payment_frequency: variation.frequency,
                        products: [{
                            product_id: variation.id,
                            sku: variation.suk,
                            name: variation.suk,
                            price: variation.price,
                            quantity: this.state.quantity,
                        }]
                    });
                } else {
                    // Product not found
                    window.location = config.app.web_url;
                }
            });

        axios.get('products/shiping_countrys')
            .then(response => {
                // look for selected country
                let defaultSelectedCountryDialingCode = '';
                let defaultSelectedCountry = '';

                for (const i in response.data.data) {
                    if (response.data.data[i].selected) {
                        defaultSelectedCountryDialingCode = response.data.data[i].dialing_code;
                        defaultSelectedCountry = response.data.data[i].country_code;
                    }
                }

                this.setState({
                    countrys: response.data.data
                });
                this.setState(update(this.state, {fields: {['mobile_country']: {$set: defaultSelectedCountryDialingCode}}}));
                this.setState(update(this.state, {fields: {['delivery_country']: {$set: defaultSelectedCountry}}}));

                //Then update the postage costs based on selected country
                if (defaultSelectedCountry) {
                    this.calculatePostageCosts(defaultSelectedCountry);
                }
            });

        // Stripe is loaded aync
        if (window.Stripe) {
            this.setState({stripe: window.Stripe(config.app.stripe_key)});
        } else {
            utils.loadStripeElement().onload = () => {
                this.setState({
                    stripe: window.Stripe(config.app.stripe_key),
                });
            };
        }

        utils.track('Checkout Step Viewed', {checkout_id: this.state.checkoutId, step: 1});
    }

    componentWillUnmount() {
        utils.removeStripeElement();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.section !== this.props.section) {
            this.setState({
                redirectTo: false
            });
        }

        if (prevState.countrys != this.state.countrys ||
             prevState.isShippableToGB !== this.state.isShippableToGB) {
            const isUserOutsideOfGB = 
                this.state.countrys 
                    && this.state.countrys.some((country) => country.country_code !== 'GB' && country.selected);

            if (isUserOutsideOfGB && this.state.isShippableToGB) {
                this.setState({ showShippingWarning: true });
            }
        }
    }

    loadBranding() {
        const { onFetchBranding } = this.props;
        const { reference_id } = this.state;
        const { search } = window.location;

        let brandCode;
        // eslint-disable-next-line no-undef
        if(typeof $FPROM !== 'undefined' && $FPROM.data && $FPROM.data.ref_id) {
            // eslint-disable-next-line no-undef
            brandCode = $FPROM.data.ref_id.replace('_r_', '');
        } else if (reference_id === undefined) {
            if (!search) {
                return;
            }
            const segments = search.split('&');
            const segment = segments.find(s => s.toLowerCase().startsWith('fp_ref'));
            if (!segment) {
                return;
            }

            brandCode = segment.toLowerCase().replace('fp_ref=', '');
        }

        if (reference_id !== undefined) {
            brandCode = 'tui';
        }

        if (brandCode && brandCode.length > 0) {
            onFetchBranding(brandCode);
            this.setState(update(this.state, { allowCouponCodeEntry: {$set: false}, fields: {affiliate_code: {$set: brandCode}}}));
        }
    }

    loadAffiliate() {
        const { search } = window.location;
        if (!search) {
            return;
        }
        const segments = search.split('&');
        const segment = segments.find(s =>
            s.toLowerCase().startsWith('affiliate-code')
        );
        if (!segment) {
            return;
        }

        const affiliateCode = segment.toLowerCase().replace('affiliate-code=', '');
        if (affiliateCode && affiliateCode.length > 0) {
            this.setState(update(this.state, { allowCouponCodeEntry: {$set: false}, fields: {affiliate_code: {$set: affiliateCode}}}));
        }
    }

    handleInputChange(field, value='') {
        this.setState(update(this.state, {fields: {[field]: {$set: value}}}));
        this.setState({submitError: false});
    }

    handleUpdateAddress(address) {
      let fields;

      if (address.country && address.country.toUpperCase() === 'UNITED STATES') {
        fields = {
          'delivery_address1': { $set: address.addressline1 },
          'delivery_city': { $set: address.posttown },
          'delivery_county': { $set: address.stateabbreviation },
          'delivery_postcode': { $set: address.postcode },
        };
      } else {
        fields = {
          'delivery_address1': { $set: address.addressline1 },
          'delivery_address2': { $set: address.addressline2 },
          'delivery_city': { $set: address.posttown },
          'delivery_postcode': { $set: address.postcode },
        };
      }  

      this.setState(update(this.state, { fields }));
      this.setState({submitError: false});
    }

    //for the stripe card elements
    handleCardFieldChange(element) {
        if(!element.complete && element.error) {
            this.setState(update(this.state, {fieldErrors: {[element.elementType]: {$set: element.error.message}}}));
            this.setState({submitError: true});
        }
        else {
            this.setState(update(this.state, {fieldErrors: {[element.elementType]: {$set: ''}}}));
            this.setState({submitError: false});
        }
    }

    /*
    Start one-time payment (at the moment COVID-19 only, but ready to be extended to general case)
    The process is (updated 2020-12-11):
        1. BROWSER -> SERVER -> STRIPE: Create payment intent, add user
        2. BROWSER -> STRIPE.confirmCardPayment(intent_secret)
        3. STRIPE -> SERVER: calls hooks
    */
    async initiateOneTimePayment({stripe, billingDetails, stripeElements}) {
        LogPaymentApi('[OneTime payment] Init one-time payment...');

        const postParms = this.preparePaymentPostParams();

        LogPaymentApi('[OneTime payment] Calling POST /subscription/create_payment_intent postParms:', postParms);

        const url = new URL(window.location.href);

        if (url.searchParams.get('d')) {
            postParms['dispatched'] = true;
        }

        try {
            const response = await axios.post('subscription/create_payment_intent', postParms);
            LogPaymentApi('[OneTime payment] Reponse is:', response);

            const currentIntentSecret = response.data.intent_secret;

            // examples at https://github.com/stripe/react-stripe-elements
            // warning - it's not the "react-stripe-js" yet, this code is "react-stripe-elements"
            const cardElement = stripeElements.getElement('cardNumber');
            LogPaymentApi(`[OneTime payment] Calling stripe.confirmCardPayment(currentIntentSecret: ${currentIntentSecret}`);

            stripe.confirmCardPayment(currentIntentSecret,
                {
                    payment_method: {
                        type: 'card',
                        card: cardElement,
                        billing_details: billingDetails
                    }
                }
            ).then((stripeResult) => {
                LogPaymentApi('[OneTime payment] Stripe payment result is:', stripeResult);
                if(stripeResult.error) {
                    this.setState({
                        disableSubmitButton: false,
                        submitError: true,
                        submitErrorMessage: stripeResult.error.message
                    });
                } else {
                    // The payment is confirmed.
                    this.completedPaymentOrSubscription(response);

                    utils.track('Completed Checkout Step', {checkout_id: this.state.checkoutId, step: 3});
                    utils.track('Checkout Step Viewed', {checkout_id: this.state.checkoutId, step: 4});
                }
            });
        }
        catch(err) {
            this.setState({
                disableSubmitButton: false,
                submitError: true,
                submitErrorMessage: utils.getFirstApiError(err, 'Unable to complete order at this time. Please try again.')
            });
        }
    }

    /* Start recurring payment (any type of subscription)
    The process is (updated 2020-12-11):
        1. BROWSER -> SERVER -> STRIPE: Create setup intent - no params, just "dummy" intent (OUT: intent_secret)
        2. BROWSER -> STRIPE.handleCardSetup (IN: intent_secret, OUT: setupIntent.payment_method)
        3. BROWSER -> SERVER: subscription (IN: setupIntent.payment_method)
            - this registers the user if needed in both internal database and STRIPE
            - calls STRIPE to create actual subscription
        4. STRIPE -> SERVER: calls hooks (create order etc.)
    */
    async initiateRecurringPayment({stripe, billingDetails}) {
        // create "setup intent"
        LogPaymentApi('[Recurring payment] Init recurring payment, calling subscription/create_stripe_setup_intent');
        const response = await axios.post('subscription/create_stripe_setup_intent');
        LogPaymentApi('[Recurring payment] Reponse is:', response);
        const currentIntentSecret = response.data.intent_secret;

        // Card Setup
        LogPaymentApi(`[Recurring payment] Calling stripe.handleCardSetup(currentIntentSecret: ${currentIntentSecret}`);
        stripe.handleCardSetup(currentIntentSecret, {
            payment_method_data: {billing_details: billingDetails}
        }).then((result) => {
            LogPaymentApi('[Recurring payment] Result is:', result);
            if(result.error) {
                this.setState({
                    disableSubmitButton: false,
                    submitError: true,
                    submitErrorMessage: result.error.message
                });
            } else {
                // The "intent setup" has succeeded.
                this.completeSubscription(result.setupIntent.payment_method);

                utils.track('Completed Checkout Step', {checkout_id: this.state.checkoutId, step: 3});
                utils.track('Checkout Step Viewed', {checkout_id: this.state.checkoutId, step: 4});
            }
        });
    }

    handleFormValid(stripe, args) {
        const stripeElements = args ? args.stripeElements : null;
        this.setState({submitError: false, submitErrorMessage: ''});
        this.setState(update(this.state, {sectionsComplete: {[this.props.section]: {$set: true}}}));

        const { addressMode, fields, error, encryptedUserId, checkoutId, isTravelToFlyScenario, shippingMethod } = this.state;
        //Final step where adding payment
        if(this.props.section === 'payment') {
            this.setState({disableSubmitButton: true});
            const {
                email,
                mobile_country,
                mobile,
                card_holder_name,
                delivery_address1,
                delivery_address2,
                delivery_city,
                delivery_postcode,
                delivery_country ,
                billing_address1,
                billing_address2,
                billing_city,
                billing_postcode,
                billing_country,
            } = this.state.fields;


            const billingDetails = {
                name: card_holder_name,
                phone: mobile_country+mobile,
                email: email,
                address: {
                    'line1': delivery_address1,
                    'line2': delivery_address2,
                    'city': delivery_city,
                    'country': delivery_country,
                    'postal_code': delivery_postcode,
                }
            };

            if(addressMode == 'different-address'){
                billingDetails['name'] = card_holder_name;
                billingDetails['address']['line1'] = billing_address1;
                billingDetails['address']['line2'] = billing_address2;
                billingDetails['address']['city'] = billing_city;
                billingDetails['address']['country'] = billing_country;
                billingDetails['address']['postal_code'] = billing_postcode;
            }


            // Initiate one-off payment or card setup
            if (this.state.isOneTimePayment) {
                this.initiateOneTimePayment({stripe, billingDetails, stripeElements});
            } else {
                this.initiateRecurringPayment({stripe, billingDetails});
            }
        }
        else {
            const urlSegment = window.location.search || '';
            if(this.props.section === 'account') {
                if(isTravelToFlyScenario) {
                    this.props.history.push(`/join/travel${urlSegment}`);
                    //utils.scrollToHash(document.getElementById('travel-section')); //@FIXME not working
                } else if(shippingMethod == 'default') {
                    this.props.history.push(`/join/payment${urlSegment}`);
                    utils.scrollToHash(document.getElementById('billing-section'));
                } else {
                    this.props.history.push(`/join/delivery${urlSegment}`);
                    utils.scrollToHash(document.getElementById('delivery-section'));
                }

                utils.track('Completed Checkout Step', {checkout_id: checkoutId, step: 1});
                utils.track('Checkout Step Viewed', {checkout_id: checkoutId, step: 2});

            } else if(this.props.section === 'delivery' && shippingMethod != 'default') {
                this.props.history.push(`/join/payment${urlSegment}`);
                utils.scrollToHash(document.getElementById('billing-section'));

                utils.track('Completed Checkout Step', {checkout_id: checkoutId, step: 2});
                utils.track('Checkout Step Viewed', {checkout_id: checkoutId, step: 3});

            } else if(this.props.section === 'travel') {
                this.props.history.push(`/join/payment${urlSegment}`);
                utils.scrollToHash(document.getElementById('billing-section'));

                utils.track('Completed Checkout Step', {checkout_id: checkoutId, step: 2});
                utils.track('Checkout Step Viewed', {checkout_id: checkoutId, step: 3});
            }
            // else if(this.props.section === 'delivery') redirectTo = 'payment';
            else if(this.props.section === 'payment') {
                this.props.history.push('/join/complete');
                //DONT THINK THIS IS USED??
            }
            else {
                axios.post('subscription/set-password', {
                    email: fields.email,
                    password: fields.password,
                    encrypted_user_id: encryptedUserId,
                }).then((response) => {
                    if(response.data.status) {
                        this.setState({showShareIcon:true});
                    }
                }).catch((err) => {
                        this.setState({submitError: true, submitErrorMessage: utils.getFirstApiError(err, 'session invalid')});
                });

                utils.track('Completed Checkout Step', {checkout_id: checkoutId, step: 4});
            }
        }
    }

    preparePaymentPostParams() {
        const { variation, quantity } = this.state;

        const postParms = Object.entries(this.state.fields).reduce((acc, [key, value]) => {
            if (
                [
                    'affiliate_code',
                    'billing_address1',
                    'billing_address2',
                    'billing_city',
                    'billing_postcode',
                    'billing_country',
                    'card_holder_name',
                    'flight_number',
                    'arrival_day',
                    'arrival_month',
                    'arrival_year',
                    'departure_day',
                    'departure_month',
                    'departure_year',
                    'departure_country',
                    'transit_countries',
                    'transit_country_1',
                    'transit_country_2',
                    'transit_country_3',
                    'lab_availability',
                    ].includes(key)) {
                return acc;
            }

            return {...acc, [key]: value };

        }, {});

        postParms['reference_id'] = this.state.reference_id;
        postParms['affiliate_code'] = this.state.fields.affiliate_code;
        postParms['mobile'] = this.state.fields.mobile.replace(`+${this.state.fields.mobile_country}`, '');

        if (this.state.isTravelToFlyScenario) {
            const { quantity, fields } = this.state;
            const {
                flight_number, arrival_day, arrival_month, arrival_year,
                departure_day, departure_month, departure_year, departure_country,
                transit_countries, transit_country_1, transit_country_2, transit_country_3,
                lab_availability
            } = fields;

            postParms.travelDetails = {
                flight_number,
                ...(!this.state.product.required_outbound_from && {inbound_to_date: `${arrival_year}-${utils.ifSingleDigitReturnDouble(arrival_month)}-${utils.ifSingleDigitReturnDouble(arrival_day)}`}),
                ...(!this.state.product.required_outbound_from && {inbound_from_date: `${departure_year}-${utils.ifSingleDigitReturnDouble(departure_month)}-${utils.ifSingleDigitReturnDouble(departure_day)}`}),
                ...(this.state.product.required_outbound_from && {outbound_from_date: `${departure_year}-${utils.ifSingleDigitReturnDouble(departure_month)}-${utils.ifSingleDigitReturnDouble(departure_day)}`}),
                departure_country,
                transit_countries,
                transit_countries_list: [],
                lab_availability,
            };

            if (transit_country_1) {
                postParms.travelDetails.transit_countries_list.push(transit_country_1);
            }
            if (transit_country_2) {
                postParms.travelDetails.transit_countries_list.push(transit_country_2);
            }
            if (transit_country_3) {
                postParms.travelDetails.transit_countries_list.push(transit_country_3);
            }

            postParms.travelDetails.persons = [];
            for (let personIdx = 0; personIdx < quantity; personIdx++) {
                const firstNameField = `pax_${personIdx + 1}_first_name`;
                const lastNameField = `pax_${personIdx + 1}_last_name`;
                postParms.travelDetails.persons[personIdx] = {
                    first_name: fields[firstNameField] || '',
                    last_name: fields[lastNameField] || ''
                };
            }
        }

        // let postParams = this.state.fields;
        // Add in stuff about the order
        postParms.product_variation_id = variation.id;
        postParms.quanity = quantity;
        postParms.is_gift = this.props.isGift;
        postParms.campaign_id = this.props.campaignId;

        return postParms;
    }

    completedPaymentOrSubscription(response) {
        const { variation, fields, quantity, paymentFrequency, postage, checkoutId } = this.state;
        this.setState({
            redirectTo: 'complete',
            disableSubmitButton: false,
            submitErrorMessage: '',
            submitError: false,
            showShareIcon: !response.data.is_new_user, //will determin of they see password box
            encryptedUserId: response.data.encrypted_user_id,
            showRequiresExtraPaymentConfirmationMessage: response.data.requires_extra_confirmation,
            orderValue: parseFloat(this.calculateTotal(false, true, false, true, true), 10),
            extid: String(response.data.user_id),
            orderNumber: response.data.orderId
        });

        utils.setAnalyticsUser(response.data.user_id);

        utils.track('Order Completed', {
            checkout_id: checkoutId,
            order_id: response.data.order_id,
            affiliation: fields.affiliate_code,
            revenue: parseFloat(this.calculateTotal(false, true, false, true, true), 10),
            shipping: postage.postage_price,
            currency: variation.currency,
            //payment_frequency: paymentFrequency, //Not offical segment parm
            products: [{
                product_id: variation.id,
                sku: variation.suk,
                name: variation.suk,
                price: variation.price,
                quantity: parseInt(quantity, 10),
            }]
        });

        //Track TO GA ecommerce
        if(config.app.env === 'production') {
            // eslint-disable-next-line no-undef
            if(typeof $FPROM !== 'undefined') {
                //Track to firstpromoter
                // eslint-disable-next-line no-undef
                $FPROM.trackSignup({uid:response.data.stripe_id},function(){});
            }
        }
    }

    completeSubscription(paymentMethod) {
        LogPaymentApi('completeSubscription() called with paymentMethod', paymentMethod);
        const postParms = this.preparePaymentPostParams();
        postParms.payment_method = paymentMethod;

        //Call API to complete
        LogPaymentApi('completeSubscription() POST /subscription', postParms);
        axios.post('subscription', postParms)
            .then((response) => {
                LogPaymentApi('completeSubscription() Response:', response);
                this.completedPaymentOrSubscription(response);
            })
            .catch((error) => {
                LogPaymentApi('Error:', error);
                this.setState({
                    disableSubmitButton: false,
                    submitError: true,
                    submitErrorMessage: utils.getFirstApiError(error, 'Error submitting  payment. Please contact customer support.')
                });
            });
    }

    // If use is logged in... let em logout!
    handelLogout(e) {
        e.preventDefault();

        axios.post('auth/logout')
            .then((response) => {
                //Redirect so page can refresh.. product will be saved
                window.location.href = '/join/account';
            });
    }

    handelMonthlyYearlyChange(e) {
        const {product} = this.props;

        const paymentFrequency = e.target.value;
        let newVariation = null;

        //Switch the variation
        for(const x in product.variations) {
            if(product.variations[x].gift_only == 0) {
                if(product.variations[x].frequency === paymentFrequency) {
                    newVariation = product.variations[x];
                    break;
                }
            }
        }

        this.setState({paymentFrequency: paymentFrequency, variation: newVariation});
    }

    handleQuantityChange(e) {
        this.setState({quantity: e.target.value});
    }

    handleFormInvalid() {
        this.setState({submitError: true});
    }

    handelCouponCodeFormOpen(e) {
        e.preventDefault();
        this.setState({couponCodeFormOpen: true});

        utils.track('Coupon Form Opened');
    }

    submitCouponCode() {
        utils.track('Coupon Entered', {coupon_id: this.state.fields.coupon_code});

        // Call API to validate
        axios.post('subscription/validate_coupon_code', {
            coupon_code: this.state.fields.coupon_code,
            product_variation_id: this.state.variation,
            campaign_id: this.props.campaignId,
        }).then((response) => {
            this.setState({
                couponCodeFormError: '',
                couponCodeFormSuccess: response.data.description,
                discountTitle: response.data.title,
                discountAmount: response.data.amount_off,
                discountPercentage: response.data.percent_off,
                discountDuration: response.data.duration,
                discountDurationMonths: response.data.duration_in_months,
            });

            utils.track('Coupon Applied', {coupon_id: this.state.fields.coupon_code});
        })
            .catch((error) => {
                const errorMsg = utils.getFirstApiError(error);//.data ? error.response.data.error.errors.coupon_code[0] : 'Invalid code';
                this.setState({
                    couponCodeFormError: errorMsg,
                    couponCodeFormSuccess: false,
                    discountTitle: null,
                    discountAmount: null,
                    discountPercentage: null,
                    discountDuration: null,
                    discountDurationMonths: null,
                });

                utils.track('Coupon Denied', {coupon_id: this.state.fields.coupon_code, 'reason': errorMsg});

                //Reset coupon code otherwise gets passed with form..
                this.setState(update(this.state, {fields: {['coupon_code']: {$set: ''}}}));
            });
    }

    handelCouponCodeSubmit(e) {
        e.preventDefault();
        this.submitCouponCode();
    }

    calculatePostageCosts(countryCode) {
        const {productSuk, slug} = this.props;

        axios.post('/products/postage_cost', {
            country_code: countryCode,
            slug: slug,
        })
            .then(response => {
                this.setState({postage: response.data});
            })
            .catch(error => {
                // TODO How are we handling errors? There is a util function - look up how it works.
            });
    }

    calculateTotal(includeCurrency, includeDiscount, includePostage = false, includeExtraPrice = false, includeEpiKitPrice = false) {
        const {variation, quantity, postage} = this.state;
        const {product} = this.props;

        if(!variation) {
            return '';
        }

        let totalAmount = quantity*variation.price;

        //Any one off costs?
        if(includeExtraPrice && variation.extra_price) {
            totalAmount += (quantity*variation.extra_price);
        }

        if(includeEpiKitPrice && variation.epi_kit_price) {
            totalAmount += (quantity*variation.epi_kit_price);
        }

        //Apply any discounts from coupon codes
        if(includeDiscount) {
            if(this.state.discountAmount) {
                totalAmount -= (this.state.discountAmount*quantity);
            }
            if(this.state.discountPercentage) {
                totalAmount = totalAmount * ((100-this.state.discountPercentage)/100);
            }
        }

        // Postage. THIS IS NOT discountable so must come after discount is calculated
        if(includePostage && postage && postage.postage_price) {
            totalAmount += postage.postage_price;
        }

        if(includeCurrency) {
            return variation.currency_symbol + utils.formatCurrency(totalAmount);
        }

        return utils.formatCurrency(totalAmount);
    }

    UpdateState(obj) {
        this.setState(obj);
    }


    render() {
        let {submitError, submitErrorMessage, redirectTo, isLoggedIn, variation, quantity, isTravelToFlyScenario,
            paymentFrequency, postage, product, sectionsComplete, isLoading, countrys, shippingMethod} = this.state;
        const {section} = this.props;

        if(!(countrys && countrys.length) || isLoading) {
            return <Loader />;
        }

        //Check we are on a section we should be
        if(section === 'payment') {
            if(!this.state.sectionsComplete.account) {
                redirectTo = 'account';
            }
            else if(isTravelToFlyScenario && !this.state.sectionsComplete.travel) {
                redirectTo = 'travel';
            }
            else if(!this.state.sectionsComplete.delivery && shippingMethod != 'default') {
                redirectTo = 'delivery';
            }
        }
        else if(section === 'delivery' && !this.state.sectionsComplete.account) {
            redirectTo = 'account';
        }
        /*
        else if(section === 'account' && this.state.isLoggedIn) {
            //If already logged in we dont need to create an account..
            redirectTo = 'delivery';
        }
        */


        if(redirectTo) {
            this.props.history.push(`/join/${redirectTo}`);
            return null;
        }


        /*
        const productFrequency = variation ? variation.frequency.slice(0, -2) : '';
        const extraPriceShowHideClass = variation && variation.extra_price ? '' : 'hide';
        const discountShowHideClass = this.state.couponCodeFormSuccess ? '' : 'hide';


        //Find the monthly and yearly prices
        let monthlyVariation = null;
        let yearlyVariation = null;
        let yearlySaving = '';

        if(product) {
            for(let x in product.variations) {
                if(product.variations[x].gift_only == 0) {
                    if(product.variations[x].frequency === 'monthly') {
                        monthlyVariation = product.variations[x];
                    }
                    else if(product.variations[x].frequency === 'yearly') {
                        yearlyVariation = product.variations[x];
                    }
                }
            }

            if(monthlyVariation && yearlyVariation) {
                yearlySaving = yearlyVariation.currency_symbol + (monthlyVariation.price*12 - yearlyVariation.price);
            }
        }

        // Variables for order container price details area
        let hasOneTimeCost = variation && variation.extra_price ? true : false;
        let isFirstSameAsRepeatPrice = variation && !hasOneTimeCost && postage && this.calculateTotal(true, true, true, true) === this.calculateTotal(true, false, false, false);
        let hasOneTimePostage = postage ? postage.postage_price : false;
        let hasSuccessfulOneTimeDiscount = this.state.couponCodeFormSuccess && this.state.discountDuration==='once';
        //let showFrequency = variation ? variation.type==REPEAT && ((!hasSuccessfulOneTimeDiscount && !hasOneTimePostage) || (hasOneTimePostage && hasSuccessfulOneTimeDiscount && isFirstSameAsRepeatPrice) || !hasOneTimeCost) : false;
        */
        const isBranded = cachedPartnerTheming();


        return (
            <section className={`checkout-section content-wrapper-animate${!isBranded ? ' default-theme-background' : ''}`}>

                <div className="checkout-section__container">

                    {/*<ul className="checkout-menu">*/}
                    {/*<li><NavLink to="/join/account" activeClassName="active">1. Account Info</NavLink></li>*/}
                    {/*<li className="checkout-menu__divider">/</li>*/}
                    {/*<li><NavLink to="/join/delivery" activeClassName="active">2. Delivery Address</NavLink></li>*/}
                    {/*<li className="checkout-menu__divider">/</li>*/}
                    {/*<li><NavLink to="/join/payment" activeClassName="active">3. Payment Details</NavLink></li>*/}
                    {/*</ul>*/}

                    {/*section === 'account' && product && variation &&
                        <div className="checkout-options">
                            <div className="checkout-options__col">
                                <img src="/img/products/box-transparent-small.png" alt={product.title} className="checkout-options__image" />
                                {product.title}
                            </div>
                            <div className="checkout-options__col checkout-options__col--quantity">
                                <label htmlFor="quantity" className="checkout-options__quanity-label">Quantity</label>



                                <div className="form__select-wrapper">
                                <select value={quantity} id="quantity" className="checkout-options__quantity-select"
                                        onChange={(e) => this.handleQuantityChange(e)}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                <i className="icon-down-open"></i>
                                </div>
                            </div>
                            <div className="checkout-options__col checkout-options__col--how-pay">
                                <div className="checkout-options__pay-title">How would you like to pay?</div>
                                <div>
                                    <input type="radio" name="how-pay" id="how-pay-monthly" checked={paymentFrequency=='monthly'} onChange={(e) => this.handelMonthlyYearlyChange(e)} value="monthly"/>
                                    <label className="checkout-options__option-label" htmlFor="how-pay-monthly">Monthly - {monthlyVariation.price_formated} / month <span className="checkout-options__payment-length">(min 12 months)</span></label>
                                </div>
                                <div>
                                    <input type="radio" name="how-pay" id="how-pay-yearly" checked={paymentFrequency=='yearly'} onChange={(e) => this.handelMonthlyYearlyChange(e)} value="yearly"/>
                                    <label className="checkout-options__option-label" htmlFor="how-pay-yearly">Yearly - {yearlyVariation.price_formated} / year <span className="checkout-options__payment-save">(Save {yearlySaving})</span></label>
                                </div>
                            </div>
                        </div>
                    */}


                    {section !== 'complete' && <>
                        <OrderSummary
                            {...this.props}
                            {...this.state}
                            calculateTotal={this.calculateTotal}
                            hasTravelSection={this.state.isTravelToFlyScenario}
                            handelCouponCodeSubmit={this.handelCouponCodeSubmit}
                            handelCouponCodeFormOpen={this.handelCouponCodeFormOpen}
                            handleInputChange={this.handleInputChange}
                            handleQuantityChange={this.handleQuantityChange}
                            UpdateState={this.UpdateState}
                            showShippingWarning={this.state.showShippingWarning}
                        />
                        <Shipping
                            {...this.props}
                            {...this.state}
                            UpdateState={this.UpdateState}
                            onValid={this.handleFormValid}
                            onChange={this.handleInputChange}
                            onInvalid={this.handleFormInvalid}
                            calculatePostageCosts={this.calculatePostageCosts}
                            isCompleted={sectionsComplete.account}
                            shouldExpand={true}
                            history={this.props.history}
                            onUpdateAddress={this.handleUpdateAddress}
                            shippingMethod={shippingMethod}
                            hasTravelSection={this.state.isTravelToFlyScenario}
                            submitButtonText={this.state.isTravelToFlyScenario ? 'Continue to Travel' : shippingMethod === 'default' ? 'Continue to Billing' : 'Continue to Shipping Method'}
                            isLoggedIn={isLoggedIn}
                        />
                        {
                            shippingMethod === 'default' ? '' :
                                <ShippingMethod
                                    {...this.props}
                                    {...this.state}
                                    UpdateState={this.UpdateState}
                                    onValid={this.handleFormValid}
                                    onChange={this.handleInputChange}
                                    onInvalid={this.handleFormInvalid}
                                    isCompleted={sectionsComplete.delivery}
                                    hasTravelSection={this.state.isTravelToFlyScenario}
                                    shouldExpand={this.props.section === 'delivery' || sectionsComplete.delivery}
                                    history={this.props.history}
                                />

                        }
                        <>
                        {
                            this.state.isTravelToFlyScenario ?
                                <Travel
                                    {...this.props}
                                    {...this.state}
                                    UpdateState={this.UpdateState}
                                    onValid={this.handleFormValid}
                                    onChange={this.handleInputChange}
                                    onInvalid={this.handleFormInvalid}
                                    isCompleted={sectionsComplete.travel}
                                    shouldExpand={this.props.section === 'travel' || sectionsComplete.travel}
                                    history={this.props.history}
                                    requiredOutboundFrom={this.state.product.required_outbound_from}
                                    quantity={this.state.product.required_outbound_from ? 0 : this.state.quantity}
                                />
                            : null
                        }
                        </>
                        {
                            this.state.stripe ?
                                <StripeProvider stripe={this.state.stripe}>
                                    <Elements>
                                        <Billing
                                            {...this.props}
                                            {...this.state}
                                            UpdateState={this.UpdateState}
                                            onChange={this.handleInputChange}
                                            onCardFieldChange={this.handleCardFieldChange}
                                            onValid={this.handleFormValid}
                                            onInvalid={this.handleFormInvalid}
                                            hasTravelSection={this.state.isTravelToFlyScenario}
                                            submitError={submitError}
                                            submitErrorMessage={submitErrorMessage}
                                            disableSubmitButton={this.state.disableSubmitButton}
                                            isCompleted={sectionsComplete.payment}
                                            shouldExpand={this.props.section === 'payment' || sectionsComplete.payment}
                                            history={this.props.history}
                                            fieldErrors={this.state.fieldErrors}
                                            shippingMethod={shippingMethod}
                                        />
                                    </Elements>
                                </StripeProvider> : ''
                        }
                                               </>}
                    {section === 'complete' && <OrderComplete
                        {...this.props}
                        {...this.state}
                        fname={this.state.fields.delivery_fname}
                        onValid={this.handleFormValid}
                        onChange={this.handleInputChange}
                        onInvalid={this.handleFormInvalid}
                        UpdateState={this.UpdateState}
                        submitError={submitError}
                        submitErrorMessage={submitErrorMessage}
                        showShareIcon={this.state.showShareIcon}
                        error ={this.state.error}
                        showRequiresExtraPaymentConfirmationMessage={this.state.showRequiresExtraPaymentConfirmationMessage}
                                               />
                    }
                </div>

            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    branding: brandingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onFetchBranding: (brand) => dispatch(getSiteBrandingRequest(brand)),
});

const enhance = compose(
    withErrorBoundary,
    withError({reducer: 'branding', page: 'join'}),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Join);

function registerValidationConfig(props) {
    const {email, password, mobile_country, mobile} = props.fields;

    return {
        fields: ['email', 'password', 'mobile_country', 'mobile'],
        validations: {
            email: [
                [validationRules.isRequired, email],
                [validationRules.isEmail, email],
                // [validationRules.isEmailUnique, email],
            ],
            password: [
                [validationRules.isRequired, password],
                [validationRules.complexPassword, password]
            ],
            mobile_country: [
                [validationRules.isRequired, mobile_country]
            ],
            mobile: [
                [validationRules.isRequired, mobile],
                [validationRules.isMobile, mobile]
            ]
        },
    };
}
function deliveryValidationConfig(props) {
    const {delivery_fname, delivery_lname, delivery_address1, delivery_address2, delivery_city,
        delivery_postcode, delivery_country} = props.fields;

    return {
        fields: ['delivery_fname', 'delivery_lname', 'delivery_address1', 'delivery_address2',
            'delivery_city', 'delivery_postcode', 'delivery_country'],
        validations: {
            delivery_fname: [
                [validationRules.isRequired, delivery_fname],
                [validationRules.isName, delivery_fname]
            ],
            delivery_lname: [
                [validationRules.isRequired, delivery_lname],
                [validationRules.isName, delivery_lname]
            ],
            delivery_address1: [
                [validationRules.isRequired, delivery_address1]
            ],
            delivery_address2: [],
            delivery_city: [
                [validationRules.isRequired, delivery_city]
            ],
            delivery_postcode: [
                [validationRules.isRequired, delivery_postcode],
                [validationRules.isPostCode, delivery_postcode, delivery_country]
            ],
            delivery_country: [
                [validationRules.isRequired, delivery_country],
                //[validationRules.isCountry, delivery_country]
            ]
        },
    };
}
function paymentValidationConfig(props) {
    const {accepted_dna_services, accepted_terms, accepted_privacy, newsletter} = props.fields;

    return {
        fields: ['accepted_dna_services', 'accepted_terms', 'accepted_privacy', 'newsletter'],
        validations: {
            accepted_dna_services: [
                [validationRules.isTermsChecked, accepted_dna_services, 'You must accept these conditions to allow us to process your sample']
            ],
            accepted_terms: [
                [validationRules.isTermsChecked, accepted_terms, 'You must accept the terms & conditions']
            ],
            accepted_privacy: [
                [validationRules.isTermsChecked, accepted_privacy, 'You must accept the privacy policy']
            ],
            newsletter: []
        },
    };
}
function orderCompleteValidationConfig(props) {
    const {password} = props.fields;

    return {
        fields: ['password'],
        validations: {
            password: [
                [validationRules.isRequired, password],
                [validationRules.complexPassword, password]
            ],

        },
    };
}

class LoggedInRegisterForm extends Component {
    render() {
        const {onValid, handelLogout} = this.props;

        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                onValid();
            }} className="form checkout-form"
            >
                <h3 className="checkout__title">1. Your Account Info</h3>

                <div className="form__row">
                    <p>You are logged in as {window.CONFIG.user.email}. <a href="#" onClick={handelLogout}>Not you?</a></p>
                </div>


                <div className="form__row">
                    <button className="btn">Delivery Address<i className="icon-right-open"/></button>
                </div>
            </form>
        );
    }
}


class RegisterForm extends Component {

    render() {
        const {fields, onChange, onValid, onInvalid, $field, $validation,
            countrys, submitError} = this.props;


        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
            }} className="form checkout-form"
            >
                <h3 className="checkout__title">1. Your Account Info</h3>

                <div className="checkout__already-account"><Link to="/login?redirect=/join/account">Already have an account?</Link></div>

                <div className="form__row">
                    <label htmlFor="email" className="form__label">Your Email <span className="form__required">*</span></label>
                    <input type="email" value={fields.email} name="email" id="email" placeholder="order@chronomics.co.uk"
                           {...$field('email', (e) => onChange('email', e.target.value))}
                    />

                    <div className="form__error">{$validation.email.show ? $validation.email.error.reason : ''}</div>
                </div>

                <div className="form__row">
                    <label htmlFor="password" className="form__label">Password
                        <span className="form__required">*</span>
                        <Tooltip title="Minimum 8 characters" position="bottom">
                            <i className="icon-info-circled help-icon form__help-icon"/>
                        </Tooltip>
                    </label>
                    <input type="password" value={fields.password} name="password" id="password"
                           {...$field('password', (e) => onChange('password', e.target.value))}
                    />
                    <div className="form__error">{$validation.password.show ? $validation.password.error.reason : ''}</div>
                </div>


                <div className="form__row form__row--2col">
                    <div className="form__col2 form__col2--narrow">
                        <label htmlFor="mobile" className="form__label">Mobile Number <span className="form__required">*</span></label>
                        <div className="form__select-wrapper checkout-form__mobile-country">
                            <select name="mobile_country" id="mobile_country"
                                    value={fields.mobile_country}
                                    {...$field('mobile_country', (e) => onChange('mobile_country', e.target.value))}
                            >
                                <option key="" value=""></option>
                                {countrys.length && countrys.map((o, key) => o.dialing_code ? <option key={key} value={o.dialing_code}>{o.title} {o.dialing_code !== '-' ? '(+'+o.dialing_code+')' : ''}</option> : '')}
                            </select>
                            {!fields.mobile_country &&
                            <i className="icon-down-open"></i>
                            }
                        </div>
                        <div className="form__error">{$validation.mobile_country.show ? $validation.mobile_country.error.reason : ''}</div>
                    </div>

                    <div className="form__col2">
                        <input type="tel" value={fields.mobile} name="mobile" id="mobile" placeholder="777777777" className="checkout-form__mobile"
                               {...$field('mobile', (e) => onChange('mobile', e.target.value))}
                        />
                        <div className="form__error">{$validation.mobile.show ? $validation.mobile.error.reason : ''}</div>
                    </div>
                </div>

                <div className="form__row">
                    <button className="btn">Delivery Address<i className="icon-right-open"/></button>
                    {submitError &&
                    <div className="form__error form__error--general">Please complete the errors above.</div>
                    }
                </div>
            </form>
        );
    }
}
RegisterForm = validated(registerValidationConfig)(RegisterForm);



class DeliveryForm extends Component {

    render() {
        const {fields, onChange, onValid, onInvalid, $field, $validation, submitError, countrys, calculatePostageCosts} = this.props;

        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
            }} className="form checkout-form"
            >
                <h3 className="checkout__title">2. Delivery Address</h3>

                <div className="form__row form__row--2col">
                    <div className="form__col2">
                        <label htmlFor="delivery_fname" className="form__label">First Name <span className="form__required">*</span></label>
                        <input type="text" value={fields.delivery_fname} name="delivery_fname" id="delivery_fname" placeholder="John"
                               {...$field('delivery_fname', (e) => onChange('delivery_fname', e.target.value))}
                        />
                        <div className="form__error">{$validation.delivery_fname.show ? $validation.delivery_fname.error.reason : ''}</div>
                    </div>
                    <div className="form__col2">
                        <label htmlFor="delivery_lname" className="form__label">Last Name <span className="form__required">*</span></label>
                        <input type="text" value={fields.delivery_lname} name="delivery_lname" id="delivery_lname" placeholder="Smith"
                               {...$field('delivery_lname', (e) => onChange('delivery_lname', e.target.value))}
                        />
                        <div className="form__error">{$validation.delivery_lname.show ? $validation.delivery_lname.error.reason : ''}</div>
                    </div>
                </div>

                <div>
                    <label htmlFor="delivery_address1" className="form__label">Address 1 <span className="form__required">*</span></label>
                    <input type="text" value={fields.delivery_address1} name="delivery_address1" id="delivery_address1" placeholder="1 Chronomics Way"
                           {...$field('delivery_address1', (e) => onChange('delivery_address1', e.target.value))}
                    />
                    <div className="form__error">{$validation.delivery_address1.show ? $validation.delivery_address1.error.reason : ''}</div>
                </div>

                <div>
                    <label htmlFor="delivery_address2" className="form__label">Address 2</label>
                    <input type="text" value={fields.delivery_address2} name="delivery_address2" id="delivery_address2" placeholder="(Optional)"
                           {...$field('delivery_address2', (e) => onChange('delivery_address2', e.target.value))}
                    />
                    <div className="form__error">{$validation.delivery_address2.show ? $validation.delivery_address2.error.reason : ''}</div>
                </div>

                <div>
                    <label htmlFor="delivery_city" className="form__label">City <span className="form__required">*</span></label>
                    <input type="text" value={fields.delivery_city} name="delivery_city" id="delivery_city" placeholder="London"
                           {...$field('delivery_city', (e) => onChange('delivery_city', e.target.value))}
                    />
                    <div className="form__error">{$validation.delivery_city.show ? $validation.delivery_city.error.reason : ''}</div>
                </div>


                <div className="form__row form__row--2col">
                    <div className="form__col2">
                        <label htmlFor="delivery_postcode" className="form__label">Postcode <span className="form__required">*</span></label>
                        <input type="text" value={fields.delivery_postcode} name="delivery_postcode" id="delivery_postcode" placeholder="CR23 1ON"
                               {...$field('delivery_postcode', (e) => onChange('delivery_postcode', e.target.value))}
                        />
                        <div className="form__error">{$validation.delivery_postcode.show ? $validation.delivery_postcode.error.reason : ''}</div>
                    </div>

                    <div className="form__col2">
                        <label htmlFor="delivery_country" className="form__label">Country <span className="form__required">*</span></label>
                        <div className="form__select-wrapper">
                            <select
                                name="delivery_country"
                                id="delivery_country"
                                value={fields.delivery_country}
                                {...$field('delivery_country', (e) => { onChange('delivery_country', e.target.value); calculatePostageCosts(e.target.value); })}
                            >
                                <option key="" value=""></option>
                                {countrys.length && countrys.map((o, key) => <option key={key} value={o.country_code}>{o.title}</option>)}
                            </select>
                            <i className="icon-down-open"></i>
                        </div>
                        <div className="form__error">{$validation.delivery_country.show ? $validation.delivery_country.error.reason : ''}</div>
                    </div>
                </div>

                <div className="form__row">
                    <button className="btn">Payment Details<i className="icon-right-open"/></button>
                    {submitError &&
                    <div className="form__error form__error--general">Please complete the errors above.</div>
                    }
                </div>
            </form>
        );
    }
}
DeliveryForm = validated(deliveryValidationConfig)(DeliveryForm);




class _PaymentForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {fields, onChange, onCardFieldChange, onValid, onInvalid, $field, $validation,
            submitError, submitErrorMessage, stripe, disableSubmitButton} = this.props;

        const stripeFieldStyle = {
            base: {
                'fontFamily': 'Roboto',
                'fontSize': '15px',
                'color': '#1B1D26',
                '::placeholder': {
                    color: '#a3a4a8',
                }
            }
        };

        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.$submit(() => onValid(stripe), onInvalid);
            }} className="form checkout-form"
            >
                <h3 className="checkout__title">3. Payment Details</h3>

                <div className="form__row form__row--no-error">
                    Accepted cards:
                    <img className="checkout-form__cards" src="/img/accepted-cards.jpeg" alt="Accepted cards: Visa, Mastercard and American Express"/>
                </div>

                <div className="form__row form__row--no-error">
                    <label className="form__label">
                        Card number <span className="form__required">*</span>
                        <CardNumberElement style={stripeFieldStyle} onReady={(el) => el.focus()} onChange={(el) => onCardFieldChange(el)} />
                    </label>
                    {config.app.env !== 'production' &&
                    <p>Test: 4242424242424242</p>
                    }
                </div>

                <div className="form__row form__row--2col form__row--no-error">
                    <div className="form__col2">
                        <label className="form__label">
                            Expiration date <span className="form__required">*</span>
                            <CardExpiryElement style={stripeFieldStyle} onChange={(el) => onCardFieldChange(el)} />
                        </label>
                    </div>

                    <div className="form__col2">
                        <label className="form__label">
                            CVC <span className="form__required">*</span>
                            <CardCVCElement style={stripeFieldStyle} onChange={(el) => onCardFieldChange(el)} />
                        </label>
                    </div>
                </div>

                {/* <div className="form__row form__row--2col form__row--no-error">
                    <div className="form__col2">
                        <label className="form__label">
                            Postal code <span className="form__required">*</span>
                            <PostalCodeElement style={stripeFieldStyle} onChange={(el) => onCardFieldChange(el)} />
                        </label>
                    </div>
                    <div className="form__col2"/>
                </div> */}

                <div className="form__row">
                    <input type="checkbox" value={fields.accepted_dna_services} name="accepted_dna_services" id="accepted_dna_services" value="1"
                           checked={fields.accepted_dna_services}
                           {...$field('accepted_dna_services', (e) => onChange('accepted_dna_services', e.target.checked))}
                    />
                    <label className="form__label form__label--checkbox form__label--terms" htmlFor="accepted_dna_services">I understand that to receive and use
                        your DNA/RNA Services I will need to give you a DNA/RNA  sample. I hereby consent to Chronomics processing my DNA/RNA  sample f
                        or the purposes of providing the DNA/RNA  Services (as set out in the <a href={config.app.web_url+'/terms-conditions'} target="_blank" rel="noreferrer">Terms &amp; Conditions</a>)
                        and as described in your <a href={config.app.web_url+'/legal/privacy-policy'} target="_blank" rel="noreferrer">Privacy Policy</a>.
                        <br />You are free to withdraw your consent at any time but if you do so your access to, and
                        use of the, DNA/RNA Services will cease. <span className="form__required">*</span>
                    </label>

                    <div className="form__error">{$validation.accepted_dna_services.show ? $validation.accepted_dna_services.error.reason : ''}</div>
                </div>

                <div className="form__row">
                    <input type="checkbox" value={fields.accepted_privacy} name="accepted_privacy" id="accepted_privacy" value="1"
                           checked={fields.accepted_privacy}
                           {...$field('accepted_privacy', (e) => onChange('accepted_privacy', e.target.checked))}
                    />
                    <label className="form__label form__label--checkbox form__label--terms" htmlFor="accepted_privacy">I have read and understood
                        your  <a href={config.app.web_url+'/legal/privacy-policy'} target="_blank" rel="noreferrer">Privacy Policy</a>.
                        <span className="form__required">*</span>
                    </label>
                    <div className="form__error">{$validation.accepted_privacy.show ? $validation.accepted_privacy.error.reason : ''}</div>
                </div>

                <div className="form__row">
                    <input type="checkbox" value={fields.accepted_terms} name="accepted_terms" id="accepted_terms" value="1"
                           checked={fields.accepted_terms}
                           {...$field('accepted_terms', (e) => onChange('accepted_terms', e.target.checked))}
                    />
                    <label className="form__label form__label--checkbox form__label--terms" htmlFor="accepted_terms">I have read and
                        understood your <a href={config.app.web_url+'/terms-conditions'} target="_blank" rel="noreferrer">Terms &amp; Conditions</a>.
                        <span className="form__required">*</span>
                    </label>

                    <div className="form__error">{$validation.accepted_terms.show ? $validation.accepted_terms.error.reason : ''}</div>
                </div>

                <div className="form__row">
                    <input type="checkbox" value={fields.newsletter} name="newsletter" id="newsletter" value="1"
                           checked={fields.newsletter}
                           {...$field('newsletter', (e) => onChange('newsletter', e.target.checked))}
                    />
                    <label className="form__label form__label--checkbox form__label--terms" htmlFor="newsletter">Send me the latest epigenetic news (occasionally)!</label>
                    <div className="form__error">{$validation.newsletter.show ? $validation.newsletter.error.reason : ''}</div>
                </div>

                <div className="form__row">
                    <button className="btn" disabled={disableSubmitButton}>Complete Order<i className="icon-right-open"/></button>
                    {submitErrorMessage !== '' && submitError  &&
                    <div className="form__error form__error--general">{submitErrorMessage}</div>
                    }
                </div>
            </form>
        );
    }
}
//PaymentForm = injectStripe(PaymentForm);
const PaymentForm = injectStripe(validated(paymentValidationConfig)(_PaymentForm));


class OrderComplete extends Component {
    constructor(props) {
        super(props);
        this.state ={
            EyeIconOff:false,
        };
        this.handleEyeClick=this.handleEyeClick.bind(this);
    }
    handleEyeClick(){
        const{EyeIconOff} = this.state;
        this.setState({EyeIconOff:!EyeIconOff});
    }

    render() {
        const {fname, onChange, $validation, fields, onValid, onInvalid, $submit, submitError, showShareIcon, error , submitErrorMessage, showRequiresExtraPaymentConfirmationMessage, orderValue, variation: { currency }, extid, orderNumber} = this.props;
        const {EyeIconOff} = this.state;
        const isTradedoublerAffiliate = utils.getCookie('TRADEDOUBLER');

        return (
            <div className="checkout new-checkout-flow">
                {isTradedoublerAffiliate && <Helmet>{getConfirmationScript({ orderValue, currency, extid, orderNumber })}</Helmet>}

                <div className="checkout__form-col order-summary">
                    <h3 className="checkout__title"><span>{fname}</span>, your order is complete.</h3>

                    {showRequiresExtraPaymentConfirmationMessage &&
                        <p>Your payment requires extra validation to comply with new European banking regulations for online payments.
                        Please check your email for further details to complete this. Your order can not be processed until this is confirmed.
                        </p>
                    }

                    {!showShareIcon ? (
                        <div className="password-container">
                            <p>Now please set a password for your online account. This is required to register your kit online once received in the post.</p>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                $submit(onValid, onInvalid);
                            }} className="form checkout-form"
                            >
                                <div className="form__row">
                                    <label htmlFor="password" className="form__label">Password
                                        <Tooltip title="Minimum 8 characters" position="bottom">
                                            <i className="icon-info-circled help-icon form__help-icon"/>
                                        </Tooltip>
                                    </label>
                                    <input
                                        id="password-field"
                                        className="password-field"
                                        type={EyeIconOff ? 'text':'password'}
                                        value={fields.password}
                                        name="password"
                                        onChange={(e) => onChange('password', e.target.value)}
                                    />
                                    <span className="eye-open" onClick={this.handleEyeClick}><i className={EyeIconOff? 'icon-eye-off':'icon-eye'} title="Show/Hide password"/></span>
                                    <div className="form__error">{$validation.password.show ? $validation.password.error.reason : ''}</div>
                                </div>
                                <div className="password-container__account-create">
                                    <button className="btn create-account">
                                        Create account
                                    </button>
                                    {submitErrorMessage !== '' && submitError  &&
                                    <div className="form__error form__error--left form__error--general">{submitErrorMessage}</div>}
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div>
                            <p>Please see your email for confirmation and you will receive your kit in the post with further instructions.</p>

                            <p><StyledHyperlinkWrapper href="/dashboard" className="btn">View Your Account</StyledHyperlinkWrapper></p>

                            <div className="checkout__share-container">
                                <ShareIcons shareUrl={window.CONFIG.base_url} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
OrderComplete = validated(orderCompleteValidationConfig)(OrderComplete);
