import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FAQ from './faq';
import Overview from './overview';
import Science from './science';

import Disclaimer from '../../../components/common/disclaimer';
import Loader from '../../../components/common/loader';
import SubMenuBar from '../../../components/common/subMenuBar';
import withError from '../../../components/common/withError';

import { getIndicator } from '../../../services/you/actions';
import { youLoadingSelector, youLinksSelector, chromosomeAgeSelector } from '../../../services/you/selectors';

const statusMapping = {
    '-1': '#2CB5FF',
    '0': '#5B7BFF',
    '1': '#9374FF',
};

class ChromosomeAge extends Component {

    componentDidMount() {
        const { location, fetchIndicator } = this.props;
        const search = qs.parse(location.search);
        const id = search && search.previous_test;
        const paramsObject = {};

        if(id) {
            paramsObject['test_user_test'] = id;
        }
        fetchIndicator('chromosome-ageing', paramsObject);
    }

    render() {
        const { match, breakpoint, isLoading, results, links } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        const { title, sub_title, information, graph, more_info, related_articles, summary, current_test_date, is_latest } = results;

        const OverviewTab = () =>
            <Overview
                chromosomeGraph={graph}
                chromosomeInformation={information}
                gaugeGraph={summary}
                statusMapping={statusMapping}
                subTitle={sub_title}
                title={title}
            />;

        const ScienceTab = () =>
            <Science
                breakpoint={breakpoint}
                chromosomeGraph={graph}
                relatedArticles={related_articles}
                statusMapping={statusMapping}
            />;

        const FAQPage = () => <FAQ accordion={more_info} />;

        return (
            <section className="content-wrapper-animate chromosome-age__container">
                <SubMenuBar
                    currentTestDate={current_test_date}
                    hideMenuBar={true}
                    internalLinks={links.indicatorLinks}
                    navigation={links.youPageLink}
                    show={true}
                    showPrevious={!is_latest}
                />
                <div>
                    <Helmet>
                        <title>Chromosome Ageing - Hurdle</title>
                    </Helmet>
                </div>
                <section>
                    <Switch>
                        <Route path={`${match.url}/science`} render={ScienceTab} />
                        <Route path={`${match.url}/faq`} render={FAQPage} />
                        <Route path={match.url} render={OverviewTab} />
                    </Switch>
                </section>
                
                <Disclaimer />
            </section>
        );
    }
}

ChromosomeAge.propTypes = {
    history: PropTypes.object.isRequired,
    breakpoint: PropTypes.string,
    match: PropTypes.object.isRequired,
    results: PropTypes.object,
    isLoading: PropTypes.bool,
    links: PropTypes.object,
    fetchIndicator: PropTypes.func,
};

const mapStateToProps = (state) => ({
    results: chromosomeAgeSelector(state),
    isLoading: youLoadingSelector(state, 'chromosomeAge'),
    links: youLinksSelector(state, 'indicator'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchIndicator: (slug, params) => dispatch(getIndicator(slug, params)),
});

export default withError({reducer: 'you', page: 'indicator'})(connect(mapStateToProps, mapDispatchToProps)(ChromosomeAge));
