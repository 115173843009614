import React, { Component } from 'react';
import utils from '../helpers/utils';


export default class ShareIcons extends Component {
    render() {
        const {shareUrl} = this.props;

        return (
            <ul className="share-icons">
                <li className="share-icons__share-text">Share</li>
                <li><a href="#" onClick={(e) => utils.shareToSocial(e, 'facebook', shareUrl)}><i className={'icon-facebook'} title="Share to Facebook"/></a></li>
                <li><a href="#" onClick={(e) => utils.shareToSocial(e, 'twitter', shareUrl)}><i className={'icon-twitter'} title="Share to Twitter"/></a></li>
            </ul>
        );
    }
}