import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tracker from './tracker';
import Evolution from './evolution';

import withError from '../../../components/common/withError';

import { getTransposons, setTransposonsTooltip } from '../../../services/you/actions';
import { transposonsSelector, youLoadingSelector, transposonsTooltipSelector } from '../../../services/you/selectors';

const Transposons = ({match, fetchTransposons, isLoading, results, updateTransposonsTooltip, tooltips}) => {

    const TrackerPage = (props) => (
        <Tracker
            {...props}
            fetchTransposons={fetchTransposons}
            isLoading={isLoading}
            results={results}
            tooltips={tooltips}
            updateTransposonsTooltip={updateTransposonsTooltip}
        />
    );

    const EvolutionPage = (props) => (
        <Evolution
            {...props}
            fetchTransposons={fetchTransposons}
            isLoading={isLoading}
            results={results}
        />
    );

    return (
        <Switch>
            <Route
                path={`${match.url}/tracker`}
                render={TrackerPage}
            />
            <Route
                path={`${match.url}/evolution`}
                render={EvolutionPage}
            />
        </Switch>
    );
};

Transposons.propTypes = {
    match: PropTypes.object.isRequired,
    fetchTransposons: PropTypes.func,
};

const mapStateToProps = (state) => ({
    results: transposonsSelector(state),
    isLoading: youLoadingSelector(state, 'transposons'),
    tooltips: transposonsTooltipSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchTransposons: (slug) => dispatch(getTransposons(slug)),
    updateTransposonsTooltip: (title) => dispatch(setTransposonsTooltip(title)),
});

export default withError({reducer: 'you', page: 'transposons'})(connect(mapStateToProps, mapDispatchToProps)(Transposons));