import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FAQs from '../../../containers/you/common/faqs';

import GaugeChart from '../../../components/common/gaugeChart';
import ChromosomeChart from '../../../components/common/chromosomeChart';
import SplitConsumer from '../../../components/context/splitConsumer';

import { saveChromosomeValue } from '../../../services/you/actions';
import { chromosomeValueSelector } from '../../../services/you/selectors';
import { StyledLink } from '../../../theming/themed-components';

const RenderGaugeChartsSection = ({ gaugeGraph, statusMapping }) => {
  if (!(gaugeGraph && gaugeGraph.data)) {
    return null;
  }

  const { title, data } = gaugeGraph;

  const updatedGraph = Object.keys(data).map(item => {
    return { key: item, ...data[item] };
  });

  let startAngle = 90;

  return (
    <div className="chromosome-age__overview__section">
      {title !== '' && <h4 className="section-title">{title}</h4>}
      <div className="graphs-wrapper">
        {updatedGraph.map((item, index) => {
          const { status, percentage, count, total, title, description } = item;
          const value = [{ name: 'section', value: percentage, count: count, total: total }];

          if (index > 0) {
            //Get the previous graph's percentage
            const previousPercentage = updatedGraph[index - 1].percentage;

            //generate value based on the percentage
            const newAngle = startAngle - (360 * previousPercentage) / 100;

            //assign a value to startAngle through which the next graph will start from the end angle of previous graph
            startAngle = newAngle < 0 ? 360 + newAngle : newAngle;
          }

          return (
            <div key={index} className="gauge-chart__container">
              {title && <p className="gauge-chart__title">{title}</p>}
              <div className="gauge-chart__section">
                <GaugeChart data={value} fill={statusMapping[status]} startAngle={startAngle} />
              </div>
              <p className="gauge-chart__description">{description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RenderChromosomeAgeingSection = ({ chromosomeInformation }) => {
  if (!chromosomeInformation) {
    return null;
  }

  const { title, description, link } = chromosomeInformation;

  return (
    <div className="chromosome-age__overview__information">
      {title && <h4 className="section-title">{title}</h4>}
      {description && <p className="section-description">{description}</p>}
      {link && (
        <div className="section-button-container">
          <button className="btn">Learn more</button>
        </div>
      )}
    </div>
  );
};

const RenderChromosomeGraphSection = ({ chromosomeGraph, statusMapping, selectedValue, saveSelectedValue, chromosomeInformation }) => {
  if (!(chromosomeGraph && chromosomeGraph.data && chromosomeGraph.data.length)) {
    return null;
  }

  const { buttons, data } = chromosomeGraph;

  return (
    <div className="chromosome-age__overview__section">
      {RenderChromosomeAgeingSection(chromosomeInformation)}
      <div className="chromosome-buttons-container">
        <div className={`chromosome-button ${selectedValue.includes(-1) ? 'ageing-slower' : ''}`} onClick={() => saveSelectedValue(-1)}>
          {buttons[-1]}
        </div>
        <div className={`chromosome-button ${selectedValue.includes(0) ? 'ageing-same' : ''}`} onClick={() => saveSelectedValue(0)}>
          {buttons[0]}
        </div>
        <div className={`chromosome-button ${selectedValue.includes(1) ? 'ageing-faster' : ''}`} onClick={() => saveSelectedValue(1)}>
          {buttons[1]}
        </div>
      </div>
      <div className="chromosome-age__overview__graph-section">
        <ChromosomeChart data={data} activeValue={selectedValue} statusMapping={statusMapping} />
      </div>
    </div>
  );
};

const RenderFutureSection = () => (
  <SplitConsumer name="show_future_predictions">
    {value =>
      value == 'on' ? (
        <div className="biological-age__how-works">
          <div className="how-works-section">
            <div className="result-hero__how-works-section">
              <h4 className="range__title">Your future biological age</h4>
              <p className="indicator-section-information">See how different lifestyle factors impact your future biological age.</p>
              <p>
                <StyledLink className="btn" to="/dashboard/improvements/future-predictions/overview">
                  My Future
                </StyledLink>
              </p>
            </div>
          </div>
        </div>
      ) : (
        ''
      )
    }
  </SplitConsumer>
);

const Overview = props => {
  const { title, subTitle, gaugeGraph, statusMapping } = props;

  return (
    <div>
      {title || subTitle ? (
        <section className="result-hero chromosome-age__hero-section">
          <div className="result-hero__information-section">
            {title && <h1 className="result-hero__title study-hero__title">{title}</h1>}
            {subTitle && <p className="result-hero__title-information">{subTitle}</p>}
          </div>
        </section>
      ) : (
        ''
      )}

      <div className="chromosome-age__overview__container">
        <div className="biological-age__graph-section">{RenderGaugeChartsSection({ gaugeGraph, statusMapping })}</div>
        <div className="biological-age__graph-section">{RenderChromosomeGraphSection({ ...props })}</div>
        {RenderFutureSection()}
      </div>

      <FAQs slug="chromosome-ageing" />
    </div>
  );
};

Overview.propTypes = {
  chromosomeGraph: PropTypes.object,
  chromosomeInformation: PropTypes.object,
  gaugeGraph: PropTypes.object,
  statusMapping: PropTypes.object,
  saveSelectedValue: PropTypes.func,
  selectedValue: PropTypes.array,
};

const mapStateToProps = state => ({
  selectedValue: chromosomeValueSelector(state),
});

const mapDispatchToProps = dispatch => ({
  saveSelectedValue: value => dispatch(saveChromosomeValue(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
