import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BloodResults from './bloodResults';
import EpigeneticsResult from './epigeneticsResults';
import { fetchResultByTestId, fetchProductByProductCode, fetchBaseBiomarkers } from '../../services/you/web-api';
import Loader from '../../components/common/loader';
import withErrorBoundary from '../../components/common/errorBoundary';
import { EmbeddedBayerResult } from './embeddedBayerResult';
import config from '../../config';

const EPIGENETICS_BIOMARKER_CODE_PREFIX = 'EPI';
const BAYER_PRODUCTS = ['C1EPI02500', 'C1EPI02501'];
const { bayer_iframe_url } = config.app;

const isEPIProduct = test => {
  const biomarkerResults = test?.biomarkerResults;
  if (!Array.isArray(biomarkerResults)) {
    return false;
  }
  const biomarkerResult = biomarkerResults.find(biomarkerResult =>
    biomarkerResult?.biomarker.code.includes(EPIGENETICS_BIOMARKER_CODE_PREFIX),
  );
  return Boolean(biomarkerResult);
};

const mapBaseBiomarkersToBiomarkerDescriptions = baseBiomarkers => {
  const biomarkerDescriptions = baseBiomarkers.reduce((acc, baseBiomarker) => {
    const interpretationInformation = baseBiomarker.interpretationInformation;
    if (interpretationInformation?.length) {
      acc[baseBiomarker.code] = interpretationInformation.reduce(
        (acc, interpretationInformation) => {
          acc['about'] = baseBiomarker.description;
          acc.interpretations.push(interpretationInformation.content);
          return acc;
        },
        { about: '', interpretations: [] },
      );
    }
    return acc;
  }, {});
  return biomarkerDescriptions;
};

const BiomarkerBasedResults = ({
  match: {
    params: { testId },
  },
  testUser: { fname },
}) => {
  const [currentTest, setCurrentTest] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [biomarkerDescriptions, setBiomarkerDescriptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingResult, setIsLoadingResult] = useState(true);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isLoadingBiomarkerDescriptions, setIsLoadingBiomarkerDescriptions] = useState(true);
  const [isBayerProduct, setIsBayerProduct] = useState(null);

  useEffect(() => {
    const fetchTestResult = async testId => {
      // const response = mockBloodResults; // use this to mock the bloodTestResults
      return await fetchResultByTestId(testId);
    };

    fetchTestResult(testId)
      .then(testResponse => {
        setCurrentTest(testResponse);
      })
      .catch(error => {
        setErrorMessage(error.message || error);
      })
      .finally(() => setIsLoadingResult(false));
  }, []);

  useEffect(() => {
    setIsBayerProduct(BAYER_PRODUCTS.includes(currentTest?.productCode));
  }, [currentTest]);

  useEffect(() => {
    const getBaseBiomarkers = async () => {
      const { data } = await fetchBaseBiomarkers();
      const biomarkerDescriptions = mapBaseBiomarkersToBiomarkerDescriptions(data);
      setBiomarkerDescriptions(biomarkerDescriptions);
    };

    getBaseBiomarkers()
      .catch(error => {
        setErrorMessage(error.message || error);
      })
      .finally(() => {
        setIsLoadingBiomarkerDescriptions(false);
      });
  }, []);

  useEffect(() => {
    if (isLoadingResult === false && currentTest && currentProduct === null) {
      const fetchProduct = async currentTest => {
        return await fetchProductByProductCode(currentTest.productCode, { includeMarketingDetails: true });
      };

      fetchProduct(currentTest)
        .then(productResponse => {
          if (productResponse && productResponse.length > 0) {
            setCurrentProduct(productResponse[0]);
            return;
          }
          setErrorMessage('No product was returned from the request');
        })
        .catch(error => {
          setErrorMessage(error.message || error);
        })
        .finally(() => setIsLoadingProduct(false));
    }
  }, [currentProduct, currentTest, isLoadingResult]);

  if (errorMessage) throw new Error(errorMessage);

  const shouldDisplayLoader = isLoadingResult === true || isLoadingProduct === true || isLoadingBiomarkerDescriptions === true;

  return isBayerProduct ? (
    <EmbeddedBayerResult testId={testId} url={bayer_iframe_url} hmacSignature={currentTest.hmacSignature} />
  ) : (
    <div>
      {shouldDisplayLoader && <Loader />}
      {!shouldDisplayLoader && (
        <>
          {isEPIProduct(currentTest) ? (
            <EpigeneticsResult fname={fname} currentTest={currentTest} currentProduct={currentProduct} />
          ) : (
            <BloodResults
              fname={fname}
              currentTest={currentTest}
              currentProduct={currentProduct}
              biomarkerDescriptions={biomarkerDescriptions}
            />
          )}
        </>
      )}
    </div>
  );
};

BiomarkerBasedResults.propTypes = {
  testUser: PropTypes.shape({
    fname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      testId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default withErrorBoundary(BiomarkerBasedResults);
