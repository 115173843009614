import axios from 'axios';

import config from '../../config';

export const getProductInfoForProductCode = async productCode => {
  const accessToken = localStorage.getItem('access_token');
  const orderServiceApiUrl = config.app.order_service_api;
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios.get(`${orderServiceApiUrl}/registration/v1/products/${productCode}`, headers);
};
