
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from '../../components/common/loader';
import {modalButtons, modalMessages, modalTitles} from './utils';
import utils from '../helpers/utils';
import { StyledButton } from '../../theming/themed-components';

const OrderCancelModal = ({ onClose, openInfo, orderId, message }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    let cancelRequest;

    useEffect(() => {
        utils.track('Opened Cancel Order Modal');
        return () => { cancelRequest && cancelRequest(); };
    }, []);

    function deleteOrder() {
        setIsLoading(true);

        axios.delete(`user/orders/${orderId}`, {
            cancelToken: new axios.CancelToken(c => { cancelRequest = c; })
        })
            .then(() => {
                setIsLoading(false);
                setSuccess(true);
                utils.track('Order Canceled', {
                    order_id: orderId,
                    cancel_message: message
                });
            })
            .catch(() => {
                setIsLoading(false);
                setError(true);
            });
    }

    if (isLoading) {
        return (
            <div className="order-cancel-modal">
                <Loader />
            </div>
        );
    }

    if (success || error) {
        const title = success ?
            'Your order is successfully cancelled.' :
            'Failed to cancel order';
        return (
            <div className="order-cancel-modal">
                <div className="order-cancel-modal__title">{title}</div>
                <div className="order-cancel-modal__buttons">
                    <StyledButton className="btn btn--full-width" onClick={() => onClose(success)}>Ok</StyledButton>
                </div>
            </div>
        );
    }

    return (
        <div className="order-cancel-modal">
            {(message !== 'cancel_flight_not_cancelled' && message !== 'cancel_requested_refund') && <div className="order-cancel-modal__title">{modalTitles[message] || 'Are you sure you want to cancel this order?'}</div>}
            <div className="order-cancel-modal__text">
              {(message === 'refund_14_days_grace_return' && openInfo)
                ? modalMessages[message](openInfo) || 'You will be refunded back to the original card you paid on with 5-10 days.'
                : modalMessages[message] || 'You will be refunded back to the original card you paid on with 5-10 days.'} 
            </div>
            {(message !== 'cancel_flight_not_cancelled' && message !== 'cancel_requested_refund' && message !== 'downgrade_day_8_late') ?
            <div className="order-cancel-modal__buttons">
                <StyledButton className="btn" onClick={() => deleteOrder()}>{(modalButtons[message] && modalButtons[message][0]) || 'Process Refund'}</StyledButton>
                <button className="btn btn--white" onClick={() => onClose()}>{(modalButtons[message] && modalButtons[message][1]) || 'Cancel'}</button>
            </div> :
            <div className="order-cancel-modal__buttons">
                <StyledButton className="btn" onClick={() => onClose()}>Got it</StyledButton>
            </div>}
        </div>
    );
};

export default OrderCancelModal;
