import config from '../config';

export const LogoAlignment = {
  START: 'start',
  CENTER: 'center',
  END: 'end',
};

const lightGrey = '#EEEEEB';
const darkGrey = '#1B1D26';

export const DEFAULT_THEME = {
  base_font_size: 16,
  header: {
    bg_colour: lightGrey,
    logo_url: config.logo.dashboardLogo,
    nav_link_colour: darkGrey,
  },
  footer: {
    colour: lightGrey,
    text_colour: darkGrey,
    hide_copyright: false,
  },
  section: {
    background: 'white',
    login_background: lightGrey,
    covid_background: '#FFFFFF',
    cta_colour: darkGrey,
    error_colour: '#b3261e'
  },
};
