import React from 'react';
import PropTypes from 'prop-types';

import ImageComponent from './imageMapping';

const RenderExtraSection = (graph) => {
    if(!(graph && graph.extra_data)) {
        return null;
    }

    const { extra_data, you_label, other_label, icon_url } = graph;

    const keys = Object.keys(extra_data);

    return (
        <div className="factors-information-section">
            <div className="factors-graph-section extra-factors">
                <div className="factors-items-container">
                    {
                        keys.length && keys.map((key) => {
                            const youCount = parseInt(extra_data[key].you_count);
                            if(youCount <= 0) {
                                return null;
                            }
                            const showAll = [...Array(youCount).keys()];

                            return (
                                <div className="factors-featured-graph factors-featured-graph--extra" key={key}>
                                    <p className="factors-graph-label">{extra_data[key].you_label}</p>
                                    <div className="factors-graph-images">
                                        {showAll.map((number, index) => <div className="factor-image dot" key={index} />)}
                                    </div>
                                </div>
                            );
                        })
                    }
                    <p className="factors-graph-label">{you_label}</p>
                    <p className="factors-graph-label">{other_label}</p>
                </div>
                <div className="factors-top-image">
                    <img alt={icon_url} src={icon_url} />
                </div>
            </div>
        </div>
    );
};

const RenderFeaturedGraph = (graph,display) => {

    if(!graph || Array.isArray(graph)) {
        return null;
    }
    
    const imageSplit = graph.icon_url && graph.icon_url.split('/')[graph.icon_url.split('/').length - 1];

    const youCount = parseInt(graph.you_count);
    const otherCount = parseInt(graph.other_count);

    const all = youCount > otherCount ? youCount : otherCount;

    const showAll = [...Array(all).keys()];

    return (
        <div className="factors-information-section">
            <div className={display ? 'graph-container' :'factors-graph-section'}>
                <div className="factors-featured-graph">
                    <p className="factors-graph-label">{graph.you_label}</p>
                    <div className="factors-graph-images">
                        {showAll.map((number, youIndex) => <ImageComponent isActive={youIndex < youCount} key={youIndex} name={imageSplit.split('.')[0]} />)}
                    </div>
                </div>
                <div className="factors-featured-graph">
                    <p className="factors-graph-label">{graph.other_label}</p>
                    <div className="factors-graph-images">
                        {showAll.map((number, otherIndex) => <ImageComponent isActive={otherIndex < otherCount} key={otherIndex} name={imageSplit.split('.')[0]} />)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const RenderFeaturedTip = (info,display) => {
    if(!info) {
        return null;
    }
    return (
        <div className="factors-information-section right-section">
            <div className="factors-tip-section">
                {info.title ? <h4 className={display ? 'graph-section__title' :'section-title'}>{info.title}</h4> : ''}
                {info.text ? <p className="section-description" dangerouslySetInnerHTML={{ __html: info.text }} /> : ''}
                {
                    info.stat ?
                        <div className="biological-age__statistics-section feature">
                            <div className="statistics-value">
                                <p>{info.stat.number}</p>
                                <p>{info.stat.units}</p>
                            </div>
                            <div className="statistics-description">{info.stat.text}</div>
                        </div> : ''
                }
            </div>
        </div>
    );
};

const RenderFactor = (info) => {
    if(!info) {
        return null;
    }

    return (
        <div className="featured-information-section">
            <div className="factors-information-section">
                {
                    info.stat ?
                        <div className="biological-age__statistics-section">
                            <div className="impact-item-image">
                                <img src={info.icon_url} />
                            </div>
                            {info.text ? <div className="statistics-description" dangerouslySetInnerHTML={{ __html: info.text }} /> : ''}
                        </div> : ''
                }
            </div>
            <div className="factors-information-section right-section">
                {
                    info.stat ?
                        <div className="biological-age__statistics-section">
                            {(info.stat.number || info.stat.units) &&
                            <div className="statistics-value">
                                <p>{info.stat.number}</p>
                                <p>{info.stat.units}</p>
                            </div>
                            }
                            <div className="statistics-description">{info.stat.text}</div>
                        </div> : ''
                }
            </div>
        </div>
    );
};

const FeaturedSection = ({graph, info},display) => {
    if(!(graph || info)) {
        return null;
    }

    return (
        <div className="featured-information-section">
            {(graph && graph.extra_data) ? RenderExtraSection(graph,display) : RenderFeaturedGraph(graph,display)}
            {RenderFeaturedTip(info,display)}
            {
                (info.tip && !Array.isArray(info.tip)) ?
                    <div className="tip-container">
                        <h5 className="tip-title">{info.tip.title}</h5>
                        <p dangerouslySetInnerHTML={{__html: info.tip.text}}/>
                    </div> : ''
            }
        </div>
    );
};

const FeaturedFactors = ({items,display}) => {

    if(!items || !items.length) {
        return null;
    }

    return (
        <div className="biological-age__items-container">
            {
                items.map((item, index) => {
                    const { title, sub_title, info, is_featured } = item;
                    
                    return (
                        <div className={display ? 'biological-age__featured-section graph-section' : 'biological-age__featured-section '} key={index}>
                            { title || sub_title ?
                                <div className="factors-header-section">
                                    {!display && title ? <h4 className="header-title">{title}</h4> : ''}
                                    {!display && sub_title ? <p className="header-description" dangerouslySetInnerHTML={{ __html: sub_title }} /> : ''}
                                </div> : ''
                            }
                            {is_featured ? FeaturedSection(item,display) : RenderFactor(info,display)}
                        </div>
                    );
                })
            }
        </div>
    );
};

FeaturedFactors.propTypes = {
    items: PropTypes.array,
};

export default FeaturedFactors;