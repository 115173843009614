import React from 'react';
import { CarouselNextArrow, CarouselPrevArrow } from '../common/carouselArrows';
import config from '../../config';
import jwt_decode from 'jwt-decode';

export default {
  getCookie(name) {
    const dc = document.cookie;
    const prefix = name + '=';
    let begin = dc.indexOf('; ' + prefix);
    if (begin === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
    } else {
      begin += 2;
    }
    let end = document.cookie.indexOf(';', begin);
    if (end === -1) {
      end = dc.length;
    }
    return unescape(dc.substring(begin + prefix.length, end));
  },

  setAnalyticsUser(userId) {
    if (window.analytics) {
      window.analytics.alias(userId);
      window.analytics.identify(userId);
    }
  },

  track(name, properties = {}) {
    window.analytics && window.analytics.track(name, properties);
  },

  trackOutboundLink(to, section = '') {
    const properties = {
      url: to,
      section: section,
    };
    window.analytics && window.analytics.track('Outbound Link', properties);
  },

  isNumeric(input) {
    return !Array.isArray(input) && input - parseFloat(input) + 1 >= 0;
  },

  getFirstApiError(errorObj, defaultError) {
    //not always a api error
    if (errorObj.response) {
      let mainErrorObj = null;
      if (errorObj.response.data.error && errorObj.response.data.error.errors) {
        mainErrorObj = errorObj.response.data.error.errors;
      } else if (errorObj.response.data.errors) {
        mainErrorObj = errorObj.response.data.errors;
      }

      if (mainErrorObj) {
        for (const i in mainErrorObj) {
          const errorArray = mainErrorObj[i];
          if (Array.isArray(errorArray)) {
            return errorArray[0];
          } else {
            return errorArray;
          }
        }
      } else if (errorObj.response.data.error && errorObj.response.data.error.message) {
        return errorObj.response.data.error.message;
      } else if (errorObj.response.data.message) {
        return errorObj.response.data.message;
      }
    }

    return defaultError ? defaultError : 'Error. Please try again.';
  },

  scrollToHash(element, offsetPx = 0) {
    //Doesnt take into account the offset
    //element.scrollIntoView({ behavior: 'smooth' });

    const offset = element.offsetTop - 80 - offsetPx; //fixed menu offset
    window.scroll({ top: offset, left: 0, behavior: 'smooth' });

    return true;
  },

  formatCurrency(amount) {
    return amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  searchArrayByKeyValue(array, key, value) {
    for (const i in array) {
      if (array[i][key] && array[i][key] === value) return array[i];
    }
    return null;
  },

  defaultCarouselSettings() {
    return {
      lazyLoad: true,
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <CarouselNextArrow />,
      prevArrow: <CarouselPrevArrow />,
      responsive: [
        {
          breakpoint: config.breakpoints.medium,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: config.breakpoints.small,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
  },

  shareToSocial(e, type, url) {
    e.preventDefault();
    const typeConfig = config.socialShareConfig[type];
    const encodedUrl = encodeURI(url ? url : window.location.href);

    window.open(typeConfig.url + encodedUrl, type + '_share', 'width=' + typeConfig.width + ',height=' + typeConfig.height);

    this.trackEvent('Share', 'Click', type);
  },

  urlEncodeQueryParams(data) {
    const params = Object.keys(data).map(key => (data[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}` : ''));
    return params.filter(value => !!value).join('&');
  },

  isIEBrowser() {
    const navigator = window.navigator;
    return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
  },

  loadStripeElement() {
    //Stripe is loaded async
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = 'https://js.stripe.com/v3/';
    scriptElement.async = true;
    scriptElement.id = 'stripe-js';

    document.head.appendChild(scriptElement);
    return scriptElement;
  },

  removeStripeElement() {
    const scriptElement = document.querySelector('#stripe-js');
    scriptElement && document.head.removeChild(scriptElement);
  },

  isMobileOrTablet() {
    const navigator = window.navigator;
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  },

  defaultSliderMarks(min, max, labels) {
    return {
      [min]: {
        style: {
          fontSize: '18px',
          color: '#444444',
          textAlign: 'center',
          lineHeight: '18px',
        },
        label: labels ? labels.min : `${min}`,
      },
      [max]: {
        style: {
          fontSize: '18px',
          color: '#444444',
          textAlign: 'center',
          lineHeight: '18px',
        },
        label: labels ? labels.max : `${max}+`,
      },
    };
  },

  ifSingleDigitReturnDouble(value) {
    if (typeof value === 'string' && value.length === 1) {
      return '0' + value;
    }
    return value;
  },

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  },

  userIsLoggedIn() {
    const accessToken = localStorage.getItem('access_token');

    const decodeAccessToken = accessToken ? jwt_decode(accessToken) : {};

    return accessToken && decodeAccessToken.exp > Date.now() / 1000;
  },

  /**
   * @param {string} chars
   * @returns {string}
   *
   * Adds UK postcode spacing
   * eg. W45TF -> W4 5TF
   */
  formatPostcode(chars, country) {
    switch (country) {
      case 'GB': {
        chars = chars.toUpperCase().replace(/[^0-9a-z]/gi, '');
        const parts = chars.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
        if (!parts) return chars;
        parts.shift();

        return parts.join(' ');
      }
      default:
        return chars;
    }
  },

  /**
   * @param {string[]} ranges
   * @returns {string[]}
   *
   * Helps to avoid writing lists of postcodes manually by
   * generating UK postcode collections from ranges
   *
   * Accepts ranges as far as there is `-` betweent the range
   * and there are no letters at the end of the string
   *
   * correct: IV1-39, PH49-50
   * incorrect: DN55 1-9PT, A5-10A
   *
   * if no range is provided, it will generate an exact match
   * W4 5TF -> W4 5TF
   * SE -> SE
   */
  generateUKPostcodes(ranges) {
    const postcodes = [];

    ranges.forEach(postcode => {
      const hasRange = /-+/g.test(postcode);
      const [letters] = postcode.split(/\d+/g);

      if (!hasRange) {
        const digits = postcode.match(/\d+/g);
        if (!digits) return postcodes.push(postcode);

        const isLastCharDigit = /\d+/g.test(postcode.charAt(postcode.length - 1));
        if (isLastCharDigit && digits.length < 2) {
          let [digit] = digits;
          digit = digit.length < 2 ? digit + ' ' : digit;
          return postcodes.push(`${letters}${digit}`);
        }

        return postcodes.push(postcode);
      }

      const [from, to] = postcode.match(/\d+/g);
      for (let i = Number(from); i <= Number(to); i += 1) {
        const digit = String(i).length < 2 ? i + ' ' : i;
        postcodes.push(`${letters}${digit}`);
      }
    });

    return postcodes;
  },

  capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  },

  removeUnderscore(str) {
    return str?.split('_')?.join(' ');
  },
};

//padStart pollyfill from https://github.com/behnammodi/polyfill/blob/master/string.polyfill.js
if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ');
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}
