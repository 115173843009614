import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../common/loader';
import moment from 'moment';
import { StyledButton, StyledHyperlinkWrapper, StyledLink } from '../../../theming/themed-components';

export default class AccountSubscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subscription: null,
        };

        this.contact = this.contact.bind(this);
    }

    /*
        Get the subscription again with the full stripe billing information
    */
    componentDidMount() {
        this.mounted =  true;
        axios.get('user/subscription?include=stripe')
        .then(response => this.mounted && this.setState({
            subscription: response.data
        }))
        .catch(error => this.mounted && this.setState({
            subscription: {}
        }));
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    contact(openChat,msg) {
        openChat(msg);
    }


    render() {
        const {subscription} = this.state;
        const {openChat} = this.props;
        if(subscription === null) {
            return <Loader/>;
        }

        let statusHtml;

        if(subscription.status === 'active') {
            statusHtml = <p>Your subscription will automatically renew on {moment(subscription.next_payment_date).format('Do MMMM YYYY')} 
                             &nbsp;and you'll be charged {subscription.next_payment_amount_formated}.
                         </p>;
        }
        else if(subscription.status === 'canceling') {
            statusHtml = <p>You subscription is canceled and will finish on {moment(subscription.end_date).format('Do MMMM YYYY')}.
                         </p>;
        }
        else if(subscription.status === 'cancelled') {
            statusHtml = <div>
                            <p>Your subscription has ended.</p>
                            <p><StyledButton onClick={() => this.contact(openChat,'I would like to renew my subscription')} className="btn">Renew subscription<i className="icon-right-open"/></StyledButton></p> 
                         </div>;
        }
        else {
            statusHtml = <div>
                            <p>Your currently have no active subscription. This test may have been a gift or you 
                            may have purchase the test under a different account.
                            </p>
                            <br />
                            <div><StyledHyperlinkWrapper onClick={() => this.contact(openChat,'I would like to renew my subscription')} className="btn">Renew subscription</StyledHyperlinkWrapper></div>
                            <p>Please <a onClick={() => this.contact(openChat,'I cant see my subscription details in my account')}>contact us</a> if you purchased the test under a different account.</p>
                         </div>;

        }

        return (
            <div className="layout-2col__box">
                <div className="subscription__wrapper">
                    <p dangerouslySetInnerHTML={{__html: subscription ? subscription.product : ''}}></p>

                    {statusHtml}

                    {subscription.status === 'active' &&
                        <div className="account-billing">
                            <h5 className="account-billing__title">Payment Details</h5>
                            <div className="account-billing__card-container">
                                <div>
                                    {subscription.card_brand}
                                    **** {subscription.card_last_four}
                                </div>
                                <div>
                                    Expires: {subscription.card_expires_month}/{subscription.card_expires_year}
                                </div>
                            </div>

                            <StyledLink to="/dashboard/account/updatebilling" className="btn">Update<i className="icon-right-open"/></StyledLink>

                            <p className="account-billing__cancel">Want to change your plan or cancel? Please <a onClick={() => this.contact(openChat,'I would like to change/cancel my subscription')}>contact us</a>.</p>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
