import React from 'react';

import DiseaseRisk from '../../../../containers/you/common/diseaseRisk';

import WhatAffecting from './whatAffecting';
import CardiovascularHealth from './cardiovascularHealth';
import Comparisons from './comparisons';
import EpigeneticAndHeartbeat from './epigeneticAndHeartbeat';

import HeroSection from '../components/heroSection';
import HealthyRanges from '../components/healthyRanges';
import TrackEpigeneticAge from '../components/trackEpigeneticAge';
import GenesAffected from '../components/genesAffected';
import ResultImprovements from '../components/resultImprovements';
import HealthSpecialist from '../components/healthSpecialist';

const OverviewCardiovascularFitness = (props) => {
    const { slug, heroSectionInfo, healthyRanges, previousTest, resultDelta, ageOverTimeGraph, breakpoint, whatAffecting,
        customComparisons, genes, recommendations, diseaseRisk, team, resultUnit } = props;

    return (
        <section>
            {heroSectionInfo && heroSectionInfo.currentTestDateText ? <p className="result-hero__current-test-date">{heroSectionInfo.currentTestDateText}</p> : ''}
            <section className="biological-age__overview-container">
                <HeroSection
                    heroSectionInfo={heroSectionInfo}
                    previousTest={previousTest}
                    resultDelta={resultDelta}
                    slug={slug}
                />
            </section>
            <section className="biological-age__overview-container">
                <HealthyRanges healthyRanges={healthyRanges} wrapperClass="no-shadow" />
            </section>
            {
                heroSectionInfo && heroSectionInfo.analaysisText ?
                    <section className="biological-age__overview-container">
                        <div className="biological-age__graph-section no-padding">
                            <p className="result-hero__biological-description" dangerouslySetInnerHTML={{__html: heroSectionInfo.analaysisText}} />
                            <div className="disease-risk__epigenetics-section">
                                <img alt="epigenetics" src="/img/you/actionable-you/daniel-herranz.png" />
                                <p className="epigenetic-title">Dr. Daniel E. Martín Herranz Epigenetics expert</p>
                            </div>
                        </div>
                    </section> : ''
            }

            <CardiovascularHealth />

            <section className="biological-age__overview-container">
                <WhatAffecting whatAffecting={whatAffecting} breakpoint={breakpoint} />
            </section>

            <Comparisons customComparisons={customComparisons} />

            <section className="biological-age__overview-container">
                <TrackEpigeneticAge
                    biologicalData={ageOverTimeGraph}
                    breakpoint={breakpoint}
                    resultUnit={resultUnit}
                    wrapperClass="no-padding"
                />
            </section>
            
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    <GenesAffected genes={genes} />
                </div>
            </section>

            <EpigeneticAndHeartbeat />
            <section className="biological-age__overview-container">
                <ResultImprovements recommendations={recommendations} slug={slug} wrapperClass="no-padding" />
            </section>

            <section className="biological-age__overview-container">
                <DiseaseRisk diseaseRisk={diseaseRisk} />
            </section>

            <section className="biological-age__overview-container full-width">
                <div className="biological-age__graph-section no-padding">
                    <HealthSpecialist team={team} />
                </div>
            </section>
        </section>
    );
};

export default OverviewCardiovascularFitness;