import React, { Component } from 'react';
import Select from '../select';
import moment from 'moment';

const defaults = {
    min: 1918,
    max: moment().format('YYYY'),
};

export default class Year extends Component {
    constructor(props) {
        super(props);

        const {options} = this.props;

        const choices = [];
        const min = options.min ? parseInt(options.min, 10) : defaults.min;
        const max = options.max ? parseInt(options.max, 10) : defaults.max;

        for(let i=max; i>= min; i--) {
            choices.push({value: i});
        }

        this.state = {
            choices: choices,
        };

        this.handelOnChange = this.handelOnChange.bind(this);
    }

    handelOnChange(e) {
        const value = e.target.value;
        const {id, onChange} = this.props;

        onChange(id, value);
    }

    render() {
        return (
            <Select {...this.props} onChange={this.handelOnChange} choices={this.state.choices} style={{width: '130px'}}/>
        );
    }
}