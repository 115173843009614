import * as actionTypes from './constants';
// TODO:
// -> remove the mocks below
// import mockBloodResults from './mock-blood-results.json';

const initialState = {
  //indicator initial states
  indicator: {},
  indicatorLoading: true,
  indicatorLinks: {},
  indicatorError: null,

  //chromosome age initial states
  chromosomeAge: null,
  chromosomeAgeLoading: true,
  chromosomeSelectedValue: [-1],

  //comparison initial states
  compareLoading: true,
  comparison: null,
  comparisonLoading: true,
  comparisonSection: null,
  comparisonId: null,

  //transposons initial states
  transposonsLoading: true,
  transposons: null,
  transposonsError: null,
  transposonsTooltips: [],

  //you initial states
  you: null,
  youError: null,
  youLoading: true,
  selectedTest: null,

  //userDetails initial states
  userDetails: null,
  userDetailsError: null,
  userDetailsLoading: true,

  //covid results initial state
  covidData: null,
  covidResultError: null,
  covidResultLoading: true,

  // blood results initial state
  // bloodData: mockBloodResults, // -> Uncomment to use mock bloodData
  bloodData: null,
  bloodResultsError: null,
  bloodResultsLoading: false,

  //orders results initial state
  orders: null,
  ordersError: null,
  ordersLoading: true,

  //covid result
  singleCovidResult: null,
  singleCovidResultError: null,
  singleCovidResultLoading: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INDICATOR:
      return {
        ...state,
        indicatorLoading: true,
      };

    case actionTypes.FETCH_INDICATOR_SUCCESS: {
      const data = action.indicator;
      const tests = [];
      tests.push({ status: 'current', date: data.current_test_date }, { status: 'future', date: data.next_test_date });

      return {
        ...state,
        indicator: action.indicator,
        indicatorLoading: false,
        indicatorError: null,
      };
    }

    case actionTypes.FETCH_INDICATOR_FAILURE:
      return {
        ...state,
        indicatorError: action.error,
      };

    case actionTypes.FETCH_INDICATOR_LINKS:
      return {
        ...state,
        indicatorLinks: action.indicatorLinks,
      };

    case actionTypes.FETCH_CHROMOSOME_AGE:
      return {
        ...state,
        chromosomeAge: action.results,
        chromosomeAgeLoading: false,
      };

    case actionTypes.FETCH_CHROMOSOME_VALUE:
      return {
        ...state,
        chromosomeSelectedValue: action.value,
      };

    case actionTypes.FETCH_COMPARE_SUCCESS:
      return {
        ...state,
        compare: action.results,
        compareLoading: false,
      };

    case actionTypes.FETCH_COMPARE_FAILURE:
      return {
        ...state,
        compareError: action.error,
      };

    case actionTypes.FETCH_COMPARISON_SUCCESS:
      return {
        ...state,
        comparison: action.results,
        comparisonLoading: false,
        comparisonSection: action.section,
        comparisonId: action.id,
      };

    case actionTypes.FETCH_COMPARISON_LOADING:
      return {
        ...state,
        comparisonLoading: action.value,
      };

    case actionTypes.FETCH_TRANSPOSONS_SUCCESS:
      return {
        ...state,
        transposons: action.result,
        transposonsLoading: false,
      };

    case actionTypes.FETCH_TRANSPOSONS_FAILURE:
      return {
        ...state,
        transposonsError: action.error,
      };

    case actionTypes.FETCH_TRANSPOSONS_LOADING:
      return {
        ...state,
        transposonsLoading: action.value,
      };

    case actionTypes.UPDATE_TRANSPOSONS_TOOLTIP:
      return {
        ...state,
        transposonsTooltips: action.tooltips,
      };

    case actionTypes.FETCH_YOU_SUCCESS:
      return {
        ...state,
        you: action.results,
        youLoading: false,
      };

    case actionTypes.FETCH_YOU_FAILURE:
      return {
        ...state,
        youError: action.error,
        youLoading: false,
      };

    case actionTypes.FETCH_YOU_LOADING:
      return {
        ...state,
        youLoading: action.value,
      };

    case actionTypes.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.results,
        userDetailsLoading: false,
      };

    case actionTypes.FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetailsError: action.error,
        userDetailsLoading: false,
      };

    case actionTypes.FETCH_COVID_RESULTS_SUCCESS:
      return {
        ...state,
        covidData: action.covidData,
        covidResultLoading: false,
      };

    case actionTypes.FETCH_USER_DETAILS_LOADING:
      return {
        ...state,
        userDetailsLoading: action.value,
      };

    case actionTypes.FETCH_COVID_RESULTS_FAILURE:
      return {
        ...state,
        covidResultError: action.error,
        covidResultLoading: false,
      };

    case actionTypes.FETCH_COVID_RESULTS_LOADING:
      return {
        ...state,
        covidResultLoading: action.value,
      };

    case actionTypes.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.orders,
        ordersLoading: false,
      };

    case actionTypes.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        ordersError: action.error,
        ordersLoading: false,
      };

    case actionTypes.FETCH_ORDERS_LOADING:
      return {
        ...state,
        ordersLoading: action.value,
      };

    case actionTypes.SET_SELECTED_TEST:
      return {
        ...state,
        selectedTest: action.test,
      };

    case actionTypes.FETCH_COVID_RESULT:
      return {
        ...state,
        singleCovidResult: null,
        singleCovidResultError: null,
        singleCovidResultLoading: true,
      };

    case actionTypes.FETCH_COVID_SUCCESS:
      return {
        ...state,
        singleCovidResult: action.testResult,
        singleCovidResultLoading: false,
      };

    case actionTypes.FETCH_COVID_FAILURE:
      return {
        ...state,
        singleCovidResultError: action.error,
        singleCovidResultLoading: false,
      };

    // Blood results
    case actionTypes.FETCH_BLOOD_RESULTS_FAILURE:
      return {
        ...state,
        bloodResultsError: action.error,
      };
    case actionTypes.SET_BLOOD_RESULTS:
      return {
        ...state,
        bloodData: action.data,
      };
    case actionTypes.SET_BLOOD_RESULTS_LOADING:
      return {
        ...state,
        bloodResultsLoading: action.predicate,
      };

    default:
      return state;
  }
};
