import React from 'react';
import { Tooltip } from 'react-tippy';

const RenderPointSection = ({you}) => {
    if(!you) {
        return null;
    }

    const HTMLContent = <div className="methylation-graph__tooltip-text">{you.label || 'You'}</div>;
    return (
        <div className="compare-result-point" style={{left: `calc(${you.percent}% - 3px)`}}>
            <Tooltip
                arrow={true}
                arrowSize="small"
                html={HTMLContent}
                open={true}
                position="top"
                theme="light"
            >
                <div className="tooltip-child" />
            </Tooltip>
        </div>
    );
};

const CompareLineGraph = ({data}) => {
    if(!(data && data.range)) {
        return null;
    }

    const { x, range, you } = data;

    return (
        <div className="compare-line__container">
            <div className="compare-line__bar">
                {RenderPointSection({you})}
                {
                    range ?
                        <div
                            className="compare-line__bar compare-line__bar--inside-bar"
                            style={{width: `${range.max_percent - range.min_percent}%`, left: `${range.min_percent}%`}}
                        /> : ''
                }
            </div>
            {
                x ?
                    <div className="methylation-graph__labels">
                        <p>{x.min_label}</p>
                        <p>{x.max_label}</p>
                    </div> : ''
            }
        </div>
    );
};

export default CompareLineGraph;