import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
//import ReactGA from "react-ga";

/*
    Used for logging page URLs to segment/anylatics
*/

class LogPageView extends PureComponent {

    componentDidMount() {
        const newPageUrl = this.props.location.pathname + this.props.location.search + this.props.location.hash;
        this.track(newPageUrl);
    }

    componentDidUpdate(prevProps) {
        const lastPageUrl = prevProps.location.pathname + prevProps.location.search + prevProps.location.hash;
        const newPageUrl = this.props.location.pathname + this.props.location.search + this.props.location.hash;
        
        if(lastPageUrl !== newPageUrl) {
            this.track(newPageUrl);
        }
    }

    track(url) {
        // ReactGA.pageView(newPageUrl);
        const analytics = window.analytics;
        analytics && analytics.page(url);
    }

    render() {
        return null;
    }
}

export default withRouter(LogPageView);