import { get } from '../methods';

import * as API_CONSTANTS from './constants';

const mapSuccess = response => {
  return response.data || {};
};

const mapError = err => {
  return {
    errorResponse: err.response,
    error: err,
  };
};

export const getAffiliateBranding = affiliationCode => {
  return get(`${API_CONSTANTS.BRANDING_URL}/${affiliationCode}`)
    .then(mapSuccess)
    .catch(mapError);
};
