import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../../../components/common/accordion';

const FAQ = ({accordion}) => {

    if(!(accordion || Object.keys(accordion).length)) {
        return null;
    }

    return (
        <div>
            <div className="chromosome-age__faq__container">
                <h4 className="indicator-section-title">{accordion.title}</h4>
                <Accordion items={accordion.data} />
            </div>
        </div>
    );
};

FAQ.propTypes = {
    accordion: PropTypes.object
};

export default FAQ;
