import React from 'react';
import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
  RadialBarChart,
  PolarAngleAxis,
  RadialBar,
} from 'recharts';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import Loader from '../../../components/common/loader';
import SplitConsumer from '../../../components/context/splitConsumer';

import ImageComponent from '../../../containers/you/common/imageMapping';
import PageTour from '../../../components/common/pageTour';
import DotGraph from './dotGraph';
import TestLineGraph from './testLineGraph';
import ReferenceLine from './referenceLine';
import { StyledSection } from '../../../theming/themed-components';
import { withTheme } from 'styled-components';

const futureHealth = [
  {
    name: 'Biological Age',
    slug: 'biological-age',
    icon_url: '/img/you/actionable-you/epi-indicator/biological-age.svg',
    result_text: '',
    your_result_graph: [
      {
        x: '1998-03-06',
        x_label: '1998',
        value: null,
        value_past: 0,
      },
      {
        x: '2008-06-01',
        x_label: '2008',
        value: null,
        value_past: 22,
      },
      {
        x: '2019-06-01',
        x_label: '2019',
        value: null,
        value_past: 25,
      },
      {
        x: '2019-06-01',
        x_label: '2019',
        value: 25,
        value_past: null,
      },
    ],
  },
  {
    name: 'Chromosome ageing',
    slug: 'chromosome-ageing',
    icon_url: '/img/you/actionable-you/epi-indicator/chromosome-ageing.png',
    result_text: 'Learn more',
  },
];

const compareResults = {
  name: 'Compare with others',
  link: 'you/compare',
  icons: [
    {
      url: '/img/you/smokers.png',
      name: 'Smoking',
      className: 'smoking',
    },
    {
      url: '/img/you/broccoli.png',
      name: 'Diet',
      className: 'diet',
    },
    {
      url: '/img/you/beer.png',
      name: 'Alcohol',
      className: 'alcohol',
    },
  ],
};

const healthPageSteps = [
  {
    content: ({ goTo }) => (
      <div className="page-tour">
        <div className="page-tour__container">
          <div className="page-tour__header">
            <div className="image-name-container">
              <div className="icon-container">
                <img alt="" src="/img/you/actionable-you/daniel-herranz.png" className="icon" />
              </div>
              <span>
                <strong>Daniel</strong> from Hurdle
              </span>
            </div>
          </div>
          <div className="page-tour__description-container">
            <h2 className="introduction">Welcome to your epigenetic insights</h2>
            <div>I’m going to show you how to get the most out of your results.</div>
          </div>
        </div>
        <div className="page-tour__footer">
          <div className="step-number">1 of 4</div>
          <button className="btn" onClick={() => goTo(1)}>
            Next
          </button>
        </div>
      </div>
    ),
    style: {
      width: 'auto',
      maxWidth: 'calc(100% - 20px)',
    },
  },
  {
    content: ({ goTo }) => (
      <div className="page-tour">
        <div className="page-tour__container">
          <div className="page-tour__header">
            <div className="image-name-container">
              <div className="icon-container">
                <img alt="" src="/img/you/actionable-you/daniel-herranz.png" className="icon" />
              </div>
              <span>
                <strong>Daniel</strong> from Hurdle
              </span>
            </div>
          </div>
          <div className="page-tour__description-container">
            Lifestyle and environmental factors affect the regulation of your DNA. We call this epigenetics. So, what you eat, how much you
            exercise, how often you are exposed to certain environmental toxins, and so on, changes your epigenetic information over time.
          </div>
          <div>
            <img alt="" src="/img/tour/lifestyle-and-environment-factors.png" className="page-tour__description-image" />
          </div>
        </div>
        <div className="page-tour__footer">
          <div className="step-number">2 of 4</div>
          <button className="btn" onClick={() => goTo(2)}>
            Next
          </button>
        </div>
      </div>
    ),
    style: {
      width: '550px',
      maxWidth: 'calc(100% - 20px)',
    },
  },
  {
    content: ({ goTo }) => (
      <div className="page-tour">
        <div className="page-tour__container">
          <div className="page-tour__header">
            <div className="image-name-container">
              <div className="icon-container">
                <img alt="" src="/img/you/actionable-you/daniel-herranz.png" className="icon" />
              </div>
              <span>
                <strong>Daniel</strong> from Hurdle
              </span>
            </div>
          </div>
          <div className="page-tour__description-container">
            Using your epigenetic data we measure the main risk factors that affect the development of most complex diseases, such as
            certain types of cancer, diabetes, cardiovascular disease or neurodegenerative diseases. We call these risk factors ‘epigenetic
            indicators’. Here are three of them.
          </div>
          <div>
            <img alt="" src="/img/tour/risk-factors.png" className="page-tour__description-image" />
          </div>
        </div>
        <div className="page-tour__footer">
          <div className="step-number">3 of 4</div>
          <button className="btn" onClick={() => goTo(3)}>
            Next
          </button>
        </div>
      </div>
    ),
    style: {
      width: '550px',
      maxWidth: 'calc(100% - 20px)',
    },
  },
  {
    content: ({ close }) => (
      <div className="page-tour">
        <div className="page-tour__container">
          <div className="page-tour__header">
            <div className="image-name-container">
              <div className="icon-container">
                <img alt="" src="/img/you/actionable-you/daniel-herranz.png" className="icon" />
              </div>
              <span>
                <strong>Daniel</strong> from Hurdle
              </span>
            </div>
          </div>
          <div className="page-tour__description-container last-step">Start here to see your biological age.</div>
        </div>
        <div className="last-step-button-container">
          <button className="btn" onClick={close}>
            Get Started
          </button>
        </div>
      </div>
    ),
    style: {
      width: '400px',
      maxWidth: 'calc(100% - 20px)',
    },
  },
];

const RenderDot = (props, theme) => {
  const { cx, cy, value, key, payload } = props;

  if (value === null || !payload.x_label) {
    return null;
  }

  return (
    <g key={key}>
      <circle cx={cx} cy={cy} r={8} stroke={theme.section.cta_colour} strokeWidth={3} fill="#FFFFFF" />
    </g>
  );
};

const RenderTooltip = props => {
  const { active, payload } = props;

  if (!(payload && payload[0] && payload[0].payload.tooltip && active)) {
    return null;
  }

  const status = payload[0].payload.tooltip.trending_direction;

  return (
    <div className="line-graph-tooltip">
      <div className={`main-tooltip ${status}`}>{payload[0].payload.tooltip.title}</div>
      <div>
        {status ? (
          <div>
            <span>Trending</span>
            <ImageComponent name={`${status}-arrow`} />
          </div>
        ) : (
          <span>No previous test</span>
        )}
      </div>
    </div>
  );
};

const CustomizedAreaLabel = props => {
  const { x, y, data, index } = props;

  if (index < data.length - 1) {
    return null;
  }

  const elem = document.querySelector('.recharts-curve.recharts-area-area');
  const measures = elem && elem.getBoundingClientRect();

  return (
    <Text
      className="previous-test-label"
      x={measures ? x - measures.width : x}
      y={measures ? measures.height / 2 : y}
      textAnchor="middle"
      verticalAnchor="start"
    >
      No data yet
    </Text>
  );
};

const YouPage = ({
  data,
  fetchResults,
  history,
  match: { url },
  selectedTest,
  isLoading,
  breakpoint,
  updateSelectedState,
  openChat,
  theme,
}) => {
  const setSelectedTest = test => {
    let params;
    updateSelectedState(test);
    fetchResults(test.test_id);
    if (test.test_id && test.test_id !== selectedTest.test_id) {
      params = qs.stringify({ test_user_test: test.test_id });
    }
    history.replace({ url, search: params });
  };

  const RenderTestTimeline = () => {
    if (!(data && data.tests_timeline)) {
      return null;
    }

    const { tests_timeline } = data;

    return <DotGraph fetchResults={setSelectedTest} selectedTest={selectedTest} tests_timeline={tests_timeline} />;
  };

  const ShowUnlockMessage = openChat => {
    openChat('I would like to upgrade and unlock all indicators');
  };

  const RenderLockedMessage = () => {
    return (
      <div className="overlay-text-container">
        <div className="overlay-text">
          <i className="icon-lock-filled" />
          <div>
            <button className="btn" onClick={() => ShowUnlockMessage(openChat)}>
              Unlock
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderYearsSection = props => {
    if (!(props && Object.keys(props).length)) {
      return null;
    }

    const { metadata_result, result_delta_text, result, result_status, healthy_ranges } = props;

    return (
      <div className="you-page-v3__years-section">
        <p className="year-title">{result}</p>
        <div className="you-page-v3__year-information">
          {result_delta_text && <p className={`result-delta-text ${result_status}`}>{result_delta_text}</p>}
          {metadata_result && (
            <p className="calendar-age">
              Calendar age: <span>{metadata_result}</span>
            </p>
          )}
          <ReferenceLine data={healthy_ranges && healthy_ranges.data} />
        </div>
      </div>
    );
  };

  const RenderTestGraph = ({
    your_result_graph: graphData,
    hideDot,
    metadata_result,
    result_delta_text,
    result,
    result_status,
    healthy_ranges,
  }) => {
    if (!graphData) {
      return null;
    }

    return (
      <div className="actionable-container">
        {RenderYearsSection({ metadata_result, result_delta_text, result, result_status, healthy_ranges })}
        <div className="you-page-v3__line-graph">
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart data={graphData}>
              <defs>
                <linearGradient id="area-gradient" x1="50%" y1="0" x2="50%" y2="100%">
                  <stop offset="0%" stopColor={theme.section.cta_colour} stopOpacity={0.2} />
                  <stop offset="100%" stopColor={theme.section.cta_colour} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="area-gradient-past" x1="50%" y1="0" x2="50%" y2="100%">
                  <stop offset="0%" stopColor="#EEEEEE" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                axisLine={false}
                tickline={false}
                dataKey="x_label"
                type="number"
                hide={true}
                domain={['dataMin', 'dataMax']}
                interval="preserveStartEnd"
              />
              <YAxis axisLine={false} tickline={false} dataKey="value" type="number" hide={true} domain={[0, 'dataMax + 5']} />
              <Area type="natural" dataKey="value_past" fill="url(#area-gradient-past)" stroke="none" />
              <Area type="natural" dataKey="value" fill="url(#area-gradient)" stroke="none" />
              <Line type="natural" dataKey="value_past" stroke="#A5A5A5" strokeWidth="3" dot={false} />

              <Area
                type="natural"
                dataKey="value_future"
                fill="url(#area-gradient-past)"
                stroke="none"
                label={<CustomizedAreaLabel data={graphData} />}
              />
              <Line type="natural" dataKey="value_future" stroke="#A5A5A5" strokeWidth="3" strokeDasharray="15 5" dot={false} />
              <Line
                activeDot={false}
                type="natural"
                dataKey="value"
                stroke={theme.section.cta_colour}
                strokeWidth="3"
                dot={hideDot ? false : props => RenderDot(props, theme)}
              />
              <Tooltip
                content={props => RenderTooltip(props)}
                cursor={false}
                wrapperStyle={{ boxShadow: '0 5px 10px 0 rgba(203,209,223,0.50)', backgroundColor: '#F3F3F3', borderRadius: '10px' }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const RenderLineGraph = healthyRanges => {
    if (!healthyRanges) {
      return null;
    }

    const { data } = healthyRanges;

    return <TestLineGraph data={data} breakpoint={breakpoint} />;
  };

  const RenderActionableSection = () => {
    let items = futureHealth;
    if (data && data.actionable && data.actionable.epigenetic_indicators && data.actionable.epigenetic_indicators.length) {
      items = data.actionable.epigenetic_indicators;
    }

    const showActionable = items && items.length;
    const showOverlay = selectedTest && (!selectedTest.test_id || selectedTest.status !== 'processed');

    return (
      <div className={`you__overlay v3-design ${showOverlay ? 'show' : ''}`}>
        <div className="you-page-v3__actionable-container">
          {showActionable
            ? items.map((item, index) => {
                const {
                  name,
                  result_status,
                  your_result_graph,
                  healthy_ranges,
                  slug,
                  result_text,
                  status,
                  metadata_result,
                  result_delta_text,
                  result,
                } = item;
                const pageLink = `${url}/indicator/${slug}`;
                if (showOverlay && index > 1) {
                  return null;
                }

                return (
                  <div
                    className={`you__overlay v3-design change-disabled-state ${status != 'viewable' && !showOverlay ? 'show' : ''}`}
                    key={item.slug + index}
                  >
                    <Link className={`you-page-v3__actionable-card ${!result_delta_text ? 'flex-card' : ''}`} to={pageLink}>
                      <div className="test-header">
                        <h5 className={`indicator-section-title inside-title ${result_status ? result_status : 'no-status'}`}>{name}</h5>
                        {!slug.includes('biological-age') ? (
                          <p className={`test-information ${result_status}`} data-private>
                            {result_text}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                      {RenderTestGraph({ your_result_graph, metadata_result, result_delta_text, result, result_status, healthy_ranges })}
                      {result_delta_text ? '' : RenderLineGraph(healthy_ranges)}
                    </Link>
                    {status == 'locked' && selectedTest && selectedTest.test_id ? RenderLockedMessage() : ''}
                  </div>
                );
              })
            : ''}
        </div>
        <div className="overlay-text-container">
          <div className="overlay-text">
            {selectedTest.status ? <i className="icon-lock-filled" /> : ''}
            <p className="overlay-title">{selectedTest.status ? 'Processing test' : 'No test results for this year'}</p>
          </div>
        </div>
      </div>
    );
  };

  const RenderGaugeChart = ({ data, icon_url, name }) => {
    let graphData = data;

    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');

    if (!data) {
      graphData = {
        percent: 100,
      };
    }

    return (
      <div className="you-page-v3__gauge-chart">
        <div className="gauge-image">
          <img src={icon_url} alt={name} />
        </div>
        <ResponsiveContainer>
          <RadialBarChart
            innerRadius={isMobile ? 42 : 52}
            outerRadius={isMobile ? 50 : 60}
            barSize={8}
            data={[graphData]}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
            <RadialBar
              background
              dataKey="percent"
              isAnimationActive={false}
              cornerRadius={graphData.percent == 100 ? 0 : 50}
              fill={theme.section.cta_colour}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const RenderTransposonsSection = () => {
    if (
      !(data && data.actionable && data.actionable.transposons) ||
      (data.actionable && data.actionable.status !== 'processed') ||
      !(selectedTest && selectedTest.test_id)
    ) {
      return null;
    }

    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');

    const { actionable } = data;

    const showTransposons = actionable.transposons && actionable.transposons.length;

    return (
      <div className="you__epigenetic-indicators">
        <div className="you__epigenetic-sections">
          {showTransposons &&
            actionable.transposons.map((item, index) => {
              const { title, split_name, link, name, icon_url, result_text, slug, status, data } = item;
              const pageLink = `${url}/${slug}`;

              if (split_name) {
                return (
                  <SplitConsumer name={split_name} key={index}>
                    {value =>
                      value == 'on' ? (
                        <div
                          className={`you__overlay epigenetic-section centered-section ${
                            (status != 'viewable' && !actionable.locked) || slug == '' ? 'show' : ''
                          }`}
                        >
                          <div className="you__epigenetic-indicator">
                            {link ? (
                              <Link className="you-item-link" to={link}>
                                {title}
                              </Link>
                            ) : (
                              <Link className="row-section" to={pageLink}>
                                <div className="title-section">
                                  <h3 className="title">{name}</h3>
                                </div>
                                <div className="gauge-chart-container">
                                  {RenderGaugeChart({ data, icon_url, name })}
                                  {data && data.text && data.text.length ? (
                                    <div className="rating-text-container">
                                      {isMobile ? (
                                        <div key={index} className="rating-text" dangerouslySetInnerHTML={{ __html: data.text[0] }} />
                                      ) : (
                                        data.text.map((text, index) => (
                                          <div key={index} className="rating-text" dangerouslySetInnerHTML={{ __html: text }} />
                                        ))
                                      )}
                                    </div>
                                  ) : (
                                    <div className="rating-text" dangerouslySetInnerHTML={{ __html: result_text }} />
                                  )}
                                </div>
                              </Link>
                            )}
                          </div>
                          {status == 'locked' ? RenderLockedMessage() : ''}
                        </div>
                      ) : (
                        ''
                      )
                    }
                  </SplitConsumer>
                );
              }

              return (
                <div
                  className={`you__overlay epigenetic-section centered-section ${
                    (status != 'viewable' && !actionable.locked) || slug == '' ? 'show' : ''
                  }`}
                  key={index}
                >
                  <div className="you__epigenetic-indicator">
                    {link ? (
                      <Link className="you-item-link" to={link}>
                        {title}
                      </Link>
                    ) : (
                      <Link className="row-section" to={pageLink}>
                        <div className="title-section">
                          <h3 className="title">{name}</h3>
                        </div>
                        <div className="gauge-chart-container">
                          {RenderGaugeChart({ data, icon_url, name })}
                          {data && data.text && data.text.length ? (
                            <div className="rating-text-container">
                              {isMobile ? (
                                <div key={index} className="rating-text" dangerouslySetInnerHTML={{ __html: data.text[0] }} />
                              ) : (
                                data.text.map((text, index) => (
                                  <div key={index} className="rating-text" dangerouslySetInnerHTML={{ __html: text }} />
                                ))
                              )}
                            </div>
                          ) : (
                            <div className="rating-text" dangerouslySetInnerHTML={{ __html: result_text }} />
                          )}
                        </div>
                      </Link>
                    )}
                  </div>
                  {status == 'locked' ? RenderLockedMessage() : ''}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const RenderCompareResultsSection = () => {
    if (
      !(data && data.actionable && data.actionable.compare) ||
      (data.actionable && data.actionable.status !== 'processed') ||
      !(selectedTest && selectedTest.test_id)
    ) {
      return null;
    }

    return (
      <SplitConsumer name="show_compare_feature">
        {value =>
          value == 'on' ? (
            <div className="you__epigenetic-indicators">
              <div className="you__overlay epigenetic-section compare-section">
                <div className="you__epigenetic-indicator">
                  <Link className="row-section compare-row" to={compareResults.link}>
                    <div className="title-section">
                      <h3 className="title">{compareResults.name}</h3>
                    </div>
                    <div className="compare-icons-container">
                      {compareResults.icons.map(icon => (
                        <div className="single-icon" key={icon.name}>
                          <div className={`icon-image ${icon.className}`}>
                            <img src={icon.url} alt={icon.name} />
                          </div>
                          <p className="icon-name">{icon.name}</p>
                        </div>
                      ))}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )
        }
      </SplitConsumer>
    );
  };

  const RenderAllSections = () => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="you-page-v3__sections">
        {RenderActionableSection()}
        {RenderTransposonsSection()}
        {RenderCompareResultsSection()}
      </div>
    );
  };

  return (
    <StyledSection className="you-page-v3__container">
      {window.location.toString().indexOf('?tour=1') != -1 ? <PageTour steps={healthPageSteps} /> : ''}
      <h2 className="page-title">Epigenetic Health</h2>
      {RenderTestTimeline()}
      {RenderAllSections()}
    </StyledSection>
  );
};

export default withTheme(YouPage);
