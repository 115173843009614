import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import axios from 'axios';

const getSuggestionValue = suggestion => suggestion.title;

const renderSuggestion = suggestion => (
    <div>{suggestion.title}</div>
);

class AutoSuggest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
        };

        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    onSuggestionsFetchRequested(value) {
        const { fetchUrl, responseKey } = this.props;
        if(value.value.length >= this.props.searchQueryMinimumLength) {
            axios.get(fetchUrl+value.value).then((response) => {
                const suggestions = responseKey ? response.data[responseKey] : response.data;
                this.setState({ suggestions: suggestions || [] });
            });
        }
    }

    onSuggestionsClearRequested() {
        this.setState({ suggestions: [] });
    }

    onSuggestionSelected(event, {suggestion, ...args}) {
        this.props.onSelectItem(suggestion);
        this.onSuggestionsClearRequested();
    }

    render() {
        const { value, onChange, placeholder, disabled } = this.props;
        const { suggestions } = this.state;
        const inputProps = {
            value: value,
            onChange: onChange,
            disabled: disabled,
            placeholder: placeholder
        };

        return (
            <div className="autosuggest-container">
                <Autosuggest
                    autofocus
                    getSuggestionValue={getSuggestionValue}
                    inputProps={inputProps}
                    onSuggestionSelected={this.onSuggestionSelected}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    renderSuggestion={renderSuggestion}
                    suggestions={suggestions}
                />
            </div>
        );
    }
}

AutoSuggest.defaultProps = {
    value: '',
    onChange: () => {},
    onSelectItem: () => {},
    searchQueryMinimumLength: 2,
    fetchUrl: '',
    disabled: false,
    placeholder: '',
};

AutoSuggest.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func,
    searchQueryMinimumLength: PropTypes.number,
    fetchUrl: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

export default AutoSuggest;
