import React from 'react';

const SmokeExposure = () => {
    return (
        <div>
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    {/*<h5 className="indicator-section-title">What is smoke exposure?</h5>*/}
                    <div className="biological-age__stress-section">
                        <div className="stress-container">
                            <div className="stress-information-section">
                                <h4 className="indicator-section-title inside-title">Types of smoke</h4>
                                <p className="indicator-section-description">Your smoke signature is mainly determined by exposure to cigarette smoke (whether active or passive). However, other types of smoke can contribute to your score, such as traffic pollution, factory emissions and even household emissions, such as cooking and barbeques.</p>
                                <br />
                                <p className="indicator-section-description">Some of the chemicals found in cigarette smoke and pollution are shared, including carbon monoxide (CO), nitrogen dioxide (NO<sub>2</sub>) and polycyclic aromatic hydrocarbons (PAHs). Epigenetic data cannot yet tell us where the exposure has come from. So, if you’ve never smoked but your smoke signature suggests you have, then air pollution could be responsible.</p>
                            </div>
                            <div className="stress-image">
                                <img src="/img/you/actionable-you/smoke-exposure/different-types-of-smoke.png" alt="Types of smoke exposure" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    {/*<h5 className="indicator-section-title">What is smoke exposure?</h5>*/}
                    <div className="biological-age__stress-section">
                        <div className="stress-container">
                            <div className="stress-information-section">
                                <h4 className="indicator-section-title inside-title">Calculating your score </h4>
                                <p className="indicator-section-description">The harmful chemicals found in cigarette smoke can lead 
                                to changes in your DNA that damage your cells. If the core DNA (genetic) sequence is affected, 
                                this is known as a mutation, which is irreversible. However, many other changes involve your DNA 
                                methylation (epigenetic) marks. These epigenetic changes are generally reversible with the right kind of 
                                lifestyle improvements, which shows that it’s never too late to stop smoking.
                                </p>
                                 <br />
                                <p className="indicator-section-description">To determine your smoke signature, we quantify the changes in your epigenome that are caused by exposure to these chemicals. The lower your exposure, the lower your score.</p>
                            </div>
                            <div className="stress-image">
                                <img src="/img/you/actionable-you/smoke-exposure/affect-on-genes.png" alt="Effect of smoke exposure on genes" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SmokeExposure;