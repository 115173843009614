import React, { Component } from 'react';
import {validated} from 'react-custom-validation';
import axios from 'axios';

import withErrorBoundary from '../common/errorBoundary';
import InputField from '../common/inputField';

import validationRules from '../helpers/validationRules';
import utils from '../helpers/utils';

class DemoLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            disableSubmitButton: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    onSubmit() {
        const { email } = this.state;
        this.setState({ disableSubmitButton: true });

        axios.post('auth/login', {email: 'demo@chronomics.com', password: 'Epigenetics2019!'})
            .then(() => {
                utils.track('Demo login', {email: email});
                this.setState({disableSubmitButton: false});
                window.location.href = '/dashboard';
            })
            .catch(() => {
                this.setState({disableSubmitButton: false});
            });
    }

    render() {
        const { email, disableSubmitButton } = this.state;
        return (
            <div className="registration__wrapper">
                <div className="registration__container">
                    <div className="register-form__container">
                        <h4 className="register-form__title">Demo Login</h4>
                        <DemoLoginForm
                            disableSubmitButton={disableSubmitButton}
                            email={email}
                            onChange={this.onChange}
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const Form = (props) => {
    const { onSubmit, email, $validation, onChange, $fieldEvent, disableSubmitButton } = props;
    return (
        <form
            className="register-form__input-fields"
            onSubmit={(e) => {
                e.preventDefault();
                props.$submit(onSubmit);
            }}
        >
            <div className="register-form__single-field">
                <InputField
                    error={$validation.email.show ? $validation.email.error.reason : ''}
                    label="Email Address"
                    name="email"
                    onBlur={() => $fieldEvent('blur', 'email')}
                    onChange={onChange}
                    showError={$validation.email.show}
                    type="email"
                    value={email}
                />
            </div>
            <div className="register-form__single-field">
                <button className="btn btn--full-width" disabled={disableSubmitButton} type="submit" >log in</button>
            </div>
        </form>
    );
};

const demoLoginValidationConfig = (props) => {
    const {email} = props;

    return {
        fields: ['email'],
        validations: {
            email: [
                [validationRules.isRequired, email],
                [validationRules.isEmail, email]
            ],
        },
    };
};

const DemoLoginForm = validated(demoLoginValidationConfig)(Form);

export default withErrorBoundary(DemoLogin);