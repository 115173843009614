import React, { Fragment } from 'react';
import Modal from 'react-responsive-modal';

import DataSharePreferences from './dataSharePreferencesCard';
import utils from '../../../components/helpers/utils';

const Card = (props) => {

    const { locked, coachCount, showModal, showDataPreference, toggleCoachKey, id, deleteCoach, has_selected,
        invitePeople, fname, lname, photo, occupation, brief_description, buttonText,openChat } = props;

    const lockCardClass = locked ? 'locked-card' : '';

    const handelClick = (openChat) => {
        if(invitePeople) {
            openChat('Leave their name, number and/or email and we will get in touch about joining the Hurdle platform');
            utils.track('Invite Coach');
        }
        else {
            has_selected ?
                openChat(`I would like to book a session with ${fname}`) : 
                toggleCoachKey(id, 'showDataPreference');
            utils.track('Book Coach Session', {coach_id: id});
        }
    };

    const getCard = () =>  {
        let ButtonText = buttonText ? buttonText : has_selected ? 'Book session' : 'Add to team';

        if (locked) {
            ButtonText = 'Awaiting results';
        }

        return (
            <Fragment>
                {locked ? <img className="locked" src="/img/dashboard/improvement/lock.png" alt="lock" /> : ''}
                {has_selected ?
                    <i
                        className="icon-cancel locked"
                        onClick={() => toggleCoachKey(id, 'showModal')}
                        title="Remove from team"
                    /> : ''
                }
                {photo ?
                    <div className="card__member-image"><img src={photo} alt={fname} /></div> :
                    <div className="card__member-image card__member-image--no-photo">?</div>
                }
                <p className="card__member-name">{fname} {lname}</p>
                {occupation ? <p className="card__occupation">{occupation}</p> : ''}
                <p className="card__description">{brief_description}</p>
                <button className="btn btn--pale-orange" onClick={() => handelClick(openChat)}>{ButtonText}</button>
                {has_selected ?
                    <div
                        className="card__edit-data-sharing-preferences"
                        onClick={() => toggleCoachKey(id, 'showDataPreference')}
                    >
                        Edit data sharing preferences
                    </div> : ''
                }
            </Fragment>
        );
    };

    return (
        <div className={`card ${lockCardClass} _${coachCount}${`${showDataPreference ? ' data-share-preferences' : ''}`}`}>
            {locked ? <div className="card__overlay"/> : ''}
            {showDataPreference ? <DataSharePreferences {...props} /> : getCard()}
            <Modal
                center
                classNames={{modal: 'improvement-container__modal', closeButton: 'close-button'}}
                onClose={() => toggleCoachKey(id, 'showModal')}
                open={!!showModal}
            >
                <h3 className="modal-header">Remove Coach?</h3>
                <p className="modal-description">Are you sure you want to remove this improvement provider?</p>
                <p className="modal-description">This will remove all access to your data so they can no longer provide personalised improvements.</p>
                <div className="modal-buttons-container">
                    <button className="btn btn--light-green-1" onClick={() => deleteCoach(id)}>confirm</button>
                    <button className="btn btn--red" onClick={() => toggleCoachKey(id, 'showModal')}>cancel</button>
                </div>
            </Modal>
        </div>
    );
};

Card.defaultProps = {
    accepted_terms: false,
};

export default Card;
