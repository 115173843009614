import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, Text } from 'recharts';

const DIVISIONS = {
    good: 100,
    normal: 66,
    bad: 33,
};

export const RATINGS_MAP = {
    good: { value: DIVISIONS.good, color: '#87C04C', label: 'Good' },
    normal: { value: DIVISIONS.normal, color: '#69ACE9', label: 'Normal' },
    bad: { value: DIVISIONS.bad, color: '#FFA800', label: 'Needs improvement' },
};

const X_AXIS_LABELS = {
    [DIVISIONS.good]: { label: RATINGS_MAP.good.label, color: RATINGS_MAP.good.color },
    [DIVISIONS.normal]: { label: RATINGS_MAP.normal.label, color: RATINGS_MAP.normal.color },
    [DIVISIONS.bad]: { label: RATINGS_MAP.bad.label, color: RATINGS_MAP.bad.color },
};

const X_AXIS_TICKS = [DIVISIONS.good, DIVISIONS.normal, DIVISIONS.bad];

const CustomizedXTick = (props) => {
    const { payload: { value }, isTinySmallDevice, ...otherProps} = props;
    const width = isTinySmallDevice ? { width: 100 } : {};
    const { color = '', label = '' } = X_AXIS_LABELS[value] || {};
    return <Text {...otherProps} {...width} style={{fill: color}}>{label}</Text>;
};

const CustomizeBarChart = ({data, domainAttr, isTinySmallDevice, yAxisDataKey, yTick, yAxisWidth}) => (
    <ResponsiveContainer>
        <BarChart
            data={data}
            layout="vertical"
        >
            <XAxis
                axisLine={true}
                domain={domainAttr}
                height={40}
                tick={<CustomizedXTick isTinySmallDevice={isTinySmallDevice}/>}
                ticks={X_AXIS_TICKS}
                type="number"
            />
            <YAxis
                dataKey={yAxisDataKey}
                stroke="#667284"
                tickLine={false}
                type="category"
                width={yAxisWidth}
                tick={yTick}
            />
            <Bar
                dataKey="value"
                fillOpacity="0.7"
            >
                {data.map(
                    (entry, index) => <Cell
                        fill={entry.color} key={index} radius={[0, 20, 20, 0]}
                                      />
                )}
            </Bar>
        </BarChart>
    </ResponsiveContainer>
);

CustomizeBarChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    domainAttr: PropTypes.array,
    isTinySmallDevice: PropTypes.bool,
    yAxisDataKey: PropTypes.string,
};

CustomizeBarChart.defaultProps = {
    data: [],
    domainAttr: [0, 0],
    isTinySmallDevice: false,
    yAxisDataKey: '',
    yTick: true,
    yAxisWidth: 70,
};

export default CustomizeBarChart;
