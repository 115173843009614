import * as actionTypes from './constants';

export const getIndicator = (slug, params) => {
  return {
    type: actionTypes.FETCH_INDICATOR,
    params,
    slug,
  };
};

export const updateIndicator = results => {
  return {
    type: actionTypes.FETCH_INDICATOR_SUCCESS,
    indicator: results,
  };
};

export const updateIndicatorFailure = error => {
  return {
    type: actionTypes.FETCH_INDICATOR_FAILURE,
    error,
  };
};

export const updateIndicatorLinks = links => {
  return {
    type: actionTypes.FETCH_INDICATOR_LINKS,
    indicatorLinks: links,
  };
};

export const updateChromosomeAge = results => {
  return {
    type: actionTypes.FETCH_CHROMOSOME_AGE,
    results,
  };
};

export const saveChromosomeValue = value => {
  return {
    type: actionTypes.SAVE_CHROMOSOME_VALUE,
    value: value,
  };
};

export const updateChromosomeValue = value => {
  return {
    type: actionTypes.FETCH_CHROMOSOME_VALUE,
    value,
  };
};

export const getCompareResults = () => {
  return {
    type: actionTypes.FETCH_COMPARE,
  };
};

export const updateCompareSuccess = results => {
  return {
    type: actionTypes.FETCH_COMPARE_SUCCESS,
    results,
  };
};

export const updateCompareFailure = error => {
  return {
    type: actionTypes.FETCH_COMPARE_FAILURE,
    error,
  };
};

export const getComparison = (section, id) => {
  return {
    type: actionTypes.FETCH_COMPARISON,
    section,
    id,
  };
};

export const setComparisonLoading = value => {
  return {
    type: actionTypes.FETCH_COMPARISON_LOADING,
    value,
  };
};

export const updateComparisonSuccess = (results, section, id) => {
  return {
    type: actionTypes.FETCH_COMPARISON_SUCCESS,
    results,
    section,
    id,
  };
};

export const getTransposons = slug => {
  return {
    type: actionTypes.FETCH_TRANSPOSONS,
    slug,
  };
};

export const updateTransposonsFailure = error => {
  return {
    type: actionTypes.FETCH_TRANSPOSONS_FAILURE,
    error,
  };
};

export const updateTransposonsSuccess = result => {
  return {
    type: actionTypes.FETCH_TRANSPOSONS_SUCCESS,
    result,
  };
};

export const setTransposonsLoading = value => {
  return {
    type: actionTypes.FETCH_TRANSPOSONS_LOADING,
    value,
  };
};

export const setTransposonsTooltip = slug => {
  return {
    type: actionTypes.SET_TRANSPOSONS_TOOLTIP,
    slug,
  };
};

export const updateTransposonsTooltips = tooltips => {
  return {
    type: actionTypes.UPDATE_TRANSPOSONS_TOOLTIP,
    tooltips,
  };
};

export const getYou = (testId, callAPI = true) => {
  return {
    type: actionTypes.FETCH_YOU,
    testId,
    callAPI,
  };
};

export const updateYouSuccess = results => {
  return {
    type: actionTypes.FETCH_YOU_SUCCESS,
    results,
  };
};

export const updateYouFailure = error => {
  return {
    type: actionTypes.FETCH_YOU_FAILURE,
    error,
  };
};

export const setYouLoading = value => {
  return {
    type: actionTypes.FETCH_YOU_LOADING,
    value,
  };
};

export const getUserDetails = (callAPI = true) => {
  return {
    type: actionTypes.FETCH_USER_DETAILS,
    callAPI,
  };
};

export const updateUserDetailsFailure = error => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_FAILURE,
    error,
  };
};

export const updateUserDetailsSucess = results => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_SUCCESS,
    results,
  };
};

export const updateUserDetailsLoading = value => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_LOADING,
    value,
  };
};

export const getCovidResults = (callAPI = true) => {
  return {
    type: actionTypes.FETCH_COVID_RESULTS,
    callAPI,
  };
};

export const updateCovidResults = covidData => {
  return {
    type: actionTypes.FETCH_COVID_RESULTS_SUCCESS,
    covidData,
  };
};

export const updateCovidResultsFailure = error => {
  return {
    type: actionTypes.FETCH_COVID_RESULTS_FAILURE,
    error,
  };
};

export const setCovidResultsLoading = value => {
  return {
    type: actionTypes.FETCH_COVID_RESULTS_LOADING,
    value,
  };
};

// Blood results
export const fetchBloodResults = testUserId => ({
  type: actionTypes.FETCH_BLOOD_RESULTS,
  testUserId,
});

export const fetchBloodResultsFailure = error => ({
  type: actionTypes.FETCH_BLOOD_RESULTS_FAILURE,
  error,
});

export const setBloodResults = data => ({
  type: actionTypes.SET_BLOOD_RESULTS,
  data,
});

export const setBloodResultsLoading = predicate => ({
  type: actionTypes.SET_BLOOD_RESULTS_LOADING,
  predicate,
});

export const getOrders = (callAPI = true) => {
  return {
    type: actionTypes.FETCH_ORDERS,
    callAPI,
  };
};

export const updateOrdersFailure = error => {
  return {
    type: actionTypes.FETCH_ORDERS_FAILURE,
    error,
  };
};

export const updateOrders = orders => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders,
  };
};

export const setOrdersLoading = value => {
  return {
    type: actionTypes.FETCH_ORDERS_LOADING,
    value,
  };
};

export const setSelectedTest = test => {
  return {
    type: actionTypes.SET_SELECTED_TEST,
    test,
  };
};

export const getCovidResultRequest = testId => {
  return {
    type: actionTypes.FETCH_COVID_RESULT,
    testId,
  };
};

export const getCovidResultSuccess = testResult => {
  return {
    type: actionTypes.FETCH_COVID_SUCCESS,
    testResult,
  };
};

export const getCovidResultFailure = error => {
  return {
    type: actionTypes.FETCH_COVID_FAILURE,
    error,
  };
};
