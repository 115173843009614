import PropTypes from 'prop-types';
import { getInterpretation, getRangesFromBiomarkerResult } from './interpretationRule';
import ResultDelta from '../../you/indicator/components/resultDelta';
import DiseaseRiskBar from './diseaseRiskBar';
import content from './content.json';
import AgeAffectors from './ageAffectors';
import DiseaseRisk from './diseaseRisk';
import ResultHero from './resultHero';

/**
 * Returns the Delta Age value from a Biomarker Result object.
 *
 * Corrected Delta Ages take priority; if not present, the Delta Age is
 * calculated using the Chronological Age and the Test Result value.
 *
 * @param {object} biomarkerResult
 * @returns number
 */
const getDeltaAgeResult = biomarkerResult => {
  const { resultMetadata, result } = biomarkerResult;

  const deltaAgeCorrected = resultMetadata?.deltaAgeCorrected;
  if (deltaAgeCorrected) {
    return deltaAgeCorrected;
  }

  const chronologicalAge = resultMetadata?.chronologicalAge;
  if (chronologicalAge) {
    return result - Number(chronologicalAge);
  }

  return null;
};

/**
 * Adjusts the Delta Age value to a display-able format, by rounding up
 * the value to the nearest integer.
 *
 * @param {number} age
 * @returns number
 */
const adjustAge = age => Math.round(age);

const Intro = ({ description, text, iconUrl }) => (
  <>
    <p className="result-hero__description biological-description">{description}</p>

    <div className="result-hero__intro">
      <div className="result-hero-image">
        <img alt="result-intro" src={iconUrl} />
      </div>
      <p className="result-intro-description">{text}</p>
    </div>
  </>
);

const BiomarkerResult = ({ fname, collectionDate, biomarkerResult, testMetadata }) => {
  const { interpretation, result } = biomarkerResult;
  const ranges = getRangesFromBiomarkerResult(collectionDate, biomarkerResult, testMetadata);
  const deltaAge = getDeltaAgeResult(biomarkerResult);

  const adjustedBioAge = adjustAge(result);
  const adjustedDeltaAge = adjustAge(deltaAge);
  const biomarkerContent = content[biomarkerResult.biomarker.code];

  return (
    <>
      <Intro
        description={biomarkerContent.intro.description}
        text={biomarkerContent.intro.text.replace('$NAME', fname)}
        iconUrl={biomarkerContent.intro.iconUrl}
      />

      <ResultDelta result={adjustedBioAge} resultDelta={adjustedDeltaAge} resultUnit={biomarkerContent.resultDelta.unit} />

      <ResultHero
        adjustedDeltaAge={adjustedDeltaAge}
        interpretation={getInterpretation(interpretation, deltaAge, ranges.value)}
        statements={biomarkerContent.resultHero.statements}
      />

      <DiseaseRiskBar
        title={biomarkerContent.diseaseRiskBar.title}
        description={biomarkerContent.diseaseRiskBar.description}
        labels={biomarkerContent.diseaseRiskBar.labels}
        referenceLabel={biomarkerContent.diseaseRiskBar.referenceLabel}
        interpretations={biomarkerContent.diseaseRiskBar.bioAgeInterpretations}
        ranges={ranges}
        adjustedDeltaAge={adjustedDeltaAge}
        deltaAge={deltaAge}
      />

      <AgeAffectors
        title={biomarkerContent.ageAffectors.title}
        text={biomarkerContent.ageAffectors.text}
        affectors={biomarkerContent.ageAffectors.affectors}
        factors={biomarkerContent.ageAffectors.factors}
        twinSection={biomarkerContent.ageAffectors.twinSection}
        calculate={biomarkerContent.ageAffectors.calculate}
      />

      <DiseaseRisk
        title={biomarkerContent.diseaseRisk.title}
        text={biomarkerContent.diseaseRisk.text}
        diseases={biomarkerContent.diseaseRisk.diseases}
      />
    </>
  );
};

BiomarkerResult.propTypes = {
  fname: PropTypes.string.isRequired,
  collectionDate: PropTypes.string.isRequired,
  biomarkerResult: PropTypes.object.isRequired,
  testMetadata: PropTypes.object.isRequired,
};

export default BiomarkerResult;
