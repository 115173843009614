import React, { Component } from 'react';
import {Tooltip} from 'react-tippy';

export default class Label extends Component {
    render() {
        const {helpText, id, title, isRequired, onClick} = this.props;

        return (
            <label htmlFor={id} className="form__label questionnaire-form__title-label" onClick={() => onClick && onClick()}>
                {title}
                {helpText && 
                    <Tooltip title={helpText} position="bottom">
                        <i className="icon-info-circled help-icon form__help-icon"/>
                    </Tooltip>
                }
            </label>
        );
    }
}
