import { Redirect, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { authenticatePartnerUser } from '../../services/partnerUserAuthentication/api';
import { saveAccessTokenAndStoreConfig } from './getAccessToken';
import Loader from '../common/loader';
import { datadogRum } from '@datadog/browser-rum';
import utils from '../helpers/utils';
import qs from 'query-string';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';

export const HomeRouter = () => {
  const location = useLocation();
  const [isAuthenticationLoading, setIsAuthenticationLoading] = useState(true);
  const [hasAuthenticationError, setHasAuthenticationError] = useState(false);
  const { publicToken } = qs.parse(location.search);

  const redirectToDashboard = () => (window.location.href = '/dashboard');

  // Partner users are authenticated, and then redirected to dashboard using same approach as normal login
  useEffect(() => {
    if (!publicToken) return;

    const authenticate = async token => {
      return await authenticatePartnerUser(token);
    };

    authenticate(publicToken)
      .then(({ accessToken }) => {
        if (accessToken) {
          saveAccessTokenAndStoreConfig({ accessToken, onSuccess: redirectToDashboard });
        }
      })
      .catch(error => {
        datadogRum.addError(error);
        setHasAuthenticationError(true);
      })
      .finally(() => setIsAuthenticationLoading(false));
  }, []);

  // A logged in normal user
  if (utils.userIsLoggedIn()) return <Redirect to="/dashboard" />;

  // A logged out normal user
  if (!publicToken) return <Redirect to={`/login${getPartnerQueryParams()}`} />;

  if (isAuthenticationLoading) return <Loader />;
  if (hasAuthenticationError) return <p>An error occurred</p>;

  // Empty screen will be displayed for a partner user that does not exist, or is not linked to the partner
  // In this case we want to show nothing, since the dashboard is loaded in an iframe
  return <div data-testid="NoUser"></div>;
};
