import PropTypes from 'prop-types';
import { getRanges } from './utils';
import { formatInterpretation } from '../utils';

const NormalDetails = ({ interpretationData, unit }) => {
  const ranges = getRanges(interpretationData);

  return (
    <>
      <div className="biomarker-modal__text">Normal range:</div>
      {ranges.map(([key, [min, max]]) => {
        const formattedInterpretation = formatInterpretation(key);

        return (
          <div key={key} className="biomarker-modal__text">
            {formattedInterpretation}: {min}-{max} {unit}
          </div>
        );
      })}
    </>
  );
};

NormalDetails.propTypes = {
  interpretationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  unit: PropTypes.string,
};

export default NormalDetails;
