import React from 'react';
import PropTypes from 'prop-types';
import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';

import { Link, useHistory, useLocation } from 'react-router-dom';

import config from '../../../config';
import utils from '../../helpers/utils';
import { Menu } from '../menu';
import { MenuDropdown } from '../menuDropdown';
import Logo from '../logo';

import Search from '../search-box';
import { getLinkUrl, getLogoAbsoluteUrl } from './header-links';
import { StyledHeader, StyledNav } from './header.styles';
import { connect } from 'react-redux';
import { brandingSelector } from '../../../services/branding/selectors';
import { shouldDisplayHeaderLogo } from '../../auth';
import { cachedPartnerTheming, getPartnerQueryParams } from '@chronomics/chronomics-registration';
import { BREAKPOINT_NAMES } from '../../hooks/useBreakpoint';
import { useDisplayControl } from '../../hooks/useDisplayControl';
import { shouldDisplayFeature } from '../../../services/partner-config/partner-config';
import { FEATURES } from '../../../services/partner-config/features';

const getNavClass = isBranded => {
  const isPartner = !!cachedPartnerTheming();
  return ['header__container', isBranded && !isPartner && 'affiliate-header', isPartner && 'partner-header'].filter(Boolean).join(' ');
};

const deleteLocalStorageInformationAndNavigate = ({ history }) => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('userMetadata');
  sessionStorage.clear();
  history.replace(`/login${getPartnerQueryParams()}`);
};

export const WG_TEST_STATUS = 'processed';

const Header = ({ theme, loggedInUser, breakpoint, branding }) => {
  const location = useLocation();
  const history = useHistory();
  const { shouldHideLogo } = useDisplayControl({ isLoggedIn: loggedInUser ?? false });
  const isBranded = branding?.hasBranding || cachedPartnerTheming();

  const { has_results = {}, wg_test } = config.app;
  const isCheckoutPage = location.pathname.includes('/join/');

  const isMobile = ![BREAKPOINT_NAMES.LARGE, BREAKPOINT_NAMES.MEDIUM, BREAKPOINT_NAMES.SMALL].includes(breakpoint);
  const shouldDisplayLinks = loggedInUser && !isCheckoutPage;
  const shouldDisplaySearchBox = has_results && wg_test?.status?.toLowerCase() === WG_TEST_STATUS;
  const shouldShowLogo = shouldDisplayHeaderLogo() && !shouldHideLogo;
  const shouldDisplayCovidLink = shouldDisplayFeature(FEATURES.COVID);

  const handleLogout = e => {
    e.preventDefault();
    const analytics = window.analytics;
    if (analytics) {
      analytics.reset();
    }
    utils.track('Logout');

    axios
      .post('auth/logout_token', null, { timeout: 2000 })
      .catch(error => {
        datadogRum.addError(error);
      })
      .finally(() => deleteLocalStorageInformationAndNavigate({ history }));
  };
  return (
    <section>
      <StyledHeader className={loggedInUser ? 'header header--dashboard' : 'header'} theme={theme}>
        <StyledNav loggedInUser={loggedInUser} isMobile={isMobile} theme={theme} className={getNavClass(isBranded)}>
          <div className="header__container--logo">
            {shouldShowLogo && (
              <Logo
                linkUrl={getLinkUrl(loggedInUser, branding)}
                absoluteUrl={getLogoAbsoluteUrl(loggedInUser, branding, isBranded)}
                logoUrl={theme.header.logo_url}
                altText={isBranded ? '' : 'Hurdle'}
                cssClass={isBranded ? undefined : 'logo'}
              />
            )}
            {shouldDisplayLinks && shouldDisplayCovidLink && !isMobile && (
              <Link to="dashboard/covid" data-testid="covid-link" className="menu-covid">
                Covid &amp; Immunity
              </Link>
            )}
          </div>
          {/* Checking if is either partner branding or no branding, do not display for affiliates */}
          {(!isCheckoutPage || !branding?.isAffiliate) && shouldDisplayLinks && (
            <Menu theme={theme}>
              {shouldDisplaySearchBox ? (
                <li className="item">
                  <Search
                    title={
                      <a className="nav-search" href="#">
                        <i className="icon-search icon header-search-icon" />
                      </a>
                    }
                  />
                </li>
              ) : (
                ''
              )}
              {loggedInUser && <MenuDropdown handleLogout={handleLogout} isMobile={isMobile} theme={theme} />}
            </Menu>
          )}
        </StyledNav>
      </StyledHeader>
    </section>
  );
};

Header.propTypes = {
  loggedInUser: PropTypes.shape({
    id: PropTypes.number,
    mobile_country: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    accepted_terms: PropTypes.number,
    accepted_privacy: PropTypes.number,
    created_at: PropTypes.string,
    accepted_dna_services: PropTypes.bool,
    $dnaServicesType: PropTypes.string,
  }),
  breakpoint: PropTypes.oneOf(Object.values(BREAKPOINT_NAMES)).isRequired,
  theme: PropTypes.object.isRequired,
  branding: PropTypes.object,
};

export { Header as __TEST_HEADER };

const mapStateToProps = state => {
  const branding = brandingSelector(state);
  return { branding };
};

export default connect(mapStateToProps)(Header);
