import axios from 'axios';
import React, { useState } from 'react';
import moment from 'moment';
import { StyledButton } from '../../../../theming/themed-components';

export const TestStep02 = ({ history, testType, flightType, onAvailability, onFlightDate }) => {
  const [valid, setValid] = useState(false);
  const [dateError, setDateError] = useState();
  const [labAvailable, setLabAvailable] = useState();
  const [fields, setFields] = useState({
      departure_day: '',
      departure_month: '',
      departure_year: '',
      no_flight: false,
  });

  const onChange = (field, value) => {
    const newFields = {...fields, [field]: value };
    setFields(newFields);
    validateTestAvailability(newFields, false);
  };

  const handleBack = () => history.goBack();
  const handleNext = () => {
      if (fields.no_flight && testType !== 'fit-to-fly') {
        return history.push('/join/account?slug=covid-19-ttr&utm_source=chronomics&utm_medium=app&utm_campaign=Testing%20Timeline');
      } else if (fields.no_flight && testType === 'fit-to-fly') {
        return history.push('/join/account?slug=covid-19&utm_source=chronomics&utm_medium=app&utm_campaign=Testing%20Timeline');
      }
      history.push(`/dashboard/covid/test-timeline/${testType}/3`);
  };
  const validateTestAvailability = ({ departure_day, departure_month, departure_year }, validateOnChange = true) => {
    setValid(false);
    setDateError(undefined);
    setLabAvailable(undefined);
    if (departure_day.length >= 1 && departure_month.length >= 1 && departure_year.length === 4) {
      const departureDate = moment(`${departure_year}-${departure_month}-${departure_day}`,
        ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-M-DD', 'YYYY-MM-D']);

      if (!departureDate.isValid()) {
          return setDateError('* Date you have provided is not valid');
      }

      onFlightDate(departureDate.format('DD/MM/YYYY'));

      const urlParams = testType === 'test-to-release' ? `covid-19-ttr&date_of_departure=${departureDate.format('YYYY-MM-DD')}` : 'covid-19';
      axios.get(`products/shipping_dispatch_date?slug=${urlParams}`).then(response => {
        if (response.data.status === 'no_slots_available') {
              setLabAvailable('Unfortunately, we have no availability for your given travel date.');
          } else if (response.data.status === 'found') {
              setValid(true);
              onAvailability(response.data.available_date);
              setLabAvailable(`Earliest test dispatch: ${moment(response.data.available_date, 'YYYY-MM-DD').format('Do MMMM YYYY')}. In accordance with government regulation tests must not be received until day 5 of quarantine.`);
          } else {
              setLabAvailable(undefined);
          }
      });
    } else if (validateOnChange && departure_day.length >= 1 && departure_month.length >= 1 && departure_year.length >= 1) {
        setDateError('* Date you have provided is not valid');
    }
  };

  const restrictNumericInput = (evt) => {
    if (evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  };

  const restrictCharLength = (value, existing, max) => {
    if (value.length > max) {
        return existing;
    }
    else return value;
  };

  return (
    <section className="test-dashboard-section content-wrapper-animate">
        <div className="test-dashboard-section__section_content centered">
            <img className="back-button" src="/img/back.svg" alt="" onClick={handleBack} />
            
            <div className="test-dashboard-section__container">
                <div className="content-container">
                <div>
                    <div className="section-title">
                        Testing timeline calculator
                    </div>
                    <div className="section-sub-title">
                        Your {flightType} flight
                    </div>

                    <div className="form__row form__row--2col">
                        <div className="form__col40">
                            <div className="form__row form__row--group">
                                <div className="input-label-container">
                                {fields.departure_day.length > 0 ? <label>DD</label> : <div className="input-label-space" />}
                                <input
                                    type="number"
                                    value={fields.departure_day}
                                    name="departure_day"
                                    placeholder="DD"
                                    maxLength={2}
                                    id={fields.departure_day}
                                    onKeyPress={restrictNumericInput}
                                    onChange={(e) => onChange('departure_day', restrictCharLength(e.target.value, fields.departure_day, 2))}
                                    onBlur={() => validateTestAvailability(fields, true)}
                                />
                                </div>
                                <div className="input-label-container">
                                {fields.departure_month.length > 0 ? <label>MM</label> : <div className="input-label-space" />}
                                <input
                                    type="number"
                                    value={fields.departure_month}
                                    name="departure_month"
                                    placeholder="MM"
                                    maxLength={2}
                                    id={fields.departure_month}
                                    onKeyPress={restrictNumericInput}
                                    onChange={(e) => onChange('departure_month', restrictCharLength(e.target.value, fields.departure_month, 2))}
                                    onBlur={() => validateTestAvailability(fields, true)}
                                />
                                </div>
                                <div className="input-label-container">
                                {fields.departure_year.length > 0 ? <label>YYYY</label> : <div className="input-label-space" />}
                                <input
                                    type="number"
                                    value={fields.departure_year}
                                    name="departure_year"
                                    placeholder="YYYY"
                                    maxLength={4}
                                    id={fields.departure_year}
                                    onKeyPress={restrictNumericInput}
                                    onChange={(e) => onChange('departure_year', restrictCharLength(e.target.value, fields.departure_year, 4))}
                                    onBlur={() => validateTestAvailability(fields, true)}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="error-container">
                        {dateError ? <label>{dateError}</label> : null}
                    </div>

                    <div className="checkbox" title={`I don’t know my ${flightType} flight yet`}>
                        <input type="checkbox" name="no-flight" onChange={e => onChange('no_flight', e.target.checked)} value={fields.no_flight} />
                        <label htmlFor="no-flight">
                            I don’t know my {flightType} flight yet
                        </label>
                    </div>

                    {labAvailable ? (
                        <div className="lab-results">
                            {labAvailable}
                        </div>
                    ) : null}
                </div>
                <div className="button-container">
                    <div className="small-back" onClick={handleBack}>
                        <img src="/img/small-back.svg" alt="" />
                        <div>
                            Back
                        </div>
                    </div>
                    <StyledButton
                        className="btn cancel"
                        disabled={!valid && !fields.no_flight}
                        onClick={handleNext}
                        type="button"
                    >
                        Next
                    </StyledButton>
                </div>
                </div>
            </div>
        </div>
    </section>
  );
};
