import React, { Component } from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';

const RenderActiveShape = (props, breakpoint) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, name } = props;

    const isMobile = breakpoint == 'tinySmall' || breakpoint == 'tiny';

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const percentText = Math.round(percent*100)+'%';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            {
                isMobile ?
                    <foreignObject width="100%" height="80px">
                        <div className="pie-slice-label">
                            <span>{percentText}</span> - <span>{name}</span>
                        </div>
                    </foreignObject> :
                    <g>
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" className="section-active-shape" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" className="section-active-shape" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" className="section-active-shape">
                            {name}
                        </text>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{percentText}</text>
                    </g>
            }
        </g>
    );
};

class PositionsBreakdown extends Component {
    constructor() {
        super();

        this.state = {
            activeIndex: 0
        };

        this.onPieEnter = this.onPieEnter.bind(this);
    }

    onPieEnter(data, index) {
        this.setState({
            activeIndex: index,
        });
    }

    render() {
        const { positionsGraph, breakpoint } = this.props;
        if (!(positionsGraph && positionsGraph.data)) {
            return null;
        }

        const {title, text, data, label, analysis_text} = positionsGraph;

        const colors = ['#5B7BFF', '#5B7BFF', '#5B7BFF', '#5B7BFF', '#5B7BFF'];

        return (
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    <div className="biological-age__pie-chart-section">
                        {title && <h4 className="indicator-section-title">{title}</h4>}
                        {text && <p className="indicator-section-description">{text}</p>}
                        <div className="pie-chart-container">
                            <div className="pie-label">
                                {label}
                            </div>
                            <ResponsiveContainer height="100%" width="100%">
                                <PieChart>
                                    <Pie
                                        activeIndex={this.state.activeIndex}
                                        activeShape={(props) => RenderActiveShape(props, breakpoint)}
                                        data={data}
                                        dataKey="value"
                                        fill="#8884d8"
                                        innerRadius={75}
                                        labelLine={false}
                                        onClick={this.onPieEnter}
                                        onMouseEnter={this.onPieEnter}
                                        outerRadius={90}
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={index} fill={colors[index]} opacity={0.4}/>
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        {analysis_text &&
                            <div className="analysis-text analysis-text--wide">
                                <div className="comparison__analysis-section">
                                    <div className="analysis-image">
                                        <img alt="analysis-placeholder" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                                    </div>
                                    <div className="comparison-analysis-text">{analysis_text}</div>
                                </div>
                            </div> 
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default PositionsBreakdown;