import React, { Component } from 'react';
import { StyledLink } from '../../theming/themed-components';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';

import withErrorBoundary from './errorBoundary';

class ErrorPage extends Component {
  render() {
    const { user } = this.props;

    return (
      <section className="dashboard-section content-wrapper-animate">
        <section className="dashboard-hero">
          <div className="dashboard-hero__text-container">
            <h3 className="dashboard-hero__title">Oops...page not found</h3>

            <p>
              {user ? (
                <StyledLink to="/dashboard" className="btn">
                  Return to dashboard
                </StyledLink>
              ) : (
                <StyledLink to={`/login${getPartnerQueryParams()}`} className="btn">
                  Return to homepage
                </StyledLink>
              )}
            </p>
          </div>
        </section>
      </section>
    );
  }
}

export default withErrorBoundary(ErrorPage);
