import { cachedPartnerTheming, getPartnerQueryParams } from '@chronomics/chronomics-registration';

export const appendQueryStringForPartnerUsers = (currentLocation, history) => {
  setQueryStringInitially(currentLocation, history);

  history.listen(location => {
    if (isInitPartnerRoute(location) || hasPartnerCodeInUrl(location)) {
      return;
    }

    if (cachedPartnerTheming()) {
      pushNewRoute(history, location);
    }
  });
};

const setQueryStringInitially = (currentLocation, history) => {
  if (cachedPartnerTheming() && !hasPartnerCodeInUrl(currentLocation) && !isInitPartnerRoute(currentLocation)) {
    pushNewRoute(history, currentLocation);
  }
};

export const isInitPartnerRoute = location => {
  return location.pathname.startsWith('/login') || location.pathname === '/register' || location.pathname.startsWith('/register?');
};

export const hasPartnerCodeInUrl = location => {
  return location.search.includes('partnerCode') || location.pathname.includes('partnerCode');
};

const pushNewRoute = (history, location) => {
  // form new query string with partner code
  history.replace({
    pathname: location.pathname,
    search: getPartnerQueryParams(),
  });
};
