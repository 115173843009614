import React from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';

const RenderArrow = ({ previousTest, resultDelta }) => {
  if (!previousTest || !previousTest.difference) {
    return null;
  }

  const { difference, difference_text, previous_result_text } = previousTest;

  const iconClass = difference === 'increased' ? 'up-arrow' : difference === 'decreased' ? 'down-arrow' : 'right-arrow';
  const htmlContent = (
    <div className="previous-test-tooltip">
      <p>{difference_text}</p>
      <p>{previous_result_text}</p>
    </div>
  );

  const children = <i className={`icon-right-arrow ${iconClass} ${!resultDelta ? 'previous-icon' : ''}`} />;

  return (
    <div className={`result-hero__arrow-section ${!resultDelta ? 'previous-section' : ''}`}>
      <Tooltip arrow={true} arrowSize="small" html={htmlContent} position="top" theme="light">
        {children}
      </Tooltip>
    </div>
  );
};

const ResultDelta = ({ resultDelta, result, resultUnit, previousTest }) => {
  return (
    <div className={`result-hero__result-container ${!resultDelta ? 'previous-results' : ''}`}>
      <div data-tut="health-indicator" className={`result-hero__result-section ${!resultDelta ? 'add-margin' : ''}`}>
        <h4 className="result-title">{result}</h4>
        <p className="result-unit">{resultUnit}</p>
      </div>
      {RenderArrow({ previousTest, resultDelta })}
    </div>
  );
};

ResultDelta.propTypes = {
  resultDelta: PropTypes.any,
  result: PropTypes.number,
  resultUnit: PropTypes.string,
  previousTest: PropTypes.object,
};

export default ResultDelta;
