import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import utils from '../helpers/utils';
import Loader from '../common/loader';
import { withRouter } from 'react-router-dom';
import withErrorBoundary from '../common/errorBoundary';

class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionnaires: [],
        };
    }

    componentDidMount() {
        const queryedString = queryString.stringify({ section: 'both' });
        axios.get(`faqs?${queryedString}`).then((response = {}) => {
            this.setState({
                questionnaires: response.data
            }, () => {
                const { categorySlug, faqSlug } = this.props;
                const elementId = faqSlug || categorySlug;
                const element = document.getElementById(elementId);

                if (element) {
                    utils.scrollToHash(element);
                }
            });
        }).catch((err) => {
            this.setState(() => { throw err; });
        });
    }

    getQuestions(questions = [], categorySlug) {
        const { history, faqSlug } = this.props;
        return questions.map(
            question => <div className="question-section" id={question.slug} key={question.slug}>
                <header onClick={() => history.push(`/dashboard/faqs/${categorySlug}/${question.slug}`)}>
                    <p className="question-item">{question.title}</p>
                    <i className={question.slug === faqSlug ? 'icon-up-open' : 'icon-down-open'} />
                </header>
                <div className={`answer ${question.slug === faqSlug ? 'active' : ''} `}>
                    <p>{question.answer}</p>
                </div>

                        </div>
        );
    }

    getQuestionnaires() {
        const { questionnaires = [] } = this.state;

        return questionnaires.map(question => (
            <div className="questionnaire-container" id={question.slug} key={question.slug}>
                <header className="questionnaire-container__title">
                    <p>{question.title}</p>
                </header>
                {this.getQuestions(question.questions, question.slug)}
            </div>
        ));
    }

    render() {
        if (!this.state.questionnaires.length) {
            return <Loader />;
        }

        return (
            <div className="faq-page">
                <div className="faq-page__title-container">
                    <h2 className="faq-page__title-container__header">FAQs</h2>
                    {/*<p className="faq-page__title-container__description">
                        Find out more about your test.
                    </p>*/}
                </div>
                {this.getQuestionnaires()}
            </div>
        );
    }
}

export default withRouter(withErrorBoundary(Faqs));
