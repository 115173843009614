import React from 'react';
import moment from 'moment';

export const TestProgressBasic = ({ result }) => {
    const registered_at_day = result && result.registered_at_timezone === null ?
        moment(result.registered_at).format('DD/MM/YY') :
        result && result.registered_at_timezone !== null ?
            moment(result.localised_registered_at).format('DD/MM/YY') : null
    ;
    const registered_at_time = result && result.registered_at_timezone === null ?
        moment(result.registered_at).format('H:mm a') :
        result && result.registered_at_timezone !== null ?
            moment(result.localised_registered_at).format('H:mm a') : null
    ;
    const lab_recieved_at_day = result && result.lab_recieved_at && result.registered_at_timezone === null ?
        moment(result.lab_recieved_at).format('DD/MM/YY') :
        result && result.lab_recieved_at && result.registered_at_timezone !== null ?
            moment(result.localised_lab_recieved_at).format('DD/MM/YY') : null
    ;
    const lab_recieved_at_time = result && result.lab_recieved_at && result.registered_at_timezone === null ?
        moment(result.lab_recieved_at).format('H:mm a') :
        result && result.lab_recieved_at && result.registered_at_timezone !== null ?
            moment(result.localised_lab_recieved_at).format('H:mm a') : null
    ;

    const lab_processed_at_day = result && result.lab_processed_at && result.registered_at_timezone === null ?
        moment(result.lab_processed_at).format('DD/MM/YY') :
        result && result.lab_processed_at && result.registered_at_timezone !== null ?
            moment(result.localised_lab_processed_at).format('DD/MM/YY') : null
    ;
    const lab_processed_at_time = result && result.lab_processed_at && result.registered_at_timezone === null ?
        moment(result.lab_processed_at).format('H:mm a') :
        result && result.lab_processed_at && result.registered_at_timezone !== null ?
            moment(result.localised_lab_processed_at).format('H:mm a') : null
    ;
    const timezone = result && result.registered_at_timezone !== null ?
        ' (GMT '+((result.registered_at_timezone<0) ? '' : '+') + result.registered_at_timezone + ')' : '';
  return (
      <div className="test-dashboard-section__container full-width">
          <div className="content-container">
              <div className="section-content centered">
                  <div className="test-results-basic row-group">
                    <img src="/img/test/register.svg" alt="" />
                    {result.lab_recieved_at ? (
                      <>
                        <div className="split active" />
                        <img src="/img/test/awaitingresult_active.svg" alt="" />
                      </>
                    ) : (
                      <>
                        <div className="split" />
                        <img src="/img/test/awaitingresult.svg" alt="" />
                      </>
                    )}
                    {result.lab_processed_at ? (
                      <>
                        <div className="split active" />
                        <img src="/img/test/resultready_active.svg" alt="" />
                      </>
                    ) : (
                      <>
                        <div className="split" />
                        <img src="/img/test/resultready.svg" alt="" />
                      </>
                    )}
                  </div>
                  <div className="test-results-basic row-group" style={{ paddingTop: 10 }}>
                    <div>
                      <label>
                        Registered
                      </label>
                      <div>
                        {registered_at_day}
                        <br />{registered_at_time}{timezone}
                      </div>
                    </div>
                    <div>
                      <label>
                        Received at lab
                      </label>
                      {result.lab_recieved_at ? (
                        <div>
                          {lab_recieved_at_day}
                          <br />{lab_recieved_at_time}{timezone}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label>
                        Result ready
                      </label>
                      {result.lab_processed_at ? (
                        <div>
                          {lab_processed_at_day}
                          <br />{lab_processed_at_time}{timezone}
                        </div>
                      ) : null}
                    </div>
                  </div>
              </div>
          </div>
      </div>
    );
};
