export const INDICATOR_URL = 'test_user/results/epigenetic';
export const FETCH_INDICATOR = 'you/indicator/FETCH_INDICATOR';
export const FETCH_INDICATOR_SUCCESS = 'you/indicator/FETCH_INDICATOR_SUCCESS';
export const FETCH_INDICATOR_FAILURE = 'you/indicator/FETCH_INDICATOR_FAILURE';

export const FETCH_INDICATOR_LINKS = 'you/indicator/FETCH_INDICATOR_LINKS';

export const FETCH_CHROMOSOME_AGE = 'you/indicator/FETCH_CHROMOSOME_AGE';
export const SAVE_CHROMOSOME_VALUE = 'you/indicator/SAVE_CHROMOSOME_VALUE';
export const FETCH_CHROMOSOME_VALUE = 'you/indicator/FETCH_CHROMOSOME_VALUE';

export const COMPARISON_URL = 'test_user/results/compare';
export const FETCH_COMPARE = 'you/compare/FETCH_COMPARE';
export const FETCH_COMPARE_SUCCESS = 'you/compare/FETCH_COMPARE_SUCCESS';
export const FETCH_COMPARE_FAILURE = 'you/compare/FETCH_COMPARE_FAILURE';

export const FETCH_COMPARISON = 'you/compare/FETCH_COMPARISON';
export const FETCH_COMPARISON_SUCCESS = 'you/compare/FETCH_COMPARISON_SUCCESS';
export const FETCH_COMPARISON_LOADING = 'you/compare/FETCH_COMPARISON_LOADING';

export const TRANSPOSONS_URL = 'test_user/results/transposons';
export const FETCH_TRANSPOSONS = 'you/transposons/FETCH_TRANSPOSONS';
export const FETCH_TRANSPOSONS_SUCCESS = 'you/transposons/FETCH_TRANSPOSONS_SUCCESS';
export const FETCH_TRANSPOSONS_LOADING = 'you/transposons/FETCH_TRANSPOSONS_LOADING';
export const FETCH_TRANSPOSONS_FAILURE = 'you/transposons/FETCH_TRANSPOSONS_FAILURE';

export const SET_TRANSPOSONS_TOOLTIP = 'you/transposons/SET_TRANSPOSONS_TOOLTIP';
export const UPDATE_TRANSPOSONS_TOOLTIP = 'you/transposons/UPDATE_TRANSPOSONS_TOOLTIP';

export const YOU_URL = 'test_user/results';
export const FETCH_YOU = 'you/FETCH_YOU';
export const FETCH_YOU_SUCCESS = 'you/FETCH_YOU_SUCCESS';
export const FETCH_YOU_FAILURE = 'you/FETCH_YOU_FAILURE';
export const FETCH_YOU_LOADING = 'you/FETCH_YOU_LOADING';

export const USER_DETAILS_URL = 'user/config';
export const FETCH_USER_DETAILS = 'you/FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_SUCCESS = 'you/FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'you/FETCH_USER_DETAILS_FAILURE';
export const FETCH_USER_DETAILS_LOADING = 'you/FETCH_USER_DETAILS_LOADING';

// Blood results
export const FETCH_BLOOD_RESULTS = 'FETCH_BLOOD_RESULTS';
export const FETCH_BLOOD_RESULTS_FAILURE = 'FETCH_BLOOD_RESULTS_FAILURE';
export const SET_BLOOD_RESULTS = 'SET_BLOOD_RESULTS';
export const SET_BLOOD_RESULTS_LOADING = 'SET_BLOOD_RESULTS_LOADING';

export const FETCH_COVID_RESULTS = 'you/FETCH_COVID_RESULTS';
export const FETCH_COVID_RESULTS_SUCCESS = 'you/FETCH_COVID_RESULTS_SUCCESS';
export const FETCH_COVID_RESULTS_FAILURE = 'you/FETCH_COVID_RESULTS_FAILURE';
export const FETCH_COVID_RESULTS_LOADING = 'you/FETCH_COVID_RESULTS_LOADING';

export const FETCH_ORDERS = 'you/FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'you/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'you/FETCH_ORDERS_FAILURE';
export const FETCH_ORDERS_LOADING = 'you/FETCH_ORDERS_LOADING';

export const SET_SELECTED_TEST = 'SET_SELECTED_TEST';

export const FETCH_COVID_RESULT = 'FETCH_COVID_RESULT';
export const FETCH_COVID_SUCCESS = 'FETCH_COVID_SUCCESS';
export const FETCH_COVID_FAILURE = 'FETCH_COVID_FAILURE';
