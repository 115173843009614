import React from 'react';

import Slider from '../../slider';

import utils from '../../../helpers/utils';

const Scale = (props) => {
    const {id, leftLabel, lowerBound, rightLabel, upperBound, value, onChange} = props;

    const onScaleChange = (value) => {
        onChange({target: {name: id, value: value.toString()}});
    };

    const marks = utils.defaultSliderMarks(lowerBound, upperBound, {min: leftLabel, max: rightLabel});
    marks[lowerBound].style = {
        fontSize: '16px',
        color: '#A5A5A5',
        lineHeight: '16px',
    };

    marks[upperBound].style = {
        fontSize: '16px',
        color: '#A5A5A5',
        lineHeight: '16px',
        width: '50%',
    };

    return (
        <div className="custom__slider-container">
            <Slider
                defaultTooltip="Move me"
                defaultValue={0}
                marks={marks}
                maxValue={upperBound}
                minValue={lowerBound}
                name={id}
                onChange={onScaleChange}
                showDefaultMark={false}
                showTooltip={true}
                step={1}
                tooltipClass="slider-tooltip"
                units=""
                value={value ? (utils.isNumeric(value) ? Number(value) : parseFloat(value)) : 0}
                visibleTooltip={true}
            />
        </div>
    );
};

export default Scale;