import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  padding: 31.5px 20px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
`;

const RelativeWrap = styled.div`
  position: relative;
`;

const ContentWrap = styled.div`
  width: 85%;
`;

export { Wrapper, RelativeWrap, ContentWrap };
