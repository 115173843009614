import React, { Component } from 'react';
import moment from 'moment';
import { TEST_STATUS } from '../../constants/test-status';

const TIMELINE_ICONS = [
  {
    title: 'Dispatched',
    status: [TEST_STATUS.POSTED, TEST_STATUS.REGISTERED, TEST_STATUS.PROCESSING, TEST_STATUS.PROCESSED],
  },
  {
    title: 'Registered',
    status: [TEST_STATUS.REGISTERED, TEST_STATUS.PROCESSING, TEST_STATUS.PROCESSED],
  },
  {
    title: 'Received',
    status: [TEST_STATUS.PROCESSING, TEST_STATUS.PROCESSED],
  },
  {
    title: 'Processing in lab',
    status: [TEST_STATUS.PROCESSED], //dont have processing or it looks checked when its in progress
    showLoadingIcon: true,
  },
  {
    title: 'QC',
    status: [TEST_STATUS.PROCESSED],
  },
  {
    title: 'Results', //You can never get this as it switches to results ready mode
    status: [TEST_STATUS.PROCESSED],
  },
];

export default class TimelineIcons extends Component {
  getCompleteStatus(item) {
    const { test } = this.props;

    return item.status.includes(test.status);
  }

  isQC(timelineItem) {
    // https://chronomics.atlassian.net/browse/USER-1053?focusedCommentId=15105
    return timelineItem.title === 'QC';
  }

  render() {
    const { test } = this.props;

    let dateStr = '';
    if (test.recieved_at) {
      dateStr = `Received: ${moment(test.recieved_at).format('Do MMM YYYY')}`;
    } else if (test.registered_at) {
      dateStr = `Registered: ${moment(test.registered_at).format('Do MMM YYYY')}`;
    } else if (test.dispatched_at) {
      dateStr = `Dispatched: ${moment(test.dispatched_at).format('Do MMM YYYY')}`;
    } else if (test.ordered_at) {
      dateStr = `Ordered: ${moment(test.ordered_at).format('Do MMM YYYY')}`;
    }

    return (
      <div className="timeline-icons dashboard-item__result-item">
        <div className="card-title-container">
          <p className="card-title">{test.title ? `Your ${test.title} test status` : 'Your test status'}</p>
          {dateStr && <p className="card-date">{dateStr}</p>}
        </div>

        <div className="dashboard-item__icons-container">
          {TIMELINE_ICONS.map(
            (timelineItem, i) =>
              !this.isQC(timelineItem) && (
                <div key={i} className={'timeline-icons__item ' + (this.getCompleteStatus(timelineItem) ? 'complete' : '')}>
                  {test.status == TEST_STATUS.PROCESSING && timelineItem.showLoadingIcon ? (
                    <img src="/img/processing-test-loader.gif" alt="Processing" className="timeline-icons__loading-icon" />
                  ) : (
                    <div className="timeline-icons__icon">
                      <i className="icon-tick_complete" />
                    </div>
                  )}

                  <h6 className="timeline-icons__title">{timelineItem.title}</h6>
                </div>
              ),
          )}
        </div>
      </div>
    );
  }
}
