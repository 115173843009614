import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { StyledButton } from '../../../../theming/themed-components';

export const CovidStep02 = ({ history }) => {
    const [error, setError] = useState();
    const [labAvailable, setLabAvailable] = useState();

  const handleBack = () => history.goBack();
  const handleCancel = () => history.push('/dashboard/covid');
  const handleNext = () => history.push('/join/account?slug=covid-19&utm_source=chronomics&utm_medium=app&utm_campaign=Testing%20Timeline');

  useEffect(() => {
    axios.get('products/shipping_dispatch_date?slug=covid-19').then(response => {
        if (response.data.status === 'no_slots_available') {
            setError('Unfortunately, we have no test availability at this time.');
          } else if (response.data.status === 'found') {
            setLabAvailable(moment(response.data.available_date, 'YYYY-MM-DD').format('DD/MM'));
          }
      });
  }, []);

  return (
    <section className="test-dashboard-section content-wrapper-animate">
        <div className="test-dashboard-section__section_content centered">
            <img className="back-button" src="/img/back.svg" alt="" onClick={handleBack} />
            
            <div className="test-dashboard-section__container">
                <div className="content-container">
                <div>
                    <div className="section-title">
                        Testing timeline calculator
                    </div>
                    <div className="section-sub-title">
                        for your standard COVID-19 test
                    </div>

                    {error === undefined ? (
                        <div className="panel-container">
                            <div className="sub-panel">
                                <div>
                                    <div>
                                        Your kit will be shipped on
                                        <br />{labAvailable ? <span>{labAvailable}</span> : null}
                                    </div>
                                    <div>
                                        Take the test on date needed (must be used within 6 months)
                                    </div>
                                </div>
                            </div>

                            <div className="sub-panel">
                                <div>
                                    <div>
                                        Send back on once test complete
                                    </div>
                                    <div>
                                        Get your result in
                                        <br /><span>24-36 </span>hrs<br />
                                        of being received at the lab 
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="notify-container"><label>{error}</label></div>
                    )}
                </div>
                <StyledButton
                    className="btn cancel"
                    onClick={handleNext}
                    type="button"
                >
                    Order Now
                </StyledButton>
                <div className="button-link" onClick={handleCancel}>
                    Clear result
                </div>
                </div>
            </div>
        </div>
    </section>
  );
};
