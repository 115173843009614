import validator from 'validator';
import axios from 'axios';
import utils from './utils';
import { VALID_US_STATE_CODES } from './validUSStateCodes';
import emailMisspelled, { top100 } from 'email-misspelled';

const emailChecker = emailMisspelled({ domains: [...top100, 'icloud.com' , 'btinternet.com'] });

export const ERROR_CONSTANTS = {
    REQUIRED_FIELD: 'This field is required',
    IN_VALID_EMAIL: 'This is not a valid email.',
    INVALID_POSTCODE_MESSAGE: 'We don\'t deliver to this postcode',
    INVALID_STATE: 'We are unable to ship to this state at the moment.'
};

const countriesToValidate = [ 'AD', 'AU', 'BE', 'BG', 'CA', 'CH', 'CZ', 'DE', 'DK', 'DZ', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IL', 'IN', 'IS', 'IT', 'JP', 'KE', 'LI', 'LT', 'LU', 'LV', 'MX', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SA', 'SE', 'TN', 'TW', 'UA', 'US', 'ZA', 'ZM' ];
export const restrictedUKPostcodes = utils.generateUKPostcodes([
    'AB36-38', 'AB55-56', 'FK17-21', 'IV1-39', 'IV52-54', 'IV63', 'KW1-14',
    'PA21-40', 'PH19-26', 'PH30-41', 'PH49-50', 'HS1-9', 'IV40-51', 'IV55-56',
    'KA27-28', 'KW15-17', 'PA20', 'PA41-49', 'PA60-78', 'PH42-44', 'ZE1-3', 'IM',
    'JE', 'GY', 'PO30 1', 'PO31', 'PO32', 'PO33', 'PO34', 'PO35', 'PO36', 'PO37',
    'PO38', 'PO39', 'PO40', 'PO41', 'BT', 'AB51 3YQ' , 'AB31 4FG', 'AB51 3QP', 'AB35', 'PH18']);


export const UKPostcodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
// \p{L} is unicode set of characters for all letters
const nameRegex = /^[\p{L}][\p{L} ,.'-]+$/u;

export default {

    areTestsAvailableToBuy(value, slug) {
        return axios.post('products/check_lab_capacity', {date_of_arrival: value, slug}).then(response => {
            if (response.data.status === 'no_slots_available') {
                return 'Unfortunately, we have no availability for your given travel date.';
            }
            return null;
        });
    },

    isRequired(value) {
        return value && !validator.isEmpty(validator.trim(value.toString())) ? null : ERROR_CONSTANTS.REQUIRED_FIELD;
    },

    isCountyRequired(value, country) {
      if (country !== 'US') return null; // County (i.e. State) is required only in the US
      return value && !validator.isEmpty(validator.trim(value.toString())) ? null : ERROR_CONSTANTS.REQUIRED_FIELD;
    },

    isName(value) {
        return nameRegex.test(value) ?  null : 'Please input valid characters';
    },

    isEmail(email) {
        return validator.isEmail(email) ? null : ERROR_CONSTANTS.IN_VALID_EMAIL;
    },

    isEmailDomainValid(email) {
        const [result] = emailChecker(email);
        return result ? `Please check the spelling of your email. Did you mean ${result.corrected}?` : null;
    },

    minPasswordLength(password) {
        return password.length >= 8 ? null : 'Password must be at least 8 characters';
    },

    complexPassword(password) {
        if(password.length < 8) {
            return 'Password must be at least 8 characters';
        }

        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasNonalphas = /\W/.test(password);
        if((hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas) < 3) {
            return 'Password must include a character from at least 3 of these categories: uppercase letter, lowercase letter, digits, special character';
        }

        return null;
    },

    isKitId(kitId) {
        if (/^[A-Z]{4}[0-9]{4}$/.test(kitId)) {
            return null;
        } else {
            return 'This is not a valid Kit ID.';
        }
    },

    matches(field, matchesField) {
        return field===matchesField ? null : 'Fields do not match';
    },

    isEmailUnique(email) {
        return axios.post('users/verify/email', {email: email})
            .then(response => null)
            .catch(error => utils.getFirstApiError(error, 'Invalid email') );
    },

    isMobile(mobile) {
        //Strict mode means has to have country code
        return validator.isMobilePhone(mobile, 'any', {strictMode: true}) ? null : 'Invalid number. Please include the country code';
    },

    //using any should match any country
    isPostCode(postcode, country) {
        /*
            Issues with brazil: 01229-010 and ierland having no postcode so lets not be so strict..
        */
        postcode = utils.formatPostcode(postcode, country);

        const checkRestrictedPostcodes = country === 'GB' && restrictedUKPostcodes.find((item) => postcode.startsWith(item));
        const UKValidPostcode = country === 'GB' && !UKPostcodeRegex.test(postcode);

        if(countriesToValidate.indexOf(country) > -1) {
            const error = (country === 'US' || country === 'CA') ? 'Zipcode is invalid' : ERROR_CONSTANTS.INVALID_POSTCODE_MESSAGE;
            return validator.isPostalCode(postcode, country) && !checkRestrictedPostcodes && !UKValidPostcode ? null : error;
        }

        //Otherwise no validation as its too strict..
        return null;
    },

    isBillingPostcode(postcode, country) {
         /*
            Issues with brazil: 01229-010 and Ireland having no postcode so lets not be so strict..
        */
        const UKValidPostcode = country === 'GB' && !UKPostcodeRegex.test(postcode);
        if(countriesToValidate.indexOf(country) > -1) {
            const error = (country === 'US' || country === 'CA') ? 'Zipcode is invalid' : ERROR_CONSTANTS.INVALID_POSTCODE_MESSAGE;
            return validator.isPostalCode(postcode, country) && !UKValidPostcode ? null : error;
        }

        //Otherwise no validation as its too strict..
        return null;
    },

    //2 letter country code from https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    isCountry(country) {
        return validator.isISO31661Alpha2(country) ? null : 'Country is invalid';
    },

    isTermsChecked(field, message) {
        message = message ? message : 'You must accept the terms';
        return field ? null : message;
    },

    isNumeric(value) {
        return validator.isNumeric(validator.trim(value)) ? null : 'This field must be numeric';
    },

    isMobile(value) {
        //allow spaces
        value = validator.trim(value.replace(/ /g, ''));
        return validator.isNumeric(value) ? null : 'This field must be a valid number';
    },

    isValidStateCode(value, country) {
        if (country !== 'US') return null; // County (i.e. State) is required only in the US
        const trimmedValue = value && validator.trim(value.toString());

        if (!VALID_US_STATE_CODES.includes(trimmedValue)) {
            return ERROR_CONSTANTS.INVALID_STATE;
        }

        return null;
    },

    isValidBarcode(barcode) {
        return axios.post('users/verify/barcode', {barcode: barcode})
            .then(response => {
                if(response.data.status && response.data.status === true) {
                    return null;
                }
                if(response.data && response.data.errors && response.data.errors.barcode) {
                    return response.data.errors.barcode;
                }
                return 'Invalid barcode';
            })
            .catch(error => utils.getFirstApiError(error, 'Invalid barcode'));
    },

    duplicateBarcode(value, firstBarcode) {
        validator.trim(value);
        return validator.trim(value) !== validator.trim(firstBarcode) ? null : 'Duplicate barcode. Enter different barcode than the first one.';
    },

    checkDuplicateBarcode(value, barcodes) {
        validator.trim(value);

        for (let i = 0; i<barcodes.length; i++) {
            if(value === barcodes[i]) {
                return 'Duplicate barcode. Enter different barcode than the first one.';
            }
        }

        return null;
    },

    isDate(value) {
        return value > 0 && value < 32 ? null : 'This must be a valid date';
    },

    isMonth(value) {
        return value > 0 && value < 13 ? null : 'This must be a valid Month';
    },

    isCurrentYear(value, month) {
        const currentYear = new Date().getFullYear();

        if(month) {
            if(month == 1) {
                return value >= currentYear-1 && value <= currentYear ? null : 'This must be a valid Year';
            }
            else if(month == 12) {
                return value >= currentYear && value <= currentYear+1 ? null : 'This must be a valid Year';
            }
        }

        return value == currentYear ? null : 'This must be a valid Year';
    },

    isYear(value) {
        return value >= 1900 && value <= new Date().getFullYear() ? null : 'This must be a valid Year';
    },

    isYearForOrder(value) {
        return value >= 1900 && value <= new Date().getFullYear() + 1 ? null : 'This must be a valid Year';
    },

    isYearGreaterThan1900(value) {
        return value >= 1900 ? null : 'This must be a valid Year';
    }
};
