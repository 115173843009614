import React, {Component} from 'react';

export default class CouponCodeForm extends Component {

    render() {
        const {fields, onSubmit, onChange, submitError, success} = this.props;

        if(success) {
            return (null);
        }

        return (
            <form onSubmit={(e) => onSubmit(e)} className="form coupon-form">

                {/*<label htmlFor="coupon-code">Code:</label>*/}
                <input type="text" value={fields.coupon_code} name="coupon_code" id="coupon_code" placeholder="Code"
                       onChange={(e) => onChange('coupon_code', e.target.value)}
                />
                <button className="btn btn--inline-small">Apply</button>

                {submitError &&
                <div className="form__error form__error--general">{submitError}</div>
                }
            </form>
        );
    }
}
