import React from 'react';
import PropTypes from 'prop-types';
import BiomarkerDetails from '../biomarkerDetails/details';
import { detailedBms } from '../../../../constants';

import ResultsBar from '../../../../components/common/resultsBar';
import { getIsUnsupportedRule, getIsFailedInterpretation } from '../utils';

const ValidResultContent = ({ bm, biomarkerDescription }) => {
  const { result, interpretation, interpretationData, biomarker } = bm;
  const isUnsupportedRule = biomarker && biomarker.interpretationRule ? getIsUnsupportedRule(biomarker.interpretationRule) : true;
  const isFailedInterpretation = getIsFailedInterpretation(interpretation);

  const renderAbout = () => (
    <>
      <br />
      <div className="biomarker-modal__title">About</div>
      <div className="biomarker-modal__text">{biomarkerDescription.about}</div>
    </>
  );

  // Loop through all values in reference ranges, map those values to interpretation descriptions from base biomarker
  // and filter out values for which there was no description.
  const labInterpretations = Object.keys(bm.interpretationData.value);
  const interpretationExplanations = labInterpretations
    .map(labInterpretation =>
      biomarkerDescription.interpretations.find(i => i.interpretation.toLowerCase() === labInterpretation.toLowerCase()),
    )
    .filter(i => Boolean(i));

  return (
    <>
      <div className="biomarker-modal__text">
        {interpretationData && result && !isUnsupportedRule && !isFailedInterpretation && (
          <ResultsBar interpretationData={interpretationData} result={result} />
        )}
        {detailedBms[biomarker.code] && <BiomarkerDetails interpretationData={interpretationData} unit={biomarker.unit} />}
      </div>
      <>
        {renderAbout()}
        {interpretationExplanations.map(({ interpretation, explanation }) => (
          <React.Fragment key={interpretation}>
            <br />
            <div className="biomarker-modal__title">{`What might a ${interpretation.toLowerCase()} result mean?`}</div>
            <div className="biomarker-modal__text">{explanation}</div>
          </React.Fragment>
        ))}
      </>
    </>
  );
};

ValidResultContent.propTypes = {
  bm: PropTypes.shape({
    biomarker: PropTypes.shape({
      unit: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      interpretationRule: PropTypes.shape({
        rule: PropTypes.string.isRequired,
      }),
    }).isRequired,
    result: PropTypes.string.isRequired,
    interpretation: PropTypes.string.isRequired,
    interpretationData: PropTypes.object.isRequired,
  }),
};

export default ValidResultContent;
