import * as React from "react";

function SvgParcel(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx={23}
                cy={23}
                r={22}
                fill="#fff"
                stroke="#fff"
                strokeWidth={2}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.72 15.333L20.752 26.292l-5.418-5.415-2.189 2.188 5.418 5.414 2.19 2.187 2.188-2.187 10.97-10.96-2.19-2.186z"
                fill="currentColor"
            />
            <path
                d="M23 46c12.703 0 23-10.297 23-23S35.703 0 23 0 0 10.297 0 23s10.297 23 23 23z"
                fill="currentColor"
            />
            <path
                d="M11 16.9L22.5 10 34 16.9l-11.5 6.9L11 16.9z"
                fill="#fff"
                stroke="currentColor"
            />
            <path
                d="M11 29.932l11.5 6.9V23.798l-11.5-6.9v13.034zM34 29.932l-11.5 6.9V23.798l11.5-6.9v13.034z"
                fill="#fff"
                stroke="currentColor"
            />
        </svg>
    );
}

export default SvgParcel;
