import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const DISPLAY_CONTROL_KEYS = {
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
};

export const DISPLAY_CONTROL = {
  [DISPLAY_CONTROL_KEYS.HEADER]: {
    LABEL: 'hideHeader',
    CLASS_NAME: 'hidden-header',
  },
  [DISPLAY_CONTROL_KEYS.FOOTER]: {
    LABEL: 'hideFooter',
    CLASS_NAME: 'hidden-footer',
  },
};

const getClassName = ({ className, shouldHideHeader, shouldHideFooter }) => {
  return [shouldHideFooter && DISPLAY_CONTROL.FOOTER.CLASS_NAME, shouldHideHeader && DISPLAY_CONTROL.HEADER.CLASS_NAME, className]
    .filter(Boolean)
    .join(' ');
};

const getSearchParameters = searchParameters => {
  return {
    [DISPLAY_CONTROL.HEADER.LABEL]: searchParameters?.get(DISPLAY_CONTROL.HEADER.LABEL) === 'true',
    [DISPLAY_CONTROL.FOOTER.LABEL]: searchParameters?.get(DISPLAY_CONTROL.FOOTER.LABEL) === 'true',
  };
};

const getSessionParameters = () => {
  return {
    [DISPLAY_CONTROL.HEADER.LABEL]: sessionStorage.getItem(DISPLAY_CONTROL.HEADER.LABEL) === 'true',
    [DISPLAY_CONTROL.FOOTER.LABEL]: sessionStorage.getItem(DISPLAY_CONTROL.FOOTER.LABEL) === 'true',
  };
};

const getLocalParameters = () => {
  const brandingJson = localStorage.getItem('partnerBranding');
  const branding = brandingJson ? JSON.parse(brandingJson) : null;
  return {
    [DISPLAY_CONTROL.HEADER.LABEL]: branding?.hideHeaderAlways === true,
    [DISPLAY_CONTROL.FOOTER.LABEL]: branding?.hideFooterAlways === true,
  };
};

const getDisplayControlFromSearchOrStorage = searchParameters => {
  const controlFromSearch = getSearchParameters(searchParameters);
  const controlFromSession = getSessionParameters();
  const controlFromLocal = getLocalParameters();

  return {
    controlFromSearch,
    controlFromSession,
    controlFromLocal,
  };
};

/**
 * There are 3 places the header and footer can be controlled from:
 *  1, partner config (stored in local storage)
 *  2. query string
 *  3. session storage (from a previous page's query string)
 *
 *  - For logged out pages, the header is hidden completely if the hideHeader flag is set in any of these locations.
 *  - For logged in pages, the header is only hidden completely if flag is set in the partner config in local storage
 *      since this indicates a partner whose users should never access the site un-embedded.
 *  - If the hideHeader flag is only set in query string or session storage, we just hide the Hurdle logo for logged in pages
 *      so that the users can still navigate the site.
 *  - The footer is hidden if the hideFooter flag is set in any of the locations whether logged in or not.
 */
const getDisplayControl = ({ controlFromSearch, controlFromSession, controlFromLocal, isLoggedIn }) => {
  const shouldHideHeader =
    (isLoggedIn && controlFromLocal[DISPLAY_CONTROL.HEADER.LABEL]) ||
    (!isLoggedIn &&
      (controlFromLocal[DISPLAY_CONTROL.HEADER.LABEL] ||
        controlFromSearch[DISPLAY_CONTROL.HEADER.LABEL] ||
        controlFromSession[DISPLAY_CONTROL.HEADER.LABEL]));
  const shouldHideLogo = controlFromSearch[DISPLAY_CONTROL.HEADER.LABEL] || controlFromSession[DISPLAY_CONTROL.HEADER.LABEL];
  const shouldHideFooter =
    controlFromLocal[DISPLAY_CONTROL.FOOTER.LABEL] ||
    controlFromSearch[DISPLAY_CONTROL.FOOTER.LABEL] ||
    controlFromSession[DISPLAY_CONTROL.FOOTER.LABEL];

  return {
    shouldHideHeader,
    shouldHideLogo,
    shouldHideFooter,
  };
};

const setDisplayControlInSession = ({ controlFromSearch, controlFromSession }) => {
  Object.keys(DISPLAY_CONTROL).map(key => {
    const { LABEL } = DISPLAY_CONTROL[key];
    const value = controlFromSearch[LABEL];
    if (value && !controlFromSession[key]) {
      sessionStorage.setItem(LABEL, `${value}`);
    }
  });
};

export const useDisplayControl = ({ className, isLoggedIn } = {}) => {
  const { search } = useLocation();
  const searchParameters = useMemo(() => new URLSearchParams(search), [search]);
  const { controlFromSearch, controlFromSession, controlFromLocal } = getDisplayControlFromSearchOrStorage(searchParameters);

  setDisplayControlInSession({ controlFromSearch, controlFromSession });

  const { shouldHideHeader, shouldHideLogo, shouldHideFooter } = getDisplayControl({
    controlFromSearch,
    controlFromSession,
    controlFromLocal,
    isLoggedIn,
  });

  const mergedClassName = getClassName({
    shouldHideHeader,
    shouldHideFooter,
    className,
  });

  return {
    shouldHideHeader,
    shouldHideLogo,
    shouldHideFooter,
    className: mergedClassName,
  };
};
