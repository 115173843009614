import React from 'react';

const stressAndEpigeneticsData = {
    title: 'Stress and epigenetics',
    factors: [
        {
            title: 'The stress response',
            text: 'Stress comes from many sources: the good kind, such as exercise, and the bad, such as disrupted ' +
                'sleep/wake cycle, issues at work or the loss of a loved one. Our bodies\' response to these events is surprisingly similar, ' +
                'including the release of a hormone called cortisol into the bloodstream, driving us into \'fight-or-flight\' mode.',
            causes: [
                {
                    title: 'Hypothalamus secretes ACTH',
                    imageUrl: '/img/you/actionable-you/stress/hypothalamus-secretes.png'
                },
                {
                    title: 'Adrenal glands secrete cortisol',
                    imageUrl: '/img/you/actionable-you/stress/cortisol-secretes.png'
                },
                {
                    title: 'Body generates a stress response',
                    imageUrl: '/img/you/actionable-you/stress/stress-response.png'
                }
            ]
        },
        {
            title: 'How lifetime stress affects epigenetics',
            text: 'When we\'re stressed released cortisol enters our cells and binds with a glucocorticoid' +
                ' receptor. This travels into the nucleus and affects how our DNA is read. This, in turn, can lead to ' +
                'specific epigenetic changes, that in some cases can negatively impact body’s key functions, such as the immune system and our metabolism.',
            imageUrl: '/img/you/actionable-you/stress/stress-affects-epigenetics.png'
        }
    ]
};

const StressAndEpigenetics = () => {
    const { title, factors } = stressAndEpigeneticsData;
    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__graph-section no-padding">
                <h4 className="indicator-section-title">{title}</h4>
                <div className="biological-age__stress-section">
                    {
                        factors.map((item, index) => (
                            <div className="stress-container" key={index}>
                                <div className="stress-information-section">
                                    <h4 className="indicator-section-title inside-title">{item.title}</h4>
                                    <p className="indicator-section-description">{item.text}</p>
                                </div>
                                <div className="biological-age__stress-epigenetics-section">
                                    {
                                        item.imageUrl ?
                                            <div className="stress-image">
                                                <h4 className="indicator-section-title inside-title">&nbsp;</h4>{/* Spaces same as left col */}
                                                <img src={item.imageUrl} alt={item.title.replace(/ /g, '-')} className="img-narrow" />
                                            </div> : item.causes ?
                                            <div className="causes-section">
                                                <h4 className="indicator-section-title inside-title">&nbsp;</h4>{/* Spaces same as left col */}
                                                {
                                                    item.causes.map((cause, index) => (
                                                        <div className="single-cause" key={cause.title.replace(/ /g, '-')}>
                                                            <div className="cause-number">{index+1}</div>
                                                            {
                                                                cause.imageUrl ?
                                                                    <div className="cause-image">
                                                                        <img src={cause.imageUrl} alt={cause.title.replace(/ /g, '-')} />
                                                                    </div> : ''
                                                            }
                                                            <p className="cause-title">{cause.title}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div> : ''
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default StressAndEpigenetics;