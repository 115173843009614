import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MethylationGraph from '../../../../containers/you/common/methylationGraph';

import Loader from '../../../../components/common/loader';
import GaugeChart from '../../../../components/common/gaugeChart';
import Accordion from '../../../../components/common/accordion';

class Tracker extends Component {

    componentDidMount() {
        this.props.fetchTransposons('tracker');
    }

    renderHeroSection() {
        const { results: { title, text } } = this.props;

        if(!(title || text)) {
            return null;
        }

        return (
            <div className="result-hero">
                <div className="result-hero__text-container">
                    <div className="transposons__hero-section">
                        {title ? <h1 className="result-hero__title study-hero__title">{title}</h1> : ''}
                        {text ? <p className="hero-description">{text}</p> : ''}
                    </div>
                </div>
            </div>
        );
    }

    renderSummarySection() {
        const { results: {summary} } = this.props;

        if(!summary) {
            return null;
        }

        const { title, image_url, summary_text, analysis_text } = summary;

        return (
            <div className="biological-age__graph-section">
                <div className="transposons__evolutionary-protector">
                    {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                    {image_url ? <div className="image-container"><img alt={title} src={image_url} /></div> : ''}
                    {summary_text ? <p className="summary-text">{summary_text}</p> : ''}
                    {analysis_text ? <p className="transposons__title-information">{analysis_text}</p> : ''}
                </div>
            </div>
        );
    }

    renderActiveInactiveSection() {
        const { results: {active_inactive} } = this.props;
        if(!active_inactive) {
            return null;
        }

        return (
            <div className="biological-age__graph-section">
                <div className="transposons__section-without-shadow">
                    {active_inactive.title ? <h4 className="indicator-section-title">{active_inactive.title}</h4> : ''}
                    {active_inactive.text ? <p className="indicator-section-description">{active_inactive.text}</p> : ''}
                </div>
                {this.renderActiveInactiveItems()}
            </div>
        );
    }

    renderActiveInactiveItems() {
        const { results: { active_inactive: {items} }} = this.props;

        if(!(items && items.length)) {
            return null;
        }

        return (
            <div>
                {
                    items.map((item) => {
                        const { title, percent, label, analysis_text } = item;
                        const gaugeChartData = [{value: percent, name: 'section', label}];
                        return (
                            <div className="transposons__gauge-container" key={title}>
                                {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                                {percent ?
                                    <div className="transposons__gauge-chart">
                                        <GaugeChart data={gaugeChartData} startAngle={90} />
                                    </div> : ''
                                }
                                {analysis_text ? <p className="transposons__title-information">{analysis_text}</p> : ''}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    renderResultsSection() {
        const { results: {active_inactive}, updateTransposonsTooltip, tooltips } = this.props;

        if(!(active_inactive && active_inactive.results && active_inactive.results.items && active_inactive.results.items.length)) {
            return null;
        }

        const { results: {title, items} } = active_inactive;

        const children = ({range: {data}, percent, label, top_tip, title}) => {
            return (
                <div className="accordion-children">
                    <div className="methylation-graph__title">
                        <span>{percent}</span>
                        <span>%{label}</span>
                    </div>
                    {data ?
                        <MethylationGraph
                            data={data.range}
                            labels={data.x}
                            showTooltip={tooltips.includes(title.replace(' ', '-'))}
                            you={data.you}
                        /> : ''
                    }
                    {
                        top_tip ?
                            <div className="top-tip">
                                <h5 className="tip-title">Top tip</h5>
                                <p className="tip-description">{top_tip}</p>
                            </div> : ''
                    }
                </div>
            );
        };

        const updatedItems = items.map((item) => (
            {
                title: item.title,
                description: item.text,
                children: children({...item}),
                classes: 'transposons__tracker-accordion'
            }
        ));

        return (
            <div className="biological-age__graph-section">
                <Accordion
                    classes="transposons__section-without-shadow"
                    items={updatedItems}
                    showOpen={true}
                    title={title}
                    toggleTooltip={updateTransposonsTooltip}
                />
            </div>
        );
    }

    render() {
        const { isLoading, results } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        if(!(results && Object.keys(results).length)) {
            return null;
        }

        return (
            <section className="transposons__container content-wrapper-animate">
                {this.renderHeroSection()}
                {this.renderSummarySection()}
                {this.renderActiveInactiveSection()}
                {this.renderResultsSection()}
            </section>
        );
    }
}

Tracker.defaultProps = {
    isLoading: true,
    results: {},
    fetchTransposons: () => {},
    tooltips: [],
    updateTransposonsTooltip: () => {},
};

Tracker.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.object,
    fetchTransposons: PropTypes.func,
    tooltips: PropTypes.array,
    updateTransposonsTooltip: PropTypes.func,
};

export default Tracker;