import React from 'react';
import {Tooltip} from 'react-tippy';

import MethylationGraph from '../../../../containers/you/common/methylationGraph';

const GenesAffected = ({genes}) => {
    if(!genes) {
        return null;
    }

    const { title, text, data } = genes;

    return (
        <div className="genes-affected">
            {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
            {text ? <p className="indicator-section-description">{text}</p> : ''}
            <div className="genes-affected__affected-section">
                {
                    data ? data.map((item, index) => {
                        const ranges = [{max_percent: 100, min_percent: 0, rating: 'gradient'}];
                        const { healthy_range, percentage, title, text, link_url, link_title } = item;
                        return (
                            <div className="affector-section" key={index}>
                                {title ? <div className="header-section">{title}</div> : '' }
                                <div className="affector-information-section">
                                    {text ? <p className="affector-information-description">{text}</p> : ''}
                                    {(link_url && link_title) ? <a className="disease-read-link affector-information-link" href={link_url} target="_blank" rel="noreferrer">{link_title}</a> : ''}
                                    {
                                        percentage !== null &&
                                            <div>
                                                <div className="methylation-graph__title">
                                                    <span>{percentage}</span>
                                                    <span>%</span>
                                                </div>
                                                <div className="affector-text">
                                                    Relative methylation impact
                                                    <Tooltip title="We measure the methylation level at a CpG position that is within or close to this gene and then calculate a relative impact score to show what effect it's having.">
                                                        <i className="icon-info-circled help-icon form__help-icon"/>
                                                    </Tooltip>
                                                </div>
                                                {
                                                    healthy_range ?
                                                        <MethylationGraph
                                                            data={ranges}
                                                            invert={healthy_range.invert}
                                                            labels={{min_label: healthy_range.min_label, max_label: healthy_range.max_label}}
                                                            showResultPoint={true}
                                                            you={healthy_range.you[0]}
                                                        /> : ''
                                                }
                                            </div>
                                    }
                                    
                                </div>
                            </div>
                        );
                    }) : ''
                }
            </div>
        </div>
    );
};

export default GenesAffected;