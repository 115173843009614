import React from 'react';
import PropTypes from 'prop-types';

import RelatedArticles from '../../../components/common/relatedArticles';
import ChromosomeGraph from '../../../components/common/chromosomeGraph';

import HowWorksSteps from '../../../containers/you/common/howWorksSteps';
import FAQ from '../../../containers/you/common/faqs';

const Science = ({howWorks, relatedArticles, scientificSteps, chromosomeGraph, testUser, slug, breakpoint}) => (
    <div className="compare-tab__container">
        
        {/*<div>
            <div className="result-hero__how-works-section">
                <h4 className="indicator-section-title">{howWorks.title}</h4>
                <p className="indicator-section-information">{howWorks.text}</p>
            </div>
            
        </div>*/}

        {Object.keys(scientificSteps).length &&
            <div className="compare-tab__how-works">
                <h4 className="indicator-section-title">How your result is generated</h4>
                <HowWorksSteps expandImages="yes" showNumbers="yes" steps={scientificSteps} breakpoint={breakpoint} />
            </div>
        }

            
        {
            (testUser && chromosomeGraph && chromosomeGraph.data) &&
                <div className="biological-age__chromosome-graph-container">
                    <div className="biological-age__chromosome-graph-section">
                        {chromosomeGraph.title && <h4 className="indicator-section-title">{chromosomeGraph.title}</h4>}
                        {chromosomeGraph.text && <p className="indicator-section-description">{chromosomeGraph.text}</p>}

                        <ChromosomeGraph
                            gender={testUser.gender}
                            data={chromosomeGraph.data}
                            orientation="vertical"
                        />
                    </div>
                </div>
        }

        {
            (relatedArticles && relatedArticles.length) &&
            <div className="biological-age__related-wrapper">
                <h4 className="indicator-section-title">References</h4>
                <RelatedArticles relatedArticles={relatedArticles} className="biological-age-related-container" hideTitle={true} />
            </div>
        }
        <FAQ slug={slug} />
    </div>
);

Science.propTypes = {
    comparisonGraph: PropTypes.object,
};

export default Science;
