import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Badge extends Component {

    render() {
        const {text, imageSource, title} = this.props;

        if(!imageSource) return '';

        return (
            <div className="badge__container">
                <div className="badge-image-container">
                    <img className="badge-image" src={imageSource} alt={text} />
                    <p className="badge-text">{text}</p>
                </div>
            </div>
        );
    }
}

Badge.propTypes = {
    text: PropTypes.string,
    imageSource: PropTypes.string,
    title: PropTypes.string
};
