import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FeaturedFactors from '../../containers/you/common/featuredFactors';

import { Helmet } from 'react-helmet';

import Loader from '../../components/common/loader';
import withError from '../../components/common/withError';
import withErrorBoundary from '../../components/common/errorBoundary';


import { getGoalDetails} from '../../services/improvements/actions';
import {  goalDetailsSelector, improvementsLoadingSelector } from '../../services/improvements/selectors';

const stage = [
    {
        'icon': '/img/you/improvements/experimental.png',
        'title': 'Experimental',
        'slug': 'Experimental'
    },
    {
        'icon': '/img/you/improvements/trials.png',
        'title': 'In trials',
        'slug': 'in_trials'
    },
    {
        'icon': '/img/you/actionable-you/biological-age/result-intro-image.png',
        'title': 'Epigenetically proven',
        'slug': 'epigenetically_proven'
    }
];

class ProductDetails extends Component {
    constructor(){
        super();
    }

    componentDidMount() {
        const {history,data} = this.props;
        const path = history.location.pathname.split('/');
        const slug = path[4];
        if(data == null){
            this.props.fetchGoalDetails(slug);
        }
        window.scroll(0,0);
    }

    render() {
        const { isLoading, goalDetails, match, data } = this.props;
            if(!data && isLoading) {
                return <Loader />;
            }
            const category = match.params.slug;

            const sectionData = data ? data : goalDetails;
            
            const value = sectionData.items.map((item,index) => {
                if(category == item.slug){
                    const value = item;
                    return value;
                }
            });

            const items = value.filter(function (el){
                return el != null;
            });

            const product = items[0];

            return (
                    <div className="product-details-container">
                        <h3 className="product-details-container__title"> {product.title}</h3>
                        <div className="product-details-container__description" dangerouslySetInnerHTML={{ __html: product.sub_title }} />
                        
                        <div className="product-stage-section">
                            <p className="product-stage-section__title">Product Stage</p>
                            <div className="product-stage-section__container">
                                {
                                    stage.map((item,index) => {
                                        return(
                                            <div className="stage-details" key={index}>
                                                <div className={`${item.slug}` == `${product.product_stage}` ? 'stage-details__icon selected' : 'stage-details__icon'}>
                                                    <img src={item.icon} alt={item.title} />
                                                    <div className="line" />
                                                </div>
                                                <p className={`${item.slug}` == `${product.product_stage}` ? 'stage-details__description-selected' : 'stage-details__description'}>{item.title}</p>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
    
                        <FeaturedFactors items={items} display ={true}/>
    
                        <Helmet>
                            <title>{product.title}</title>
                        </Helmet>
    
                    </div>
                );
        }
}

ProductDetails.defaultProps = {
    goalDetails: {},
    isLoading: true,
};

ProductDetails.propTypes = {
    fetchGoalsDetails: PropTypes.func,
    isLoading: PropTypes.bool,
    goalDetails: PropTypes.object
};

const mapStateToProps = (state) => ({
    isLoading: improvementsLoadingSelector(state,'goalDetails'),
    goalDetails: goalDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchGoalDetails: (slug) => dispatch(getGoalDetails(slug)),
});

const enhance = compose(
    withErrorBoundary,
    withError({reducer: 'improvements', page: 'aspiration'}),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ProductDetails);
