import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import LogPageView from './common/logPageView';

import config from '../config';
import withErrorBoundary from './common/errorBoundary';

import Join from './join';
import Login from './auth/login/login';
import ForgotPassword from './auth/forgotPassword';
import ResetPassword from './auth/resetPassword';
import BreakpointContext from './context/breakpoint';
import RestoreScroll from './common/restoreScroll';
import DemoLogin from './auth/demoLogin';
import ShowMessage from './loggedOut/showMessage';
import TuiDrop from './loggedOut/tuiDrop';
import InteractiveReport from './interactiveReport';
import RegistrationPage from './join/registrationPage';
import { extractFirstQueryStringParameter } from '../../utils/extractFirstQueryStringParameter';

const LoggedOutRoutes = () => {

  const JoinPage = props => {
    const section = props.match.params.section || 'account';
    const parsedQueryString = queryString.parse(props.location.search);

    /*
            The old checkout used ?product=product-suk&quantity=1&is_gift=0 which was currency specific
            Now we use ?slug=basic&frequency=monthly so its currency independent
        */
    let slug = null;
    let productSuk = null;
    let frequency = null;
    let quantity = 1;
    let isGift = 0;
    let campaignId = null;
    const coupon = parsedQueryString.coupon;

    //Are we coming in initially with a product selected?
    try {
      if (parsedQueryString.product || parsedQueryString.slug) {
        if (parsedQueryString.product) {
          productSuk = parsedQueryString.product;
          frequency = null; //Will get set from the suk..
        } else if (parsedQueryString.slug) {
          slug = parsedQueryString.slug;
          frequency = parsedQueryString.frequency ? parsedQueryString.frequency : 'monthly';
        }

        //These are all optional.. which will default if not provided
        if (parsedQueryString.quantity) {
          quantity = parsedQueryString.quantity;
        }
        if (parsedQueryString.is_gift) {
          isGift = parsedQueryString.is_gift;
        }
        if (parsedQueryString.campaign_id) {
          campaignId = parsedQueryString.campaign_id;
        }

        //Save to session so persists between pages. setItem uses strings to jsonify to keep type..
        sessionStorage.setItem('productSuk', JSON.stringify(productSuk));
        sessionStorage.setItem('slug', JSON.stringify(slug));
        sessionStorage.setItem('frequency', frequency);
        sessionStorage.setItem('quantity', quantity);
        sessionStorage.setItem('isGift', isGift);
        sessionStorage.setItem('campaignId', campaignId);
      } else if (sessionStorage.getItem('productSuk') || sessionStorage.getItem('slug')) {
        if (sessionStorage.getItem('productSuk') !== null) {
          productSuk = JSON.parse(sessionStorage.getItem('productSuk'));
        }
        if (sessionStorage.getItem('slug') !== null) {
          slug = JSON.parse(sessionStorage.getItem('slug'));
        }

        frequency = sessionStorage.getItem('frequency');
        quantity = sessionStorage.getItem('quantity');
        isGift = sessionStorage.getItem('isGift');
        campaignId = sessionStorage.getItem('campaignId');
      }
    } catch (e) {
      return e;
    }
    //If we didnt have a valid suk or slug redirect back to pricing..
    if (!productSuk && !slug) {
      window.location = `${config.app.web_url}/products`;
    }

    return (
      <>
        <Helmet>
          <title>Checkout - Hurdle</title>
          <meta content="Hurdle Checkout" name="description" />
        </Helmet>
        <RestoreScroll>
          <Join {...props} {...{ campaignId, isGift, productSuk, slug, frequency, quantity, section, coupon }} />
        </RestoreScroll>
      </>
    );
  };

  const LoginPage = () => (
    <>
      <Helmet>
        <title>Login - Hurdle</title>
        <meta content="Hurdle customer login." name="description" />
      </Helmet>
      <Login />
    </>
  );

  const ForgotPasswordPage = () => (
    <>
      <Helmet>
        <title>Forgot Password - Hurdle</title>
        <meta content="Forgot Password." name="description" />
      </Helmet>
      <ForgotPassword />
    </>
  );

  const ResetPasswordPage = () => {
    const token = extractFirstQueryStringParameter('token');

    return (
      <>
        <Helmet>
          <title>Reset Password - Hurdle</title>
          <meta content="Reset Password" name="description" />
        </Helmet>
        <ResetPassword token={token} />
      </>
    );
  };

  const DemoLoginPage = props => {
    return (
      <>
        <Helmet>
          <title>Demo Login - Hurdle</title>
          <meta content="Hurdle demo login." name="description" />
        </Helmet>
        <DemoLogin {...props} />
      </>
    );
  };

  const ShowMessagePage = props => {
    const { message_title, message_text } = config.app;

    return (
      <>
        <Helmet>
          <title>{message_title} - Hurdle</title>
        </Helmet>
        <ShowMessage {...props} message_title={message_title} message_text={message_text} />
      </>
    );
  };

  const InteractiveReportPage = props => {
    return (
      <>
        <Helmet>
          <title>Interactive Report - Hurdle</title>
          <meta content="Hurdle interactive report" name="description" />
        </Helmet>
        <BreakpointContext.Consumer>{breakpoint => <InteractiveReport {...props} breakpoint={breakpoint} />}</BreakpointContext.Consumer>
      </>
    );
  };

  return (
    <Fragment>
      <Route component={LogPageView} />
      <Switch>
        <Route path="/join/:section" render={JoinPage} />
        <Route path="/register" render={props => <RegistrationPage {...props} />} />
        <Route path="/login" render={LoginPage} />
        <Route path="/forgot-password" render={ForgotPasswordPage} />
        <Route path="/reset-password" render={ResetPasswordPage} />
        <Route path="/demo" render={DemoLoginPage} />
        <Route path="/register-test-intrest" render={ShowMessagePage} />
        <Route path="/shop/checkout-guest/complete" render={ShowMessagePage} />
        <Route path="/tui-drop" render={TuiDrop} />
        <Route path="/report" render={InteractiveReportPage} />

        <Redirect to="/" />
      </Switch>
    </Fragment>
  );
};

export default withErrorBoundary(LoggedOutRoutes);
