import React from 'react';
import PropTypes from 'prop-types';
import utils from '../helpers/utils';
import { StyledLink } from '../../theming/themed-components';

const HelpBox = ({ title, description, link, buttonText, linkText, className }) => {
  let linkHtml = '';
  if (buttonText) {
    if (link.startsWith('http')) {
      linkHtml = (
        <a className="btn" href={link} onClick={() => utils.track('Clicked Help Box', { title: title, to: link })}>
          {buttonText}
        </a>
      );
    } else {
      linkHtml = (
        <StyledLink className="btn" to={link} onClick={() => utils.track('Clicked Help Box', { title: title, to: link })}>
          {buttonText}
        </StyledLink>
      );
    }
  }

  return (
    <div className={`help-box__container ${className}`}>
      <div className="help-box__information-section">
        <h4 className="help-box__title">{title}</h4>
        <p className="help-box__information">{description}</p>
        {linkHtml}
      </div>
    </div>
  );
};

HelpBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  linkText: PropTypes.string,
  className: PropTypes.string,
};

export default HelpBox;
