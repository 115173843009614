import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { StyledInput } from '../../theming/themed-components';

class InputField extends Component {
  constructor(props) {
    super();
    this.state = {
      inputType: props.type,
    };

    this.changeType = this.changeType.bind(this);
  }

  changeType() {
    const { type } = this.props;
    this.setState(prev => ({ inputType: prev.inputType === 'password' ? 'text' : type }));
  }

  render() {
    const { value, onChange, showError, name, label, error, required, iconClass, helper, ...rest } = this.props;
    const { inputType } = this.state;
    return (
      <div className="input-field__container">
        <StyledInput {...rest} className="input-field" id={name} name={name} onChange={onChange} type={inputType} value={value} />
        <label className={`${'input-field__label'} ${value ? 'valid' : ''}`} htmlFor={name}>
          {label} {required ? <span>*</span> : ''}
        </label>
        {iconClass ? (
          <i className={`input-field__icon ${inputType === 'password' ? iconClass : iconClass + '-off'}`} onClick={this.changeType} />
        ) : (
          ''
        )}
        {!showError && helper ? <div className="form__helper">{helper}</div> : null}
        <div className="form__error">{showError ? error : ''}</div>
      </div>
    );
  }
}

InputField.defaultProps = {
  value: '',
  required: false,
  type: 'text',
};

InputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  helper: PropTypes.string,
};

export default withTheme(InputField);
