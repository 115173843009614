import React from 'react';
import PropTypes from 'prop-types';

import MethylationGraph from '../../../../containers/you/common/methylationGraph';

const HealthyRanges = ({healthyRanges, wrapperClass}) => {

    if(!(healthyRanges && healthyRanges.data)) {
        return null;
    }

    const { title, text, data: {range, x, you, refrence_line, ledgend} } = healthyRanges;

    return (
        <div className="biological-age__graph-section no-padding">
            <div className={`healthy-range__container${wrapperClass ? ` ${wrapperClass}` : ''}`}>
                {title && <h3 className="indicator-section-title inside-title">{title}</h3>}
                {text && <p className="indicator-section-description">{text}</p>}

                <div className="healthy-range__ranges-graph">
                    {(refrence_line && !Array.isArray(refrence_line)) ? <div className="reference-line" style={{left: `${refrence_line.percent}%`}} /> : ''}
                    <MethylationGraph
                        data={range}
                        labels={x}
                        showResultPoint={true}
                        you={you}
                    />
                    {(refrence_line && !Array.isArray(refrence_line)) ? <div className="reference-label" style={{left: `calc(${refrence_line.percent}% - 55px)`}}>{refrence_line.label}</div> : ''}
                </div>
                <div className="healthy-range__legends">
                    { Object.keys(ledgend).map((key) => {
                        return (
                            <div className="single-legend" key={key}>
                                <div className={`legend-point ${key}`} />
                                <div className="legend-title">{ledgend[key]}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

HealthyRanges.propTypes = {
    healthyRanges: PropTypes.object,
};

export default HealthyRanges;