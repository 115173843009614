import React, { useEffect } from 'react';
import KitDisposal from './kitDisposal';
import utils from '../helpers/utils';
import config from '../../config';
import { StyledButton } from '../../theming/themed-components';

const titles = {
  cancel_not_allowed: 'Sorry, it’s not possible to cancel this test.',
  cancel_not_allowed_registered: 'Sorry, it’s not possible to cancel this test as it has been registered.',
  refund_14_days_passed: 'Sorry, it’s not possible to cancel this test.',
  refund_14_days_grace_return: 'DISPOSING OF YOUR USED AND UNUSED HOME TEST KIT (AND PARTS)*',
};

// cancel_not_allowed = order is created > 14 days ago and has been dispatched
const texts = {
  cancel_not_allowed:
    'It’s been more than 14 days since you placed the order so unfortunately you no longer qualify for an automated refund.\n\nIf you’ve had issues with your test kit or your results, please click here to apply for a refund.',
  cancel_not_allowed_registered:
    'Unfortunately you no longer qualify for an automated refund as you have registered the test.\n\nIf you’ve had issues with your test kit or your results, please click here to apply for a refund.',
  refund_14_days_passed:
    'It’s been more than 14 days since you placed the order so unfortunately you no longer qualify for an automated refund.\n\nIf you’ve had issues with your test kit or your results, please click here to apply for a refund.',
  refund_14_days_grace_return: KitDisposal(),
};

const isRefundLinkVisible = {
  cancel_not_allowed: true,
  cancel_not_allowed_registered: true,
  refund_14_days_passed: true,
  refund_14_days_grace_return: false,
};

const { refundUrl } = config;

const InfoModal = ({ onClose, message, orderNumber = '' }) => {
  useEffect(() => {
    utils.track('Opened Why Order Modal');
  }, []);
  const shouldDisplayRefundLink = isRefundLinkVisible[message] && orderNumber;
  return (
    <div className="order-cancel-modal">
      <div className="order-cancel-modal__title">{titles[message]}</div>
      <div className="order-cancel-modal__text">{texts[message]}</div>
      {shouldDisplayRefundLink && (
        <div className="order-cancel-modal__text">
          If your test kit is faulty or incorrect, please{' '}
          <a href={`${refundUrl}?order_id=${orderNumber}`}>click here to apply for a refund</a>.
        </div>
      )}
      <div className="order-cancel-modal__buttons">
        <StyledButton className="btn" onClick={() => onClose()}>
          Ok
        </StyledButton>
      </div>
    </div>
  );
};

export default InfoModal;
