import React from 'react';

const stressedData = {
    title: 'Why we get stressed',
    text: 'To help our bodies cope with situations we perceive as harmful, we have an uncontrollable physiological' +
        ' response - acute stress (often called \'fight-or-flight\'). This raises our heart rate and pushes blood to muscles' +
        ' and vital organs to give a burst of energy. As cave dwellers it helped us instinctively deal with predators and' +
        ' it\'s why, even today, we can act unconsciously. It\'s good to know that a short burst of stress, such as during a race,' +
        ' may even be good for us.',
    factors: [
        {
            title: 'Stress. Recover. Repeat.',
            text: 'Stress adds up over time. Our levels go up as we react to tense situations and go down as we recover' +
                ' and return to rest mode. It\'s been shown that we\'re more sensitive to the damaging effects of stress' +
                ' as children and even before we\'re born.',
            imageUrl: '/img/you/actionable-you/stress/stress-recover-repeat.png'
        },
        {
            title: 'Prolonged stress',
            text: 'If stressful events happen continuously, without time to recover, we can become locked in \'flight-or-fight mode\'' +
                ' even after the situation is over. This can increase the risk of developing stress-related conditions, such as ' +
                'anxiety, depression, or post-traumatic stress disorder in extreme cases.',
            imageUrl: '/img/you/actionable-you/stress/lifetime-stress.png'
        },
    ]
};

const GetStressed = () => {
    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__graph-section no-padding">
                <h4 className="indicator-section-title">{stressedData.title}</h4>
                <p className="indicator-section-description">{stressedData.text}</p>
                <div className="biological-age__stress-section">
                    {
                        stressedData.factors.map((item, index) => (
                            <div className="stress-container" key={index}>
                                <div className="stress-information-section">
                                    <h4 className="indicator-section-title inside-title">{item.title}</h4>
                                    <p className="indicator-section-description">{item.text}</p>
                                </div>
                                {
                                    item.imageUrl ?
                                        <div className="stress-image">
                                            <h4 className="indicator-section-title inside-title">&nbsp;</h4>
                                            <img src={item.imageUrl} alt={item.title.replace(/ /g, '-')} />
                                        </div> : ''
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default GetStressed;