import React, { useState } from 'react';
import { FitToFlyStep01 } from './FitToFlyStep01';
import { TestStep02 } from '../common/TestStep02';
import { FitToFlyStep03 } from './FitToFlyStep03';

export const FitToFly = ({ history, match }) => {
  const [availability, setAvailability] = useState();
  const [flightDate, setFlightDate] = useState();

  const step = match.params.step;
  if (step === '2') {
    return <TestStep02 history={history} testType="fit-to-fly" flightType="outbound" onAvailability={setAvailability} onFlightDate={setFlightDate} />;
  } else if (step === '3' && availability && flightDate) {
    return <FitToFlyStep03 history={history} availability={availability} flightDate={flightDate} />;
  } else {
    return <FitToFlyStep01 history={history} />;
  }
};
