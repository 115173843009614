import React from 'react';

export const TestSelection = ({ history }) => {
  const handleBack = () => history.goBack();
  const handleTestSelection = (value) => {
    history.push(`/dashboard/covid/test-timeline/${value}`);
  };
  return (
    <section className="test-dashboard-section content-wrapper-animate">
        <div className="test-dashboard-section__section_content centered">
            <img className="back-button" src="/img/back.svg" alt="" onClick={handleBack} />

            <div className="test-dashboard-section__container">
              <div className="content-container">
                <div className="section-title">
                  Testing timeline calculator
                </div>
                <div className="section-sub-title">
                  I want to have a Covid test for
                </div>
                <div className="item-selection" title="Test-to-release" onClick={() => handleTestSelection('test-to-release')}>
                  <input type="radio" name="text-selection" value="test-to-release" />
                  <div>
                    <div className="section-sub-title">
                      Test-to-release
                    </div>
                    <div className="item-tag">
                      For travellers returning to UK and want to reduce self-isolation period
                    </div>
                  </div>
                </div>

                <div className="item-selection" title="Fit-to-fly" onClick={() => handleTestSelection('fit-to-fly')}>
                  <input type="radio" name="text-selection" value="fit-to-fly" />
                  <div>
                    <div className="section-sub-title">
                      Fit-to-fly
                    </div>
                    <div className="item-tag">
                      For outbound international travelers to have a certificate for your destination 
                    </div>
                  </div>
                </div>

                <div className="item-selection" title="Standard COVID-19 test" onClick={() => handleTestSelection('covid')}>
                  <input type="radio" name="text-selection" value="covid" />
                  <div>
                    <div className="section-sub-title">
                      Standard COVID-19 test
                    </div>
                    <div className="item-tag">
                      Detect if you currently have COVID-19
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
  );
};
