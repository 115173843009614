import React from 'react';
import { StyledButton } from '../../../theming/themed-components';

export const Article = ({ history }) => {
  const handleBack = () => history.goBack();
  const handleBookTest = () => history.push('/dashboard/covid/test-timeline/select-test');
  return (
    <section className="article-section">
      <div className="section-content">
        <div className="article">
          <img className="back-button" src="/img/back.svg" alt="" onClick={handleBack} />
          <h3>Am I immune to COVID-19?</h3>
          <p className="tagline">And how long does immunity last?</p>
          <p>
            Immunity to COVID-19 or any infectious disease means that your immune system is capable of identifying the invading pathogen and
            neutralise it before it can cause harm. In the case of COVID-19 disease, this means your immune system has been trained to
            recognise the virus particle itself and stop it from entering our cells to replicate. Stopping the virus in its tracks also
            prevents our immune systems from misfiring in the particular way that COVID-19 disease manifests, thus protecting us from the
            worst. This doesn’t, however, mean that you are completely prevented from carrying and transmitting the virus to others, you’re
            just much less likely to get very sick.{' '}
          </p>
          <p>
            <strong>Training your immune system </strong>
          </p>
          <p>
            Our immune systems can be ‘trained’ either by past infection by the virus, or by a very controlled exposure to the most
            sensitive parts of the virus - vaccination! In each case immunity is not immediate, the response varies greatly from person to
            person and can take days or weeks to develop. In our immune system we have a type of cell called B-cells, we have billions of
            these cells each with slightly different pattern recognition receptors. Out of billions, a handful that happen to recognise the
            virus go on high alert. Over the next 7-10 days those few cells will clone themselves to huge numbers and begin producing
            antibodies that also recognise the virus.
          </p>
          <p>
            Antibody levels after infection vary significantly between people, depending on the severity of their COVID-19 illness and other
            factors. In some cases after several months they drop to below detectable levels, however we also know that cellular immunity is
            a vital and longer lasting component of immune memory against COVID-19. As with previous coronaviruses such as SARS and MERS,
            immunity may last several years or longer, and the latest research shows that re-infections are very rare for COVID-19.{' '}
          </p>
          <p>
            <strong>Precision guided immunity stops the virus in its tracks</strong>
          </p>
          <p>
            To get a sense of our immunity to COVID-19, we can hone in on a specific type of antibody that recognises the most critical part
            of the virus - the very part the vaccines are designed to train our immune systems to recognise. These are antibodies that
            recognise, bind to, and physically block the virus spike protein from attaching to our cells in the way it needs to trick its
            way inside. Blocking these spike proteins - literally the spiky bits you see on illustrations of SARS-CoV-2 - means the virus
            cannot replicate and cause havoc in our bodies.{' '}
          </p>
          <p>
            The Hurdle VacTrack immunity test is designed to measure these very special ‘anti-spike’ antibodies that offer the most
            protection against COVID-19 disease. You can also measure how levels change over time - particularly important as immunity takes
            time to develop and protection is well known to vary between people, and especially critical if your vaccine doses are not being
            given in the way they were tested in clinical trials - eg. the UK extending the Pfizer dose gap from 3 to 10 weeks.{' '}
          </p>
          <p>
            <strong>Vaccines will save us all, your immunity will save you</strong>
          </p>
          <p>
            Testing for immunity is an essential part of the vaccination journey. Overall we can be certain that the vaccine will protect a
            percentage of people from COVID-19, but will you be part of that percentage? Previous antibody based tests measured a less
            protective type of antibody, and just their presence or absence, only indicate that you’ve been previously exposed and not
            indicative of immunity. With VacTrack Anti-Spike testing Hurdle can help you get reassurance that you yourself, and those
            around you in your bubble or workplace, are protected from COVID-19.{' '}
          </p>

          <div className="button-container">
            <StyledButton className="btn btn--full-width" onClick={handleBookTest}>
              Book a test
            </StyledButton>
          </div>
        </div>
        <quote>
          “Antibody levels after infection vary significantly between people, depending on the severity of their COVID-19 illness and other
          factors. “
        </quote>
      </div>
    </section>
  );
};
