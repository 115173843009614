import React from 'react';
import PropTypes from 'prop-types';
import { RadialBarChart, PolarAngleAxis, RadialBar, ResponsiveContainer } from 'recharts';

const RenderCustomizedLabel = (props) => {
    const { count, total, label, value } = props;

    if(label && value) {
        return (
            <g>
                <text
                    x="50%"
                    y="52%"
                    fill="#444444"
                    fontSize="44px"
                    textAnchor="middle"
                >
                    {value}%
                </text>
                <text
                    x="50%"
                    y="65%"
                    fill="#9e9e9e"
                    fontSize="20px"
                    textAnchor="middle"
                >
                    {label}
                </text>
            </g>
        );
    }

    return (
        <g>
            <text
                x="35%"
                y="45%"
                fill="#444444"
                fontSize="30px"
                textAnchor="middle"
            >
                {count}
            </text>
            <text
                x="50%"
                y="55%"
                fill="#444444"
                fontSize="30px"
                textAnchor="middle"
            >/
            </text>
            <text
                x="65%"
                y="65%"
                fill="#444444"
                fontSize="30px"
                textAnchor="middle"
            >
                {total}
            </text>
        </g>
    );
};

const GaugeChart = ({data, fill, startAngle}) => {
    const endAngle = startAngle > 0 ? -(360 - startAngle) : (360 + startAngle);

    return (
        <ResponsiveContainer>
            <RadialBarChart
                innerRadius={80}
                outerRadius={100}
                barSize={10}
                data={data}
                startAngle={startAngle}
                endAngle={endAngle}
            >
                <PolarAngleAxis
                    type="number"
                    domain={[0, 100]}
                    angleAxisId={0}
                    tick={false}
                />
                <RadialBar
                    background
                    dataKey="value"
                    isAnimationActive={false}
                    cornerRadius={50}
                    fill={fill}
                    label={(props) => RenderCustomizedLabel(data[0])}
                />
            </RadialBarChart>
        </ResponsiveContainer>
    );
};

GaugeChart.defaultProps = {
    fill: '#5B7BFF',
    startAngle: 0,
};

GaugeChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number
    })),
    fill: PropTypes.string,
    startAngle: PropTypes.number,
};

export default GaugeChart;
