import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { formatDeltaAge } from './deltaAge';

const RANGE_VALUE_OPERATOR_REGEX = /(>=|<=|>|<)/g;

/**
 * Parses the given range values from an InterpretationRule, and returns a numerical
 * bound that represents it, using "min" and "max" parameters.
 *
 * @param {Array<string>} range
 * @returns {{ min: number, max: number }}
 */
const quantifyRangeBounds = range => {
  const initialValue = { min: Number.NEGATIVE_INFINITY, max: Number.POSITIVE_INFINITY };

  return range.reduce((result, value) => {
    const op = value.match(RANGE_VALUE_OPERATOR_REGEX)[0];
    const factor = Number(value.replace(RANGE_VALUE_OPERATOR_REGEX, ''));

    switch (op) {
      case '<=':
      case '<':
        return { min: result.min, max: Math.min(result.max, factor) };
      case '>=':
      case '>':
        return { min: Math.max(result.min, factor), max: result.max };
      default:
        return result;
    }
  }, initialValue);
};

/**
 * Keeps a number between two inclusive bounds, specified by "min" and "max".
 *
 * @param {number} x
 * @param {number} min
 * @param {number} max
 * @returns number
 */
const clamp = (x, min, max) => Math.min(Math.max(x, min), max);

/**
 * Returns the percentage factor of a given Delta Age, in the context of
 * bar rendering using the InterpretationRule ranges provided in input.
 *
 * @param {object} ranges
 * @param {number} deltaAge
 * @returns number
 */
const computeDeltaAgePercentageInRange = (ranges, deltaAge, interpretations) => {
  const start = interpretations.AVERAGE_RISK.from;
  const end = interpretations.AVERAGE_RISK.to;
  const diff = end - start;

  const { min, max } = quantifyRangeBounds(ranges.value.AVERAGE_RISK);
  const deltaAgeFactor = (deltaAge - min) / (max - min);

  const deltaAgePercentage = start + deltaAgeFactor * diff;

  return clamp(deltaAgePercentage, 0, 100);
};

/**
 * Computes the percentages of values to display in the bar.
 *
 * @returns {{ [string]: { rating: "good" | "normal" | "bad", from: number, to: number } }}
 */
const computeBarPercentages = interpretations => {
  // NOTE: right now the percentages are hardcoded in this object.
  // If we need to change the implementation to something different for the displaying,
  // we just need to change this function.
  return interpretations;
};

const MethylationGraph = ({ ranges, deltaAge, adjustedDeltaAge, labels, referenceLabel, barPercentages, interpretations }) => {
  const formattedDeltaAge = formatDeltaAge(adjustedDeltaAge);
  const tooltipPercentage = computeDeltaAgePercentageInRange(ranges, deltaAge, interpretations);
  const calendarAgePercentage = computeDeltaAgePercentageInRange(ranges, 0, interpretations);

  return (
    <div className="healthy-range__ranges-graph">
      <div className="methylation-graph__container">
        <div className="methylation-graph__bars">
          {/* The rendering section for the Tooltip in the Graph */}
          <div className="methylation-graph__point" style={{ left: `calc(${tooltipPercentage}% - 4px)` }}>
            <Tooltip
              arrow={true}
              arrowSize="small"
              html={<div className="methylation-graph__tooltip-text">You: {formattedDeltaAge}</div>}
              open={true}
              position="top"
              theme="light"
            >
              <div className="tooltip-child" />
            </Tooltip>
          </div>
          <div className="methylation-graph__result-point" style={{ left: `calc(${tooltipPercentage}% - 3px)` }} />
          {/* The rendering section for the bars in the Graph */}
          {Object.entries(barPercentages).map(([key, value]) => (
            <div
              className={`methylation-graph__bar ${value.rating ? value.rating : ''}`}
              key={key}
              style={{ width: `${value.to - value.from}%` }}
            />
          ))}
        </div>
        {/* The rendering section for legend of the bars in the Graph */}
        <div className="methylation-graph__labels">
          {labels.map(label => (
            <p key={label}>{label}</p>
          ))}
        </div>
      </div>
      {/* The rendering section for the calendar age line */}
      <div className="reference-line" style={{ left: `${calendarAgePercentage}%` }} />
      <div className="reference-label" style={{ left: `calc(${calendarAgePercentage}% - 55px)` }}>
        {referenceLabel}
      </div>
    </div>
  );
};

const DiseaseRiskBar = ({ title, description, labels, referenceLabel, ranges, deltaAge, adjustedDeltaAge, interpretations }) => {
  const barStyle = computeBarPercentages(interpretations);

  return (
    <div className="biological-age__graph-section no-padding">
      <div className="healthy-range__container">
        {title && <h3 className="indicator-section-title inside-title">{title}</h3>}
        {description && <p className="indicator-section-description">{description}</p>}

        <MethylationGraph
          ranges={ranges}
          deltaAge={deltaAge}
          adjustedDeltaAge={adjustedDeltaAge}
          labels={labels}
          referenceLabel={referenceLabel}
          barPercentages={barStyle}
          interpretations={interpretations}
        />

        <div className="healthy-range__legends">
          {Object.keys(ranges.value).map(key => {
            const interpretation = barStyle[key];

            return (
              interpretation && (
                <div className="single-legend" key={key}>
                  <div className={`legend-point ${interpretation.rating}`} />
                  <div className="legend-title">{interpretation.displayName}</div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

DiseaseRiskBar.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  referenceLabel: PropTypes.string.isRequired,
  interpretations: PropTypes.object.isRequired,
  ranges: PropTypes.shape({
    value: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  }).isRequired,
  deltaAge: PropTypes.number.isRequired,
  adjustedDeltaAge: PropTypes.number.isRequired,
};

export default DiseaseRiskBar;
