import React, { Component } from 'react';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Loader from '../common/loader';
import Card from './card';
import { StyledContainer, StyledHyperlink } from '../../theming/themed-components';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';
import { shouldDisplayFeature } from '../../services/partner-config/partner-config';
import { FEATURES } from '../../services/partner-config/features';
import { bloodResultSelector } from '../../services/you/selectors';
import { fetchBloodResults } from '../../services/you/actions';
import GeneralHealth from '../../containers/dashboard/generalHealth';
import { path } from '../../constants';

class Tests extends Component {
  displayCovid = shouldDisplayFeature(FEATURES.COVID);
  displayEpi = shouldDisplayFeature(FEATURES.EPIGENETIC);
  displayWholeGenome = shouldDisplayFeature(FEATURES.WHOLE_GENOME);

  constructor() {
    super();
    this.state = {
      epigeneticTests: null,
      wholeGenomeTests: null,
      covidTests: null,
      processingTests: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    axios
      .get('test_user/tests')
      .then((response = {}) => {
        const { epigenetic_results, processing, wholegenome_results } = response.data;
        this.setState({
          epigeneticTests: epigenetic_results,
          wholeGenomeTests: wholegenome_results,
          covidTests: response.data['covid-19_results'], //need bracket notation for hyphen
          processingTests: processing,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false }, () => {});
      });
    this.props.getBloodResults();
  }

  renderHeroSection() {
    return (
      <div className="result-hero__text-container">
        <div className="result-hero__information-section">
          <h1 className="result-hero__title study-hero__title">Your tests</h1>
        </div>
      </div>
    );
  }

  renderRegisterSection() {
    const { epigeneticTests, wholeGenomeTests, processingTests, covidTests } = this.state;

    const showRegisterAnotherTest =
      (epigeneticTests && epigeneticTests.length) ||
      (wholeGenomeTests && wholeGenomeTests.length) ||
      (covidTests && covidTests.length) ||
      (processingTests && processingTests.length);

    return (
      <StyledHyperlink href={`/register${getPartnerQueryParams()}`} className="tests__card-wrapper">
        <StyledContainer className="tests__card tests__card--register-card">
          <div className="tests__card-wrapper-header">
            <p className="tests__card-title large">
              {showRegisterAnotherTest ? 'Register another test' : 'Register test'}
              <i className="icon-right-open-big" />
            </p>
            <p className="tests__card-description extra-top-margin">
              Register <i className="icon-right-open-big" />
            </p>
          </div>
        </StyledContainer>
      </StyledHyperlink>
    );
  }

  renderBloodSection() {
    const { bloodData } = this.props;

    if (!bloodData?.data.length) {
      return null;
    }

    return <GeneralHealth match={{url: path.DASHBOARD}} tests={bloodData} displayHeader={false} />;
  }

  renderProcessingSection() {
    const { processingTests } = this.state;

    if (!(processingTests && processingTests.length)) {
      return null;
    }

    return (
      <div className="tests__card-wrapper">
        <h5 className="tests__card-wrapper-title">Processing</h5>
        {processingTests.map((item, index) => (
          <Card item={item} key={index} className="tests__card--no-shadow" showIndicators={false} />
        ))}
      </div>
    );
  }

  renderNoResults() {
    return (
      <div className="tests__card tests__card--no-result">
        <div className="tests__card-wrapper-header no-result">
          <p className="tests__card-title">No results found</p>
        </div>
      </div>
    );
  }

  renderEpigeneticSection() {
    const { epigeneticTests } = this.state;

    return (
      this.displayEpi && (
        <div className="tests__card-wrapper">
          <h5 className="tests__card-wrapper-title">Epigenetic Results</h5>
          {epigeneticTests && epigeneticTests.length
            ? epigeneticTests.map((item, index) => <Card item={item} key={index} link="/dashboard/you" showIndicators={true} />)
            : this.renderNoResults()}
        </div>
      )
    );
  }

  renderWholeGenomeSection() {
    const { wholeGenomeTests } = this.state;

    return (
      this.displayWholeGenome && (
        <div className="tests__card-wrapper">
          <h5 className="tests__card-wrapper-title">Whole Genome Results</h5>
          {wholeGenomeTests && wholeGenomeTests.length
            ? wholeGenomeTests.map((item, index) => <Card item={item} key={index} link="/dashboard/genetic" showIndicators={false} />)
            : this.renderNoResults()}
        </div>
      )
    );
  }

  renderCovidSection() {
    const { covidTests } = this.state;

    return (
      this.displayCovid && (
        <div className="tests__card-wrapper">
          <h5 className="tests__card-wrapper-title">COVID-19 Results</h5>
          {covidTests && covidTests.length
            ? covidTests.map((item, index) => <Card item={item} key={index} link="/dashboard/covid" showIndicators={false} />)
            : this.renderNoResults()}
        </div>
      )
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <section className="content-wrapper-animate">
        <section className="result-hero">{this.renderHeroSection()}</section>
        <section className="tests__container">
          {this.renderRegisterSection()}
          {this.renderBloodSection()}
          {this.renderProcessingSection()}
          {this.renderEpigeneticSection()}
          {this.renderWholeGenomeSection()}
          {this.renderCovidSection()}
        </section>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  bloodData: bloodResultSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getBloodResults: () => dispatch(fetchBloodResults()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(Tests);
