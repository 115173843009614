import React from 'react';

const epigeneticMetabolicStatusData = {
    title: 'How epigenetics is linked with diet',
    data: {
        title: '',
        text: 'DNA methylation is one of several epigenetic mechanisms that cells use to control gene expression. This process relies on key ingredients, including folate (F), methionine (M) and vitamin B12, which we get through a balanced diet. If we don’t have enough of these nutrients, our bodies can’t properly fuel these important metabolic cycles and so the risk of a poor metabolic status increases. For example, people with type 2 diabetes have reduced levels of folate in circulation.',
        image_url: '/img/you/actionable-you/metabolic-status/dna-melthylation-process.png',
        read_more_title: 'Read more about epigenetics in obesity and type 2 diabetes',
        read_more_link: 'https://www.sciencedirect.com/science/article/pii/S1550413119301378#bib101',
    }
};

const EpigeneticMetabolicStatus = ({breakpoint}) => {

    const { title, data } = epigeneticMetabolicStatusData;
    const isMobile = !(breakpoint == 'large' || breakpoint == 'medium');

    return (
        <section className="biological-age__overview-container">
            <section className="biological-age__graph-section no-padding">
                {title &&  <h4 className="indicator-section-title">{title}</h4>}
                <div className="biological-age__stress-section">
                    <div className="stress-container">
                        <div className="stress-information-section">
                            {data.title && <h5 className="indicator-section-title inside-title">{data.title}</h5>}
                            <p className="indicator-section-description">{data.text}</p>
                            {!isMobile && (data.read_more_title && data.read_more_link) &&
                                <p className="indicator-section-description"><a href={data.read_more_link} target="_blank" rel="noreferrer">{data.read_more_title}</a></p>
                            }
                        </div>
                        <div className={`stress-image ${data.image_url ? '' : ' show-background'}`}>
                            {
                                data.image_url ? <img src={data.image_url} alt={title.replace(/ /g, '-')} /> : ''
                            }
                        </div>
                        {isMobile && data.read_more_title && data.read_more_link &&
                            <p className="indicator-section-description no-margin"><a href={data.read_more_link} target="_blank" rel="noreferrer">{data.read_more_title}</a></p>
                        }
                    </div>
                    
                </div>
            </section>
        </section>
    );
};

export default EpigeneticMetabolicStatus;