import React, { Component } from 'react';
import Slider from 'rc-slider';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';

import Datamap from '../../../../components/lib/DataMap';

import 'rc-slider/assets/index.css';

const heatMapOptions = {
  // countries don't listed in dataset will be painted with this color
  fills: { defaultFill: '#E9E9E9' },
  data: null,
  geographyConfig: {
    borderColor: 'white',
    highlightBorderWidth: 1,
    // don't change color on mouse hover
    highlightFillColor: geo => geo['fillColor'] || '#E9E9E9',
    // only change border
    highlightBorderColor: 'white',
    // show desired information in tooltip
    popupTemplate: function(geo, data) {
      // don't show tooltip if country isn't present in dataset
      if (!data) {
        return;
      }
      // tooltip content
      return ['<div className="hoverinfo">', '<span>', geo.properties.name, '</span>', ' ', data.numberOfThings, '%', '</div>'].join('');
    },
  },
};

export default class WorldMap extends Component {
  constructor(props) {
    super(props);

    //index our data by variant and genotype so can retrieve easier
    const data = new Map();
    if (props.heatMapData && props.heatMapData.variants) {
      for (const variant of props.heatMapData.variants) {
        const genotypesMap = new Map();
        for (const genotype of variant.genotypes) {
          genotypesMap.set(genotype.genotype, genotype);
        }
        data.set(variant.rs_id, genotypesMap);
      }
    }

    const selectedVariant = props.heatMapData && props.heatMapData.variants ? props.heatMapData.variants[0].rs_id : null;
    const selectedGenotype = selectedVariant ? props.heatMapData.variants[0].genotypes[0].genotype : null;

    this.state = {
      data: data,
      selectedVariant: selectedVariant,
      selectedGenotype: selectedGenotype,
      mapSliderValue: props.heatMapData && props.heatMapData.you ? props.heatMapData.you : 100,
    };

    this.handelSliderChange = this.handelSliderChange.bind(this);
    this.handelGenotypeClick = this.handelGenotypeClick.bind(this);
  }

  getSliderOptions() {
    const { heatMapData } = this.props;

    const sliderMarks = {
      0: {
        style: {
          fontSize: '12px',
          color: '#9E9E9E',
          letterSpacing: '0.14px',
          textAlign: 'center',
          lineHeight: '18px',
          top: '20px',
          transform: 'translateX(-50%)',
          width: '50%',
          marginLeft: '0',
        },
        label: `${heatMapData.label_0}`,
      },
      100: {
        style: {
          fontSize: '12px',
          color: '#9E9E9E',
          letterSpacing: '0.14px',
          textAlign: 'center',
          lineHeight: '18px',
          top: '20px',
          transform: 'translateX(-50%)',
          width: '50%',
          marginLeft: '0',
        },
        label: `${heatMapData.label_100}`,
      },
    };

    return sliderMarks;
  }

  getHeatmapOptions() {
    const { selectedVariant, selectedGenotype } = this.state;
    const dataset = {};

    if (!selectedVariant || !selectedGenotype) {
      return null;
    }

    const options = heatMapOptions;
    options.data = dataset;

    return options;
  }

  handelGenotypeClick(value) {
    this.setState({ selectedGenotype: value });
  }

  handelSliderChange(value) {
    this.setState({ mapSliderValue: value });
  }

  render() {
    const { heatMapData } = this.props;
    const { data, selectedVariant, selectedGenotype } = this.state;
    const heatmapOptions = this.getHeatmapOptions();

    const genotypesItems = [];
    if (selectedVariant && data.has(selectedVariant)) {
      let alreadyHadYouClass = false;

      data.get(selectedVariant).forEach(item => {
        let itemClass = 'item';
        if (item.genotype === selectedGenotype) itemClass += ' active';

        if (item.is_your_genotype) {
          itemClass += ' you';
          alreadyHadYouClass = true;
        } else if (item.is_your_country_genotype && !alreadyHadYouClass) {
          itemClass += ' you';
        }

        genotypesItems.push(
          <a key={item.genotype} className={itemClass} onClick={() => this.handelGenotypeClick(item.genotype)}>
            {item.genotype}
          </a>,
        );

        if (item.is_your_genotype) {
          genotypesItems.push(
            <Tooltip key="you-help" title="This is your genotype from your genetics" position="bottom">
              <i className="icon-info-circled help-icon" />
            </Tooltip>,
          );
        } else if (item.is_your_country_genotype) {
          genotypesItems.push(
            <Tooltip key="country-help" title="This is the most common genotype found in your country" position="bottom">
              <i className="icon-info-circled help-icon" />
            </Tooltip>,
          );
        }
      });
    }

    return (
      <div className="biological-age__age-section">
        <div className="biological-age__section-information">
          <h3 className="indicator-section-title">{heatMapData.title}</h3>
          {heatMapData.text && <p className="indicator-section-information">{heatMapData.text}</p>}
          {heatMapData.text_info && <p className="indicator-section-information">{heatMapData.text_info}</p>}
        </div>

        <div className="heatmap-genotypes">Genotypes: {genotypesItems}</div>

        <div className="heatmap__container">
          <div className="heatmap__graph-section">
            <Datamap {...heatmapOptions} />
          </div>
          <div className="slider-container">
            <Slider
              defaultValue={100}
              min={0}
              max={100}
              step={1}
              marks={this.getSliderOptions()}
              disabled
              onChange={this.handelSliderChange}
              dotStyle={{
                width: '1px',
                border: 'none',
                borderRight: '1px solid #9E9E9E',
                height: '50px',
                top: '-18px',
                margin: 0,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

WorldMap.propTypes = {
  heatMapData: PropTypes.object,
};
