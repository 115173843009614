import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyledLink } from '../../theming/themed-components';

const KeyActions = ({ goals, match: { url }, hideTitle }) => {
  if (!(goals && goals.length)) {
    return null;
  }

  return (
    <div className="dashboard-section__section-container">
      {hideTitle ? '' : <h2 className="dashboard-section__sub-title">Improve - select a goal</h2>}
      <div className="dashboard-section__results-section">
        <div>
          {goals.map(item => {
            const { title, sub_title, icon_url, slug } = item;
            return (
              <Link className="dashboard-section__single-result" key={title.replace(' ', '-')} to={`${url}/improvements/goals/${slug}`}>
                <div className="recommendation-image">
                  <img alt={title} src={icon_url} />
                </div>
                <div className="recommendation-header">
                  <h5 className="result-title">{title}</h5>
                  <p className="recommendation-description" dangerouslySetInnerHTML={{ __html: sub_title }} />
                </div>
              </Link>
            );
          })}
        </div>
        <div className="result-button">
          <StyledLink className="btn" to={`${url}/improvements`}>
            View All Goals
          </StyledLink>
        </div>
      </div>
    </div>
  );
};

KeyActions.defaultProps = {
  recommendations: [],
  match: { url: '/dashboard' },
  hideTitle: false,
};

KeyActions.propTypes = {
  recommendations: PropTypes.array,
  match: PropTypes.object.isRequired,
  hideTitle: PropTypes.bool,
};

export default KeyActions;
