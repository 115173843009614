import React, { Component } from 'react';
import SliderBar, { createSliderWithTooltip } from 'rc-slider';
import PropTypes from 'prop-types';

import utils from '../helpers/utils';

import 'rc-slider/assets/index.css';

const SliderWithTooltip = createSliderWithTooltip(SliderBar);

class Slider extends Component {
    constructor() {
        super();

        this.state = {
            sliderMoved: false
        };

        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    onSliderChange(value) {
        const { onChange } = this.props;
        this.setState({sliderMoved: true});
        onChange(value);
    }

    getSliderOptions() {
        const { minValue, maxValue, defaultValue, showDefaultMark } = this.props;

        const marks = utils.defaultSliderMarks(minValue, maxValue);
        if(showDefaultMark && minValue !== defaultValue && maxValue !== defaultValue) {
            marks[defaultValue] = '';
        }

        return marks;
    }

    handleSliderChange(value) {
        const { onChange } = this.props;
        this.setState({sliderMoved: true});
        onChange(value);
    }

    tipFormatter(value) {
        const { defaultTooltip, defaultValue, units } = this.props;
        const { sliderMoved } = this.state;
        return (
            <div>{(value === defaultValue && defaultTooltip && !sliderMoved) ? defaultTooltip : value + ' ' + units}</div>
        );
    }

    render() {

        const { minValue, maxValue, marks, hideMarks, showTooltip, value, disabled, step, visibleTooltip, defaultValue, defaultTooltip } = this.props;
        const { sliderMoved } = this.state;

        if(showTooltip) {
            return (
                <SliderWithTooltip
                    defaultValue={defaultValue}
                    value={value}
                    onChange={(value) => this.handleSliderChange(value)}
                    onAfterChange={(value) => this.handleSliderChange(value)}
                    min={minValue}
                    max={maxValue}
                    marks={marks || (hideMarks ? {} : this.getSliderOptions())}
                    step={step ? step : 1}
                    disabled={disabled}
                    tipFormatter={(value) => this.tipFormatter(value)}
                    tipProps={{visible: visibleTooltip, prefixCls: `slider-tooltip ${(value === defaultValue && defaultTooltip && !sliderMoved) ? '' : 'active'} rc-slider-tooltip`}}
                />
            );
        }

        return (
            <div>
                <SliderBar
                    defaultValue={defaultValue}
                    value={value}
                    onChange={(value) => this.handleSliderChange(value)}
                    onAfterChange={(value) => this.handleSliderChange(value)}
                    min={minValue}
                    max={maxValue}
                    marks={marks || (hideMarks ? {} : this.getSliderOptions())}
                    step={step ? step : 1}
                    disabled={disabled}
                    handleStyle={[{backgroundColor: `${!sliderMoved && value === defaultValue ? '#444444' : '#5B7BFF'}`}]}
                />
            </div>
        );
    }
}

Slider.defaultProps = {
    value: 0,
    minValue: 0,
    maxValue: 100,
    hideMarks: false,
    showTooltip: false,
    disabled: false,
    visibleTooltip: true,
    defaultValue: 0,
    showDefaultMark: true,
    tooltipClass: ''
};

Slider.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    marks: PropTypes.object,
    hideMarks: PropTypes.bool,
    showTooltip: PropTypes.bool,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
    visibleTooltip: PropTypes.bool,
    defaultValue: PropTypes.number,
    showDefaultMark: PropTypes.bool,
    tooltipClass: PropTypes.string
};

export default Slider;