import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { Link } from 'react-router-dom';

import Footer from '../../containers/you/youPage/footer';

import Loader from '../common/loader';
import { StyledButton } from '../../theming/themed-components';

const wellness = [
    {
        name: 'Muscle Composition',
        slug: 'muscle-composition',
        icon_url: '/img/you/basic-you/traits/muscle-composition.svg'
    },
    {
        name: 'Genetic Weight',
        slug: 'genetic-weight',
        icon_url: '/img/you/basic-you/traits/genetic-weight.svg'
    },
    {
        title: 'View all',
        link: 'genetic/wellness',
        class: 'you__row-container--link',
    }
];

const traits = [
    {
        name: 'Sweet Or Salt Lover',
        slug: 'sweet-vs-salty',
        icon_url: '/img/you/basic-you/traits/sweet-vs-salty.svg',
    },
    /*{
        name: "Ancestry",
        slug: "ancestry",
        icon_url: "/img/you/basic-you/traits/ancestry.svg"
    },*/
    {
        name: 'Earwax Type',
        slug: 'earwax-type',
        icon_url: '/img/you/basic-you/traits/earwax-type.svg'
    },
    {
        title: 'View all',
        link: 'genetic/trait',
        class: 'you__row-container--link',
    }
];

class GenomePage extends Component {
    constructor() {
        super();

        this.handleNavigation = this.handleNavigation.bind(this);
        this.handelUnlockGenetic = this.handelUnlockGenetic.bind(this);
    }

    handleNavigation(navigateTo = '') {
        const { history, match } = this.props;

        if(typeof navigateTo === 'object') {
            history.push(navigateTo);
            return;
        }

        if(navigateTo) {
            history.push(`${match.url}/${navigateTo}`);
        }
    }

    handelUnlockGenetic(openChat) {
        openChat('I would like to upgrade to whole genome sequencing');
    }

    renderSection(title, sectionData, pageUrl, isLocked, helpText) {
        const { match: {url} } = this.props;
        return (
            <div className={`you__information-section ${isLocked ? 'hide-for-mobile' : ''}`}>
                <div className="you__row-container information-title skyblue">
                    {title}
                    {helpText &&
                    <Tooltip position="bottom" title={helpText}>
                        <i className="icon-info-circled help-icon form__help-icon"/>
                    </Tooltip>
                    }
                </div>
                {
                    sectionData && sectionData.map((item, index) => {
                        const pageLink = `${url}/${pageUrl}/${item.slug}`;
                        return (
                            <div className={`you__row-container ${item.class ? item.class : ''}`} key={index}>
                                {
                                    item.link ? <Link className="you-item-link" to={item.link}>{item.title}</Link> :
                                        <Link className="row-section" to={pageLink}>
                                            <div className="you-image-section">
                                                <img src={item.icon_url} alt={item.name} />
                                                <h3 className="title">{item.name}</h3>
                                            </div>
                                        </Link>
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    renderSections() {
        const { basic, isLoading, openChat } = this.props;

        if(isLoading) {
            return (
                <Loader />
            );
        }

        const locked = basic && basic.locked===false ? false : true;
        const status = basic && basic.status ? basic.status : false;

        return (
            <div>
                <div className={`you__overlay ${locked ? 'show' : ''}`}>
                    <div className="you__row-container information-title">Unique You</div>
                    <div className="you__section-container last-section">
                        {this.renderSection('Your health traits', wellness, 'wellness', false, 'These traits show how your genetic information influences how healthy your body is.')}
                        {this.renderSection('Your body traits', traits, 'trait', locked, 'These traits show how your genetic information makes you unique.')}
                    </div>
                    <div className="overlay-text-container">
                        <div className="overlay-text">
                            <i className="icon-lock-filled" />
                            {status ?
                                <p className="overlay-title">Processing Sample</p>

                                :
                                <a href={window.location.origin + '/join/account?slug=whole-genome'} type="wholegenome">
                                    <p className="overlay-title">Unlock your whole genome</p>
                                    <p className="overlay-information">Sequence all of the 3.2 billion positions in your genome to discover new health insights.</p>
                                    <StyledButton className="btn" onClick={()=>this.handelUnlockGenetic(openChat)}>Add now</StyledButton>
                                </a>
                            }
                        </div>
                    </div>
                </div>
                <Footer onSubmit={this.handleNavigation} search={{locked: locked}} />
            </div>
        );
    }

    render() {
        const { testUser } = this.props;
        const fname = testUser && testUser.fname || '';

        return (
            <section className="you__container">
                <div className="you-section__take-a-look-container">
                    <div className="you-section__take-a-look-text-container">
                        <h2>Here is your genome,{fname ? ` ${fname}` : ''}</h2>
                    </div>
                </div>
                {this.renderSections()}
            </section>
        );
    }
}

GenomePage.propTypes = {
    testUser: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
};

export default GenomePage;