import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AutoSuggest from './autoSuggest';
import qs from 'query-string';
import utils from '../helpers/utils';

const searchQueryMinimumLength = 2;

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldOpen: false,
            searchQuery: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.panelRef = React.createRef();
    }

    onChange(e) {
        this.setState({
            searchQuery: e.target.value
        });
    }

    onSelectItem(suggestion) {
        const { history } = this.props;
        this.setState({ searchQuery: suggestion.title }, () => {
            history.push({
                pathname: '/dashboard/you/search',
                search: qs.stringify({ q: this.state.searchQuery })
            });
        });
    }

    onSubmit(e) {
        e.preventDefault();
        const { history } = this.props;

        this.setState({ shouldOpen: false }, () => {
            history.push({
                pathname: '/dashboard/you/search',
                search: qs.stringify({ q: this.state.searchQuery })
            });
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate() {
        const searchBoxFormInput = document.querySelector('#search-box-form input');
        searchBoxFormInput && searchBoxFormInput.focus();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    panelRefElem() {
        return this.panelRef && this.panelRef.current;
    }

    handleClickOutside(e) {
        if (this.panelRefElem() && !this.panelRefElem().contains(e.target)) {
            this.setState({ shouldOpen: false });
        }
    }

    togglePanel() {
        if(!this.state.shouldOpen) {
            utils.track('Opened Search From Menu');
        }

        this.setState({ shouldOpen: !this.state.shouldOpen });
    }

    render() {
        const { title, hideHeader } = this.props;

        return (
            <div className="search-box__container" ref={this.panelRef}>
                <div onClick={this.togglePanel}>{title}</div>
                {
                    this.state.shouldOpen && (
                        <div className={`search-box__panel${hideHeader ? ' sticky-search' : ''}`}>
                            <div className="search-box__panel__container">
                                <form className="search-box__form" id="search-box-form" onSubmit={this.onSubmit}>
                                    <AutoSuggest
                                        className="search-box__input"
                                        placeholder="Search gene or variant"
                                        fetchUrl="test_user/search/autocomplete?q="
                                        onChange={this.onChange}
                                        onSelectItem={this.onSelectItem}
                                        searchQueryMinimumLength={searchQueryMinimumLength}
                                        value={this.state.searchQuery}
                                    />
                                    <button className="search-box__button" type="submit">
                                        <i id="search-box-icon" className="icon-search icon" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

SearchBox.defaultProps = {
    title: '',
    hideHeader: false,
};

SearchBox.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object,]),
    hideHeader: PropTypes.bool,
};

export default withRouter(SearchBox);
