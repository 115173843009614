import config from '../../../config';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';

// Navigate to dashboard for partner or hurdle users that are logged in.
// Do not show a link for logged out or affiliate users
export const getLinkUrl = (loggedInUser, branding) => {
  if (loggedInUser) {
    if (branding?.isAffiliate) {
      return undefined;
    }

    return '/dashboard';
  }

  return undefined;
};

// Navigate to login page for partner users that are logged out.
// Navigate to config defined page page for hurdle users that are logged out.
// Do not show a link for logged in or affiliate users
export const getLogoAbsoluteUrl = (loggedInUser, branding, isBranded) => {
  if (!loggedInUser) {
    if (branding?.isAffiliate) {
      return undefined;
    }

    if (isBranded) {
      return `/login${getPartnerQueryParams()}`;
    }

    return config.app.web_url;
  }

  return undefined;
};
