import React from 'react';
import RangeBar from './RangeBar';
import BoolBar from './BoolBar';

const ResultsBar = ({ result, interpretationData }) => {
  switch (interpretationData.rule) {
    case 'RANGE':
      return <RangeBar interpretationDataValue={interpretationData.value} result={result} />;
    case 'LEVEL':
      return <RangeBar interpretationDataValue={interpretationData.value} result={result} />;
    case 'QUALITATIVE':
      return <BoolBar interpretationDataValue={interpretationData.value} result={result} />;
    default:
      return null;
  }
};

export default ResultsBar;
