import axios from 'axios';
import qs from 'query-string';

export const get = (endPoint, params) => {
    return new Promise((resolve, reject) => {
        let url = endPoint;
        if(params && Object.keys(params).length) {
            const parameters = qs.stringify(params);
            url = url + '?' + parameters;
        }

        axios.get(url).then((res) => resolve(res)).catch((err) => reject(err));
    });
};

export const send = (endPoint, body) => new Promise((resolve, reject) => {
    axios.post(endPoint, body).then((res) => resolve(res)).catch((err) => reject(err));
});

export const del = (endpoint, config) => new Promise((resolve, reject) => {
    axios.delete(endpoint, config).then((res) => resolve(res)).catch((err) => reject(err));
});

export const put = (endpoint, body) => new Promise((resolve, reject) => {
    axios.put(endpoint, body).then((res) => resolve(res)).catch((err) => reject(err));
});