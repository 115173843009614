import axios from 'axios';
import config from '../../config';

const reverseLogisticApiUrl = config.app.reverse_logistics_api;
const defaultErrorMessage = 'Failed to fetch tracking urls';

const mapError = error => {
  const data = error.response?.data;
  return {
    errorResponse: error.response,
    error: { message: data?.message || defaultErrorMessage, code: data?.code },
  };
};

export const getTrackingUrls = async orderNumbers => {
  const authToken = localStorage.getItem('access_token');
  try {
    const response = await axios.get(`${reverseLogisticApiUrl}/tracking?orderNumbers=${JSON.stringify(orderNumbers)}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (e) {
    return mapError(e);
  }
};
