import React from 'react';

const calculationData = {
    title: 'More about metabolic status',
    text: 'Metabolic status is an accurate measure of health as it compares your epigenome with that of an obese person. The higher the result, the more likely it is that you have high adiposity (excess or abnormal fat), a disrupted metabolism and increased inflammation.',
    moreAccurate: {
        title: 'More accurate than BMI',
        text: 'It’s tricky to know the health risks that come with a disrupted metabolism and doctors often use BMI (body-mass index) to get an idea of our metabolic risk. Since BMI relies on height and weight it doesn’t take into account muscle and fat, which means the score can be misleading. Consider a rugby player – their dense muscle mass often makes them obese on the BMI scale despite them being very fit and healthy. Alternatively, there are people that appear skinny with a normal BMI but eat lots of fast food (mystifying, we know!).',
        text2: 'Calculating your metabolic status using epigenetic data measures your molecular BMI, giving you an accurate and personalised measure of your risk of developing metabolic-related diseases.',
        image_url: '/img/you/actionable-you/metabolic-status/more-accurate.png'
    },
};

const VsBMI = () => {

    const { title, text, moreAccurate, } = calculationData;
    
    return (
        <div className="biological-age__result-calculation__container">
            <h4 className="indicator-section-title">{title}</h4>
            <p className="indicator-section-description">{text}</p>
            <div className="biological-age__section-container">
                <div className="result-calculation-container">
                    <div className="result-calculation-section">
                        <div className="result-calculation-information">
                            <h5 className="indicator-section-title inside-title">{moreAccurate.title}</h5>
                            <p className="indicator-section-description">{moreAccurate.text}</p>
                        </div>
                        <div className="result-calculation-image">
                            <img alt={moreAccurate.title.replace(/ /, '-')} src={moreAccurate.image_url} />
                        </div>
                    </div>
                    {moreAccurate.text2 &&
                        <div className="result-calculation-section">
                            <p className="indicator-section-description">{moreAccurate.text2}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default VsBMI;