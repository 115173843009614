import React, { Component } from 'react';
import {Tooltip} from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DiseaseRisk from '../../../containers/you/common/diseaseRisk';

import Loader from '../../../components/common/loader';
import SubMenuBar from '../../../components/common/subMenuBar';

import { getComparison, setComparisonLoading } from '../../../services/you/actions';
import { comparisonSelector, youLoadingSelector } from '../../../services/you/selectors';

const navigation = [{ type: 'link', title: 'Compare', link: 'you/compare' }];

const RenderCircle = ({value, unit, text, className, isDelta}) => {
    let currentCircleClass = 'default';

    //Only set colour if its a delta...
    if(isDelta) {
        if(value < 0) currentCircleClass = 'gained';
        else if(value > 0) currentCircleClass = 'lost';
        else currentCircleClass = 'maintained';
    }

    return (
        <div className={`comparison-card__oval-section ${className ? className : ''}`}>
            <div className={`comparison-card__oval ${currentCircleClass} ${className ? className : ''}`}>
                <div className="content-container">
                    <p>{value}</p>
                    <p>{unit}</p>
                </div>
            </div>
            {text ? <div className="label">{text}</div> : ''}
        </div>
    );
};

class Comparisons extends Component {

    componentDidMount() {
        const { match: { params }, fetchComparison, setLoading } = this.props;
        const { id, section } = params;

        setLoading(true);
        fetchComparison(section, id);
    }

    renderComparison({you, other, resultUnitYou, resultUnitOther, isDelta}) {
        const { results: { youText, otherText } } = this.props;

        return (
            <div className="comparison-card__circles-container">
                {RenderCircle({value: you, unit: resultUnitYou, text: youText, isDelta: isDelta})}
                {RenderCircle({value: other, unit: resultUnitOther, text: otherText, isDelta: isDelta})}
            </div>
        );
    }

    renderDescriptions(descriptions) {
        if(!(descriptions && descriptions.length)) {
            return null;
        }

        return (
            <div className="comparison-card__descriptions-container">
                {
                    descriptions.map((item, index) => (
                        <div className="comparison-card__description" key={index}>
                            <div className="image-container">
                                {item.icon_url ? <img alt={`item-image-${index}`} src={item.icon_url} /> : RenderCircle({value: item.difference, unit: item.resultUnit, className: 'description-circle', isDelta: true})}
                            </div>
                            <div className="item-description">{item.text}</div>
                        </div>
                    ))
                }
            </div>
        );
    }

    renderComparisonCards(comparison) {
        if(!(comparison && comparison.length)) {
            return null;
        }

        return (
            <div className="compare__sections comparison-cards">
                {
                    comparison.map((item, index) => {
                        return (
                            <div className="comparison-card__container" key={index}>
                                <div className="comparison-card__information-section">
                                    <p className="card-title">{item.title}</p>
                                    <p className="card-information">{item.subText}</p>
                                </div>
                                {this.renderComparison({...item})}
                                {this.renderDescriptions(item.info)}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const { results, isLoading, match: { params } } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        const { title, introText, helpText, data, diseaseRisk } = results;
        const { section } = params;

        return (
            <div>
                <SubMenuBar
                    hideMenuBar={true}
                    navigation={navigation}
                    show={true}
                />
                <div className="compare__container">
                    <div className="compare__hero-section">
                        {title && <h2 className="title">{title}</h2>}
                        {introText &&
                            <div className="description">
                                {introText}
                                {helpText &&
                                    <Tooltip title={helpText} position="bottom">
                                        <i className="icon-info-circled help-icon form__help-icon"/>
                                    </Tooltip>
                                }
                            </div>
                        }
                    </div>
                    {this.renderComparisonCards(data)}
                    <div className="compare__disease-risk-section">
                        <DiseaseRisk
                            diseaseRisk={diseaseRisk}
                            slug={section}
                            classes={{
                                wrapperClass: 'comparison-risk-wrapper',
                                containerClass: 'comparison-risk-container',
                                cardClass: 'comparison-risk-card',
                                informationClass: 'comparison-risk-information'
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Comparisons.propTypes = {
    results: PropTypes.object,
    isLoading: PropTypes.bool,
    fetchComparison: PropTypes.func,
    setLoading: PropTypes.func,
};

const mapStateToProps = (state) => ({
    results: comparisonSelector(state),
    isLoading: youLoadingSelector(state, 'comparison'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchComparison: (section, id) => dispatch(getComparison(section, id)),
    setLoading: (value) => dispatch(setComparisonLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comparisons);
