import React from 'react';
import PropTypes from 'prop-types';

const Disclaimer = ({ className }) => (
    <div className={`disclaimer__container ${className}-disclaimer__container`}>
        <p className={`disclaimer__content ${className}-disclaimer__content`}>
            This report does not diagnose any health conditions or provide medical advice.
            Consult with a healthcare professional before making any major lifestyle changes or
            if you have any other concerns about your results.
        </p>
    </div>
);

Disclaimer.defaultProps = {
    className: '',
};

Disclaimer.propTypes = {
    className: PropTypes.string,
};

export default Disclaimer;
