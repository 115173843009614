import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { DEFAULT_THEME } from '../../constants/default-theme';
import { getPartnerBrandingRequest } from '../../services/branding/actions';
import { brandingSelector } from '../../services/branding/selectors';
import qs from 'query-string';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';
import Loader from '../../components/common/loader';
import { appendQueryStringForPartnerUsers, hasPartnerCodeInUrl, isInitPartnerRoute } from '../../../utils/appendQueryString';
import { datadogRum } from '@datadog/browser-rum';

// Handle special case when user is using our platform through our partner.
// In that scenario UI should be rendered only after the
// theme has been applied, so that user doesn't see default
// Hurdle theme while partner theme is being loaded. If it's not
// used through a partner, proceed as usual, ie set default theme
// through a ThemeProvider.
const PartnerTheme = props => {
  const dispatcher = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const stateBranding = useSelector(brandingSelector);
  const [shouldRender, setShouldRender] = useState(false);
  const [theme, setTheme] = useState(DEFAULT_THEME);

  // call only once to set route listener
  useEffect(() => {
    appendQueryStringForPartnerUsers(location, history);
  }, []);

  useEffect(() => {
    handleBrandingForPartner();
  }, [location]);

  useEffect(() => {
    if ((stateBranding?.hasBranding && !!cachedPartnerTheming()) || stateBranding?.error) {
      setShouldRender(true);
    }
    setTheme(stateBranding?.isAffiliate ? stateBranding : cachedPartnerTheming()?.theme || DEFAULT_THEME);
  }, [stateBranding]);

  const fetchPartnerTheme = () => {
    const { partnerCode, hideFooter, hideHeader } = qs.parse(location.search);

    // to add debugging information for HEX-780
    datadogRum.addAction('fetchPartnerBranding', {
      origin: window.location.origin,
      ...location,
    });

    dispatcher(getPartnerBrandingRequest({ partnerCode, hideFooter, hideHeader }));
  };

  const handleBrandingForPartner = () => {
    if (hasPartnerCodeInUrl(location)) {
      const hasTheme = stateBranding?.hasBranding && !!cachedPartnerTheming();
      setShouldRender(hasTheme);
      if (!hasTheme) {
        fetchPartnerTheme();
      }
      return;
    }

    // remove branding for localStorage if user is on landing page
    // and doesnt have partner code in query string
    if (isInitPartnerRoute(location)) {
      localStorage.removeItem('partnerBranding');
    }
    setShouldRender(true);
    setTheme(cachedPartnerTheming()?.theme || DEFAULT_THEME);
  };

  return shouldRender ? <ThemeProvider theme={theme}>{props.children}</ThemeProvider> : <Loader />;
};

export default PartnerTheme;
