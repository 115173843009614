import React from 'react';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';

const ageAffectorsSection = {
  title: 'What affects your biological age',
  text:
    'Diet, exercise, smoking, sleep, alcohol, stress or even access to good education can all affect your body’s age. For most of us, genetic predispositions only have a small influence.',
  affectors: [
    {
      title: 'Genetic predisposition',
      value: 15,
      icons: ['/img/you/actionable-you/biological-age/genetic-predisposition.svg'],
      unit: '%',
      key: 'genes',
    },
    {
      title: 'Lifestyle and environment',
      value: 85,
      icons: [
        '/img/you/actionable-you/biological-age/lifestyle-and-environment-1.svg',
        '/img/you/actionable-you/biological-age/bowl-with-vegetables.png',
        '/img/you/actionable-you/biological-age/lifestyle-and-environment-3.svg',
      ],
      unit: '%',
      label: 'Ageing influences',
      key: 'exercise',
    },
  ],
  factors: {
    title: 'How lifestyle and environmental factors affect your DNA ',
    text: 'You can’t turn back calendar years but making some changes can reduce your biological age and lower the risk of disease.',
  },
  twinSection: {
    title: 'Let’s look at an identical twin example',
    text:
      'We’ll call them James and John. While James is a non-smoker, eats healthily and exercises regularly, John’s a fan of beer, pizza and TV. ',
    description:
      'You can see that although James and John share the same genetics, their bodies are ageing at different rates. If John takes up some healthy habits his ageing trajectory could be more like James’. ',
    iconUrl: '/img/you/actionable-you/biological-age/twin-section.png',
  },
  calculate: {
    title: 'How we calculate your biological age',
    text:
      'Your biological age result is 100% derived from your DNA. How we live leaves biological marks on our DNA, known as epigenetic marks. We measure a specific type of epigenetic mark called DNA methylation. We analyze thousands of sites that were extracted from your saliva sample and identify which ones are related to your ageing process before applying machine learning models to map these complex patterns and derive your true biological age.',
    link: 'science',
  },
};

const RenderCustomizedLabel = ({ label }) => (
  <foreignObject width="180" height="180">
    <div className="gauge-label">{label}</div>
  </foreignObject>
);

const AgeAffectors = disableLink => {
  if (!ageAffectorsSection) {
    return null;
  }

  const { affectors, factors, twinSection, calculate } = ageAffectorsSection;

  return (
    <section>
      <div className="biological-age__graph-section no-padding">
        <div className="biological-age__age-affectors__container">
          <h4 className="indicator-section-title">{ageAffectorsSection.title}</h4>
          <p className="indicator-section-description">{ageAffectorsSection.text}</p>
          <div className="biological-age__age-affectors__ageing-influence">
            <div className="biological-age__age-affectors__gauge-chart">
              <ResponsiveContainer>
                <RadialBarChart innerRadius={80} outerRadius={100} barSize={10} data={[affectors[1]]} startAngle={90} endAngle={-270}>
                  <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                  <RadialBar
                    background
                    dataKey="value"
                    isAnimationActive={false}
                    cornerRadius={50}
                    fill="#5B7BFF"
                    label={() => RenderCustomizedLabel(affectors[1])}
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </div>
            <div className="biological-age__age-affectors__affectors-section">
              {affectors.map((affector, index) => {
                return (
                  <div className="biological-age__age-affectors__affector" key={index}>
                    <div className={`affector-title-section ${affector.key}`}>
                      <p className="affector-value">
                        {affector.value}
                        {affector.unit}
                      </p>
                      <p className="affector-title">{affector.title}</p>
                    </div>
                    <div className="affectors-container">
                      {affector.icons.map((icon, iconIndex) => {
                        return (
                          <div className="icon-container" key={iconIndex}>
                            <img alt={icon} src={icon} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="biological-age__graph-section no-padding">
        <div className="biological-age__age-affectors__container">
          <h4 className="indicator-section-title">{factors.title}</h4>
          <p className="indicator-section-description">{factors.text}</p>
          <div className="biological-age__age-affectors__section-container">
            <div className="biological-age__age-affectors__twin-section">
              <h4 className="indicator-section-title inside-title">{twinSection.title}</h4>
              <p className="indicator-section-description">{twinSection.text}</p>
              <div className="content-container">
                <div className="twin-image-section">
                  <img alt={twinSection.title} src={twinSection.iconUrl} />
                </div>
                <p className="indicator-section-description">{twinSection.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="biological-age__age-affectors__container">
          <div className="biological-age__age-affectors__section-container">
            <div className="biological-age__age-affectors__calculate-section">
              <h4 className="indicator-section-title inside-title">{calculate.title}</h4>
              <p className="indicator-section-description">{calculate.text}</p>
              {!disableLink ? (
                <Link to={calculate.link}>Read more about the science and epigenetics</Link>
              ) : (
                <Link to={'/dashboard/you/indicator/biological-age/science'}>Read more about the science and epigenetics</Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgeAffectors;
