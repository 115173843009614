import React, { Component } from 'react';
import PhoneNumberInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';

class PhoneInput extends Component {
    constructor(props) {
        super(props);
        const { countries, country } = props;

        let selected = '44';
        if(countries && countries.length && country !== 'GB') {
            const selectedCountry = countries.filter((item) => item.country_code == country);
            selected = selectedCountry && selectedCountry[0] && selectedCountry[0].dialing_code;
        }

        this.state = {
            selected
        };

        this.selectCountry = this.selectCountry.bind(this);
    }

    selectCountry(country) {
        const { countries, onChange } = this.props;
        const selectedCountry = countries.filter((item) => item.country_code == country);

        this.setState({selected: selectedCountry[0] && selectedCountry[0].dialing_code || '44'}, () => {
            onChange('mobile_country', this.state.selected);
        });
    }

    render() {
        const { value, onChange, countries, country, ...rest } = this.props;
        return (
            <div className="phone-input__container">
                <PhoneNumberInput
                    flagUrl="/img/country_flags/{xx}.svg"
                    value={value || ''}
                    defaultCountry={country}
                    onChange={(phone) => onChange('mobile', phone)}
                    onCountryChange={(country) => this.selectCountry(country)}
                    name="mobile"
                    id="mobile"
                    {...rest}
                />
            </div>
        );
    }
}

PhoneInput.defaultProps = {
    country: 'GB'
};

PhoneInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    countries: PropTypes.array.isRequired,
    country: PropTypes.string
};

export default PhoneInput;
