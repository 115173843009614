import React from 'react';
/*
const data = {
    title: "",
    text: "",
    text2: "",
    section: {
        title: "The effects of alcohol over time ",
        image_url: "",
        text: ""
    }
};
*/

const AlcoholFootprint = () => {
    //const { title, text, text2, section } = data;
    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__graph-section no-padding">
                <h4 className="indicator-section-title">More about alcohol footprint</h4>
                <p className="indicator-section-description">For many societies, alcohol is a part of everyday life. But whether people drink to celebrate, relax or to temporarily relieve stress, its effects can be harmful. Misuse of alcohol is responsible for 5.1% of the global burden of disease, with men being more affected. It’s also the leading risk factor for early death and disability among 15- to 49-year-olds.</p>
                <p className="indicator-section-description">But even if doctors know the dangers, they lack accurate ways to quantify this risk in a personalised way.  Alcohol footprint is a long-term cumulative measure of how much it looks like your body drinks and is more reliable than counting the number of alcoholic units consumed each week. It’s a unique insight that no other measures can provide.</p>

                <div className="biological-age__stress-section">
                    <h5 className="indicator-section-title inside-title">The effects of alcohol over time</h5>
                    <div className="stress-container">
                        <div className="stress-information-section">
                            <p className="indicator-section-description">Your alcohol footprint can go up after periods of heavy 
                            drinking, or down after a period of abstinence as the body attempts to repair itself. However, 
                            unlike a hangover, which for most of us will disappear within a day or two (thankfully), this 
                            epigenetic indicator will change slower, reflecting the true effect of alcohol on our bodies.
                            </p><br/>
                            <p className="indicator-section-description">For example, someone who rarely drinks now but used to drink heavily may still have a high alcohol footprint as their body is still recovering over time.</p>
                        </div>
                        <div className="stress-image">
                            <img src="/img/you/actionable-you/alcohol-consumption/alcohol-footprint.png" alt="The effects of alcohol over time" />
                            <div className="legends__container full-width">
                                <div className="legends__section">
                                    <div className="item-legend" />
                                    <p className="legend-title">Epigenetic alcohol footprint</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AlcoholFootprint;