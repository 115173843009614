export const mockUKAddresses = [{'addressline1':'Chronomics Ltd','addressline2':'1 St. James Court',
  'summaryline':'Chronomics Ltd, 1 St. James Court, Norwich, Norfolk, NR3 1RU',
  'organisation':'Chronomics Ltd','number':'1','premise':'1','street':'St. James Court',
  'posttown':'Norwich','county':'Norfolk','postcode':'NR3 1RU'}];

export const mockUSAddresses = [{'addressline1':'462 BROADWAY STE 524','addressline2':'NEW YORK NY  10013-2618',
  'summaryline':'462 BROADWAY STE 524, NEW YORK NY  10013-2618','subbuildingname':'STE 524',
  'number':'462','premise':'STE 524, 462','street':'BROADWAY','posttown':'NEW YORK','county':'NEW YORK',
  'state':'NEW YORK','stateabbreviation':'NY','postcode':'10013-2618','country':'UNITED STATES'}];

// Search with zip code 10013 in the US or postcode NR3 1RU in the UK to get a mock response back
const fetchMockAddresses = (country, postcode) => {
  if (window.CONFIG.env === 'production') return []; // Don't return mock results for production
  if (!country || !postcode) return [];
  if (country.toUpperCase() === 'GB' && postcode.toUpperCase() === 'NR3 1RU') return mockUKAddresses;
  if (country.toUpperCase() === 'US' && postcode === '10013') return mockUSAddresses;
  return [];
};

export const fetchAddresses = async (country, postcodeFilter) => {
  const { address_lookup_api_key } = window.CONFIG;

  // Use mock addresses if no API key is provided
  if (!address_lookup_api_key) {
    return Promise.resolve(fetchMockAddresses(country, postcodeFilter));
  }

  const response = await fetch(
    `https://ws.postcoder.com/pcw/${address_lookup_api_key}/address/${country}/` 
    + `${encodeURIComponent(postcodeFilter)}?format=json&lines=2&exclude=country`
  );

  return response.json();
};
