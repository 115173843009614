import React, { Component } from 'react';
import axios from 'axios';
import update from 'immutability-helper';
import moment from 'moment';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const AREA_TYPE = ['Urban', 'Suburban', 'Rural'];

export default class PreviousAddress extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = {
      countrys: {},
      fields: value.length ? value : [{ postcode: '', country: '', from_year: '', from_month: '', to_year: '', to_year: '', area: '' }],
    };

    this.handelOnChange = this.handelOnChange.bind(this);
    this.handelAddAnother = this.handelAddAnother.bind(this);
    this.handelRemover = this.handelRemover.bind(this);
  }

  componentDidMount() {
    axios.get('products/shiping_countrys').then(response => {
      this.setState({
        countrys: response.data.data,
      });
    });
  }

  handelOnChange(e, name, index) {
    const value = e.target.value;
    const { id, onChange } = this.props;

    const currentAddress = this.state.fields[index] ? this.state.fields[index] : {};
    currentAddress[name] = value;

    this.setState(update(this.state, { fields: { [index]: { $set: currentAddress } } }), () => {
      onChange(id, this.state.fields);
    });
  }

  handelAddAnother(e) {
    e.preventDefault();
    const currentAddress = { postcode: '', country: '', from_year: '', from_month: '', to_year: '', to_year: '', area: '' };

    this.setState(update(this.state, { fields: { [this.state.fields.length]: { $set: currentAddress } } }));
  }

  handelRemover(e) {
    const { id, onChange } = this.props;

    e.preventDefault();

    this.setState(update(this.state, { fields: { $splice: [[this.state.fields.length - 1, 1]] } }), () => {
      onChange(id, this.state.fields);
    });
  }

  render() {
    const { id } = this.props;
    const { countrys, fields } = this.state;

    const monthsHtml = [];
    for (let month = 0; month < MONTHS.length; month++) {
      monthsHtml.push(
        <option key={month + 1} value={month + 1}>
          {MONTHS[month]}
        </option>,
      );
    }

    const yearsHtml = [];
    for (let i = moment().format('YYYY'); i >= 1950; i--) {
      yearsHtml.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }

    const areaHtml = [];
    for (let area = 0; area < AREA_TYPE.length; area++) {
      areaHtml.push(
        <option key={area} value={AREA_TYPE[area]}>
          {AREA_TYPE[area]}
        </option>,
      );
    }

    const fieldsHtml = [];
    for (let i = 0; i < fields.length; i++) {
      fieldsHtml.push(
        <div key={i} className="questionnaire-previousaddress">
          <div>
            {i + 1}
            <input
              type="text"
              name={id + '_' + i + '_postcode'}
              id={id + '_' + i + '_postcode'}
              value={fields[i].postcode}
              onChange={e => this.handelOnChange(e, 'postcode', i)}
              className="postcode"
              placeholder="Post/Zip code"
            />

            <div className="form__select-wrapper questionnaire-previousaddress__select-wrapper">
              <select
                name={id + '_' + i + '_country'}
                id={id + '_' + i + '_country'}
                value={fields[i].country}
                onChange={e => this.handelOnChange(e, 'country', i)}
              >
                <option key="" value=""></option>
                {countrys.length &&
                  countrys.map((o, key) => (
                    <option key={key} value={o.country_code}>
                      {o.title}
                    </option>
                  ))}
              </select>
              <i className="icon-down-open"></i>
            </div>
          </div>

          <div className="questionnaire-previousaddress__date-wrapper">
            From:
            <div className="form__select-wrapper from">
              <select
                name={id + '_' + i + '_from_month'}
                id={id + '_' + i + '_from_month'}
                value={fields[i].from_month}
                onChange={e => this.handelOnChange(e, 'from_month', i)}
              >
                <option key="" value=""></option>
                {monthsHtml}
              </select>
              <i className="icon-down-open"></i>
            </div>
            <div className="form__select-wrapper from">
              <select
                name={id + '_' + i + '_from_year'}
                id={id + '_' + i + '_from_year'}
                value={fields[i].from_year}
                onChange={e => this.handelOnChange(e, 'from_year', i)}
              >
                <option key="" value=""></option>
                {yearsHtml}
              </select>
              <i className="icon-down-open"></i>
            </div>
            <span className="questionnaire-previousaddress__to">To:</span>
            <div className="form__select-wrapper from">
              <select
                name={id + '_' + i + '_to_month'}
                id={id + '_' + i + '_to_month'}
                value={fields[i].to_month}
                onChange={e => this.handelOnChange(e, 'to_month', i)}
              >
                <option key="" value=""></option>
                {monthsHtml}
              </select>
              <i className="icon-down-open"></i>
            </div>
            <div className="form__select-wrapper from">
              <select
                name={id + '_' + i + '_to_year'}
                id={id + '_' + i + '_to_year'}
                value={fields[i].to_year}
                onChange={e => this.handelOnChange(e, 'to_year', i)}
              >
                <option key="" value=""></option>
                {yearsHtml}
              </select>
              <i className="icon-down-open"></i>
            </div>
            {i == fields.length - 1 && i > 0 && (
              <a href="#" onClick={this.handelRemover}>
                Remove
              </a>
            )}
          </div>

          <div className="questionnaire-previousaddress__date-wrapper">
            Type of area:
            <div className="form__select-wrapper area">
              <select
                name={id + '_' + i + '_area'}
                id={id + '_' + i + '_area'}
                value={fields[i].area}
                onChange={e => this.handelOnChange(e, 'area', i)}
              >
                <option key="" value=""></option>
                {areaHtml}
              </select>
              <i className="icon-down-open"></i>
            </div>
          </div>
        </div>,
      );
    }

    return (
      <div>
        {fieldsHtml}
        <a href="#" onClick={this.handelAddAnother}>
          Add another
        </a>
      </div>
    );
  }
}
