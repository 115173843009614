import React from 'react';
import {Tooltip as ResultTooltip} from 'react-tippy';

import SplitConsumer from '../../../../components/context/splitConsumer';

import FeaturedFactors from '../../../../containers/you/common/featuredFactors';

const RenderImpactSection = (greatest_impact) => {
    if(!greatest_impact.data || !greatest_impact.data.length) {
        return null;
    }

    return (
        <div className="biological-age__impact-section">
            {greatest_impact.title ? <div className="improvement-section-title">{greatest_impact.title}</div> : ''}
            <div className="impact-items-container">
                {
                    greatest_impact.data.map((item, index) => {
                        return (
                            <div className="impact-item" key={index}>
                                <div className="impact-item-image">
                                    <ResultTooltip arrow={true} arrowSize="small" position="top" theme="light" title={item.title}>
                                        {item.icon_url ? <img alt={item.title} src={item.icon_url} /> : ''}
                                    </ResultTooltip>
                                </div>
                                <div className="impact-bar-container">
                                    <div className="impact-item-bar" style={{width: `${item.percent}%`}}>
                                        {item.percent > 80 ? <span>{item.percent}%</span> : ''}
                                    </div>
                                    {item.percent <= 80 ? <span className="outside">{item.percent}%</span> : ''}
                                </div>
                            </div>

                        );
                    })
                }
            </div>
        </div>
    );
};

const ResultImprovements = ({recommendations, slug, wrapperClass}) => {
    if(!recommendations || Array.isArray(recommendations)) {
        return null;
    }

    const {title, sub_title, greatest_impact, items} = recommendations;

    return (
        <SplitConsumer name="results_improvments" attributes={{slug: slug}}>
            {(value) => value == 'on' ?
                <div className={`biological-age__graph-section ${wrapperClass ? wrapperClass : ''}`}>
                    {greatest_impact && greatest_impact.data &&
                        <div className="biological-age__impact-container">
                            {RenderImpactSection(greatest_impact)}
                        </div>
                    }
                    {items && (items.length > 0) &&
                        <div className="biological-age__factors-wrapper">
                            {title && <h4 className="indicator-section-title">{title} <sup>beta</sup></h4>}
                            {sub_title && <p className="indicator-section-description">{sub_title}</p>}
                            <FeaturedFactors items={items} />
                        </div>
                    }
                </div> : ''
            }
        </SplitConsumer>
    );
};

export default ResultImprovements;