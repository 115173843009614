import React, { Component } from 'react';
import update from 'immutability-helper';

export default class Checkbox extends Component {
    constructor(props) {
        super(props);

        const {hasOtherOption, choices, value} = this.props;

        const checked = {};
        let otherText = '';
        for(const x in choices) {
            checked[x] = value[choices[x].value] ? true : false;
        }
        if(hasOtherOption) {
            checked['other'] = value['other'] && value['other'] !== '' ? true : false;
            otherText = value['other'];
        }

        this.state = {
            checked: checked,
            otherText: otherText,
        };

        this.handelOnChange = this.handelOnChange.bind(this);
        this.handelOnOtherTextChange = this.handelOnOtherTextChange.bind(this);
    }

    handelOnChange(e, index) {
        const isChecked = e.target.checked;
        this.setState(update(this.state, {checked: {[index]: {$set: isChecked}}}));

        e.target.checkboxName = e.target.value;
        this.props.onChange(e);
    }

    handelOnOtherTextChange(e) {
        //Set to checked
        this.setState(update(this.state, {checked: {['other']: {$set: true}}}));

        //Fake checkbox update of this text
        const fakeEvent = {
            target: {
                name: this.props.id,
                type: 'checkbox',
                checked: true,
                checkboxName: 'other',
                value: e.target.value,
            }
        };
        this.props.onChange(fakeEvent);
    }

    render() {
        const {id, isRequired, hasOtherOption, choices, value, onChange} = this.props;
        const {checked, otherSelected} = this.state;

        const options = choices.map((choice, index) => {
            return (
                <div key={id+'_'+index}>
                    <input
                        type="checkbox"
                        name={id}
                        id={id+'_'+index}
                        checked={checked[index]}
                        value={choice.value}
                        onChange={(e) => this.handelOnChange(e,index)}
                    />
                    <label htmlFor={id+'_'+index}>{choice.value}</label>
                </div>
            );
        });

        return (
            <div className="custom__checkbox-container">
                {options}
                {hasOtherOption &&
                    <div>
                        <input
                            type="checkbox"
                            name={id}
                            id={id+'_other'}
                            checked={checked['other'] === true}
                            onChange={(e) => this.handelOnChange(e,'other')}
                        />
                        <label htmlFor={id+'_other'}>Other</label>
                        {
                            checked['other'] === true ?
                                <input
                                    type="text"
                                    name={id}
                                    onChange={(e) => this.handelOnOtherTextChange(e)}
                                    value={value['other'] ? value['other'] : ''}
                                    className="questionnaire-form__other-text"
                                    autoFocus={true}
                                /> : ''
                        }
                    </div>
                }
            </div>
        );
    }
}