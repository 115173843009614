import PropTypes from 'prop-types';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { lightBlue } from '../../../components/common/resultsBar/barColors';

const CustomizedLabel = ({ label }) => (
  <foreignObject width="180" height="180">
    <div className="gauge-label">{label}</div>
  </foreignObject>
);

const AgeAffectors = ({ title, text, affectors, factors, twinSection, calculate }) => (
  <section>
    <div className="biological-age__graph-section no-padding">
      <div className="biological-age__age-affectors__container">
        <h4 className="indicator-section-title">{title}</h4>
        <p className="indicator-section-description">{text}</p>

        <div className="biological-age__age-affectors__ageing-influence">
          <div className="biological-age__age-affectors__gauge-chart">
            <ResponsiveContainer>
              <RadialBarChart innerRadius={80} outerRadius={100} barSize={10} data={[affectors[1]]} startAngle={90} endAngle={-270}>
                <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                <RadialBar
                  background
                  dataKey="value"
                  isAnimationActive={false}
                  cornerRadius={50}
                  fill={lightBlue}
                  label={() => CustomizedLabel(affectors[1])}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>

          <div className="biological-age__age-affectors__affectors-section">
            {affectors.map((affector, index) => {
              return (
                <div className="biological-age__age-affectors__affector" key={index}>
                  <div className={`affector-title-section ${affector.key}`}>
                    <p className="affector-value">
                      {affector.value}
                      {affector.unit}
                    </p>
                    <p className="affector-title">{affector.title}</p>
                  </div>
                  <div className="affectors-container">
                    {affector.icons.map((icon, iconIndex) => {
                      return (
                        <div className="icon-container" key={iconIndex}>
                          <img alt={icon} src={icon} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>

    <div className="biological-age__graph-section no-padding">
      <div className="biological-age__age-affectors__container">
        <h4 className="indicator-section-title">{factors.title}</h4>
        <p className="indicator-section-description">{factors.text}</p>

        <div className="biological-age__age-affectors__section-container">
          <div className="biological-age__age-affectors__twin-section">
            <h4 className="indicator-section-title inside-title">{twinSection.title}</h4>
            <p className="indicator-section-description">{twinSection.text}</p>
            <div className="content-container">
              <div className="twin-image-section">
                <img alt={twinSection.title} src={twinSection.iconUrl} />
              </div>
              <p className="indicator-section-description">{twinSection.description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="biological-age__age-affectors__container">
        <div className="biological-age__age-affectors__section-container">
          <div className="biological-age__age-affectors__calculate-section">
            <h4 className="indicator-section-title inside-title">{calculate.title}</h4>
            <p className="indicator-section-description">{calculate.text}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

AgeAffectors.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  affectors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      icons: PropTypes.arrayOf(PropTypes.string).isRequired,
      unit: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  factors: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  twinSection: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }).isRequired,
  calculate: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default AgeAffectors;
