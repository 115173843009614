import React from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';

const CompareGraph = ({data, labels, showAnalysisImage}) => {
    if(!(data && Object.keys(data).length)) {
        return null;
    }

    const { count, analysis_text, low_label, high_label } = data;

    return (
        <div className="comparison__graph-container stress-section">
            {
                count ?
                    <div className="graph-section-container stress-compare-graph">
                        <div className="label-section">
                            <div className="compare-label left">
                                <img alt="left-label" src={labels.left} />
                            </div>
                            {low_label ? <p className="label-title">{low_label}</p> : ''}
                        </div>
                        <div className="compare-graph-bar-container">
                            <div className="methylation-graph__point" style={{left: `${count}%`}}>
                                <Tooltip
                                    arrow={true}
                                    arrowSize="small"
                                    html={<div className="methylation-graph__tooltip-text">You</div>}
                                    open={true}
                                    position="top"
                                    theme="light"
                                >
                                    <div className="tooltip-child" />
                                </Tooltip>
                            </div>
                            <div className="methylation-graph__result-point" style={{left: `calc(${count}% + 1px)`}} />
                        </div>
                        <div className="label-section">
                            <div className="compare-label right">
                                <img alt="right-label" src={labels.right} />
                            </div>
                            {high_label ? <p className="label-title">{high_label}</p> : ''}
                        </div>
                    </div> : ''
            }
            {
                analysis_text ?
                    <div className="analysis-text analysis-text--stress">
                        {
                            showAnalysisImage ?
                                <div className="comparison__analysis-section">
                                    <div className="analysis-image">
                                        <img alt="analysis-placeholder" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                                    </div>
                                    <div className="comparison-analysis-text">{analysis_text}</div>
                                </div> : analysis_text
                        }
                    </div> : ''
            }
        </div>
    );
};

CompareGraph.defaultProps = {
    labels: {
        left: '/img/you/actionable-you/biological-age/compare-good-label.png',
        right: '/img/you/actionable-you/biological-age/compare-bad-label.png'
    },
    showAnalysisImage: true
};

CompareGraph.propTypes = {
    data: PropTypes.object,
    labels: PropTypes.object,
    showAnalysisImage: PropTypes.bool,
};

export default CompareGraph;
