import React from 'react';

import ImageComponent from '../../../containers/you/common/imageMapping';

const RenderPointSection = (you, breakpoint) => {
  const { label, percent } = you;

  const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');

  return (
    <div>
      <div className="test-tooltip-container" style={{ left: `calc(${percent}% - ${isMobile && percent < 10 ? '40px' : '60px'})` }}>
        {Object.keys(label).map(key => {
          if (!label[key]) {
            return null;
          }

          let keyPlaceholder = '';
          if (key == 'trending') {
            keyPlaceholder = <ImageComponent name={`${label[key].direction}-arrow`} />;
          } else if (key == 'previous') {
            if (label[key].text == 'No data') {
              keyPlaceholder = <div className="" />;
            } else {
              keyPlaceholder = <div className="tooltip-arrow" />;
            }
          } else if (key == 'current') {
            //keyPlaceholder = <div className="tooltip-arrow tooltip-arrow--current" />
            keyPlaceholder = <div className="" />;
          }

          return (
            <div className="tooltip-section" key={key}>
              {keyPlaceholder ? (
                <div className="test-keys" data-private>
                  <span>{key}</span>
                  {keyPlaceholder}
                </div>
              ) : (
                ''
              )}
              <p className={`test-title ${key == 'trending' ? label[key].direction : ''} ${key == 'current' ? 'current' : ''}`}>
                {label[key].text}
              </p>
            </div>
          );
        })}
        {/*!(you_previous && you_previous.percent) ? <p className="test-description">No previous test</p> : ""*/}
      </div>
      <div className="test-point-container" style={{ left: `calc(${percent}% - 1px)` }}>
        <div className="tooltip-child" />
      </div>
    </div>
  );
};

const RenderPreviousPoint = you_previous => {
  if (!(you_previous && you_previous.percent)) {
    return null;
  }

  const { percent } = you_previous;

  return <div className="test-point-container arrow" style={{ left: `${percent}%` }} />;
};

const RenderHighlightedArea = ({ you, you_previous }) => {
  if (!(you_previous && you_previous.percent && you && you.percent)) {
    return null;
  }

  const maxValue = Math.max(you_previous.percent, you.percent);
  const minValue = Math.min(you_previous.percent, you.percent);
  const showHighlightedArea = { width: maxValue - minValue, left: minValue };

  return (
    <div>
      <div className="line-overlay" style={{ width: `${minValue}%`, left: 0 }} />
      <div className="highlighted-line" style={{ width: `${showHighlightedArea.width}%`, left: `${showHighlightedArea.left}%` }} />
      <div className="line-overlay" style={{ width: `${100 - maxValue}%`, left: `${maxValue}%` }} />
    </div>
  );
};

const RenderArrow = label => {
  if (!(label && label.trending)) {
    return <div className="trending-arrow">No change</div>;
  }

  return (
    <div className="trending-arrow">
      <ImageComponent name={`${label.trending.direction}-arrow`} />
    </div>
  );
};

const TestLineGraph = ({ data, breakpoint }) => {
  if (!data) {
    return null;
  }

  const { range, you, you_previous } = data;
  return (
    <div className="you-page-v3__test-line">
      {RenderArrow(you && you.label)}
      <div className="methylation-graph__bars">
        {RenderPointSection(you, breakpoint)}
        {RenderPreviousPoint(you_previous)}
        {RenderHighlightedArea({ you, you_previous })}
        {range.map((item, index) => (
          <div
            className={`methylation-graph__bar ${item.rating ? item.rating : ''} ${
              item.in_range || (you_previous && you_previous.percent) ? '' : 'inactive'
            }`}
            key={index}
            style={{ width: `${item.max_percent - item.min_percent}%` }}
          />
        ))}
      </div>
    </div>
  );
};

export default TestLineGraph;
