import React, { Component } from 'react';
import utils from '../helpers/utils';
//import {Link } from "react-router-dom";
import { Link } from 'react-router-dom';
import { StyledHyperlinkWrapper } from '../../theming/themed-components';

const CheckBrowserCompatibility = () => (
    <section className="dashboard-section content-wrapper-animate">
        <section className="dashboard-hero">
            <div className="dashboard-hero__text-container">
                <h3 className="dashboard-hero__title">Sorry this browser is not supported.</h3>
                <p>Please upgrade</p>
            </div>
        </section>
    </section>
);

export default (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };

        this.reloadPage = this.reloadPage.bind(this);
    }

    reloadPage() {
        window.location.reload();
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error});
    }

    render() {
        const { error } = this.state;

        if(error) {
            const { response = {} } = error;

            const errorMessage = <p>{utils.getFirstApiError(error, 'We have been notified and are looking into it.')}</p>;

            if (utils.isIEBrowser()) {
                return <CheckBrowserCompatibility />;
            }

            return (
                <section className="dashboard-section content-wrapper-animate">
                    <section className="dashboard-hero">
                        {response.data && response.data.error && response.data.error.errors &&
                            response.data.error.errors.no_questionnaires_complete ?
                            <div className="dashboard-hero__text-container">
                                <h3 className="">You must complete My Story</h3>

                                {errorMessage}

                                <br/>
                                <p><Link to="/dashboard/onboarding" className="btn">My Story</Link></p>
                            </div>
                            :
                            <div className="dashboard-hero__text-container">
                                <h3 className="">Oops, something went wrong...</h3>

                                {errorMessage}

                                <br/>
                                <p><StyledHyperlinkWrapper href="" onClick={this.reloadPage} className="btn">Reload page</StyledHyperlinkWrapper></p>
                            </div>
                        }
                    </section>
                </section>
            );
        }

        return <WrappedComponent {...this.props}/>;
    }
};
