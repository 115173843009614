import React, { Component, Fragment } from 'react';

import config from '../../../config';

const Checkbox = ({ label = '', id = '', ...props }) => (
    <Fragment>
        <input type="checkbox" id={id} {...props} />
        <label htmlFor={id}>{label}</label>
    </Fragment>
);

class DataSharePreferences extends Component {

    componentDidMount() {
        this.props.getCoachPermissions(this.props.id, this.props.has_selected);
    }

    handelChange = (name) => {
        const { permissions, updatePermissions, id } = this.props;

        if(!permissions.has(name)) {
            updatePermissions(id, name);
        }
    };

    render() {
        const { coachPermissions, id, has_selected, category, fname, toggleCoachKey, permissions, accepted_terms, addCoach } = this.props;
        const buttonText = has_selected ? 'Save' : 'Add to team';

        return (
            <Fragment>
                <i className="icon-cancel locked" onClick={() => toggleCoachKey(id, 'showDataPreference')} />
                <p className="card__data-share-preferences__description">
                    Select which data you want to share with {fname}.
                </p>
                <div className="card__data-share-preferences__switch-container">
                    {Object.entries(coachPermissions).map(([ permission, label ]) => {
                        const permissionLabel = permission === 'required' ? `Required data for ${category.title}` : label;

                        return (
                            <p key={permission}>
                                <Checkbox
                                    checked={permissions.has(permission)}
                                    className="improvement-switch"
                                    id={`${permission}-${id}`}
                                    label={permissionLabel}
                                    name={permission}
                                    onChange={() => this.handelChange(permission)}
                                />
                            </p>
                        );
                    })}
                </div>
                {accepted_terms ? '' :
                    <div className="terms__checkbox-container">
                        <input
                            checked={accepted_terms}
                            id="agree"
                            onChange={() => toggleCoachKey(id, 'accepted_terms')}
                            type="checkbox"
                        />

                        <label htmlFor="agree">
                            Please confirm that you have read our <a href={`${config.app.web_url}/terms-conditions`} target="_blank" rel="noreferrer">Terms and Conditions</a> and consent to sharing your data with this individual.
                        </label>
                    </div>
                }
                <button
                    className="btn btn--pale-orange"
                    disabled={!(accepted_terms && permissions)}
                    onClick={() => addCoach(id)}
                >
                    {buttonText}
                </button>
            </Fragment>
        );
    }
}

export default DataSharePreferences;
