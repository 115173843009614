import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SplitConsumer from '../../../components/context/splitConsumer';

class DiseaseRisk extends Component {
    constructor() {
        super();

        this.state = {
            isExpanded: false
        };

        this.toggleAccordion = this.toggleAccordion.bind(this);
    }

    toggleAccordion() {
        this.setState((prev) => ({isExpanded: !prev.isExpanded}));
    }

    renderDiseases() {
        const { diseaseRisk: {diseases}, classes } = this.props;

        if(!(diseases && diseases.length)) {
            return null;
        }

        return (
            <div className={`disease-risk__diseases-section ${classes.containerClass ? classes.containerClass : ''}`}>
                {
                    diseases.map((disease, index) => {
                        const { title, text, link_url, icon_url, link_title } = disease;
                        return (
                            <div className={`disease-risk__disease-card ${classes.cardClass ? classes.cardClass : ''}`} key={index}>
                                {
                                    (title || icon_url) ?
                                        <div className="header-section">
                                            {title ? <h5 className="disease-title">{title}</h5> : ''}
                                            {icon_url ? <div className="disease-image"><img alt={disease.title} src={icon_url} /></div> : ''}
                                        </div> : ''
                                }
                                {text ? <p className="disease-description">{text}</p> : ''}
                                {(link_url && link_title) ? <a className="disease-read-link" href={link_url} target="_blank" rel="noreferrer">{link_title}</a> : ''}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    renderAnalysis() {
        const { analysis } = this.props.diseaseRisk;

        if(!analysis) {
            return null;
        }

        const { title, text } = analysis;
        return (
            <div className="disease-risk__analysis-section">
                {title ? <h3 className="indicator-section-title">{title}</h3> : ''}
                {text ? <p className="analysis-description">{text}</p> : ''}
            </div>
        );
    }

    renderEpigenetics() {
        return (
            <div className="disease-risk__epigenetics-section">
                <img alt="epigenetics" src="/img/you/actionable-you/daniel-herranz.png" />
                <p className="epigenetic-title">Dr. Daniel E. Martín Herranz Epigenetics expert</p>
            </div>
        );
    }

    renderDevelopSection() {
        const { how_develop } = this.props.diseaseRisk;
        const { isExpanded } = this.state;

        if(!how_develop) {
            return null;
        }

        const { title, text } = how_develop;

        const descriptionClasses = `develop-description ${isExpanded ? 'active' : ''}`;

        return (
            <div className="disease-risk__develop-section" onClick={this.toggleAccordion}>
                <header className="header-section">
                    <p className="header-title">{title}</p>
                    <i className={isExpanded ? 'icon-up-open' : 'icon-down-open'} />
                </header>
                <div className={descriptionClasses} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        );
    }

    render() {
        const { diseaseRisk, slug, classes } = this.props;
        if(!diseaseRisk || Array.isArray(diseaseRisk)) {
            return null;
        }

        const { title, text } = diseaseRisk;

        return (
            <SplitConsumer name="results_diseases" attributes={{slug: slug}}>
                {(value) => value == 'on' ?
                    <div className="biological-age__disease-risk-container">
                        <div className={`disease-risk__container ${classes.wrapperClass ? classes.wrapperClass : ''}`}>
                            <div className={classes.informationClass ? classes.informationClass : ''}>
                                {title ? <h3 className="indicator-section-title">{title}</h3> : ''}
                                {text ? <p className="indicator-section-description">{text}</p> : ''}
                            </div>
                            {this.renderDiseases()}
                            {/*this.renderAnalysis()*/}
                            {/*{this.renderEpigenetics()}*/}
                            {this.renderDevelopSection()}
                        </div>
                    </div>: ''
                }
            </SplitConsumer>
        );
    }
}

DiseaseRisk.defaultProps = {
    classes: {
        wrapperClass: '',
        containerClass: '',
        cardClass: '',
        informationClass: ''
    },
    diseaseRisk: {},
    slug: ''
};

DiseaseRisk.propTypes = {
    diseaseRisk: PropTypes.any,
    classes: PropTypes.shape({
        wrapperClass: PropTypes.string,
        containerClass: PropTypes.string,
        cardClass: PropTypes.string,
        informationClass: PropTypes.string,
    }),
    slug: PropTypes.string,
};

export default DiseaseRisk;