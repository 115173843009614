import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withError from '../../components/common/withError';
import Loader from '../../components/common/loader';
import { getOrders } from '../../services/you/actions';
import withErrorBoundary from '../../components/common/errorBoundary';
import { ordersSelector } from '../../services/you/selectors';
import OrderStatus from '../../components/order/orderStatus';
import utils from '../../components/helpers/utils';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

const Orders = function Orders(props) {
  useEffect(() => {
    props.fetchOrders();
  }, []);

  if (!props.orders) {
    return <Loader />;
  }

  const featuredOrders = [];

  props.orders.data.forEach(order => {
    //Put everything onto featured for now...
    featuredOrders.push(order);
  });

  const sortedOrders = Array.isArray(featuredOrders) ? featuredOrders.sort((a, b) => a.created_at - b.created_at) : featuredOrders;
  if (Array.isArray(sortedOrders)) {
    sortedOrders.forEach((item, index) => {
      if (item.is_bundle_parent) {
        const findIndex = sortedOrders.findIndex(order => order.parent_id === item.id);
        utils.arrayMove(sortedOrders, index, findIndex);
      }
    });
  }

  return (
    <section className="orders-section__container">
      {sortedOrders.length > 0 ? (
        <div>
          <h2 className="orders-section__sub-title">Order history</h2>

          {!cachedPartnerTheming() && (
            <p>
              For any questions please visit our{' '}
              <a href="https://help.chronomics.com/" target="_blank" rel="noopener noreferrer">
                Help Portal
              </a>
              .
            </p>
          )}
          <div>
            {sortedOrders.map(order => (
              <OrderStatus key={order.order_number} order={order} />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <h2 className="orders-section__sub-title">Order history</h2>
          <p>No current orders</p>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = state => ({
  orders: ordersSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOrders: () => dispatch(getOrders()),
});

const enhance = compose(withErrorBoundary, connect(mapStateToProps, mapDispatchToProps), withError({ reducer: 'you', page: 'you' }));

export default enhance(Orders);
export { Orders as __TestOrders };
