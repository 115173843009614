import React, { Component } from 'react';
import { withTheme } from 'styled-components';

class DotGraph extends Component {
  constructor(props) {
    super(props);

    const latestTest = props.tests_timeline && props.tests_timeline.findIndex(test => test.is_latest);

    this.state = {
      offsetLeft: null,
      selectedTest: props.selectedTest,
      hoveredTest: null,
      latestTest,
    };

    this.onHover = this.onHover.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedTest !== prevState.selectedTest) {
      return {
        selectedTest: nextProps.selectedTest,
      };
    }

    return null;
  }

  componentDidMount() {
    const element = document.getElementById('show-tooltip');
    this.setState({ offsetLeft: element && element.offsetLeft }, () => {
      const timelineSection = document.getElementById('timeline-section');
      timelineSection.scrollBy(this.state.offsetLeft, 0);
    });
  }

  renderTooltip() {
    const { hoveredTest, offsetLeft, selectedTest } = this.state;
    let test = selectedTest;
    if (hoveredTest && hoveredTest.info) {
      test = hoveredTest;
    }

    if (!(test && test.info)) {
      return null;
    }

    const firstElem = document.getElementById('first-element');

    //calculation to get the exact distance of hovered element from the first element
    //35 - 25 as half the width of tooltip and 10 as half the width of dot
    let left = offsetLeft - (firstElem && firstElem.offsetLeft) - 35;

    //change the left of the tooltip when the hovered or active element is first element
    //if don't change this implementation the tooltip will be cropped from the left side
    if (offsetLeft - (firstElem && firstElem.offsetLeft) <= 5) {
      left = offsetLeft - (firstElem && firstElem.offsetLeft);
    }

    return (
      <div className="single-test-tooltip" style={{ left: `${left}px` }}>
        {/*<p className="test-info">{test.info.title}</p>*/}
        <p className="selected-test-info">{test.info.text}</p>
      </div>
    );
  }

  onHover(event, test) {
    const element = event.target;
    this.setState({ offsetLeft: element.offsetLeft, hoveredTest: test });
  }

  onBlur() {
    const element = document.getElementById('show-tooltip');
    this.setState({ offsetLeft: element && element.offsetLeft, hoveredTest: null });
  }

  render() {
    const { tests_timeline, selectedTest, fetchResults } = this.props;
    const { latestTest } = this.state;

    return (
      <div className="you-page-v3__test-timeline" id="timeline-section">
        {/*{this.renderTooltip()}*/}
        {tests_timeline.map((test, index) => {
          const { year, status, date } = test;
          const selected = selectedTest && selectedTest.date == date;

          return (
            <div
              key={date}
              className={`single-test-container${selected ? ' show-tooltip' : ''}`}
              id={(selected ? 'show-tooltip' : '') + (selected && index === 0 ? ' ' : '') + (index === 0 ? 'first-element' : '')}
              onClick={() => fetchResults(test)}
              onMouseOut={this.onBlur}
              onMouseOver={event => this.onHover(event, test)}
            >
              <div
                className={`test-point${latestTest < index ? ' white-background' : ''}`}
                style={{ backgroundColor: status !== null ? this.props.theme.section.cta_colour : 'inherit' }}
              />
              <p className="test-year">{(index + 2) % 2 === 0 ? year : ''}</p>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withTheme(DotGraph);
