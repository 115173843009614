import React, { Component } from 'react';

import utils from '../../../../helpers/utils';
import UnitSlider from './unitSlider';

const possibleOptions = {
    hm: {majorName: 'Hours', minorName: 'Minutes', metricOrImperial: 'metric', minorConversionFactor: 60, showCombined: true, step: 0.25},
};

const defaultUnit = 'hm';


export default class HoursMinutes extends Component {
    static convertToUnits(currentUnit, toUnits, value) {
        if(value === '') {
            return false;
        }

        return value;
    }

    //Default units is in minutes
    static convertToDefault(currentUnit, value) {
        if(value === '') {
            return false;
        }

        value = utils.isNumeric(value) ? Number(value) : 0;

        return (value * 60);
    }

    render() {
        const { options, value } = this.props;
        const optionsWithRange = {...options, min: options.min ? options.min : 0, max: options.max ? options.max : 12};

        return (
            <UnitSlider
                {...this.props}
                convertToDefault={HoursMinutes.convertToDefault}
                convertToUnits={HoursMinutes.convertToUnits}
                defaultUnit={defaultUnit}
                defaultValue={0}
                options={optionsWithRange}
                possibleOptions={possibleOptions}
                step={1}
                value={value/60}
            />
        );
    }
}