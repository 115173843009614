import React, { Component } from 'react';


export default class CheckboxGrid extends Component {
    constructor(props) {
        super(props);

        const {rows, columns, value} = this.props;

        const checked = {};
        for(const x in rows) {
            checked[rows[x]] = {};
            for(const y in columns) {
                checked[rows[x]][columns[y]] = (value[rows[x]] && value[rows[x]][columns[y]]) ? 
                                                        value[rows[x]][columns[y]] : false;
            }
        }

        this.state = {
            checked: checked,
        };

        this.handelOnChange = this.handelOnChange.bind(this);
    }

    handelOnChange(e) {
        const {id, rows, columns, onChange} = this.props;
        const checked = this.state.checked;

        checked[e.target.name][e.target.value] = e.target.checked ? e.target.value : false;

        //Only pass the ones that are atcualy checked back up
        const onlyChecked = {};
        for(const x in rows) {
            for(const y in columns) {
                if(checked[rows[x]] && checked[rows[x]][columns[y]]) {
                    if(!onlyChecked[rows[x]]) {
                        onlyChecked[rows[x]] = {};
                    }
                    onlyChecked[rows[x]][columns[y]] = checked[rows[x]][columns[y]];
                }
            }
        }

        this.setState({checked: checked});
        onChange(id, onlyChecked);
    }

    renderTableHeader() {
        const { columns, id } = this.props;
        return (
            <thead>
            <tr>
                <th style={{width: `calc(100%/${columns.length + 1})`}}>&nbsp;</th>
                {columns.map((thisCol, index) => <th key={`${id}_${index}`} style={{width: `calc(100%/${columns.length + 1})`}}>{thisCol}</th>)}
            </tr>
            </thead>
        );
    }

    renderTableBody() {
        const { rows, id, columns } = this.props;
        const {checked} = this.state;

        return (
            <tbody>
                {
                    rows.map((thisRow, rowIndex) => {
                        return (
                            <tr key={`${id}tr_${rowIndex}`} className="questionnaire-grid__row">
                                <td className="questionnaire-grid__row-label" style={{width: `calc(100%/${columns.length + 1})`}}>{thisRow}</td>
                                {columns.map((thisCol, colIndex) => {
                                    return (
                                        <td
                                            className="questionnaire-grid__cell "
                                            key={`${id}_${rowIndex}_${colIndex}`}
                                            onClick={() => this.handelOnChange({target: {name: thisRow, value: thisCol, checked: !checked[thisRow][thisCol]}})}
                                            style={{width: `calc(100%/${columns.length + 1})`}}
                                        >
                                            <input
                                                checked={checked[thisRow][thisCol]}
                                                name={thisRow}
                                                onChange={(e) => this.handelOnChange(e)}
                                                type="checkbox"
                                                value={thisCol}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    render() {
        return (
            <div>
                <table className="questionnaire-grid">
                    {this.renderTableHeader()}
                    {this.renderTableBody()}
                </table>
            </div>
        );
    }
}