import React, { Component } from 'react';
import {Tooltip} from 'react-tippy';
import PropTypes from 'prop-types';

import MethylationGraph from '../../../../containers/you/common/methylationGraph';

import Loader from '../../../../components/common/loader';
import Accordion from '../../../../components/common/accordion';

class Evolution extends Component {

    componentDidMount() {
        this.props.fetchTransposons('evolution');
    }

    renderHeroSection() {
        const { results: {title, text} } = this.props;

        if(!(title || text)) {
            return null;
        }

        return (
            <div className="result-hero">
                <div className="result-hero__text-container">
                    <div className="transposons__hero-section">
                        {title ? <h1 className="result-hero__title study-hero__title">{title}</h1> : ''}
                        {text ? <p className="hero-description">{text}</p> : ''}
                    </div>
                </div>
            </div>
        );
    }

    renderSummarySection() {
        const { results: {summary} } = this.props;

        if(!summary) {
            return null;
        }

        const { title, image_url, summary_text, analysis_text, range: {data = {}} } = summary;

        return (
            <div className="biological-age__graph-section">
                <div className="transposons__evolutionary-protector">
                    {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                    {image_url ? <div className="image-container"><img alt={title} src={image_url} /></div> : ''}
                    {summary_text ? <p className="summary-text">{summary_text}</p> : ''}
                    {data ?
                        <MethylationGraph
                            className="summary-methylation-graph"
                            data={data.range}
                            labels={data.x}
                            you={data.you}
                        /> : ''
                    }
                    {analysis_text ? <p className="transposons__title-information">{analysis_text}</p> : ''}
                </div>
            </div>
        );
    }

    renderTransposonHistory() {
        const { results: {history} } = this.props;
        if(!history) {
            return null;
        }

        return (
            <div>
                <div className="biological-age__graph-section">
                    <div className="transposons__section-without-shadow">
                        {history.title ? <h4 className="indicator-section-title">{history.title}</h4> : ''}
                        {history.text ? <p className="indicator-section-description">{history.text}</p> : ''}
                    </div>
                </div>
                {this.renderTransposonsSection()}
            </div>
        );
    }

    renderTransposonsSection() {
        const { history } = this.props;
        if(!(history.items && history.items.length)) {
            return null;
        }

        return (
            <ul className="transposons__history-section">
                {
                    history.items.map((item) => {
                        const { title, date_text, text, percent } = item;
                        return (
                            <li className="transposons__history-content line-item" key={title}>
                                <div>
                                    {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                                    {date_text ? <p className="history-time">{date_text}</p> : ''}
                                    {text ? <p className="indicator-section-description">{text}</p> : ''}
                                    {/*data ? <MethylationGraph data={data.range} labels={data.x} you={data.you} /> : ""*/}
                                    {percent &&
                                        <div className="methylation-level">
                                            <div className="methylation-level__title">
                                                <span className="first">{percent}</span>
                                                <span>%</span>
                                            </div>
                                            <div className="methylation-level__text">
                                                Relative methylation impact
                                                <Tooltip title="">
                                                    <i className="icon-info-circled help-icon form__help-icon"/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    renderAccordionSection() {
        const { results: {info} } = this.props;

        if(!(info && info.items && info.items.length)) {
            return null;
        }

        const children = ({image_url, imagel_label}) => {
            if(!(image_url || imagel_label)) {
                return null;
            }

            return (
                <div className="accordion-children">
                    {image_url ? <img alt={imagel_label} src={image_url} /> : ''}
                    {imagel_label ? <p>{imagel_label}</p> : ''}
                </div>
            );
        };

        const updatedItems = info.items.map((item) => (
            {
                title: item.title,
                description: item.text,
                children: children({...item}),
                classes: 'transposons__accordion-section'
            }
        ));

        return (
            <div className="biological-age__graph-section">
                <Accordion
                    classes="transposons__section-without-shadow"
                    descriptionInHeader={true}
                    items={updatedItems}
                    showOpen={true}
                    title={info.title}
                />
            </div>
        );
    }

    render() {
        const { isLoading, results } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        if(!(results && Object.keys(results).length)) {
            return null;
        }

        return (
            <section className="transposons__container content-wrapper-animate">
                {this.renderHeroSection()}
                {this.renderSummarySection()}
                {this.renderTransposonHistory()}
                {this.renderAccordionSection()}
            </section>
        );
    }
}

Evolution.defaultProps = {
    isLoading: true,
    results: {},
    fetchTransposons: () => {},
};

Evolution.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.object,
    fetchTransposons: PropTypes.func,
};

export default Evolution;