import React from 'react';
import PropTypes from 'prop-types';

const RenderDesktopCard = ({rightSideSection, children, description, number}) => (
    <div className="process-card__wrapper">
        <div className={`process-card__container ${rightSideSection ? 'right' : ''}`}>
            {number && <div className={`process-card__number ${rightSideSection ? '' : 'right'}`}>{number}</div>}
            {!rightSideSection ? children : ''}
            {description ? <div className="information-section">{description}</div> : ''}
            {rightSideSection ? children : ''}
        </div>
    </div>
);

const RenderMobileCard = ({rightSideSection, children, description, number}) => (
    <div className="process-card__wrapper">
        <div className={`process-card__container ${rightSideSection ? 'right' : ''}`}>
            {number && <div className={`process-card__number ${rightSideSection ? '' : 'right'}`}>{number}</div>}
            {children}
            {description ? <div className="information-section">{description}</div> : ''}
        </div>
    </div>
);

const ProcessCard = ({breakpoint, ...rest}) => {
    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium');

    return isMobile ? RenderMobileCard({...rest}) : RenderDesktopCard({...rest});
};

ProcessCard.defaultProps = {
    rightSideSection: false,
    breakpoint: 'large'
};

ProcessCard.propTypes = {
    rightSideSection: PropTypes.bool,
    description: PropTypes.string,
    number: PropTypes.number,
    breakpoint: PropTypes.string,
};

export default ProcessCard;