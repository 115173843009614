import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

import IntervenableCard from './intervenableCard';

const sectionTitle = {
    'high_impact': 'high impact on your',
    'medium_impact': 'medium impact on your',
    'low_impact': 'low impact on your'
};

class Overview extends Component {

    renderTooltip() {
        return (
            <Tooltip
                title="This is a prediction of what your biological age could be in 10 years"
                position="top"
                arrow={true}
                arrowSize="small"
                className="future-tooltip"
            >
                <i className="icon-info-circled" />
            </Tooltip>
        );
    }

    renderAgeSection() {
        const { age } = this.props;

        if(!age) {
            return null;
        }

        const { title, cta, current, current_age, future, future_age, num_years, rating } = age;

        const circleClass = current_age > 0 ? 'gained' : current_age < 0 ? 'lost' : 'maintained';

        return (
            <div className="futures__age-section">
                <h4 className="section-title">{title}</h4>
                <div className="futures__ovals-container">
                    <div className="futures__oval-section">

                        <svg viewBox="0 0 100 100" className={`intervenable-card__oval futures__oval ${circleClass}`}>
                            <circle cx="50" cy="50" r="45" className={`circle ${rating}`} />
                            <foreignObject x="5" y="5" className="future-count" width="90" height="90">
                                <div className="content-container">
                                    <div>
                                        <p>{current_age}</p>
                                        <p>YEARS</p>
                                    </div>
                                    <div>
                                        <p>0</p>
                                        <p>MOS</p>
                                    </div>
                                </div>
                            </foreignObject>
                        </svg>
                        <div className="label">{current}</div>
                    </div>
                    <div className="futures__oval-section futures__oval-section--arrow">
                        <p className="arrow-text">{num_years}</p>
                        <div className="arrow" />
                    </div>
                    <div className="futures__oval-section">
                        {/*this.renderTooltip()*/}
                        <svg viewBox="0 0 100 100" className={`futures__oval ${circleClass}`}>
                            <circle cx="50" cy="50" r="45" className={`circle dashed ${rating}`} />
                            <foreignObject x="5" y="5" className="future-count" width="90" height="90">
                                <div className="content-container">
                                    <div>
                                        <p>{future_age}</p>
                                        <p>YEARS</p>
                                    </div>
                                </div>
                            </foreignObject>
                        </svg>
                        <div className="label">{future}</div>
                    </div>
                </div>
                {
                    cta ?
                        <div>
                            <Link to="/dashboard/improvements" className="btn">{cta}</Link>
                        </div> : ''
                }
            </div>
        );
    }

    renderIntervenablesSection() {
        const { intervenables, intervenablesTitle, title, age } = this.props;

        if(!(intervenables && Object.keys(intervenables).length && intervenablesTitle)) {
            return null;
        }

        return (
            <div className="futures__intervenables-section">
                {/*<h4 className="section-title">{intervenablesTitle}</h4>*/}
                <div>
                    {
                        Object.keys(intervenables).map((key) => intervenables[key].length > 0 ? (
                            <div key={key}>
                                <h5 className={`futures__intervenable-title ${key}`}>{sectionTitle[key]} {title}</h5>
                                <div>
                                    {intervenables[key].map((item, index) =>
                                        <IntervenableCard
                                            item={item}
                                            key={index}
                                            index={index}
                                            impactKey={key}
                                            numYears={age.num_years}
                                        />
                                    )}
                                </div>
                            </div>
                        ) : '')
                    }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="futures__container">
                {this.renderAgeSection()}
                {/*}
                <div className="futures__intervenables-section">
                    <div className="section-title">
                        Environmental factors have different magnitudes of impact on your biological Age
                    </div>
                    <div className="image-section">
                        <img src="/img/you/futures/impact-magnitude.png" alt="magnitude-impact" />
                    </div>
                </div>
                */}
                {this.renderIntervenablesSection()}
            </div>
        );
    }
}

Overview.propTypes = {
    age: PropTypes.shape({
        title: PropTypes.string,
        cta: PropTypes.string,
        current: PropTypes.string,
        current_age: PropTypes.string,
        future: PropTypes.string,
        future_age: PropTypes.string,
        num_years: PropTypes.string,
        rating: PropTypes.string
    }),
    intervenables: PropTypes.object,
    intervenablesTitle: PropTypes.string
};

export default Overview;