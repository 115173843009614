import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import withErrorBoundary from './common/errorBoundary';
import BreakpointContext from './context/breakpoint';
import LogPageView from './common/logPageView';

import Dashboard from '../containers/dashboard';
import { AntiSpike, CovidResultDashboard, TestingDashboard, TimelineCalculator } from '../containers/testingDashboard';
import { Article } from '../containers/articles/Article';
import You from '../containers/you';
import Improvements from '../containers/improvements';

import Stats from './stats';
import Studies from './studies';
import StudyIndividual from './studies/study';
import OnboardingQuestions from './onboarding/questions';
import Account from './account';
import Results from './results';
import TermsOverlay from './common/termsOverlay';
import ErrorPage from './common/errorPage';
import Faqs from './faqs';
import RestoreScroll from './common/restoreScroll';
import Tests from './tests';
import Genome from './genome';
import ShopComplete from './shop/complete';
import PlanDetails from '../containers/improvements/planDetails';
import ProductDetails from '../containers/improvements/productDetails';
import Orders from '../containers/orders';
import OrderEdit from '../containers/orders/edit';
import BiomarkerBasedResults from '../containers/biomarkerBasedResults';
import RegistrationPage from './join/registrationPage';

const LoggedInRoutes = ({ testUser, user, showTermsOverlay, subscription, openChat }) => {
  const DashboardPage = props => (
    <div>
      <Helmet>
        <title>Dashboard - Hurdle</title>
      </Helmet>
      <Dashboard {...props} />
    </div>
  );
  const AntiSpikeDashboardPage = props => (
    <>
      <Helmet>
        <title>Anti-Spike - Hurdle</title>
      </Helmet>
      <AntiSpike {...{ testUser }} {...props} />
    </>
  );
  const CovidDashboardPage = props => (
    <>
      <Helmet>
        <title>Covid & Immunity - Hurdle</title>
      </Helmet>
      <TestingDashboard {...{ testUser }} {...props} />
    </>
  );
  const TimelineCalculatorPage = props => (
    <>
      <Helmet>
        <title>Testing Timeline - Hurdle</title>
      </Helmet>
      <TimelineCalculator {...{ testUser }} {...props} />
    </>
  );

  const OrdersPage = props => (
    <>
      <Helmet>
        <title>Orders - Hurdle</title>
      </Helmet>
      <Orders {...props} />
    </>
  );

  const OrderEditPage = props => (
    <>
      <Helmet>
        <title>Edit Order - Hurdle</title>
      </Helmet>
      <OrderEdit {...props} />
    </>
  );

  const AccountPage = props => {
    const section = props.match.params.section || null;
    const componentProps = {
      section,
      subscription,
    };

    return (
      <>
        <Helmet>
          <title>Account - Hurdle</title>
        </Helmet>
        <Account {...componentProps} openChat={openChat} />
      </>
    );
  };

  const OnboardingQuestionsPage = props => {
    const { id = null, currentPage = null } = props.match.params;

    return (
      <>
        <Helmet>
          <title>Onboarding - Hurdle</title>
        </Helmet>
        <RestoreScroll>
          <OnboardingQuestions slug="onboarding" sectionId={id} currentPage={currentPage} />
        </RestoreScroll>
      </>
    );
  };
  const StudiesPage = () => (
    <>
      <Helmet>
        <title>Studies - Hurdle</title>
      </Helmet>
      <Studies />
    </>
  );

  const StudyIndividualPage = () => (
    <>
      <Helmet>
        <title>Studies - Hurdle</title>
      </Helmet>
      <StudyIndividual />
    </>
  );

  const StatsPage = () => (
    <>
      <Helmet>
        <title>Stats - Hurdle</title>
      </Helmet>
      <Stats />
    </>
  );

  const ResultsPage = () => (
    <>
      <Helmet>
        <title>My Results - Hurdle</title>
      </Helmet>
      <RestoreScroll>
        <Results />
      </RestoreScroll>
    </>
  );

  const CovidResultsPage = props => {
    return (
      <>
        <Helmet>
          <title>COVID-19 Test Results - Hurdle</title>
        </Helmet>
        <RestoreScroll>
          <CovidResultDashboard {...{ testUser }} {...props} />
        </RestoreScroll>
      </>
    );
  };

  const PlanDetailsPage = props => {
    return (
      <>
        <RestoreScroll>
          <PlanDetails {...props} slug={props.location.state} />
        </RestoreScroll>
      </>
    );
  };

  const ProductDetailsPage = props => {
    return (
      <>
        <RestoreScroll>
          <ProductDetails {...props} data={props.location.state} />
        </RestoreScroll>
      </>
    );
  };

  const YouPage = props => {
    return (
      <>
        <Helmet>
          <title>You - Hurdle</title>
        </Helmet>
        <BreakpointContext.Consumer>
          {breakpoint => {
            return <You {...{ breakpoint, testUser, ...props }} openChat={openChat} />;
          }}
        </BreakpointContext.Consumer>
      </>
    );
  };

  const FAQPage = props => {
    const categorySlug = props.match.params.categorySlug ? props.match.params.categorySlug : null;
    const faqSlug = props.match.params.faqSlug ? props.match.params.faqSlug : null;

    return (
      <>
        <Helmet>
          <title>FAQ - Hurdle</title>
        </Helmet>
        <RestoreScroll>
          <Faqs categorySlug={categorySlug} faqSlug={faqSlug} />
        </RestoreScroll>
      </>
    );
  };

  const NoMatch = props => {
    if (props.location.pathname.substring(0, 10) == '/dashboard') {
      //404 page
      return (
        <>
          <Helmet>
            <title>Page not found - Hurdle</title>
          </Helmet>
          <ErrorPage {...{ httpCode: 404, user }} />
        </>
      );
    } else {
      // window.location.reload();
      return null;
    }
  };

  const ImprovementsPage = props => (
    <>
      <Helmet>
        <title>Improvements - Hurdle</title>
      </Helmet>
      <BreakpointContext.Consumer>
        {breakpoint => {
          return <Improvements {...props} breakpoint={breakpoint} openChat={openChat} />;
        }}
      </BreakpointContext.Consumer>
    </>
  );

  const TestsPage = props => (
    <Fragment>
      <Helmet>
        <title>Archived Tests - Hurdle</title>
      </Helmet>
      <Tests {...props} />
    </Fragment>
  );

  const GenomePage = props => (
    <Fragment>
      <Helmet>
        <title>Genetics - Hurdle</title>
      </Helmet>
      <BreakpointContext.Consumer>
        {breakpoint => <Genome {...props} breakpoint={breakpoint} testUser={testUser} openChat={openChat} />}
      </BreakpointContext.Consumer>
    </Fragment>
  );

  const ShopCompletePage = props => (
    <Fragment>
      <Helmet>
        <title>Purchase Complete - Hurdle</title>
      </Helmet>
      <ShopComplete {...props} />
    </Fragment>
  );

  return (
    <Fragment>
      <Route component={LogPageView} />

      {showTermsOverlay && <TermsOverlay testUser={testUser} user={user} />}

      <Switch>
        <Route exact path="/dashboard" render={DashboardPage} />
        <Route exact path="/dashboard/article/am-i-immune-to-covid-19" render={Article} />
        <Route exact path="/dashboard/covid" render={CovidDashboardPage} />
        <Route exact path="/dashboard/anti-spike" render={AntiSpikeDashboardPage} />
        <Route path="/dashboard/covid/test-timeline" render={TimelineCalculatorPage} />
        <Route exact path="/dashboard/covid/result/:testId" render={CovidResultsPage} />
        <Route exact path="/dashboard/results/:testId" render={props => <BiomarkerBasedResults {...props} testUser={testUser} />} />
        <Route exact path="/dashboard/orders" render={OrdersPage} />
        <Route exact path="/dashboard/orders/:id/edit" render={OrderEditPage} />
        <Route path="/dashboard/account/:section?" render={AccountPage} />
        <Route path="/dashboard/onboarding/:id?/:currentPage?" render={OnboardingQuestionsPage} />
        <Route exact path="/dashboard/studies" render={StudiesPage} />
        <Route path="/dashboard/studies/" render={StudyIndividualPage} />
        <Route path="/dashboard/stats" render={StatsPage} />

        <Route exact path="/dashboard/results" render={ResultsPage} />

        <Route path="/dashboard/you" render={YouPage} />
        <Route path="/dashboard/faqs/:categorySlug?/:faqSlug?" render={FAQPage} />
        <Route path="/dashboard/tests" render={TestsPage} />
        <Route path="/dashboard/genetic" render={GenomePage} />
        <Route path="/shop/complete" render={ShopCompletePage} />
        <Route exact path="/dashboard/improvements/goals/:slug" render={PlanDetailsPage} />
        <Route path="/dashboard/improvements/goals/:slug/:slug" render={ProductDetailsPage} />
        <Route path="/dashboard/improvements" render={ImprovementsPage} />
        <Route path="/register/questionnaire" render={props => <RegistrationPage {...props} />} />
        <Route component={NoMatch} />
      </Switch>
    </Fragment>
  );
};

export default withErrorBoundary(LoggedInRoutes);
