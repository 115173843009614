import React, {Component} from 'react';
import CouponCodeForm from './couponCodeForm';
import Loader from '../common/loader';
import ShippingWarning from './shippingWarning';

const REPEAT = 'subscription';

let quantityValues = new Array(100).fill({}).map((_, idx) => idx + 1);

export default class OrderSummary extends Component {

    renderProductAndVariationSection() {
        const { allowCouponCodeEntry, branding, product, variation, quantity, max_qty, postage, discountTitle,
            couponCodeFormOpen, handelCouponCodeFormOpen, calculateTotal, fields, handelCouponCodeSubmit,
            handleInputChange, couponCodeFormError, couponCodeFormSuccess, handleQuantityChange, discountDuration,
            discountDurationMonths, showShippingWarning
        } = this.props;

        if (!isNaN(max_qty) && parseInt(max_qty, 100) >= 1 && parseInt(max_qty, 100) < 99) {
            quantityValues = new Array(parseInt(max_qty, 100)).fill({}).map((_, idx) => idx + 1);
        }
        const productFrequency = (variation && variation.frequency) ? variation.frequency.slice(0, -2) : '';

        //Find the monthly and yearly prices
        let monthlyVariation = null;
        let yearlyVariation = null;
        let yearlySaving = '';

        if(product) {
            for(const x in product.variations) {
                if(product.variations[x].gift_only == 0) {
                    if(product.variations[x].frequency === 'monthly') {
                        monthlyVariation = product.variations[x];
                    }
                    else if(product.variations[x].frequency === 'yearly') {
                        yearlyVariation = product.variations[x];
                    }
                }
            }

            if(monthlyVariation && yearlyVariation) {
                yearlySaving = yearlyVariation.currency_symbol + (monthlyVariation.price*12 - yearlyVariation.price);
            }
        }

        // Variables for order container price details area
        const hasOneTimeCost = variation && variation.extra_price ? true : false;
        const hasReccuringCost = variation && variation.price ? true : false;
        const hasEpiKitCost = variation && variation.epi_kit_price ? true : false;
        const isFirstSameAsRepeatPrice = variation && (!hasOneTimeCost || !hasEpiKitCost) && postage && calculateTotal(true, true, true, true, true) === calculateTotal(true, false, false, false, false);
        const hasOneTimePostage = (postage && fields.shipping_method !== '')  ? true : false;
        const hasSuccessfulOneTimeDiscount = couponCodeFormSuccess && discountDuration==='once';
        //let showFrequency = variation ? variation.type==REPEAT && ((!hasSuccessfulOneTimeDiscount && !hasOneTimePostage) || (hasOneTimePostage && hasSuccessfulOneTimeDiscount && isFirstSameAsRepeatPrice) || !hasOneTimeCost) : false;


        let paymentFrequenceText = '';
        /*
        if(!isFirstSameAsRepeatPrice &&
            (hasSuccessfulOneTimeDiscount || hasOneTimePostage || hasOneTimeCost || hasEpiKitCost)) {*/

                let discountFrequencyText = '';
                const hasSecondPayment = discountDurationMonths && discountDurationMonths > 1 && discountDurationMonths < 12;
                if(hasSecondPayment) {
                    discountFrequencyText = ` for ${discountDurationMonths} ${productFrequency}s`;
                }
                else {
                    discountFrequencyText = ` /${productFrequency}`;
                }

                if (variation && variation.frequency) {
                    paymentFrequenceText = (
                        <div className="order-container__price-next">
                            {!hasSecondPayment && 'Then '}
                            {calculateTotal(true, !hasSuccessfulOneTimeDiscount, false, false, false)}

                            {discountFrequencyText}

                            {hasSecondPayment &&
                                <div>
                                    Then {variation.price_formated} / {productFrequency}
                                </div>
                            }
                        </div>
                    );
                }
        /*}
        else {
            paymentFrequenceText = ("/" + productFrequency);
        }*/


        //If discount is only for like 3 months need to say it correctly..


        {/*<div className="order-container__cell left">*/}
        {/*Postage*/}
        {/*</div>*/}
        {/*<div className="order-container__cell right">*/}
        {/*{postage ? postage.postage_price === 0 ? 'FREE' : postage.postage_price_formatted + '' : 'FREE'}*/}
        {/*</div>*/}

        const getBrandingStyle = () => (branding && branding.hasBranding && branding.section) ? {
            color: branding.section.highlight_colour,
        } : {};
        const isShippingToUS = this.props.fields.delivery_country === 'US';
        return (
            <div className="card-wrapper slide">
                <div className="card-container">
                    <div className="order-container">

                        <div className="order-container__cell">
                            <img
                                className="order-container__product-image"
                                src="/img/kit-box.jpeg"
                                alt={product.title}
                            />
                        </div>
                        <div className="order-container__cell">
                            {hasOneTimeCost && <div>{product.title}</div>}
                            {hasReccuringCost && <div>{product.title} plan</div>}
                            {hasEpiKitCost && <div>Kit Cost</div>}

                            <div className="form__select-wrapper order-container__quantity">
                                Quantity:   <select
                                                value={quantity}
                                                id="quantity"
                                                className="checkout-options__quantity-select"
                                                onChange={handleQuantityChange}
                                            >
                                                {
                                                    quantityValues.map((value) => (
                                                        <option value={value} key={value}>{value}</option>
                                                    ))
                                                }
                                            </select>
                                <i className="icon-down-open" />
                            </div>



                        </div>
                        <div className="order-container__cell right">
                            {hasOneTimeCost &&
                                <div>
                                    <span className="order-container__price" style={getBrandingStyle()}>{variation.extra_price_formated}</span>
                                </div>
                            }

                            {hasReccuringCost &&
                                <div>
                                    <span className="order-container__price" style={getBrandingStyle()}>{variation.price_formated}</span>
                                    {variation.type == REPEAT
                                        ? '/' + productFrequency
                                        : ''}
                                </div>
                            }

                            {hasEpiKitCost &&
                                <div>
                                    <span className="order-container__price" style={getBrandingStyle()}>{variation.epi_kit_price_formated}</span>
                                </div>
                            }
                        </div>

                        <div className="order-container__border-bottom" />
                        <div className="order-container__border-bottom" />
                        <div className="order-container__border-bottom" />

                        <div className="order-container__cell" >&nbsp;</div>
                        <div className="order-container__cell order-container__cell--coupon-code">
                            {couponCodeFormSuccess ? (
                                <div>{discountTitle}</div>
                            ) : (
                                <div>
                                    {allowCouponCodeEntry ? (
                                        <>
                                            {couponCodeFormOpen ? (
                                                <CouponCodeForm
                                                    fields={fields}
                                                    onSubmit={handelCouponCodeSubmit}
                                                    onChange={handleInputChange}
                                                    submitError={couponCodeFormError}
                                                    success={couponCodeFormSuccess}
                                                />
                                            ) : (
                                                <a
                                                    href="#"
                                                    onClick={handelCouponCodeFormOpen}
                                                    className="coupon-form__link"
                                                >
                                                    Coupon code
                                                </a>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                        <div className="order-container__cell right">
                            {couponCodeFormSuccess &&
                                <span>{couponCodeFormSuccess}</span>
                            }
                            &nbsp;
                        </div>



                        {hasOneTimePostage &&
                            <div className="order-container__cell" ></div>
                        }
                        {hasOneTimePostage &&
                            <div className="order-container__cell ">
                                    <div>Shipping</div>
                            </div>
                        }
                        {hasOneTimePostage &&
                            <div className="order-container__cell right">
                                <span>{postage.postage_price_formatted}</span>
                            </div>
                        }

                        <div className="order-container__cell order-container__cell--cards" >

                            <div className="form__row">
                                <img className="checkout-form__cards" src="/img/accepted-cards.jpeg" alt="Accepted cards: Visa, Mastercard and American Express" />
                                <img className="checkout-form__cards" src="/img/ssl-secure.png" alt="SSL Secured" />
                            </div>

                        </div>
                        <div className="order-container__cell ">
                            <div className="order-container__big">
                                Total
                            </div>
                        </div>
                        <div className="order-container__cell right">
                            <span className="order-container__big order-container__price" style={getBrandingStyle()}>
                                {calculateTotal(
                                    true,
                                    true,
                                    hasOneTimePostage,
                                    true,
                                    true,
                                )}{isShippingToUS && '*'}
                            </span>                            
                            {isShippingToUS && <div className="order-container__sales-tax">*sales tax included</div>}


                            {/*variation.frequency == 'monthly' ? paymentFrequenceText : ''*/}
                            {paymentFrequenceText}

                            <div className="order-container__frequency-text">
                                {variation.frequency == 'monthly' ? '(minimum 12 months)' : ''}
                            </div>
                        </div>
                        {showShippingWarning && 
                            <div className="message-container">
                                <ShippingWarning />
                            </div>
                        }
                        
                    </div>
                    <div className="cards--mobile">
                        <img className="checkout-form__cards" src="/img/accepted-cards.jpeg" alt="Accepted cards: Visa, Mastercard and American Express" />
                        <img className="checkout-form__cards" src="/img/ssl-secure.png" alt="SSL Secured" />
                    </div>
                </div>
                {/*
                <div className="">
                    <p><strong>IMPORTANT: HOLIDAY SEASON UPDATES</strong></p>
                    <p>When placing a new order please note that normal cut off times might not apply during the Holidays, so ensure you order early. You can check delivery information <a href="https://help.chronomics.com/how-will-the-december-holidays-impact-my-order-and-my-results" target="_blank">here.</a></p>
                </div>
            */}
                
            </div>
        );
    }
    render() {
        const { branding, product, variation, isLoading } = this.props;
        return (
            <div className="checkout new-checkout-flow">
                <div className="checkout__form-col order-summary">
                    <h4 className="checkout__title checkout__title" style={branding && branding.hasBranding && branding.section ? { color: branding.section.highlight_colour } : {}}>
                        Order Summary
                    </h4>
                    {
                        isLoading ? <Loader /> : ((product && variation) ? this.renderProductAndVariationSection() : '')
                    }
                </div>
            </div>
        );
    }
}
