import * as actionTypes from './constants';

const initialState = {
    //my improvements initial states
    aspiration: '',
    aspirationError: null,
    aspirationLoading: true,
    showAspirationForm: true,

    //my team initial states
    coaches: null,
    teamError: null,
    teamLoading: true,

    //goal initial states
    goals: null,
    goalsError: null,
    goalsLoading: true,

    //goal details intial states
    goalDetails: null,
    goalDetailsError: null,
    goalDetailsLoading: true,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ASPIRATION_SUCCESS:
            return {
                ...state,
                aspiration: action.aspiration,
                aspirationLoading: false,
            };

        case actionTypes.FETCH_ASPIRATION_FAILURE:
            return {
                ...state,
                aspirationError: action.error
            };

        case actionTypes.FETCH_COACHES_SUCCESS:
            return {
                ...state,
                coaches: action.coaches,
                teamLoading: false
            };

        case actionTypes.FETCH_COACHES_FAILURE:
            return {
                ...state,
                teamError: action.error,
            };

        case actionTypes.TOGGLE_ASPIRATION_FORM:
            return {
                ...state,
                showAspirationForm: action.value,
            };

        case actionTypes.UPDATE_SINGLE_COACH:
            return {
                ...state,
                coaches: {
                    ...state.coaches,
                    teamMembers: action.coaches,
                }
            };

        case actionTypes.FETCH_GOAL_SUCCESS:
            return {
                ...state,
                goals: action.goals,
                goalsLoading: false,
            };

        case actionTypes.FETCH_GOAL_FAILURE:
            return {
                ...state,
                goalsError: action.error,
            };

        case actionTypes.FETCH_GOAL_DETAILS_SUCCESS:
            return {
                ...state,
                goalDetails: action.goalDetails,
                goalDetailsLoading: false,
            };

        case actionTypes.FETCH_GOAL_DETAILS_FAILURE:
            return {
                ...state,
                goalDetailsError: action.error,
                goalDetailsLoading: false,
            };

        case actionTypes.FETCH_GOAL_DETAILS_LOADING:
            return{
                ...state,
                goalDetailsLoading: action.goalDetailsLoading,
                goalDetails: null,
            };
    

        default:
            return state;
    }
};
