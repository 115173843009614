import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';
import utils from '../helpers/utils';
import LoggedInApp from '../loggedInApp';
import LoggedOutApp from '../loggedOutApp';
import { HomeRouter } from './homeRouter';

const Auth = () => {
  const location = useLocation();

  useEffect(() => {
    const status = window.HubSpotConversations?.widget?.status();

    // Refresh the chat widget when location changes to ensure the widget shows/hides depending on the url
    if (status && status.loaded) {
      window.HubSpotConversations.widget.refresh();
    }
  }, [location]);

  return (
    <>
      <Route exact path="/" component={HomeRouter} />
      <Switch>
        <Route
          path={[
            '/dashboard',
            '/dashboard/article/am-i-immune-to-covid-19',
            '/dashboard/covid',
            '/dashboard/anti-spike',
            '/dashboard/covid/test-timeline',
            '/dashboard/covid/result/:testId',
            '/dashboard/results/:testId',
            '/dashboard/orders',
            '/dashboard/orders/:id/edit',
            '/dashboard/account/:section?',
            '/dashboard/onboarding/:id?/:currentPage?',
            '/dashboard/studies',
            '/dashboard/studies/',
            '/dashboard/stats',
            '/dashboard/results',
            '/dashboard/you',
            '/dashboard/faqs/:categorySlug?/:faqSlug?',
            '/dashboard/tests',
            '/dashboard/genetic',
            '/shop/complete',
            '/dashboard/improvements/goals/:slug',
            '/dashboard/improvements/goals/:slug/:slug',
            '/dashboard/improvements',
            '/register/questionnaire'
          ]}
          render={() =>
            utils.userIsLoggedIn() ? (
              <LoggedInApp />
            ) : (
              <Redirect to={{ pathname: '/login', state: { referrer: location }, search: getPartnerQueryParams() }} />
            )
          }
        />
        <Route
          path={[
            '/join/:section',
            '/register-covid',
            '/register',
            '/login',
            '/forgot-password',
            '/reset-password',
            '/demo',
            '/register-test-intrest',
            '/shop/checkout-guest/complete',
            '/tui-drop',
            '/report',
          ]}
          render={props => <LoggedOutApp {...props} />}
        />
      </Switch>
    </>
  );
};

export default Auth;
