import { takeLatest, fork, all, call, put, select, delay } from 'redux-saga/effects';

import * as actionTypes from './constants';
import * as api from './api';
import { getTrackingUrls } from '../return/api';
import { fetchResults, fetchProducts, fetchQuestionnaireForKitId, fetchQuestionnaires } from './web-api';
import * as actions from './actions';
import * as selectors from './selectors';
import { datadogRum } from '@datadog/browser-rum';
import questionnaire from '../../components/common/questionnaires/questionnaire';

const youPageLink = [{ type: 'link', title: 'Health', link: 'you' }];

const indicatorPageLinks = [
  { type: 'link', title: 'Overview', slug: 'overview', link: 'you/indicator', data: '' },
  { type: 'link', title: 'The Science', slug: 'science', link: 'you/indicator', data: 'the-science' },
  { type: 'link', title: 'FAQs', slug: 'faq', link: 'you/indicator', data: '' },
];

function* fetchIndicator(action) {
  const indicatorResults = yield call(api.getIndicator, action.params, action.slug);

  if (indicatorResults.errorResponse) {
    yield put(actions.updateIndicatorFailure(indicatorResults.error));
  } else {
    const indicatorLinks = [];

    indicatorPageLinks.map(link => {
      const newLink = link.link + '/' + action.slug + (link.slug ? `/${link.slug}` : '');
      indicatorLinks.push({ ...link, link: newLink });
    });

    if (action.slug == 'chromosome-ageing') {
      yield put(actions.updateChromosomeAge(indicatorResults));
    } else {
      yield put(actions.updateIndicator(indicatorResults));
    }

    yield put(actions.updateIndicatorLinks({ indicatorLinks, youPageLink }));
  }
}

function* watchFetchIndicator() {
  yield takeLatest(actionTypes.FETCH_INDICATOR, fetchIndicator);
}

function* saveChromosomeValue(action) {
  const value = yield select(selectors.chromosomeValueSelector);
  let newSelectedValue = [];
  if (value.includes(action.value)) {
    newSelectedValue = value.filter(item => item != action.value);
  } else {
    newSelectedValue = [...value, action.value];
  }

  yield put(actions.updateChromosomeValue(newSelectedValue));
}

function* watchSaveChromosomeValue() {
  yield takeLatest(actionTypes.SAVE_CHROMOSOME_VALUE, saveChromosomeValue);
}

function* fetchCompare() {
  const compareResults = yield call(api.getCompare);
  if (compareResults.errorResponse) {
    yield put(actions.updateCompareFailure(compareResults.error));
  } else {
    yield put(actions.updateCompareSuccess(compareResults));
  }
}

function* watchFetchCompare() {
  yield takeLatest(actionTypes.FETCH_COMPARE, fetchCompare);
}

function* fetchComparison(action) {
  const params = yield select(selectors.comparisonParamsSelector);
  if (params.section !== action.section || params.id !== action.id) {
    const comparison = yield call(api.getCompare, action.section, action.id);

    if (comparison.errorResponse) {
      yield put(actions.updateCompareFailure(comparison.error));
    } else {
      yield put(actions.updateComparisonSuccess(comparison, action.section, action.id));
    }
  } else {
    yield put(actions.setComparisonLoading(false));
  }
}

function* watchFetchComparison() {
  yield takeLatest(actionTypes.FETCH_COMPARISON, fetchComparison);
}

function* fetchTransposons(action) {
  const transposons = yield select(selectors.transposonsSelector);

  if ((transposons && transposons.slug) !== action.slug) {
    const transposonsResult = yield call(api.getTansposons, action.slug);
    if (transposonsResult.errorResponse) {
      yield put(actions.updateTransposonsFailure(transposonsResult.error));
    } else {
      const { active_inactive } = transposonsResult;
      let transposonsTooltips = [];
      if (active_inactive && active_inactive.results && active_inactive.results.items && active_inactive.results.items.length) {
        transposonsTooltips = active_inactive.results.items.map(item => item.title.replace(' ', '-'));
      }
      yield put(actions.updateTransposonsTooltips(transposonsTooltips));
      yield put(actions.updateTransposonsSuccess({ ...transposonsResult, slug: action.slug }));
    }
  } else {
    yield put(actions.setTransposonsLoading(false));
  }
}

function* watchFetchTransposons() {
  yield takeLatest(actionTypes.FETCH_TRANSPOSONS, fetchTransposons);
}

function* setTransposonsTooltip(action) {
  const tooltips = yield select(selectors.transposonsTooltipSelector);
  let updatedTooltips = [...tooltips];
  if (tooltips && tooltips.includes(action.slug)) {
    updatedTooltips = tooltips.filter(tooltip => tooltip !== action.slug);
  } else {
    updatedTooltips.push(action.slug);
  }

  yield put(actions.updateTransposonsTooltips(updatedTooltips));
}

function* watchSetTransposonsTooltip() {
  yield takeLatest(actionTypes.SET_TRANSPOSONS_TOOLTIP, setTransposonsTooltip);
}

function* fetchYou(action) {
  const you = yield select(selectors.youSelector);
  yield put(actions.setYouLoading(true));

  if (!you || (action.testId && you && action.testId !== you.testId)) {
    const youData = yield call(api.getYou, action.testId);
    if (youData.errorResponse && youData.errorResponse.data.error.http_code !== 400) {
      yield put(actions.updateYouFailure(youData.error));
    } else {
      const tests = youData && youData.tests_timeline && youData.tests_timeline.filter(test => test.is_viewing);
      if (!you) {
        const viewableTest = tests && tests.length ? tests[0] : null;
        if (viewableTest) {
          yield put(actions.setSelectedTest(viewableTest));
        }
      }
      yield put(actions.updateYouSuccess({ ...youData, testId: action.testId }));
      yield put(actions.setYouLoading(false));
    }
  }
  yield put(actions.setYouLoading(false));
}

function* watchFetchYou() {
  yield takeLatest(actionTypes.FETCH_YOU, fetchYou);
}

export function* fetchUserDetails() {
  const userDetailsData = yield call(api.getUserDetails);
  yield put(actions.updateUserDetailsLoading(true));

  if (userDetailsData.errorResponse) {
    yield put(actions.updateUserDetailsFailure(userDetailsData.error));
  } else {
    localStorage.setItem('userMetadata', JSON.stringify(userDetailsData));
    window.CONFIG = { ...window.CONFIG, ...userDetailsData };
    yield put(actions.updateUserDetailsSucess(userDetailsData));
  }

  yield put(actions.updateUserDetailsLoading(false));
}

function* watchFetchUserDetails() {
  yield takeLatest(actionTypes.FETCH_USER_DETAILS, fetchUserDetails);
}

function* fetchCovidResults() {
  const covidResult = yield select(selectors.covidResultSelector);

  if (!covidResult) {
    const covidData = yield call(api.getCovidResults);
    if (covidData.errorResponse && covidData.errorResponse.status !== 400) {
      yield put(actions.updateCovidResultsFailure(covidData.error));
    } else {
      yield put(actions.updateCovidResults({ ...covidData }));
    }
  } else {
    yield delay(500);
    yield put(actions.setCovidResultsLoading(false));
  }
}

export function* fetchOrders() {
  const orders = yield select(selectors.ordersSelector);
  if (!orders) {
    const orders = yield call(api.getOrders);

    if (orders.errorResponse && orders.errorResponse.data.error.http_code !== 400) {
      yield put(actions.updateOrdersFailure(orders.error));
    } else {
      const orderNumbers = orders.data.map(order => order.order_number);
      // TODO Remove zigzag integration completely in NUCL-517
      let trackingUrlsResponse = []; // yield call(getTrackingUrls, orderNumbers);
      if (trackingUrlsResponse.error) {
        // If getTrackingUrls errors we don't want to stop the user from seeing
        // the orders, so we set the response to an empty list and don't dispatch
        // the error.
        trackingUrlsResponse = [];
      }
      const trackingUrlsMap = trackingUrlsResponse.reduce((acc, { orderNumber, zigzagUrl }) => {
        Object.assign(acc, { [orderNumber]: zigzagUrl });
        return acc;
      }, {});
      const ordersWithZigZagUrl = orders.data.map(order => {
        const zigzagUrl = trackingUrlsMap[order.order_number];
        return zigzagUrl ? { ...order, zigzagUrl } : order;
      });
      yield put(actions.updateOrders({ data: ordersWithZigZagUrl }));
    }
  } else {
    yield delay(500);
    yield put(actions.setOrdersLoading(false));
  }
}

function* watchFetchCovidResults() {
  yield takeLatest(actionTypes.FETCH_COVID_RESULTS, fetchCovidResults);
}

function* watchFetchOrders() {
  yield takeLatest(actionTypes.FETCH_ORDERS, fetchOrders);
}

function* fetchCovidResult(action) {
  const result = yield call(api.getCovidResultByTestId, action.testId);
  if (result.errorResponse && result.errorResponse.data.error.http_code !== 400) {
    yield put(actions.getCovidResultFailure(result.error));
  } else {
    yield put(actions.getCovidResultSuccess({ ...result }));
  }
}

function* watchFetchCovidResult() {
  yield takeLatest(actionTypes.FETCH_COVID_RESULT, fetchCovidResult);
}

export function* retrieveBloodResults({ testUserId }) {
  const results = yield select(selectors.bloodResultSelector);
  if (results != null) return;

  yield put(actions.setBloodResultsLoading(true));
  try {
    const data = yield call(fetchResults, testUserId);
    const testResults = data?.data;
    const products = yield call(fetchProducts, testResults);
    const questionnaires = yield call(fetchQuestionnaires);

    const existingProductCodes = products.map(({ productCode }) => productCode);

    const resultsAndMissingProducts = filterTestResultsAndMissingProducts(testResults, existingProductCodes);

    if (resultsAndMissingProducts.missingProductCodes?.length > 0) {
      const error = new Error(
        `The following products don't exist on product service: ${resultsAndMissingProducts.missingProductCodes
          .toString()
          .replaceAll(',', ', ')}`,
      );
      datadogRum.addError(error);
    }

    const resultsWithProductName = {
      ...data,
      data: resultsAndMissingProducts.testResults.map(result => {
        const { productCode } = result;
        const { title: productName } = products?.find(product => product.productCode === productCode);
        const questionnaire = questionnaires?.find(questionnaire => questionnaire.kitId === result.kitId);
        return { ...result, productName, questionnaire };
      }),
    };

    yield put(actions.setBloodResults(resultsWithProductName));
  } catch ({ message }) {
    yield put(actions.fetchBloodResultsFailure(message));
  } finally {
    yield put(actions.setBloodResultsLoading(false));
  }
}

function* watchRetrieveBloodResults() {
  yield takeLatest(actionTypes.FETCH_BLOOD_RESULTS, retrieveBloodResults);
}

const filterTestResultsAndMissingProducts = (testResults, existingProductCodes) => {
  return testResults?.reduce((partition, testResult) => {
    if (existingProductCodes.includes(testResult.productCode)) {
      const results = partition?.testResults || [];

      return {
        ...partition,
        testResults: [...results, testResult],
      };
    }

    const missingProducts = partition?.missingProductCodes || [];

    return {
      ...partition,
      missingProductCodes: [...missingProducts, testResult.productCode],
    };
  }, {});
};

export default function*() {
  yield all([
    fork(watchFetchIndicator),
    fork(watchSaveChromosomeValue),
    fork(watchFetchCompare),
    fork(watchFetchComparison),
    fork(watchFetchTransposons),
    fork(watchSetTransposonsTooltip),
    fork(watchFetchYou),
    fork(watchFetchUserDetails),
    fork(watchFetchCovidResults),
    fork(watchRetrieveBloodResults),
    fork(watchFetchCovidResult),
    fork(watchFetchOrders),
  ]);
}
