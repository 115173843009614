import React from 'react';
import { organization, event, checksum, exttype, voucher, reportInfo } from './constants';

export default function ({ orderValue, currency, extid, orderNumber }) {
  // http://dev.tradedoubler.com/tracking/advertiser/#Support_Pixel
  return (
    <script type="text/javascript">{`
        (function () {
          // Your organization ID
          var organization=${organization};
          //
          // Value of the sale.
          var orderValue = ${orderValue};
          //
          // Currency of the sale.
          var currency = "${currency}";
          //
          //Set value of extid. This must be the email address hashed using SHA-256.
          var extid = "${extid}";
          //
          //Set the value of the external ID type. If an email address is used for extid, exttype=1. If an internal user ID is used, exttype=0.
          var exttype = ${exttype};
          //
          // Voucher code used by user.
          var voucher = "${voucher}";
          //
          // Event ID
          var event=${event};
          //
          // Event type:
          // true = Sale
          // false = Lead
          var isSale = true;
          //
          // Encrypted connection on this page:
          // true = Yes (https)
          // false = No (http)
          var isSecure = true;
          //
          // A unique identifier for the transaction. For a sale, this is typically the order number.
          var orderNumber = "${orderNumber}";
          //
          // You may transmit a list of items ordered in the reportInfo parameter.
          var reportInfo = "${reportInfo}";
          reportInfo = escape(reportInfo);
          //
          function getCookie(name)
          {
            var dc = document.cookie;
            var prefix = name + "=";
            var begin = dc.indexOf("; " + prefix);
            if (begin == -1)
            {
              begin = dc.indexOf(prefix);
              if (begin != 0) return null;
            }
            else
            {
              begin += 2;
            }
            var end = document.cookie.indexOf(";", begin);
            if (end == -1)
            {
              end = dc.length;
            }
            return unescape(dc.substring(begin + prefix.length, end));
          }
          var tduid = getCookie("TRADEDOUBLER");
          /***** IMPORTANT: *****/
          /***** Please consult Tradedoubler before modifying the code below this line. *****/
          if (isSale)
          {
            var domain = "tbs.tradedoubler.com";
            var checkNumberName = "orderNumber";
          }
          else
          {
            var domain = "tbl.tradedoubler.com";
            var checkNumberName = "leadNumber";
            var orderValue = "1";
          }
          //
          //Create the checksum. Your Tradedoubler contact will explain how it should be implemented.
          var checksum = "${checksum}";
          //
          if (isSecure)
            var scheme = "https";
          else
            var scheme = "http";
          //
          var trackBackUrl = scheme + "://" + domain + "/report" + "?organization=" + organization + "&event=" + event + "&" + checkNumberName + "=" + orderNumber + "&checksum=" + checksum + "&tduid=" + tduid + "&extid=" + extid + "&exttype=" + exttype + "&type=iframe&reportInfo=" + reportInfo;
          if (isSale)
          {
            trackBackUrl = trackBackUrl + "&orderValue=" + orderValue + "&currency=" + currency + "&voucher=" + voucher;
          }
          //
          function prepareFrame(tburl){
            ifrm = document.createElement("IFRAME"); 
            ifrm.setAttribute("src", tburl); 
            ifrm.style.width = 1+"px"; 
            ifrm.style.height = 1+"px";
            ifrm.style.border = "none";
            document.body.appendChild(ifrm); 
          }         
          prepareFrame(trackBackUrl);
        })();
      `}
    </script>
  );
}
