import React from 'react';

const data = {
    title: '',
    section: {
        title: 'Epigenetics and your heart beat',
        text: 'Long QT syndrome is a heart rhythm disorder where your heart muscle takes longer than normal to recharge between beats. This cardiac disorder can cause blackouts, seizures and palpitations and more uncommonly sudden death, especially in young people. This is how the QT intervals in an electrocardiogram might look.',
        images: ['/img/you/actionable-you/cardiovascular-fitness/normal-qt.png', '/img/you/actionable-you/cardiovascular-fitness/long-qt.png'],
        description: 'Epigenetic changes in cardiac tissue have been associated with an increased risk of long QT syndrome, especially in genes that encode potassium channels that control the transmission of electric signals in the heart to maintain a regular heartbeat',
        link_url: 'https://www.sciencedirect.com/science/article/pii/S1096719210002143?via%3Dihub',
    }
};

const EpigeneticAndHeartbeat = () => {
    const { title, section } = data;
    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__graph-section no-padding">
                <h4 className="indicator-section-title">{title}</h4>
                <div className="epigenetic-heartbeat__container">
                    <h5 className="indicator-section-title inside-title">{section.title}</h5>
                    <p className="indicator-section-description">{section.text}</p>
                    <div className="epigenetic-heartbeat__images-section">
                        {
                            section.images.map((image, index) => {
                                return (
                                    <div className="heartbeat-image" key={index}>
                                        <img src={image} alt={`${section.title.replace(/ /g, '-')}${index}`} />
                                    </div>
                                );
                            })
                        }
                    </div>
                    <p className="indicator-section-description last-section">{section.description}</p>
                    <p className="read-more-link"><a href={section.link_url} target="_blank" rel="noreferrer">Read more</a></p>
                </div>
            </div>
        </section>
    );
};

export default EpigeneticAndHeartbeat;