import React, { Component } from 'react';

export default class Textarea extends Component {
    render() {
        const {id, isRequired, value, onChange} = this.props;

        return (
            <textarea name={id} id={id} value={value} onChange={onChange}/>
        );
    }
}