import moment from 'moment';
import { checkValueInRange, checkValueWithOperator, getRangeValueNumber, getRangeValueOperator } from '../bloodResults/utils';

/**
 *
 * @param {string | null} interpretation
 * @param {number} deltaAge
 * @param {{ [k: string]: string[] }} bioAgeRule
 * @returns string | null
 */
export function getInterpretation(interpretation, deltaAge, bioAgeRule) {
  if (interpretation) {
    return interpretation;
  }

  // Recursively goes through all the ranges in the nested rule.
  // Returns the first rule that satisfies all the conditions in the `rule.value` constraints.
  const result = Object.entries(bioAgeRule).find(([_, value]) => {
    return value.every((rangeExp) => {
      return checkValueWithOperator({
        value: deltaAge,
        operator: getRangeValueOperator(rangeExp),
        operatorValue: getRangeValueNumber(rangeExp),
      });
    });
  });

  if (!result) {
    return null;
  }

  return result[0];
}

/**
 * Returns the Patient's Chronological Age from their Biomarker Test Result.
 *
 * @param {string} collectionDate
 * @param {object} result
 * @param {object} testMetadata
 * @returns number | null
 */
export const getChronologicalAge = (collectionDate, result, testMetadata) => {
  const { resultMetadata } = result;

  const chronologicalAge = resultMetadata?.chronologicalAge;
  if (chronologicalAge) {
    return Number(chronologicalAge);
  }

  const dateOfBirth = testMetadata?.dateOfBirth;
  if (dateOfBirth) {
    return Number(moment(collectionDate).diff(dateOfBirth, 'years'));
  }

  return null;
};

export function getNestedAgeRangeFromInterpretationRule(chronologicalAge, rule) {
  if (!chronologicalAge) {
    return null;
  }

  return rule.value.find(value => {
    if (value?.ageRange?.length < 2) {
      const operator = getRangeValueOperator(value.ageRange[0]);
      const value = getRangeValueNumber(value.ageRange[0]);
      return checkValueWithOperator({ value: chronologicalAge, operator, operatorValue: value });
    }

    const ageInRange = checkValueInRange({
      value: chronologicalAge,
      minValue: value?.ageRange[0],
      maxValue: value?.ageRange[1],
    });

    return ageInRange;
  });
}

export function getRangesFromBiomarkerResult(collectionDate, result, testMetadata) {
  const {
    biomarker: { interpretationRule },
  } = result;
  const chronologicalAge = getChronologicalAge(collectionDate, result, testMetadata);

  switch (interpretationRule.rule) {
    case 'RANGE':
      return interpretationRule;
    case 'LEVEL':
      return interpretationRule;
    case 'BIOAGE':
      return interpretationRule;
    case 'AGE':
      return getNestedAgeRangeFromInterpretationRule(chronologicalAge, interpretationRule);
    default:
      return null;
  }
}
