import React from 'react';

import CompareGraph from '../components/compareGraph';

const RenderCompareSection = (props) => {
    if(!props) {
        return null;
    }

    const { count, analysis_text, title, text, low_label, high_label } = props;

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <CompareGraph
                data={{count, analysis_text, low_label, high_label}}
                labels={{
                    left: '/img/you/actionable-you/alcohol-consumption/alcohol-consumption-left-label.svg',
                    right: '/img/you/actionable-you/alcohol-consumption/alcohol-consumption-right-label.svg'
                }}
            />
        </div>
    );
};

const CompareAlcoholFootprint = ({customComparisons}) => {
    if (!customComparisons || Array.isArray(customComparisons)) {
        return null;
    }

    const { title, text, everyone_alcohol } = customComparisons;

    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__graph-section no-padding">
                {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                {text ? <p className="indicator-section-description">{text}</p> : ''}
                {RenderCompareSection(everyone_alcohol)}
            </div>
        </section>
    );
};

export default CompareAlcoholFootprint;
