import React from 'react';
import moment from 'moment';
import config from '../../config';
import Loader from '../../components/common/loader';
import { useSelector } from 'react-redux';
import { singleCovidResultSelector } from '../../services/you/selectors';
import { TEST_COLOR } from './PastResultsPanels';
import { Spinner } from '../../../icons';
import { StyledButton } from '../../theming/themed-components';

export const LatestDetailedResultPanel = ({
  history,
  onDownload,
  isLoading,
  isFtfTest = false,
  isTtrTest = false,
  showView = true,
  spinnerDisplay,
}) => {
  const test = useSelector(singleCovidResultSelector);

  const handleView = () => history.push(`/dashboard/covid/result/${test.id}`);
  const handleNext = () => history.push('/dashboard/covid/test-timeline/select-test');
  const { can_promote_to } = config.app;

  const registered_at =
    test && test.registered_at_timezone === null
      ? moment(test.registered_at).format('DD/MM/YY h:mm a')
      : test && test.registered_at_timezone !== null
      ? moment(test.localised_registered_at).format('DD/MM/YY h:mm a')
      : null;
  const report_date =
    test && test.lab_processed_at && test.registered_at_timezone === null
      ? moment(test.lab_processed_at).format('DD/MM/YY h:mm a')
      : test && test.lab_processed_at && test.registered_at_timezone !== null
      ? moment(test.localised_lab_processed_at).format('DD/MM/YY h:mm a')
      : null;
  const timezone =
    test && test.registered_at_timezone === null
      ? ''
      : test && test.registered_at_timezone !== null
      ? ' (GMT ' + (test.registered_at_timezone < 0 ? '' : '+') + test.registered_at_timezone + ')'
      : '';
  const displaySpin = spinnerDisplay ? 'block' : 'none';
  return (
    <div className="test-dashboard-section__container full-width">
      <div className="content-container">
        <div className="section-content">
          {!test && !isLoading ? <div className="section-empty">Nothing to show</div> : null}
          {!test && isLoading ? <Loader /> : null}

          {test ? (
            <>
              <div className="split-row-group">
                {showView ? (
                  <div className="icon-row" onClick={handleView} title="View your test result">
                    <img src="/img/eye.svg" alt="" />
                    View Details
                  </div>
                ) : null}
                <div className="icon-row" onClick={() => onDownload(test)} title="Download your text">
                  <span>
                    <img src="/img/download.svg" alt="" />
                  </span>
                  <span>Download</span>
                  <span id="spinner" style={{ width: '25px', display: displaySpin }}>
                    <Spinner />
                  </span>
                </div>
              </div>

              <div
                className={`test-card ${isTtrTest ? 'ttr-card' : ''}`}
                style={!isTtrTest ? { backgroundColor: TEST_COLOR[test.result.toLowerCase()] || TEST_COLOR.default } : {}}
                onClick={handleView}
              >
                <div className="split-row-group">
                  <img src={isTtrTest ? '/img/test/logo_black.svg' : '/img/test/logo.svg'} alt="" />
                  {isFtfTest || isTtrTest ? (
                    <div className="test-icon">
                      <img src={isTtrTest ? '/img/test/test_ttr.svg' : '/img/test/test_ftf_white.svg'} alt="" />
                      <label>{isTtrTest ? 'Test-to-release' : 'Fit-to-fly'}</label>
                    </div>
                  ) : null}
                </div>
                <div className="split-row-group split-end">
                  <div>
                    <div className="lighten-text">{test.test_user_full_name}</div>
                    <div
                      className="section-large-title"
                      style={
                        isTtrTest ? { marginTop: 5, color: TEST_COLOR[test.result.toLowerCase()] || TEST_COLOR.default } : { marginTop: 5 }
                      }
                    >
                      {test.result}
                    </div>
                  </div>
                </div>

                <div className="result-detail-panel">
                  {isTtrTest ? <div className="bar-width" /> : null}
                  <div className="split-row-group">
                    <div className="left-etch" />
                    <div className="right-etch" />
                  </div>
                  {test.passport_number || test.dob ? (
                    <div className="split-row-group split-end">
                      {test.passport_number ? (
                        <div>
                          <div className="field-text">Passport/ID number</div>
                          <div className="field-value">{test.passport_number}</div>
                        </div>
                      ) : null}
                      {test.dob ? (
                        <div>
                          <div className="field-text">DOB</div>
                          <div className="field-value">{moment(test.dob).format('DD/MM/YY')}</div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="split-row-group split-end">
                    <div>
                      <div className="field-text">Test Type</div>
                      <div className="field-value">{test.test_type_description}</div>
                    </div>
                  </div>
                  <div className="split-row-group split-end">
                    <div>
                      <div className="field-text">Product</div>
                      <div className="field-value">
                        {test.product != null ? test.product.product_test_type + ' - ' + test.product.product_purpose : null}
                      </div>
                    </div>
                  </div>

                  <div className="split-row-group split-end">
                    <div>
                      <div className="field-text">Collection Date</div>
                      <div className="field-value">
                        {registered_at}
                        {timezone}
                      </div>
                    </div>
                  </div>
                  <div className="split-row-group split-end">
                    <div>
                      <div className="field-text">Report Date</div>
                      <div className="field-value">
                        {report_date}
                        {timezone}
                      </div>
                    </div>
                  </div>
                  <div className="split-row-group split-end">
                    <div>
                      <div className="field-text">Barcode</div>
                      <div className="field-value">{test.barcode}</div>
                    </div>
                  </div>
                  <div className="split-row-group split-end">
                    <div>
                      <div className="field-text">Sample Type</div>
                      <div className="field-value">{test.sample_type}</div>
                    </div>
                  </div>
                </div>
              </div>

              {test.rerun_test ? <div className="run-text">{test.rerun_test}</div> : null}

              {can_promote_to ? (
                <StyledButton className="btn btn--full-width" style={{ marginTop: 30 }} onClick={handleNext} type="button">
                  Buy another test
                </StyledButton>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
