import React, { Component } from 'react';

import Select from '../select';

const defaults = {
    min: 0,
    max: 100,
    order: 'desc',
};

export default class Age extends Component {
    constructor(props) {
        super(props);

        const {options} = this.props;

        const choices = [];
        const min = options.min ? parseInt(options.min, 10) : defaults.min;
        const max = options.max ? parseInt(options.max, 10) : defaults.max;
        const order = options.order ? options.order : defaults.order;

        if(order == 'desc') {
            for(let i=max; i>= min; i--) {
                choices.push({value: i});
            }
        }
        else {
            for(let i=min; i<=max; i++) {
                choices.push({value: i});
            }
        }

        this.state = {
            choices: choices,
            value: ''
        };

        this.handelOnChange = this.handelOnChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onBlur(event) {
        this.setState({value: event.target.value});
    }

    handelOnChange(e) {
        const value = e.target.value;
        const {id, onChange} = this.props;

        this.setState({value: value});

        onChange(id, value);
    }

    renderAgeValidation() {
        const { options: {show_age_validation} } = this.props;
        const { value } = this.state;

        if(!(show_age_validation && value)) {
            return null;
        }

        return <div className="date-confirmation">You're {value} years old, is this correct?</div>;
    }

    render() {
        return (
            <div>
                <Select
                    {...this.props}
                    choices={this.state.choices}
                    onBlur={this.onBlur}
                    onChange={this.handelOnChange}
                    style={{width: '130px'}}
                />
                {this.renderAgeValidation()}
            </div>
        );
    }
}