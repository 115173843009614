import React from 'react';
import { Link } from 'react-router-dom';
import {Tooltip} from 'react-tippy';

import SplitConsumer from '../../../../components/context/splitConsumer';

import CompareGraph from '../components/compareGraph';

const RenderCompareSection = (everyone_cardiovascular) => {
    if(!everyone_cardiovascular) {
        return null;
    }

    const { count, analysis_text, title, text, low_label, high_label } = everyone_cardiovascular;

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <CompareGraph
                data={{count, analysis_text, low_label, high_label}}
                labels={{
                    left: '/img/you/actionable-you/cardiovascular-fitness/cardiovascular-left-label.svg',
                    right: '/img/you/actionable-you/cardiovascular-fitness/cardiovascular-right-label.svg'
                }}
            />
        </div>
    );
};

const RenderYou = (you) => {
    if(!you) {
        return null;
    }

    return (
        <div className="comparison-graph__you-section">
            <div className="tooltip-container" style={{left: `calc(${you.percent}% - 1px)`}}>
                <Tooltip
                    arrow={true}
                    arrowSize="small"
                    html={<div className="methylation-graph__tooltip-text">{you.label || 'You'}</div>}
                    open={true}
                    position="top"
                    theme="light"
                >
                    <div className="tooltip-line" />
                </Tooltip>
            </div>
            <div className="other-point" style={{left: `calc(${you.percent}% - 3px)`}} />
        </div>
    );
};

const RenderGraph = (data) => {
    if(!(data && Object.keys(data).length)) {
        return null;
    }

    const { others, x, you } = data;

    return (
        <div className="comparison-graph__container">
            <div className="comparison-graph__bar">
                {
                    others.map((other, index) => (
                        <div className="comparison-graph__other-section" key={index}>
                            <div className="other-image-section" style={{left: `calc(${other.percent}% - 18px)`}}>
                                <p className="other-label">{other.label}</p>
                                <div className="other-image">
                                    <img src={other.icon_url} alt={`other-${index}`} />
                                </div>
                            </div>
                            <div className="other-point" style={{left: `calc(${other.percent}% - 3px)`}} />
                        </div>
                    ))
                }
                {RenderYou(you)}
            </div>
            <div className="comparison-graph__labels">
                <p>{x.min_label}</p>
                <p className="graph-label">{x.label}</p>
                <p>{x.max_label}</p>
            </div>
        </div>
    );
};

const RenderSportsSection = (sports) => {
    if(!sports) {
        return null;
    }

    const { title, text, data, analysis_text } = sports;

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <div>
                {RenderGraph(data)}
                {analysis_text ?
                    <div className="analysis-text analysis-text--wide">
                        <div className="comparison__analysis-section">
                            <div className="analysis-image">
                                <img alt="analysis-label" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                            </div>
                            <div className="comparison-analysis-text">{analysis_text}</div>
                        </div>
                    </div> : ''
                }
            </div>
        </div>
    );
};

const Comparisons = ({customComparisons}) => {
    if (!customComparisons || Array.isArray(customComparisons)) {
        return null;
    }

    const { title, text, sports, everyone_cardiovascular } = customComparisons;

    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__graph-section no-padding">
                {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                {text ? <p className="indicator-section-description">{text}</p> : ''}
                {RenderCompareSection(everyone_cardiovascular)}
                {RenderSportsSection(sports)}
            </div>
            <SplitConsumer name="show_compare_feature">
                {(value) => value == 'on' ?
                    <div className="biological-age__graph-section no-padding">
                        <Link className="btn" to="/dashboard/you/compare">see more comparisons</Link>
                    </div> : ''
                }
            </SplitConsumer>
        </section>
    );
};

export default Comparisons;