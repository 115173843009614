import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import config from '../../config';
import SubMenuBar from '../../components/common/subMenuBar';
import RestoreScroll from '../../components/common/restoreScroll';
import SplitConsumer from '../../components/context/splitConsumer';
import Futures from '../../components/you/futures';

import MyTeam from './team';
import MyImprovements from './myImprovements';

const helpSection = {
    title: 'Looking for more information?',
    description: 'Check out the help section to find more information',
    buttonText: 'View Help',
    link: config.faqUrl
};

const improvementPageLink = [
    {type: 'link', title: 'Back', slug: 'overview', link: 'improvements'},
];

const futuresPageLinks = [
    {type: 'link', title: 'Overview', slug: 'overview', link: 'improvements/future-predictions/overview'},
    {type: 'link', title: 'The Science', slug: 'science', link: 'improvements/future-predictions/science'},
    {type: 'link', title: 'FAQs', slug: 'faq', link: 'improvements/future-predictions/faq'},
];

const Improvements = ({match, location,breakpoint, openChat}) => {

    const ImprovementsPage = (props) => (
        <MyImprovements {...props} helpSection={helpSection} />
    );

    const TeamPage = () => <MyTeam helpSection={helpSection} openChat={openChat}/>;

    const FuturesPage = (props) => {
        let selectedTab = 'overview';

        if (props.location.pathname.includes('faq')) {
            selectedTab = 'faq';
        } else if(props.location.pathname.includes('science')) {
            selectedTab = 'science';
        }

        return (
            <SplitConsumer name="show_future_predictions">
                {(value) => value == 'on' ?
                    <RestoreScroll>
                        <Futures {...props} breakpoint={breakpoint} selectedTab={selectedTab}/>
                    </RestoreScroll> : <Redirect to={match.url} />
                }
            </SplitConsumer>
        );
    };

    return (
        <div>
            <SubMenuBar
                goBack={true}
                hideMenuBar={true}
                internalLinks={location.pathname.includes('future-predictions') ? futuresPageLinks : []}
                navigation={improvementPageLink}
                show={location.pathname.includes('team') || location.pathname.includes('future-predictions')}
            />
            <div className="improvement-container">
                <Switch>
                    <Route
                        path={`${match.url}/future-predictions`}
                        render={FuturesPage}
                    />
                    <Route
                        path={`${match.url}/team`}
                        render={TeamPage}
                    />
                    <Route
                        path={match.url}
                        render={ImprovementsPage}
                    />
                </Switch>
            </div>
        </div>
    );
};

Improvements.defaultProps = {
    breakpoint: 'large',
};

Improvements.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    breakpoint: PropTypes.string,
};

export default Improvements;
