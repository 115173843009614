import axios from 'axios';
import { mapBrandingToState } from './reducer';
import { getPartnerConfigurationUrl } from '../apiHelpers/partnerServiceRequests';

const mapSuccess = response => {
  return response.data || {};
};

const mapError = error => {
  const data = error.response.data;
  return {
    errorResponse: error.response,
    error: { message: data.message, code: data.code },
  };
};

export const getPartnerBranding = async ({ partnerCode, hideFooter, hideHeader }) => {
  return axios
    .get(`${getPartnerConfigurationUrl({})}?partnerCode=${partnerCode}`)
    .then(response => savePartnerBrandingToLocalStorage({ partnerCode, hideFooter, hideHeader, response }))
    .then(mapSuccess)
    .catch(mapError);
};

const savePartnerBrandingToLocalStorage = ({ partnerCode, hideFooter, hideHeader, response }) => {
  if (response) {
    localStorage.setItem(
      'partnerBranding',
      JSON.stringify({
        partnerCode,
        hideFooter: Boolean(hideFooter),
        hideHeader: Boolean(hideHeader),
        hideHeaderAlways: response.data.hideHeader,
        hideFooterAlways: response.data.hideFooter,
        theme: mapBrandingToState(response.data),
        partnerConfig: {
          displayedFeatures: response.data.displayedFeatures,
          displayedPostResultActionFeatures: response.data.displayedPostResultActionFeatures,
        },
      }),
    );
  }
  return response;
};
