import React, { Component } from 'react';
import update from 'immutability-helper';
import utils from '../../../helpers/utils';


export default class TimeField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: this.parseTimeFormat(this.props.value),
        };

        this.handelOnChange = this.handelOnChange.bind(this);
    }

    parseTimeFormat(timeStr) {
        const parts = timeStr.split(':');
        const returnElem = {
            hour: '',
            minutes: '',
            ampm: '',
        };

        //Does have am/pm on it?
        if(parts.length == 3) {
            returnElem.hour = utils.isNumeric(parts[0]) ? parseInt(parts[0], 10) : '';
            returnElem.minutes = utils.isNumeric(parts[1]) ? parseInt(parts[1], 10) : '';
            returnElem.ampm = parts[2];
        }
        else if(parts.length == 2) {
            const hourNumeric = parseInt(parts[0], 10);
            if(hourNumeric > 12) {
                returnElem.hour = hourNumeric-12;
                returnElem.ampm = 'pm';
            }
            else {
                returnElem.hour = hourNumeric;
                returnElem.ampm = 'am';
            }

            returnElem.minutes = parts[1];
        }

        return returnElem;
    }

    handelOnChange(e) {
        const {fields} = this.state;
        const {id, onChange} = this.props;

        const name = e.target.name;
        const value = e.target.value;

        //Value shouldnt be greater than 2 for time fields..
        if(value.length <= 2) {
            this.setState(update(this.state, {fields: {[name]: {$set: value}}}), () => {
                //need to use this.state here as fields is stale
                let timeStr = this.state.fields.ampm == 'pm' ? parseInt(this.state.fields.hour, 10)+12 : this.state.fields.hour;
                timeStr += ':' + this.state.fields.minutes;

                onChange(id, timeStr);
            });

            
        }


    }

    render() {
        const {fields} = this.state;
        const {id, isRequired, value, onChange, options} = this.props;


        const showAmPm = (options.hasOwnProperty('ampm') && options['ampm']===false) ? false : true;

        return (
            <div className="questionnaire-time custom__time-field">
                <input type="text" name="hour" id={id+'_hour'} value={fields.hour} onChange={this.handelOnChange}/>
                <span className="questionnaire-time__divider">:</span>
                <input type="text" name="minutes" id={id+'_minutes'} value={fields.minutes} onChange={this.handelOnChange}/>

                {showAmPm &&
                    <div className="form__select-wrapper questionnaire-time__select-wrapper">
                        <select name="ampm" id={id+'_ampm'} value={fields.ampm} onChange={this.handelOnChange}>
                            <option value="am">AM</option>
                            <option value="pm">PM</option>
                        </select>
                        <i className="icon-down-open"></i>
                    </div>
                }
            </div>
        );
    }
}