import React, { useState } from 'react';
import { TestToReleaseStep01 } from './TestToReleaseStep01';
import { TestStep02 } from '../common/TestStep02';
import { TestToReleaseStep03 } from './TestToReleaseStep03';

export const TestToRelease = ({ history, match }) => {
  const [availability, setAvailability] = useState();
  const [flightDate, setFlightDate] = useState();

  const step = match.params.step;
  if (step === '2') {
    return <TestStep02 history={history} testType="test-to-release" flightType="inbound" onAvailability={setAvailability} onFlightDate={setFlightDate} />;
  } else if (step === '3' && availability && flightDate) {
    return <TestToReleaseStep03 history={history} availability={availability} flightDate={flightDate} />;
  } else {
    return <TestToReleaseStep01 history={history} />;
  }
};
