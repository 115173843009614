import PropTypes from 'prop-types';

const DiseaseRisk = ({ title, text, diseases }) => (
  <div className="biological-age__disease-risk-container">
    <div className="disease-risk__container">
      <div>
        <h3 className="indicator-section-title">{title}</h3>
        <p className="indicator-section-description">{text}</p>
      </div>
    </div>

    <div className="disease-risk__diseases-section">
      {diseases.map(disease => (
        <div className="disease-risk__disease-card" key={disease.title}>
          <div className="header-section">
            <h5 className="disease-title">{disease.title}</h5>
            <div className="disease-image">
              <img alt={disease.title} src={disease.iconUrl} />
            </div>
          </div>

          <p className="disaease-description">{disease.text}</p>
          <a className="disease-read-link" href={disease.link.url} target="_blank" rel="noreferrer">
            {disease.link.text}
          </a>
        </div>
      ))}
    </div>
  </div>
);

DiseaseRisk.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  diseases: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      iconUrl: PropTypes.string.isRequired,
      link: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
};

export default DiseaseRisk;
