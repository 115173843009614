import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import config from '../../config';
import AccordionNew from '../../components/common/accordionNew';
import { getCovidResults, getCovidResultRequest } from '../../services/you/actions';
import { covidResultSelector, singleCovidResultSelector } from '../../services/you/selectors';
import { SmallContainer } from '../../components/SmallContainer';
import { LatestDetailedResultPanel } from './LatestDetailedResultPanel';
import { PastResultsPanels } from './PastResultsPanels';
import { TestProgressBasic } from './TestProgressBasic';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

const FaqData = [
  {
    title: 'What does it mean if SARS-CoV-2 is detected?',
    description:
      'A positive result indicates that RNA from SARS-CoV-2 was detected, you are presumptively infected with the virus and presumed to be contagious. Please contact your local health provider via the recommended channel and follow current local recommended guidelines. Laboratory test results should always be considered in the context of clinical observations and epidemiological data for making final diagnosis and treatment management decisions. ',
  },
  {
    title: 'What does it mean if SARS-CoV-2 is not detected?',
    description:
      'A negative test result indicates that SARS-CoV-2 RNA was not present in the specimen above the limit of detection. However, a negative result does not rule out current or future infection by SARS-CoV-2 and should not be used as the sole basis for treatment management decisions. Your recent exposure or clinical presentation should be fully considered, including if other diagnostic tests for other causes of illness (e.g., other respiratory illness) are negative. If your health deteriorates or if SARS-CoV-2 infection is still suspected based on exposure history together with other clinical findings, please contact your local health authority and re-testing should be considered.',
  },
  {
    title: 'What does it mean if SARS-CoV-2 detection is inconclusive?',
    description:
      'An inconclusive result means the test results did not meet the requirements to be reported as detection of SARS-CoV-2 viral RNA in the sample, and should be treated clinically as not detected. However, there will have been partial detection of a signal from SARS-CoV-2 that warrants notification under duty of care for further investigation. Provisions for further testing are recommended in consultation with your doctor or local health authority, and as with a negative result, your full health history should be considered.',
  },
  {
    title: 'What does a failed result mean?',
    description:
      'A failed result means it was not possible to accurately process your test in the laboratory. This is normally due to an incorrect saliva sample being provided (ie not providing enough spit, not mixing with the stabilizing fluid or eating/drinking before the test). We recommended taking another test as soon as possible.',
  },
  {
    title: 'What does a not processed result mean?',
    description:
      'A not processed result means it was not possible to process your sample at all. This may be due to factors such as leaking on arrival or no sample provided in the tube.  We recommended taking another test as soon as possible.',
  },
  {
    title: 'What should I do if I have adverse effects from the test?',
    description:
      'Our test is extremly safe, but should you suffer any adverse affects please report them via https://www.chronomics.com/covid-adverse-effects',
  },
  {
    title: 'What does it mean is my test is being rerun?',
    description:
      'In some cases, a sample can fail our quality control lab protocols (e.g. the sample was not taken correctly, the result is too close to the acceptance thresholds). In the case of a failed or inconclusive result, it is our standard lab protocol to rerun the test to eliminate any lab error. You will be informed when this is complete (which may be another 24 hours, excluding weekends).',
  },
];

export const CovidResultDashboard = ({ history, match }) => {
  const dispatcher = useDispatch();
  const isBranded = cachedPartnerTheming();

  const testId = match.params ? match.params.testId : undefined;
  const covidResults = useSelector(covidResultSelector);
  const currentTest = useSelector(singleCovidResultSelector);

  const [spinnerDisplay, setSpinnerDisplay] = useState(false);

  useEffect(() => {
    dispatcher(getCovidResults(true));
    dispatcher(getCovidResultRequest(testId));
  }, [testId]);

  const downloadCertificate = test => {
    const { id, registered_at, lab_processed_at } = test;
    const { fname, lname } = config.app.test_user;
    setSpinnerDisplay(true);
    axios
      .request({
        url: `/test_user/covid/${id}/download_certificate`,
        method: 'GET',
      })
      .then(({ data }) => {
        setSpinnerDisplay(false);
        if (data) {
          const link = document.createElement('a');
          link.href = data;
          link.setAttribute(
            'download',
            `${fname.toLowerCase()}_${lname.toLowerCase()}_${moment(lab_processed_at ? lab_processed_at : registered_at).format(
              'D_M_YYYY',
            )}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch(err => {
        setSpinnerDisplay(false);
        console.error(err);
      });
  };

  const { can_promote_to } = config.app;
  let latest = covidResults && covidResults.latest_result ? covidResults.latest_result : undefined;
  let allResults = covidResults ? covidResults.all_results : undefined;
  const isTtrTest = currentTest && currentTest.product && currentTest.product.product_code === 'C1COV01400';
  const isFtfTest = currentTest && currentTest.product && currentTest.product.product_code === 'C1COV01800';
  const isRapidAntigen = currentTest && currentTest.product && currentTest.product.product_code === 'C1COV02200';

  if (allResults && latest) {
    if (testId) {
      latest = allResults.find(({ id }) => `${id}` === testId);
    }
    if (latest) {
      allResults = allResults.filter(({ barcode }) => barcode !== latest.barcode);
    }
  }
  return (
    <section className={`test-dashboard-section content-wrapper-animate${!isBranded ? ' default-theme-background' : ''}`}>
      <div className="test-dashboard-section__section_content standard-flow">
        <div>
          <div className="row-container full-width">
            <div>
              {latest ? <TestProgressBasic result={latest} /> : null}
              <LatestDetailedResultPanel
                history={history}
                isLoading={!covidResults}
                result={latest}
                isFtfTest={isFtfTest}
                isTtrTest={isTtrTest}
                showView={false}
                onDownload={downloadCertificate}
                spinnerDisplay={spinnerDisplay}
              />
            </div>
            <div className="container-comment">
              {currentTest &&
                currentTest.result_copy &&
                currentTest.result_copy.map(copy => (
                  <Fragment key={copy.title}>
                    <label dangerouslySetInnerHTML={{ __html: copy.title }} />
                    <p dangerouslySetInnerHTML={{ __html: copy.text }} />
                  </Fragment>
                ))}
              {currentTest && currentTest.help_copy ? (
                <>
                  <label dangerouslySetInnerHTML={{ __html: currentTest.help_copy }} />
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="main-container full-width">
          <div className="row-container">
            <PastResultsPanels
              columns={2}
              history={history}
              isLoading={!covidResults}
              results={allResults}
              onDownload={downloadCertificate}
            />
          </div>

          {can_promote_to ? (
            <div className="row-container single">
              <SmallContainer
                bgColor="#FEECEB"
                title="Wondering if your loved ones are safe?"
                subtitle="Buy for family member"
                onClick={() => history.push('/dashboard/covid/test-timeline/select-test')}
              />
            </div>
          ) : null}

          <div className="row-container">
            <SmallContainer
              bgColor="#FFF0D7"
              title="How at risk are you from Covid?"
              subtitle="Use the free tool to find out if your health puts you at risk"
              onClick={() => (window.location.href = 'https://covid.chronomics.com/risk-calculator')}
            />
            <SmallContainer
              bgColor="#E2F9FB"
              title="Am I immune to Covid-19 now?"
              subtitle="And how long does immunity last?"
              onClick={() => history.push('/dashboard/article/am-i-immune-to-covid-19')}
            />
          </div>

          {/* <div className="row-container">
                <div className="test-dashboard-section__container">
                  <div className="content-container">
                    <div className="section-content">
                        <div className="section-title">
                          Some useful information about Covid testing
                        </div>
                        <div className="section-sub-title">
                          Why Covid testing is the key to getting back to normal?
                        </div>
                        <div className="section-sub-title">
                          What is test-to-release for international travel 
                        </div>
                        <div className="section-sub-title">
                          Timeline of Coronavirus symptoms detailed and when should I get a test
                        </div>
                    </div>
                  </div>
                </div>

                <div className="test-dashboard-section__container">
                  <div className="content-container">
                    <div className="section-content">
                        <div className="section-title">
                          Long term health
                        </div>
                        <div className="section-sub-title">
                          How at risk are you from COVID-19? Find out if your health is putting you at risk
                        </div>
                        <div className="section-sub-title">
                          Did you know that your body might be older or younger than what your passport says?
                        </div>
                        <div className="section-sub-title">
                          Take action: preventative healthcare in a COVID-19 world
                        </div>
                    </div>
                  </div>
                </div>
              </div> */}

          {!isRapidAntigen && (
            <div className="covid-result__test-details-container">
              <div className="test-result-info">
                <div className="info-title">FAQs</div>
                {FaqData.map((item, index) => (
                  <AccordionNew title={item.title} content={item.description} key={index} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
