import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const withError = ({reducer, page}) => (WrappedComponent) => {
    class HOComponent extends Component {

        componentDidUpdate(prevProps) {
            if(this.props[page] && (prevProps[page] !== this.props[page])) {
                this.setState({}, () => {throw new Error(this.props[page]);});
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state) => ({
        [page]: state.services[reducer][`${page}Error`],
    });

    return connect(mapStateToProps)(HOComponent);
};

withError.propTypes = {
    reducer: PropTypes.string,
    page: PropTypes.string,
};

export default withError;
