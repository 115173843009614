import React from 'react';
import moment from 'moment';
import { StyledButton } from '../../../../theming/themed-components';

export const TestToReleaseStep03 = ({ availability, flightDate, history }) => {
  const date = moment(availability, 'YYYY-MM-DD').format('DD/MM/YYYY');
  const shortDate = moment(availability, 'YYYY-MM-DD').format('DD/MM');
  const handleOrder = () => history.push('/join/account?slug=covid-19-ttr&utm_source=chronomics&utm_medium=app&utm_campaign=Testing%20Timeline');
  return (
    <section className="test-dashboard-section content-wrapper-animate">
        <div className="test-dashboard-section__section_content centered">
            <img className="back-button" src="/img/back.svg" alt="" onClick={() => history.goBack()} />
            
            <div className="test-dashboard-section__container">
                <div className="content-container">
                <div>
                    <div className="section-title">
                        Testing timeline calculator
                    </div>
                    <div className="section-sub-title">
                        for your Test to release
                    </div>
                    <div className="section-sub-title">
                        Inbound flight: {flightDate}<br />
                        You should complete the test on day 5 of self isolation<br />
                    </div>

                    <div className="panel-container">
                        <div className="sub-panel">
                            <div>
                                <div>
                                    When should I order to receive the booking reference for passenger locator form?
                                    <br /><span>Now</span>
                                </div>
                                <div>
                                    Your kit will be shipped on
                                    <br /><span>{shortDate}</span><br />
                                    or the first working day after
                                </div>
                            </div>
                        </div>

                        <div className="sub-panel">
                            <div>
                                <div>
                                    Return once complete
                                </div>
                                <div>
                                    Get your result in
                                    <br /><span>24-36 </span>hrs<br />
                                    of being received at the lab 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <StyledButton
                    className="btn cancel"
                    onClick={handleOrder}
                    type="button"
                >
                    Order Now
                </StyledButton>
                <div className="button-link" onClick={() => history.push('/dashboard/covid')}>
                    Clear result
                </div>
                </div>
            </div>
        </div>
    </section>
  );
};
