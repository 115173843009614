import React from 'react';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Line, Text, ReferenceLine, Label } from 'recharts';
import { Link } from 'react-router-dom';

import Question from '../../../../components/common/question';
import SplitConsumer from '../../../../components/context/splitConsumer';

const MenIcon = () => (
    <svg width="23px" height="52px" viewBox="0 0 23 52">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-593.000000, -7288.000000)" fill="#D5D5D5" fillRule="nonzero">
                <g transform="translate(295.000000, 7091.000000)">
                    <g transform="translate(37.000000, 197.000000)">
                        <g transform="translate(261.000000, 0.000000)">
                            <circle id="Oval" cx="11.2269897" cy="4.507" r="4.507" />
                            <path d="M12.7969897,8.763 C11.6739897,8.535 10.4529897,8.545 9.34998966,8.805 C1.85698966,9.683 -0.576010339,18.356 0.110989661,24.969 C0.408989661,27.828 4.91598966,27.858 4.61498966,24.969 C4.36498966,22.559 4.47198966,18.922 5.75298966,16.337 C5.75298966,19.479 5.75298966,22.621 5.75298966,25.762 C5.75298966,25.873 5.76398966,25.977 5.76898966,26.084 C5.76598966,26.135 5.75398966,26.178 5.75398966,26.23 C5.75398966,33.709 5.74098966,41.185 5.43198966,48.658 C5.29498966,51.98 10.4459897,51.967 10.5819897,48.658 C10.8239897,42.801 10.8849897,36.941 10.8989897,31.08 C11.1429897,31.096 11.3869897,31.096 11.6309897,31.082 C11.6459897,36.943 11.7049897,42.803 11.9449897,48.658 C12.0819897,51.967 17.2329897,51.98 17.0949897,48.658 C16.7859897,41.185 16.7749897,33.709 16.7749897,26.23 C16.7749897,25.998 16.7439897,25.787 16.6969897,25.584 C16.6899897,22.337 16.5659897,19.087 16.6039897,15.842 C18.1379897,18.439 18.2779897,22.4 18.0119897,24.967 C17.7099897,27.854 22.2179897,27.825 22.5159897,24.967 C23.2189897,18.217 20.6689897,9.319 12.7969897,8.763 Z" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const RenderDot = (props) => {
    const { cx, cy, value, key, payload, dataKey, breakpoint } = props;

    if(!value) {
        return null;
    }

    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');

    return (
        <g key={key}>
            <circle cx={cx} cy={cy} r={isMobile ? 20 : 25} stroke="#5B7BFF" strokeWidth={isMobile ? 2 : 3} fill="#FFFFFF" />
            <Text className="dot-value" textAnchor="middle" x={cx} y={cy+(isMobile ? 5 : 7)} fill="black" fontSize={'12'}>{(dataKey == 'value_future' && !payload.value) ? '?' : value}</Text>
        </g>
    );
};

const RenderComparingSection = (everyone_age_delta) => {
    if(!everyone_age_delta) {
        return null;
    }

    const fullCount = [...Array(10).keys()];

    const { title, text, count, analysis_text } = everyone_age_delta;
    return (
        <div data-tut="comparison-factor" className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <div className="compare-graph-section">
                <div className="compare-graph">
                    {
                        fullCount.map((key) => (
                            <div className={`compare-icon-container ${key < Math.round(count) ? 'add-colour' : ''}`} key={key}>
                                <MenIcon />
                            </div>
                        ))
                    }
                </div>
                {analysis_text ? <p className="analysis-text">{analysis_text}</p> : ''}
            </div>
        </div>
    );
};

const RenderCenturianSection = (super_centenarians, breakpoint, disableLink) => {
    if(!super_centenarians || !data) {
        return null;
    }

    const { title, text, graph: {data, label_x, label_y, ledgend}, analysis_text, json_schema } = super_centenarians;

    

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <div className="comparison__graph-container">
                <div className="graph-section-container">
                    <div className="graph-section">
                        <ResponsiveContainer height="100%" width="100%">
                            <LineChart data={data} margin={{bottom: 30}}>
                                <XAxis axisLine={false} tickline={false} dataKey="x" type="number" domain={['dataMin', 'dataMax']} hide={true} />
                                <YAxis axisLine={false} tickline={false} dataKey="value_future" type="number"  domain={[0, 'dataMax']} hide={true} />
                                <ReferenceLine key="y-line" x={0} stroke="#BABABA" strokeDasharray="10 8">
                                    <Label value={label_y} position="insideTopLeft" offset={10} className="graph-label" />
                                </ReferenceLine>
                                <ReferenceLine key="x-line" y={0} stroke="#BABABA" strokeDasharray="10 8">
                                    <Label value={label_x} position="bottom" offset={13} className="graph-label" />
                                </ReferenceLine>
                                <Line type="linear" dataKey="value_future" stroke="#5B7BFF" strokeWidth="3" id="value_future" strokeDasharray="15 5" dot={false} />
                                <Line type="linear" dataKey="value_super_centenarians" stroke="#A577FF" strokeWidth="3" dot={false} />
                                <Line type="linear" dataKey="value" stroke="#5B7BFF" strokeWidth="3" dot={(props) => RenderDot({...props, breakpoint})} />
                            </LineChart>
                        </ResponsiveContainer>
                        <div className="label-container">
                            {
                                data.map((label, index) => {
                                    if(!label.x_label) {
                                        return null;
                                    }

                                    return (
                                        <div key={index}>{label.x_label}</div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="healthy-range__legends comparison-legends">
                    {Object.keys(ledgend).map((key) => (
                        <div className="single-legend" key={key}>
                            <svg className={key}>
                                <line x1="0" y1="0" x2="80" y2="0" strokeWidth="6" strokeDasharray={key == 'you' ? '15 5': '0 0'} />
                            </svg>
                            <div className="legend-title">{ledgend[key]}</div>
                        </div>
                    ))}
                </div>
            </div>
            {analysis_text ? <div className="analysis-text analysis-text--wide">{analysis_text}</div> : ''}
            {(json_schema && json_schema.items) && !disableLink ? <div className="comparison__question-section"><Question jsonSchema={json_schema.items} section="EPIGENETIC_INDICATORS" /></div> : ''}
        </div>
    );
};

const Comparison = ({customComparisons, breakpoint, disableLink}) => {
    if(!customComparisons || Array.isArray(customComparisons)) {
        return null;
    }

    const { title, text, super_centenarians, everyone_age_delta } = customComparisons;
    return (
        <section>
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                    {text? <p className="indicator-section-description">{text}</p> : ''}
                    {RenderComparingSection(everyone_age_delta)}
                    {RenderCenturianSection(super_centenarians, breakpoint, disableLink)}
                </div>
            </section>
            <SplitConsumer name="show_compare_feature">
                {(value) => value == 'on' ?
                    <section className="biological-age__overview-container full-width">
                        <div className="biological-age__graph-section no-padding">
                            <div className="health-specialist__container comparison-section">
                                {
                                    !disableLink ? 
                                    <Link className="btn" to="/dashboard/you/compare">see more comparisons</Link>
                                    :<div className="btn" to="/dashboard/you/compare">see more comparisons</div>
                                }
                            </div>
                        </div>
                    </section> : ''
                }
            </SplitConsumer>
        </section>
    );
};

export default Comparison;
