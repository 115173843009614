import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import {Tooltip} from 'react-tippy';

import Slider from '../../common/slider';

class IntervenableCard extends Component {
    constructor(props) {
        super(props);

        //Slider 2 is optional and may be null....
        const sliderValue2 = props.item && props.item.score_2 !== null ? props.item.score_2 : null;

        this.state = {
            isExpanded: false,//props.index === 0,
            sliderValue: props.item && props.item.score || 0,
            sliderValue2: sliderValue2,
            showModal: false,
            newValue: 0,
            currentCategoryValue: null,
            possibleSavingMonths: null,
        };

        this.toggleSection = this.toggleSection.bind(this);
        this.handleSlider2Change = this.handleSlider2Change.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handelBothSlidersChange = this.handelBothSlidersChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.changeNewValue = this.changeNewValue.bind(this);
    }

    componentDidMount() {
        const { sliderValue, sliderValue2 } = this.state;

        this.setState({
            currentCategoryValue: this.calculateInterpolationValueFromCategories(sliderValue, sliderValue2),
            possibleSavingMonths: 0,
        });
    }



    toggleSection() {
        this.setState(prev => ({
            isExpanded: !prev.isExpanded
        }));
    }

    handleSliderChange(value) {
        const { sliderValue2 } = this.state;

        this.setState({
            possibleSavingMonths: this.calculateCategoryMonthsDifference(value, sliderValue2),
            sliderValue: value,
        });
    }

    handleSlider2Change(value) {
        const { sliderValue } = this.state;

        this.setState({
            possibleSavingMonths: this.calculateCategoryMonthsDifference(sliderValue, value),
            sliderValue2: value,
        });
    }

    //If both sliders need to change at the same time (ie reset) use this otherwise you get state race condition
    handelBothSlidersChange(value, value2) {
        this.setState({
            possibleSavingMonths: this.calculateCategoryMonthsDifference(value, value2),
            sliderValue: value,
            sliderValue2: value2,
        });
    }

    //calculates the diffference between your current score and the position you are looking at
    calculateCategoryMonthsDifference(value, value2) {
        const { item: { factor } } = this.props;
        const { currentCategoryValue } = this.state;
        const numYears = 3; //Numer of years to predict over

        const newCategoryValue = this.calculateInterpolationValueFromCategories(value, value2);
        //12 to convert years to months..
        const difference = (currentCategoryValue - newCategoryValue) * factor * numYears * 12;

        return difference;
    }

    //Calc the figure for this value from the categories. use Interpolation to find between categories..
    calculateInterpolationValueFromCategories(value, value2) {
        const { item: { categories, slug } } = this.props;
        //Ensure we have at least 1 category..
        if(categories.length < 1) {
            return 0;
        }

        //Some need a conversion on the values..
        if(slug === 'me-total') {
            //convert to total METS. value=vigerous, value2=moderate
            value = (value * 4) + (value2 * 8);
        }
        else if(slug === 'weight') {
            //convert height and weight into bmi. value=weight (kg), value2=height(m)
            value = value2 > 0 ? value / Math.pow(value2, 2) : 0;
        }

        let matchedCategoryValue = null;
        let currentPoint = null;
        let prevPoint = null;
        let nextPoint = null;

        for(let i = 0; i < categories.length; i++) {
            currentPoint = categories[i];
            prevPoint = (i > 0) ? categories[i-1] : null; 
            nextPoint = ((i+1) < categories.length) ? categories[i+1] : null; 

            //if value is above any of the categories we look at...
            if(value == currentPoint.x) {
                matchedCategoryValue = currentPoint.y;
                break;
            }
            else if(value > currentPoint.x && nextPoint === null) {
                matchedCategoryValue = currentPoint.y;
                break;
            }
            else if(value >= currentPoint.x && nextPoint && value <= nextPoint.x) {
                //Intopelate between values https://byjus.com/interpolation-formula/
                matchedCategoryValue = currentPoint.y + ((value - currentPoint.x)/(nextPoint.x - currentPoint.x)) * (nextPoint.y-currentPoint.y);
                break;
            }
        }

        //couldnt find anything? max value..
        if(matchedCategoryValue === null) {
            if(currentPoint !== null) {
                matchedCategoryValue = currentPoint.y;
            }
            else {
                matchedCategoryValue = 0;
            }
        }

        return matchedCategoryValue;
    }

    /*
    calculatePossibleSaving(value) {
        const { item: {f_value, age_difference, categories} } = this.props;

        //find the category value that matches this
        let matchedCategoryValue = 0;
        let previousCategory = null;
        for(let category of categories) {
            if(value >= category.min && value <= category.max) {
                //Exact match - return the exact value

                    matchedCategoryValue = category.value;

                break;
            }

            previousCategory = category;
        }

        let score = (age_difference * 12) + (f_value * matchedCategoryValue);

        return score;
    }
    */

    /*
    calculateMaxiumSaving(minValue, maxValue) {
        const { item: {scale_min, scale_max, show_dataset_2} } = this.props;
        const { sliderValue2 } = this.state;

        let allValues = [];

        //Do we have a second slider you can change?
        if(show_dataset_2 === true) {
            for(let i=scale_min; i<scale_max; i++) {
                for(let x=scale_min; x<scale_max; x++) {
                    allValues.push(this.calculateCategoryMonthsDifference(i, x));
                }
            }
        }
        else {
            for(let i=scale_min; i<scale_max; i++) {
                //sliderValue2 is static in this case so we dont need to calc all values
                allValues.push(this.calculateCategoryMonthsDifference(i, sliderValue2));
            }
        }
        //console.log(allValues)

        return Math.max(...allValues);
    }
    */

    toggleModal() {
        this.setState(prev => ({
            showModal: !prev.showModal
        }));
    }

    changeNewValue(event) {
        this.setState({newValue: event.target.value});
    }

    renderTooltip(title) {
        return (
            <Tooltip
                title={title}
                position="top"
                arrow={true}
                arrowSize="small"
                className="intervenable-card-tooltip"
            >
                <i className="icon-info-circled" />
            </Tooltip>
        );
    }

    renderHeader() {
        const { isExpanded } = this.state;
        const { item: { rating, rating_title, score, title, impact_title }, impactKey } = this.props;

        if(!(rating && rating_title && score && title)) {
            //return null;
        }

        return (
            <header className="intervenable-card__header" onClick={this.toggleSection}>
                <h4 className="intervenable-card__title">
                    {title && <span>{title}</span>}
                </h4>
                {/*maxSavingMonths !== null &&
                    <div className="score-section">
                        {maxSavingMonths <= 0 ? (
                            <span>
                                <span className={`rating`}><i className="icon-award icon-award--gold" /></span>
                                <div className="score-item"><span>&nbsp;</span>{this.renderTooltip('You are currently making the maximum gain from this - well done!')}</div>
                            </span>
                        ) : (
                            <span>
                                <span className={`rating ${rating}`}><i className="icon-bolt icon-bolt--green" /></span>
                                <div className="score-item"><span>{maxSavingMonths.toFixed(1)}</span>{this.renderTooltip('This is the maximum number of months you could gain from making this change.')}</div>
                            </span>
                        )}
                    </div>
                */}
                <div>
                    <p className={`intervenable-card__impact-title ${impactKey}`}>{impact_title}</p>
                    <i className={isExpanded ? 'icon icon-up-open' : 'icon icon-down-open'} />
                </div>
            </header>
        );
    }

    renderRecommendation() {
        const { item: { recommendation } } = this.props;

        if(!recommendation) {
            return null;
        }

        return (
            <div className="intervenable-card__recommendation-section">
                <div className="coach-text">{recommendation.text}</div>
            </div>
        );
    }

    renderDescriptionSection() {
        const { sliderValue, sliderValue2, isExpanded, possibleSavingMonths } = this.state;
        const { item: { units, units_2, scale_max, scale_min, scale_max_2, scale_min_2, rating, score, actual_score, score_2, actual_score_2, show_dataset_2, current_age_delta }, impactKey, index, numYears } = this.props;
        if(!(units || possibleSavingMonths || scale_max || scale_min)) {
            return null;
        }

        let monthsText = '';
        let circleClass = '';
        let yearlySaving = '';
        let monthlySaving = '';
        let currentCircleClass = '';
        let currentYearAge = '';
        let currentMonthAge = '';

        /*
        //This is for gained/lost
        if(possibleSavingMonths < 0) {
            monthsText = 'Biological age lost over next 10 years';
            circleClass = 'lost';
        }
        else if(possibleSavingMonths > 0){
            monthsText = 'Biological age gained over next 10 years';
            circleClass = 'gained';
        } else {
            monthsText = 'Biological age lost/gained over next 10 years';
            circleClass = 'maintained';
        }


        let possibleSavingMonthsStr = '';
        if(possibleSavingMonths !== null) {
            if(possibleSavingMonths < 0) {
                possibleSavingMonthsStr = possibleSavingMonths * -1;
            }
            else {
                possibleSavingMonthsStr = possibleSavingMonths;
            }
            possibleSavingMonthsStr = possibleSavingMonthsStr.toFixed(1);

            //?
            monthsText = 'Age gained/lost over the next 10 years'
            possibleSavingMonthsStr = possibleSavingMonths.toFixed(1);
        }

        yearlySaving = Math.floor(possibleSavingMonthsStr/12);
        monthlySaving = Math.round(possibleSavingMonthsStr%12)
        */
        
        currentYearAge = current_age_delta;
        if(currentYearAge > 0) {
            currentYearAge = '+'+currentYearAge;
        }
        currentMonthAge = 0;
        
        if(current_age_delta < 0) currentCircleClass = 'gained';
        else if(current_age_delta > 0) currentCircleClass = 'lost';
        else currentCircleClass = 'maintained';



        const possibleSaving = (current_age_delta * 12) - possibleSavingMonths;
        yearlySaving = possibleSaving < 0 && !Number.isInteger(possibleSaving) ? Math.floor(possibleSaving/12) + 1 : Math.floor(possibleSaving/12); //If negative rounds down
        if(yearlySaving > 0) {
            yearlySaving = '+'+yearlySaving;
        }
        monthlySaving = Math.round(possibleSaving%12);

        monthsText = 'Predicted biological age in 10 years';
        if(possibleSaving < 0) circleClass = 'gained';
        else if(possibleSaving > 0) circleClass = 'lost';
        else circleClass = 'maintained';

        return (
            <div className={`intervenable-card__description-section ${isExpanded ? 'active' : ''}`}>
                {this.renderRecommendation()}
                <div className="intervenable-card__saving-section">
                    <div className="intervenable-card__oval-section">
                        <svg viewBox="0 0 100 100" className={'intervenable-card__oval'}>
                            <circle cx="50" cy="50" r="45" className={`circle ${currentCircleClass}`} />
                            <foreignObject x="5" y="5" className="future-count" width="90" height="90">
                                <div className="content-container">
                                    <div>
                                        <p>{currentYearAge}</p>
                                        <p>YEARS</p>
                                    </div>
                                    <div>
                                        <p>{currentMonthAge}</p>
                                        <p>MOS</p>
                                    </div>
                                </div>
                            </foreignObject>
                        </svg>
                        <div className="label">Current</div>
                    </div>
                    <div className="futures__oval-section futures__oval-section--arrow">
                        <p className="arrow-text">{numYears}</p>
                        <div className="arrow" />
                    </div>
                    <div className="intervenable-card__oval-section">
                        <svg viewBox="0 0 100 100" className={'intervenable-card__oval'}>
                            <circle cx="50" cy="50" r="45" className={`circle dashed ${circleClass}`} />
                            <foreignObject x="5" y="5" className="future-count" width="90" height="90">
                                <div className="content-container">
                                    <div>
                                        <p>{yearlySaving}</p>
                                        <p>YEARS</p>
                                    </div>
                                    <div>
                                        <p>{monthlySaving}</p>
                                        <p>MOS</p>
                                    </div>
                                </div>
                            </foreignObject>
                        </svg>
                        <div className="label">Future</div>
                    </div>
                </div>
                {
                    units ?
                    <div className="intervenable-card__current-section">
                        <div className="buttons-container">
                            <span>Current:</span> <span>{actual_score} {units}</span>{/*} <i className="icon-pencil" onClick={this.toggleModal} />*/}
                        </div>
                    </div> : ''
                }
                <div className={`intervenable-card__slider-section${score%1 !== 0 ? ' add-extra-mark' : ''}`}>
                    <Slider
                        value={sliderValue}
                        units={units}
                        defaultTooltip="MOVE ME"
                        minValue={scale_min}
                        maxValue={scale_max}
                        showTooltip={true}
                        onChange={this.handleSliderChange}
                        defaultValue={score}
                        visibleTooltip={isExpanded}
                        step={0.1}
                    />
                </div>

                {
                    (show_dataset_2 && score_2 !== null) && 
                    <div>
                        <div className="intervenable-card__current-section">
                            <div className="buttons-container">
                                <span>Current:</span> <span>{score_2} {units_2}</span>{/*} <i className="icon-pencil" onClick={this.toggleModal} />*/}
                            </div>
                        </div>

                        <div className={`intervenable-card__slider-section${score_2%1 !== 0 ? ' add-extra-mark' : ''}`}>
                            <Slider
                                value={sliderValue2}
                                units={units_2}
                                defaultTooltip="MOVE ME"
                                minValue={scale_min_2}
                                maxValue={scale_max_2}
                                showTooltip={true}
                                onChange={this.handleSlider2Change}
                                defaultValue={score_2}
                                visibleTooltip={isExpanded}
                                step={0.1}
                            />
                        </div>
                    </div>
                }


                <div className="reset-button-container">
                    {
                        <span className="reset-button" onClick={() => { this.handelBothSlidersChange(score, score_2); }}>
                            Reset
                        </span>
                    }
                </div>
            </div>
        );
    }

    renderButton() {
        const { item: {cta, cta_link} } = this.props;
        const { isExpanded } = this.state;

        if(!(cta && cta_link)) {
            return null;
        }

        return (
            <div className={`intervenable-card__description-section button-container ${isExpanded ? 'active' : ''}`}>
                <a href={cta_link} className="read-more-link" target="_blank" rel="noreferrer">
                    <div className="link-image-container">
                        <img src="/img/you/futures/read-more.png" />
                    </div>
                    <p className="link-title">{cta}</p>
                </a>
            </div>
        );
    }

    render() {
        const { item, impactKey, index } = this.props;
        const { showModal, newValue } = this.state;

        if(!item) {
            return null;
        }

        const { units } = item;

        return (
            <div className="intervenable-card__container">
                {(impactKey === 'high_impact' && index === 0) ?
                    <div className="go-premium-button">highest impact</div> : ''
                }
                {this.renderHeader()}
                {this.renderDescriptionSection()}
                {this.renderButton()}
                <Modal open={showModal} onClose={this.toggleModal} center showCloseIcon={false} classNames={{modal: 'update-modal'}}>
                    <form className="intervenable-card__update-section">
                        <h3 className="update-title">Update {item.title}</h3>
                        <div className="input-field-container">
                            <label htmlFor="previous">Previous</label>
                            <div className="input-container">
                                <input
                                    type="number"
                                    id="previous"
                                    name="previous"
                                    className="input-field"
                                    defaultValue={item.score || 0}
                                    disabled={true}
                                />
                                <span>{units}</span>
                            </div>
                        </div>
                        <div className="input-field-container">
                            <label htmlFor="new">New</label>
                            <div className="input-container">
                                <input
                                    type="number"
                                    id="new"
                                    name="new"
                                    className="input-field"
                                    value={newValue}
                                    onChange={this.changeNewValue}
                                />
                                <span>{units}</span>
                            </div>
                        </div>
                        <div className="button-container">
                            <button type="submit" className="btn">update</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}

IntervenableCard.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string,
        rating: PropTypes.string,
        rating_title: PropTypes.string,
        score: PropTypes.number,
        recommendation: PropTypes.object,
        units: PropTypes.string,
        scale_max: PropTypes.number,
        scale_min: PropTypes.number,
        scale_max_2: PropTypes.number,
        scale_min_2: PropTypes.number,
        factor: PropTypes.number,
        cta: PropTypes.string
    }),
    index: PropTypes.number,
    numYears: PropTypes.string
};

export default IntervenableCard;