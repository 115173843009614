import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Helmet } from 'react-helmet';

import Loader from '../../components/common/loader';
import withError from '../../components/common/withError';
import withErrorBoundary from '../../components/common/errorBoundary';


import { getGoalDetails} from '../../services/improvements/actions';
import {  goalDetailsSelector, improvementsLoadingSelector } from '../../services/improvements/selectors';

class PlanDetails extends Component {

    componentDidMount() {
        const {match} = this.props;
        const slug = this.props.slug ? this.props.slug : match.params.slug;   
        this.props.fetchGoalDetails(slug);
    }

    render() {
        const { isLoading, goalDetails, match } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        return (
            <div className="plan-details-container">
                <h3 className="plan-details-container__title">{goalDetails.title}</h3>
                <div className="plan-details-container__description">{goalDetails.description}</div>
                <div className="plan-details-container__details-section">
                    <div className="details-icon">
                        <img src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                    </div>
                    <p className="details">
                        This plan is based on your epigenetic profile, questionare data and the healthy habits of the Hurdle community.
                    </p>
                </div>
                <div className="plan-details-container__improve-card-container">
                    {
                        goalDetails.items.map((item,index) =>{
                            const product_stage = item.product_stage.replace(/_/g,' ');
                            return(
                                <Link to={{ pathname: `${match.url}/${item.slug}`, state: goalDetails }} className="improve-card" key={index}>
                                    <div className="improve-card__information-section">
                                        <p className="card-title">{item.title}</p>
                                        <p className="card-information" dangerouslySetInnerHTML={{ __html: item.sub_title }} />
                                    </div>
                                    <div className="improve-card__details-section">
                                        <div className="product-information">
                                            <p>{product_stage}</p>
                                            <div className="icon-container">
                                                <img src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                                            </div>
                                        </div>
                                        <div className="product-information">
                                            <p>Overall Match</p>
                                            <p><strong>{item.overall_match_percent}%</strong></p>
                                        </div>
                                        {item.indicators_affected && item.indicators_affected.length >= 1 ? 
                                            <div className="indicator-section"> 
                                                <p>Epigenetic Indicators Impacted</p>
                                                <div className="indicator-section__icon-container-section">
                                                    {
                                                        item.indicators_affected.map((indicator,index) => {
                                                            return(
                                                                <div className="icon-container" key={index}>
                                                                    <img src={indicator.icon_url} alt={indicator.title}/>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            :''
                                        }
                                        <div className="btn width-100">View Details</div>
                                    </div>
                                </Link>
                            );
                        })
                    }
                </div>

                <Helmet>
                    <title>{goalDetails.title}</title>
                </Helmet>
            </div>
        );
    }
}

PlanDetails.defaultProps = {
    goalDetails: {},
    isLoading: true,
};

PlanDetails.propTypes = {
    fetchGoalsDetails: PropTypes.func,
    isLoading: PropTypes.bool,
    goalDetails: PropTypes.object
};

const mapStateToProps = (state) => ({
    isLoading: improvementsLoadingSelector(state,'goalDetails'),
    goalDetails: goalDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchGoalDetails: (slug) => dispatch(getGoalDetails(slug)),
});

const enhance = compose(
    withErrorBoundary,
    withError({reducer: 'improvements', page: 'aspiration'}),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(PlanDetails);
