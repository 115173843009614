import React from 'react';

const howWorksData = {
    title: 'How the cardiovascular system works',
    sections: [
        {
            title: '',
            text: 'Multiple components in our bodies work together to provide tissues with oxygen.',
            image_url: '/img/you/actionable-you/cardiovascular-fitness/elements.png'
            
        },
        {
            title: '',
            text: 'Oxygen is used by the powerhouses in our cells (mitochondria) to produce ATP, which is the main molecule that stores the energy needed to keep our metabolism working (the energy currency of our cells). ',
            image_url: '/img/you/actionable-you/cardiovascular-fitness/oxygen-atp.png'
        },
    ]
};

const howMeasureData = {
    title: 'How we measure cardiovascular efficiency',
    sections: [
        {
            title: '',
            text: 'Assessing how well the different functions of our bodies are working builds a picture of overall cardiovascular efficiency. These systems and organs include heart and blood vessels, lungs, neuromuscular system, kidneys and even the immune system.',
            image_url: '/img/you/actionable-you/cardiovascular-fitness/measure-cariovascular-health.png'
            
        },
        {
            title: '',
            text: 'Doctors will often look at your resting heart rate, resting blood pressure or maximal oxygen uptake (VO2 max) to see how certain parts of these systems are working but these measures don’t give you a holistic view.',
            image_url: ''
        },
        {
            title: '',
            text: 'Epigenetics captures the complex interactions that emerge between these measures and the impact of lifestyle factors on our cells. It gives us the whole view that traditional medicine can’t.',
            image_url: ''
        },
    ]
};


const RenderSection = ({ title, sections }) => {
    return (
        <div className="biological-age__graph-section no-padding">
            <h4 className="indicator-section-title">{title}</h4>
            <div className="biological-age__stress-section">
                {
                    sections.map((item, index) => { 
                        let className = 'stress-information-section';
                        if(item.image_url == '') {
                            className += ' full-width';
                        }
                        return (
                            <div className="stress-container" key={title.replace(/ /g, '-')+index}>
                                <div className={className}>
                                    {item.title && <h5 className="indicator-section-title inside-title">{item.title}</h5>}
                                    <p className="indicator-section-description">{item.text}</p>
                                </div>
                                {item.image_url != '' && 
                                    <div className="stress-image">
                                        <img src={item.image_url} alt={item.title.replace(/ /g, '-' )} className="img-narrow" />
                                    </div>
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

const CardiovascularHealth = () => {
    return (
        <section className="biological-age__overview-container">
            {RenderSection(howWorksData)}
            {RenderSection(howMeasureData)}
        </section>
    );
};

export default CardiovascularHealth;