import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AutoSuggest from '../../../autoSuggest';

class Occupation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            selected: props.value,
        };

        this.handelOnChange = this.handelOnChange.bind(this);
        this.onOccupationSelect = this.onOccupationSelect.bind(this);
        this.onDeselectTag = this.onDeselectTag.bind(this);
    }

    handelOnChange(e) {
        const value = e.target.value;
        this.setState({value: value});
        this.props.onChange(this.props.id, value);
    }

    onOccupationSelect(value) {
        this.setState({value: value.title, selected: true});
        this.props.onChange(this.props.id, value.title);
    }

    onDeselectTag() {
        this.setState({selected: false, value: ''}, () => {
            this.props.onChange(this.props.id, this.state.value);
        });
    }

    render() {
        const { value, selected } = this.state;
        return (
            <div className="form__select-wrapper form__autosuggest-wrapper">
                {selected ?
                    <div className="selected-tag">
                        <span>{value}</span>
                        <i className="icon-cancel" onClick={this.onDeselectTag} />
                    </div> :
                    <AutoSuggest
                        fetchUrl="occupations?q="
                        onChange={this.handelOnChange}
                        onSelectItem={this.onOccupationSelect}
                        placeholder="Current Occupation"
                        responseKey="data"
                        value={value}
                    />
                }
            </div>
        );
    }
}

Occupation.defaultProps = {
    value: ''
};

Occupation.propTypes = {
    value: PropTypes.string,
    id: PropTypes.number,
};

export default Occupation;