import React, { Component } from 'react';

class ChromosomeChart extends Component {

    renderBars(bars) {
        const { activeValue, statusMapping } = this.props;
        return bars.map((bar, index) => {
            const width = (bar.end_percentage - bar.start_percentage);
            const backgroundColor = activeValue.includes(bar.status) ? statusMapping[bar.status] : '#ffffff';
            const borderColor = activeValue.includes(bar.status) ? statusMapping[bar.status] : '#A3A3A3';

            return (
                <div key={index} style={{width: `${width}%`}} className="chromosome-chart__part-section">
                    <div style={{backgroundColor: backgroundColor, border: `2px solid ${borderColor}`}} className="chromosome-chart__part" />
                </div>
            );
        });
    }

    render() {
        const { data } = this.props;

        return (
            <div className="chromosome-chart__container">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="chromosome-chart__section">
                                <div className="chromosome-chart__label">{item.title}</div>
                                <div className="chromosome-chart__parts-container">{this.renderBars(item.chunks)}</div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default ChromosomeChart;
