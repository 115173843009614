import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import AutoSuggest from '../../../components/common/autoSuggest';

const FOOTER = {
    title: 'What does my DNA know about',
    description: 'Search for any gene or variant within the 3 billion positions in your DNA.',
    searchPlaceholder: 'gene or variant',
    lockedPlaceholder: 'BRCA1',
};

const searchQueryMinimumLength = 2;

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
    }

    onChange(e) {
        this.setState({
            searchQuery: e.target.value
        });
    }

    onSelectItem(suggestion) {
        const { onSubmit } = this.props;
        this.setState({ searchQuery: suggestion.title }, () => {
            onSubmit({
                pathname: '/dashboard/you/search',
                search: qs.stringify({q: this.state.searchQuery})
            });
        });
    }

    onSubmit() {
        const { onSubmit } = this.props;
        onSubmit({
            pathname: '/dashboard/you/search',
            search: qs.stringify({ q: this.state.searchQuery })
        });
    }

    render() {
        const { search: { locked } } = this.props;
        const imgSrc = locked ? '/img/you/lock.svg' : '/img/you/search-blue.png';
        const placeHolder = locked ? FOOTER.lockedPlaceholder : FOOTER.searchPlaceholder;
        return (
            <div className={`you-footer-section  ${ locked ? 'locked' : ''} `}>
                {locked && <div className="you-footer-section__overlay"/>}
                <div className="you-footer-wrapper">
                    <div>
                        <h3 className="title">{FOOTER.title}</h3>
                        <form className="search-input" onSubmit={this.onSubmit}>
                            <AutoSuggest
                                disabled={locked}
                                fetchUrl="test_user/search/autocomplete?q="
                                onChange={this.onChange}
                                onSelectItem={this.onSelectItem}
                                placeholder={placeHolder}
                                searchQueryMinimumLength={searchQueryMinimumLength}
                                value={this.state.searchQuery}
                            />
                            <button disabled={locked} type="submit">
                                <img alt="dna-report" src={imgSrc}/>
                            </button>
                        </form>
                        <span className="title">?</span>
                    </div>
                    {FOOTER.description && <p>{FOOTER.description}</p>}
                </div>
            </div>
        );
    }
}


Footer.defaultProps = {
    onSubmit: () => {},
    search: { locked: false },
};

Footer.propTypes = {
    onSubmit: PropTypes.func,
    search: PropTypes.shape({
        locked: PropTypes.bool,
    })
};

export default Footer;
