import React from 'react';
import PropTypes from 'prop-types';

import ResultDelta from '../components/resultDelta';

const HeroSection = ({heroSectionInfo, previousTest, resultDelta, slug, disablelink}) => {
    if(!heroSectionInfo) {
        return null;
    }

    return (
        <section className="result-hero">
            <div className="result-hero__text-container" >
                {
                    slug?
                        <div className="hero-image">
                            <img alt={slug} src={`/img/you/actionable-you/epi-indicator/${slug}.svg`} />
                        </div> : ''
                }
                <h1 className="result-hero__title study-hero__title">{heroSectionInfo.title}</h1>
                { 
                    !disablelink ? 
                        <p className="result-hero__description biological-description">{heroSectionInfo.summaryText}</p>
                        :<p className="result-hero__description biological-description">{heroSectionInfo.summary_text}</p>
                }
                <div className="result-hero__intro">
                    <div className="result-hero-image">
                        <img alt="result-intro" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                    </div>
                {
                    !disablelink ? 
                    <p className="result-intro-description">{heroSectionInfo.resultIntroText}</p>
                    :<p className="result-intro-description">{heroSectionInfo.result_intro_text}</p> 
                }
                </div>
                <ResultDelta
                    previousTest={previousTest}
                    result={heroSectionInfo.result}
                    resultDelta={resultDelta}
                    resultUnit={heroSectionInfo.resultUnit}
                />
                {
                    !disablelink ? 
                    <p data-tut="health-indicator-details" className="result-hero__biological-description" dangerouslySetInnerHTML={{__html: heroSectionInfo.analaysisText}} />:
                    <p data-tut="health-indicator-details" className="result-hero__biological-description" dangerouslySetInnerHTML={{__html: heroSectionInfo.analysis_text}} />
                }
                <div className="disease-risk__epigenetics-section">
                    <img alt="epigenetics" src="/img/you/actionable-you/daniel-herranz.png" />
                    <p className="epigenetic-title">Dr. Daniel E. Martín Herranz Epigenetics expert</p>
                </div>
            </div>
        </section>
    );
};

HeroSection.propTypes = {
    heroSectionInfo: PropTypes.object,
    resultDelta: PropTypes.any,
    previousTest: PropTypes.object
};

export default HeroSection;