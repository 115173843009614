import React from 'react';

export const SmallContainer = ({ bgColor, title, subtitle, onClick }) => {
    return (
        <div
            className="test-dashboard-section__container small"
            onClick={onClick}
            title={title}
        >
            <div className="content-container" style={{ backgroundColor: bgColor }}>
                <div className="section-content">
                    <div className="section-title">{title}</div>
                    <div className="section-sub-title">{subtitle}</div>
                </div>
                <img src="/img/right_arrow.svg" alt="" />
            </div>
        </div>
    );
};
