export function getIsFallbackInterpretationContent(interpretation) {
  if(interpretation){
    let lowerCaseInterpretation = '';
    if(Array.isArray(interpretation)){
      if(interpretation.length > 0){
        lowerCaseInterpretation = interpretation[0].toString();
      } else {
        return false;
      }
    } else {
      lowerCaseInterpretation = interpretation.toLowerCase();
    }
    return lowerCaseInterpretation === 'inconclusive' || lowerCaseInterpretation === 'indefinite';
  }
  return false;
}
