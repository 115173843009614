import React, { Component } from 'react';


export default class Text extends Component {
    render() {
        const {id, isRequired, value, onChange} = this.props;

        return (
            <input type="text" className="custom__text-field" name={id} id={id} value={value} onChange={onChange}/>
        );
    }
}