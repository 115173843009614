import React from 'react';
import moment from 'moment';
import { StyledButton } from '../../../../theming/themed-components';

export const FitToFlyStep03 = ({ availability, flightDate, history }) => {
  const shortDate = moment(availability, 'YYYY-MM-DD').format('DD/MM');
  const handleBack = () => history.goBack();
  const handleCancel = () => history.push('/dashboard/covid');
  const handleNext = () => history.push('/join/account?slug=covid-19&utm_source=chronomics&utm_medium=app&utm_campaign=Testing%20Timeline');
  return (
    <section className="test-dashboard-section content-wrapper-animate">
        <div className="test-dashboard-section__section_content centered">
            <img className="back-button" src="/img/back.svg" alt="" onClick={handleBack} />
            
            <div className="test-dashboard-section__container">
                <div className="content-container">
                <div>
                    <div className="section-title">
                        Testing timeline calculator
                    </div>
                    <div className="section-sub-title">
                        for your Fit-to-fly
                        <br /><br />
                    </div>
                    <div className="section-sub-title">
                        Outbound flight: {flightDate}
                    </div>

                    <div className="panel-container">
                        <div className="sub-panel">
                            <div>
                                <div>
                                    Your kit will be shipped on
                                    <br /><span>{shortDate}</span>
                                </div>
                                <div>
                                    Take the test: Within 72-24 hours of your outbound flight depending on country policies
                                </div>
                            </div>
                        </div>

                        <div className="sub-panel">
                            <div>
                                <div>
                                    Send back on once test complete
                                </div>
                                <div>
                                    Get your result in
                                    <br /><span>24-36 </span>hrs<br />
                                    of being received at the lab 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <StyledButton
                    className="btn cancel"
                    onClick={handleNext}
                    type="button"
                >
                    Order Now
                </StyledButton>
                <div className="button-link" onClick={handleCancel}>
                    Clear result
                </div>
                </div>
            </div>
        </div>
    </section>
  );
};
