import axios from 'axios';
import qs from 'query-string';
import moment from 'moment';
import React, { Component } from 'react';
import { validated } from 'react-custom-validation';
import utils from '../helpers/utils';
import validationRules from '../helpers/validationRules';
import TravelCard from './travelCard';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

function travelValidationConfig(props) {
    const { fields, quantity, requiredOutboundFrom } = props;
    const {
      flight_number,
      arrival_day,
      arrival_month,
      arrival_year,
      departure_day,
      departure_month,
      departure_year,
      departure_country,
      transit_countries,
      transit_country_1,
      arrival_date,
      departure_date,
    } = props.fields;

    const parsedUrl = qs.parse(window.location.search);
    const members = new Array(parseInt(quantity, 10)).fill({});
    const validations = requiredOutboundFrom ? {
        departure_day: [
            [validationRules.isRequired, departure_day],
            [validationRules.isDate, departure_day],
        ],
        departure_month: [
            [validationRules.isRequired, departure_month],
            [validationRules.isMonth, departure_month],
        ],
        departure_year: [
            [validationRules.isRequired, departure_year],
            [validationRules.isYearForOrder, departure_year],
        ],
        departure_date: [],
    } : {
      flight_number: [
        [validationRules.isRequired, flight_number],
      ],
      arrival_day: [
        [validationRules.isRequired, arrival_day],
        [validationRules.isDate, arrival_day],
      ],
      arrival_month: [
        [validationRules.isRequired, arrival_month],
        [validationRules.isMonth, arrival_month],
      ],
      arrival_year: [
        [validationRules.isRequired, arrival_year],
        [validationRules.isYearForOrder, arrival_year],
      ],
      departure_day: [
        [validationRules.isRequired, departure_day],
        [validationRules.isDate, departure_day],
      ],
      departure_month: [
        [validationRules.isRequired, departure_month],
        [validationRules.isMonth, departure_month],
      ],
      departure_year: [
        [validationRules.isRequired, departure_year],
        [validationRules.isYearForOrder, departure_year],
      ],
      departure_country: [
        [validationRules.isRequired, departure_country]
      ],
      transit_countries: [
        [validationRules.isRequired, transit_countries],
      ],
      arrival_date: [],
      departure_date: [],
    };

    const fieldNames = requiredOutboundFrom ? ['departure_day', 'departure_month', 'departure_year', 'departure_date'] :
        [
            'flight_number', 'transit_countries', 'departure_country',
            'arrival_day', 'arrival_month', 'arrival_year',
            'departure_day', 'departure_month', 'departure_year', 'transit_country_1',
            'arrival_date', 'departure_date',
        ];

    members.forEach((_, index) => {
      const firstNameField = `pax_${index + 1}_first_name`;
      const lastNameField = `pax_${index + 1}_last_name`;

      validations[firstNameField] = [
        [validationRules.isRequired, (fields[firstNameField] ? fields[firstNameField] : '')],
        [validationRules.isName, (fields[firstNameField] ? fields[firstNameField] : '')],
      ];
      validations[lastNameField] = [
        [validationRules.isRequired, (fields[lastNameField] ? fields[lastNameField] : '')],
        [validationRules.isName, (fields[lastNameField] ? fields[lastNameField] : '')],
      ];

      fieldNames.push(firstNameField);
      fieldNames.push(lastNameField);
    });

    if (transit_countries === 'yes') {
      validations.transit_country_1 = [
        [validationRules.isRequired, transit_country_1],
      ];
    }

    let arrivalDate;
    if (arrival_day.length >= 1 && arrival_month.length >= 1 && arrival_year.length === 4) {
      arrivalDate = moment(`${arrival_year}-${arrival_month}-${arrival_day}`,
        ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-M-DD', 'YYYY-MM-D']);

      if (arrivalDate.isValid()) {
        const diff = arrivalDate.diff(moment().subtract(4, 'days'), 'days');
        validations.arrival_date = [
          [() => diff < 0 ? 'Arrival date is too far in the past' : null, arrival_date],
        ];

        /*
        validations.arrival_date = [
          [() => validationRules.areTestsAvailableToBuy(arrivalDate.format("YYYY-MM-DD"), parsedUrl.slug), arrivalDate],
        ];
        */
      } else {
          validations.arrival_date = [
              [() => 'Arrival date is not valid', arrival_date],
          ];
      }
    }

    if (departure_day.length >= 1 && departure_month.length >= 1 && departure_year.length === 4) {
      const departureDate = moment(`${departure_year}-${departure_month}-${departure_day}`,
        ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-M-DD', 'YYYY-MM-D']);

      if (departureDate.isValid()) {
        const diff = departureDate.diff(moment().subtract(4, 'days'), 'days');
        validations.departure_date = [
          [() => diff < 0 ? 'Departure date is too far in the past' : null, departure_date],
        ];

        if (arrivalDate && arrivalDate.isValid() && !requiredOutboundFrom) {
          const diff = arrivalDate.diff(departureDate, 'days');
          validations.departure_date = [
            [() => diff < 0 ? 'Departure date must be before or equal to arrival date' : null, departure_date],
          ];
        }
      } else {
          validations.departure_date = [
              [() => 'Departure date is not valid', departure_date],
          ];
      }
  }


    return {
      fields: fieldNames,
      validations,
    };
}

class Travel extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);

        this.state = {
          test_available: null,
          test_error: false,
          processing_request: false,
        };
    }

    onSubmit(e) {
        e.preventDefault();
        const {$submit, onInvalid, onValid, } = this.props;
        $submit(() => onValid(null, 'travel'), onInvalid);
    }

    onEdit() {
        const { UpdateState, history } = this.props;
        const sectionsComplete = {
            account: true,
            delivery: true,
            travel: false,
            payment: false
        };
        if (UpdateState) {
            UpdateState({ sectionsComplete });
        }
        if (history) {
            history.push('/join/travel');
        }
    }

    validateTestAvailability(fieldChanged) {
      this.setState({ test_available: null, test_error: false, processing_request: true });
      this.props.$fieldEvent('blur', fieldChanged);
      const parsedUrl = qs.parse(window.location.search);

      const { fields, onChange } = this.props;
      if (fields.arrival_day.length >= 1 && fields.arrival_month.length >= 1 && fields.arrival_year.length === 4) {
        const arrivalDate = moment(`${fields.arrival_year}-${fields.arrival_month}-${fields.arrival_day}`,
          ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-M-DD', 'YYYY-MM-D']);
        if (arrivalDate.isValid()) {
          axios.post('products/check_lab_capacity', {
            date_of_arrival: arrivalDate.format('YYYY-MM-DD'),
            email: fields.email,
            fname: fields.delivery_fname,
            lname: fields.delivery_lname,
            slug: parsedUrl.slug,
          }).then(response => {
            let test_available = null;
            let lab_availability = '';
            let test_error = false;
            // @FIXME for David please fix
            if (response.data.status === 'no_slots_available') {
              test_available = 'Unfortunately, we have no availability for your given travel date.';
              test_error = true;
            } else if (response.data.status === 'found') {
              test_available = `In accordance with UK government regulation your travel tests when arriving back in the UK will be dispatched on ${moment(response.data.available_date, 'YYYY-MM-DD').format('Do MMMM YYYY')}. Any other tests as part of a bundle that are required before traveling will be sent separately before your holiday.`;
              lab_availability = response.data.available_date;
            }

            onChange('lab_availability', lab_availability);
            this.setState({ lab_availability, test_available, test_error, processing_request: false });
          });

          utils.track('TTR checkout test date', {date: arrivalDate.format('YYYY-MM-DD')});
        }
      }
    }

    render() {
      const {
        branding,
          isCompleted,
          fields,
          quantity,
          $validation,
          onChange,
          countrys,
          shouldExpand,
          submitError,
          submitButtonText,
          hideStepNumber,
          requiredOutboundFrom
      } = this.props;

      const members = new Array(parseInt(quantity, 10)).fill({});
      const getBrandingStyle = () => (branding && branding.hasBranding && branding.section) ? {
        color: branding.section.highlight_colour,
      } : {};

      const partnerBranding = cachedPartnerTheming();

      const getBrandingButtonStyle = () => {
        if (partnerBranding || branding?.hasBranding) {
          const theme = partnerBranding ? partnerBranding.theme : branding;
          return { background: theme.section.cta_colour };
        }
        return {};
      };

      return (
          <div className="checkout new-checkout-flow" id="travel-section">
            <div className="checkout__form-col order-summary">
                <h4 className="checkout__title checkout__title" style={getBrandingStyle()}>
                  2. Travel details
                  {isCompleted
                  ? <span className="checkout__title--orderstep" onClick={this.onEdit} style={getBrandingStyle()}>EDIT</span>
                  : !hideStepNumber && <span className="checkout__title--orderstep" style={getBrandingStyle()}>Step 2 of 3</span>}
                </h4>
                <form className="form checkout-form" onSubmit={this.onSubmit} data-testid="form">
                {isCompleted ? (
                  <TravelCard members={members} fields={fields} countrys={countrys} test_available={this.state.test_available} />
              ) : (
                <>
                  <div className={`card-wrapper ${shouldExpand ? 'slide' : '' }`}>
                    <div className="card-container">
                        <div className="card-container__card-display">
                            {!requiredOutboundFrom ? <div>UK arrival travel details for all passengers</div> : <div>Travel details for all passengers</div>}
                            {!requiredOutboundFrom && <div className="card-container__field-information">These details must match those on your UK GOV passenger locator form</div>}
                        </div>
                      <div className="card-container__card-info">

                          <div className="form__row form__row--2col" style={{ paddingTop: 20 }}>
                            <div className="form__col60">
                                {!requiredOutboundFrom ? <div className="travel-info-text">Date on which you will depart from or transit through a country or territory outside the <a href="https://www.gov.uk/government/publications/common-travel-area-guidance/common-travel-area-guidance" target="_blank" rel="noreferrer">common travel area</a></div> : <div className="travel-info-text">Departure Date</div>}
                            </div>

                            <div className="form__row form__row--2col">
                              <div className="form__col40">
                                <div className="form__row form__row--group">
                                  <div className="input-label-container">
                                    <input
                                      type="number"
                                      value={fields.departure_day}
                                      name="departure_day"
                                      maxLength={2}
                                      id={fields.departure_day}
                                      aria-labelledby="departure_day"
                                      onChange={(e) => onChange('departure_day', e.target.value)}
                                      onBlur={() => this.props.$fieldEvent('blur', 'departure_day')}
                                    />
                                    <label htmlFor="departure_day" id="departure_day" className={`${'form__label'} ${fields.departure_day ? 'valid': ''}`}>
                                        DD
                                    </label>
                                  </div>
                                  <div className="input-label-container">
                                    <input
                                      type="number"
                                      value={fields.departure_month}
                                      name="departure_month"
                                      maxLength={2}
                                      id={fields.departure_month}
                                      aria-labelledby="departure_month"
                                      onChange={(e) => onChange('departure_month', e.target.value)}
                                      onBlur={() => this.props.$fieldEvent('blur', 'departure_month')}
                                    />
                                    <label htmlFor="departure_month" id="departure_month" className={`${'form__label'} ${fields.departure_month ? 'valid': ''}`}>
                                        MM
                                    </label>
                                  </div>
                                  <div className="input-label-container">
                                    <input
                                      type="number"
                                      value={fields.departure_year}
                                      name="departure_year"
                                      maxLength={4}
                                      id={fields.departure_year}
                                      aria-labelledby="departure_year"
                                      onChange={(e) => onChange('departure_year', e.target.value)}
                                      onBlur={() => this.props.$fieldEvent('blur', 'departure_year')}
                                    />
                                    <label htmlFor="departure_year" id="departure_year" className={`${'form__label'} ${fields.departure_year ? 'valid': ''}`}>
                                        YYYY
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form__error">
                            {$validation.departure_day.show && $validation.departure_day.error.reason ? (
                              <div>
                                {`Day - ${$validation.departure_day.error.reason}`}
                              </div>
                            ) : null}
                            {$validation.departure_month.show && $validation.departure_month.error.reason ? (
                              <div>
                                {`Month - ${$validation.departure_month.error.reason}`}
                              </div>
                            ) : null}
                            {$validation.departure_year.show && $validation.departure_year.error.reason ? (
                              <div>
                                {`Year - ${$validation.departure_year.error.reason}`}
                              </div>
                            ) : null}
                            {
                              $validation.departure_date.show && $validation.departure_date.error.reason ? (
                                <div dangerouslySetInnerHTML={{ __html: $validation.departure_date.error.reason }} />
                            ) : null}
                          </div>

                          {!requiredOutboundFrom &&
                          <>
                              <div className="form__row form__row--2col" style={{paddingTop: 20}}>
                                  <div className="form__col60">
                                      <div>Date of arrival in the UK</div>
                                  </div>

                                  <div className="form__row form__row--2col">
                                      <div className="form__col40">
                                          <div className="form__row form__row--group">
                                              <div className="input-label-container">
                                                  <input
                                                      type="number"
                                                      value={fields.arrival_day}
                                                      name="arrival_day"
                                                      maxLength={2}
                                                      id={fields.arrival_day}
                                                      aria-labelledby="arrival_day"
                                                      onChange={(e) => onChange('arrival_day', e.target.value)}
                                                      onBlur={() => this.validateTestAvailability('arrival_day')}
                                                  />
                                                  <label htmlFor="arrival_day" id="arrival_day"
                                                         className={`${'form__label'} ${fields.arrival_day ? 'valid' : ''}`}
                                                  >
                                                      DD
                                                  </label>
                                              </div>
                                              <div className="input-label-container">
                                                  <input
                                                      type="number"
                                                      value={fields.arrival_month}
                                                      name="arrival_month"
                                                      maxLength={2}
                                                      id={fields.arrival_month}
                                                      aria-labelledby="arrival_month"
                                                      onChange={(e) => onChange('arrival_month', e.target.value)}
                                                      onBlur={() => this.validateTestAvailability('arrival_month')}
                                                  />
                                                  <label htmlFor="arrival_month" id="arrival_month"
                                                         className={`${'form__label'} ${fields.arrival_month ? 'valid' : ''}`}
                                                  >
                                                      MM
                                                  </label>
                                              </div>
                                              <div className="input-label-container">
                                                  <input
                                                      type="number"
                                                      value={fields.arrival_year}
                                                      name="arrival_year"
                                                      maxLength={4}
                                                      id={fields.arrival_year}
                                                      aria-labelledby="arrival_year"
                                                      onChange={(e) => onChange('arrival_year', e.target.value)}
                                                      onBlur={() => this.validateTestAvailability('arrival_year')}
                                                  />
                                                  <label htmlFor="arrival_year" id="arrival_year"
                                                         className={`${'form__label'} ${fields.arrival_year ? 'valid' : ''}`}
                                                  >
                                                      YYYY
                                                  </label>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="form__error">
                                  {$validation.arrival_day.show && $validation.arrival_day.error.reason ? (
                                      <div>
                                          {`Day - ${$validation.arrival_day.error.reason}`}
                                      </div>
                                  ) : null}
                                  {$validation.arrival_month.show && $validation.arrival_month.error.reason ? (
                                      <div>
                                          {`Month - ${$validation.arrival_month.error.reason}`}
                                      </div>
                                  ) : null}
                                  {$validation.arrival_year.show && $validation.arrival_year.error.reason ? (
                                      <div>
                                          {`Year - ${$validation.arrival_year.error.reason}`}
                                      </div>
                                  ) : null}
                                  {$validation.arrival_date.show && $validation.arrival_date.error.reason ? (
                                      <div>
                                          {$validation.arrival_date.error.reason}
                                      </div>
                                  ) : null}
                                  {
                                      !this.state.processing_request && this.state.test_available !== null &&
                                      !($validation.arrival_date.show && $validation.arrival_date.error.reason) ? (
                                          <div className={this.state.test_error ? '' : 'green-highlight'}
                                               dangerouslySetInnerHTML={{__html: this.state.test_available}}
                                          />
                                      ) : null}
                              </div>

                          <div className="form__row">
                            <div className="input-label-container">
                              <input
                                type="text"
                                value={fields.flight_number}
                                name="flight_number"
                                id={fields.flight_number}
                                aria-labelledby="flight_number"
                                onChange={(e) => onChange('flight_number', e.target.value)}
                                onBlur={() => this.props.$fieldEvent('blur', 'flight_number')}
                              />
                              <label htmlFor="flight_number" id="flight_number" className={`${'form__label'} ${fields.flight_number ? 'valid': ''}`}>
                                  Flight number, coach or vessel name
                              </label>
                              <div className="form__error">
                                {$validation.flight_number.show
                                  ? $validation.flight_number.error.reason
                                  : ''}
                              </div>
                            </div>
                          </div>

                          <div className="form__row" style={{ paddingTop: 10 }}>
                            <div className="input-label-container">
                              <div className="form__select-wrapper">
                                <select
                                  data-testid="select"
                                  name="departure_country"
                                  id={fields.departure_country}
                                  value={fields.departure_country}
                                  onChange={(e) => onChange('departure_country', e.target.value)}
                                  onBlur={() => this.props.$fieldEvent('blur', 'departure_country')}
                                >
                                  <option key="" value="" label="Country travelling from" />
                                  {countrys.length > 0 && countrys.map(
                                    (o,key) => (
                                      <option key={key} value={o.country_code}>
                                        {o.title}
                                      </option>
                                    )
                                  )}
                                </select>
                                <i className="icon-down-open" />
                              </div>
                              <div className="form__error">
                                {$validation.departure_country.show
                                  ? $validation.departure_country.error.reason
                                  : ''}
                              </div>
                            </div>
                          </div>

                          <div className="form__row form__row--2col">
                            <div className="input-label-container">
                              <div className="form__label--radio">
                                <label>
                                  Did you transit through any countries?
                                </label>
                              </div>
                              <div className="form__row form__row--2col">
                                <div className="form__col">
                                  <input
                                    type="radio"
                                    name="transit_countries"
                                    checked={fields.transit_countries === 'yes'}
                                    id="yes"
                                    value="yes"
                                    onChange={(e) => onChange('transit_countries', e.target.value)}
                                  />
                                  <label htmlFor="yes" className="form__label--checkbox">
                                      Yes
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="transit_countries"
                                    checked={fields.transit_countries === 'no'}
                                    id="no"
                                    value="no"
                                    onChange={(e) => onChange('transit_countries', e.target.value)}
                                  />
                                  <label htmlFor="no" className="form__label--checkbox">
                                      No
                                  </label>
                                </div>
                              </div>
                              {$validation.transit_countries.show ? (
                                <div className="form__error">
                                  {$validation.transit_countries.show
                                    ? $validation.transit_countries.error.reason
                                    : ''}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          </>
                          }
                          {
                            fields.transit_countries === 'yes' ? (
                              <>
                              <div className="form__row">
                                <div className="input-label-container">
                                  <div className="form__select-wrapper">
                                    <select
                                      name="transit_country_1"
                                      id={fields.transit_country_1}
                                      value={fields.transit_country_1}
                                      onChange={(e) => onChange('transit_country_1', e.target.value)}
                                      onBlur={() => this.props.$fieldEvent('blur', 'transit_country_1')}
                                    >
                                      <option key="" value="" label="Transit country 1" />
                                      {countrys.length > 0 && countrys.map(
                                        (o,key) => (
                                          <option key={key} value={o.country_code}>
                                            {o.title}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <i className="icon-down-open" />
                                  </div>
                                  <div className="form__error">
                                    {$validation.transit_country_1.show
                                      ? $validation.transit_country_1.error.reason
                                      : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="form__row">
                                  <div className="input-label-container">
                                    <div className="form__select-wrapper">
                                      <select
                                        name="transit_country_2"
                                        id={fields.transit_country_2}
                                        value={fields.transit_country_2}
                                        onChange={(e) => onChange('transit_country_2', e.target.value)}
                                        onBlur={() => this.props.$fieldEvent('blur', 'transit_country_2')}
                                      >
                                        <option key="" value="" label="Transit country 2" />
                                        {countrys.length > 0 && countrys.map(
                                          (o,key) => (
                                            <option key={key} value={o.country_code}>
                                              {o.title}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <i className="icon-down-open" />
                                    </div>
                                    <div className="form-label-helper">
                                      Optional
                                    </div>
                                  </div>
                              </div>

                              <div className="form__row">
                                <div className="input-label-container">
                                  <div className="form__select-wrapper">
                                    <select
                                      name="transit_country_3"
                                      id={fields.transit_country_3}
                                      value={fields.transit_country_3}
                                      onChange={(e) => onChange('transit_country_3', e.target.value)}
                                      onBlur={() => this.props.$fieldEvent('blur', 'transit_country_3')}
                                    >
                                      <option key="" value="" label="Transit country 3" />
                                      {countrys.length > 0 && countrys.map(
                                        (o,key) => (
                                          <option key={key} value={o.country_code}>
                                            {o.title}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <i className="icon-down-open" />
                                  </div>
                                  <div className="form-label-helper">
                                    Optional
                                  </div>
                                </div>
                              </div>
                              </>
                            ) : null
                          }
                      </div>

                    </div>
                  </div>

                  {shouldExpand && !requiredOutboundFrom ? (
                    <>
                      {members.map((_, index) => {
                        const firstNameField = `pax_${index + 1}_first_name`;
                        const firstNameValue = fields[firstNameField] ? fields[firstNameField] : '';
                        const lastNameField = `pax_${index + 1}_last_name`;
                        const lastNameValue = fields[lastNameField] ? fields[lastNameField] : '';
                        return (
                          <div className="card-wrapper slide" key={`member_${index}`}>
                            <div className="card-container">
                              <div className="card-container__card-display">
                                <div>
                                  {`Details of person for test number ${index + 1}`}<br />
                                  <label className="form__label">
                                    Please enter details exactly as on passport
                                  </label>
                                </div>
                              </div>

                              <div className="card-container__card-info">

                                  <div className="form__row">
                                    <div className="input-label-container">
                                      <input
                                        type="text"
                                        value={firstNameValue}
                                        name={firstNameField}
                                        id={firstNameField}
                                        aria-labelledby={'firstNameField'}
                                        onChange={(e) => onChange(firstNameField, e.target.value)}
                                        onBlur={() => this.props.$fieldEvent('blur', firstNameField)}
                                      />
                                      <label htmlFor={firstNameField} id={'firstNameField'} className={`${'form__label'} ${firstNameValue ? 'valid': ''}`}>
                                          First name and any middle names
                                      </label>
                                      <div className="form__error">
                                        {$validation[firstNameField].show
                                          ? $validation[firstNameField].error.reason
                                          : ''}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form__row">
                                    <div className="input-label-container">
                                      <input
                                        type="text"
                                        value={lastNameValue}
                                        name={lastNameField}
                                        id={lastNameField}
                                        aria-labelledby={'lastNameField'}
                                        onChange={(e) => onChange(lastNameField, e.target.value)}
                                        onBlur={() => this.props.$fieldEvent('blur', lastNameField)}
                                      />
                                      <label htmlFor={lastNameField} id={'lastNameField'} className={`${'form__label'} ${lastNameValue ? 'valid': ''}`}>
                                          Last name
                                      </label>
                                      <div className="form__error">
                                        {$validation[lastNameField].show
                                          ? $validation[lastNameField].error.reason
                                          : ''}
                                      </div>
                                    </div>
                                  </div>


                              </div>

                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                    {
                        shouldExpand && (members.length || requiredOutboundFrom) ? (
                            <div className="button-container">
                                <div className="button-container__card-display"/>
                                <div className="button-container__card-info">
                                    <div className="form__row form-space">
                                        <button className="btn checkout__btn" style={getBrandingButtonStyle()} type="submit">
                                            {submitButtonText ? submitButtonText : 'Continue to Billing'}
                                            <i className="icon-right-open" />
                                        </button>
                                        {submitError ?
                                            <div className="form__error form__error--general form__error--start">
                                                Please complete the
                                                errors above
                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </>
              )}
                </form>
            </div>
          </div>
        );
    }
}

export default validated(travelValidationConfig)(Travel);
