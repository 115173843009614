import React from 'react';

export default function Loader() {
    return (
        <div className="loader-container">
            <img
                alt="Loading"
                className="loader"
                src="/img/dna-loader.svg"
            />
        </div>
    );
}
