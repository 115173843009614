import React from 'react';
import { validated } from 'react-custom-validation';
import { withRouter } from 'react-router-dom';
import { StyledButton, StyledWhiteButton } from '../../../theming/themed-components';
import FormSection from './component/form-section';
import validationRules from '../../helpers/validationRules';
import { Tooltip } from 'recharts';

class ChangePasswordForm extends React.Component {
  render() {
    const {
      fields,
      onChange,
      onValid,
      $field,
      $validation,
      submitError,
      disableSubmitButton,
      successMessage,
      history,
    } = this.props;

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.props.$submit(onValid);
        }}
        className="form account-form checkout-form"
      >
        <div className="edit-password__wrapper">
          <div className="layout-2col__box">
            <div className="edit-password">
              <FormSection
                preFixClass="edit-password"
                fields={fields}
                label="Current password"
                id="current_password"
                required
                $field={$field}
                onChange={onChange}
                type="password"
                isInputElement={true}
                name="current_password"
                $validation={$validation}
                // inputAddon={<i className="icon-eye" />}
              />
              <FormSection
                preFixClass="edit-password"
                fields={fields}
                label="New password"
                id="password"
                required
                $field={$field}
                onChange={onChange}
                type="password"
                isInputElement={true}
                name="password"
                $validation={$validation}
                // inputAddon={<i className="icon-eye" />}
                labelAddon={
                  <Tooltip title="Minimum 8 characters" position="bottom">
                    <i className="icon-info-circled help-icon form__help-icon" />
                  </Tooltip>
                }
              />
              <FormSection
                preFixClass="edit-password"
                fields={fields}
                label="Confirm password"
                id="confirm_password"
                required
                $field={$field}
                onChange={onChange}
                type="password"
                isInputElement={true}
                name="confirm_password"
                $validation={$validation}
              />
            </div>
          </div>

          <div className="form__row edit-password__button-container">
            <div>
              <StyledButton className="btn" disabled={disableSubmitButton} type="submit">
                Save
                <i className="icon-right-open" />
              </StyledButton>
              <StyledWhiteButton className="btn cancel" type="button" onClick={() => history.push('/dashboard/account')}>
                Cancel
                <i className="icon-right-open" />
              </StyledWhiteButton>
              {successMessage && <p className="account-form__success">{successMessage}</p>}
              {submitError && <div className="form__error form__error--general">{submitError}</div>}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function changePasswordValidationConfig(props) {
  const { current_password, password, confirm_password } = props.fields;

  return {
    fields: ['current_password', 'password', 'confirm_password'],
    validations: {
      current_password: [[validationRules.isRequired, current_password]],
      password: [
        [validationRules.isRequired, password],
        [validationRules.complexPassword, password],
      ],
      confirm_password: [
        [validationRules.isRequired, confirm_password],
        [validationRules.matches, confirm_password, password],
      ],
    },
  };
}

export default withRouter(validated(changePasswordValidationConfig)(ChangePasswordForm));
