import React from 'react';
import utils from '../helpers/utils';


export function CarouselNextArrow(props) {
  const { className, style, onClick } = props;
  const click = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    utils.track('Carousel scroll', {direction: 'Next'});
  };
  return (
    <i
      className={`icon-right-open-big ${className}`}
      style={{ ...style }}
      onClick={click}
    />
  );
}

export function CarouselPrevArrow(props) {
  const { className, style, onClick } = props;
  const click = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    utils.trackEvent('Carousel scroll', {direction: 'Previous'});
  };
  return (
    <i
      className={`icon-left-open-big ${className}`}
      style={{ ...style }}
      onClick={click}
    />
  );
}
