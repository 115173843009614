import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';

import FAQ from '../../../containers/you/indicator/faq';

import Loader from '../../common/loader';
import Overview from './overview';
import Science from './science';

class Futures extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            title: '',
            subTitle: '',
            age: null,
            intervenables: null,
            moreInfo: null,
            intervenablesTitle: null,
        };
    }

    componentDidMount() {
        axios.get('test_user/results/futures')
            .then(({ data = {} }) => {
                const { title, sub_title, age, intervenables, intervenables_title, more_info, related_articles} = data;

                this.setState({
                    title,
                    subTitle: sub_title,
                    age: age,
                    intervenables: intervenables,
                    moreInfo: more_info,
                    intervenablesTitle: intervenables_title,
                    relatedArticles: related_articles,
                    isLoading: false,
                });
            }).catch((err) => {
            this.setState({ isLoading: false }, () => { throw err; });
        });
    }

    

    renderHeroSection() {
        const { title, subTitle } = this.state;
        const { selectedTab } = this.props;

        if(selectedTab === 'science') {
            return (
                <div>
                    <Helmet>
                        <title>Futures - Hurdle</title>
                    </Helmet>
                        <div className="result-hero__information-section">
                            <h1 className="result-hero__title study-hero__title">My Future: The Science</h1>
                        </div>
                </div>
            );
        }

        return (
            <div>
                <Helmet>
                    <title>Futures - Hurdle</title>
                </Helmet>
                {
                    (title || subTitle) ?
                    <div className="result-hero__information-section">
                        {title && <h1 className="result-hero__title study-hero__title">{title}</h1>}
                        {subTitle && <p className="result-hero__title-information">{subTitle}</p>}
                    </div> : ''
                }
            </div>
        );
    }

    render() {
        const { isLoading, moreInfo, age, intervenables, intervenablesTitle, relatedArticles, title } = this.state;
        const { match, breakpoint } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        const OverviewTab = props =>
            <Overview
                {...props}
                age={age}
                intervenables={intervenables}
                intervenablesTitle={intervenablesTitle}
                title={title}
            />;

        const ScienceTab = props => <Science {...props} relatedArticles={relatedArticles} breakpoint={breakpoint} />;

        const FAQPage = () => <FAQ accordion={moreInfo} />;

        return (
            <section className="content-wrapper-animate chromosome-age__container">
                <section className="result-hero futures__hero-section">
                    {this.renderHeroSection()}
                </section>
                <section>
                    <Switch>
                        <Route path={`${match.url}/science`} render={ScienceTab} />
                        <Route path={`${match.url}/faq`} render={FAQPage} />
                        <Route path={match.url} render={OverviewTab} />
                    </Switch>
                </section>
            </section>
        );
    }
}

Futures.defaultProps = {
    selectedTab: 'overview',
    breakpoint: 'large'
};

Futures.propTypes = {
    selectedTab: PropTypes.string,
    breakpoint: PropTypes.string
};

export default Futures;
