import React, {Component} from 'react';

import { validated } from 'react-custom-validation';
import validationRules from '../helpers/validationRules';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';


function shippingMethodValidationConfig(props) {
    const { shipping_method } = props.fields;
    return {
        fields: ['shipping_method'],
        validations: {
            shipping_method: [
                [validationRules.isRequired,shipping_method]
            ]
        }
    };
}

class ShippingMethod extends Component {
    constructor(props) {
        super(props);
        
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }
    onSubmit(e) {
        e.preventDefault();
        const {$submit, onInvalid, onValid, } = this.props;
        $submit(() => onValid(null, 'shippingMethod'), onInvalid);
    }
    onEdit() {
        const { UpdateState, history } = this.props;
        const sectionsComplete = {
            account: true,
            delivery: false,
            payment: false
        };
        UpdateState({ sectionsComplete });
        history.push('/join/delivery');
    }

    render() {
        const { 
            branding,
            fields,
            $field,
            $validation,
            onChange,
            submitError,
            postage = {}, 
            isCompleted, 
            shouldExpand
        } = this.props;

        const partnerBranding = cachedPartnerTheming();

        const getBrandingStyle = () => (branding && branding.hasBranding && branding.section) ? {
            color: branding.section.highlight_colour,
        } : {};

        const getBrandingButtonStyle = () => {
          if (partnerBranding || branding?.hasBranding) {
            const theme = partnerBranding ? partnerBranding.theme : branding;
            return { background: theme.section.cta_colour };
          }
          return {};
        };

        return (
            <div className="checkout new-checkout-flow" id="delivery-section">
                <div className={`${'checkout__form-col order-summary'}`}>
                    <h4 className="checkout__title checkout__title" style={getBrandingStyle()}>
                        2. Shipping method
                        {(isCompleted && <span className="checkout__title--orderstep" onClick={this.onEdit} style={getBrandingStyle()}>
                            EDIT
                                         </span>) || <span className="checkout__title--orderstep" style={getBrandingStyle()}>
                                Step 2 of 3
                                                     </span>}
                    </h4>
                    <div className={`card-wrapper ${shouldExpand ? 'slide' : '' }`}>
                        <div className={'card-container'}>
                            <div className="card-container__card-display">
                                <div>Select Shipping</div>
                                {!isCompleted &&
                                        <div className="card-container__field-information">This includes delivery and return postage to our lab using tracked postage</div>
                                }
                            </div>
                            {!isCompleted && <div className="card-container__card-info">
                                <div>
                                    <input type="radio" name="shipping_method" id="shipping_method" value="default" checked={fields.shipping_method=='default'} {...$field(
                                        'shipping_method',
                                        e => onChange('shipping_method', e.target.value)
                                    )}
                                    />
                                    <label
                                        className="checkout-options__option-label" htmlFor="shipping_method"
                                    > {postage && postage.postage_price === 0 ? 'Free' : ''}
                                        <span>{postage && postage.postage_price == 0 ? '' : postage && postage.postage_price_formatted}</span>
                                    </label>
                                    <div className="form__error form__error--start">
                                        {$validation
                                            .shipping_method
                                            .show
                                            ? $validation
                                                  .shipping_method
                                                  .error.reason
                                            : ''}
                                    </div>
                                </div>
                                <div className="form__row">
                                    <button className="btn checkout__btn" style={getBrandingButtonStyle()} onClick={this.onSubmit}>
                                        Continue to Billing
                                        <i className="icon-right-open"/>
                                    </button>
                                    {submitError &&
                                        <div className="form__error form__error--general form__error--start">
                                            Please complete the errors above
                                        </div>
                                    }
                                </div>
                                             </div>
                            || <div>
                                <div>
                                    <label className="checkout-options__option-label">
                                        {postage && postage.postage_price === 0 ? 'Free' : ''}
                                        <span>{postage && postage.postage_price == 0 ? '' : postage && postage.postage_price_formatted}</span>
                                    </label>
                                </div>
                               </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default validated(shippingMethodValidationConfig)(ShippingMethod);
