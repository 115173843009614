import React,{Component} from 'react';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


class PageTour extends Component {
    constructor(){
        super();
        this.state={
            isTourOpen: true,
        };
        this.closeTour = this.closeTour.bind(this);
    }

    closeTour(){
        const {isTourOpen} = this.state;
        this.setState({isTourOpen: false});
    }

    disableBody = target => disableBodyScroll(target);
    enableBody = target => enableBodyScroll(target);

  render(){
    const {steps} = this.props;
      return(
        <div>
            <Tour
                steps={steps}
                isOpen={this.state.isTourOpen}
                onRequestClose={this.closeTour}
                showCloseButton= {true}
                showNumber={false}
                closeWithMask={false}
                showButtons={false}
                showNavigation={false}
                disableDotsNavigation={true}
                rounded={5}
                className="tour-container"
                maskClassName="mask"
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
            />
        </div>
      );
  }
}

export default PageTour;
