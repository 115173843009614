import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Disclaimer from '../../../common/disclaimer';
import FunFact from '../../../common/funFact';
import Loader from '../../../common/loader';
import RelatedArticles from '../../../common/relatedArticles';
import Accordion from '../../../common/accordion';
import HeroCTAButtons from '../../../common/heroCtaButtons';
import CustomizeBarChart, { RATINGS_MAP } from '../../../common/customizeBarChart';
import Question from '../../../common/question';

class Intervenable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervenable: null,
        };
    }

    componentDidMount() {
        const { slug } = this.props;

        if (slug) {
            axios.get(`test_user/results/intervenable/${slug}`)
                .then(( response = {} ) => {
                    const { data } = response;
                    if (data) {
                        this.setState({
                            intervenable: { ...data }
                        });
                    }
                })
                .catch((err) => {
                    this.setState(() => { throw err; });
                });
        }
    }

    render() {
        const { intervenable } = this.state;
        const { history } = this.props;

        if (intervenable === null) {
            return (
                <Loader />
            );
        }

        const {
            title,
            intro_text,
            result_intro_text,
            result_status,
            result_text,
            intervenables,
            what_means_title,
            what_means_text,
            background_image_url,
            icon_url,
            intervenables_title,
            intervenables_text,
            quotation: {
                from: quotationFrom,
                icon_url: quotationIconUrl,
                text: quotationText,
            },
            improvement: {
                title: improvementTitle,
                intro_text: improvementIntroText,
                cta_text: improvementCtaText
            },
            implications: {
                title: implicationsTitle,
                intro_text: implicationsIntroText,
                cta_text: implicationsCtaText
            },
            related_articles,
            fun_facts: {
                data: items,
                title: funFactTitle
            },
            more_info: {
                title: aboutTitle,
                data: aboutIntervenable
            },
            disclaimer_text,
            json_schema: {
                items: jsonSchema
            },
        } = intervenable;
        const { breakpoint = '' } = this.props;

        const intervenablesData = intervenables.reduce((acc, intervenable) => {
            const { rating, name } = intervenable;
            const { value, color } = RATINGS_MAP[rating] || {};
            return {
                    graphData: [ ...acc.graphData, { value, color, name: name.replace('/', ' / ') }],
            };
        }, {graphData: []});

        const isTinySmallDevice = breakpoint === 'tinySmall';

        return (
            <Fragment>
                <Helmet>
                    <title>{title} - Hurdle</title>
                </Helmet>
                {!isTinySmallDevice && <HeroCTAButtons />}

                <div className="intervenable-container">
                    <h2 className="intervenable-container__title">{title}</h2>
                    <p className="intervenable-container__description">{intro_text}</p>
                    
                    <div className="intervenable-container__active-on-improvement-container">
                        {/*}
                        <span className="active-on-improvement-text">Active on improvements</span>
                        <img className="active-on-improvement-icon" src="/img/sleep/improvement.png"/>
                        */}
                    </div>
                    

                    <div className="intervenable-container__improvement-container">
                        <div className="needing-improvement" style={{backgroundImage: `url(${background_image_url})`}}>
                            {/*}<img src="/img/sleep/improvement.png"/> */}

                            <div className="needing-improvement__container">
                                <p>{result_intro_text}</p>
                                <h6 className={`needing-improvement__container__result-status ${result_status}`}>
                                    {result_text}
                                </h6>
                                <Link className="btn btn--yellow" to="/dashboard/improvements">Improve</Link>
                            </div>

                            <div className="needing-improvement__disclaimer-text">{disclaimer_text}</div>

                        </div>
                        {/*<div className="intervenable-container__improvement-container__result">*/}
                            {/*{*/}
                                {/*intervenables.map(({ icon_url, name, score, rating, rating_title, slug, comment, units }, index) => (*/}
                                    {/*<div className="content" key={`improvement-${index}`}>*/}
                                        {/*<span className="content__title">{name}</span>*/}
                                        {/*<span className={`content__description ${rating}`}>{units === "hide" ? rating_title : score}</span>*/}
                                        {/*<Tooltip title={comment} position="bottom">*/}
                                            {/*<i className="icon-help help-icon"/>*/}
                                        {/*</Tooltip>*/}

                                        {/*{units !== '' && units !== 'hide' &&*/}
                                            {/*<div className={`content__description ${rating}`}>{units}</div>*/}
                                        {/*}*/}
                                    {/*</div>*/}
                                {/*))*/}
                            {/*}*/}
                        {/*</div>*/}

                    </div>

                    {jsonSchema ? <Question jsonSchema={jsonSchema} section="INTERVENABLES" /> : ''}

                    <div className="intervenable-container__mean-container">
                        <h2 className="intervenable-container__mean-container__title">{what_means_title}</h2>
                        <p className="intervenable-container__mean-container__description">{what_means_text}</p>
                    </div>
                    <div className="intervenable-container__help-in-improving-card">
                        <div className="summary-container">
                            <img src={icon_url} alt={title} />
                            <h3 className="summary-container__title"><q>{quotationText}</q></h3>
                            <p className="summary-container__description">{quotationFrom}</p>
                        </div>
                        <div className="detail-container">
                            <h4 className="detail-container__title">{improvementTitle}</h4>
                            <p className="detail-container__description">{improvementIntroText}</p>
                            <Link className="btn detail-container__improve-link" to="/dashboard/improvements">{improvementCtaText}</Link>
                        </div>
                    </div>

                    <div className="intervenable-container__bar-graph-section">
                        <h2 className="intervenable-container__bar-graph-section__title">{intervenables_title}</h2>
                        <p>{intervenables_text}</p>
                        <div className="intervenable-container__bar-graph-section__graph">
                            <CustomizeBarChart
                                data={intervenablesData.graphData}
                                domainAttr={[0, 'dataMax + 10']}
                                isTinySmallDevice={isTinySmallDevice}
                                yAxisDataKey="name"
                                yAxisWidth={isTinySmallDevice ? 70 : 150}
                            />
                        </div>
                    </div>

                    <div className="intervenable-container__implications-container">
                        <h2 className="intervenable-container__implications-container__title">{implicationsTitle}</h2>
                        <p className="intervenable-container__implications-container__description">{implicationsIntroText}</p>
                        <Link
                            className="btn intervenable-container__implications-container__button"
                            to="/dashboard/improvements"
                        >
                            {implicationsCtaText}
                        </Link>
                    </div>

                    <FunFact items={items} title={funFactTitle}/>

                    {aboutIntervenable.length > 0 && (
                        <div className="intervenable-container__accordian-section">
                            <Accordion items={aboutIntervenable} title={aboutTitle} />
                        </div>
                    )}

                    <RelatedArticles relatedArticles={related_articles}/>
                </div>
                <Disclaimer />
            </Fragment>
        );
    }
}

export default Intervenable;
