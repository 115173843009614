import React from 'react';
import PropTypes from 'prop-types';
//import ReactGA from "react-ga";

const FunFact = ({ items, title }) => Array.isArray(items) ? (
    <div className="fun-fact-container">
        <h5 className="fun-fact-container__header">{title}</h5>
        <div className="fun-fact-container__body">
            {items.map(
                ({ text, icon_url, title, link_title, link_url }, index) => <div
                    key={`fun-fact-${index}`}
                    className="fun-fact-container__body__item"
                                                                            >

                    {icon_url && icon_url !== '' &&
                        <div className="fun-fact__icon-container">
                            <img src={icon_url ? icon_url : '/img/fun-facts.png'} />
                        </div>
                    }
                    {title &&
                        <div className="fun-fact__title">{title}</div>
                    }
                    {text && <p>{text}</p>}
                    {/*ROBT: disable links for now*/ /*link_title && link_url &&
                        <div>
                            <a href={link_url}
                               onClick={() => utils.trackOutboundLink(link_url, 'FunFacts')}
                               target="_blank"
                               className="fun-fact__link">{link_title}</a>
                        </div>
                    */}
                                                                            </div>
            )}
        </div>
    </div>
) : '';


FunFact.propTypes = {
    //items: PropTypes.array, //disabled as if translations. are missing gets passed as string
    title: PropTypes.string
};

export default FunFact;
