import {green, orange, lightGreen, lightOrange, defaultColor} from '../barColors';

export const getDefaultBarColors = ({currentBarNumber, totalNumberOfBars}) => {

    const twoBarRange = [green, orange];
    const threeBarRange = [orange, green, orange];
    const fourBarRange = [orange, lightOrange, green, orange];
    const fiveBarRange = [orange, lightOrange, lightGreen, green, orange];

    if(totalNumberOfBars < 2 && currentBarNumber < 1) return defaultColor;

    if(totalNumberOfBars === 2 && currentBarNumber <= 1){
        return twoBarRange[currentBarNumber];
    }

    if (totalNumberOfBars === 3 && currentBarNumber <= 2) {
        return threeBarRange[currentBarNumber];
    }

    if (totalNumberOfBars === 4 && currentBarNumber <= 3) {
        return fourBarRange[currentBarNumber];
    }

    if (totalNumberOfBars === 5 && currentBarNumber <= 4) {
        return fiveBarRange[currentBarNumber];
    }
    
    if (totalNumberOfBars >5 ) {
      if (currentBarNumber <= 4) {
            return fiveBarRange[currentBarNumber];
      }

      return orange;
    }

    return defaultColor;
};

export const getStartFromNormalBarColors = ({currentBarNumber, totalNumberOfBars}) => {

    const twoBarRange = [green, orange];
    const threeBarRange = [green, lightOrange, orange];

    if(totalNumberOfBars < 2 && currentBarNumber < 1) return defaultColor;

    if(totalNumberOfBars === 2 && currentBarNumber <= 1){
        return twoBarRange[currentBarNumber];
    }

    if (totalNumberOfBars === 3 && currentBarNumber <= 2) {
        return threeBarRange[currentBarNumber];
    }
    
    if (totalNumberOfBars >3 ) {
      if (currentBarNumber <= 2) {
            return threeBarRange[currentBarNumber];
      }

      return orange;
    }

    return defaultColor;
};

export const getBarColorFromRangeName = (rangeName) => {

    const orangeRanges = ['low', 'l', 'high', 'h', 'deficient', 'diabetes', 'very_high'];
    const lightOrangeRanges = ['insufficient', 'pre-diabetic'];
    const lightGreenRanges = ['adequate'];
    const greenRanges = ['normal', 'optimal'];

    if(orangeRanges.includes(rangeName.toLowerCase())){
        return orange;
    } else if (lightOrangeRanges.includes(rangeName.toLowerCase())){
        return lightOrange;
    } else if (lightGreenRanges.includes(rangeName.toLowerCase())){
        return lightGreen;
    } else if (greenRanges.includes(rangeName.toLowerCase())){
        return green;
    }
    
    return '#666';
};