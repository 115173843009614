import React from 'react';
import { StyledSpan } from '../../theming/themed-components';
import { ProgressSteps, ProgressStepText } from '../common/progressSteps';
import { latestOrderStatus, orderToProgressSteps } from './utils';

const OrderStatusSmall = ({ order }) => {
  const steps = orderToProgressSteps(order);
  const currentStep = latestOrderStatus(steps);
  const titles = [];
  if (Array.isArray(order.items)) {
    order.items.forEach(item => titles.push(item.title));
  }
  const joinedTitles = titles.join();

  return (
    <div className="order-status-small">
      <div>
        <span className="order-status-small__title">{joinedTitles}</span>
        <ProgressStepText dateTime={currentStep.dateTime} name={currentStep.name} />
      </div>
      <div className="order-status-small__right">
        <ProgressSteps hideText={true} steps={steps} />
        <div>
          <span className="linkSpan" to="/dashboard/orders">
            <StyledSpan>Details</StyledSpan>
            <StyledSpan className="arrow right"></StyledSpan>
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusSmall;
