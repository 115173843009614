import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const dateFields = {
    day: {name: 'day', label: 'DD', type: 'text', maxLength: 2},
    month: {name: 'month', label: 'MM', type: 'text', maxLength: 2},
    year: {name: 'year', label: 'YYYY', type: 'text', maxLength: 4},
};

class CustomDate extends Component {
    constructor(props) {
        super(props);

        const day = props.dateFormat === 'MM-YYYY' ? '01' : '';

        this.state = {
            day: day,
            month: '',
            year: '',
            age: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        const { day, month, year } = this.state;
        const { user } = window.CONFIG;
        let date = `${day}-${month}-${year}`;

        if(user.mobile_country == '+1') {
            date = `${month}-${day}-${year}`;
        }

        if(this.props.value && (date !== this.props.value)) {
            const dateArray = this.props.value.split('-');
            if(user.mobile_country == '+1') {
                this.setState({
                    day: dateArray[1],
                    month: dateArray[2],
                    year: dateArray[0]
                }, () => {this.setState({age: this.calculateAge(this.state)});});
            } else {
                this.setState({
                    day: dateArray[2],
                    month: dateArray[1],
                    year: dateArray[0]
                }, () => {this.setState({age: this.calculateAge(this.state)});});
            }
        }
    }

    onBlur() {
        this.setState({age: this.calculateAge(this.state)});
    }

    calculateAge({day, month, year, age}) {
        if(day && month && year) {
            const birthDate = moment(new Date(`${month}-${day}-${year}`));
            const currentDate = moment(new Date());

            return currentDate.diff(birthDate, 'year');
        }

        return age;
    }

    onChange(event) {
        const { name, value } = event.target;
        const { id, onChange } = this.props;

        if(isNaN(Number(value))) {
            return null;
        } else {
            this.setState({[name]: value}, () => {
                if(this.state.day && this.state.month && this.state.year) {
                    const dateValue = `${this.state.year}-${this.state.month.padStart(2, '0')}-${this.state.day.padStart(2, '0')}`;
                    onChange(id, dateValue);
                }
            });
        }
    }

    renderDateValidation() {
        const { age } = this.state;
        const { show_age_validation } = this.props;

        if(age !== '' && show_age_validation) {
            return (
                <div className="date-confirmation">You're {age} years old, is this correct?</div>
            );
        }

        return null;
    }

    renderFields(fields) {
        return (
            <div className="custom__date-container">
                {
                    fields.map((field) => (
                            <div className="field-section" key={field}>
                                <div className="input-field__container">
                                    <input
                                        type={dateFields[field].type}
                                        value={this.state[field]}
                                        name={dateFields[field].name}
                                        onChange={this.onChange}
                                        id={dateFields[field].name}
                                        className="input-field"
                                        maxLength={dateFields[field].maxLength}
                                        onBlur={this.onBlur}
                                    />
                                    <label
                                        htmlFor={dateFields[field].name}
                                        className={`${'input-field__label'} ${this.state[field] ? 'valid' : ''}`}
                                    >
                                        {dateFields[field].label}
                                    </label>
                                </div>
                            </div>
                        )
                    )
                }
                {this.renderDateValidation()}
            </div>
        );
    }

    render() {
        const { user } = window.CONFIG;
        const { dateFormat } = this.props;

        if(dateFormat === 'MM-YYYY') {
            return this.renderFields(['month', 'year']);
        }

        //Format date for americans
        return user.mobile_country == '1' ? this.renderFields(['month', 'day', 'year']) : this.renderFields(['day', 'month', 'year']);
    }
}

CustomDate.defaultProps = {
    dateFormat: 'DD-MM-YYYY'
};

CustomDate.propTypes = {
    id: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    dateFormat: PropTypes.string
};

export default CustomDate;