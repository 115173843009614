import * as React from "react";

function SvgShoppingTrolley(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M23 46c12.703 0 23-10.297 23-23S35.703 0 23 0 0 10.297 0 23s10.297 23 23 23z"
                fill="currentColor"
            />
            <path fill="#fff" d="M21 24h6v6h-6z" />
            <path
                d="M23.25 26.25V24h1.5v2.25h-1.5zM23.25 27.75V27h1.5v.75z"
                fill="currentColor"
            />
            <path d="M11 17v17h24V17l-12-6-12 6z" fill="#fff" stroke="#fff" />
            <path fill="currentColor" d="M20 18h10v10H20z" />
            <path
                d="M23.75 21.75V18h2.5v3.75h-2.5zM23.75 24.25V23h2.5v1.25z"
                fill="#fff"
            />
            <circle cx={19.5} cy={33.5} r={1.5} fill="currentColor" />
            <circle cx={27.5} cy={33.5} r={1.5} fill="currentColor" />
            <path d="M18 21h-4v2h2v9h14v-2H18v-9z" fill="currentColor" />
        </svg>
    );
}

export default SvgShoppingTrolley;
