import PropTypes from 'prop-types';
import { getRanges } from './utils';
import { formatInterpretation } from '../utils';

const TimeDetails = ({ interpretationData, unit }) => {
  const allRanges = getRanges(interpretationData);

  return (
    <>
      {allRanges.map(({ timeRangeAM, timeRangePM, value }, index) => {
        const ranges = Object.entries(value);

        return (
          <div key={index} className="biomarker-modal__text">
            {index > 0 && <br />}
            <div className="biomarker-modal__text">
              {timeRangeAM ? `Reference range ${timeRangeAM.join('-')} AM:` : `Reference range ${timeRangePM.join('-')} PM:`}
            </div>
            {ranges.map(([key, range]) => {
              const [min, max] = range;
              const formattedInterpretation = formatInterpretation(key);

              return (
                <div key={key} className="biomarker-modal__text">
                  {formattedInterpretation}: {min}
                  {max && ' - '}
                  {max} {unit}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

TimeDetails.propTypes = {
  interpretationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  unit: PropTypes.string,
};

export default TimeDetails;
