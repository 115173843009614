import React, { Component } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const CustomTooltip = (props) => {
    if(!props.active) {
        return null;
    }

    const { ledgend, payload, label } = props;

    return (
        <div className="custom-tooltip">
            <p className="tooltip-title">{label}</p>
            <div className="tooltip-values">
                {
                    payload.map((item, index) => (
                        <div key={index}>
                            {ledgend[item.dataKey].replace('(%)', '')}: {item.value}%
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

class HorizontalBarGraph extends Component {
    constructor(props) {
        super(props);

        const keys = props.tabs && Object.keys(props.tabs);

        this.state = {
            selectedTab: keys[0]
        };

        this.selectTab = this.selectTab.bind(this);
    }

    selectTab(value) {
        this.setState({selectedTab: value});
    }

    renderTabs() {
        const { selectedTab } = this.state;
        const { tabs } = this.props;
        if(!(tabs && Object.keys(tabs).length)) {
            return null;
        }

        return (
            <div className="line-graph__tabs-container">
                {
                    Object.keys(tabs).map((key, index) => {
                        return (
                            <button
                                className={`single-tab type${selectedTab === key ? ' active' : ''}`}
                                key={index}
                                onClick={() => this.selectTab(key)}
                            >
                                {tabs[key]}
                            </button>
                        );
                    })
                }
            </div>
        );
    }

    renderLegend() {
        const { ledgend } = this.props;
        const { selectedTab } = this.state;

        return (
            <div className="legends__container full-width">
                <div className="legends__section">
                    <div className="item-legend" />
                    <p className="legend-title">{ledgend.epigenetic_result}</p>
                </div>
                <div className="legends__section">
                    <div className="item-legend type" />
                    <p className="legend-title">{ledgend[selectedTab]}</p>
                </div>
            </div>
        );
    }

    renderGraph() {
        const { data, ledgend } = this.props;
        const { selectedTab } = this.state;

        return (
            <div className="line-graph__graph-container bar-graph-section">
                <ResponsiveContainer>
                    <BarChart
                        data={data}
                        layout="vertical"
                    >
                        <XAxis
                            axisLine={false}
                            domain={[0, 100]}
                            tickCount={6}
                            tickLine={false}
                            type="number"
                        />
                        <CartesianGrid horizontal={false} strokeDasharray="10 8" />
                        <Tooltip active={true} cursor={{fill: 'transparent'}} content={<CustomTooltip ledgend={ledgend} />} />
                        <Bar
                            dataKey="epigenetic_result"
                            fill="#5B7BFF"
                            radius={[0, 20, 20, 0]}
                        />
                        <Bar
                            dataKey={selectedTab}
                            fill="#FF9EAF"
                            radius={[0, 20, 20, 0]}
                        />
                        <YAxis
                            dataKey="name"
                            width={80}
                            stroke="#BABABA"
                            tickLine={false}
                            type="category"
                            interval={0}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    render() {
        const { data } = this.props;
        if(!(data && data.length)) {
            return null;
        }

        return (
            <div className="graph-section-container stress-compare-graph no-margin bar-graph-container">
                <div className="line-graph__container horizontal-graph">
                    {this.renderTabs()}
                    {this.renderGraph()}
                    {this.renderLegend()}
                </div>
            </div>
        );
    }
}

export default HorizontalBarGraph;
