import React from 'react';

import FAQ from '../../../containers/you/common/faqs';

import RelatedArticles from '../../common/relatedArticles';
import ProcessCard from './processCard';


const FuturesProcessData = [
    {
        description: 'Take current biological age score and information derived from our clinically-validated questionnaires.',
        image: '/img/you/futures/biological-age-score.png'
    },
    {
        description: 'Project biological age score forwards 10 years.',
        image: '/img/you/futures/biological-age-forwards.png'
    },
    {
        description: 'Use statistical models to predict the impact of different lifestyle and environmental factors on your biological age.',
        image: '/img/you/futures/age-difference.png'
    },
    {
        description: 'Determine potential biological age gains from steady adherence to changes.',
    }
];

const FutureProcess = ({breakpoint}) => {
    return (
        <div className="futures__science-process">
            {
                FuturesProcessData.map((item, index) => (
                    <ProcessCard
                        description={item.description}
                        rightSideSection={index%2 !== 0}
                        number={index+1}
                        breakpoint={breakpoint}
                        key={index}
                    >
                        <div className="process-card__children-section">
                            {
                                item.image ? <img src={item.image} alt={item.description} /> :
                                    <div className="bolt-text">
                                        <i className="icon-bolt" />5
                                    </div>
                            }
                        </div>
                    </ProcessCard>
                ))
            }
        </div>
    );
};

const DownArrow = () => {
    return (
        <svg viewBox="0 0 15 45">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-713.000000, -1159.000000)" fill="#979797" stroke="#979797">
                    <g transform="translate(720.500000, 1181.500000) rotate(90.000000) translate(-720.500000, -1181.500000) translate(698.000000, 1175.000000)">
                        <path d="M36.5,7.5 L36.5,5.5 L0.5,5.5 L0.5,7.5 L36.5,7.5 Z" id="Rectangle" />
                        <path d="M38.9472136,0.894427191 L44.6381966,12.2763932 C44.7616912,12.5233825 44.6615791,12.823719 44.4145898,12.9472136 C44.3451621,12.9819275 44.2686056,13 44.190983,13 L32.809017,13 C32.5328746,13 32.309017,12.7761424 32.309017,12.5 C32.309017,12.4223775 32.3270895,12.3458209 32.3618034,12.2763932 L38.0527864,0.894427191 C38.176281,0.647437942 38.4766175,0.547325769 38.7236068,0.670820393 C38.8203705,0.719202244 38.8988317,0.79766349 38.9472136,0.894427191 Z" id="Triangle" transform="translate(38.500000, 6.500000) rotate(90.000000) translate(-38.500000, -6.500000) " />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const Science = ({relatedArticles, breakpoint}) => {
    return (
        <div className="futures__container">
            <div className="futures__science-section first-section">
                <h4 className="section-title">Your future biological age</h4>
                <div className="futures__science-image-section">
                    <div className="information-section">
                        Different things affect your biological age, like smoking and diet
                    </div>
                </div>
                <div className="down-arrow">
                    <DownArrow />
                </div>
                <div className="futures__science-image-section">
                    <div className="image-section">
                        <img src="/img/you/futures/impact-magnitude.png" alt="magnitude-impact" />
                    </div>
                    <div className="information-section">
                        These things have different magnitudes of impact
                    </div>
                </div>
                <div className="down-arrow">
                    <DownArrow />
                </div>
                <div className="futures__science-image-section">
                    <div className="image-section">
                        <img src="/img/you/futures/impact-changes.png" alt="impact-changes" />
                    </div>
                    <div className="information-section">
                        We determine the impact of changes to lifestyle on your future health. These changes help you live healthier for longer.
                    </div>
                </div>
            </div>
            <div className="futures__science-section">
                <h4 className="section-title">We calculate your future biological age with the following process:</h4>
                <FutureProcess breakpoint={breakpoint} />
            </div>
            {relatedArticles &&
                <div className="futures__science-section">
                    <RelatedArticles relatedArticles={relatedArticles} className="futures__science-related-articles" />
                </div>
            }
            <div className="futures__science-faqs">
                <FAQ />
            </div>
        </div>
    );
};

export default Science;