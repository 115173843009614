import React, { Component } from 'react';

import config from '../../config';
import axios from 'axios';
import { StyledButton, StyledContainer } from '../../theming/themed-components';
class Chat extends Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen: true,
            message: '',
            submit: false,
            submitError: false
        };

        this.handleOnClick = this.handleOnClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeChat = this.closeChat.bind(this);
    }

    handleOnClick() {
        const {isOpen, submit} = this.state;
        this.setState({isOpen: !isOpen,  submitError: false});
        if(submit){
            this.setState({submit: false, message: ''});
        }
    }

    onChange(e) {
        this.setState({message: e.target.value});
    }

    closeChat(openChat){
        const {submit} = this.state;
        this.setState({submitError: false});
        openChat();
        if(submit){
            this.setState({submit: false, message: ''});
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const hubspotData = {
            'fields': [
                {
                    'name': 'email',
                    'value': config.app && config.app.user ? config.app.user.email : '',
                },
                {
                    'name': 'message',
                    'value': this.state.message
                },
                {
                    'name': 'TICKET.ticket_category',
                    'value': 'Account',
                },
            ],
            'context': {
                'pageUri': window.location.href,
                'pageName': document.title,
            }
        };

        const axiosHubspotInstance = axios.create();
        axiosHubspotInstance.post(config.app.hubspot_contact_form_url,
            hubspotData).then(() => {
                this.setState({submit: true});
        }).catch(() => {
            this.setState({submitError: true});
        });
    }

    render(){
        const { isOpen, submit, submitError } = this.state;
        const { openChat, openChatValue } = this.props;
        if(!openChatValue) {
            return null;
        }

        return(
            <div className="chat__container">
                <div className="chat__section">
                    {
                        isOpen ?
                            <div className="chat__message-container">
                                <StyledContainer className="chat__message-header">
                                    <div className="header-detail-section">
                                        <div className="header-image-section">
                                            <img src="/img/user-icon.png" alt="user-placeholder"/>
                                        </div>
                                        <span>Tom</span>
                                    </div>
                                    <img className="close-chat-icon" src="/img/cross.png" onClick={() => this.closeChat(openChat)}/>
                                </StyledContainer>
                                <div className="chat__message-section">
                                    {
                                        submit ?
                                            <div>
                                                <div className="user-message">
                                                    <p>{submit ? this.state.message : ''}</p>
                                                </div>
                                                <div className="user-message reply">
                                                    <p>Thanks for your message, Hurdle typically replies within 24 hours by email.</p>
                                                </div>
                                            </div> :
                                            <div>
                                                <div className="chat__textarea">
                                                    <div className="title">Hi, what can I help you with today?</div>
                                                    <textarea
                                                        data-gramm_editor="false"
                                                        onChange={this.onChange}
                                                        placeholder="Start Typing..."
                                                        rows="6"
                                                        value={this.state.message}
                                                    />
                                                </div>
                                                <div className="submit-button">
                                                    <StyledButton className="btn no-shadow" onClick={this.onSubmit}>Submit</StyledButton>
                                                </div>
                                                {
                                                    submitError?
                                                    <div className="form__error">Sorry, there was an issue submitting your enquiry.</div>
                                                    :''
                                                }
                                            </div>
                                    }
                                </div>
                            </div> : ''
                    }
                    <StyledContainer className="chat__chat-button" onClick={this.handleOnClick}>
                        {
                            !isOpen ?
                            <svg width="24px" height="28px" viewBox="0 0 24 28" fill="transparent" stroke="#ffffff">
                                <g strokeWidth="1" fillRule="evenodd">
                                    <g transform="translate(-331.000000, -2346.000000)" fillRule="nonzero" strokeWidth="1.4">
                                        <g transform="translate(0.000000, 2334.000000)">
                                            <g transform="translate(328.000000, 11.000000)">
                                            <path d="M22,20 L22,20 C22,20 21,21 20,21 C19,22 17,22 15,22 C13,22 11,22 10,21 C9,21 8,20 8,20 C7,19 ,7,18 8,18 C8,17 9,17 10,18 C10,18 10,18 10,18 C10,18 11,18 11,19 C12,19 13,19 15,19 C17,19 18,19 19,19 C20,18 20,18 20,18 C20,18 20,18 20,18 C21,17 22,17 20,18 C23,18 23,19 22,20 Z M25,27 C25,26 25,26 25,24 C25,23 25,23 25,23 L25,7 C25,6 24,5 23,5 L7,5 C6,5 5,6 5,7 L5,23 C5,24 6,25 7,25 L19,25 C20,25 22,26 25,27 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>:
                            <svg viewBox="0 0 24 24" width="24px" height="24px" fill="transparent" stroke="#ffffff" strokeWidth="2">
                                <path d="M 3,3 L 23,23 M 23,3 L 3,23" />
                            </svg>
                        }
                    </StyledContainer>
                </div>
            </div>
        );
    }
}

export default Chat;
