import React from 'react';

export default function TravelCard({members, fields = {}, countrys=[], test_available = ''}) {
    let country = fields.departure_country;
    countrys.map(function(value) {
        if(value.country_code == fields.departure_country) {
            country = value.title;
        }
    });

    return (
    <>
        <div className="card-wrapper slide">
            <div className="card-container">
            <div className="card-container__card-display">
                <div>Travel details for all passengers</div>
            </div>

            <div className="card-container__card-info">
                
                <div className="card-details-row">
                    Date of arrival in the UK<br />
                    <strong>{fields.arrival_day}/{fields.arrival_month}/{fields.arrival_year}</strong>
                </div>
                <div className="card-details-row">
                    Flight number, coach number or vessel name<br />
                    <strong>{fields.flight_number}</strong>
                </div>
                <div className="card-details-row">
                    Date of departure of country<br />
                    <strong>{fields.departure_day}/{fields.departure_month}/{fields.departure_year}</strong>
                    <p className="green-highlight">{test_available}</p>
                </div>                
                <div className="card-details-row">
                    Country travelling from<br />
                    <strong>{country}</strong>
                </div>
                <div>
                    Did you transit through any countries?<br />
                    <strong>{fields.transit_countries}</strong>
                </div>

            </div>
            </div>
        </div>

        {members.map((_, index) => {
            const firstNameField = `pax_${index + 1}_first_name`;
            const firstNameValue = fields[firstNameField] ? fields[firstNameField] : '';
            const lastNameField = `pax_${index + 1}_last_name`;
            const lastNameValue = fields[lastNameField] ? fields[lastNameField] : '';
            return (
                <div className="card-wrapper slide" key={index}>
                    <div className="card-container">
                    <div className="card-container__card-display">
                        <div>Details of person for test number 1</div>
                    </div>
                    <div className="card-container__card-info">
                        <div>
                            {firstNameValue} {lastNameValue}
                        </div>
                    </div>
                    </div>
                </div>
            );
        })}
    </>
    );
}
