import * as React from 'react';

const ShippingCountries = ({
                                      calculatePostageCosts,
                                      fields,
                                      onBlur,
                                      onChange,
                                      shippingCountries,
                                  }) => {

    const isValidList = shippingCountries.length > 0;

    return <div className="form__select-wrapper">
        <select
            name="delivery_country"
            id={fields.delivery_country}
            {
                ...(isValidList && {
                    value: fields.delivery_country,
                    onChange: (e) => {onChange('delivery_country', e.target.value); calculatePostageCosts(e.target.value); },
                    onBlur: () => onBlur('blur', 'delivery_country')
                })
            }
        >
            <option key="" value="" />
            {isValidList && shippingCountries.map(
                (
                    o,
                    key
                ) => (
                    <option
                        key={
                            key
                        }
                        value={
                            o.country_code
                        }
                    >
                        {
                            o.title
                        }
                    </option>
                )
            )}
        </select>
        <i className="icon-down-open" />
           </div>;
};

export default ShippingCountries;