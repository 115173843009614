import axios from 'axios';

export const saveAccessTokenAndStoreConfig = ({ accessToken, onSuccess }) => {
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  }

  axios.get('user/config').then(({ data }) => {
    localStorage.setItem('userMetadata', JSON.stringify(data));
    window.CONFIG = { ...window.CONFIG, ...data };
    onSuccess();
  });
};

const getAccessToken = ({ email, password, onSuccess, onError }) => {
  axios
    .post('auth/login_token', { email: email, password: password })
    .then(({ data }) => {
      saveAccessTokenAndStoreConfig({ accessToken: data.access_token, onSuccess });
    })
    .catch(error => {
      onError(error);
    });
};

export default getAccessToken;
