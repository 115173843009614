import { takeLatest, call, put, fork, all, select } from 'redux-saga/effects';
import update from 'immutability-helper';

import utils from '../../components/helpers/utils';

import * as actionTypes from './constants';
import * as api from './api';
import * as actions from './actions';
import * as selectors from './selectors';

function* fetchAspiration() {
    const aspiration = yield call(api.fetchAspiration);
    if(aspiration.errorResponse) {
        yield put(actions.aspirationError(aspiration.error));
    } else {
        let showAspirationForm = true;
        if(aspiration) {
            showAspirationForm = false;
        }

        yield put(actions.updateAspiration(aspiration));
        yield put(actions.toggleAspirationForm(showAspirationForm));
    }
}

function* watchFetchAspiration() {
    yield takeLatest(actionTypes.FETCH_ASPIRATION, fetchAspiration);
}

function* editAspiration(action) {
    yield put(actions.updateAspiration(action.value));
}

function* watchEditAspiration() {
    yield takeLatest(actionTypes.EDIT_ASPIRATION, editAspiration);
}

function* saveAspiration() {
    const aspiration = yield select(selectors.aspirationSelector);
    const aspirationSaved = yield call(api.saveAspiration, aspiration);

    if(aspirationSaved.errorResponse) {
        yield put(actions.aspirationError(aspirationSaved.error));
        yield put(actions.toggleAspirationForm(true));
    } else {
        yield put(actions.toggleAspirationForm(false));
    }
}

function* watchSaveAspiration() {
    yield takeLatest(actionTypes.SAVE_ASPIRATION, saveAspiration);
}

const updation = (listToUpdate, updatedParams, index) => {
    const set = {};
    for (const key of Object.keys(updatedParams)) {
        set[key] = {$set: updatedParams[key]};
    }

    return update(listToUpdate, {[index]: set});
};

function* fetchCoaches() {
    const coaches = yield call(api.fetchCoaches);
    if(coaches.errorResponse) {
        yield put(actions.coachesError(coaches.error));
    } else {
        const updatedCoaches = coaches.teamMembers && coaches.teamMembers.length && coaches.teamMembers.map((item) => {
            return {
                ...item,
                permissions: new Set([]),
            };
        });
        yield put(actions.updateCoachesData({...coaches, teamMembers: updatedCoaches}));
    }
}

function* watchFetchCoaches() {
    yield takeLatest(actionTypes.FETCH_COACHES, fetchCoaches);
}

function* toggleCoachKey(action) {
    const { coachId, key } = action;
    const coaches = yield select(selectors.coachesSelector);
    const index = coaches.teamMembers.findIndex((item) => item.id === coachId);
    const updatedParams = {[key]: !coaches.teamMembers[index][key]};
    const updatedCoaches = updation(coaches.teamMembers, updatedParams, index);
    yield put(actions.updateSingleCoach(updatedCoaches));
}

function* watchToggleCoachKey() {
    yield takeLatest(actionTypes.TOGGLE_COACH_KEY, toggleCoachKey);
}

function* addCoach(action) {
    const coaches = yield select(selectors.coachesSelector);
    const index = coaches.teamMembers.findIndex((item) => item.id === action.coachId);
    let coachAdd = {};
    const { id = '', permissions = {}, accepted_terms = false, has_selected = false } = coaches.teamMembers[index];
    if(index > -1) {
        if (has_selected) {
            coachAdd = yield call(api.updateCoach, id, permissions);
            utils.track('Updated Coach Permissions', {coach_id: id, permissions: permissions});
        } else {
            coachAdd = yield call(api.addCoach, id, permissions, accepted_terms);
            utils.track('Added Coach', {coach_id: id, permissions: permissions});
        }
    }

    if(coachAdd.errorResponse) {
        yield put(actions.coachesError(coachAdd.error));
    } else {
        const updatesParams = {
            showDataPreference: !coaches.teamMembers[index].showDataPreference,
            accepted_terms: true,
            has_selected: true
        };
        const updatedCoaches = updation(coaches.teamMembers, updatesParams, index);
        yield put(actions.updateSingleCoach(updatedCoaches));
    }
}

function* watchAddCoach() {
    yield takeLatest(actionTypes.ADD_COACH, addCoach);
}

function* updateCoach(coachId, params) {
    const coaches = yield select(selectors.coachesSelector);
    const index = coaches.teamMembers.findIndex((item) => item.id === coachId);
    const updatedCoach = updation(coaches.teamMembers, params, index);
    yield put(actions.updateSingleCoach(updatedCoach));
}

function* getCoachPermissions(action) {
    if(action.has_selected) {
        const permissions = yield call(api.getPermissions, action.coachId);

        if(permissions.errorResponse) {
            yield put(actions.coachesError(permissions.error));
        } else {
            yield* updateCoach(action.coachId, {permissions: new Set(permissions.permissions)});
        }
    }
}

function* watchGetCoachPermissions() {
    yield takeLatest(actionTypes.FETCH_COACH_PERMISSIONS, getCoachPermissions);
}

function* deleteCoach(action) {
    const coachDelete = yield call(api.deleteCoach, action.coachId);

    if(coachDelete.errorResponse) {
        yield put(actions.coachesError(coachDelete.error));
    } else {
        yield* updateCoach(action.coachId, {has_selected: false, showModal: false});
    }
}

function* watchDeleteCoach() {
    yield takeLatest(actionTypes.DELETE_COACH, deleteCoach);
}

function* updateCoachPermission(action) {
    yield* updateCoach(action.coachId, {permissions: new Set([action.permission])});
}

function* watchUpdateCoachPermission() {
    yield takeLatest(actionTypes.UPDATE_COACH_PERMISSION, updateCoachPermission);
}

function* fetchGoals() {
    const goals = yield call(api.fetchGoals);
    if(goals.errorResponse) {
        yield put(actions.goalsError(goals.error));
    } else {
        yield put(actions.updateGoals(goals));
    }
}

function* watchFetchGoals() {
    yield takeLatest(actionTypes.FETCH_GOAL, fetchGoals);
}

function* fetchGoalDetails(slug) {
    
    const goalDetailsLoading = true;

    yield put(actions.updateGoalDetailsLoading(goalDetailsLoading));

    const goalDetails = yield call(api.fetchGoalDetails, slug);
    if(goalDetails.errorResponse) {
        yield put(actions.goalDetailsError(goalDetails.error));
    } else {
        yield put(actions.updateGoalDetails(goalDetails));
    }
}

function* watchFetchGoalDetails() {
    yield takeLatest(actionTypes.FETCH_GOAL_DETAILS, fetchGoalDetails);
}

export default function* () {
    yield all([
        fork(watchFetchAspiration),
        fork(watchFetchCoaches),
        fork(watchEditAspiration),
        fork(watchSaveAspiration),
        fork(watchDeleteCoach),
        fork(watchToggleCoachKey),
        fork(watchGetCoachPermissions),
        fork(watchAddCoach),
        fork(watchUpdateCoachPermission),
        fork(watchFetchGoals),
        fork(watchFetchGoalDetails),
    ]);
}