import React from 'react';

import { SplitContext } from './splitProvider';

const SplitConsumer = ({name, attributes, children}) => (
    <SplitContext.Consumer>
        {({ client, isReady }) => isReady ? children (
            (typeof(name) == 'string' ? client.getTreatment(name, attributes) : client.getTreatments(name, attributes))) : null
        }
    </SplitContext.Consumer>
);

export default SplitConsumer;