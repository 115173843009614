import React from 'react';
import config from '../../config';
import { StyledHeaderNavLink } from '../../theming/themed-components';

const renderLink = (link) => {
  const { title, url, transparentBackground, hideOnTable, userConfigShowLink, exact, shouldDisplay = true } = link;

  if (userConfigShowLink && !config.app[userConfigShowLink]) {
    return null;
  }

  if (!shouldDisplay) {
    return null;
  }

  return (
    <li className={`item${hideOnTable ? ' desktop-only' : ''}`} key={title.replace(' ', '-')}>
      <StyledHeaderNavLink
        activeClassName={`active ${transparentBackground ? 'transparent' : ''}`}
        exact={exact}
        to={url}
      >
        {title}
      </StyledHeaderNavLink>
    </li>
  );
};

export const Menu = props => {
  const { links, children, theme } = props;

  return (
    <ul className="menu">
      {links?.map(link => renderLink(link, theme)).filter(Boolean)}
      {children}
    </ul>
  );
};
