import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'react-tippy';

const LineGraph = ({title, text, help, graphData, resultUnit}) => {
    const { range, x, you } = graphData;
    
    const tooltipHtml = help ? <Tooltip title={help} position="bottom"><i className="icon-info-circled help-icon form__help-icon"/></Tooltip> : '';

    const biologicalAgeBarWidth = (range.max_percent - range.min_percent);

    return (
        <div className="range__wrapper">
            {title && <div className="range__title">{title} {tooltipHtml}</div>}
            {text && <p className="range__description">{text}</p>}
            <div className="range__container">
                <div className="range__line-container">
                    <div className="line">
                        <div className="range__biological-age-line">
                            <div className="colored-line"  style={{width: `${biologicalAgeBarWidth}%`, left: `${range.min_percent}%`}} />
                        </div>
                        <div className="range__result-point-container" style={{left: `${you.percent}%`}}>
                            <Tooltip
                                arrow={true}
                                arrowSize="small"
                                open={true}
                                position="top"
                                theme="light"
                                title={`${you.label} ${resultUnit}`}
                            >
                                <div className="result-point" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="line-labels">
                        <span>{`${x.min_label} ${resultUnit}`}</span><span>{`${x.max_label} ${resultUnit}`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

LineGraph.propTypes = {
    title: PropTypes.string,
    graphData: PropTypes.object,
};

export default LineGraph;
