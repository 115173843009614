import React, { Component } from 'react';
import update from 'immutability-helper';
import axios from 'axios';
import {validated} from 'react-custom-validation';
import config from '../../config';
import validationRules from '../helpers/validationRules';
import Modal from 'react-responsive-modal';
import utils from '../helpers/utils';
import withErrorBoundary from './errorBoundary';

/*
    Show the terms overlay that you must aggree to before closing
    CLinic patients wont have agreeded to these
*/
class TermsOverlay extends Component {
    constructor(props) {
        super(props);

        const {user} = props;
        const { processing_tests } = config.app;

        this.state = {
            //user: user,
            fields: {
                accepted_dna_services: user ? user.accepted_dna_services : 0,
                accepted_privacy_terms: user && user.accepted_terms && user.accepted_privacy ? 1 : 0,
                accepted_clinic_sharing: 0,
            },
            formSubmited: false,
            disableSubmitButton: false,
            submitError: false,
            submitErrorMessage: '',
            //If no epi tests, skip the welcome screen (as its epi focused)
            showTerms: processing_tests.length > 0 ? false : true,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormValid = this.handleFormValid.bind(this);
        this.handleFormInvalid = this.handleFormInvalid.bind(this);
        this.showTermsClick = this.showTermsClick.bind(this);
    }

    handleInputChange(field, value) {
        this.setState(update(this.state, {fields: {[field]: {$set: value}}}));
    }

    showTermsClick() {
        this.setState({showTerms: true});
    }

    handleFormValid() {
        this.setState({submitError: false, submitErrorMessage: '', disableSubmitButton: true});
        this.setState({disableSubmitButton: true});

        //Call API
        axios.post('user', {
            accepted_dna_services: this.state.fields.accepted_dna_services,
            //Need to map these into the 2 individual fields
            accepted_privacy: this.state.fields.accepted_privacy_terms,
            accepted_terms: this.state.fields.accepted_privacy_terms,
            accepted_clinic_sharing: this.state.fields.accepted_clinic_sharing,
        })
        .then((response) => {
            if(response.data.status) {
                // Update user metadata because app is using that info to determine
                // if Terms modal should be presented or not. If user metadata is
                // not updated, user would end up with stale info which would cause
                // Terms modal to be shown infinitely until logging out and logging in.
                const userMetadata = JSON.parse(localStorage.getItem("userMetadata"));
                userMetadata.user = {
                    ...userMetadata.user,
                    accepted_terms: this.state.fields.accepted_privacy_terms,
                    accepted_privacy: this.state.fields.accepted_privacy_terms,
                    accepted_dna_services: this.state.fields.accepted_dna_services
                };
                localStorage.setItem("userMetadata", JSON.stringify(userMetadata));
                
                //redirect so that user is updated in global object
                window.location = "/dashboard";
            }
        })
        .catch((error) => {
            this.setState({
                disableSubmitButton: false,
                submitError: true,
                submitErrorMessage: utils.getFirstApiError(error),
            });
        });
    }

    handleFormInvalid() {
        this.setState({submitError: true});
    }

    render() {
        const {user, testUser} = this.props;
        const {fields, submitError, submitErrorMessage, formSubmited, disableSubmitButton, showTerms} = this.state;
        const { requires_clinic_sharing_permission, clinic_name } = config.app;


        return(
            <Modal open={true} onClose={function(){}} center showCloseIcon={false} classNames={{modal: 'study-joined-model'}}>
                {
                    showTerms ?
                        <div>
                            <h4>Please review the below</h4>

                            <TermsForm
                                user={user}
                                requiresClinicSharingPermission={requires_clinic_sharing_permission}
                                clinicName={clinic_name}
                                fields={fields}
                                onChange={this.handleInputChange}
                                onValid={this.handleFormValid}
                                onInvalid={this.handleFormInvalid}
                                submitError={submitError}
                                submitErrorMessage={submitErrorMessage}
                                disableSubmitButton={disableSubmitButton}
                            />
                        </div> : <WelcomeScreen showTermsClick={this.showTermsClick} testUser={testUser} />
                }
            </Modal>
        );
    }
}

export default withErrorBoundary(TermsOverlay);

function termsConfig(props) {
    const {accepted_dna_services, accepted_privacy_terms, accepted_clinic_sharing} = props.fields;
    const { requires_clinic_sharing_permission } = config.app;

    const rules = {
        fields: ['accepted_dna_services', 'accepted_privacy_terms', 'accepted_clinic_sharing'],
        validations: {
            accepted_dna_services: [
                [validationRules.isTermsChecked, accepted_dna_services, 'You must accept these conditions to allow us to process your sample.']
            ],
            accepted_privacy_terms: [
                [validationRules.isTermsChecked, accepted_privacy_terms, 'You must accept the terms & conditions and privacy policy.']
            ]
        },
    };

    if(requires_clinic_sharing_permission) {
        rules.validations['accepted_clinic_sharing'] = [
            [validationRules.isTermsChecked, accepted_clinic_sharing, 'You must accept the data sharing policy.']
        ];
    }

    return rules;
}

const WelcomeScreen = ({testUser, showTermsClick}) => (
    <section>
        <p className="welcome-title">Welcome to Hurdle {testUser ? testUser.fname : ''}</p>
        <strong>What will you do today?</strong>
        <p>Well, you’ll probably do the things you often do: walk down your street, go to work, eat lunch, workout, grab some sleep. These everyday behaviours are so familiar you might not have given them much thought.</p>
        <p>The truth is our bodies are made up of shifting molecules, all affected by what we do and what we’re exposed to. For the first time epigenetics reveals how our actions and environment influence our long-term health.</p>
        <p>Now you’ve embarked on the ground-breaking Hurdle journey, you’ll benefit from revolutionary scientific and technological advances to gain critical insights into your health at a DNA level.</p>
        <p>First we need a few bits of consent from you; but remember you are always in full control of your data. We’ll never sell it or share it without your permission.</p>
        <button className="btn" onClick={showTermsClick}>Get Started</button>
    </section>
);

class TermsForm extends Component {

    render() {
        const {user, requiresClinicSharingPermission, clinicName, fields, onChange, onValid, onInvalid, $field, $validation, submitError, 
                submitErrorMessage, disableSubmitButton} = this.props;

        return (
            <form
                className="form register-kit-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    this.props.$submit(onValid, onInvalid);
                }}
            >
                {user && !user.accepted_dna_services &&
                    <div className="form__row">
                        <input
                            type="checkbox"
                            name="accepted_dna_services"
                            id="dna_services"
                            value="1"
                            checked={fields.accepted_dna_services}
                            {...$field('accepted_dna_services', (e) => onChange('accepted_dna_services', e.target.checked))}
                        />
                        <label className="form__label form__label--checkbox form__label--terms" htmlFor="dna_services">
                            {(user.dna_service_type && user.dna_service_type=='covid-19') ?
                                (<span>I understand that to receive and use
                                your COVID-19 Services I will need to provide a saliva/NPS sample. I hereby consent to Hurdle processing my saliva/NPS sample
                                for the purposes of providing the COVID-19 Services (as set out in the <a href={config.app.web_url+'/terms-conditions'} target="_blank" rel="noreferrer">terms &amp; conditions</a>)
                                and as described in your <a href={config.app.web_url+'/legal/privacy-policy'} target="_blank" rel="noreferrer">privacy policy</a>.
                                You are free to withdraw your consent at any time but if you do so your access to, and
                                use of the, COVID-19 Services will cease.
                                 </span>)
                            :
                                (<span>I understand that to receive and use
                                your DNA/RNA Services I will need to give you a DNA/RNA sample. I hereby consent to Hurdle processing my DNA/RNA sample
                                for the purposes of providing the DNA/RNA Services (as set out in the <a href={config.app.web_url+'/terms-conditions'} target="_blank" rel="noreferrer">terms &amp; conditions</a>)
                                and as described in your <a href={config.app.web_url+'/legal/privacy-policy'} target="_blank" rel="noreferrer">privacy policy</a>.
                                You are free to withdraw your consent at any time but if you do so your access to, and
                                use of the, DNA/RNA Services will cease.
                                 </span>)
                            }
                        </label>

                        <div className="form__error">{$validation.accepted_dna_services.show ? $validation.accepted_dna_services.error.reason : ''}</div>
                    </div>
                }

                {user && (!user.accepted_terms || !user.accepted_privacy)  &&
                    <div>
                        <div className="form__row privacy-row">
                            <input
                                type="checkbox"
                                name="accepted_privacy_terms"
                                id="accepted_privacy_terms"
                                value="1"
                                checked={fields.accepted_privacy_terms}
                                {...$field('accepted_privacy_terms', (e) => onChange('accepted_privacy_terms', e.target.checked))}
                            />
                            <label className="form__label form__label--checkbox form__label--terms" htmlFor="accepted_privacy_terms">
                                <span>I agree to your <a href={config.app.web_url+'/terms-conditions'} target="_blank" rel="noreferrer">terms &amp; conditions</a> and <a href={config.app.web_url+'/legal/privacy-policy'} target="_blank" rel="noreferrer">privacy policy</a>.</span>
                            </label>
                            <div className="form__error">{$validation.accepted_privacy_terms.show ? $validation.accepted_privacy_terms.error.reason : ''}</div>
                        </div>
                    </div>
                }


                {requiresClinicSharingPermission &&
                    <div>
                        <div className="form__row privacy-row">
                            <input
                                type="checkbox"
                                name="accepted_clinic_sharing"
                                id="accepted_clinic_sharing"
                                value="1"
                                checked={fields.accepted_clinic_sharing}
                                {...$field('accepted_clinic_sharing', (e) => onChange('accepted_clinic_sharing', e.target.checked))}
                            />
                            <label className="form__label form__label--checkbox form__label--terms" htmlFor="accepted_clinic_sharing">
                                I consent to sharing my data with {clinicName} as outlined in the <a href={config.app.web_url+'/terms-conditions'} target="_blank" rel="noreferrer">terms &amp; conditions</a>.<br />You are free to withdraw your consent at any time.
                            </label>

                            <div className="form__error">{$validation.accepted_clinic_sharing.show ? $validation.accepted_clinic_sharing.error.reason : ''}</div>
                        </div>
                    </div>
                }

                <div className="form__row">
                    <button className="btn" disabled={disableSubmitButton}>Confirm<i className="icon-right-open"/></button>
                    {submitError &&
                        <div className="form__error form__error--general">{submitErrorMessage}</div>
                    }
                </div>
            </form>
        );
    }
}



TermsForm = validated(termsConfig)(TermsForm);
