import React, { Component } from 'react';
import utils from '../../../../helpers/utils';
import UnitWidgit from './unitWidgit';

/*
    Instead of doing live exchanre rates fix at some sensibel defualts
*/
const possibleOptions = {
    gbp: {majorName: '£ (GBP)',  metricOrImperial: 'metric', rate: 1},
    usd: {majorName: '$ (USD)',  metricOrImperial: 'imperial', rate: 1.3},
    eur: {majorName: '€ (EUR)',  metricOrImperial: 'other', rate: 1.1},
};

const defaultUnit = 'gbp';


export default class Currency extends Component {
    static convertToUnits(currentUnit, toUnits, major, minor) {
        if(major === '' && minor === '') {
            return false;
        }

        //Convert whatever we have to default first
        const currentDefault = Currency.convertToDefault(currentUnit, major, minor);
        
        let newMajor, newMinor = '';

        newMajor = Math.round(currentDefault * possibleOptions[toUnits].rate);
        
        return {major: newMajor, minor: newMinor};
    }

    //Default units is in meters
    static convertToDefault(currentUnit, major, minor) {
        if(major === '' && minor === '') {
            return false;
        }

        major = major ? Number(major.toString().replace(/[^0-9\.]+/g,'')) : 0;
        

        return Math.round(major / possibleOptions[currentUnit].rate);
    }

    static convertFromDefaultToMajorMinor(value) {
        let major = '';
        let minor = '';
        if(utils.isNumeric(value)) {
            major = Math.floor(value);
            minor = Math.round((value-major)*100);
        }

        return {major: major, minor: minor};
    }

    render() {
        return (
            <UnitWidgit
                {...this.props}
                fieldType="number"
                defaultUnit={defaultUnit}
                convertToUnits={Currency.convertToUnits}
                convertToDefault={Currency.convertToDefault}
                possibleOptions={possibleOptions}
                convertFromDefaultToMajorMinor={Currency.convertFromDefaultToMajorMinor}
            />
        );
    }
}