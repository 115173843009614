import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SubMenuBar from '../../../components/common/subMenuBar';

const navigation = [{ type: 'link', title: 'Health', link: 'you' }];

const Cards = ({friends, categories, previousTests, match, openChat}) => {

    const inviteFriendsAndFamily = () => {
        openChat('Thank you for your interest in comparing to friends and family, we haven’t built this feature yet but we will let you know if/when it becomes available');
    };

    const RenderFriendsSection = () => {
        /*
        if(!friends) {
            return null;
        }
        */

        return (
            <div className="compare__single-section">
                <h4 className="compare__single-section-title">{friends.title}</h4>
                <div className="compare-card__container" onClick={()=>inviteFriendsAndFamily(openChat)}>
                    <p className="compare-card__title">+ Add Family/Friend</p>
                    <i className="icon-right-open-big" />
                </div>
                {
                    (friends.data && friends.data.length) ? friends.data.map((friend, index) => (
                        <Link className="compare-card__container" key={index} to={`${match.url}/friends/${friend.id}`}>
                            <p className="compare-card__title">{friend.name}</p>
                            <i className="icon-right-open-big" />
                        </Link>
                    )) : ''
                }
            </div>
        );
    };

    const RenderCategoriesSection = () => {
        if(!categories) {
            return null;
        }

        return (
            <div className="compare__single-section">
                <h4 className="compare__single-section-title">{categories.title}</h4>
                {
                    (categories.data && categories.data.length) ? categories.data.map((category, index) => (
                        <Link className="compare-card__container" key={index} to={`${match.url}/categories/${category.slug}`}>
                            <p className="compare-card__title">{category.title}</p>
                            <i className="icon-right-open-big" />
                        </Link>
                    )) : ''
                }
            </div>
        );
    };

    const RenderPreviousTestsSection = () => {
        if(!previousTests) {
            return null;
        }

        return (
            <div className="compare__single-section">
                <h4 className="compare__single-section-title">{previousTests.title}</h4>
                {
                    (previousTests.data && previousTests.data.length) ? previousTests.data.map((test, index) => (
                            <Link className="compare-card__container" key={index} to={`${match.url}/previous-test/${test.id}`}>
                                <p className="compare-card__title">{moment(test.registered_at).format('DD MMM YYYY')}</p>
                                <i className="icon-right-open-big" />
                            </Link>
                        )) :
                        <div className="compare-card__container compare-card__container--no-results">
                            <p className="compare-card__title">Take a second test to compare your progress</p>
                        </div>
                }
            </div>
        );
    };

    return (
        <div>
            <SubMenuBar
                hideMenuBar={true}
                navigation={navigation}
                show={true}
            />
            <div className="compare__container">
                <div className="compare__hero-section">
                    <h2 className="title">Compare Epigenetics</h2>
                    <p className="description">
                        See how your epigenetics compare with other Hurdle users or your previous tests.
                    </p>
                </div>
                <div className="compare__sections">
                    {RenderCategoriesSection()}
                    {RenderPreviousTestsSection()}
                    {RenderFriendsSection()}
                </div>
            </div>
        </div>
    );
};

Cards.propTypes = {
    friends: PropTypes.object,
    categories: PropTypes.object,
    previousTests: PropTypes.object,
    match: PropTypes.object.isRequired
};

export default Cards;
