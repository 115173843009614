import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import PercentageBar from '../common/percentageBar';
import withErrorBoundary from '../common/errorBoundary';

class StudyIndividual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            joinedModalOpen: false,
        };

        this.handelJoinedModelOpen = this.handelJoinedModelOpen.bind(this);
        this.handelJoinedModelClose = this.handelJoinedModelClose.bind(this);
    }

    handelJoinedModelOpen(e) {
        this.setState({joinedModalOpen: true});
    }

    handelJoinedModelClose(e) {
        this.setState({joinedModalOpen: false});
    }


    render() {
        return (
            <div>
                <Modal open={this.state.joinedModalOpen} onClose={this.handelJoinedModelClose} center classNames={{modal: 'study-joined-model'}}>
                    <h4>Thanks for joining!</h4>
                    <p>Your input will help contribute towards science for the future.</p>
                    <p><Link to="/dashboard/stats" className="btn btn--inverse-pink">View Stats <i className="icon-right-open"/></Link></p>
                </Modal>

                <section className="dashboard-section content-wrapper-animate">
                    <section className="dashboard-hero study-individual-hero">
                        <div className="dashboard-hero__text-container">
                            <h1 className="dashboard-hero__title study-hero__title">Salivary Gland Cancer Study</h1>
                            <p className="dashboard-hero__subtitle">Get involved</p>
                        </div>

                    </section>

                    <section className="dashboard-section__container">
                        <div className="study-container">
                            <div className="study-container__credits-container">
                                {/*<Link to="/dashboard/studies">&lt; Back</Link>*/}
                                <div className="study-container__feature-text study-container__credits-earn">Earn</div>
                                <div className="price-box price-box--credits">40 Credits</div>
                            </div>

                            <div className="study-container__2cols">
                                <div className="study-container__col study-container__col--left layout-2col__box" style={{minHeight: '442px'}}>
                                    <h4 className="study-container__subtitle">How it works</h4>
                                    <p className="study-container__intro-text">To investigate the prevalence of p16INK4 a, p14ARF, 
                                    tumor protein p53 (TP53), and human telomerase reverse transcriptase (hTERT) promoter hypermethylation 
                                    in mucoepidermoid carcinomas (MECs) and search for a possible association between methylation status 
                                    and clinicopathological parameters.
                                    </p>
                                    <p>
                                        <a href="#" className="btn" onClick={this.handelJoinedModelOpen}>Join Now<i className="icon-right-open"/></a>
                                    </p>
                                </div>



                                <div className="study-container__col study-container__col--right">
                                    <div className="layout-2col__box">

                                        <div className="study-information-container">
                                            <div className="study-information-container--left study-container__feature-text">
                                                Joined <span className="study-information-container__number">123</span><br />
                                                Required <span className="study-information-container__number">200</span>

                                                <PercentageBar percentage="62" text="62% Full" className="study-information-container__percentage-bar"/>
                                            </div>
                                            <div className="study-information-container--right study-container__feature-text">
                                                Internal Study<br />
                                                Starts in 8 days
                                            </div>
                                        </div>



                                        <div className="box-divider"></div> 

                                        <div className="study-information-container">
                                            <div className="study-information-container--left">
                                                <div className="study-container__feature-text">
                                                    Whats required?
                                                </div>

                                                <ul className="study-container__whats-required-list">
                                                    <li>Personal data <br/><div className="time-icon" /> 0 MINS</li>
                                                    <li>Epigenetic Data</li>
                                                </ul>
                                            </div>

                                            <div className="study-information-container--right">
                                                <div className="study-container__feature-text">
                                                    Are you eligible?
                                                </div>

                                                <ul className="study-container__whats-required-list study-container__whats-required-list--eligible">
                                                    <li>Congratulations! You are fully eligible to help this scientific study take place.</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="study-container__button-container">
                                            <a href="#" className="btn btn--inverse-pink" onClick={this.handelJoinedModelOpen}>Join Now<i className="icon-right-open"/></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="study-more-information">
                            <div className="study-more-information__title">More Information</div>

                            <div className="study-container__2cols">
                                <div className="study-container__col study-container__col--left">
                                    <div className="study-more-information__box study-more-information__science-box">
                                        <h4 className="study-more-information__box-title">The science</h4>

                                        <div className="study-more-information__scroll-box">
                                        <p>Mucoepidermoid carcinoma (MEC) accounts for approximately 35% of salivary gland cancers, ranging from nonaggressive low-grade to aggressive high-grade tumors. Several malignant and benign tumors can histologically mimic MEC, such as Warthin tumor, adenoid cystic carcinoma, and squamous cell carcinoma, among others, leading to misdiagnosis. MEC pathogenesis studies have mainly been focused on the reciprocal chromosomal translocation t(11;19)(q21;p13), which gives rise to a fusion oncoprotein MECT1-MAML2. This fusion appears to be one of the drivers of MEC development through Notch signaling disruption. It also represents an important prognostic parameter; namely, it was found that patients with MEC harboring the translocation have a less aggressive form of tumor and a more favorable outcome. Recently, using whole exome sequencing, several new alterations have been found in MECs, such as tumor protein p53 (TP53) and POU6F2 mutations.
                                        Still, these genetic changes cannot explain all MEC cases.
                                        The latest molecular studies have broadened the knowledge
                                        on the importance of epigenetic changes associated
                                        with salivary gland cancer (SGC) development, in particular
                                        DNA methylation, as a mechanism of gene
                                        silencing. Kishi et al. found, for instance, that RB1 was
                                        hypermethylated in 42% of cases of SGC, and Nikolic
                                        et al. reported that approximately 70% of pleomorphic
                                        adenomas harbored hypermethylated p14 and p16.
                                        Using a microarray approach, Bell et al. also found
                                        several highly methylated genes in adenoid cystic carcinoma.
                                        Williams et al. found 48% of methylated
                                        RASSF1A in salivary duct cancers, whereas Zhang et al.
                                        correlated RASSF1A promoter hypermethylation with
                                        poor survival in patients with salivary adenoid cystic carcinoma
                                        in a Chinese population. 
                                        </p><p>In the same type of
                                        tumors, on the other hand, Tan et al. found that AQP1
                                        was hypomethylated in 75% of cases.
                                        Nonetheless, the number of published studies is still
                                        relatively limited and mostly focused on gene methylation
                                        in adenoid cystic carcinomas. Findings on DNA
                                        methylation status in mucoepidermoid carcinomas are extremely scarce, and that fact prompted us to explore
                                        the potential role of methylation in the pathogenesis of
                                        this type of tumor. The following genes known to be implicated
                                        in the etiology of different malignancies were
                                        selected for the present study: CDKN2A or INK4 a-ARF
                                        locus, which encodes 2 tumor suppressors (p16INK4 a and
                                        p14ARF), TP53, and human telomerase reverse transcriptase
                                        (hTERT). p16 is a potent cell cycle regulator,
                                        and when absent or nonfunctional, the progression of the
                                        cell cycle into the S phase is enabled. p14 is one of the
                                        key p53 regulators; thus methylated p14 would mean inactive
                                        p53 tumor suppressor. 
                                            </p><p>The TP53 gene, the most
                                        commonly mutated gene in human cancer, encodes the
                                        p53 suppressor, which promotes cell cycle arrest or apoptosis
                                        under replication stress; epigenetic modifications
                                        affect TP53 expression and p53 function. The hTERT
                                        gene encodes the protein subunit of the enzyme
                                        telomerase. Telomere maintenance by telomerase has a
                                        key role in carcinogenesis, and hTERT expression is
                                        known to be the limiting step in telomerase function.
                                        With the aim of assessing their respective contribution
                                        to MEC development, progression, and outcome, the
                                        methylation status of 2 gene promoters analyzed in the
                                        past (p14 and p16) and 2 gene promoters not examined
                                        before (TP53 and hTERT) has been determined.
                                                </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="study-container__col study-container__col--right">
                                    <div className="study-more-information__box layout-2col__box">
                                        <h4 className="study-more-information__box-title study-more-information__box-title">More Information</h4>
                                        <p>This study will include 200 samples, originating from 100 patients
                                            with mucoepidermoid carcinoma (surgically treated
                                            between 2000 and 2010 at the Clinic for Maxillofacial
                                            Surgery, School of Dental Medicine, University of Belgrade)
                                            and 100 Hurdle customer controls. The control specimens were obtained from Hurdle customers. Patients with MEC with distant metastases
                                            were excluded from the study. MECT1-MAML2
                                            translocation analysis on these cases was done in a previous
                                            study. Relevant clinicopathologic data are available. The research was conducted in full accordance
                                            with the World Medical Association Declaration
                                            of Helsinki (Version 2002).

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </section>
            </div>
        );
    }
}

export default withErrorBoundary(StudyIndividual);
