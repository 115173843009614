import React from 'react';
import PropTypes from 'prop-types';

const AnimatedMenuIcon = ({ isOpen }) => {
  return (
    <div className={isOpen ? 'menu-icon open' : 'menu-icon'}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

AnimatedMenuIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default AnimatedMenuIcon;
