import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

class SubMenuBar extends Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     lastScrollTop: 0,
        //     hideSubMenu: false
        // };

        // this.subMenuBarRef = React.createRef();

        // this.handleScroll = this.handleScroll.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    // componentDidMount() {
    //     window.addEventListener("scroll", this.handleScroll);
    // }
    //
    // componentWillUnmount() {
    //     window.removeEventListener("scroll", this.handleScroll);
    // }
    //
    // componentDidUpdate(prevProps) {
    //     if (this.props.location !== prevProps.location) {
    //         this.setState({ hideSubMenu: false });
    //     }
    // }

    goBack() {
        const { goBack, history } = this.props;
        if (goBack) {
            history.goBack();
        }
    }

    // handleScroll() {
    //     const { lastScrollTop } = this.state;
    //     const currentScrollTop = window.scrollY;
    //     const menuBarHeight = this.subMenuBarRef.current && this.subMenuBarRef.current.clientHeight;
    //
    //     if (lastScrollTop < currentScrollTop && currentScrollTop > menuBarHeight * 2.5) {
    //         this.setState({ hideSubMenu: true });
    //     } else if (lastScrollTop > currentScrollTop && currentScrollTop > menuBarHeight) {
    //         this.setState({ hideSubMenu: false });
    //     }
    //
    //     this.setState({ lastScrollTop: currentScrollTop });
    // }

    showPreviousTestBanner() {
        const { currentTestDate } = this.props;
        // const { hideSubMenu } = this.state;
        // const containerClasses = `sub-menu-bar__container previous-test-banner ${hideSubMenu ? (hideMenuBar ? " menu-scroll-up" : " menu-stick-top") : ""}`;
        return (
            <div className="sub-menu-bar__container previous-test-banner" ref={this.subMenuBarRef}>
                <div className="sub-menu-bar__titles">
                    <div className="sub-menu-bar__section">
                        <i className="icon-info-circled-alt" />
                        <p className="test-banner-title">Viewing a previous test</p>
                    </div>
                    <div className="sub-menu-bar__section">
                        {currentTestDate ? <p className="test-banner-title">{moment(currentTestDate).format('DD MMM YYYY')}</p> : ''}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { location, navigation, show, internalLinks, showPrevious, goBack, selectedTab } = this.props;
        // const { hideSubMenu } = this.state;

        if (!(show && navigation && navigation.length)) {
            return null;
        }

        if (showPrevious) {
            return this.showPreviousTestBanner();
        }

        const dashboardLink = location.pathname.split('/')[1];

        // const containerClasses = `sub-menu-bar__container ${hideSubMenu ? (hideMenuBar ? " menu-scroll-up" : " menu-stick-top") : ""}`;
        return (
            <div className="sub-menu-bar__container">
                <ul className="sub-menu-bar__titles">
                    <div className="sub-menu-bar__section">
                        {
                            navigation && navigation.map((item, index) => {
                                const link = `/${dashboardLink}/${item.link}`;
                                const isActive = index === (navigation.length - 1);
                                const isLocked = item.type === 'text';

                                if (item.title.toLowerCase() === 'back' && goBack) {
                                    return (
                                        <li className="sub-menu-bar__item" key={index}>
                                            <div className={`sub-menu-bar__item-link ${isLocked ? 'locked' : ''} ${isActive ? 'active' : ''}`} onClick={this.goBack}>
                                                {index === 0 && <i className="icon-left-open-big" />}
                                                {item.title}
                                            </div>
                                        </li>
                                    );
                                }

                                if (item.type === 'text') {
                                    return (
                                        <li className="sub-menu-bar__item" key={index}>
                                            <div className={`sub-menu-bar__item-link ${isLocked ? 'locked' : ''} ${isActive ? 'active' : ''}`}>
                                                {index === 0 && <i className="icon-left-open-big" />}
                                                {item.title}
                                            </div>
                                        </li>
                                    );
                                }

                                return (
                                    <li className="sub-menu-bar__item" key={index}>
                                        <Link
                                            className={`sub-menu-bar__item-link ${isLocked ? 'locked' : ''} ${isActive ? 'active' : ''}`}
                                            to={link}
                                        >
                                            {index === 0 && <i className="icon-left-open-big" />}
                                            <span>{item.title}</span>
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </div>
                    <div className="sub-menu-bar__section">
                        {
                            (internalLinks && internalLinks.length) ? internalLinks.map((item, index) => {
                                const link = `/${dashboardLink}/${item.link}`;
                                const isActive = location.pathname.includes(item.slug) || (selectedTab && selectedTab === item.slug);
                                return (
                                    <li className="sub-menu-bar__item internal-link" key={index}>

                                        <Link data-tut={item.data}
                                            className={`sub-menu-bar__internal-link ${isActive ? 'active' : ''}`}
                                            to={link}
                                        >
                                            {item.title}
                                        </Link>
                                    </li>
                                );
                            }) : ''
                        }
                    </div>
                </ul>
            </div>
        );
    }
}

SubMenuBar.defaultProps = {
    showPrevious: false
};

SubMenuBar.propTypes = {
    location: PropTypes.object.isRequired,
    navigation: PropTypes.array,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func,
    hideMenuBar: PropTypes.bool,
    internalLinks: PropTypes.array,
    showPrevious: PropTypes.bool
};

export default withRouter(SubMenuBar);
