import React from 'react';
import PropTypes from 'prop-types';

import FAQs from '../../../containers/you/common/faqs';
import HowWorks from '../../../containers/you/common/howWorksSteps';

import RelatedArticles from '../../../components/common/relatedArticles';
import ChromosomeChart from '../../../components/common/chromosomeChart';

const lines = {
    dnaLine:
        (<div className="chromosome-age__science__chromosome-information">
            <div className="chromosome-age__science__line-section">
                <div className="chromosome-title">DNA</div>
                <div className="chromosome-age__science__chromosome-line" />
            </div>
         </div>),
    ageingFasterLine:
        (<div className="chromosome-age__science__ageing-faster">
            <div className="chromosome-age__science__line-section single-element">
                <div className="chromosome-title">ageing faster</div>
                <div className="chromosome-age__science__chromosome-line" />
            </div>
         </div>),
    ageingSameLine:
        (<div className="chromosome-age__science__ageing-same">
            <div className="chromosome-age__science__line-section single-element">
                <div className="chromosome-title">ageing the same</div>
                <div className="chromosome-age__science__chromosome-line" />
            </div>
         </div>),
    ageingSlowerLine:
        (<div className="chromosome-age__science__ageing-slower">
            <div className="chromosome-age__science__line-section top single-element">
                <div className="chromosome-age__science__chromosome-line" />
                <div className="chromosome-title">ageing slower</div>
            </div>
         </div>)
};

const dnaChromosomes = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {addLine: lines['dnaLine']},
    {},
    {},
    {},
    {},
];

const ageing = [{key: '0'}, {key: '0'}, {key: '0'}, {key: '1', addLine: lines['ageingFasterLine']}, {key: '1'},
    {key: '0'}, {key: '0'}, {key: '0'}, {key: '0'}, {key: '-1'}, {key: '-1', addLine: lines['ageingSlowerLine'], position: 'bottom'},
    {key: '-1'}, {key: '-1'}, {key: '-1'}, {key: '0'}, {key: '0', addLine: lines['ageingSameLine']}, {key: '0'},
    {key: '0'}, {key: '0'}, {key: '0'}];

const steps = [
    {
        title: 'Collect sample and details from you',
        text: 'You provide us with your saliva from which we assess epigenetic marks at thousands of sites in the genome to determine methylation levels, along with extra information such as age, sex, height, weight.',
        image: '/img/you/actionable-you/epi-indicator/scientific-steps/1.jpg'
    },
    {
        title: 'Calculate biological age across your genome',
        text: 'We then split your genome up into equal sized DNA chunks. We use machine learning approaches to calculate Biological Age for each of these chunks of DNA from the epigenetic information found within your saliva sample. We calculate biological ages for X DNA chunks across your 23 pairs of chromosomes.',
        image: '/img/you/actionable-you/epi-indicator/scientific-steps/6.png'
    },
    {
        title: 'Compare to overall chronological age',
        text: 'To determine whether specific chunks or regions of your DNA look younger or older than your overall genome we have to compare them with your overall biological age calculated from thousands of sites throughout your genome.',
        image: '/img/you/actionable-you/epi-indicator/scientific-steps/7.png'
    },
    {
        title: 'Visualise chromosome ageing',
        text: 'To view how your DNA is ageing across your genome, we visualise your ageing rates in a summarised view and detailed chromosome view. Your biological age changes with time and so your scores are likely to change upon your next test.',
        image: '/img/you/actionable-you/epi-indicator/scientific-steps/3.jpg'
    }
];

const DownArrow = () => {
    return (
        <svg viewBox="0 0 15 45" className="down-arrow">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-713.000000, -1159.000000)" fill="#979797" stroke="#979797">
                    <g transform="translate(720.500000, 1181.500000) rotate(90.000000) translate(-720.500000, -1181.500000) translate(698.000000, 1175.000000)">
                        <path d="M36.5,7.5 L36.5,5.5 L0.5,5.5 L0.5,7.5 L36.5,7.5 Z" id="Rectangle" />
                        <path d="M38.9472136,0.894427191 L44.6381966,12.2763932 C44.7616912,12.5233825 44.6615791,12.823719 44.4145898,12.9472136 C44.3451621,12.9819275 44.2686056,13 44.190983,13 L32.809017,13 C32.5328746,13 32.309017,12.7761424 32.309017,12.5 C32.309017,12.4223775 32.3270895,12.3458209 32.3618034,12.2763932 L38.0527864,0.894427191 C38.176281,0.647437942 38.4766175,0.547325769 38.7236068,0.670820393 C38.8203705,0.719202244 38.8988317,0.79766349 38.9472136,0.894427191 Z" id="Triangle" transform="translate(38.500000, 6.500000) rotate(90.000000) translate(-38.500000, -6.500000) " />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const RenderBar = (section, statusMapping) => section.map((item, index) => {
    const style = {backgroundColor: statusMapping[item.key] || '#ffffff', border: `2px solid ${statusMapping[item.key] || '#A3A3A3'}`};
    return (
        <div key={index} className="chromosome-age__science__part-section">
            {(item.position !== 'bottom' && item.addLine) ? item.addLine : ''}
            <div style={style} className="chromosome-chart__part" />
            {(item.position === 'bottom' && item.addLine) ? item.addLine : ''}
        </div>
    );
});

const RenderDnaSection = (statusMapping) =>(
    <div className="chromosome-age__science__dna-section first-section">
        <div className="chromosome-age__science__bar-container">
            {RenderBar(dnaChromosomes, statusMapping)}
            <div className="chromosome-age__science__chromosome">
                <div className="chromosome-age__science__line-section top">
                    <div className="chromosome-age__science__chromosome-line" />
                    <div>chromosome</div>
                </div>
            </div>
        </div>
        <p className="section-description">Your DNA is split out into continuous stretches called chromosomes. Humans have 23 pairs of chromosomes (one from your mum and one from your dad). Each of these chromosomes can be broken down further into chunks of DNA shown here.</p>
    </div>
);

const RenderEpigeneticMarkersSection = () => (
    <div className="chromosome-age__science__dna-section">
        <div className="chromosome-age__science__bar-container">
            <div className="chromosome-age__science__oval-bars">
                <div className="chromosome-age__science__oval-bar large">
                    <div className="bar-code">C</div>
                </div>
                <div className="chromosome-age__science__oval-bar large">
                    <div className="bar-code">A</div>
                </div>
                <div className="chromosome-age__science__oval-bar large">
                    <div className="chromosome-age__science__chromosome-part">
                        <div className="chromosome-age__science__line-section single-element">
                            <div className="chromosome-title">epigenetic mark</div>
                            <div className="chromosome-age__science__chromosome-line" />
                        </div>
                    </div>
                    <div className="coloured-section" style={{height: '31px'}} />
                    <div className="bar-code">C</div>
                </div>
                <div className="chromosome-age__science__oval-bar large">
                    <div className="bar-code">G</div>
                </div>
                <div className="chromosome-age__science__oval-bar large">
                    <div className="coloured-section" style={{height: '16px'}} />
                    <div className="bar-code">C</div>
                </div>
            </div>
        </div>
        <p className="section-description">How your DNA functions is controlled by epigenetics, or epigenetic marks. One very important epigenetic mark is called DNA methylation. As the name suggests this mark is directly placed on your DNA and is responsible for controlling which genes are expressed and which genes aren't.</p>
    </div>
);

const RenderEpigeneticChangesSection = () => (
    <div className="chromosome-age__science__dna-section">
        <div className="chromosome-age__science__bar-container">
            <div className="chromosome-age__science__oval-bars">
                <div className="chromosome-age__science__oval-bar large">
                    <div className="bar-code">C</div>
                </div>
                <div className="chromosome-age__science__oval-bar large">
                    <div className="bar-code">A</div>
                </div>
                <div className="chromosome-age__science__changes-bars">
                    <div className="chromosome-age__science__chromosome-part">
                        <div className="chromosome-age__science__line-section">
                            <div className="chromosome-title">epigenetic changes</div>
                            <div className="chromosome-age__science__chromosome-line" />
                        </div>
                    </div>
                    <div className="chromosome-age__science__oval-bar large">
                        <div className="coloured-section" style={{height: '22px'}} />
                        <div className="bar-code">C</div>
                    </div>
                    <div className="chromosome-age__science__oval-bar large">
                        <div className="bar-code">G</div>
                    </div>
                    <div className="chromosome-age__science__oval-bar large">
                        <div className="coloured-section" style={{height: '38px'}} />
                        <div className="bar-code">C</div>
                    </div>
                </div>
            </div>
        </div>
        <p className="section-description">
            As we age, the location of these DNA methylation marks changes and this impacts how our DNA is controlled. The changing DNA methylation marks can be measured and combined with weighting to predict overall biological age.
        </p>
    </div>
);

const RenderBiologicalAgeSection = () => (
    <div className="chromosome-age__science__dna-section">
        <div className="chromosome-age__science__combination">
            <div className="chromosome-part">
                <div className="chromosome-age__science__chromosome-part">
                    <div className="chromosome-age__science__line-section single-element">
                        <div className="chromosome-title">DNA chunk</div>
                        <div className="chromosome-age__science__chromosome-line" />
                    </div>
                </div>
            </div>
            <span>=</span>
            <div className="chromosome-age__science__oval-bars">
                <div className="chromosome-age__science__chromosome-part">
                    <div className="chromosome-age__science__line-section">
                        <div className="chromosome-title">DNA letters</div>
                        <div className="chromosome-age__science__chromosome-line" />
                    </div>
                </div>
                <div className="chromosome-age__science__oval-bar">
                    <div className="bar-code">C</div>
                </div>
                <div className="chromosome-age__science__oval-bar">
                    <div className="bar-code">A</div>
                </div>
                <div className="chromosome-age__science__oval-bar">
                    <div className="bar-code">C</div>
                </div>
                <div className="chromosome-age__science__oval-bar">
                    <div className="bar-code">G</div>
                </div>
                <div className="chromosome-age__science__oval-bar">
                    <div className="bar-code">C</div>
                </div>
            </div>
        </div>
        <p className="section-description">Each chunk is made up of multiple pieces of DNA</p>
    </div>
);

const RenderAgingSection = (statusMapping) => (
    <div className="chromosome-age__science__dna-section">
        <div className="chromosome-age__science__bar-container">
            {RenderBar(ageing, statusMapping)}
        </div>
        <p className="section-description">Measuring biological age at these chunks in your DNA highlights something very interesting, that the DNA in your genome ages at differing rates, as shown below.</p>
    </div>
);

const RenderChromosomeGraphSection = ({chromosomeGraph, statusMapping}) => {

    if(!(chromosomeGraph && chromosomeGraph.data && chromosomeGraph.data.length)) {
        return null;
    }

    const { data } = chromosomeGraph;

    return (
        <div className="chromosome-age__science__section">
            <h4 className="section-title">
                Predicting Biological age for specific DNA chunks
            </h4>
            {RenderAgingSection(statusMapping)}
            {DownArrow()}
            <p className="section-description">Here is what the full genome looks like - showing slower ageing epigenetic markers:</p>
            <div className="chromosome-age__overview__graph-section">
                <ChromosomeChart data={data} activeValue={[-1]} statusMapping={statusMapping} />
            </div>
        </div>
    );
};

const RenderRelatedArticlesSection = (relatedArticles) => {
    if(!(relatedArticles && relatedArticles.length)) {
        return null;
    }

    return (
        <div className="chromosome-age__science__section">
            <div className="chromosome-age__science__related-articles">
                <RelatedArticles className="chromosome-age__science__related-articles" relatedArticles={relatedArticles} />
            </div>
        </div>
    );
};

const Science = (props) => {
    const { breakpoint, relatedArticles, statusMapping, chromosomeGraph } = props;

    return (
        <div>
            <section className="result-hero chromosome-age__hero-section">
                <div className="result-hero__information-section">
                    <h1 className="result-hero__title study-hero__title">How your DNA ages</h1>
                    <p className="result-hero__title-information">The epigenetic changes that occur in your genome with age are not localised to a small set of epigenetic marks on your DNA, but rather occur through out all regions of your DNA.</p>
                </div>
            </section>

            <div className="chromosome-age__overview__container science-container">
                <div className="chromosome-age__science__section">
                    <h4 className="section-title">Your DNA and epigenetic marks</h4>
                    <div className="chromosome-age__science__bars">
                        {RenderDnaSection(statusMapping)}
                        {DownArrow()}
                        {RenderBiologicalAgeSection()}
                        {DownArrow()}
                        {RenderEpigeneticMarkersSection()}
                        {DownArrow()}
                        {RenderEpigeneticChangesSection()}
                    </div>
                </div>
                {RenderChromosomeGraphSection({chromosomeGraph, statusMapping})}
                <div className="chromosome-age__science__section">
                    <h4 className="section-title">We measure your DNA ageing with the following process:</h4>
                    <div className="chromosome-age__science__steps">
                        <HowWorks steps={steps} showNumbers={true} breakpoint={breakpoint} />
                    </div>
                </div>
                {RenderRelatedArticlesSection(relatedArticles)}
            </div>
            <FAQs slug="chromosome-ageing" />
        </div>
    );
};

Science.propTypes = {
    breakpoint: PropTypes.string,
    relatedArticles: PropTypes.array,
    statusMapping: PropTypes.object,
    chromosomeGraph: PropTypes.object
};

export default Science;
