import React from "react";

const message = "If you're placing an order whilst outside of the UK, " + 
                "shipping cost will be calculated based on your current location. " +
                "Please continue to the next step where these charges will be " +
                "adjusted according to the delivery address you enter.";

const ShippingWarning = () => {
    return  <div className="shipping-warning" >
                <img src="/img/shipping_warning_icon.svg" className="shipping-warning__icon" alt="Warning" />
                <div className="shipping-warning__text">{ message }</div>
            </div>;
};

export default ShippingWarning;