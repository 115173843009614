import React, { Component } from 'react';
import PropTypes from 'prop-types';

const chromosomeBars = {
    chr1: {height: '100%', bottom: '50.2306%', key: 1},
    chr2: {height: '97.5722%', bottom: '38.5075%', key: 2},
    chr3: {height: '79.4471%', bottom: '45.9039%', key: 3},
    chr4: {height: '76.6916%', bottom: '26.3923%', key: 4},
    chr5: {height: '72.5837%', bottom: '26.7529%', key: 5},
    chr6: {height: '68.6518%', bottom: '35.6485%', key: 6},
    chr7: {height: '63.8468%', bottom: '37.6087%', key: 7},
    chr8: {height: '58.7216%', bottom: '31.1552%', key: 8},
    chr9: {height: '56.6552%', bottom: '34.6992%', key: 9},
    chr10: {height: '54.3769%', bottom: '29.6234%', key: 10},
    chr11: {height: '54.165%', bottom: '39.7388%', key: 11},
    chr12: {height: '53.7017%', bottom: '26.7086%', key: 12},
    chr13: {height: '46.2065%', bottom: '15.5423%', key: 13},
    chr14: {height: '43.0689%', bottom: '16.395%', key: 14},
    chr15: {height: '41.1359%', bottom: '17.7994%', key: 15},
    chr16: {height: '36.2506%', bottom: '40.507%', key: 16},
    chr17: {height: '32.5757%', bottom: '29.5584%', key: 17},
    chr18: {height: '31.3248%', bottom: '22.0295%', key: 18},
    chr19: {height: '23.7227%', bottom: '44.8173%', key: 19},
    chr20: {height: '25.286%', bottom: '43.6331%', key: 20},
    chr21: {height: '19.3098%', bottom: '26.1792%', key: 21},
    chr22: {height: '20.5835%', bottom: '29.3346%', key: 22},
    chrX: {height: '62.295%', bottom: '38.9964%', key: 'X'},
    chrY: {height: '23.8208%', bottom: '21.0531%', key: 'Y'}
};

class ChromosomeSection extends Component {

    getChromosomeData() {
        const { data } = this.props;
        const chromosomeData = {};
        Object.keys(chromosomeBars).map((key) => {
            chromosomeData[key] = {};
            chromosomeData[key].barDimensions = chromosomeBars[key];
            if(data[key]) {
                chromosomeData[key].chromosomeSection = data[key];
            }
        });

        return chromosomeData;
    }

    render() {
        const chromosomeGraphData = this.getChromosomeData();
        return (
            <div className="chromosome-section__container">
                {
                    Object.keys(chromosomeGraphData).map((key) => {
                        const position = chromosomeGraphData[key].barDimensions.bottom;
                        const barHeight = `${parseFloat(chromosomeGraphData[key].barDimensions.height) * 260 / 100}px`;
                        return (
                            <div className="chromosome-section__section-container" key={key}>
                                <div className="chromosome-section__section">
                                    <div className="chromosome-section__bar-container">
                                        {
                                            chromosomeGraphData[key].chromosomeSection && chromosomeGraphData[key].chromosomeSection.map((section, index) => (
                                                <div
                                                    key={index}
                                                    className="chromosome-section__oval-shape"
                                                    style={{bottom: `calc(${section.bottom}% - 12px)`}}
                                                />
                                            ))
                                        }
                                        <div className="chromosome-section__bar" style={{height: barHeight}}>
                                            <div className="chromosome-section__notch left" style={{bottom: position}} />
                                            <div className="chromosome-section__bar-content" />
                                            {
                                                chromosomeGraphData[key].chromosomeSection && chromosomeGraphData[key].chromosomeSection.map((section, index) => (
                                                    <div
                                                        key={index}
                                                        className="chromosome-section__pink-section"
                                                        style={{height: `${section.height}%`, bottom: `${section.bottom}%`}}
                                                    />
                                                ))
                                            }
                                            <div className="chromosome-section__notch right" style={{bottom: position}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

ChromosomeSection.propTypes = {
    data: PropTypes.object
};

export default ChromosomeSection;
