import React, { Component } from 'react';
import SliderBar, { createSliderWithTooltip } from 'rc-slider';

import 'rc-slider/assets/index.css';

import utils from '../../../../helpers/utils';

const SliderWithTooltip = createSliderWithTooltip(SliderBar);

const measurementData = {
    'show_waist_info': {
        title: 'How to take your waist measurement:',
        image: '/img/questionnaire/waist_measurement.png',
        description: [
            'Remove outer clothes (e.g jackets).',
            'Find the mid point between the lower rib bone and the hip bone.',
            'Measure the whole way around, starting and ending at the same point.'
        ]
    },
    'show_hip_info': {
        title: 'How to take your hip measurement:',
        image: '/img/questionnaire/hip-measurement.png',
        description: [
            'Remove outer clothes (e.g jackets).',
            'Find the widest point around your buttocks.',
            'Measure the whole way around your hips, starting and ending at the same point.'
        ]
    },
};

class UnitSlider extends Component {
    constructor(props) {
        super(props);
        //console.log(props)
        const {value, options, defaultUnit, convertToUnits, possibleOptions} = props;

        let selectedUnits = {};
        let selectedUnit = defaultUnit;

        if(options.show_units) {
            for(const x in options.show_units) {
                selectedUnits[options.show_units[x]] = possibleOptions[options.show_units[x]];
            }
        } else {
            selectedUnits = possibleOptions;
        }

        for(const i in selectedUnits) {
            if(options.metricOrImperial === selectedUnits[i].metricOrImperial) {
                selectedUnit = i;
                break;
            }
        }

        let major = value;

        if(selectedUnit && selectedUnit !== defaultUnit) {
            major = convertToUnits(defaultUnit, selectedUnit, major);
        }

        const minValue = convertToUnits(defaultUnit, selectedUnit, options.min);
        const maxValue = convertToUnits(defaultUnit, selectedUnit, options.max);

        this.state = {
            major: major || 0,
            selectedUnit: selectedUnit,
            allUnits: selectedUnits,
            minValue: minValue || options.min,
            maxValue: maxValue || options.max,
            sliderMoved: false
        };

        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handelOnChangeUnits = this.handelOnChangeUnits.bind(this);
    }

    componentDidUpdate() {
        const { major, selectedUnit, allUnits } = this.state;

        if(allUnits[selectedUnit].metricOrImperial !== this.props.options.metricOrImperial && major === '') {
            for(const i in allUnits) {
                if(this.props.options.metricOrImperial === allUnits[i].metricOrImperial) {
                    this.setState({selectedUnit: i});
                    break;
                }
            }
        }
    }

    getSliderOptions(min, max, unit) {
        const { allUnits, selectedUnit } = this.state;
        const { minorConversionFactor, showCombined, minorName } = allUnits[selectedUnit];

        const minValue = `${min} ${unit}`;
        let maxValue = `${max} ${unit}`;

        if(showCombined) {
            const majorValue = Math.floor(max);
            const minorValue = Math.round((max - majorValue) * minorConversionFactor);
            maxValue =  `${majorValue} ${unit} ${minorValue > 0 ? `${minorValue} ${minorName}` : ''}`;
        }

        const marks = utils.defaultSliderMarks(min, max, {min: minValue, max: maxValue});
        marks[min].style = {
            fontSize: '16px',
            color: '#A5A5A5',
            lineHeight: '16px',
        };

        marks[max].style = {
            fontSize: '16px',
            color: '#A5A5A5',
            lineHeight: '16px',
            width: '50%',
        };

        return marks;
    }

    tipFormatter(value, unit) {
        const { sliderMoved, allUnits, selectedUnit } = this.state;
        const { defaultValue } = this.props;
        const { minorConversionFactor, showCombined, minorName } = allUnits[selectedUnit];

        let showValue = `${value} ${unit}`;

        if(showCombined) {
            const majorValue = Math.floor(value);
            const minorValue = Math.round((value - majorValue) * minorConversionFactor);
            showValue = `${majorValue > 0 ? `${majorValue} ${unit}` : ''} ${minorValue > 0 ? `${minorValue} ${minorName}` : ''} ${!majorValue && !minorValue ? `0 ${unit}` : ''}`;
        }

        return (
            <div>{!sliderMoved && value === defaultValue ? 'Move me' : showValue}</div>
        );
    }

    handleSliderChange(section, value) {
        const { id, onChange, convertToDefault } = this.props;
        const {allUnits} = this.state;

        this.setState({[section]: value, sliderMoved: true}, () => {
            onChange(id, convertToDefault(this.state.selectedUnit, this.state.major),
                {metricOrImperial: allUnits[this.state.selectedUnit].metricOrImperial});
        });
    }

    handelOnChangeUnits(e) {
        const {id, onChange, convertToUnits, convertToDefault, options, defaultUnit} = this.props;
        const {allUnits, selectedUnit, major} = this.state;

        const newMeasurements = convertToUnits(selectedUnit, e.target.value, major);
        const minValue = convertToUnits(defaultUnit, e.target.value, options.min);
        const maxValue = convertToUnits(defaultUnit, e.target.value, options.max);

        const newState = {selectedUnit: e.target.value, minValue: minValue, maxValue: maxValue};
        if(newMeasurements) {
            newState.major = newMeasurements;
        }

        this.setState(newState, () => {
            onChange(id, convertToDefault(this.state.selectedUnit, this.state.major),
                {metricOrImperial: allUnits[this.state.selectedUnit].metricOrImperial});
        });
    }

    renderSlider(section, state) {
        const { allUnits, selectedUnit, minValue, maxValue, sliderMoved } = this.state;
        const { marks, hideMarks, disabled, step, defaultValue } = this.props;

        if(!allUnits[selectedUnit][section]) {
            return null;
        }

        const sliderStep = allUnits[selectedUnit].step ? allUnits[selectedUnit].step : step ? step : 1;

        return (
            <div className="custom__slider-container">
                <SliderWithTooltip
                    defaultValue={utils.isNumeric(defaultValue) ? Number(defaultValue) : parseFloat(defaultValue)}
                    disabled={disabled}
                    handleStyle={[{backgroundColor: `${!sliderMoved && this.state[state] === defaultValue ? '#444444' : '#5B7BFF'}`}]}
                    marks={marks || (hideMarks ? {} : this.getSliderOptions(minValue, maxValue, allUnits[selectedUnit][section]))}
                    max={maxValue}
                    min={minValue}
                    onAfterChange={(value) => this.handleSliderChange(state, value)}
                    onChange={(value) => this.handleSliderChange(state, value)}
                    step={sliderStep}
                    tipFormatter={(value) => this.tipFormatter(value, allUnits[selectedUnit][section])}
                    tipProps={{visible: true, prefixCls: `slider-tooltip ${(this.state[state] === defaultValue) ? '' : 'active'} rc-slider-tooltip`}}
                    value={utils.isNumeric(this.state[state]) ? Number(this.state[state]) : parseFloat(this.state[state])}
                />
            </div>
        );
    }

    renderSliderSection() {
        const { selectedUnit } = this.state;

        if(!selectedUnit) {
            return null;
        }

        return (
            <div className="custom__slider-wrapper">
                {this.renderSlider('majorName', 'major')}
            </div>
        );
    }

    renderMeasurementSection() {
        const { showMeasurement, measurementKey } = this.props;
        const data = measurementData[measurementKey];

        if(!showMeasurement && !(data && Object.entries(data).length)) {
            return null;
        }

        return (
            <div className="questionnaire-length__measurement-section">
                <h6 className="measurement-section-title">{data.title}</h6>
                <div className="image-container"><img src={data.image} alt={measurementKey} /></div>
                <div className="description-container">
                    {
                        data.description.map((item, index) => (
                            <p className="description-item" key={index}>{index + 1}. {item}</p>
                        ))
                    }
                </div>
            </div>
        );
    }

    render() {
        const { allUnits, selectedUnit } = this.state;
        const { id } = this.props;

        const labelOptions = [];
        for(const unit in allUnits) {
            labelOptions.push(
                <span key={unit} className={`custom__slider-radio ${selectedUnit === unit ? 'active' : ''}`}>
                    <input
                        checked={selectedUnit === unit}
                        id={id+'_units_'+unit}
                        name={id+'_units'}
                        onChange={this.handelOnChangeUnits}
                        type="radio"
                        value={unit}
                    />
                    <label
                        className="questionnaire-length__unit"
                        htmlFor={id+'_units_'+unit}
                    >
                        {allUnits[unit].majorName}
                    </label>
                </span>
            );
        }

        return (
            <div>
                {
                    labelOptions.length > 1 ?
                        <div className="questionnaire-length__units">
                            <div className="questionnaire-length__units-container slider-unit">
                                {labelOptions}
                            </div>
                        </div> : ''
                }
                {this.renderSliderSection()}
                {this.renderMeasurementSection()}
            </div>
        );
    }
}

export default UnitSlider;