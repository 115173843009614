import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { EditAccountInfoIcon } from './EditAccountInfoIcon';
import { withTheme } from 'styled-components';
import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

const AccountOverview = ({ history, subscription, testUser, user: { email, mobile_country, mobile }, location: { state }, theme }) => {
  const { fname = '', lname = '' } = testUser || {};
  const product = (subscription && subscription.product) || '';

  const displayMobile = mobile.replace(`+${mobile_country}`, '');

  return (
    <div>
      <div className="layout-2col__box">
        <div className="account-overview">
          <div className="account-overview__edit" onClick={() => history.push('/dashboard/account/profile')}>
            <EditAccountInfoIcon color={theme.section.cta_colour} />
          </div>
          {(fname || lname) && (
            <div className="account-overview__row">
              <span className="cell">Name</span>
              <span className="cell">
                {fname} {lname}
              </span>
            </div>
          )}
          <div className="account-overview__row">
            <span className="cell">E-mail</span>
            <span className="cell">{email}</span>
          </div>
          <div className="account-overview__row">
            <span className="cell">Mobile</span>
            <span className="cell">
              +({mobile_country}) {displayMobile}
            </span>
          </div>
          {!cachedPartnerTheming() && (
            <div className="account-overview__row">
              <span className="cell">Subscription</span>
              <span className="cell" dangerouslySetInnerHTML={{ __html: product }}></span>
            </div>
          )}
          {subscription && (
            <div>
              <div className="account-overview__row">
                <span className="cell">Next test due</span>
                <span className="cell">
                  {subscription.next_test_due_at ? moment(subscription.next_test_due_at).format('Do MMMM YYYY') : ''}
                </span>
              </div>
              <div className="account-overview__row">
                <span className="cell">Shipping address</span>
                <span className="cell">
                  {subscription.delivery_fname} {subscription.delivery_lname}
                </span>
              </div>
              {subscription.delivery_address1 && (
                <div className="account-overview__row">
                  <span className="cell">&nbsp;</span>
                  <span className="cell">{subscription.delivery_address1}</span>
                </div>
              )}
              {subscription.delivery_address2 && (
                <div className="account-overview__row">
                  <span className="cell">&nbsp;</span>
                  <span className="cell">{subscription.delivery_address2}</span>
                </div>
              )}
              {subscription.delivery_city && (
                <div className="account-overview__row">
                  <span className="cell">&nbsp;</span>
                  <span className="cell">{subscription.delivery_city}</span>
                </div>
              )}
              {subscription.delivery_postcode && (
                <div className="account-overview__row">
                  <span className="cell">&nbsp;</span>
                  <span className="cell">{subscription.delivery_postcode}</span>
                </div>
              )}
              {subscription.delivery_country && (
                <div className="account-overview__row">
                  <span className="cell">&nbsp;</span>
                  <span className="cell">{subscription.delivery_country}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {state && state.successMessage && <p className="account-form__success">{state.successMessage}</p>}
    </div>
  );
};

AccountOverview.defaultProps = {
  user: { email: '', mobile_country: '', mobile: '' },
  subscription: {},
  testUser: {},
};
export default withRouter(withTheme(AccountOverview));
