import React, { Component } from 'react';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Datamap from '../../lib/DataMap';
import RelatedArticles from '../../common/relatedArticles';
import Loader from '../../common/loader';
import AutoSuggest from '../../common/autoSuggest';
import ChromosomeSection from '../../common/chromosomeSection';
import HelpBox from '../../common/helpBox';

const searchQueryMinimumLength = 2;

const healthOpportunityData = {
    title: 'Understand your health opportunities.',
    description: 'Curious to see how your DNA is changing or what factors you are in control of that can improve your health today and health outcomes in the future? You will find this information here.',
    className: 'health-opportunity-section',
    linkText: 'Coming soon',
};

const feelSectionData = {
    title: 'Feel like talking to someone?',
    description: 'If you are worried about any information that you read or if you just want someone to explain it better, you can talk to your health team!',
    buttonText: 'talk to health team',
    link: '/dashboard/improvements/team',
};

const heatMapOptions = {
    // countries don't listed in dataset will be painted with this color
    fills: { defaultFill: '#E9E9E9' },
    data: null,
    geographyConfig: {
        borderColor: 'white',
        highlightBorderWidth: 1,
        // don't change color on mouse hover
        highlightFillColor: (geo) => geo['fillColor'] || '#E9E9E9',
        // only change border
        highlightBorderColor: 'white',
        // show desired information in tooltip
        popupTemplate: function(geo, data) {
            // don't show tooltip if country isn't present in dataset
            if (!data) { return ; }
            // tooltip content
            return ['<div className="hoverinfo">',
                '<span>', geo.properties.name, '</span>',
                ' ', data.numberOfThings, '%',
                '</div>'].join('');
        }
    }
};

class Search extends Component {
    constructor(props) {
        super();

        let searchQuery = '';
        const geneticData = {};
        if(props.location && props.location.search) {
            const searchedQuery = qs.parse(props.location.search);
            const {q, from, size} = searchedQuery;
            searchQuery = q;

            if(from && size) {
                geneticData.from = from;
                geneticData.size = size;
            }
        }

        this.state = {
            searchQuery,
            isLoading: searchQuery ? true : false,
            relatedArticles: null,
            relatedArticlesLoading: searchQuery ? true : false,
            chromosomeGraphData: null,
            chromosomeData: null,
            worldMapData: null,
            geneticData,
            nextResult: false,
            variantData: false,
            regionStrand: null
        };

        this.changeSearchQuery = this.changeSearchQuery.bind(this);
        this.submitQuery = this.submitQuery.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.nextSearchQuery = this.nextSearchQuery.bind(this);
    }

    componentDidMount() {
        const { geneticData } = this.state;
        if(this.state.searchQuery) {
            let query = qs.stringify({q: this.state.searchQuery});
            if(geneticData && geneticData.from && geneticData.size) {
                query = qs.stringify({q: this.state.searchQuery, from: geneticData.from, size: geneticData.size});
            }
            this.fetchResults(`?${query}`);
            this.fetchArticles();
        }
    }

    changeSearchQuery(event) {
        const { history } = this.props;
        this.setState({searchQuery: event.target.value}, () => {
            history.push({
                pathname: '/dashboard/you/search',
                search: qs.stringify({q: this.state.searchQuery})
            });
        });
    }

    nextSearchQuery(nextQuery) {
        const { history } = this.props;
        const newQuery = qs.parse(nextQuery);
        history.push({
            pathname: '/dashboard/you/search',
            search: qs.stringify({q: newQuery.q, from: newQuery.from, size: newQuery.size})
        });

        this.fetchResults(nextQuery, true);
    }

    onSelectItem(suggestion) {
        const { history } = this.props;
        this.setState({ searchQuery: suggestion.title }, () => {
            history.push({
                pathname: '/dashboard/you/search',
                search: qs.stringify({q: this.state.searchQuery})
            });
            this.fetchResults(`?${qs.stringify({q: this.state.searchQuery})}`);
            this.fetchArticles();
        });
    }

    fetchResults(query, isNextResults) {
        if(isNextResults) {
            this.setState({ nextResult: true });
        } else {
            this.setState({ isLoading: true });
        }

        if(this.state.searchQuery.length >= searchQueryMinimumLength) {
            axios.get(`test_user/search${query}`)
                .then((response) => {
                    const { data = {} } = response;
                    this.setState({
                        isLoading: false,
                        data: data,
                        chromosomeGraphData: data.chromosome_graph,
                        chromosomeData: data.data,
                        worldMapData: data.world_map,
                        geneticData: {
                            title: data.title,
                            introText: data.intro_text,
                            subSectionTitle: data.sub_section_title,
                            subSectionText: data.sub_section_text,
                            queryType: data.query_type,
                            variantsTotal: data.genetic_variants_total,
                            nextResult: data.next_result,
                            pageFrom: data.page_from,
                            pageSize: data.page_size,
                        },
                        nextResult: false
                    });
                }).catch((err) => {
                this.setState(() => { throw err; });
            });

            const strandQuery = qs.parse(query);

            //DISABLE STRAND FOR NOW AS ITS SLOWWWW
            /*
            if(strandQuery.q.startsWith("rs")) {
                let variantQuery = qs.stringify({rs_id: strandQuery.q, size: 700});
                axios.get(`test_user/search/strand?${variantQuery}`)
                    .then((response) => {
                    let newRegionStrand = "";
                        if(response && response.data && response.data.region_strand) {
                            newRegionStrand = response.data.region_strand;
                            const index = Math.floor(newRegionStrand.length/2);
                            let frontRegion = newRegionStrand.substring(0, index);
                            let lastRegion = newRegionStrand.substring(index+1, newRegionStrand.length);
                            //newRegionStrand = frontRegion +
                            //    `<div class="highlight-region"><div class="oval-shape"></div>${newRegionStrand.charAt(index+1)}</div>` + lastRegion;
                            
                        }
                        this.setState({ variantData: response.data, regionStrand: newRegionStrand });
                    });
            }
            */
        }
    }

    fetchArticles() {
        const { searchQuery } = this.state;
        if(this.state.searchQuery.length >= searchQueryMinimumLength) {
            axios.get(`test_user/search/articles?${qs.stringify({q: searchQuery})}`)
                .then((response) => {
                    this.setState({ relatedArticlesLoading: false, relatedArticles: response.data });
                }).catch((err) => {
                //this.setState(() => { throw err; });
            });
        }
    }

    submitQuery(event) {
        event.preventDefault();
        const query = qs.stringify({q: this.state.searchQuery});
        this.props.history.push({
            pathname: '/dashboard/you/search',
            search: query
        });
        this.fetchResults(`?${query}`);
        this.fetchArticles();
    }

    renderHeaderSection() {
        const { searchQuery } = this.state;
        return (
            <div className="search-page__header-section">
                <div>
                    <h1 className="title">What does my DNA know about</h1>
                    <form className="input-container" onSubmit={this.submitQuery}>
                        <AutoSuggest
                            fetchUrl="test_user/search/autocomplete?q="
                            onChange={this.changeSearchQuery}
                            onSelectItem={this.onSelectItem}
                            searchQueryMinimumLength={searchQueryMinimumLength}
                            value={searchQuery}
                        />
                        <button type="submit">
                            <img src="/img/you/search-blue.png" alt="dna-report" />
                        </button>
                        <span>?</span>
                    </form>
                </div>
            </div>
        );
    }

    renderInformationSection() {
        const { geneticData, chromosomeGraphData, chromosomeData, variantData, regionStrand } = this.state;

        if(!chromosomeGraphData || !chromosomeGraphData.data) {
            return null;
        }

        return (
            <div className="search-page__section-container">
                {
                    (geneticData || chromosomeGraphData || chromosomeData) ?
                        <div className="search-page__science-section">
                            {
                                geneticData ?
                                    <div>
                                        <div className="search-page__information-section">
                                            <h4 className="search-section-title">{geneticData.subSectionTitle}</h4>
                                            <p className="search-section-information">{geneticData.subSectionText}</p>
                                        </div>
                                        <div className="other-result">
                                            <p>{/*Zoomed in 10,000x*/}</p>
                                            {geneticData.nextResult !== '' ? <p><span onClick={() => this.nextSearchQuery(geneticData.nextResult)}><i className="icon-reload" />Show next position</span></p> : ''}
                                        </div>
                                    </div> : ''
                            }
                            {
                                (chromosomeGraphData || variantData) ?
                                    <div className="image-section">
                                        {
                                            geneticData.queryType == 'variant' ?
                                                <div>
                                                    { variantData &&
                                                        <div className="variant-section">
                                                            <div className="single-region">
                                                                <span dangerouslySetInnerHTML={{__html: regionStrand}} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                : <div>{chromosomeGraphData && <ChromosomeSection data={chromosomeGraphData.data} /> }</div>
                                        }
                                        {chromosomeData && <div className="show-circle">{chromosomeData.genotype}</div>}
                                    </div> : ''
                            }
                            <div className="specifications-section">
                                {this.renderGenomeSection()}
                                {this.renderGeneSection()}
                                {this.renderPublicationSection()}
                            </div>
                        </div> : ''
                }
            </div>
        );
    }

    renderGenomeSection() {
        const { chromosomeData } = this.state;

        if(!chromosomeData) {
            return null;
        }

        return (
            <div className="genome-section">
                <div className="container">
                    <span className="question">Variant name</span>
                    <span className="answer">{chromosomeData.genetic_variant.rs_id}</span>
                </div>
                <div className="container">
                    <span className="question">Genotype</span>
                    <span className="answer">{chromosomeData.genotype}</span>
                </div>
                <div className="container">
                    <span className="question">Chromosome</span>
                    <span className="answer">{chromosomeData.chromosome_hg38}</span>
                </div>
                <div className="container">
                    <span className="question">Position</span>
                    <span className="answer">{chromosomeData.coordinate_hg38}</span>
                </div>
                <div className="container">
                    <span className="question">Genome</span>
                    <span className="answer">GRCH38</span>
                </div>
            </div>
        );
    }

    renderGeneSection() {
        const { chromosomeData } = this.state;

        if(!chromosomeData) {
            return null;
        }

        const {genetic_variant} = chromosomeData;

        return (
            <div className="gene-section">
                {
                    (genetic_variant && genetic_variant.genes && genetic_variant.genes.length) ?
                    <div className="container">
                        {
                            genetic_variant.genes.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="specification-section-title">Gene <span onClick={() => this.onSelectItem({title: item.name})}>{item.name}</span> info</div>
                                        <div className="gene-description">
                                            {item.summary}
                                        </div>
                                        {item.ncbi_id &&
                                            <a href={'https://www.ncbi.nlm.nih.gov/gene/'+item.ncbi_id} target="_blank" rel="noreferrer">Read more +</a>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div> : ''
                }
                {
                    (genetic_variant && genetic_variant.phenotypes && genetic_variant.phenotypes.length) ?
                    <div className="container">
                        <div className="specification-section-title">Associated diseases</div>
                        {
                            genetic_variant.phenotypes.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="gene-description diseases" onClick={() => this.onSelectItem({title: item.name})}>{item.name}</div>
                                    </div>
                                );
                            })
                        }
                    </div> : ''
                }
            </div>
        );
    }

    renderPublicationSection() {
        const { chromosomeData } = this.state;

        if(!chromosomeData || !chromosomeData.genetic_variant || !chromosomeData.genetic_variant.scientific_papers || !chromosomeData.genetic_variant.scientific_papers.length) {
            return null;
        }

        const {genetic_variant} = chromosomeData;

        return (
            <div className="publications-section">
                <div className="specification-section-title">Specific publications</div>
                {
                    genetic_variant.scientific_papers.map((item, index) => {
                        return (
                            <a href={item.link} key={index} className="publication-name" target="_blank" rel="noreferrer">{item.title}<i className="icon-right-arrow" /></a>
                        );
                    })
                }
            </div>
        );
    }

    getHeatmapOptions() {
        const { worldMapData: {data} } = this.state;
        const dataset = {};
        const onlyValues = data.map((item) => item[1]);
        const minValue = Math.min.apply(null, onlyValues),
            maxValue = Math.max.apply(null, onlyValues);

        // let paletteScale = d3.scale.linear()
        //     .domain([minValue,maxValue])
        //     .range(["#f5f9fc", "#b5d0ee"]);
        //
        // data.map((item) => {
        //     let iso = item[0], value = item[1];
        //     dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
        // });

        const options = heatMapOptions;
        options.data = dataset;

        return options;
    }

    renderHeatmapSection() {
        const { worldMapData } = this.state;
        if(!worldMapData || !worldMapData.data) {
            return null;
        }

        const heatmapOptions = this.getHeatmapOptions();

        return (
            <div className="search-page__section-container">
                <div className="search-page__information-section">
                    <h4 className="search-section-title">{worldMapData.title}</h4>
                    <p className="search-section-information">{worldMapData.text}</p>
                </div>
                <div className="search-page__graph-section">
                    <Datamap {...heatmapOptions}/>
                </div>
            </div>
        );
    }

    renderRelatedBlogs() {
        const { relatedArticles } = this.state;
        if(!relatedArticles || !relatedArticles.web_articles || !relatedArticles.web_articles.length) {
            return null;
        }

        const { web_articles } = relatedArticles;

        return (
            <div className="search-page__section-container">
                <RelatedArticles className="search-page" relatedArticles={web_articles} title="Related blogs" />
            </div>
        );
    }

    renderRelatedArticles() {
        const { relatedArticles } = this.state;
        if(!relatedArticles || !relatedArticles.scientific_articles || !relatedArticles.scientific_articles.length) {
            return null;
        }

        const { scientific_articles } = relatedArticles;

        return (
            <div className="search-page__related-articles-container">
                <div className="search-page__information-section">
                    <h3 className="search-section-title">Related articles</h3>
                </div>
                <div className="related-article-section">
                    {
                        scientific_articles.map((item, itemIndex) => {
                            return (
                                <div key={itemIndex} className="related-articles">
                                    <Link to={item.link} target="_blank" className="article">
                                        {item.title}
                                        <i className="icon-right-arrow" />
                                    </Link>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    renderSearchSection() {
        if(this.state.isLoading || this.state.relatedArticlesLoading) {
            return <Loader />;
        }

        const { geneticData, nextResult } = this.state;

        if(!geneticData) {
            return null;
        }

        return (
            <div className="search-page__container">
                <div className="search-page__top-section">
                    <h2 className="title">{geneticData.title}</h2>
                    <p className="information" dangerouslySetInnerHTML={{__html: geneticData.introText}} />
                </div>

                {geneticData.variantsTotal > 0 &&
                    <div className="search-page__section-container">
                        <HelpBox {...healthOpportunityData} />
                    </div>
                }
                
                <div className="search-page__information-container">
                    {/*nextResult && <Loader />*/}
                    <div className={`search-page__box-container ${nextResult ? 'nextResult' : ''}`}>
                        {this.renderInformationSection()}
                    </div>
                </div>

                
                {this.renderHeatmapSection()}

                {//geneticData.variantsTotal > 0 &&
                    <div className="search-page__section-container">
                        <HelpBox {...feelSectionData} />
                    </div>
                }
                {this.renderRelatedBlogs()}
                {this.renderRelatedArticles()}
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderHeaderSection()}
                {this.renderSearchSection()}
            </div>
        );
    }
}

export default Search;

