import PropTypes from 'prop-types';
import { formatInterpretation } from '../utils';

/**
 * Component renders details for CYCLE interpretation rule.
 * Rule structure is as follows:
 *
 * {
 *   rule: 'CYCLE',
 *   value: [
 *     {
 *       cyclePhase: 'FOLLICULAR',
 *       rule: 'RANGE',
 *       value: {
 *         LOW: ['<2.4'],
 *         NORMAL: ['>=2.4', '<=12.6'],
 *         HIGH: ['>12.6'],
 *       },
 *     },
 *     {
 *       cyclePhase: 'OVULATION',
 *       rule: 'RANGE',
 *       value: {
 *         LOW: ['<14'],
 *         NORMAL: ['>=14', '<=95.6'],
 *         HIGH: ['>95.6'],
 *       },
 *     },
 *     {
 *       cyclePhase: 'LUTEAL',
 *       rule: 'RANGE',
 *       value: {
 *         LOW: ['<1'],
 *         NORMAL: ['>=1', '<=11.4'],
 *         HIGH: ['>11.4'],
 *       },
 *     },
 *     {
 *       cyclePhase: 'POSTMENOPAUSE',
 *       rule: 'RANGE',
 *       value: {
 *         LOW: ['<7.7'],
 *         NORMAL: ['>=7.7', '<=58.5'],
 *         HIGH: ['>58.5'],
 *       },
 *     },
 *   ],
 * }
 *
 * @param props contains interpretationData which holds interpretation
 * rule selected based on the users GENDER, AGE etc and biomarker unit.
 * @returns rendered component
 */
const CycleDetails = ({ interpretationData, unit }) => {
  return (
    <>
      {interpretationData.value.map((rule, index) => {
        return (
          <div key={rule.cyclePhase} className="biomarker-modal__text">
            {index > 0 && <br />}
            <div key={rule.cyclePhase}>{rule.cyclePhase.toUpperCase()}</div>
            {Object.entries(rule.value).map(([interpretation, range]) => {
              const [min, max] = range;
              return (
                <div key={interpretation}>
                  {formatInterpretation(interpretation)}: {min}
                  {max && ' - '}
                  {max} {unit}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

CycleDetails.propTypes = {
  interpretationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  unit: PropTypes.string,
};

export default CycleDetails;
