import React  from 'react';
import { ShoppingTrolley, Parcel, Van } from '../../../icons';

export const ORDER_STATUS = {
    Placed: 'Estimated dispatch',
    Dispatched: 'In transit',
    Recieved: 'Delivered',
};

const cancelDispatchedMessage = () => (
    <div>Are you sure you want to cancel your testing package? If you do, you’ll need to <b>return the tests we’ve
        already sent out to you
                                                                                         </b> before we can process your refund (which will be paid to the same card you used to
        purchase).{'\n'}{'\n'}
        <b>DO NOT send tests to the lab in the returns envelope.{'\n'}This is the address to return any unused tests by post
            ONLY (in-person returns can’t be accepted for staff safety reasons):
        </b>{'\n'}{'\n'}
        <b>Chronomics{'\n'}
            Unit 26A BizSpace Wimbledon{'\n'}
            8 Lombard Road{'\n'}
            London{'\n'}
            SW19 3TZ{'\n'}
        </b>{'\n'}
        As soon as we’ve received them, we’ll refund you within 5-10 days.
    </div>
);

const cancelDispatchedWithInfo = (openInfo) => (
  <>If you do, you’ll need to dispose of the kit we’ve already sent to you <a onClick={openInfo}>in line with our guidance for doing so responsibly</a>.{'\n'}{'\n'}If you cancel now, you’ll receive your refund within 10 working days (and your kit ID number can no longer be registered/used).</>
);

const downgradeDay8 = () => (
    <div>If you cancel, <a href="https://help.chronomics.com/downgrades-tui-customer" target="_blank" rel="noreferrer">a small change fee</a> will be deducted from your kit price refund because it’s close to your departure date.</div>
);

const downgradeDay8Late = () => (
    <div>Unfortunately your trip is too close for us to cancel<br/> this for you. <a href="https://help.chronomics.com/order-timing" target="_blank" rel="noreferrer">Find out more here.</a></div>
);

export const modalMessages = {
    cancel_flight_not_cancelled : 'Sorry, it looks like your TUI holiday hasn’t been cancelled so you’re not eligible for a refund.\n' +
        '\n' +
        'If your holiday has been cancelled within the last 24 hours and you believe you’re due a refund, please check again tomorrow once our systems have updated.\n',
    cancel_dispatched : cancelDispatchedMessage(),
    refund_14_days_grace: 'If you cancel, you’ll receive a full refund within 10 working days.',
    cancel_allowed : 'Are you sure you want to cancel your testing package? If you do, you won’t receive any tests and we’ll process a refund in the next 5-10 days to the same card you paid with.',
    cancel_requested_refund: 'Order already cancelled.',
    downgrade_day_8_allowed: 'If you cancel, you will receive a refund of the full kit price in 5-10 days.',
    downgrade_day_8_late: downgradeDay8Late(),
    downgrade_day_8_allowed_fee: downgradeDay8(),
    refund_14_days_grace_return: (openInfo) => cancelDispatchedWithInfo(openInfo),
};

export const modalTitles = {
    refund_14_days_grace: 'Are you sure you want to cancel this test?',
    refund_14_days_grace_return: 'Are you sure you want to cancel this test?',
    downgrade_day_8_late: 'Sorry, it’s too late to cancel this test.'
};

export const modalButtons = {
  refund_14_days_grace: ['Yes', 'No'],
  refund_14_days_grace_return: ['Yes', 'No'],
};

export function orderToProgressSteps(order) {
    return [
        {
            name: ORDER_STATUS.Placed,
            icon: ShoppingTrolley,
            dateTime: order.process_at
        },
        {
            name: ORDER_STATUS.Dispatched,
            icon: Van,
            dateTime: order.dispatched_at
        },
        {
            name: ORDER_STATUS.Recieved,
            icon: Parcel,
            dateTime: order.delivered_at
        }
    ];
}

export function latestOrderStatus(orderProgressSteps) {
    let index = orderProgressSteps.length;
    while (index-- && !orderProgressSteps[index].dateTime);
    return orderProgressSteps[index];
}
