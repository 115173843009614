import React from 'react';
import { StyledSpanWrapper } from '../../theming/themed-components';

const PercentageBar = ({ percentage, className, text }) => {
  let textPotion = percentage;
  if (percentage > 97) textPotion = 93;

  const barStyles = { width: percentage + '%' };
  const textStyles = { left: textPotion + '%' };

  return (
    <div className={'progress-bar ' + className}>
      <StyledSpanWrapper className="progress-bar__bar" style={barStyles}></StyledSpanWrapper>
      {text ? (
        <span className="progress-bar__text" style={textStyles}>
          {text}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default PercentageBar;
