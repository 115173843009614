import React from 'react';

const FormSection = props => {
    const {
        labelAddon,
        placeholder,
        preFixClass,
        fields,
        label,
        id,
        required,
        $field,
        onChange,
        type,
        name,
        $validation,
        inputAddon = null
    } = props;

    return (
        <div className={`${preFixClass}__row`}>
            <label
                className="form__label cell"
                htmlFor={id}
            >
                {label} {required && <span className="form__required">*</span>}
                {labelAddon}
            </label>

            <div className="cell">
                <div className="form__select-wrapper">
                    <input type={type} value={fields[name]} name={name} id={id}
                           {...$field(name, (e) => onChange(name, e.target.value))}
                           placeholder={placeholder}
                    />
                    {inputAddon}
                </div>
                <div className="form__error">{$validation && $validation[name].show ? $validation[name].error.reason : ''}</div>
            </div>
        </div>
    );
};

FormSection.defaultProps = {
    labelAddon: null,
    placeholder: '',
    preFixClass: '',
    fields: {},
    label: '',
    id: '',
    required: false,
    onChange: () => {},
    type: 'text',
    name: '',
    inputAddon: null,
};

export default FormSection;
