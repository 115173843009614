import React from 'react';
import Datamaps from 'datamaps';

export default class Datamap extends React.Component {

    constructor(props) {
        super(props);
        //window.addEventListener('resize', this.resize);

        this.clear = this.clear.bind(this);
        this.drawMap = this.drawMap.bind(this);
    }

    /*
    resize = () => {
        if (this.map) {
            this.map.resize();
        }
    }
    */
    componentDidMount() {
        this.drawMap();
    }

    shouldComponentUpdate(nextProps) {
        // avoid component from update if previous props and current props are same
        return JSON.stringify(this.props) !== JSON.stringify(nextProps);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) === JSON.stringify(prevProps)) {
            return;
        }
        this.clear();
        this.drawMap();
    }

    componentWillUnmount() {
        this.clear();
        window.removeEventListener('resize', this.resize);
    }

    clear() {
        const container = this.refs.container;

        for (const child of Array.from(container.childNodes)) {
            container.removeChild(child);
        }
    }

    drawMap() {
        var map = new Datamaps(Object.assign({}, {
            ...this.props
        }, {
            element: this.refs.container,
            projection: 'mercator',
            responsive: true
        }));

        if (this.props.arc) {
            map.arc(this.props.arc, this.props.arcOptions);
        }

        if (this.props.bubbles) {
            map.bubbles(this.props.bubbles, this.props.bubbleOptions);
        }

        if (this.props.graticule) {
            map.graticule();
        }

        if (this.props.labels) {
            map.labels();
        }

        this.map = map;
    }

    render() {
        const style = {
            position: 'relative',
            width: '80%',
            height: '60%'
        };

        return <div ref="container" style={style}></div>;
    }

}
