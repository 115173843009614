import * as actionTypes from './constants';

export const getSiteBrandingRequest = params => {
  return {
    type: actionTypes.FETCH_SITE_BRANDING_REQUEST,
    params,
  };
};

export const getSiteBrandingSuccess = params => {
  return {
    type: actionTypes.FETCH_SITE_BRANDING_SUCESS,
    params,
  };
};

export const getSiteBrandingFailure = params => {
  return {
    type: actionTypes.FETCH_SITE_BRANDING_FAILURE,
    params,
  };
};

export const getPartnerBrandingRequest = params => {
  return {
    type: actionTypes.FETCH_PARTNER_BRANDING_REQUEST,
    params,
  };
};

export const getPartnerBrandingSuccess = params => {
  return {
    type: actionTypes.FETCH_PARTNER_BRANDING_SUCCESS,
    params,
  };
};

export const getPartnerBrandingFailure = params => {
  return {
    type: actionTypes.FETCH_PARTNER_BRANDING_FAILURE,
    params,
  };
};
