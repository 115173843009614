import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class RestoreScroll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isScrolling: '',
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        sessionStorage.setItem(this.props.location.pathname, window.scrollY);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.handleScroll();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(RestoreScroll);
