import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import withErrorBoundary from '../../../components/common/errorBoundary';
import Header from './header';
import BiomarkerResult from './biomarkerResult';
import BackButton from '../../../components/common/MaterialUi/backButton';

const EpigeneticsResult = ({ fname, currentTest, currentProduct }) => {
  const { registeredAt, processedAt, specimens = [], biomarkerResults = [], metadata } = currentTest || {};
  const { title: productName } = currentProduct || 'Test';
  const reportDate = processedAt || (specimens.length ? specimens[0].processedAt : '');
  const collectionDate = registeredAt || (specimens.length ? specimens[0].registeredAt : '');

  return (
    <div>
      <section className="orders-section__container">
        <BackButton/>
        <Header productName={productName} collectionDate={collectionDate} reportDate={reportDate} />
        <>
          <Helmet>
            <title>{`${productName} Result - Hurdle`}</title>
          </Helmet>
          {/* Epigenetics Test Results have only one Biomarker Result inside, therefore just access it directly. */}
          <BiomarkerResult fname={fname} biomarkerResult={biomarkerResults[0]} collectionDate={collectionDate} testMetadata={metadata} />
        </>
      </section>
    </div>
  );
};

EpigeneticsResult.propTypes = {
  fname: PropTypes.string.isRequired,
  currentTest: PropTypes.shape({
    registeredAt: PropTypes.string,
    processedAt: PropTypes.string,
    specimens: PropTypes.arrayOf(PropTypes.object),
    biomarkerResults: PropTypes.arrayOf(PropTypes.object),
    metadata: PropTypes.object,
  }).isRequired,
  currentProduct: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default withErrorBoundary(EpigeneticsResult);
