import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import config from '../../../config';
import HelpBox from '../../../components/common/helpBox';
import Loader from '../../../components/common/loader';
import withError from '../../../components/common/withError';
import withErrorBoundary from '../../../components/common/errorBoundary';

import Card from './coaches-card';

import { getCoaches, deleteCoach, toggleCoachKey, addCoach, getCoachPermissions, updatePermissions } from '../../../services/improvements/actions';
import { coachesSelector, improvementsLoadingSelector } from '../../../services/improvements/selectors';

class MyTeam extends Component {
    componentDidMount() {
        this.props.fetchCoaches();
    }

    getCoaches() {
        const { coaches: { teamMembers, locked, coachPermissions = {} }, deleteCoach, toggleCoachKey,
            addCoach, fetchCoachPermissions, updatePermissions,openChat } = this.props;
        if (Array.isArray(teamMembers)) {
            return teamMembers.map((member, index) => <Card
                coachPermissions={coachPermissions}
                key={`coach-${index}`}
                {...member}
                locked={locked}
                coachCount={teamMembers.length}
                deleteCoach={deleteCoach}
                toggleCoachKey={toggleCoachKey}
                addCoach={addCoach}
                getCoachPermissions={fetchCoachPermissions}
                updatePermissions={updatePermissions}
                openChat={openChat}
                                                      /> );
        }
    }

    inviteCoaches(openChat) {
        openChat('Leave their name, number and/or email and we will get in touch about joining the Hurdle platform');
    }


    render() {
        const { isLoading, helpSection, openChat } = this.props;
        const testUser = config.app.test_user;

        if(isLoading) {
            return <Loader />;
        }

        return (
            <div className="improvement-container__coaches-container">
                <h6 className="improvement-container__title">My Team</h6>
                <p className="improvement-container__description">Our health specialists are available to provide
                    personalised support to help you meet your health goals.
                </p>
                <div className="improvement-container__coaches-container__cards">
                    {this.getCoaches()}
                </div>

                {(testUser && !testUser.clinic_id) &&
                <p>Your introductory call with any health specialist will be free of charge to allow you to introduce
                    yourself and understand whether they are a good fit.
                    <br/>Subsequent sessions will be charged at an exclusive rate of £30 for 30 minutes via phone or
                    webchat.
                </p>
                }

                <div className="improvement-container__invite-section" onClick={()=>this.inviteCoaches(openChat)}>
                    <div className="icon-container"><i className="icon-share"/></div>
                    <p>Invite your doctor/coach to share your data</p>
                </div>
                <div className="improvement-container__help-section">
                    <HelpBox {...helpSection} />
                </div>
            </div>
        );
    }
}

MyTeam.defaultProps = {
    isLoading: true,
    coaches: {},
};

MyTeam.propTypes = {
    fetchCoaches: PropTypes.func,
    coaches: PropTypes.object,
    isLoading: PropTypes.bool,
    deleteCoach: PropTypes.func,
    toggleCoachKey: PropTypes.func,
    addCoach: PropTypes.func,
    fetchCoachPermissions: PropTypes.func,
    updatePermissions: PropTypes.func,
};

const mapStateToProps = (state) => ({
    coaches: coachesSelector(state),
    isLoading: improvementsLoadingSelector(state, 'team'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCoaches: () => dispatch(getCoaches()),
    deleteCoach: (coachId) => dispatch(deleteCoach(coachId)),
    toggleCoachKey: (coachId, key) => dispatch(toggleCoachKey(coachId, key)),
    addCoach: (coachId) => dispatch(addCoach(coachId)),
    fetchCoachPermissions: (coachId, has_selected) => dispatch(getCoachPermissions(coachId, has_selected)),
    updatePermissions: (coachId, permission) => dispatch(updatePermissions(coachId, permission)),
});

const enhance = compose(
    withErrorBoundary,
    withError({reducer: 'improvements', page: 'team'}),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(MyTeam);
