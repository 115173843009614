import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../common/loader';
import withErrorBoundary from '../common/errorBoundary';

import Body from './body';
import List from './list';


class Results extends Component {
    constructor(props) {
        super(props);

        this.state = {
            results: null,
            selectedTab: 'epigenetic',
            selectedView: 'body',
        };

        this.handelSelectTab = this.handelSelectTab.bind(this);
        this.handelSelectView = this.handelSelectView.bind(this);
    }

    handelSelectTab(e, tab) {
        e.preventDefault();
        this.setState((prevState, props) => ({
            selectedTab: tab,
            results: null,
        }), () => this.getResults());
    }

    handelSelectView(e, view) {
        e.preventDefault();
        this.setState((prevState, props) => ({
            selectedView: view,
            results: null,
        }), () => this.getResults());
    }

    componentDidMount() {
        this.getResults();
    }

    getResults() {
        //Set so get loader
        this.setState({results: null});

        axios.get('user/results', {params: {type: this.state.selectedTab, view: this.state.selectedView}})
        .then(response => this.setState({
            results: response.data,
        }));
    }


    render() {
        const {results, selectedTab, selectedView} = this.state;


        return (
            <section className="dashboard-section content-wrapper-animate">
                <section className="dashboard-hero">
                    <div className="dashboard-hero__text-container">
                        <h1 className="dashboard-hero__title study-hero__title">Your Results</h1>
                    </div>

                    <ul className="menu-list dashboard-hero__menu">
                        <li><a href="#" className={selectedTab=='epigenetic' ? 'active' : ''} onClick={(e) => this.handelSelectTab(e, 'epigenetic')}>Changing You</a></li>
                        <li><a href="#" className={selectedTab=='genetic' ? 'active' : ''} onClick={(e) => this.handelSelectTab(e, 'genetic')}>Fixed From Birth</a></li>
                    </ul>
                </section>

                <section className="dashboard-section__container">
                    {results ?
                        <div>
                            <div className="results-submenu">
                                <div className="results-submenu__legend">
                                    {selectedView == 'body' && 
                                        <div>
                                            Status: 
                                            <div className="results-submenu__item">
                                                <div className="result-indicator__pulse good" />OK
                                            </div>
                                            <div className="results-submenu__item">
                                                <div className="result-indicator__pulse avg" />Borderline
                                            </div>
                                            <div className="results-submenu__item">
                                                <div className="result-indicator__pulse bad" />Need Attention
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="results-submenu__menu">
                                    View: 
                                    <a href="#" className={selectedView=='body' ? 'active' : ''} onClick={(e) => this.handelSelectView(e, 'body')}><i className="icon-adult"/></a>
                                    <a href="#" className={selectedView=='list' ? 'active' : ''} onClick={(e) => this.handelSelectView(e, 'list')}><i className="icon-th-list"/></a>
                                </div>
                            </div>

                            {selectedView == 'list' ?
                                <List results={results}/>
                            :
                                <Body results={results}/>
                            }
                        </div>
                    :
                        <Loader/>
                    }
                </section>

            </section>
        );
    }
}

export default withErrorBoundary(Results);
