import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

const shouldDisplay = (feature, propertyName, defaultValue) => {
  const partnerConfig = cachedPartnerTheming()?.partnerConfig;
  return partnerConfig && partnerConfig[propertyName] ? partnerConfig[propertyName].includes(feature) : defaultValue;
};

export const shouldDisplayFeature = (feature, defaultValue = true) => {
  return shouldDisplay(feature, 'displayedFeatures', defaultValue);
};

export const shouldDisplayPostResultActionFeature = feature => {
  return shouldDisplay(feature, 'displayedPostResultActionFeatures', false);
};

export const PARTNER_CODE = Object.freeze({
  NPH: 'nph',
});
