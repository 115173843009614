import React from 'react';
import moment from 'moment';
import Loader from '../../components/common/loader';

const MAX_ITEMS_TO_SHOW = 6;
export const TEST_COLOR = {
  negative: '#459B64',
  positive: '#F0975B',
  default: '#EAB63E',
};

export const PastResultsPanels = ({ history, isLoading, results, onDownload, columns = 1 }) => {
  const handleMoreResults = () => history.push('/dashboard/covid/result');
  const handleView = (testId) => history.push(`/dashboard/covid/result/${testId}`);


  const renderResultsTable = (start, limit) => {
    return (
    <table>
      <tbody>
        {results.slice(start, limit).map((r, index) => (
          <tr key={index}>
            <td className="section-result-cap">{moment(r.registered_at, 'YYYY-MM-DD').format('DD/MM')}</td>
            <td style={{ width: 80 }}>
              {r.product ? (
                <div className="section-result-cap">
                  {`${r.product.product_test_type} - ${r.product.product_purpose}`}
                </div>
              ) : null}
            </td>
            <td><div className="section-result-cap" style={{ color: TEST_COLOR[r.result.toLowerCase()] || TEST_COLOR.default }}>{r.result}</div></td>
            <td>
              {r.lab_processed_at ? (
                <div className="icon-row" onClick={() => handleView(r.id)} title="View your test result">
                  <img src="/img/eye.svg" alt="" />
                  View
                </div>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    );
  };

  return (
    <div className="test-dashboard-section__container">
      <div className="content-container">
        <div className="section-content split-content">
          <div>
            <div className="section-pre-title">All Tests</div>
            {!isLoading && (!results || results.length === 0) ? (
                <div className="section-empty">Nothing to show</div>
            ) : null}
            {!results && isLoading ? <Loader /> : null}
            {results && results.length > 0 && columns === 1 ? (
              <>
                {renderResultsTable(0, MAX_ITEMS_TO_SHOW)}
              </>
            ) : null}
            {results && results.length > 0 && columns === 2 ? (
              <div className="dual-results-list">
                {renderResultsTable(0, MAX_ITEMS_TO_SHOW / 2)}
                {renderResultsTable(MAX_ITEMS_TO_SHOW / 2, MAX_ITEMS_TO_SHOW)}
              </div>
            ) : null}
          </div>

          {/* {results && results.length > MAX_ITEMS_TO_SHOW ? (
            <div className="icon-row link" onClick={handleMoreResults}>
              <div>All results</div>
              <img src="/img/right_arrow.svg" alt="" />
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};
