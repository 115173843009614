import React from 'react';
import PropTypes from 'prop-types';
import { Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ReferenceDot, ComposedChart, Text } from 'recharts';
import { Tooltip } from 'react-tippy';

const RenderShape = (props) => {
    const { reference, cx, cy } = props;

    const HTMLContent = <div className="methylation-graph__tooltip-text">{reference.label || 'You'}</div>;

    return (
        <foreignObject width="18" height="18" x={cx - 11} y={cy - 11}>
            <Tooltip
                arrow={true}
                arrowSize="small"
                html={HTMLContent}
                position="top"
                theme="light"
            >
                <div className="tooltip-child" />
            </Tooltip>
        </foreignObject>
    );
};

const RenderLegend = (ledgend, reference, legendWrapperClass) => {

    if(!ledgend) {
        return null;
    }

    const legendCopy = {...ledgend};

    if(reference && reference.y !== null) {
        delete legendCopy.you_no_data;
    } else {
        delete legendCopy.you;
    }

    return (
        <div className={legendWrapperClass ? legendWrapperClass : ''}>
            {
                Object.keys(legendCopy).map((key) => (
                    <div className="item-legend" key={key}>
                        <div className={`range-legend ${key}`} />
                        <p dangerouslySetInnerHTML={{__html: legendCopy[key]}} />
                    </div>
                ))
            }
        </div>
    );
};

const RenderTick = (props) => {
    const { x, y, textAnchor, verticalAnchor, index, visibleTicksCount } = props;
    if(index > 0 && index < visibleTicksCount-1) {
        return ' ';
    }

    return (
        <Text
            className="graph-label"
            textAnchor={textAnchor}
            verticalAnchor={verticalAnchor}
            x={x+27}
            y={y-10}
        >
            {index == 0 ? 'Decreased' : 'Increased'}
        </Text>
    );
};

const RenderGraph = (graphData, selectedTab, legendWrapperClass) => {
    const { data, label_x, label_y, ledgend, you, label_x_value_fruit_vegetable } = graphData;

    if(!(data && data.length)) {
        return null;
    }

    const reference = you[selectedTab];

    return (
        <div>
            <div className="line-graph__graph-container">
                <div className="label-container graph-label top-label">{label_y}</div>
                <ResponsiveContainer>
                    <ComposedChart data={data} margin={{ top: 30, left: -30 }}>
                        <CartesianGrid strokeDasharray="10 8" vertical={false} />
                        <XAxis dataKey="x" domain={[0, 'dataMax']} tickCount={2} tickLine={false} type="number" />
                        <YAxis axisLine={false} domain={['auto', 'auto']} tick={(props) => RenderTick(props)} tickCount={6} tickLine={false} allowDecimals={false} type="number" width={70} />
                        <Line dataKey={selectedTab} dot={false} stroke="#5B7BFF" strokeDasharray="15 5" strokeWidth={3} type="monotone"  />
                        {(reference && Object.keys(reference).length) ?
                            <ReferenceDot
                                isFront={true}
                                x={reference.x}
                                y={reference.y}
                                r={7}
                                fill="#5B7BFF"
                                stroke="#5B7BFF"
                                strokeWidth={3}
                                shape={(props) => RenderShape({...props, reference})}
                            /> : ''
                        }
                    </ComposedChart>
                </ResponsiveContainer>
                <div className="label-container graph-label">
                    {(selectedTab == 'value_fruit_vegetable' && label_x_value_fruit_vegetable) ? label_x_value_fruit_vegetable : label_x}
                </div>
            </div>
            {RenderLegend(ledgend, reference, legendWrapperClass)}
        </div>
    );
};

const CustomLineGraph = ({data, selectedTab, legendWrapperClass}) => {
    if(!data) {
        return null;
    }

    return (
        <div className="line-graph__container">
            {RenderGraph(data, selectedTab, legendWrapperClass)}
        </div>
    );
};

CustomLineGraph.propTypes = {
    data: PropTypes.object,
};

export default CustomLineGraph;
