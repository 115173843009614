import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import { validated } from 'react-custom-validation';
import axios from 'axios';

import validationRules from '../helpers/validationRules';
import withErrorBoundary from '../common/errorBoundary';
import utils from '../helpers/utils';
import InputField from '../common/inputField';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';
import { StyledButton, StyledLink } from '../../theming/themed-components';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: '',
      },
      disableSubmitButton: false,
      submitError: false,
      submitErrorMessage: '',
      formSuccess: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormValid = this.handleFormValid.bind(this);
    this.handleFormInvalid = this.handleFormInvalid.bind(this);
  }

  handleInputChange(field, value) {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  }

  handleFormValid() {
    this.setState({ submitError: false, submitErrorMessage: '', disableSubmitButton: true });
    this.setState({ disableSubmitButton: true });

    //Call API to reset
    axios
      .post(`auth/password/email${getPartnerQueryParams()}`, { email: this.state.fields.email })
      .then(response => {
        if (response.data.status) {
          this.setState({
            disableSubmitButton: false,
            formSuccess: true,
          });
        } else {
          this.setState({
            disableSubmitButton: false,
            submitError: true,
            submitErrorMessage: 'Unable to reset password at this time.',
          });
        }
      })
      .catch(error => {
        const errorMsg = utils.getFirstApiError(error, 'Unable to reset password at this time.');
        this.setState({
          disableSubmitButton: false,
          submitError: true,
          submitErrorMessage: errorMsg,
        });
      });
  }

  handleFormInvalid() {
    this.setState({ submitError: true });
  }

  render() {
    const { submitError, submitErrorMessage } = this.state;
    return (
      <div className="registration__wrapper">
        <div className="registration__container">
          <ForgotPasswordForm
            fields={this.state.fields}
            formSuccess={this.state.formSuccess}
            onChange={this.handleInputChange}
            onInvalid={this.handleFormInvalid}
            onValid={this.handleFormValid}
            submitError={submitError}
            submitErrorMessage={submitErrorMessage}
          />
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(ForgotPassword);

function forgotPasswordValidationConfig(props) {
  const { email } = props.fields;

  return {
    fields: ['email'],
    validations: {
      email: [
        [validationRules.isRequired, email],
        [validationRules.isEmail, email],
      ],
    },
  };
}

const ForgotPasswordFormFields = props => {
  const { fields, onChange, onValid, onInvalid, $fieldEvent, $validation, submitError, submitErrorMessage, formSuccess, $submit } = props;

  return (
    <form
      className="register-form__container"
      onSubmit={e => {
        e.preventDefault();
        $submit(onValid, onInvalid);
      }}
    >
      <h2 className="register-form__title">Forgotten Password?</h2>
      {!formSuccess && <p className="register-form__description">Enter your email to be sent a reset link.</p>}
      <div className="register-form__input-fields">
        {formSuccess ? (
          ''
        ) : (
          <div className="register-form__single-field">
            <InputField
              autoFocus
              error={$validation.email.show ? $validation.email.error.reason : ''}
              label="Your Email"
              name="email"
              onBlur={() => $fieldEvent('blur', 'email')}
              onChange={e => onChange('email', e.target.value)}
              showError={$validation.email.show}
              type="email"
              value={fields.email}
            />
          </div>
        )}
        <div>
          {submitError && (
            <div className="form__error form__error--general">{submitErrorMessage ? submitErrorMessage : 'Please complete the errors'}</div>
          )}
          {formSuccess ? (
            <div className="register-form__single-field">
              <p className="register-form__description">Check your email for reset instructions. Please note the email can take up to 10 minutes to arrive.</p>
              <StyledLink className="btn btn--full-width" to={`/login${getPartnerQueryParams()}`}>
                Login
              </StyledLink>
            </div>
          ) : (
            <div>
              <div className="register-form__single-field">
                <StyledButton className="btn btn--full-width">Reset Password</StyledButton>
              </div>
              <div className="register-form__single-field login-form__links single-link">
                <Link to={`/login${getPartnerQueryParams()}`}>Login Here</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const ForgotPasswordForm = validated(forgotPasswordValidationConfig)(ForgotPasswordFormFields);
