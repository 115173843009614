import React from 'react';
import PropTypes from 'prop-types';

const HeroSection = ({heroSectionInfo, slug, disableLink}) => {
    if(!heroSectionInfo) {
        return null;
    }

    return (
        <section className="result-hero">
            <div className="result-hero__text-container">
                {
                    slug ?
                        <div className="hero-image">
                            <img alt={slug} src={`/img/you/actionable-you/epi-indicator/${slug}.svg`} />
                        </div> : ''
                }
                <h1 className="result-hero__title study-hero__title">{heroSectionInfo.title}</h1>
                {
                    !disableLink ? 
                    <p className="result-hero__description biological-description">{heroSectionInfo.summaryText}</p>
                    :<p className="result-hero__description biological-description">{heroSectionInfo.summary_text}</p>
                }
                <div className="result-hero__intro">
                    <div className="result-hero-image">
                        <img alt="result-intro" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                    </div>
                    {
                        !disableLink? 
                        <p className="result-intro-description">{heroSectionInfo.resultIntroText}</p>
                        :<p className="result-intro-description">{heroSectionInfo.result_intro_text}</p>
                    }
                </div>
                <div className="result-hero__result-status-section" data-private>
                    {
                        !disableLink? 
                        <div className={`result-hero__result-status ${heroSectionInfo.resultStatus}`}>{heroSectionInfo.resultStatusText}</div>
                        :<div className={`result-hero__result-status ${heroSectionInfo.result_status}`}>{heroSectionInfo.result_status_text}</div>
                    }
                </div>
            </div>
        </section>
    );
};

HeroSection.propTypes = {
    heroSectionInfo: PropTypes.object,
    resultDelta: PropTypes.any,
    previousTest: PropTypes.object
};

export default HeroSection;