import React from 'react';
import { Link } from 'react-router-dom';

import utils from '../../components/helpers/utils';
import SplitConsumer from '../../components/context/splitConsumer';
import { StyledDivWrapper } from '../../theming/themed-components';

const ItemCard = ({ item, index }) => {
  const { title, description, imgUrl, buttonText, buttonUrl, iconClass, futures, outsideLink, isStatic } = item;
  return (
    <SplitConsumer key={`can-do-${index}`} name="show_future_predictions">
      {value =>
        value == 'off' && futures ? null : (
          <div className={`dashboard-item__result-item ${!buttonUrl ? 'no-hover' : ''}`}>
            {isStatic ? (
              <div className="row-section">
                <div
                  className={`title-section ${!(imgUrl || iconClass) ? ' title-section--fullwidth' : ''}${
                    buttonText ? ' bottom-margin' : ''
                  }`}
                >
                  <h3 className="title">{title}</h3>
                  {description ? <p className="description">{description}</p> : ''}
                </div>
                {imgUrl || iconClass ? (
                  <div className="you-image-section">
                    {imgUrl ? (
                      <img alt={title} className="dashboard-item__image" src={imgUrl} />
                    ) : iconClass ? (
                      <i className={iconClass} />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                {buttonText && <StyledDivWrapper className="rating-text">{buttonText}</StyledDivWrapper>}
              </div>
            ) : outsideLink ? (
              <a className="row-section" href={buttonUrl} onClick={() => utils.track('Dashboard Link', { title: title, to: buttonUrl })}>
                <div
                  className={`title-section ${!(imgUrl || iconClass) ? ' title-section--fullwidth' : ''}${
                    buttonText ? ' bottom-margin' : ''
                  }`}
                >
                  <h3 className="title">{title}</h3>
                  {description ? <p className="description">{description}</p> : ''}
                </div>
                {imgUrl || iconClass ? (
                  <div className="you-image-section">
                    {imgUrl ? (
                      <img alt={title} className="dashboard-item__image" src={imgUrl} />
                    ) : iconClass ? (
                      <i className={iconClass} />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                {buttonText && <StyledDivWrapper className="rating-text">{buttonText}</StyledDivWrapper>}
              </a>
            ) : (
              <Link className="row-section" onClick={() => utils.track('Dashboard Link', { title: title, to: buttonUrl })} to={buttonUrl}>
                <div
                  className={`title-section ${!(imgUrl || iconClass) ? ' title-section--fullwidth' : ''}${
                    buttonText ? ' bottom-margin' : ''
                  }`}
                >
                  <h3 className="title">{title}</h3>
                  {description ? <p className="description">{description}</p> : ''}
                </div>
                {imgUrl || iconClass ? (
                  <div className="you-image-section">
                    {imgUrl ? (
                      <img alt={title} className="dashboard-item__image" src={imgUrl} />
                    ) : iconClass ? (
                      <i className={iconClass} />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                {buttonText && <StyledDivWrapper className="rating-text">{buttonText}</StyledDivWrapper>}
              </Link>
            )}
          </div>
        )
      }
    </SplitConsumer>
  );
};

export default ItemCard;
