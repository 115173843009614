import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadarChart, PolarAngleAxis, PolarGrid, Radar, ResponsiveContainer, PolarRadiusAxis } from 'recharts';
import { Tooltip } from 'react-tippy';

const position = {
    0: 'top',
    1: 'right',
    2: 'left'
};

const RenderLabel = (props) => {
    const { x, y, index, data, payload, selectedTab, tabs, labelKey, breakpoint } = props;
    const labelData = data[index];

    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');

    //Ajust labels depending on which angel of the graph so we dont cut off
    let newX = x-30;
    let newY = y-30;
    //top
    if(payload.coordinate == 90) {
        newX = x-30;
        newY = y-47;
    }
    //right
    else if(payload.coordinate == -30) {
        newX = x-13;
        newY = y-20;
    }
    //left
    else if(payload.coordinate == -150) {
        newX = x-47;
        newY = y-20;
    }

    const HTMLContent = (
        <div className="methylation-graph__tooltip-text">
            <div>{labelData[labelKey]}</div>
            <div>{tabs[selectedTab]}: {labelData[selectedTab]}%</div>
        </div>
    );

    return (
        <foreignObject width="60" height="60" x={newX} y={newY}>
            <div className="label-image-container">
                <Tooltip
                    arrow={true}
                    arrowSize="small"
                    html={HTMLContent}
                    open={isMobile ? '' : true}
                    position={isMobile ? 'top' : position[index]}
                    size="small"
                    theme="light"
                >
                    <div className="label-image">
                        <img src={labelData.icon_url} alt={payload.value} />
                    </div>
                </Tooltip>
            </div>
        </foreignObject>
    );
};

class CustomRadarChart extends Component {
    constructor() {
        super();

        this.state = {
            selectedTab: 'you',
        };
    }

    toggleTab(value) {
        this.setState({selectedTab: value});
    }

    renderGraph(data, tabs) {
        if(!(data && data.length)) {
            return null;
        }

        const { colours, labelKey, breakpoint } = this.props;
        const { selectedTab } = this.state;

        const values = data.map(item => item[selectedTab]);

        const axisDomain = (values && values.length) ? Math.max(...values) + 15 : 'dataMax';

        return (
            <div className="graph-section-container radar-graph">
                <div className="radar-center-label">
                    <img alt="analysis-label" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
                </div>
                <ResponsiveContainer>
                    <RadarChart cy={150} outerRadius={100} data={data}>
                        <PolarAngleAxis
                            dataKey="label"
                            stroke="#f3f3f3"
                            tick={<RenderLabel data={data} selectedTab={selectedTab} tabs={tabs} labelKey={labelKey} breakpoint={breakpoint} />}
                            tickLine={false}
                        />
                        <PolarRadiusAxis
                            angle={90}
                            axisLine={false}
                            domain={[0, 100]}
                            tick={false}
                            tickLine={false}
                            type="number"
                        />
                        <PolarGrid stroke="#ffffff" strokeWidth={2} />
                        <Radar
                            dataKey={selectedTab}
                            fill={colours[selectedTab]}
                            fillOpacity={0.8}
                            stroke="transparent"
                        />
                    </RadarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    renderTabs() {
        const { graph: {data, tabs}, tabClass } = this.props;
        if(!(data && data.length) || !tabs) {
            return null;
        }

        const { selectedTab } = this.state;

        return (
            <div className={`line-graph__tabs-container line-graph__tabs-container--no-margin${tabClass ? ` ${tabClass}` : ''}`}>
                {
                    Object.keys(tabs).map((key) => (
                        <button
                            className={`single-tab ${selectedTab}${selectedTab == key ? ' active' : ''}`}
                            key={key}
                            onClick={() => this.toggleTab(key)}
                        >
                            {tabs[key]}
                        </button>
                    ))
                }
            </div>
        );
    }

    render() {
        const { graph } = this.props;

        if(!graph) {
            return null;
        }

        const { title, data, analysis_text, tabs, text } = graph;

        return (
            <div className="comparison__radar-chart">
                {title ? <h5 className="indicator-section-title inside-title">{title}</h5> : ''}
                {text ? <p className="indicator-section-description">{text}</p> : ''}
                <div className="comparison__graph-container stress-section">
                    <div className="radar-chart-container">
                        {this.renderGraph(data, tabs)}
                        {this.renderTabs()}
                    </div>
                    <p className="analysis-text analysis-text--stress text-center">{analysis_text}</p>
                </div>
            </div>
        );
    }
}

CustomRadarChart.defaultProps = {
    colours: {you: '#5B7BFF', chronomics_community: '#FF924D'},
    labelKey: 'label',
    breakpoint: 'large',
};

CustomRadarChart.propTypes = {
    graph: PropTypes.object,
    labelKey: PropTypes.string,
    colours: PropTypes.object,
    breakpoint: PropTypes.string,
};

export default CustomRadarChart;