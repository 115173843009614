import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Formats a date string into the display pattern we want for the component.
 *
 * @param {string} date
 * @returns string
 */
const formatDate = date => moment(date).format('DD.MM.YYYY, h:mm a');

const Header = ({ productName, collectionDate, reportDate }) => (
  <>
    {productName && <h2 className="orders-section__sub-title">{productName} Test Result</h2>}
    {collectionDate && <p>Sample collection date / {formatDate(collectionDate)}</p>}
    {reportDate && <p>Sample report date / {formatDate(reportDate)}</p>}
  </>
);

Header.propTypes = {
  productName: PropTypes.string,
  collectionDate: PropTypes.string,
  reportDate: PropTypes.string,
};

export default Header;
