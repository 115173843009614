import { LOCAL_ENVIRONMENT } from '../apiHelpers/partnerServiceRequests';

const TEST_RESULT_SERVICE = 'testResultService';
const PRODUCT_SERVICE = 'productService';

async function fetchWebAPI(path, headers, service) {
  try {
    const url = new URL(path, getWebApiUrl(service));

    const res = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        ...headers,
      },
    });
    const { ok, status } = res;
    if (ok) return res.json();
    throw new Error(`status code ${status}`);
  } catch (err) {
    throw err;
  }
}

const getLocalWebApiServicesUrl = () => {
  return {
    [TEST_RESULT_SERVICE]: window.CONFIG.testResultServiceUrl,
    [PRODUCT_SERVICE]: window.CONFIG.productServiceUrl,
  };
};

const getWebApiUrl = service => {
  if (window.CONFIG.env !== LOCAL_ENVIRONMENT) {
    return window.CONFIG.webApiUrl;
  }

  if (window.CONFIG.env === LOCAL_ENVIRONMENT && getLocalWebApiServicesUrl()[service]) {
    return getLocalWebApiServicesUrl()[service];
  }

  throw new Error('Fail to retrieve WebApi url');
};

/*
 Call test-result-service to get all test results.
 Covid test results not returned from this endpoint, and instead they are returned from PHP.
*/
export const fetchResults = testUserId =>
  fetchWebAPI(`/tests/v2${testUserId ? '?testUserId=' + testUserId : ''}`, null, TEST_RESULT_SERVICE);

/*
 Call test-result-service to get a specific test result.
 Covid test result not returned from this endpoint, and instead they are returned from PHP.
*/
export const fetchResultByTestId = testId => fetchWebAPI(`/tests/v2/${testId}?requiresHmac=true`, null, TEST_RESULT_SERVICE);

export const fetchBaseBiomarkers = () => fetchWebAPI('/base-biomarkers/v1?limit=1000', null, PRODUCT_SERVICE);

export const downloadResults = path => fetchWebAPI(path, null, TEST_RESULT_SERVICE);

export const fetchQuestionnairesForUser = () => fetchWebAPI('surveys/v1');

export const fetchProducts = async tests => {
  const productCodes = tests.map(test => {
    return test.productCode;
  });

  const uniqueProductCodes = [...new Set(productCodes)];

  const uniqueProductCodesQueryParameters = uniqueProductCodes.map(productCode => {
    return `productCode[]=${productCode}`;
  });

  const productCodesString = uniqueProductCodesQueryParameters.toString().replaceAll(',', '&');

  return await fetchWebAPI(`/products/v2?${productCodesString}`, null, PRODUCT_SERVICE);
};

export const fetchQuestionnaires = async () => {
  let questionnaires = [];
  try {
    questionnaires = await fetchQuestionnairesForUser();
  } catch (error) {
    console.error(`Could not fetch questionnaires for this user: ${error}`);
  }

  return questionnaires?.data;
};

export const fetchProductByProductCode = (productCode, options) => {
  const marketingDetailsQs = options && options.includeMarketingDetails ? 'includeMarketingDetails=true&' : '';
  return fetchWebAPI(`/products/v2?${marketingDetailsQs}productCode=${productCode}`, null, PRODUCT_SERVICE);
};
