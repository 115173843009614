import { isPlainObject } from 'lodash';

/**
 * as we have non overlapping ranges we'll use
 * the <nextRange as first and
 * >prevRange as last value
 */
function getNearRange({ currentRange, prevRange, nextRange }) {
  if (!prevRange) return nextRange[0];
  if (!nextRange) return prevRange[prevRange.length - 1];
  return currentRange;
}

export function getRanges(interpretation) {
  if (!isPlainObject(interpretation)) return null;
  if (interpretation.value) {
    return Array.isArray(interpretation.value) ? interpretation.value : Object.entries(interpretation.value);
  }

  return Object.entries(interpretation);
}
