import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TestSelection } from './TestSelection';
import { TestToRelease } from './testToRelease';
import { FitToFly } from './fitToFly';
import { CovidTest } from './covid';

export const TimelineCalculator = () => {
  return (
    <Switch>
      <Route
          exact
          path="/dashboard/covid/test-timeline/select-test"
          component={TestSelection}
      />
      <Route
          exact
          path="/dashboard/covid/test-timeline/test-to-release/:step?"
          component={TestToRelease}
      />
      <Route
          exact
          path="/dashboard/covid/test-timeline/fit-to-fly/:step?"
          component={FitToFly}
      />
      <Route
          exact
          path="/dashboard/covid/test-timeline/covid/:step?"
          component={CovidTest}
      />
    </Switch>
  );
};
