import moment from 'moment';
import { transform } from 'lodash';
import { excludedInterpretations, shortenedInterpretations } from '../../../constants';
import utils from '../../../components/helpers/utils';
import failedResultContent from './failedResultContent.json';


function getIsNonDeterministicResult(result) {
  result = result?.replace(/\s/g, '');
  const pattern = /^[<|>]\d*(\.\d+)?$/;
  return pattern.test(result);
}

export function getIsLessThanResult(result) {
  const isNonDeterministicResult = getIsNonDeterministicResult(result);

  const pattern = /</;
  return isNonDeterministicResult && pattern.test(result);
}
export function getIsMoreThanResult(result) {
  const isNonDeterministicResult = getIsNonDeterministicResult(result);

  const pattern = />/;
  return isNonDeterministicResult && pattern.test(result);
}

export function formatInterpretation(str) {
  if (str) {
    let lowerCaseStr = '';
    if (Array.isArray(str)) {
      if (str.length > 0) {
        lowerCaseStr = str[0].toLowerCase();
      } else {
        return '';
      }
    } else {
      lowerCaseStr = str.toLowerCase();
    }

    const extendedStr = shortenedInterpretations[lowerCaseStr];
    if (extendedStr) return extendedStr;

    const excludedInterpretation = excludedInterpretations[lowerCaseStr];
    if (excludedInterpretation) return '';

    return utils.capitalizeFirstLetter(utils.removeUnderscore(lowerCaseStr));
  }
  return '';
}

export const formatBiomarkerResult = (bm) => {
  const { result, biomarker } = bm;
  const { interpretationRule, unit } = biomarker;

  switch (interpretationRule.rule) {
    case 'QUALITATIVE':
      return `${result.charAt(0).toUpperCase()}${result.substring(1).toLowerCase()}`;

    default:
      if (Number.isNaN(Number(result)) && !getIsNonDeterministicResult(result)) return '';
      return `${result ? result : ''} ${unit ? unit : ''}`;
  }
};

export const getIsUnsupportedRule = (interpretationRule) => {
  return (interpretationRule?.rule === 'TIME');
};

export const getRangeValueOperator = (value) => {
  if (!value) return false;
  return value.match(/(>=|<=|>|<)/g)[0];
};

export const getRangeValueNumber = (value) => {
  if (!value) return false;
  return value.match(/-?\d+/g)[0];
};

export const checkValueWithOperator = ({ value, operator, operatorValue }) => {
  switch (operator) {
    case '>=':
      return value >= operatorValue;
    case '<=':
      return value <= operatorValue;
    case '>':
      return value > operatorValue;
    case '<':
      return value < operatorValue;
    default:
      return false;
  }
};

export const checkValueInRange = ({ value, minValue, maxValue }) => {
  if (!minValue || !maxValue || !value) return false;
  const minOperator = getRangeValueOperator(minValue);
  const minOperatorValue = getRangeValueNumber(minValue);
  if (!minOperator || !minOperatorValue) return false;

  const maxOperator = getRangeValueOperator(maxValue);
  const maxOperatorValue = getRangeValueNumber(maxValue);
  if (!maxOperator || !maxOperatorValue) return false;

  const checkAgeAgainstMinAge = checkValueWithOperator({ value, operator: minOperator, operatorValue: minOperatorValue });
  const checkAgeAgainstMaxAge = checkValueWithOperator({ value, operator: maxOperator, operatorValue: maxOperatorValue });

  return (checkAgeAgainstMinAge && checkAgeAgainstMaxAge);
};

export function getFailedResultContent(result) {
  const lowerCasedContent = transform(failedResultContent, (res, val, key) => (res[key.toLocaleLowerCase()] = val));
  return lowerCasedContent[result.toLowerCase()];
}

export function getIsFailedResult(result) {
  const failedContent = getFailedResultContent(result);

  if ((Number.isNaN(Number(result)) && Boolean(failedContent) && !getIsNonDeterministicResult(result))) return true;

  return false;
}

export function getIsFailedInterpretation(interpretation) {
  if (interpretation) {
    let lowerCaseInterpretation = '';
    if (Array.isArray(interpretation)) {
      if (interpretation.length > 0) {
        lowerCaseInterpretation = interpretation[0].toString();
      } else {
        return false;
      }
    } else {
      lowerCaseInterpretation = interpretation.toLowerCase();
    }
    return lowerCaseInterpretation === 'inconclusive' || lowerCaseInterpretation === 'indefinite' || lowerCaseInterpretation === 'uninterpretable';
  }
  return false;
}
