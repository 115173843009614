import React, { Component, createRef } from 'react';
import { StyledButton } from '../../theming/themed-components';
import utils from '../helpers/utils';

const HelpfulItems = [
  { title: 'Scales', image: '/img/questionnaire/weight-scale.png' },
  { title: 'Measuring tape', image: '/img/questionnaire/measure-tape.png' },
  { title: 'Stopwatch', image: '/img/questionnaire/clock.png' },
  { title: 'Coffee', image: '/img/questionnaire/coffee.png' },
];

class Introduction extends Component {
  constructor() {
    super();

    this.state = {
      showHelpfulItems: false,
    };

    this.helpfulItemsRef = createRef();

    this.helpfulItems = this.helpfulItems.bind(this);
  }

  helpfulItems() {
    this.setState({ showHelpfulItems: true });
  }

  componentDidUpdate() {
    if (this.helpfulItemsRef && this.helpfulItemsRef.current) {
      utils.scrollToHash(this.helpfulItemsRef.current, 200);
    }
  }

  renderIntroductionSection() {
    const { sequenceNumber } = this.props;
    return (
      <div className="introduction__container">
        <h1 className="introduction__title">Hey!</h1>
        <div className="introduction__image">
          <img src="/img/you/actionable-you/intervenable-category/social.svg" alt="social" />
        </div>
        {sequenceNumber > 1 ? (
          <p className="introduction__description">
            We need to update some information from your last test to see what has changed in your lifestyle. This will help show what is
            affecting your epigenetic changes.
            <br />
            <br />
            This will take about 10-20 minutes depending on what information you have to hand.
          </p>
        ) : (
          <p className="introduction__description">
            The questionnaire takes about 40 minutes.
            <br />
            <br />
            Answering these questions together with your sample will help us give you the most personalised health insights and
            recommendations available on the planet.
          </p>
        )}
        <div className="introduction__button-container">
          <StyledButton className="btn" onClick={this.helpfulItems}>
            Next
          </StyledButton>
        </div>
      </div>
    );
  }

  renderHelpfulItems() {
    const { showQuestionnaires } = this.props;
    return (
      <div className="introduction__container" ref={this.helpfulItemsRef}>
        <h1 className="introduction__title">Helpful items</h1>
        <p className="introduction__description helpful-description">Here are some items you’ll need to complete this section.</p>
        <div className="introduction__images-container">
          {HelpfulItems.map((item, index) => (
            <div className="introduction__image-section" key={index}>
              {item.image ? (
                <div className="image-section">
                  <img src={item.image} alt={item.title} />
                </div>
              ) : (
                <div className="image-replacement" />
              )}
              <p className="item-image-title">{item.title}</p>
            </div>
          ))}
        </div>

        <div className="introduction__button-container">
          <StyledButton className="btn" onClick={() => showQuestionnaires()}>
            get started
          </StyledButton>
        </div>
      </div>
    );
  }

  render() {
    const { showHelpfulItems } = this.state;
    return <div className="introduction__wrapper">{showHelpfulItems ? this.renderHelpfulItems() : this.renderIntroductionSection()}</div>;
  }
}

export default Introduction;
