import React from 'react';
import PropTypes from 'prop-types';

const CardForMobileDevices = ({title, text, rightSideImage, number, image}) => {
    return (
        <div className={`how-works__card ${rightSideImage ? 'right-image' : ''}`}>
            <div className="how-works__card-section">
                <img alt={title} className="full-height" src={image || `/img/you/actionable-you/epi-indicator/scientific-steps/${number}.jpg`} />
                <div className="how-works__information-section">
                    {number ? <div className={`how-works__step-number ${rightSideImage ? 'left-number' : ''}`}>{number}</div> : ''}
                    <div className="how-works__card-info">
                        {title && <h5 className="card-title">{title}</h5>}
                        {text && <p className="card-description">{text}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardForDesktopDevices = ({title, text, rightSideImage, number, image}) => {
    const imageUrl = `/img/you/actionable-you/epi-indicator/scientific-steps/${number}.jpg`;
    return (
        <div className={`how-works__card ${rightSideImage ? 'right-image' : ''}`}>
            {
                !rightSideImage ? <img alt={title} className="full-height" src={image || imageUrl} /> : ''
            }
            <div className="how-works__card-section">
                <div className="how-works__information-section">
                    {number ? <div className={`how-works__step-number ${rightSideImage ? 'left-number' : ''}`}>{number}</div> : ''}
                    <div className="how-works__card-info">
                        {title && <h5 className="card-title">{title}</h5>}
                        {text && <p className="card-description">{text}</p>}
                    </div>
                </div>
            </div>
            {
                rightSideImage ? <img alt={title} className="full-height" src={image || imageUrl} /> : ''
            }
        </div>
    );
};

const Card = ({breakpoint, ...props}) => {
    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium');
    if(isMobile) {
        return CardForMobileDevices(props);
    }

    return CardForDesktopDevices(props);
};

const HowWorksSteps = ({ steps, showNumbers, breakpoint }) => {
    return (
        <div className="how-works__section">
            <div className="how-works__learn-more">
                {
                    steps && steps.map((step, i) => (
                        <Card
                            {...step}
                            breakpoint={breakpoint}
                            key={i}
                            number={i+1}
                            rightSideImage={i%2}
                            showNumbers={showNumbers}
                        />
                    ))
                }
            </div>
        </div>
    );
};

HowWorksSteps.defaultProps = {
    steps: [],
    breakpoint: 'large'
};

HowWorksSteps.propTypes = {
    steps: PropTypes.array.isRequired,
    breakpoint: PropTypes.string
};

export default HowWorksSteps;
