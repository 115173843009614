import React from 'react';
import PropTypes from 'prop-types';
//import ReactGA from "react-ga";
import utils from '../helpers/utils';

const RelatedArticles = ({ className, relatedArticles, title, hideTitle }) => {
    const relatedArticlesLength = relatedArticles.length;

    if(!Array.isArray(relatedArticles) || relatedArticlesLength === 0) return ('');
    const articleClass = Math.floor(relatedArticlesLength / 4) > 0 ? 'articles-in-a-row-4' : `articles-in-a-row-${relatedArticlesLength}`;

    return (
        <div className={className}>
            <div className="related-container">
                {!hideTitle ? <h3 className="related-container__title">{title || 'References'}</h3> : ''}
                <div className="related-container__sections">
                    {
                        relatedArticles.map((article, index) => (
                            <div
                                className={`related-container__related-article ${articleClass}`}
                                key={index}
                            >
                                <a  href={article.link}
                                    onClick={() => utils.trackOutboundLink(article.link, 'relatedArticles')}
                                    target="_blank" rel="noreferrer"
                                ><h5 className="article-title">{article.title}</h5>
                                </a>
                                {/*<p className="article-information">{article.expert}</p>*/}
                                <a
                                    href={article.link}
                                    onClick={() => utils.trackOutboundLink(article.link, 'relatedArticles')}
                                    target="_blank" className="article-read-more" rel="noreferrer"
                                >Read more <i className="icon-right-arrow" />
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

RelatedArticles.propTypes = {
    className: PropTypes.string,
    relatedArticles: PropTypes.array,
    title: PropTypes.string,
    hideTitle: PropTypes.bool
};

RelatedArticles.defaultProps = {
    className: '',
    relatedArticles: [],
    hideTitle: false
};

export default RelatedArticles;
