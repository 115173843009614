import * as React from 'react';

const states = [
  {
     'title':'Alabama',
     'state_code':'AL'
  },
  {
     'title':'Alaska',
     'state_code':'AK'
  },
  {
     'title':'Arizona',
     'state_code':'AZ'
  },
  {
     'title':'Arkansas',
     'state_code':'AR'
  },
  {
     'title':'California',
     'state_code':'CA'
  },
  {
     'title':'Colorado',
     'state_code':'CO'
  },
  {
     'title':'Connecticut',
     'state_code':'CT'
  },
  {
     'title':'Delaware',
     'state_code':'DE'
  },
  {
     'title':'Florida',
     'state_code':'FL'
  },
  {
     'title':'Georgia',
     'state_code':'GA'
  },
  {
     'title':'Hawaii',
     'state_code':'HI'
  },
  {
     'title':'Idaho',
     'state_code':'ID'
  },
  {
     'title':'Illinois',
     'state_code':'IL'
  },
  {
     'title':'Indiana',
     'state_code':'IN'
  },
  {
     'title':'Iowa',
     'state_code':'IA'
  },
  {
     'title':'Kansas',
     'state_code':'KS'
  },
  {
     'title':'Kentucky',
     'state_code':'KY'
  },
  {
     'title':'Louisiana',
     'state_code':'LA'
  },
  {
     'title':'Maine',
     'state_code':'ME'
  },
  {
     'title':'Maryland',
     'state_code':'MD'
  },
  {
     'title':'Massachusetts',
     'state_code':'MA'
  },
  {
     'title':'Michigan',
     'state_code':'MI'
  },
  {
     'title':'Minnesota',
     'state_code':'MN'
  },
  {
     'title':'Mississippi',
     'state_code':'MS'
  },
  {
     'title':'Missouri',
     'state_code':'MO'
  },
  {
     'title':'Montana',
     'state_code':'MT'
  },
  {
     'title':'Nebraska',
     'state_code':'NE'
  },
  {
     'title':'Nevada',
     'state_code':'NV'
  },
  {
     'title':'New Hampshire',
     'state_code':'NH'
  },
  {
     'title':'New Jersey',
     'state_code':'NJ'
  },
  {
     'title':'New Mexico',
     'state_code':'NM'
  },
  {
     'title':'New York',
     'state_code':'NY'
  },
  {
     'title':'North Carolina',
     'state_code':'NC'
  },
  {
     'title':'North Dakota',
     'state_code':'ND'
  },
  {
     'title':'Ohio',
     'state_code':'OH'
  },
  {
     'title':'Oklahoma',
     'state_code':'OK'
  },
  {
     'title':'Oregon',
     'state_code':'OR'
  },
  {
     'title':'Pennsylvania',
     'state_code':'PA'
  },
  {
     'title':'Rhode Island',
     'state_code':'RI'
  },
  {
     'title':'South Carolina',
     'state_code':'SC'
  },
  {
     'title':'South Dakota',
     'state_code':'SD'
  },
  {
     'title':'Tennessee',
     'state_code':'TN'
  },
  {
     'title':'Texas',
     'state_code':'TX'
  },
  {
     'title':'Utah',
     'state_code':'UT'
  },
  {
     'title':'Vermont',
     'state_code':'VT'
  },
  {
     'title':'Virginia',
     'state_code':'VA'
  },
  {
     'title':'Washington',
     'state_code':'WA'
  },
  {
     'title':'West Virginia',
     'state_code':'WV'
  },
  {
     'title':'Wisconsin',
     'state_code':'WI'
  },
  {
     'title':'Wyoming',
     'state_code':'WY'
  }
];

const States = ({
    fields,
    onBlur,
    onChange,
}) => {

    return <div className="form__select-wrapper">
              <select
                  name="delivery_county"
                  id={fields.delivery_county}
                  value={fields.delivery_county}
                  onChange={(e) => onChange('delivery_county', e.target.value)}
                  onBlur={() => onBlur('blur', 'delivery_county')}          
              >
                  <option key="" value="" />
                  {states.map(
                      (
                          o,
                          key
                      ) => (
                          <option
                              key={
                                  key
                              }
                              value={
                                  o.state_code
                              }
                          >
                              {
                                  o.title
                              }
                          </option>
                      )
                  )}
              </select>
              <i className="icon-down-open" />
           </div>;
};

export default States;