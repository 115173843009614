import React, { Component, createContext } from 'react';

import { getSplitClient } from '../../app';

export const SplitContext = createContext({
    client: {},
    isReady: false,
});

class SplitProvider extends Component {
    constructor() {
        super();
        this.state = {
            isReady: false,
            client: null
        };
    }

    componentDidMount() {
        getSplitClient().then((response) => {
            this.setState({client: response}, () => {
                this.state.client.on(this.state.client.Event.SDK_READY, () => this.setState({ isReady: true }));
            });
        });
    }

    componentWillUnmount() {
        const { client } = this.state;
        client.destroy();
    }

    render() {
        const { client, isReady } = this.state;
        const { children } = this.props;
        return (
            <SplitContext.Provider value={{client, isReady}}>
                {children}
            </SplitContext.Provider>
        );
    }
}

export default SplitProvider;