import styled from 'styled-components';
import { LogoAlignment } from '../../../constants/default-theme';

const getLogoAlignmentForFlex = alignment => {
  switch (alignment) {
    case LogoAlignment.START:
      return 'flex-start';
    case LogoAlignment.CENTER:
      return 'center';
    case LogoAlignment.END:
      return 'flex-end';
    default:
      return undefined;
  }
};

export const StyledHeader = styled.header`
  background: ${props => props.theme.header.bg_colour};
`;

export const StyledNav = styled.nav`
  justify-content: ${props => {
    if (props.theme?.header?.logo_alignment) {
      return getLogoAlignmentForFlex(props.theme.header.logo_alignment);
    }

    if (!props.loggedInUser) {
      return 'center';
    }

    return 'space-between';
  }};
`;