import React, { Component } from 'react';
import { StyledLink } from '../../theming/themed-components';

class ShopComplete extends Component {
  render() {
    return (
      <section className="content-wrapper-animate">
        <div className="invite-friends__container">
          <h1 className="invite-friends__title">Purchase Complete</h1>
          <div className="invite-friends__information-section">
            <p className="information-description">Thanks for your order. We will drop you an email once it has been dispatched.</p>
            <p>
              <StyledLink to="/dashboard" class="btn">
                Continue to dashboard
              </StyledLink>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default ShopComplete;
