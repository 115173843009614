const envconfig = require('./default.json');
window.CONFIG = { ...window.CONFIG, ...JSON.parse(localStorage.getItem('userMetadata')) };
window.CONFIG.api_url = process.env.REACT_APP_API_URL;
window.CONFIG.webApiUrl = process.env.REACT_APP_WEB_API_URL;
window.CONFIG.public_api_url = process.env.REACT_APP_PUBLIC_API_URL;
window.CONFIG.env = process.env.REACT_APP_ENV;
window.CONFIG.split_api_key = process.env.REACT_APP_SPLIT_API_KEY;
window.CONFIG.order_service_api = process.env.REACT_APP_ORDER_SERVICE_URL;
window.CONFIG.partnerServiceApi = process.env.REACT_APP_PARTNER_SERVICE_URL;
window.CONFIG.reverse_logistics_api = process.env.REACT_APP_REVERSE_LOGISTICS_SERVICE_URL;
window.CONFIG.stripe_key = process.env.REACT_APP_STRIPE_KEY;
window.CONFIG.web_url = process.env.REACT_APP_WEB_URL;
window.CONFIG.address_lookup_api_key = process.env.REACT_APP_ADDRESS_LOOKUP_API_KEY;
window.CONFIG.supervised_test_url = process.env.REACT_APP_SUPERVISED_TEST_URL;
window.CONFIG.bayer_iframe_url = process.env.REACT_APP_BAYER_IFRAME_URL;
window.CONFIG.google_maps_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
window.CONFIG.omit_legacy_bioage_tests = process.env.REACT_APP_OMIT_LEGACY_BIOAGE_TESTS === 'true';
window.CONFIG.datadogRumApplicationId = process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID;
window.CONFIG.datadogRumClientToken = process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN;
window.CONFIG.datadogRumEnable = process.env.REACT_APP_DATADOG_RUM_ENABLE;
window.CONFIG.storeFrontUrl = process.env.REACT_APP_STOREFRONT_URL;
window.CONFIG.testResultServiceUrl = process.env.REACT_APP_TEST_RESULT_SERVICE_URL;
window.CONFIG.productServiceUrl = process.env.REACT_APP_PRODUCT_SERVICE_URL;
window.CONFIG.appVersion = process.env.REACT_APP_VERSION;

const urlParams = new URLSearchParams(window.location.search);
const partnerCode = urlParams.get('partnerCode');
// Special case for bayer where we load their anylatics
window.CONFIG.segment_id = (partnerCode === 'chr' && process.env.REACT_APP_ENV === 'production') ? 
                                process.env.REACT_APP_SEGMENT_BAYER_ID : process.env.REACT_APP_SEGMENT_ID;

const laravelConfig = window.CONFIG || {};

const config = Object.assign({}, envconfig, { app: Object.assign({}, envconfig.app, laravelConfig) });

export default config;
