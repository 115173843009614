import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from  'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Overview from './overview';
import Science from './science';
import OverviewBiologicalAge from './overviewBiologicalAge';
import OverviewStress from './overviewStress';
import OverviewMetabolicStatus from './overviewMetabolicStatus';
import OverviewCardiovascularFitness from './overviewCardiovascularFitness';
import OverviewAlcoholConsumption from './overviewAlcoholConsumption';
import OverviewSmokeExposure from './overviewSmokeExposure';
import FAQ from './faq';

import Loader from '../../../components/common/loader';
import Disclaimer from '../../../components/common/disclaimer';
import SubMenuBar from '../../../components/common/subMenuBar';
import withError from '../../../components/common/withError';

import SplitConsumer from '../../../components/context/splitConsumer';

import { getIndicator } from '../../../services/you/actions';
import { indicatorSelector, youLoadingSelector, youLinksSelector } from '../../../services/you/selectors';

const slugComponentMapping = {
    'biological-age': {component: OverviewBiologicalAge},
    'stress': {component: OverviewStress},
    'metabolic-status': {component: OverviewMetabolicStatus, splitKey: 'metabolic_page_v2'},
    'cardiovascular-fitness': {component: OverviewCardiovascularFitness},
    'alcohol-consumption': {component: OverviewAlcoholConsumption, splitKey: 'alcohol_consumption_page_v2'},
    'smoke-exposure': {component: OverviewSmokeExposure, splitKey: 'smoke_exposure_page_v2'},
};

const ratingText = {
    good: 'GOOD',
    normal: 'NORMAL',
    bad: 'NEEDS IMPROVEMENT'
};

export class Indicator extends Component {

    componentDidMount() {
        const { location, match: {params}, client, fetchIndicator } = this.props;
        const search = qs.parse(location.search);

        const id = search && search.previous_test;
        const splitKey = slugComponentMapping[params.slug] && slugComponentMapping[params.slug].splitKey;
        const paramsObject = {};

        if(id) {
            paramsObject['test_user_test'] = id;
        }

        if(splitKey && client) {
            paramsObject[splitKey] = client.getTreatment(splitKey);
        }

        fetchIndicator(params.slug, paramsObject);
    }

    render() {
        const { breakpoint, testUser, match, isLoading, results, links, selectedTab } = this.props;
        const { params: {slug} } = match;

        if(isLoading) {
            return <Loader />;
        }

        if(!(results && Object.keys(results))) {
            return null;
        }

        const { heroSectionInfo, relatedArticles, moreInfo, scientificSteps, howWorks, chromosomeGraph,
            latestTest, currentTestDate } = results;

        const isTinySmallDevice = breakpoint === 'tinySmall';

        const OverviewComponentTab = props => {
            const overviewSection = slugComponentMapping[slug];
            const OverviewComponent = (overviewSection && overviewSection.component) ? overviewSection.component : Overview;

            if(overviewSection && overviewSection.splitKey) {
                return (
                    <SplitConsumer name={overviewSection.splitKey}>
                        {(value) => value == 'on' ?
                            <OverviewComponent
                                {...props}
                                {...results}
                                breakpoint={breakpoint}
                                isTinySmallDevice={isTinySmallDevice}
                                ratingText={ratingText}
                                resultUnit={heroSectionInfo.resultUnit}
                                status={heroSectionInfo.resultStatus}
                            /> :
                            <Overview
                                {...props}
                                {...results}
                                breakpoint={breakpoint}
                                isTinySmallDevice={isTinySmallDevice}
                                ratingText={ratingText}
                                resultUnit={heroSectionInfo.resultUnit}
                                selectedTab={selectedTab}
                                status={heroSectionInfo.resultStatus}
                            />
                        }
                    </SplitConsumer>
                );
            }


            return (
                <OverviewComponent
                    {...props}
                    {...results}
                    breakpoint={breakpoint}
                    isTinySmallDevice={isTinySmallDevice}
                    ratingText={ratingText}
                    resultUnit={heroSectionInfo.resultUnit}
                    status={heroSectionInfo.resultStatus}
                />
            );
        };

        const ScienceTab = () =>
            <Science
                breakpoint={breakpoint}
                chromosomeGraph={chromosomeGraph}
                howWorks={howWorks}
                relatedArticles={relatedArticles}
                scientificSteps={scientificSteps}
                slug={slug}
                testUser={testUser}
            />;

        const FAQPage = () => <FAQ accordion={moreInfo} />;

        return (
            <section className="content-wrapper-animate">
                <SubMenuBar
                    currentTestDate={currentTestDate}
                    hideMenuBar={true}
                    internalLinks={links.indicatorLinks}
                    navigation={links.youPageLink}
                    selectedTab={selectedTab}
                    show={true}
                    showPrevious={!latestTest}
                />
                {
                    heroSectionInfo ?
                        <Helmet>
                            <title>{`${heroSectionInfo.title} - Hurdle`}</title>
                        </Helmet> : ''
                }
                <section>
                    <Switch>
                        <Route path={`${this.props.match.url}/science`} render={ScienceTab} />
                        <Route path={`${this.props.match.url}/faq`} render={FAQPage} />
                        <Route path={this.props.match.url} render={OverviewComponentTab} />
                    </Switch>
                </section>
                <Disclaimer />
            </section>
        );
    }
}

Indicator.defaultProps = {
    selectedTab: 'overview',
    breakpoint: 'large',
};

Indicator.propTypes = {
    history: PropTypes.object.isRequired,
    breakpoint: PropTypes.string,
    slug: PropTypes.string,
    selectedTab: PropTypes.string,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    results: indicatorSelector(state),
    isLoading: youLoadingSelector(state, 'indicator'),
    links: youLinksSelector(state, 'indicator'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchIndicator: (slug, params) => dispatch(getIndicator(slug, params)),
});

const enhance = compose(
    withError({reducer: 'you', page: 'indicator'}),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
);

export default enhance(Indicator);
