import React from 'react';
import utils from '../../components/helpers/utils';
import { StyledButton } from '../../theming/themed-components';

export const AntiSpike = ({ history }) => {
  const handleEnquire = () => {
    utils.track('Enquire Anti-Spike in app');
    window.open('https://share.hsforms.com/1bWSJsLxdTlCrQHsj8Oa4KA3pfk8', '_blank');
  };

  return (
    <section className="test-dashboard-section content-wrapper-animate">
      <div className="switch-menu">
        <div className="switch-option" onClick={() => history.push('/dashboard/covid')}>
          Summary
        </div>
        <div className="switch-option active">Anti-Spike</div>
      </div>
      <div className="test-dashboard-section__section_content">
        <div className="main-container dbl-full-width">
          <div className="row-container single">
            <div className="test-dashboard-section__container small">
              <div className="content-container no-height">
                <div className="section-content marketing">
                  <div className="section-title">COVID-19 Anti-Spike Immunity Test</div>
                  <p>Find out what level of immunity you have to COVID-19 after you’ve been vaccinated or infected</p>
                  <ul className="ticks">
                    <li>Get peace of mind you’re protected from the COVID-19 virus</li>
                    <li>Find out how you’re responding to a vaccine or if you’ve developed natural immunity from previous infection</li>
                    <li>Understand how your immunity is changing over time to protect yourself and your loved ones</li>
                    <li>Receive any validation and certification you may need to show you have COVID-19 immunity</li>
                  </ul>
                  <StyledButton className="btn btn--full-width" type="button" title="Enquire Antispike" onClick={handleEnquire}>
                    ENQUIRY
                  </StyledButton>
                </div>
              </div>
            </div>
            <div className="test-dashboard-section__container small">
              <div className="content-container no-height">
                <div className="section-content marketing">
                  <div className="section-title">What will you get?</div>
                  <ul className="ticks">
                    <li>Test for COVID-19 antibodies with a simple blood test, performed by a trained professional</li>
                    <li>Check all your test results quickly and securely via the easy-to-use Hurdle online dashboard</li>
                    <li>Get a highly accurate picture of your immunity to COVID-19, whether it’s natural or via vaccination</li>
                    <li>Share your results with your loved ones or download authenticated certificates for travel or work</li>
                  </ul>
                  <StyledButton className="btn btn--full-width" type="button" title="Enquire Antispike" onClick={handleEnquire}>
                    ENQUIRY
                  </StyledButton>
                </div>
              </div>
            </div>
            <div className="test-dashboard-section__container small">
              <div className="content-container no-height">
                <div className="section-content marketing">
                  <div className="section-title">This test can measure how your body is responding to vaccination.</div>
                  <p>
                    Our test measures the neutralising antibodies in your blood that prevent the virus spike proteins attaching to your
                    cells. To do this we need only a small blood sample, which is then analysed using gold standard methods in our ISO
                    accredited labs. Your results are then delivered straight to you online, quickly and securely. You can take more than
                    one test, so you can track the progress of your immunity over time - before, during and after any infection or
                    vaccination programme.
                  </p>
                  <StyledButton className="btn btn--full-width" type="button" title="Enquire Antispike" onClick={handleEnquire}>
                    ENQUIRY
                  </StyledButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
