import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Cards from './cards';
import Comparisons from './comparisons';

import Loader from '../../../components/common/loader';
import withError from '../../../components/common/withError';

import { getCompareResults } from '../../../services/you/actions';
import { compareSelector, youLoadingSelector } from '../../../services/you/selectors';

class Compare extends Component {

    componentDidMount() {
        this.props.fetchCompare();
    }

    render() {
        const { match, isLoading, results, openChat } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        const { friends, categories, previousTests } = results;

        const CardsPage = (props) => (
            <Cards
                {...props}
                categories={categories}
                friends={friends}
                previousTests={previousTests}
                openChat={openChat}
            />
        );

        const ComparisonsPage = (props) => (
            <Comparisons {...props} />
        );

        return (
            <div>
                <Switch>
                    <Route
                        path={`${match.url}/:section/:id`}
                        render={ComparisonsPage}
                    />
                    <Route
                        path={match.url}
                        render={CardsPage}
                    />
                </Switch>
            </div>
        );
    }
}

Compare.propTypes = {
    match: PropTypes.object.isRequired,
    fetchCompare: PropTypes.func,
    results: PropTypes.object,
    isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    results: compareSelector(state),
    isLoading: youLoadingSelector(state, 'compare'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompare: () => dispatch(getCompareResults()),
});

export default withError({reducer: 'you', page: 'compare'})(connect(mapStateToProps, mapDispatchToProps)(Compare));
