import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';

import BreakpointContext from './context/breakpoint';

import ScrollToTop from './common/scrollToTop';
import Footer from './common/footer/footer';
import Header from './common/header';
import Chat from './common/chat';
import CookieBar from './common/cookieBar';

import LoggedInRoutes from './loggedInRoutes';
import { withTheme } from 'styled-components';
import { StyledBackground } from '../theming/themed-components';
import { withRouter } from 'react-router-dom';
import { useDisplayControl } from './hooks/useDisplayControl';
import { useBreakpoint } from './hooks/useBreakpoint';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../services/you/actions';
import { userDetailsSelector } from '../services/you/selectors';

const LoggedInApp = props => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailsSelector);

  const { user, test_user, requires_clinic_sharing_permission } = userDetails || {};

  const { shouldHideHeader, shouldHideFooter, className } = useDisplayControl({
    className: 'loggedin-app-page-container',
    isLoggedIn: true,
  });

  const breakpoint = useBreakpoint();

  const showTermsOverlay = useMemo(() => {
    if (user) {
      return !user.accepted_dna_services || !user.accepted_privacy || !user.accepted_terms || requires_clinic_sharing_permission;
    }

    return false;
  }, [user, requires_clinic_sharing_permission]);

  const [isOpenChat, setIsOpenChat] = useState(false);
  const [message, setMessage] = useState('');
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    axios
      .get('user/subscription')
      .then(response => setSubscription(response?.data))
      .catch(error => {
        if (![404, 400].includes(error?.error?.http_code)) {
          datadogRum.addError(error);
        }
      });

    dispatch(getUserDetails());
  }, []);

  const openChat = msg => {
    setIsOpenChat(!isOpenChat);

    setMessage(msg);
  };

  // Must be all wrapped in router
  return (
    <div className={className}>
      <BreakpointContext.Provider value={breakpoint}>
        <Chat openChatValue={isOpenChat} message={message} openChat={openChat} />
        <ScrollToTop>
          {!shouldHideHeader && <Header loggedInUser={user} breakpoint={breakpoint} theme={props.theme} />}
          <StyledBackground className="loggedin-app-page-wrapper">
            <LoggedInRoutes
              breakpoint={breakpoint}
              message={message}
              openChat={openChat}
              showTermsOverlay={showTermsOverlay}
              subscription={subscription}
              testUser={test_user || {}}
              user={user}
            />
          </StyledBackground>
          {!shouldHideFooter && <Footer theme={props.theme} loggedInUser={user} breakpoint={breakpoint} />}
          <CookieBar />
        </ScrollToTop>
      </BreakpointContext.Provider>
    </div>
  );
};

LoggedInApp.propTypes = {
  theme: PropTypes.object.isRequired,
};

export { LoggedInApp as __TEST_LoggedInApp };
export default withTheme(withRouter(LoggedInApp));
