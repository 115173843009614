import React from 'react';
import PropTypes from 'prop-types';
import {PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer} from 'recharts';
import { getStoreLinkToEpigeneticsProduct } from '../../components/common/storefront';

const NextTest = ({nextTestDue, wrapperClass, ctaClass, barColor}) => {
  if (!nextTestDue || !Object.keys(nextTestDue).length) {
    return null;
  }

  const {title, sub_title, percentage, months, months_units, cta_title} = nextTestDue;

  return (
    <div className={`dashboard-section__next-test no-hover ${wrapperClass}`}>
      <div className="next-test-section">
        <div className="gauge-title-container">
          <div className="gauge-chart-section">
            <div className="gauge-placeholder">
              <p>{months}</p>
              <p>{months_units}</p>
            </div>
            <ResponsiveContainer>
              <RadialBarChart
                innerRadius={35}
                outerRadius={42}
                barSize={7}
                data={[{percent: percentage}]}
                startAngle={90}
                endAngle={-270}
              >
                <PolarAngleAxis
                  type="number"
                  domain={[0, 100]}
                  angleAxisId={0}
                  tick={false}
                />
                <RadialBar
                  background
                  dataKey="percent"
                  isAnimationActive={false}
                  cornerRadius={percentage === 100 ? 0 : 50}
                  fill={barColor}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
          <div className="test-section">
            <h5 className="test-title">{title}</h5>
            <p className="test-subtitle">{sub_title}</p>
          </div>
        </div>
        {
          (cta_title) && <a href={getStoreLinkToEpigeneticsProduct()} className={`btn ${ctaClass}`} rel="noopener noreferrer">{cta_title}</a>
        }
      </div>
    </div>
  );
};

NextTest.defaultProps = {
  nextTestDue: {},
  wrapperClass: '',
  ctaClass: '',
  barColor: '#51beff'
};

NextTest.propTypes = {
  nextTestDue: PropTypes.object,
  wrapperClass: PropTypes.string,
  ctaClass: PropTypes.string,
  barColor: PropTypes.string,
};

export default NextTest;
