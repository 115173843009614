import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import update from 'immutability-helper';
import { Tooltip } from 'react-tippy';

import Loader from '../loader';
import utils from '../../helpers/utils';
import PercentageBar from '../percentageBar';
import Label from './components/label';
import IntroText from './components/introText';
import Text from './components/text';
import Textarea from './components/textarea';
import MultipleChoice from './components/multipleChoice';
import Checkbox from './components/checkbox';
import Select from './components/select';
import TimeField from './components/timeField';
import Grid from './components/grid';
import CheckboxGrid from './components/checkboxGrid';
import Scale from './components/scale';
import CustomDate from '../date';

//Custom ui elements
import Length from './components/custom/length';
import Weight from './components/custom/weight';
import Country from './components/custom/country';
import Occupation from './components/custom/occupation';
import PreviousAddress from './components/custom/previousAddress';
import Age from './components/custom/age';
import Year from './components/custom/year';
import Currency from './components/custom/currency';
import HoursMinutes from './components/custom/hoursMinutes';
import Badge from '../badge';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';
import { StyledButton, StyledHyperlinkWrapper } from '../../../theming/themed-components';
import { withTheme } from 'styled-components';

const uiComponents = {
  length: Length,
  weight: Weight,
  country: Country,
  occupation: Occupation,
  previousaddress: PreviousAddress,
  age: Age,
  year: Year,
  currency: Currency,
  hoursminutes: HoursMinutes,
};

const greetingMessages = ['Congratulations', 'Great work', 'Nice effort', 'Well done'];

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.match.currentPage ? this.props.match.currentPage : 0,
      submitedCurrentPageWithErrors: false,
      totalPages: 0,
      previousPage: null, //keep tracking as if skipping may not just be -1
      questionnaire: null,
      uiSchema: null, //May contain extra information describing how each field is rendered
      complete: false,
      saving: false,
      fields: {},
      fieldErrors: {}, //Keep track of which fields are valid or not
      error: null,
      metricOrImperial: 'metric',
      badge: null,
      comparison: null,
      answeredPages: props.answeredPages,
    };

    this.handelSubmitForm = this.handelSubmitForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handelCustomInputChange = this.handelCustomInputChange.bind(this);
    this.handelGoBack = this.handelGoBack.bind(this);
    this.handelEditComplete = this.handelEditComplete.bind(this);
    this.onDateChange = this.onDateChange.bind(this);

    this.formRef = React.createRef();
    this.questionRowRefs = [];
    this.congratulationsRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props;

    //Going to be null on first load
    if (id) {
      this.loadQuestionnaire(id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.loadQuestionnaire(this.props.id);
    }
  }

  getFieldValue(id) {
    //DO we have a value set?
    if (this.state.fields.hasOwnProperty(id)) {
      return this.state.fields[id];
    }

    if (
      this.state.uiSchema &&
      this.state.uiSchema[id] &&
      this.state.uiSchema[id]['default'] &&
      this.state.uiSchema[id].hasOwnProperty('default')
    ) {
      return this.state.uiSchema[id]['default'];
    }

    return '';
  }

  loadQuestionnaire(id) {
    //So we get loader
    this.setState({ questionnaire: null, error: null, badge: null, comparison: null });

    axios
      .get('questionnaires/section/' + id)
      .then(response => {
        const fields = response.data.answers ? response.data.answers : {};
        const currentPage = response.data.current_page;
        const previousPage = currentPage > 0 ? currentPage - 1 : null;

        /*
                For now make every question required....
            */
        const jsonSchema = response.data.json_schema;
        for (const i in jsonSchema) {
          for (const x in jsonSchema[i].items) {
            //ignore birth weight for now...
            if (jsonSchema[i].items[x].id !== 86203041) {
              jsonSchema[i].items[x].isRequired = true;
            }

            if (jsonSchema[i].items[x].type == 'INTRO_TEXT') {
              jsonSchema[i].items[x].isRequired = false;
            }
          }
        }

        this.setState(
          (prev, props) => ({
            currentPage: currentPage,
            totalPages: response.data.total_pages,
            previousPage: previousPage,
            questionnaire: response.data.json_schema,
            uiSchema: response.data.ui_schema,
            complete: response.data.complete,
            fields: fields,
            badge: response.data.badge,
            comparison: response.data.comparison,
            answeredPages: props.answeredPages + currentPage,
          }),
          () => {
            if (this.formRef && this.formRef.current) {
              utils.scrollToHash(this.formRef.current, 200);
            }
          },
        );
      })
      .catch(error => {
        this.setState({
          error: utils.getFirstApiError(error),
        });
      });
  }

  handelEditComplete(event) {
    event.preventDefault();

    this.setState({
      complete: false,
      currentPage: 0,
      previousPage: null,
    });
  }

  handleInputChange(event, index) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    //Checkboxes work a bit differently as they are arrays..
    if (target.type === 'checkbox') {
      const checkboxName = target.checkboxName; //fake attrib we have added
      const checkboxValue = target.value;
      value = this.state.fields[name] ? this.state.fields[name] : {};

      if (target.checked) {
        value[checkboxName] = checkboxValue;
      } else {
        delete value[checkboxName];
      }
    } else {
      value.trim();
    }

    this.setState(update(this.state, { fields: { [name]: { $set: value } } }), () => {
      if (this.state.submitedCurrentPageWithErrors) {
        this.validateQuestion(name);
      }
    });
  }

  onDateChange(name, value) {
    this.setState(update(this.state, { fields: { [name]: { $set: value } } }));
  }

  /*
        Handel a custom input change when it might be an array of values etc
    */
  handelCustomInputChange(key, value, options) {
    const fieldsToUpdate = { fields: { [key]: { $set: value } } };
    if (options) {
      fieldsToUpdate.fields[key + '_options'] = { $set: options };
    }

    if (options && options.metricOrImperial) {
      fieldsToUpdate.metricOrImperial = { $set: options.metricOrImperial };
    }

    //console.log('Option:', options);
    this.setState(update(this.state, fieldsToUpdate), () => {
      if (this.state.submitedCurrentPageWithErrors) {
        this.validateQuestion(key);
      }
    });
  }

  validateQuestion(name, currentItem) {
    const { questionnaire, currentPage, fields, fieldErrors } = this.state;

    //If current item is not provided we need to find it
    if (!currentItem) {
      for (const i in questionnaire[currentPage].items) {
        if (questionnaire[currentPage].items[i].id == name) {
          currentItem = questionnaire[currentPage].items[i];
          break;
        }
      }
    }

    if (!currentItem) {
      return false;
    }

    let isValid = true;
    fieldErrors[name] = null;

    //console.log(currentItem, fields[currentItem.id])

    //Cant validate checkboxes as quite often they dont quire any answere
    if (currentItem.isRequired && !(currentItem.type === 'CHECKBOX')) {
      if (!fields.hasOwnProperty(currentItem.id) || fields[currentItem.id] === '') {
        //console.log(currentItem, fields[currentItem.id])
        fieldErrors[name] = { hasError: true, message: 'This field is required' };
        isValid = false;
      } else if (currentItem.type === 'GRID') {
        const values = Object.values(fields[currentItem.id]).filter(value => !value);
        if (values.length) {
          fieldErrors[name] = { hasError: true, message: 'All the fields in this grid are required' };
          isValid = false;
        }
      } else if (currentItem.type === 'CHECKBOX_GRID') {
        if (Object.keys(fields[currentItem.id]) && Object.keys(fields[currentItem.id]).length !== currentItem.rows.length) {
          fieldErrors[name] = { hasError: true, message: 'All the fields in this grid are required' };
          isValid = false;
        }
      }
    }

    this.setState({ fieldErrors: fieldErrors });

    return isValid;
  }

  validatePage() {
    const { questionnaire, currentPage } = this.state;

    let isValid = true;
    let firstErrorFound = false;
    let i,
      currentItem = null;

    if (questionnaire[currentPage]) {
      //Loop all questions for this page and validate
      for (i in questionnaire[currentPage].items) {
        currentItem = questionnaire[currentPage].items[i];

        //If its hidden dont validate..
        if (!this.shouldHideItem(currentItem)) {
          if (!this.validateQuestion(currentItem.id, currentItem)) {
            isValid = false;

            if (!firstErrorFound) {
              utils.scrollToHash(this.questionRowRefs[currentItem.id], -400);
              firstErrorFound = true;
            }
          }
        }
      }
    }

    return isValid;
  }

  handelSubmitForm(e) {
    e.preventDefault();
    const { slug, id } = this.props;

    if (!this.validatePage()) {
      this.setState({ submitedCurrentPageWithErrors: true });
      return false;
    }

    this.setState({ saving: true });
    //console.log('saving', this.state.fields)

    const isComplete = this.state.currentPage + 1 >= this.state.questionnaire.length;
    //console.log(this.state.fields)
    //Save Form
    axios
      .post('questionnaires/' + slug + '/' + id, {
        answers: JSON.stringify(this.state.fields),
        current_page: this.state.currentPage + 1, //+1 so when they reload will be on next page
        is_complete: isComplete,
      })
      .then(response => {
        this.setState((prev, props) => ({ saving: false }));

        //Have we hit the last page?
        if (isComplete) {
          this.setState((prev, props) => ({
            complete: true,
            badge: response.data.badge,
            comparison: response.data.comparison,
            answeredPages: props.answeredPages + this.state.currentPage + 1,
          }));
          utils.scrollToHash(this.congratulationsRef.current, 100);
        } else {
          const previousPageIndex = this.state.currentPage;
          const nextPageIndex = this.getNextPage();

          this.setState((prevState, props) => ({
            currentPage: nextPageIndex,
            previousPage: previousPageIndex,
            submitedCurrentPageWithErrors: false,
            answeredPages: props.answeredPages + nextPageIndex,
          }));

          this.props.history.push(`/dashboard/onboarding/${id}/${nextPageIndex}${getPartnerQueryParams()}`);

          //Scroll to top
          if (this.formRef && this.formRef.current) {
            utils.scrollToHash(this.formRef.current, 200);
          }
        }
      });
  }

  handelGoBack(e) {
    e.preventDefault();
    const { id } = this.props;

    if (this.state.previousPage !== null) {
      this.setState(
        prevState => ({
          currentPage: prevState.previousPage < prevState.currentPage ? prevState.previousPage : prevState.currentPage - 1,
          previousPage: prevState.currentPage,
          submitedCurrentPageWithErrors: false,
        }),
        () => {
          this.props.history.push(`/dashboard/onboarding/${id}/${this.state.currentPage}${getPartnerQueryParams()}`);
          utils.scrollToHash(this.formRef.current, 200);
        },
      );
    }
  }

  //figure out if we need to skip to another page based on questions
  getNextPage() {
    const { questionnaire, fields, currentPage } = this.state;

    let skipToPageId = null;

    //loop over current answers if see if any need skipping
    for (const fieldId in fields) {
      if (questionnaire[currentPage]) {
        for (const x in questionnaire[currentPage].items) {
          const currentItem = questionnaire[currentPage].items[x];
          //only choces can have skip to
          if (currentItem.id == fieldId && currentItem.choices) {
            for (const choiceX in currentItem.choices) {
              if (currentItem.choices[choiceX].value == fields[fieldId] && currentItem.choices[choiceX].gotoId) {
                skipToPageId = currentItem.choices[choiceX].gotoId;
                //Keep going (no break) in case more that 1 - use last skip
              }
            }
          }
        }
      }
    }

    //If skipping find the matching page id
    if (skipToPageId) {
      for (const pageX in questionnaire) {
        if (questionnaire[pageX].id == skipToPageId) {
          return parseInt(pageX, 10);
        }
      }
    }

    //not skipping... just give next page
    return currentPage + 1;
  }

  /*
        Should this item be hidden?
    */
  shouldHideItem(item) {
    if (this.state.uiSchema && this.state.uiSchema[item.id] && this.state.uiSchema[item.id].dependencies) {
      let shouldHide = true;
      const dependencies = this.state.uiSchema[item.id].dependencies;

      for (const dependencieId in dependencies) {
        let values = this.getFieldValue(dependencieId);
        //may be object or single value..
        //console.log(values.length)
        if (values !== '' && !(values !== null && typeof values === 'object')) {
          values = { values: values };
        }

        for (const i in values) {
          //console.log(values[i])

          if (values[i] && values[i] instanceof String) {
            //Having a quote in crashes the eval..
            const thisValue = values[i].replace('\'', '');

            if (eval('\'' + thisValue + '\' ' + dependencies[dependencieId].condition)) {
              shouldHide = false;
            }
          }
        }
      }

      return shouldHide;
    }

    return false;
  }

  renderFormItem(item) {
    const value = this.getFieldValue(item.id);

    //Allow to save custom options (ie unit used) with a value
    let valueOptions = this.state.fields[item.id + '_options'] ? this.state.fields[item.id + '_options'] : {};
    //Merge in any other options from the ui schema
    if (this.state.uiSchema && this.state.uiSchema[item.id]) {
      valueOptions = { ...valueOptions, ...this.state.uiSchema[item.id] };
    }
    if (!valueOptions.metricOrImperial) {
      valueOptions.metricOrImperial = this.state.metricOrImperial;
    }

    //Does it have a custom ui schema render?
    //console.log(this.state.uiSchema)
    if (
      this.state.uiSchema &&
      this.state.uiSchema[item.id] &&
      this.state.uiSchema[item.id]['ui:widget'] &&
      uiComponents[this.state.uiSchema[item.id]['ui:widget']]
    ) {
      const FieldType = uiComponents[this.state.uiSchema[item.id]['ui:widget']];

      return <FieldType {...item} value={value} options={valueOptions} onChange={this.handelCustomInputChange} />;
    } else if (item.type === 'INTRO_TEXT') {
      return <IntroText {...item} />;
    } else if (item.type === 'TEXT') {
      return <Text {...item} value={value} options={valueOptions} onChange={this.handleInputChange} />;
    } else if (item.type === 'PARAGRAPH_TEXT') {
      return <Textarea {...item} value={value} options={valueOptions} onChange={this.handleInputChange} />;
    } else if (item.type === 'MULTIPLE_CHOICE') {
      return <MultipleChoice {...item} value={value} options={valueOptions} onChange={this.handleInputChange} theme={this.props.theme} />;
    } else if (item.type === 'CHECKBOX') {
      return <Checkbox {...item} value={value} options={valueOptions} onChange={this.handleInputChange} />;
    } else if (item.type === 'LIST') {
      return <Select {...item} value={value} options={valueOptions} onChange={this.handleInputChange} />;
    } else if (item.type === 'DATE') {
      return <CustomDate {...item} value={value} options={valueOptions} onChange={this.onDateChange} />;
    } else if (item.type === 'TIME') {
      return <TimeField {...item} value={value} options={valueOptions} onChange={this.handelCustomInputChange} />;
    } else if (item.type === 'GRID') {
      return <Grid {...item} value={value} options={valueOptions} onChange={this.handelCustomInputChange} />;
    } else if (item.type === 'CHECKBOX_GRID') {
      return <CheckboxGrid {...item} value={value} options={valueOptions} onChange={this.handelCustomInputChange} />;
    } else if (item.type === 'SCALE') {
      return <Scale {...item} value={value} options={valueOptions} onChange={this.handleInputChange} />;
    }
  }

  renderToolTip(htmlContent, children) {
    return (
      <Tooltip arrow={true} arrowSize="small" html={htmlContent} open={true} position="top" theme="light">
        {children}
      </Tooltip>
    );
  }

  renderComparisonSections() {
    const { complete, comparison } = this.state;

    if (!(complete && comparison)) {
      return null;
    }

    return (
      <div className="questionnaire__comparison-wrapper">
        <div className="questionnaire__comparison-container">
          <h3 className="section-title">How you compare to other Hurdle members:</h3>
          {comparison.map((item, index) => {
            const { title, range, you, x } = item;
            return (
              <div className="questionnaire__comparison-section" key={index}>
                <h5 className="comparison-title">{title}</h5>
                <div className="questionnaire__comparison-range">
                  <div
                    className="highlighted-range"
                    style={{ left: `${range.min_percent}%`, width: `${range.max_percent - range.min_percent}%` }}
                  />
                  <div className="result-section" style={{ left: `${you.percent}%` }}>
                    {this.renderToolTip(<div className="comparison-tooltip">{you.label}</div>, <div className="comparison-result" />)}
                  </div>
                </div>
                <div className="comparison-labels">
                  <p>{x.min_label}</p>
                  <p>{x.max_label}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="questionnaire__button-container">
          <a href={`/dashboard/onboarding${getPartnerQueryParams()}`} className="btn continue-btn">
            continue
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { questionnaire, currentPage, answeredPages, complete, saving, error, fieldErrors, badge } = this.state;
    const { totalPages } = this.props;

    if (questionnaire === null && badge === null) {
      if (error) {
        return <p className="questionnaire-error">{error}</p>;
      }
      return <Loader />;
    }

    const buttonHtml = saving ? (
      <StyledButton className="btn btn--disabled">
        Saving...
        <i className="icon-right-open" />
      </StyledButton>
    ) : (
      <StyledButton className="btn">
        Next
        <i className="icon-right-open" />
      </StyledButton>
    );

    let percentageComplete = 0;
    if (totalPages > 0) percentageComplete = (answeredPages / totalPages) * 100;

    const randomNumber = Math.floor(Math.random() * 4);

    return (
      <div>
        <PercentageBar percentage={percentageComplete} className="questionnaire__percentage-bar" />

        <div>
          {complete ? (
            <div className="questionnaire-greetings" ref={this.congratulationsRef}>
              <h3 className="greetings-title">{greetingMessages[randomNumber]}!</h3>

              {badge ? (
                <div>
                  <p>You won a badge:</p>
                  <Badge text="" imageSource={badge.image_url} title={badge.title} />
                </div>
              ) : (
                ''
              )}
              <StyledHyperlinkWrapper
                href={`/dashboard/onboarding${getPartnerQueryParams()}`}
                className="btn greetings-next-section-button continue-btn"
              >
                continue
              </StyledHyperlinkWrapper>
            </div>
          ) : (
            <div className="questionnaire__container">
              <form className="form questionnaire-form" onSubmit={e => this.handelSubmitForm(e)} ref={this.formRef}>
                {questionnaire[currentPage] &&
                  questionnaire[currentPage].items &&
                  questionnaire[currentPage].items.map(item => {
                    let rowClassName = 'questionnaire-form__row';
                    let errorMessage = '';
                    if (fieldErrors[item.id] && fieldErrors[item.id].hasError) {
                      rowClassName += ' questionnaire-form__row--has-error';
                      errorMessage = fieldErrors[item.id].message;
                    }

                    //Check if we should render this item?
                    if (this.shouldHideItem(item)) {
                      return '';
                    }

                    return (
                      <div key={item.id} className={rowClassName} ref={ref => (this.questionRowRefs[item.id] = ref)}>
                        {item.type !== 'INTRO_TEXT' && <Label {...item} />}
                        <div className="questionnaire-form__row__answer">{this.renderFormItem(item)}</div>
                        <div className="form__error">{errorMessage}</div>
                      </div>
                    );
                  })}

                <div className="questionnaire__button-link-container">
                  {currentPage > 0 ? (
                    <a href="" className="questionnaire__go-back" onClick={this.handelGoBack}>
                      <i className="icon-left-open-big" />
                      Back
                    </a>
                  ) : (
                    ''
                  )}
                  <div className="button-section">{buttonHtml}</div>
                </div>
              </form>
            </div>
          )}
        </div>
        {this.renderComparisonSections()}
      </div>
    );
  }
}

Questionnaire.defaultProps = {
  answeredPages: 0,
  totalPages: 0,
};

Questionnaire.propTypes = {
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
  answeredPages: PropTypes.number,
  totalPages: PropTypes.number,
};

export default withRouter(withTheme(Questionnaire));
