import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import BreakpointContext from '../context/breakpoint';

// Positioning is a mix of JS and CSS and complex even otherwise. Tread carefully here. You have been warned! :)
const LABEL_POSITIONS = {
    head: {
        align: 'left',
        slant: 'down',
        breakpoint: {
            large: {
                y: 50,
                width: 375,
            },
            medium: {
                y: 50,
                width: 375,
            },
            small: {
                y: 0,
                width: 275,
            },
            tiny: {
                y: 0,
                width: 275,
            },
        },
    },
    chest: {
        align: 'right',
        slant: 'down',
        breakpoint: {
            large: {
                y: 250,
                width: 360,
            },
            medium: {
                y: 250,
                width: 360,
            },
            small: {
                y: 0,
                width: 275,
            },
            tiny: {
                y: 0,
                width: 275,
            },
        },
    },
    abdomen: {
        align: 'left',
        slant: 'down',
        breakpoint: {
            large: {
                y: 450,
                width: 353,
            },
            medium: {
                y: 450,
                width: 353,
            },
            small: {
                y: 0,
                width: 275,
            },
            tiny: {
                y: 0,
                width: 275,
            },
        },
    },
};

class Body extends Component {
    render() {
        // Uncomment when BE is updated with new schema
        // const { results } = this.props;
        // Faking data till BE is ready
        const results = {
            data: [
                {
                    date: '2018-05-01',
                    status: 'current',
                    results: [
                        {
                            title: 'Air Pollution',
                            slug: 'air-pollution',
                            result: 11,
                            status: 'avg',
                            group: 'head',
                            position: 1,
                        },
                        {
                            title: 'Smoke Exposure',
                            slug: 'smoke-exposure',
                            result: 1,
                            status: 'bad',
                            group: 'head',
                            position: 2,
                        },
                        {
                            title: 'Biological Age',
                            slug: 'biological-age',
                            result: 12,
                            status: 'good',
                            group: 'chest',
                            position: 1,
                        },
                        {
                            title: 'Caffeine Consumption',
                            slug: 'caffeine',
                            result: 8,
                            status: 'good',
                            group: 'chest',
                            position: 2,
                        },
                        {
                            title: 'Body Composition',
                            slug: 'body-composition',
                            result: 8,
                            status: 'good',
                            group: 'abdomen',
                            position: 1,
                        },
                        {
                            title: 'Sleepigenetics',
                            slug: 'sleep',
                            result: 8,
                            status: 'avg',
                            group: 'abdomen',
                            position: 2,
                        },
                    ],
                },
                { date: '2019-05-01', status: 'future' },
            ],
        };

        if (!results.data || results.data.length < 1) {
            return (
                <div>
                    <p className="center">No results to show.</p>
                </div>
            );
        }

        // Build out an object containing results for each group which we can easily use later
        const groups = {};
        Object.keys(LABEL_POSITIONS).map(group => {
            groups[group] = results.data[0].results.filter(
                res => res.group === group
            );
        });

        return (
            <div>
                <div className="result-body">
                    {Object.keys(groups).map((groupName, index) => {
                        const group = groups[groupName];
                        const groupOptions = LABEL_POSITIONS[groupName];
                        const breakpoint = this.props.breakpoint;
                        const groupStyle = {
                            top: groupOptions.breakpoint[breakpoint].y + 'px',
                            width:
                                groupOptions.breakpoint[breakpoint].width +
                                'px',
                        };

                        return (
                            <div
                                className={
                                    'result-indicator ' +
                                    groupOptions.align +
                                    ' ' +
                                    groupName
                                }
                                key={index}
                                style={groupStyle}
                            >
                                {groupOptions.slant === 'down' &&
                                    groupOptions.align === 'left' && (
                                        <svg
                                            className="result-indicator__line"
                                            height={1 + group.length * 76.5}
                                            width="100%"
                                        >
                                            <line
                                                className="result-indicator__line-horozintal"
                                                x1={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.3
                                                }
                                                x2={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width
                                                }
                                                y1="0"
                                                y2="0"
                                            />
                                            <line
                                                className="result-indicator__line-slant"
                                                x1={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.3
                                                }
                                                x2={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.3
                                                }
                                                y1="0"
                                                y2={1 + group.length * 76.5}
                                            />
                                        </svg>
                                    )}
                                {groupOptions.slant == 'down' &&
                                    groupOptions.align == 'right' && (
                                        <svg
                                            className="result-indicator__line"
                                            height={1 + group.length * 76.5}
                                            width="100%"
                                        >
                                            <line
                                                className="result-indicator__line-horozintal"
                                                x1="0"
                                                x2={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.7
                                                }
                                                y1="0"
                                                y2="0"
                                            />
                                            <line
                                                className="result-indicator__line-slant"
                                                x1={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.7
                                                }
                                                x2={
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.7
                                                }
                                                y1="0"
                                                y2={1 + group.length * 76.5}
                                            />
                                        </svg>
                                    )}
                                {group.map((result, indexChild) => {
                                    return (
                                        <div
                                            className={`result-indicator__result ${
                                                groupOptions.align
                                            }`}
                                            key={indexChild}
                                            style={{
                                                top:
                                                    breakpoint === 'tiny' ||
                                                    breakpoint === 'small'
                                                        ? 0
                                                        : 16 +
                                                          (result.position === 1
                                                              ? 0
                                                              : (result.position -
                                                                    1) *
                                                                76.5) +
                                                          'px',
                                                width:
                                                    groupOptions.breakpoint[
                                                        breakpoint
                                                    ].width * 0.6,
                                            }}
                                        >
                                            <div
                                                className={`result-indicator__icon-container ${
                                                    result.status
                                                }`}
                                            >
                                                <div className="result-indicator__icon">
                                                    <img
                                                        alt=""
                                                        src={`/img/dashboard/results/icons/${
                                                            result.slug
                                                        }.png`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="result-indicator__title">
                                                <Link
                                                    className={`title ${
                                                        result.status
                                                    }`}
                                                    to={
                                                        '/dashboard/results/' +
                                                        result.slug
                                                    }
                                                >
                                                    {result.title}
                                                </Link>
                                            </div>
                                            <div className="result-indicator__link">
                                                <Link
                                                    to={
                                                        '/dashboard/results/' +
                                                        result.slug
                                                    }
                                                >
                                                    View Indicator
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>

                <div className="result-timeline">
                    {results.data.map((item, index) => {
                        let className =
                            'result-timeline__item result-timeline__item--' +
                            item.status;
                        if (index == 0)
                            className += ' result-timeline__item--first';
                        if (index == results.data.length - 1)
                            className += ' result-timeline__item--last';

                        let dateText = '';
                        if (item.status == 'previous') dateText = 'Previous: ';
                        if (item.status == 'current') dateText = 'Current: ';
                        if (item.status == 'future') dateText = 'Due: ';

                        return (
                            <div className={className} key={index}>
                                <div className="result-timeline__icon" />
                                <h5 className="result-timeline__title">
                                    {item.status == 'future'
                                        ? 'Next Test'
                                        : moment(item.date).format('YYYY')}
                                </h5>
                                <p className="result-timeline__copy">
                                    {dateText}{' '}
                                    <span>
                                        {moment(item.date).format(
                                            'Do MMM YYYY'
                                        )}
                                    </span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default props => (
    <BreakpointContext.Consumer>
        {breakpoint => {
            return <Body {...props} breakpoint={breakpoint} />;
        }}
    </BreakpointContext.Consumer>
);
