export const FEATURES = Object.freeze({
  COVID: 'covid-section',
  EPIGENETIC: 'epigenetic-section',
  GENETIC: 'genetic-section',
  WHOLE_GENOME: 'wholegenome-section',
  BLOOD: 'blood-section',
});

export const POST_RESULT_ACTION_FEATURES = Object.freeze({
  COMMENTARY: 'commentary',
  FAQS: 'faqs',
  RECOMMENDATIONS: 'recommendations',
  TELEHEALTH: 'telehealth',
  FEEDBACK: 'feedback',
});