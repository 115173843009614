import * as React from "react";

function SvgVan(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M23 46c12.703 0 23-10.297 23-23S35.703 0 23 0 0 10.297 0 23s10.297 23 23 23z"
                fill="currentColor"
            />
            <path
                fill="#fff"
                d="M17 13h19v16H17zM9 22h8v7H9zM16 17v4h-4.444L16 17z"
            />
            <circle cx={15} cy={29} r={2.5} fill="#fff" stroke="currentColor" />
            <circle cx={32} cy={29} r={2.5} fill="#fff" stroke="currentColor" />
        </svg>
    );
}

export default SvgVan;
