import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';

class CountryInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: '',
        };

        this.selectCountry = this.selectCountry.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        //Being retrieved by api call so may not be set in constructor above..
        if(props.value !== state.selected) {
            let selected = '';

            if(props.countries && props.countries.length) {
                const selectedCountry = props.countries.filter((item) => {
                    //Allow them to match on country code, title or phone
                    return item.country_code == props.value || item.dialing_code == props.value || item.title == props.value; 
                });
                selected = selectedCountry && selectedCountry[0] && selectedCountry[0].country_code;
                return {
                    selected: selected,
                };
            }
        }
        return null;
    }

    selectCountry(newSelected) {
        const { countries, onChange } = this.props;
        const selectedCountryItem = countries.filter((item) => item.country_code == newSelected);

        this.setState({selected: newSelected}, () => {
            onChange('delivery_country', selectedCountryItem && selectedCountryItem[0] && selectedCountryItem[0].title);
        });
    }

    render() {
        const { countries, ...rest } = this.props;
        const {selected} = this.state;
        return (
            <select
                value={selected}
                onChange={event => this.selectCountry(event.target.value || undefined)}
            >
                <option value=""></option>
                {countries.map((country, i) => (
                    <option key={i} value={country.country_code}>
                    {country.title}
                    </option>
                ))}
            </select>
        );
    }
}

CountryInput.defaultProps = {
    value: ''
};

CountryInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    value: PropTypes.string
};

export default CountryInput;
