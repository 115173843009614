import { validated } from 'react-custom-validation';
import InputField from '../../common/inputField';
import { Link } from 'react-router-dom';
import validationRules from '../../helpers/validationRules';
import React from 'react';
import { withTheme } from 'styled-components';
import { StyledButton } from '../../../theming/themed-components';
import { getPartnerQueryParams } from '@chronomics/chronomics-registration';

const LoginFormFields = props => {
  const { fields, onChange, onValid, onInvalid, $validation, submitError, submitErrorMessage, $fieldEvent, $submit } = props;
  return (
    <form
      className="register-form__container"
      onSubmit={e => {
        e.preventDefault();
        $submit(onValid, onInvalid);
      }}
    >
      <h2 className="register-form__title">Login</h2>
      <div className="register-form__input-fields">
        <div className="register-form__single-field">
          <InputField
            autoFocus
            error={$validation.email.show ? $validation.email.error.reason : ''}
            label="Your Email"
            name="email"
            onBlur={() => $fieldEvent('blur', 'email')}
            onChange={e => onChange('email', e.target.value)}
            showError={$validation.email.show}
            type="email"
            value={fields.email}
          />
        </div>

        <div className="register-form__single-field">
          <InputField
            error={$validation.password.show ? $validation.password.error.reason : ''}
            iconClass="icon-eye"
            label="Password"
            name="password"
            onBlur={() => $fieldEvent('blur', 'password')}
            onChange={e => onChange('password', e.target.value)}
            showError={$validation.password.show}
            type="password"
            value={fields.password}
          />
        </div>

        <div className="register-form__single-field button-container">
          <StyledButton className="btn btn--full-width">
            Login
            <i className="icon-right-open" />
          </StyledButton>
          {submitError && (
            <div className="form__error form__error--general">{submitErrorMessage ? submitErrorMessage : 'Please complete the errors'}</div>
          )}
        </div>

        <div className="register-form__single-field login-form__links">
          <Link to={`/forgot-password${getPartnerQueryParams()}`}>Forgotten Your Password?</Link>
        </div>
      </div>
    </form>
  );
};

function loginValidationConfig(props) {
  const { email, password } = props.fields;

  return {
    fields: ['email', 'password'],
    validations: {
      email: [
        [validationRules.isRequired, email],
        [validationRules.isEmail, email],
      ],
      password: [
        [validationRules.isRequired, password],
        [validationRules.minPasswordLength, password],
      ],
    },
  };
}

export const LoginForm = validated(loginValidationConfig)(withTheme(LoginFormFields));
