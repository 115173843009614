import React from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from 'recharts';

const RenderLabel = (props) => {
    const { x, y, index } = props;

    //Only show for first bar.. which should be epigenetic one
    if(index >= 1) {
        return null;
    }

    return (
        <foreignObject height="45px" width="45px" x={x} y={y - 50}>
            <img alt="Lifetime stress" src="/img/you/actionable-you/stress/lifetime-stress-label.png" />
        </foreignObject>
    );
};

const RenderStressGraph = (data) => {
    if(!(data && data.length)) {
        return null;
    }

    return (
        <div className="graph-section-container stress-compare-graph bar-graph">
            <ResponsiveContainer height="100%" width="100%">
                <BarChart
                    barSize={45}
                    data={data}
                    margin={{top: 0, right: 0, bottom: 0, left: 0}}
                >
                    <CartesianGrid stroke="#BABABA" strokeDasharray="8 8" vertical={false} />
                    <XAxis
                        dataKey="name"
                        stroke="#BABABA"
                        textAnchor="middle"
                        tickLine={false}
                    />
                    <YAxis axisLine={false} domain={[0, 'dataMax +10']} hide={true} interval={0} tickLine={false} />
                    <Bar dataKey="value" fill="#5B7BFF" radius={[5, 5, 0, 0]} label={<RenderLabel />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

const PerceivedVsActual = ({perceivedVsActual}) => {
    if(!perceivedVsActual || Array.isArray(perceivedVsActual) || !perceivedVsActual.data) {
        return null;
    }

    const { title, text, data, analysis_text } = perceivedVsActual;

    return (
        <div className="biological-age__graph-section no-padding">
            {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
            {text ? <p className="indicator-section-description" dangerouslySetInnerHTML={{__html: text}}/> : ''}
            <div className="comparison__container">
                <div className="comparison__graph-container stress-section">
                    {RenderStressGraph(data)}
                    {analysis_text ? <p className="analysis-text analysis-text--stress">{analysis_text}</p> : ''}
                </div>
            </div>
        </div>
    );
};

export default PerceivedVsActual;