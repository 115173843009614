import React from 'react';

import BreakpointContext from './context/breakpoint';

import ScrollToTop from './common/scrollToTop';
import Footer from './common/footer/footer';
import Header from './common/header';

import CookieBar from './common/cookieBar';
import LoggedOutRoutes from './loggedOutRoutes';
import { withTheme } from 'styled-components';
import { StyledBackground } from '../theming/themed-components';
import { withRouter } from 'react-router-dom';
import { ThemeContext } from '../containers/commonTheming/commonTheme';
import { useDisplayControl } from './hooks/useDisplayControl';
import { useBreakpoint } from './hooks/useBreakpoint';

const LoggedOutApp = props => {
  const { shouldHideHeader, shouldHideFooter, className } = useDisplayControl({
    className: 'loggedout-app-page-container',
    isLoggedIn: false,
  });

  const breakpoint = useBreakpoint();
  return (
    <ThemeContext.Consumer>
      {customizedCookieSettings => {
        return (
          <div className={className}>
            <BreakpointContext.Provider value={breakpoint}>
              <ScrollToTop>
                {!shouldHideHeader && <Header theme={props.theme} breakpoint={breakpoint} />}
                <StyledBackground className={`wrapper ${customizedCookieSettings ? 'cookie-option' : ''}`}>
                  <LoggedOutRoutes {...props} />
                </StyledBackground>

                {!shouldHideFooter && <Footer theme={props.theme} breakpoint={breakpoint} />}
                <CookieBar />
              </ScrollToTop>
            </BreakpointContext.Provider>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export { LoggedOutApp as __TEST_LoggedOutApp };

export default withTheme(withRouter(LoggedOutApp));
