import { useMemo } from 'react';
import { defaultColor, green, orange } from '../barColors';

const trueValues = ['POSITIVE', 'Positive', 'Pregnant', 'Detected', 'Reactive'];
const falseValues = ['NEGATIVE', 'Negative', 'Not Pregnant', 'Not Detected', 'Non Reactive'];
const inconclusiveValues = ['Inconclusive', 'INCONCLUSIVE', 'Borderline', 'BORDERLINE'];

const useBoolBar = ({ interpretationDataValue, result }) => {
  if (!interpretationDataValue) return null;
  const resultValue = useMemo(() => {
    const resultRuleValue = Object.values(interpretationDataValue).find(rangeValue => {
      return Array.isArray(rangeValue) && rangeValue[0] && rangeValue[0].toUpperCase() === result.toUpperCase();
    });
    return resultRuleValue && resultRuleValue.length > 0 ? resultRuleValue[0] : 'Unknown';
  }, [interpretationDataValue, result]);

  const getBoolBarConfig = () => {
    if (trueValues.includes(resultValue))
      return {
        result: resultValue,
        barColor: orange,
      };

    if (falseValues.includes(resultValue))
      return {
        result: resultValue,
        barColor: green,
      };

    if (inconclusiveValues.includes(resultValue))
      return {
        result: resultValue,
        barColor: defaultColor,
      };

    return null;
  };

  return { getBoolBarConfig };
};

export default useBoolBar;
