import { get, send, del, put } from '../methods';

import * as API_CONSTANTS from './constants';

const mapSuccess = (response) => {
    return response.data || {};
};

const mapError = (err) => {
    return {
        errorResponse: err.response,
        error: err
    };
};

const mapAspiration = (response) => {
    return response.data.aspiration || '';
};

const mapCoaches = (response) => {
    const { data = [], locked = true, coach_permissions: coachPermissions = {} } = response.data;
    return { coachPermissions, locked, teamMembers: data };
};

export const fetchAspiration = () => {
    return get(API_CONSTANTS.ASPIRATION_URL)
        .then(mapAspiration)
        .catch(mapError);
};

export const saveAspiration = (aspiration) => {
    return send(API_CONSTANTS.ASPIRATION_URL, {aspiration: aspiration})
        .then(mapSuccess)
        .catch(mapError);
};

export const fetchCoaches = () => {
    return get(API_CONSTANTS.COACHES_URL)
        .then(mapCoaches)
        .catch(mapError);
};

export const deleteCoach = (coachId) => {
    return del(API_CONSTANTS.COACH_UPDATE_URL, { data: { coach_id: coachId } })
        .then(mapSuccess)
        .catch(mapError);
};

export const getPermissions = (coachId) => {
    return get(`${API_CONSTANTS.COACH_UPDATE_URL}/${coachId}`)
        .then(mapSuccess)
        .catch(mapError);
};

export const updateCoach = (coachId, permissions) => {
    return put(API_CONSTANTS.COACH_UPDATE_URL, {coach_id: coachId, permissions: [...permissions]})
        .then(mapSuccess)
        .catch(mapError);
};

export const addCoach = (coachId, permissions, accepted_terms) => {
    return send(API_CONSTANTS.COACH_UPDATE_URL,  {coach_id: coachId, permissions: [...permissions], accepted_terms})
        .then(mapSuccess)
        .catch(mapError);
};

export const fetchGoals = () => {
    return get(API_CONSTANTS.GOAL_URL)
        .then(mapSuccess)
        .catch(mapError);
};

export const fetchGoalDetails = (slug) => {
    return get(`${API_CONSTANTS.GOAL_URL}/${slug.slug}`)
    .then(mapSuccess)
    .catch(mapError);
};