import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledBackground = styled.div`
  background: ${props => props.theme.section.background};
  height: 100%;
  width: 100%;
`;

export const StyledContainer = styled.div`
  background: ${props => props.theme.section.cta_colour};
`;

export const StyledAuthBackground = styled.div`
  background: ${props => props.theme.section.login_background || props.theme.section.background};
  height: 100%;
  width: 100%;
`;

export const StyledDropdownLinkWrapper = styled.div`
  .test-user-initials {
    color: ${props => props.theme.header.nav_link_colour};

    @include media('>small') {
      border-bottom: 2px solid ${props => props.theme.header.nav_link_colour} !important;
    }
  }
`;

export const StyledButton = styled.button`
  background: ${props => props.theme.section.cta_colour};
`;

export const StyledWhiteButton = styled.button`
  background: #ffffff !important;
`;

export const StyledDivWrapper = styled.div`
  div {
    background: ${props => props.theme.section.cta_colour};
  }
`;

export const StyledHeaderSectionWrapper = styled.div`
  background: ${props => props.theme.section.background} !important;
  a {
    background: ${props => props.theme.section.cta_colour};
  }
`;

export const StyledInput = styled.input`
  :focus {
    border: none;
    border-bottom: 2px solid ${props => props.theme.section.cta_colour};
  }
`;

export const StyledLink = styled(Link)`
  background: ${props => props.theme.section.cta_colour};
`;

export const StyledSection = styled.section`
  background-color: ${props => props.theme.section.background};
`;

export const StyledHyperlink = styled.a`
  color: ${props => props.theme.section.cta_colour};
`;

export const StyledHyperlinkWrapper = styled.a`
  background: ${props => props.theme.section.cta_colour};
`;

export const StyledSpanWrapper = styled.span`
  background-color: ${props => props.theme.section.cta_colour};
`;

export const StyledSpan = styled.span`
  color: ${props => props.theme.section.cta_colour};
`;

export const StyledHeaderNavLink = styled(NavLink)`
  color: ${props => props.theme.header.nav_link_colour};

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.header.nav_link_colour};
    border-bottom: 2px solid ${props => props.theme.header.nav_link_colour} !important;
  }
`;

export const NAV_LINK_COLOUR = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
});
