import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Indicators = [
    {title: 'Biological age', link: '/dashboard/you/indicator/biological-age/overview'},
    {title: 'Smoke exposure', link: '/dashboard/you/indicator/smoke-exposure/overview'},
    {title: 'Metabolic status', link: '/dashboard/you/indicator/metabolic-status/overview'},
    {title: 'Alcohol consumption', link: '/dashboard/you/indicator/alcohol-consumption/overview'},
    {title: 'Chromosome ageing', link: '/dashboard/you/indicator/chromosome-ageing/overview'},
];

class Card extends Component {
    constructor() {
        super();

        this.state = {
            isActive: false
        };

        this.toggleSection = this.toggleSection.bind(this);
    }
    
    toggleSection() {
        this.setState((prev) => ({isActive: !prev.isActive}));
    }

    render() {
        const { isActive } = this.state;
        const { className, item, link, showIndicators } = this.props;

        if(item.is_current) {
            return (
                <Link to={link} className={`tests__card${className ? ` ${className}` : ''}`}>
                    <div className="tests__card-wrapper-header">
                        <div className="tests__card-title-section">
                            <p className="tests__card-title">{moment(item.date_registered).format('DD MMM YYYY')}</p>
                            {item.type ? <p className="tests__card-description">{item.type}</p> : ''}
                        </div>
                        <p className="tests__card-description view-link">
                            {
                                item.status === 'processed' ?
                                    <span>View <i className={isActive ? 'icon-down-open-big' : 'icon-right-open-big'} /></span>
                                    : item.status.charAt(0).toUpperCase() + item.status.slice(1)
                            }
                        </p>
                    </div>
                </Link>
            );
        }

        return (
            <div className={`tests__card tests__card--grey-background${className ? ` ${className}` : ''}`} onClick={this.toggleSection}>
                <div className="tests__card-wrapper-header">
                    <div className="tests__card-title-section">
                        <p className="tests__card-title">{moment(item.date_registered).format('DD MMM YYYY')}</p>
                        {item.type ? <p className="tests__card-description">{item.type}</p> : ''}
                    </div>
                    <p className="tests__card-description view-link">
                        {
                            item.status === 'processed' ?
                                <span>View <i className={isActive ? 'icon-down-open-big' : 'icon-right-open-big'} /></span>
                                : item.status.charAt(0).toUpperCase() + item.status.slice(1)
                        }
                    </p>
                </div>
                {showIndicators ?
                    <div className={`tests__card-wrapper-description${isActive ? ' active' : ''}`}>
                        {
                            Indicators.map((link, index) => {
                                const indicatorlink = link.link + `?previous_test=${item.test_user_test_id}`;
                                return (
                                    <Link to={indicatorlink} key={index} className="tests__indicator">
                                        {link.title} <i className="icon-right-open-big" />
                                    </Link>
                                );
                            })
                        }
                    </div> : ''
                }
            </div>
        );
    }
}

Card.defaultProps = {
    showIndicators: true
};

Card.propTypes = {
    item: PropTypes.object.isRequired,
    link: PropTypes.string,
    showIndicators: PropTypes.bool,
    className: PropTypes.string,
};

export default Card;