import styled from 'styled-components';

// NOTE: keeping dynamic branding-related styling as styled-components.

export const StyledFooter = styled.footer`
  background: ${props => props.theme.footer.colour};
`;

export const StyledPoweredBy = styled.div`
  color: ${props => props.theme?.footer?.text_colour || '#fff'};
`;

export const StyledCopyright = styled.div`
  color: ${props => props.theme?.footer?.text_colour || '#fff'};
`;

export const StyledFooterContentContainer = styled.div`
  span,
  a {
    color: ${props => props.theme?.footer?.text_colour || '#fff'};
  }
`;
