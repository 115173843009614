import axios from 'axios';
import { getPartnerAccessTokenUrl, getPartnerUsersUrl } from '../apiHelpers/partnerServiceRequests';
import { datadogRum } from '@datadog/browser-rum';

const findUser = async publicToken => {
  const headers = { Authorization: `Bearer ${publicToken}` };
  // Setting validateStatus: null disables the configured Axios interceptor that handles 401s globally by logging a user out
  return axios.get(`${getPartnerUsersUrl({})}`, { headers, validateStatus: null });
};

const generateUserAccessToken = async (publicToken, userId) => {
  const headers = { Authorization: `Bearer ${publicToken}` };
  const body = { chronomicsUserId: userId };
  // Setting validateStatus: null disables the configured Axios interceptor that handles 401s globally by logging a user out
  return axios.post(`${getPartnerAccessTokenUrl({})}`, body, { headers, validateStatus: null });
};

export const authenticatePartnerUser = async publicToken => {
  // Check if the user exists
  const userResponse = await findUser(publicToken);
  if (userResponse.status === 404) return { user: null, accessToken: null };
  if (userResponse.status !== 200) throw new Error(`Could not find partner user. Error: ${userResponse.statusText}`);
  const user = userResponse.data;

  // Check that the user has authorized sharing data with the partner
  if (!user.authorizers || !user.authorizers.read || !user.authorizers.write) {
    // User dashboard should not be loaded for a user not already linked to the partner
    // User should have been linked during order or registration
    datadogRum.addError(`User ${user.chronomicsUserId} is not linked to partner`);
    return { user, accessToken: null };
  }

  // Generate an access token if they are linked to the partner
  const tokenResponse = await generateUserAccessToken(publicToken, user.chronomicsUserId);
  if (tokenResponse.status !== 200) throw new Error(`Could not generate user access token. Error: ${tokenResponse.statusText}`);

  return { user, accessToken: tokenResponse.data.accessToken };
};
