// US state codes where shipping is possible for sales tax reasons
const VALID_US_STATE_CODES = [
    'AK',
    'AZ',
    'AR',
    'CA',
    'CT',
    'LA',
    'ME',
    'MN',
    'MS',
    'MO',
    'NV',
    'NM',
    'NC',
    'NJ', //adding so demo can be ordered
    'OK',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'VA',
    'WV',
    'WI',
];

export { VALID_US_STATE_CODES };

