import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SplitConsumer from '../../../../components/context/splitConsumer';

import CompareLineGraph from './compareLineGraph';

class ExplanationsAndComparison extends Component {
  constructor(props) {
    super(props);

    const tabs =
      props.ranges && props.ranges.former_smokers && props.ranges.former_smokers.tabs && Object.keys(props.ranges.former_smokers.tabs);

    this.state = {
      selectedTab: tabs && tabs[0] ? tabs[0] : null,
    };

    this.selectTab = this.selectTab.bind(this);
  }

  selectTab(value) {
    this.setState({ selectedTab: value });
  }

  renderTabs(tabs_title, tabs) {
    if (!(tabs && Object.keys(tabs).length)) {
      return null;
    }

    const { selectedTab } = this.state;

    return (
      <div className="line-graph__tabs-container">
        {tabs_title ? tabs_title : ''}
        {Object.keys(tabs).map((key, index) => {
          return (
            <button className={`single-tab${selectedTab === key ? ' active' : ''}`} key={index} onClick={() => this.selectTab(key)}>
              {tabs[key]}
            </button>
          );
        })}
      </div>
    );
  }

  renderSection(props) {
    if (!props) {
      return null;
    }

    const { title, text, analysis_text, data, tabs_title, tabs } = props;
    const { selectedTab } = this.state;

    return (
      <div className="compare-line__section">
        {title ? <h5 className="indicator-section-title inside-title">{title}</h5> : ''}
        {text ? <p className="indicator-section-description">{text}</p> : ''}
        {this.renderTabs(tabs_title, tabs)}
        <CompareLineGraph data={tabs ? data[selectedTab] : data} />
        {analysis_text ? (
          <div className="analysis-text analysis-text--wide">
            <div className="comparison__analysis-section">
              <div className="analysis-image">
                <img alt="analysis-placeholder" src="/img/you/actionable-you/biological-age/result-intro-image.png" />
              </div>
              <div className="comparison-analysis-text" dangerouslySetInnerHTML={{ __html: analysis_text }} />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    const { ranges } = this.props;
    if (!ranges || Array.isArray(ranges)) {
      return null;
    }

    const { title, non_smokers, heavy_smokers, former_smokers } = ranges;

    return (
      <section>
        <section className="biological-age__overview-container">
          <h5 className="indicator-section-title">Comparing your smoke signature</h5>
          <p>See how your smoke signature compares with different types of smokers in the Hurdle community.</p>
          <div className="biological-age__graph-section no-padding">
            {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
            {non_smokers ? <div className="biological-age__section-container">{this.renderSection(non_smokers)}</div> : ''}
            {heavy_smokers ? <div className="biological-age__section-container">{this.renderSection(heavy_smokers)}</div> : ''}
            {former_smokers ? <div className="biological-age__section-container">{this.renderSection(former_smokers)}</div> : ''}
          </div>
        </section>
        <SplitConsumer name="show_compare_feature">
          {value =>
            value === 'on' ? (
              <section className="biological-age__overview-container full-width">
                <div className="biological-age__graph-section">
                  <Link className="btn full-width" to="/dashboard/you/compare">
                    see more comparisons
                  </Link>
                </div>
              </section>
            ) : (
              ''
            )
          }
        </SplitConsumer>
      </section>
    );
  }
}

export default ExplanationsAndComparison;
