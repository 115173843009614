import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import config from '../../config';
import { StyledContainer, StyledHyperlink, StyledLink } from '../../theming/themed-components';
import {getPartnerQueryParams} from '@chronomics/chronomics-registration';

const CovidResults = ({ data, match: { url }, testUser = {} }) => {
  if (!data || !data.all_results) {
    //Still loading..
    return null;
  }
  const { web_url } = config.app;

  if (data.all_results.length === 0) {
    return (
      <div className="dashboard-section__section-container">
        <StyledHyperlink href={`/register${getPartnerQueryParams()}`} className="tests__card-wrapper">
          <StyledContainer className="tests__card tests__card--register-card">
            <div className="tests__card-wrapper-header">
              <p className="tests__card-title large">
                Register test
                <i className="icon-right-open-big" />
              </p>
              <p className="tests__card-description extra-top-margin">
                Register <i className="icon-right-open-big" />
              </p>
            </div>
          </StyledContainer>
        </StyledHyperlink>
      </div>
    );
  }

  return (
    <div className="dashboard-section__section-container">
      <h2 className="dashboard-section__sub-title">COVID-19 Tests</h2>
      <div className="dashboard-section__results-section">
        <div>
          {data.all_results.length &&
            data.all_results.slice(0, 2).map((item, index) => {
              const { status, registered_at, result } = item;
              const link = 'covid';

              return (
                <Link className="dashboard-section__single-result more-padding" key={index} to={`${url}/${link}`}>
                  <div className="result-header">
                    <h5 className="result-title">{moment(registered_at).format('Do MMM YYYY')}</h5>
                    {result ? (
                      <p className={`result-text ${result.toLowerCase()}`} data-private>
                        {result}
                      </p>
                    ) : (
                      <p className="result-text">{status}</p>
                    )}
                  </div>
                  <p>
                    <span className="link">View</span> <i className="icon-right-open" />
                  </p>
                </Link>
              );
            })}
        </div>
        <div className="result-button">
          <StyledLink className="btn" to={`${url}/covid`}>
            View All Tests
          </StyledLink>
        </div>
      </div>
    </div>
  );
};

CovidResults.defaultProps = {
  actionable: {},
  match: { url: '/dashboard' },
};

CovidResults.propTypes = {
  actionable: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default CovidResults;
