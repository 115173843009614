import React from 'react';

import PerceivedVsActual from '../components/perceivedVsActual';
import CompareGraph from '../components/compareGraph';

const RenderEveryoneAgeSection = (everyone_age) => {
    if(!everyone_age) {
        return null;
    }

    const { title, text, count, analysis_text, low_label, high_label } = everyone_age;

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <CompareGraph data={{count, analysis_text, low_label, high_label}} showAnalysisImage={false} />
        </div>
    );
};

const RenderJobsSection = (jobs) => {
    if(!jobs) {
        return null;
    }

    const { title, text, data, analysis_text } = jobs;

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <div className="comparison__jobs-container">
                {
                    (data && data.length) ?
                        <div className="comparison__jobs-section comparison__jobs-section--small">
                            {
                                data.map((job, index) => (
                                    <div key={job.position} className="job-section">
                                        {(index > 0 && job.position !== (data[index - 1].position+1)) ? <div className="ellipsis-wrapper"><span /><span /><span /></div> : ''}
                                        <div className={`job-container${index === (data.length - 1) ? ' active' : ''}`}>
                                            <div className="job-position">{job.position}</div>
                                            <p className="job-title">{job.title}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div> : ''
                }
                {analysis_text ? <p className="analysis-text analysis-text--stress text-center">{analysis_text}</p> : ''}
            </div>
        </div>
    );
};

const RenderCausesSection = (causes) => {
    if(!causes) {
        return null;
    }

    const { title, text, data } = causes;

    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            {
                (data && data.length) ?
                    <div className="comparison__jobs-section">
                        {
                            data.map((job) => (
                                <div className="job-container" key={job.position}>
                                    <div className="job-position">{job.position}</div>
                                    <p className="job-title">{job.title}</p>
                                </div>
                            ))
                        }
                    </div> : ''
            }
        </div>
    );
};

const RenderComparisonSection = (customComparisons) => {
    if(!customComparisons) {
        return null;
    }

    const { title, text, causes, everyone_age, jobs } = customComparisons;

    return (
        <div className="biological-age__graph-section no-padding">
            {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            {RenderEveryoneAgeSection(everyone_age)}
            {RenderJobsSection(jobs)}
            {RenderCausesSection(causes)}
        </div>
    );
};

const Comparison = ({customComparisons, perceivedVsActual}) => {
    if(!customComparisons && !perceivedVsActual) {
        return null;
    }

    return (
        <section>
            <section className="biological-age__overview-container">
                {RenderComparisonSection(customComparisons)}
            </section>
            <section className="biological-age__overview-container">
                <PerceivedVsActual perceivedVsActual={perceivedVsActual} />
            </section>
        </section>
    );
};

export default Comparison;