import { cachedPartnerTheming } from '@chronomics/chronomics-registration';

// TODO: delete when unilab has been informed about hiding header/footer control
export const NO_HEADER_LOGO_PARTNER_CODES = ['unlb'];
export const NO_ORDER_LINK_PARTNER_CODES = ['unlb', 'bsur'];

export const isPartnerBranded = theme => {
  return (theme?.hasBranding && !theme?.isAffiliate) || cachedPartnerTheming();
};

// TODO: delete when unilab has been informed about hiding header/footer control
export const shouldDisplayHeaderLogo = () => {
  const partnerCode = cachedPartnerTheming()?.partnerCode;
  return !NO_HEADER_LOGO_PARTNER_CODES.includes(partnerCode);
};

export const shouldDisplayOrderLinks = () => {
  const partnerCode = cachedPartnerTheming()?.partnerCode;
  return !NO_ORDER_LINK_PARTNER_CODES.includes(partnerCode);
};
