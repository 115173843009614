import React from 'react';

import CustomRadarChart from '../components/customRadarChart';

const RenderCategories = (categories) => {
    if(!(categories && categories.length)) {
        return null;
    }

    return (
        <div className="disease-risk__diseases-section">
            {
                categories.map((category) => {
                    const { title, text, icon_url } = category;
                    return (
                        <div className="disease-risk__disease-card" key={title.replace(/ /g, '-')}>
                            <div className="header-section">
                                <h5 className="disease-title">{title}</h5>
                                <div className="disease-image">
                                    <img src={icon_url} alt={title.replace(/ /g, '-')} />
                                </div>
                            </div>
                            <p className="disease-description category-description">{text}</p>
                        </div>
                    );
                })
            }
        </div>
    );
};

const WhatsAffecting = ({whatAffecting, breakpoint}) => {
    if(!whatAffecting || Array.isArray(whatAffecting)) {
        return null;
    }

    const { title, text, negative_impact } = whatAffecting;

    return (
        <section className="biological-age__overview-container">
            <div className="biological-age__disease-risk-container">
                <div className="disease-risk__container">
                    {title ? <h4 className="indicator-section-title">{title}</h4> : ''}
                    {text ? <p className="indicator-section-description">{text}</p> : ''}
                    {RenderCategories(negative_impact.categories)}
                </div>
                <div className="comparison__container">
                    <CustomRadarChart
                        graph={negative_impact}
                        labelKey="category"
                        tabClass="center-align"
                        breakpoint={breakpoint}
                    />
                </div>
            </div>
        </section>
    );
};

export default WhatsAffecting;