import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import DiseaseRisk from '../../containers/you/common/diseaseRisk';

import VsBMI from '../../containers/you/indicator/overviewMetabolicStatus/vsBMI';
import MoreInfo from '../../containers/you/indicator/overviewMetabolicStatus/moreInfo';
import MetabolicStatusAffector from '../../containers/you/indicator/overviewMetabolicStatus/metabolicStatusAffector';
import EpigeneticMetabolicStatus from '../../containers/you/indicator/overviewMetabolicStatus/epigeneticMetabolicStatus';

import HeroSection from '../../containers/you/indicator/components/heroSection';
import HealthyRanges from '../../containers/you/indicator/components/healthyRanges';
import TrackEpigeneticAge from '../../containers/you/indicator/components/trackEpigeneticAge';
import GenesAffected from '../../containers/you/indicator/components/genesAffected';
import ResultImprovements from '../../containers/you/indicator/components/resultImprovements';
import HealthSpecialist from '../../containers/you/indicator/components/healthSpecialist';



const RenderUpdateBar = (latestTest, currentTestDate) => {
    if(!latestTest) {
        return null;
    }

    //Model was changed pre 2020 so only show that warning for people with results before then
    if(moment(currentTestDate).year() >= 2020) {
        return null;
    }

    return (
        <div className="metabolic-bar__container">
            <div className="metabolic-bar__content">
                <div className="information-section">
                    <i className="icon-info-circled-alt" />
                    <p className="test-banner-title">Metabolic status has been updated to a new, more accurate version. Please see the <Link to ="faq">FAQs</Link>  for more info.</p>
                </div>
            </div>
        </div>
    );
};

const MetabolicStatusReport = (props) => {
    const { heroSectionInfo, healthyRanges, previousTest, resultDelta, breakpoint, ageOverTimeGraph, customComparisons,
        genes, recommendations, diseaseRisk, slug, latestTest, currentTestDate, currentTestReleaseDate, resultUnit, team, disableLink } = props;
    return (
        <section>
            {RenderUpdateBar(latestTest, currentTestReleaseDate)}
            {heroSectionInfo && heroSectionInfo.currentTestDateText ? <p className="result-hero__current-test-date">{heroSectionInfo.currentTestDateText}</p> : ''}
            <section className="biological-age__overview-container">
                <HeroSection
                    heroSectionInfo={heroSectionInfo}
                    previousTest={previousTest}
                    resultDelta={resultDelta}
                    slug={slug}
                    disableLink={disableLink}
                />
            </section>
            <section className="biological-age__overview-container">
                <HealthyRanges healthyRanges={healthyRanges} wrapperClass="no-shadow" />
            </section>
            {
                heroSectionInfo && heroSectionInfo.analysis_text ?
                    <section className="biological-age__overview-container">
                        <div className="biological-age__graph-section no-padding">
                            <p className="result-hero__biological-description" dangerouslySetInnerHTML={{__html: heroSectionInfo.analysis_text}} />
                            <div className="disease-risk__epigenetics-section">
                                <img alt="epigenetics" src="/img/you/actionable-you/daniel-herranz.png" />
                                <p className="epigenetic-title">Dr. Daniel E. Martín Herranz Epigenetics expert</p>
                            </div>
                        </div>
                    </section> : ''
            }
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    <VsBMI breakpoint={breakpoint} />
                </div>
            </section>
            <section className="biological-age__overview-container">
                <TrackEpigeneticAge
                    biologicalData={ageOverTimeGraph}
                    breakpoint={breakpoint}
                    resultUnit={resultUnit}
                    wrapperClass="no-padding"
                    disableLink={disableLink}
                />
            </section>
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    <MoreInfo breakpoint={breakpoint} />
                </div>
            </section>
            <section className="biological-age__overview-container">
                <MetabolicStatusAffector disableLink={disableLink}/>
            </section>
            <EpigeneticMetabolicStatus breakpoint={breakpoint} />
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    <GenesAffected genes={genes} />
                </div>
            </section>
            <section className="biological-age__overview-container">
                <ResultImprovements recommendations={recommendations} slug={slug} wrapperClass="no-padding" />
            </section>
            <section className="biological-age__overview-container">
                <DiseaseRisk diseaseRisk={diseaseRisk} />
            </section>
            <section className="biological-age__overview-container full-width">
                <div className="biological-age__graph-section no-padding">
                    <HealthSpecialist team={team} />
                </div>
            </section>
        </section>
    );
};


export default MetabolicStatusReport;