export const ASPIRATION_URL = 'test_user/aspiration';
export const FETCH_ASPIRATION = 'improvements/FETCH_ASPIRATION';
export const FETCH_ASPIRATION_SUCCESS = 'improvements/FETCH_ASPIRATION_SUCCESS';
export const FETCH_ASPIRATION_FAILURE = 'improvements/FETCH_ASPIRATION_FAILURE';
export const EDIT_ASPIRATION = 'improvements/EDIT_ASPIRATION';
export const SAVE_ASPIRATION = 'improvements/SAVE_ASPIRATION';

export const TOGGLE_ASPIRATION_FORM = 'improvements/TOGGLE_ASPIRATION_FORM';

export const COACHES_URL = 'coaches';
export const COACH_UPDATE_URL = 'test_user/coaches';
export const FETCH_COACHES = 'improvements/team/FETCH_COACHES';
export const FETCH_COACHES_SUCCESS = 'improvements/team/FETCH_COACHES_SUCCESS';
export const FETCH_COACHES_FAILURE = 'improvements/team/FETCH_COACHES_FAILURE';

export const DELETE_COACH = 'improvements/team/DELETE_COACH';
export const DELETE_COACH_SUCCESS = 'improvements/team/DELETE_COACH_SUCCESS';

export const UPDATE_SINGLE_COACH = 'improvements/team/UPDATE_SINGLE_COACH';
export const TOGGLE_COACH_KEY = 'improvements/team/TOGGLE_COACH_KEY';

export const ADD_COACH = 'improvements/team/ADD_COACH';
export const FETCH_COACH_PERMISSIONS = 'improvements/team/FETCH_COACH_PERMISSIONS';
export const UPDATE_COACH_PERMISSION = 'improvements/team/UPDATE_COACH_PERMISSION';

export const GOAL_URL = 'test_user/goals';
export const FETCH_GOAL = 'improvements/FETCH_GOALS';
export const FETCH_GOAL_SUCCESS = 'improvements/FETCH_GOAL_SUCCESS';
export const FETCH_GOAL_FAILURE = 'improvements/FETCH_GOAL_FAILURE';

export const FETCH_GOAL_DETAILS = 'improvements/FETCH_DETAILS_GOALS';
export const FETCH_GOAL_DETAILS_SUCCESS = 'improvements/FETCH_GOAL_DETAILS_SUCCESS';
export const FETCH_GOAL_DETAILS_FAILURE = 'improvements/FETCH_GOAL_DETAILS_FAILURE';
export const FETCH_GOAL_DETAILS_LOADING = 'improvements/FETCH_GOAL_DETAILS_LOADING';