import React from 'react';
import { Link } from 'react-router-dom';

import utils from '../../../components/helpers/utils';

const data = [
    {
        title: 'FAQs',
        imageUrl: '/img/indicator/FAQs.png',
        description: 'Find out more about your result.',
        link: '/dashboard/you/indicator/:slug/faq'
    },
    {
        title: 'TALK TO YOUR HEALTH TEAM',
        imageUrl: '/img/indicator/talk-to-health-team.png',
        description: 'Speak to a member of your personal Health Team today to start improving your health.',
        link: '/dashboard/improvements/team'
    },
];

const FAQ = (props) => {
    return (
        <div className="faq__container">
            {
                data.map((item, index) => {
                    const link = item.link.replace(':slug', props.slug);

                    return (
                            <div className="faq__section" key={index}>
                                <Link to={link} className="faq__section-image" onClick={()=> utils.track('Clicked Indicator FAQ Banner', {title: item.title, to: link})}><img src={item.imageUrl} alt={item.description} /></Link>
                                <div className="faq__information">
                                    <Link to={link}><h4 className="title">{item.title}</h4></Link>
                                    <p className="description">
                                        {item.description}
                                    </p>
                                </div>
                            </div>

                    );
                })
            }
        </div>
    );
};

export default FAQ;
