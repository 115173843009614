import React from 'react';
import { ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Text, Tooltip, XAxis, YAxis, Area } from 'recharts';

const ReferenceLabel = ({value, viewBox}) => (
    <Text
        dx={10}
        dy={10}
        fill="#656565"
        fontSize={16}
        position="top"
        textAnchor="start"
        x={viewBox.x}
        y={viewBox.y}
    >
        {value}
    </Text>
);

const RenderLegend = (props) => {
    const { payload, value_future } = props;

    return (
        <div className="legend-container">
            {
                payload.map((item, index) => {
                    const style = {backgroundColor: item.payload.fill};
                    switch (item.dataKey) {
                        case 'range':
                            return (
                                <div className="item-legend" key={index}>
                                    <div className="range-legend" style={style} />
                                    <p>{item.payload.ledgendTitle}</p>
                                </div>
                            );

                        case 'range_sometimes':
                            return (
                                <div className="item-legend" key={index}>
                                    <div className="range-legend" style={style}  />
                                    <p>{item.payload.ledgendTitle}</p>
                                </div>
                            );

                        case 'value_future':
                            return (
                                <div className="item-legend" key={index}>
                                    <div className="value-legend" />
                                    <p>{value_future.future}</p>
                                </div>
                            );


                        case 'value':
                        case 'range_blank':
                        case 'default':
                            return;
                    }
                })
            }
        </div>
    );
};

const RenderDot = (props) => {
    const { cx, cy, value, key, payload, dataKey, breakpoint, slug, resultUnit, disableLink } = props;

    if(value === null || !payload.x_label) {
        return null;
    }

    const isMobile = !(breakpoint === 'large' || breakpoint === 'medium' || breakpoint === 'small');
    const finalValue = slug == 'biological-age' ? value : !disableLink ? value : `${value}${resultUnit}`;

    return (
        <g key={key}>
            <circle cx={cx} cy={cy} r={isMobile ? 20 : 25} stroke="#5B7BFF" strokeWidth={isMobile ? 2 : 3} fill="#FFFFFF" />
            <Text className="dot-value" textAnchor="middle" x={cx} y={cy+(isMobile ? 5 : 7)} fill="black">
                {(dataKey == 'value_future' && !payload.value) ? '?' : finalValue}
            </Text>
        </g>
    );
};

const RenderTooltip = (props) => {
    const { active, payload } = props;

    if(!(payload && payload[0] && payload[0].payload.tooltip && active)) {
        return null;
    }

    const status = payload[0].payload.tooltip_status;
    let bgColor = '#459B64';
    if(status == 'bad') bgColor = '#F0975B';
    else if(status == 'normal') bgColor = '#A3A3A3';


    const contentStyle = {
        backgroundColor: bgColor,
        color: '#ffffff',
        fontSize: '16px',
        borderRadius: '5px'
    };

    return (
        <div className="tooltip" style={contentStyle}>
            {payload[0].payload.tooltip}<br />
            difference
        </div>
    );
};

const TrackEpigeneticAge = (props) => {
    const { wrapperClass, biologicalData, breakpoint, graphClass, slug, resultUnit } = props;

    if(!biologicalData) {
        return null;
    }

    return (
        <div className={`biological-age__graph-section ${wrapperClass ? wrapperClass : ''}`}>
            {biologicalData.title ? <h4 className="indicator-section-title">{biologicalData.title}</h4> : ''}
            {biologicalData.text && <p className="indicator-section-description">{biologicalData.text}</p>}
            <div className={`biological-age__section-container ${graphClass ? graphClass : ''}`}>
                <div className="indicator-section-title inside-title">{biologicalData.sub_title}</div>
                <div className="compare-tab__bar-graph-section">
                    <ResponsiveContainer height="100%" width="100%">
                        <ComposedChart data={biologicalData.data}>
                            <defs>
                                <linearGradient id="area-gradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="25%" stopColor="#5B7BFF" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#5B7BFF" stopOpacity={0.05} />
                                </linearGradient>
                            </defs>
                            <Legend verticalAlign="bottom" content={(props) => RenderLegend({...props, value_future: biologicalData.ledgend})} wrapperStyle={{bottom: '-20px'}} />
                            {biologicalData.x_reference_lines.map((line) => (
                                <ReferenceLine key={line.x} x={line.x} stroke="#A5A5A5" strokeDasharray="15 10"  label={<ReferenceLabel value={line.label} />} />
                            ))}

                            <XAxis axisLine={false} tickline={false} dataKey="x" type="number" domain={['dataMin', 'dataMax']} hide={true} />
                            <YAxis axisLine={false} tickline={false} dataKey="value" type="number"  domain={[biologicalData.domain_x[0], biologicalData.domain_x[1]]} hide={true} />
                            {slug !== 'biological-age' ? <Area type="natural" dataKey="value" fill="url(#area-gradient)" stroke="none" /> : ''}
                            <Line type="natural" dataKey="value_future" stroke="#5B7BFF" strokeWidth="3" id="value_future" strokeDasharray="15 5" dot={false} />
                            <Line type="natural" dataKey="value" stroke="#5B7BFF" strokeWidth="3" dot={(props) => RenderDot({...props, breakpoint, slug, resultUnit})} />
                            <Tooltip content={(props) => RenderTooltip(props)} cursor={false} wrapperStyle={{boxShadow: '0 5px 10px 0 rgba(203,209,223,0.50)'}} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                {typeof(biologicalData.analysis_text) == 'string' ? <div className="healthy-range__legends epigenetic-age" dangerouslySetInnerHTML={{ __html: biologicalData.analysis_text }} /> : ''}
            </div>
        </div>
    );
};

export default TrackEpigeneticAge;