import React from 'react';
import { Link } from 'react-router-dom';

const LinkableLogo = ({ linkUrl, absoluteUrl, children }) => {
  if (linkUrl) {
    return <Link to={linkUrl}>{children}</Link>;
  }

  if (absoluteUrl) {
    return <a href={absoluteUrl}>{children}</a>;
  }

  return children;
};

const Logo = ({ linkUrl, altText, logoUrl, absoluteUrl, cssClass }) => {
  return (
    <LinkableLogo linkUrl={linkUrl} absoluteUrl={absoluteUrl}>
      <img alt={altText ?? ''} className={cssClass} src={logoUrl} />
    </LinkableLogo>
  );
};

export default Logo;
