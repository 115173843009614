import _ from 'lodash';
import config from '../../config';

export const CONFIGURATIONS_PATH = 'v1/configurations/partner-branding';
export const ACCESS_TOKEN_PATH = 'v1/tokens/access';
export const LOCAL_ENVIRONMENT = 'local';

export const getPartnerConfigurationUrl = ({ apiUrl = config?.app?.partnerServiceApi, environment = config?.app?.env }) => {
  if (environment === LOCAL_ENVIRONMENT) {
    return new URL(CONFIGURATIONS_PATH, apiUrl).href;
  }

  const apiNamespace = 'partners';

  const path = _.join(
    _.map([apiNamespace, CONFIGURATIONS_PATH], urlSegment => _.trim(urlSegment, '/')),
    '/',
  );
  return new URL(path, apiUrl).href;
};

export const getPartnerUsersBaseUrl = ({ apiUrl = config?.app?.partnerServiceApi, environment = config?.app?.env }) => {
  if (environment === LOCAL_ENVIRONMENT) {
    const userPath = 'v1/users';
    return new URL(userPath, apiUrl).href;
  }

  const apiNamespace = 'users';

  const path = _.join(
    _.map([apiNamespace], urlSegment => _.trim(urlSegment, '/')),
    '/',
  );
  return new URL(path, apiUrl).href;
};

export const getPartnerUsersUrl = ({ apiUrl = config?.app?.partnerServiceApi, environment = config?.app?.env }) => {
  if (environment === LOCAL_ENVIRONMENT) {
    const userPath = 'v1/users';
    return new URL(userPath, apiUrl).href;
  }

  const apiNamespace = 'users';

  const path = _.join(
    _.map([apiNamespace, 'v1'], urlSegment => _.trim(urlSegment, '/')),
    '/',
  );
  return new URL(path, apiUrl).href;
};

export const getPartnerAccessTokenUrl = ({ apiUrl = config?.app?.partnerServiceApi, environment = config?.app?.env }) => {
  if (environment === LOCAL_ENVIRONMENT) {
    return new URL(ACCESS_TOKEN_PATH, apiUrl).href;
  }

  const apiNamespace = 'users';

  const path = _.join(
    _.map([apiNamespace, ACCESS_TOKEN_PATH], urlSegment => _.trim(urlSegment, '/')),
    '/',
  );
  return new URL(path, apiUrl).href;
};
