import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import withErrorBoundary from '../../components/common/errorBoundary';
import Loader from '../../components/common/loader';
import withError from '../../components/common/withError';

import config from '../../config';
import moment from 'moment';

import ItemCard from './itemCard';
import TimelineIcons from './timelineIcons';
import Results from './results';
import KeyActions from './keyActions';
import NextTest from './nextTest';
import { getYou, getCovidResults, getOrders, fetchBloodResults, getUserDetails } from '../../services/you/actions';
import {
  youSelector,
  youLoadingSelector,
  covidLoadingSelector,
  covidResultSelector,
  ordersSelector,
  bloodLoadingSelector,
  bloodResultSelector,
  userDetailsSelector,
  userDetailsLoadingSelector,
} from '../../services/you/selectors';
import CovidResults from './covidSection';
import OrderStatusSmall from '../../components/order/orderStatusSmall';
import utils from '../../components/helpers/utils';
import GeneralHealth from './generalHealth';
import { shouldDisplayFeature } from '../../services/partner-config/partner-config';
import { FEATURES } from '../../services/partner-config/features';
import { TEST_STATUS } from '../../constants/test-status';

//Shown before you have results
const REGISTER_ITEMS = [
  {
    title: 'Beginning your epigenetic testing journey',
    buttonText: 'Read more',
    buttonUrl: 'https://www.chronomics.com/blog/beginning-your-epigenetic-testing-journey',
    imgUrl: '',
    status: [TEST_STATUS.ORDERED, TEST_STATUS.POSTED, TEST_STATUS.REGISTERED],
    outsideLink: true,
    shouldDisplay: shouldDisplayFeature(FEATURES.EPIGENETIC),
  },
  {
    title: 'Making the most of epigenetics',
    buttonText: 'Read more',
    buttonUrl: 'https://www.chronomics.com/blog/making-the-most-of-epigenetics',
    imgUrl: '',
    status: [TEST_STATUS.RECEIVED, TEST_STATUS.PROCESSING],
    outsideLink: true,
    shouldDisplay: shouldDisplayFeature(FEATURES.EPIGENETIC),
  },
];

//Shown once you have results
const MORE_ITEMS = [
  {
    title: 'Genome',
    description: 'View your health and wellness traits',
    link: '/dashboard/genetic',
    imgUrl: '/img/dashboard/genome.png',
    wg_test: true,
    shouldDisplay: shouldDisplayFeature(FEATURES.WHOLE_GENOME),
  },
  {
    title: 'FAQs',
    description: 'Help and support with your tests',
    link: config.faqUrl,
    imgUrl: '/img/dashboard/faq.svg',
    outsideLink: true,
    shouldDisplay: true,
  },
];

class Dashboard extends Component {
  displayEpi = shouldDisplayFeature(FEATURES.EPIGENETIC);
  displayCovid = shouldDisplayFeature(FEATURES.COVID);

  constructor(props) {
    super(props);

    const testUser = this.props.userDetails?.test_user;
    const { wg_test } = config.app;

    const moreItems = MORE_ITEMS.filter(item => {
      if (item.nonClinicOnly && testUser && testUser.clinic_id) {
        return false;
      }

      if (item.wg_test && !wg_test) {
        return false;
      }

      if (item.wg_test === false && wg_test) {
        return false;
      }

      return item.shouldDisplay;
    });

    this.state = {
      moreItems,
    };
  }

  componentDidMount() {
    this.props.fetchResults();
    this.props.fetchCovidResults();
    this.props.fetchOrders();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userDetails?.test_user?.id !== this.props.userDetails?.test_user?.id) {
      this.props.getBloodResults(this.props.userDetails?.test_user?.id);
    }
  }

  getRegisterItems() {
    const latestTestStatus = this.props.userDetails?.latest_test_status;

    const registerItems = REGISTER_ITEMS.filter(item => {
      if (item.status.includes(latestTestStatus) || latestTestStatus === '') {
        return item.shouldDisplay && (!item.nonClinicOnly || !this.props.testUser?.clinic_id);
      }

      return false;
    });

    return registerItems;
  }

  renderProcessedCards() {
    const { results, match, isLoading, isCovidLoading, isBloodLoading, covidData, bloodData, userDetailsLoading, userDetails } = this.props;
    const processingTests = userDetails?.processing_tests || [];
    const hasResults = userDetails?.has_results;

    if (isLoading || isCovidLoading || isBloodLoading || userDetailsLoading) {
      return <Loader />;
    }

    //Should we show covid or epi results first?
    let showCovidResultsFirst = false;
    if (covidData?.all_results?.length) {
      //Is the covid test from the last month?
      if (!hasResults || moment(covidData.all_results[0].registered_at).isSameOrAfter(moment().subtract(1, 'months'))) {
        showCovidResultsFirst = true;
      }
    }

    const epiResults = this.displayEpi && (
      <Results
        actionable={results && results.actionable}
        hasResults={hasResults}
        processingTests={processingTests}
        match={match}
        testUser={userDetails?.testUser}
      />
    );
    const covidResults = this.displayCovid && <CovidResults match={match} data={covidData} testUser={userDetails?.testUser} />;

    return (
      <div>
        {showCovidResultsFirst ? (
          <React.Fragment>
            <GeneralHealth match={match} tests={bloodData} />
            {covidResults}
            {epiResults}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <GeneralHealth match={match} tests={bloodData} />
            {epiResults}
            {covidResults}
          </React.Fragment>
        )}

        {hasResults && results.actionable !== null && <KeyActions match={match} goals={results && results.goals} />}

        {results && results.next_test_due ? (
          <div className="dashboard-section__section-container">
            <h2 className="dashboard-section__sub-title">Repeat</h2>
            <NextTest nextTestDue={results && results.next_test_due} />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  showWhatYouCanDoSection() {
    const hasResults = this.props.userDetails?.has_results;
    const registerItems = this.getRegisterItems();

    return (
      <React.Fragment>
        <div className="dashboard-section__results">
          {this.renderProcessedCards()}

          {hasResults && this.renderMoreItems()}
        </div>
        {!hasResults && registerItems.map((item, index) => <ItemCard index={index} item={item} key={index} />)}
      </React.Fragment>
    );
  }

  renderMoreItems() {
    return (
      <div className="dashboard-section__section-container">
        <h2 className="dashboard-section__sub-title">More</h2>
        <div className="dashboard-section__more-section">
          {this.state.moreItems.map(item => {
            const { title, description, link, imgUrl, outsideLink } = item;

            if (outsideLink) {
              return (
                <a className="dashboard-section__single-more" href={link} key={title.replace(' ', '-')}>
                  <div className="more-header">
                    <h5 className="more-title">{title}</h5>
                    {description ? <p className="more-description">{description}</p> : ''}
                  </div>
                  <div className="more-image">{imgUrl ? <img src={imgUrl} alt={title.replace(' ', '-')} /> : ''}</div>
                </a>
              );
            }

            return (
              <Link className="dashboard-section__single-more" to={link} key={title.replace(' ', '-')}>
                <div className="more-header">
                  <h5 className="more-title">{title}</h5>
                  {description ? <p className="more-description">{description}</p> : ''}
                </div>
                <div className="more-image">{imgUrl ? <img src={imgUrl} alt={title.replace(' ', '-')} /> : ''}</div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { results, orders, userDetails, userDetailsLoading } = this.props;
    const latestTestStatus = userDetails?.latest_test_status;
    const processingTests = userDetails?.processing_tests || [];
    const featuredOrders = Array.isArray(orders?.data) ? orders.data.filter(order => order.is_featured) : [];
    const sortedOrders = featuredOrders.sort((a, b) => a.created_at - b.created_at);

    sortedOrders.forEach((item, index) => {
      if (item.is_bundle_parent) {
        const findIndex = sortedOrders.findIndex(order => order.parent_id === item.id);
        utils.arrayMove(sortedOrders, index, findIndex);
      }
    });

    return (
      <section className="dashboard-section content-wrapper-animate">
        <div className="dashboard-section__container">
          <h1 className="dashboard-section__title">Hey{userDetails?.test_user && `, ${userDetails.test_user.fname}`}!</h1>
          <div className="dashboard-section__orders">
            {sortedOrders.map(order =>
              order.is_bundle_parent ? (
                <div className="dashboard-section__bundle-parent">
                  {order.items[0].title}
                  <div>
                    <Link to="/dashboard/orders">
                      <span>Details</span>
                      <span className="arrow right" />
                    </Link>
                  </div>
                </div>
              ) : (
                <Link className="dashboard-section__orders-link" key={order.order_number} to="/dashboard/orders">
                  <OrderStatusSmall order={order} />
                </Link>
              ),
            )}
          </div>

          <div className="dashboard-item__result-sections">
            {this.showWhatYouCanDoSection()}
          </div>
        </div>
      </section>
    );
  }
}

Dashboard.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  results: youSelector(state),
  userDetails: userDetailsSelector(state),
  userDetailsLoading: userDetailsLoadingSelector(state),
  isLoading: youLoadingSelector(state, 'you'),
  isCovidLoading: covidLoadingSelector(state),
  isBloodLoading: bloodLoadingSelector(state),
  covidData: covidResultSelector(state),
  bloodData: bloodResultSelector(state),
  orders: ordersSelector(state),
});

const mapDispatchToProps = dispatch => ({
  // fetch results from php for backwards compatibility
  fetchResults: () => dispatch(getYou()),
  updateUserDetails: () => dispatch(getUserDetails()),
  fetchCovidResults: () => dispatch(getCovidResults()),
  getBloodResults: testUserId => dispatch(fetchBloodResults(testUserId)),
  fetchOrders: () => dispatch(getOrders()),
});

const enhance = compose(
  withErrorBoundary,
  connect(mapStateToProps, mapDispatchToProps),
  withError({
    reducer: 'you',
    page: 'you',
  }),
);

export default enhance(Dashboard);
