import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import utils from '../../helpers/utils';

import withErrorBoundary from '../../common/errorBoundary';
import Loader from '../../common/loader';

class BasicYou extends Component {
    constructor() {
        super();
        this.state = {
            items: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        const { section } = this.props;

        if (section) {
            axios.get(`test_user/results/genetic?section=${section}`)
                .then((response = {}) => {
                    const { data } = response;
                    if (data) {
                        this.setState({
                            items: data,
                            isLoading: false
                        });
                    }
                }).catch((err) => {this.setState(() => { throw err; });});
        }
    }

    getBasicYouTraits() {
        const { items: { data } } = this.state;
        const { match: { url } } = this.props;

        if (!data.length) {
            return null;
        }

        return data.map(({ description, name, slug, icon_url }, index) => (
            <div className="card-wrapper" key={`${name}-${index}`}>
                <Link to={`${url}/${slug}`}>
                    <div className="card-wrapper__card" >
                        <div><img className={slug} src={icon_url} /></div>
                        <h6 className="card-wrapper__card__title">{name}</h6>
                        <p className="card-wrapper__card__description">{description}</p>
                    </div>
                </Link>
            </div>)
        );
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const { items: { intro_text, title } } = this.state;

        return (
            <div className="basic-you-page">
                <div className="basic-you-page__image-container">
                    <img src="/img/you/basic-you-icon.png" />
                </div>
                <div className="basic-you-page__title-container">
                    <div>
                        <h5 className="basic-you-page__title-container__header">{title}</h5>
                        <p className="basic-you-page__title-container__description">{intro_text}</p>
                        <p><Link className="btn btn--yellow" to="/dashboard/improvements/team" onClick={()=> utils.track('Talk to health team', {position: 'Hero'})}>speak to someone</Link></p>
                    </div>
                </div>
                <div className="basic-you-page__card-container">
                    {this.getBasicYouTraits()}
                </div>
            </div>
        );
    }
}

BasicYou.defaultProps = {
    section: 'wellness',
    match: {}
};

BasicYou.propTypes = {
    section: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
};

export default withErrorBoundary(BasicYou);
