import React, {Component, useState} from 'react';
import {validated} from 'react-custom-validation';
import validationRules from '../helpers/validationRules';
import InputField from '../common/inputField';
import {useLocation} from 'react-router-dom';
import config from '../../config';
import axios from 'axios';


const TuiDrop = () => {
    return (
        <div className="registration__wrapper">
            <div className="registration__container">
                <TuiContainerForm/>
            </div>
        </div>
    );
};

const TuiContainerForm = () => {

    const { search } = useLocation();
    const [kitId, setKitId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const getStoreId = search && search.split('store=')[1] ? search.split('store=')[1] : '';
    const [storeId, setStoreId] = useState(getStoreId);

    const onChangeStore = (event) => {
        setStoreId(event.currentTarget.value);
    };

    const onChange = (event) => {
        setKitId(event.currentTarget.value);
    };

    const onSubmit = () => {
        setErrorMessage('');
        axios.post(config.app.order_service_api + '/test-drop-off/v1', {kitId: kitId, shopId: storeId}).then(() => {
            setSuccess(true);
        }).catch((error) => {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            }
        });
    };

    return(
        <div className="register-form__complete-container">
            {success &&
            <div className="register-form__complete-icon">
                <i className="icon-tick_complete"/>
            </div>}
            <div className="register-form__single-field">
                <h4 className="complete-title">{success ? 'Thanks' : 'TUI Test drop off'}</h4>
                <p className="complete-description">{success ? 'Your kit has been marked as received. ' +
                    'You will be alerted by email once your results are ready.' :
                    <span>Important: You MUST have <a href="https://app.chronomics.com/register" target="_blank" rel="noreferrer" >registered your kit</a> online before returning it.</span>}
                </p>
            </div>
            {!success && <TuiDropForm kitId={kitId} storeId={storeId} onChangeStore={onChangeStore} onChange={onChange} onSubmit={onSubmit} disableStoreId={search.length}/>}
            {!success && <div className="form__error">{errorMessage}</div>}
            {success && <div className="button-container">
                <a href="/login" target="_top" className="btn btn--full-width">My Account</a>
                        </div>}
        </div>
    );
};

class Form extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {onSubmit, kitId, storeId, $validation, onChangeStore, onChange, $fieldEvent, disableSubmitButton, disableStoreId} = this.props;

        return (
            <form
                className="register-form__input-fields"
                onSubmit={(e) => {
                    e.preventDefault();
                    this.props.$submit(onSubmit);
                }}
            >
                <div className="register-form__single-field">
                    <InputField
                        label="Store ID"
                        name="storeId"
                        type="storeId"
                        value={storeId}
                        onChange={onChangeStore}
                        disabled={disableStoreId}
                    />
                </div>

                <div className="register-form__single-field">
                    <InputField
                        error={$validation.kitId.show ? $validation.kitId.error.reason : ''}
                        label="Kit ID"
                        name="kitId"
                        onBlur={() => $fieldEvent('blur', 'kitId')}
                        onChange={onChange}
                        showError={$validation.kitId.show}
                        type="kitId"
                        value={kitId}
                    />
                </div>
                <div className="register-form__single-field">
                    Not sure of your kit ID? Check <a href="/login" target="_top" className="">My Account</a>
                </div>
                <div className="register-form__single-field">
                    <button className="btn btn--full-width" disabled={disableSubmitButton} type="submit">Submit</button>
                </div>
            </form>
        );
    }
}


const tuiDropValidationConfig = (props) => {
    const {kitId} = props;

    return {
        fields: ['kitId'],
        validations: {
            kitId: [
                [validationRules.isRequired, kitId],
                [validationRules.isKitId, kitId]
            ],
        },
    };
};



const TuiDropForm = validated(tuiDropValidationConfig)(Form);
export default TuiDrop;
