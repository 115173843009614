import PropTypes from 'prop-types';
import { getRanges } from './utils';
import { formatInterpretation } from '../utils';

const LevelDetails = ({ interpretationData, unit }) => {
  const ranges = getRanges(interpretationData);

  return (
    <>
      {ranges.map(([key, range], index) => {
        const [min, max] = range;
        const formattedInterpretation = formatInterpretation(key);

        return (
          <div key={key} className="biomarker-modal__text">
            {index === 0 && <br />}
            {formattedInterpretation}: {min}
            {max && ' - '}
            {max} {unit}
          </div>
        );
      })}
    </>
  );
};

LevelDetails.propTypes = {
  interpretationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  unit: PropTypes.string,
};

export default LevelDetails;
