export const indicatorSelector = state => {
  return state.services.you.indicator;
};

export const youLoadingSelector = (state, page) => {
  return state.services.you[`${page}Loading`];
};

export const youLinksSelector = (state, page) => {
  return state.services.you[`${page}Links`];
};

export const chromosomeAgeSelector = state => {
  return state.services.you.chromosomeAge;
};

export const chromosomeValueSelector = state => {
  return state.services.you.chromosomeSelectedValue || [-1];
};

export const compareSelector = state => {
  return state.services.you.compare;
};

export const comparisonSelector = state => {
  return state.services.you.comparison;
};

export const comparisonParamsSelector = state => {
  return {
    section: state.services.you.comparisonSection,
    id: state.services.you.comparisonId,
  };
};

export const transposonsSelector = state => {
  return state.services.you.transposons;
};

export const transposonsTooltipSelector = state => {
  return state.services.you.transposonsTooltips;
};

export const youSelector = state => {
  return state.services.you.you;
};

export const userDetailsSelector = state => {
  return state.services.you.userDetails;
};

export const userDetailsLoadingSelector = state => {
  return state.services.you.userDetailsLoading;
};

export const covidResultSelector = state => {
  return state.services.you.covidData;
};

export const bloodResultSelector = state => state.services.you.bloodData;

export const bloodLoadingSelector = state => state.services.you.bloodResultsLoading;

export const ordersSelector = state => {
  return state.services.you.orders;
};

export const selectedTestSelector = state => {
  return state.services.you.selectedTest;
};

export const covidLoadingSelector = state => {
  return state.services.you.covidResultLoading;
};

export const singleCovidResultSelector = state => {
  return state.services.you.singleCovidResult;
};

export const singleCovidResultLoading = state => {
  return state.services.you.singleCovidResultLoading;
};
