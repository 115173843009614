import React, { Component } from 'react';
import axios from 'axios';

import utils from '../helpers/utils';
import Questionnaire from '../common/questionnaires/questionnaire';
import withErrorBoundary from '../common/errorBoundary';
import Introduction from './introduction';
import Loader from '../common/loader';
import { StyledHyperlinkWrapper, StyledSection } from '../../theming/themed-components';

class OnboardingQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionnaires: {},
      currentSectionId: props.sectionId ? props.sectionId : null,
      allComplete: false,
      noneStarted: !props.currentPage,
      error: null,
      isLoading: true,
      totalPages: 0,
      answeredPages: 0,
    };

    this.showQuestionnaires = this.showQuestionnaires.bind(this);
  }

  componentDidMount() {
    const { slug } = this.props;
    let { currentSectionId, allComplete, noneStarted } = this.state;

    axios
      .get('questionnaires/' + slug)
      .then((response = {}) => {
        const { data } = response;
        let answeredPages = 0;
        //Figure out which one we should display
        const totalPages = data.reduce((count, item) => count + item.total_pages, 0);
        for (const i in data) {
          if (data[i].current_page || data[i].complete) {
            if (data[i].complete) {
              answeredPages = answeredPages + data[i].total_pages;
            }
            noneStarted = false;
          }
          if (!currentSectionId) {
            if (!data[i].complete && data[i].total_pages > 0) {
              currentSectionId = data[i].id;
              break;
            }
          }
        }

        //all complete...?
        if (!currentSectionId) {
          allComplete = true;
        }

        this.setState({
          questionnaires: data,
          currentSectionId: currentSectionId,
          allComplete: allComplete,
          noneStarted: noneStarted,
          isLoading: false,
          totalPages,
          answeredPages,
        });
      })
      .catch(error => {
        this.setState({
          error: utils.getFirstApiError(error),
          isLoading: false,
        });
      });
  }

  showQuestionnaires() {
    this.setState({ noneStarted: false });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sectionId !== this.state.currentSectionId && prevProps.sectionId) {
      this.setState({ currentSectionId: this.props.sectionId });
    }
  }

  render() {
    const { slug } = this.props;
    const { questionnaires, currentSectionId, error, allComplete, noneStarted, isLoading, totalPages, answeredPages } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const currentQuestionnaire = questionnaires.length && questionnaires.filter(item => item.id == currentSectionId);
    const questionnaireTitle = currentQuestionnaire && currentQuestionnaire[0] ? currentQuestionnaire[0].title : '';
    const questionnaireDescription = currentQuestionnaire && currentQuestionnaire[0] ? currentQuestionnaire[0].description : '';
    const sequenceNumber = currentQuestionnaire && currentQuestionnaire[0] ? currentQuestionnaire[0].sequence_number : 1;

    let contentHtml;
    if (error) {
      contentHtml = <p className="questionnaire-error">{error}</p>;
    } else if (allComplete) {
      contentHtml = (
        <div className="questionnaire__container">
          <div className="questionnaire__complete">
            <h3 className="introduction__title">All done</h3>
            <p className="questionnaire-complete-text">
              Great work. By providing this information combined with your epigenetic test we can derive the most accurate and personalised
              health insights available to date.
            </p>
            {/* DO as link so reloads page */}
            <StyledHyperlinkWrapper href="/dashboard" className="btn continue-btn">
              home
            </StyledHyperlinkWrapper>
          </div>
        </div>
      );
    } else if (noneStarted) {
      contentHtml = <Introduction showQuestionnaires={this.showQuestionnaires} sequenceNumber={sequenceNumber} />;
    } else {
      contentHtml = (
        <Questionnaire slug={slug} id={currentSectionId} title={questionnaireTitle} totalPages={totalPages} answeredPages={answeredPages} />
      );
    }

    return (
      <StyledSection className="questionnaire__container dashboard-section content-wrapper-animate">
        {noneStarted ? '' : <h1 className="questionnaire__title">{questionnaireTitle}</h1>}
        {noneStarted ? '' : <p className="questionnaire__intro-text">{questionnaireDescription}</p>}

        <section className="questionnaire__wrapper">{contentHtml}</section>
      </StyledSection>
    );
  }
}

export default withErrorBoundary(OnboardingQuestions);
