import React, { useState } from 'react';

import axios from 'axios';
import config from '../../config';
import DropdownMenu from './dropdown-menu';
import { StyledDropdownLinkWrapper } from '../../theming/themed-components';
import { shouldDisplayOrderLinks } from '../auth/utils';
import { cachedPartnerTheming, getPartnerQueryParams } from '@chronomics/chronomics-registration';
import Person from '../../../icons/Person';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from '../../services/you/selectors';
import AnimatedMenuIcon from '../../../icons/AnimatedMenuIcon';

const baseDropdownLinks = [
  {
    to: `/register${getPartnerQueryParams()}`,
    title: 'Register Kit',
    onClick: () => {},
    shouldDisplay: true,
  },
  {
    to: '/dashboard/orders',
    title: 'Orders',
    onClick: () => {},
    shouldDisplay: shouldDisplayOrderLinks(),
  },
  {
    to: '/dashboard/tests',
    title: 'Tests',
    onClick: () => {},
    shouldDisplay: true,
  },
  {
    to: '/dashboard/account',
    title: 'My Account',
    onClick: () => {},
    shouldDisplay: true,
  },
];

const mapTestUsersLinks = testUser => {
  // Are we looking at the current user
  const className = ['test-user', testUser.is_current && 'test-user--current'].filter(Boolean).join(' ');

  return {
    to: '#',
    title: (
      <span className={className}>
        {testUser.fname} {testUser.lname}
      </span>
    ),
    onClick: () => {
      if (!testUser.is_current) {
        // This is post so works from mobile app as well
        axios
          .post('user/set_test_user', { test_user_id: testUser.id })
          .then(() => {
            //reload current page so new user is in effect
            axios.get('user/config').then(({ data }) => {
              localStorage.setItem('userMetadata', JSON.stringify(data));
              window.CONFIG = { ...window.CONFIG, ...data };

              window.location.href = '/dashboard';
            });
          })
          .catch(() => {});
      }
    },
  };
};

const getDropdownLinks = userDetails => {
  const testUserLinks = userDetails?.test_users?.map(mapTestUsersLinks) ?? [];
  return [
    ...baseDropdownLinks,
    userDetails?.has_results && {
      to: '/dashboard/improvements',
      title: 'Future',
      onClick: () => {},
    },
    userDetails?.test_users?.length > 1 && {
      to: '#',
      title: 'Change User',
      onClick: () => {},
    },
    ...testUserLinks,
  ].filter(Boolean);
};

const getBottomDropDownLinks = handleLogout => {
  return [
    {
      href: config.faqUrl,
      title: 'Help',
      onClick: () => {},
      shouldDisplay: !cachedPartnerTheming(),
    },
    { href: '#', title: 'Logout', onClick: handleLogout },
  ];
};

/**
 * Returns the capitalized initials of the specified Test User.
 *
 * @param {{ fname: string, lname: string }} testUser the Test User
 * @returns {string} the Test User initials
 */
export const testUserInitials = ({ fname, lname }) => (fname.at(0) + lname.at(0)).toUpperCase();

export const MenuDropdown = props => {
  const { handleLogout, theme, isMobile } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userDetails = useSelector(userDetailsSelector);

  return (
    <li className="item">
      <DropdownMenu
        deviceLinks={getDropdownLinks(userDetails)}
        leftOffset={-120}
        bottomLinks={getBottomDropDownLinks(handleLogout)}
        title={
          <StyledDropdownLinkWrapper className="dropdown-wrapper" theme={theme}>
            {isMobile ? <AnimatedMenuIcon isOpen={isMenuOpen} /> : <Person theme={theme} />}
            {userDetails?.test_user && !isMobile && <span className="test-user-initials">{testUserInitials(userDetails.test_user)}</span>}
            {!isMobile && <i className="icon-down-open-big icon" />}
          </StyledDropdownLinkWrapper>
        }
        onToggle={setIsMenuOpen}
      />
    </li>
  );
};
