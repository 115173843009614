import React, { Component } from 'react';

export default class Select extends Component {
    render() {
        const {id, isRequired, hasOtherOption, choices, value, onChange, style, onBlur} = this.props;

        return (
            <div className="form__select-wrapper custom__select-wrapper" style={style}>
                <select name={id} id={id} value={value} onChange={onChange} onBlur={onBlur ? onBlur : ''}>
                    <option value="">Choose</option>
                    {choices.length && choices.map((choice, key) => <option key={key} value={choice.value}>{choice.value}</option>)}
                </select>
                <i className="icon-down-open"></i>
            </div>
        );
    }
}