/**
 * Formats a Delta Age value for display in the EpigeneticBioBar's tooltip.
 *
 * @param {number} deltaAge
 * @returns string
 */
export const formatDeltaAge = deltaAge => {
  if (deltaAge > 0) {
    return `+${deltaAge} years`;
  }

  return `${deltaAge} years`;
};
