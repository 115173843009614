import React from 'react';

import DiseaseRisk from '../../../../containers/you/common/diseaseRisk';

import AlcoholFootprint from './alcoholFootprint';
import Comparison from './comparison';
import WhatsAffecting from './whatsAffecting';
import CompareAlcoholFootprint from './compareAlcoholFootprint';

import HeroSection from '../components/heroSection';
import HealthyRanges from '../components/healthyRanges';
import TrackEpigeneticAge from '../components/trackEpigeneticAge';
import PerceivedVsActual from '../components/perceivedVsActual';
import GenesAffected from '../components/genesAffected';
import HealthSpecialist from '../components/healthSpecialist';
import PositionsBreakdown from '../components/positionsBreakdown';
import ResultImprovements from '../components/resultImprovements';

const OverviewAlcoholConsumption = (props) => {
    const { slug, heroSectionInfo, previousTest, resultDelta, healthyRanges, ageOverTimeGraph, breakpoint, perceivedVsActual,
        customComparisons, whatAffecting, genes, diseaseRisk, team, resultUnit, positionsGraph, recommendations } = props;

    return (
        <section>
            {heroSectionInfo && heroSectionInfo.currentTestDateText ? <p className="result-hero__current-test-date">{heroSectionInfo.currentTestDateText}</p> : ''}
            <section className="biological-age__overview-container">
                <HeroSection
                    heroSectionInfo={heroSectionInfo}
                    previousTest={previousTest}
                    resultDelta={resultDelta}
                    slug={slug}
                />
            </section>
            <section className="biological-age__overview-container">
                <HealthyRanges healthyRanges={healthyRanges} wrapperClass="no-shadow" />
            </section>
            {
                heroSectionInfo && heroSectionInfo.analaysisText ?
                    <section className="biological-age__overview-container">
                        <div className="biological-age__graph-section no-padding">
                            <p className="result-hero__biological-description" dangerouslySetInnerHTML={{__html: heroSectionInfo.analaysisText}} />
                            <div className="disease-risk__epigenetics-section">
                                <img alt="epigenetics" src="/img/you/actionable-you/daniel-herranz.png" />
                                <p className="epigenetic-title">Dr. Daniel E. Martín Herranz Epigenetics expert</p>
                            </div>
                        </div>
                    </section> : ''
            }
            <section className="biological-age__overview-container">
                <TrackEpigeneticAge
                    biologicalData={ageOverTimeGraph}
                    breakpoint={breakpoint}
                    resultUnit={resultUnit}
                    wrapperClass="no-padding"
                />
            </section>
            <AlcoholFootprint />
            <section className="biological-age__overview-container">
                <PerceivedVsActual perceivedVsActual={perceivedVsActual} />
            </section>
            <CompareAlcoholFootprint customComparisons={customComparisons} />
            <WhatsAffecting whatAffecting={whatAffecting} breakpoint={breakpoint} />
            <Comparison customComparisons={customComparisons} />
            {/*<EpigeneticsAndAlcohol />*/}
            <PositionsBreakdown breakpoint={breakpoint} positionsGraph={positionsGraph} />
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    <GenesAffected genes={genes} />
                </div>
            </section>
            <section className="biological-age__overview-container">
                <ResultImprovements recommendations={recommendations} slug={slug} wrapperClass="no-padding" />
            </section>
            <section className="biological-age__overview-container">
                <DiseaseRisk diseaseRisk={diseaseRisk} />
            </section>
            <section className="biological-age__overview-container full-width">
                <div className="biological-age__graph-section no-padding">
                    <HealthSpecialist team={team} />
                </div>
            </section>
        </section>
    );
};

export default OverviewAlcoholConsumption;