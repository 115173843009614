import React from 'react';
import styled from 'styled-components';

const BarWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const IndividualBar = styled.div`
  height: 0.4em;
  border-radius: 0.3em;
  background-color: ${props => props.barColor};
  width: 100%;
`;

const Bars = ({ boolBarConfig }) => {

  if(!boolBarConfig) return null;

  const { result, barColor } = boolBarConfig;

  return (
    <>
      <BarWrapper data-testid="boolBarWrapper">
        <IndividualBar 
            title={result}
            barColor= {barColor}
        />
      </BarWrapper>
    </>
  );
};

export default Bars;
