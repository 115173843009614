import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';

const affectorData = {
    title: 'What affects your metabolic status',
    text: 'Your genetic background, including genetic variants in your FTO gene, and the way that it interacts with environmental and lifestyle factors determine your metabolic status.',
    graph_data: [
        {value: 60, stroke: '#5B7BFF'},
        {value: 40, stroke: '#1fc7df'}
    ],
    affectors: [
        {
            title: 'Genetics (fixed)',
            value: 40,
            icons: ['/img/you/actionable-you/biological-age/genetic-predisposition.svg'],
            unit: '%',
            backgroundColor: '#1fc7df',
            key: 'genes'
        },
        {
            title: 'Environment and lifestyle (actionable)',
            value: 60,
            icons: ['/img/you/actionable-you/metabolic-status/diet-3.png', '/img/you/actionable-you/metabolic-status/exercise-2.png'],
            unit: '%',
            backgroundColor: '#5B7BFF',
            key: 'exercise'
        }
    ],
    analysis_text: 'The main environmental and lifestyle factors that affect your metabolic status are diet, physical exercise, alcohol consumption and chronic stress. <a href=\'https://www.nature.com/articles/ng.3912\' target=\'_blank\'>Read more</a> about the genetic basis and heritability of BMI.'
};

const MetabolicStatusAffector = () => {
    const { title, text, graph_data, affectors, analysis_text } = affectorData;
    return (
        <div className="biological-age__graph-section no-padding">
            <h4 className="indicator-section-title">{title}</h4>
            <p className="indicator-section-description">{text}</p>
            <div className="biological-age__metabolic-affector">
                <div className="biological-age__affector-section">
                    <div className="biological-age__age-affectors__gauge-chart">
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    data={graph_data}
                                    dataKey="value"
                                    endAngle={450}
                                    innerRadius={70}
                                    outerRadius={80}
                                    paddingAngle={3}
                                    startAngle={90}
                                >
                                    {
                                        graph_data.map((item, index) => (
                                            <Cell key={index} fill={item.stroke} />
                                        ))
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="gauge-label metabolic-gauge">
                            Metabolic influences
                        </div>
                    </div>
                    <div className="biological-age__age-affectors__affectors-section">
                        {
                            affectors.map((affector, index) => {
                                return (
                                    <div className="biological-age__age-affectors__affector" key={index}>
                                        <div className={`affector-title-section ${affector.title.toLowerCase()}`}>
                                            <p className="affector-value">{affector.value}{affector.unit}</p>
                                            <p className="affector-title">{affector.title}</p>
                                        </div>
                                        <div>
                                            {
                                                (affector.icons && affector.icons.length) ? affector.icons.map((icon, iconIndex) => (
                                                    <div className="icon-container" key={iconIndex}>
                                                        <img alt={icon} src={icon} />
                                                    </div>
                                                )) : <p className="empty-affectors">Icon here?</p>
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <p className="analysis-text analysis-text--wide text-center" dangerouslySetInnerHTML={{__html: analysis_text}} />
            </div>
        </div>
    );
};

export default MetabolicStatusAffector;