import {useHistory} from 'react-router-dom';
import React from 'react';
import {ArrowBack} from '@mui/icons-material';
import {withTheme} from 'styled-components';
import Box from '@mui/material/Box';
import {MuiNavigationButton} from './backButton.style';

const BackButton = props => {
  const history = useHistory();
  return (
    <Box className="back-button">
      <MuiNavigationButton disableRipple theme={props.theme} variant="text" startIcon={<ArrowBack fontSize="large"/>}
                           onClick={history.goBack}
      >
        Back
      </MuiNavigationButton>
    </Box>
  );
};

export default withTheme(BackButton);
