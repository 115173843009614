import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { openConsentManager } from '@segment/consent-manager';
import { StyledFooter, StyledPoweredBy, StyledFooterContentContainer, StyledFooterWrapper } from './footer.styles';
import { ThemeContext } from '../../../containers/commonTheming/commonTheme';

const Footer = ({ theme, loggedInUser, breakpoint }) => {
  const { customizedCookieSettings } = useContext(ThemeContext);
  const location = useLocation();
  const { search } = location;

  const isCheckoutPage = location.pathname.includes('/join/');
  const isMobile = breakpoint !== 'large' && breakpoint !== 'medium';
  const shouldDisplayMobileMenu = loggedInUser && isMobile && !isCheckoutPage && !location.search.includes('mobile=1');

  if (search.includes('mobile=1')) {
    return null;
  }

  return (
    <>
      <StyledFooter className="footer" theme={theme} addPadding={shouldDisplayMobileMenu}>
        <div className="footer__container">
          <div className="footer__wrapper">
            <StyledFooterContentContainer className="footer__content-container">
              <a href="https://www.hurdle.bio/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy policy
              </a>
              <a href="https://www.hurdle.bio/cookie-policy/" target="_blank" rel="noopener noreferrer">
                Cookie policy
              </a>
              {customizedCookieSettings && <a onClick={openConsentManager}>Customise cookie settings</a>}
              <a href="https://help.chronomics.com/" target="_blank" rel="noopener noreferrer">
                Help
              </a>
            </StyledFooterContentContainer>
            <div>
              <StyledPoweredBy className="footer__powered-by">Powered by Hurdle</StyledPoweredBy>
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  );
};

export default Footer;
