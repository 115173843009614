import * as actionTypes from './constants';

export const getAspiration = () => {
    return {
        type: actionTypes.FETCH_ASPIRATION
    };
};

export const updateAspiration = (aspiration) => {
    return {
        type: actionTypes.FETCH_ASPIRATION_SUCCESS,
        aspiration,
    };
};

export const aspirationError = (error) => {
    return {
        type: actionTypes.FETCH_ASPIRATION_FAILURE,
        error
    };
};

export const editAspiration = (value) => {
    return {
        type: actionTypes.EDIT_ASPIRATION,
        value,
    };
};

export const saveAspiration = () => {
    return {
        type: actionTypes.SAVE_ASPIRATION,
    };
};

export const toggleAspirationForm = (value) => {
    return {
        type: actionTypes.TOGGLE_ASPIRATION_FORM,
        value
    };
};

export const getCoaches = () => {
    return {
        type: actionTypes.FETCH_COACHES
    };
};

export const updateCoachesData = (coaches) => {
    return {
        type: actionTypes.FETCH_COACHES_SUCCESS,
        coaches
    };
};

export const coachesError = (error) => {
    return {
        type: actionTypes.FETCH_COACHES_FAILURE,
        error
    };
};

export const deleteCoach = (coachId) => {
    return {
        type: actionTypes.DELETE_COACH,
        coachId,
    };
};

export const updateSingleCoach = (coaches) => {
    return {
        type: actionTypes.UPDATE_SINGLE_COACH,
        coaches
    };
};

export const toggleCoachKey = (coachId, key) => {
    return {
        type: actionTypes.TOGGLE_COACH_KEY,
        coachId,
        key,
    };
};

export const addCoach = (coachId) => {
    return {
        type: actionTypes.ADD_COACH,
        coachId,
    };
};

export const getCoachPermissions = (coachId, has_selected) => {
    return {
        type: actionTypes.FETCH_COACH_PERMISSIONS,
        coachId,
        has_selected,
    };
};

export const updatePermissions = (coachId, permission) => {
    return {
        type: actionTypes.UPDATE_COACH_PERMISSION,
        coachId,
        permission,
    };
};

export const getGoals = () => {
    return {
        type: actionTypes.FETCH_GOAL
    };
};

export const goalsError = (error) => {
    return {
        type: actionTypes.FETCH_GOAL_FAILURE,
        error
    };
};

export const updateGoals = (goals) => {
    return {
        type: actionTypes.FETCH_GOAL_SUCCESS,
        goals
    };
};

export const getGoalDetails = (slug) => {
    return {
        type: actionTypes.FETCH_GOAL_DETAILS,
        slug
    };
};

export const goalDetailsError = (error) => {
    return {
        type: actionTypes.FETCH_GOAL_DETAILS_FAILURE,
        error
    };
};

export const updateGoalDetails = (goalDetails) => {
    return {
        type: actionTypes.FETCH_GOAL_DETAILS_SUCCESS,
        goalDetails
    };
};

export const updateGoalDetailsLoading = (goalDetailsLoading) => {
    return{
        type: actionTypes.FETCH_GOAL_DETAILS_LOADING,
        goalDetailsLoading,
    };
};