import React from 'react';
import { Link } from 'react-router-dom';

import SplitConsumer from '../../../../components/context/splitConsumer';

import HorizontalBarGraph from './horizontalBarGraph';

const RenderByCountrySection = (props) => {
    if(!props) {
        return null;
    }

    const { title, text, analysis_text, tabs, data, ledgend } = props;
    return (
        <div className="comparison__container">
            {title ? <h4 className="indicator-section-title inside-title">{title}</h4> : ''}
            {text? <p className="indicator-section-description">{text}</p> : ''}
            <div className="comparison__graph-container stress-section">
                <HorizontalBarGraph
                    data={data}
                    ledgend={ledgend}
                    tabs={tabs}
                />
                {analysis_text ? <p className="analysis-text analysis-text--small">{analysis_text}</p> : ''}
            </div>
        </div>
    );
};

const Comparison = ({customComparisons}) => {
    if (!(customComparisons && customComparisons.everyone_alcohol) || Array.isArray(customComparisons)) {
        return null;
    }

    const { by_country} = customComparisons;

    return (
        <section>
            <section className="biological-age__overview-container">
                <div className="biological-age__graph-section no-padding">
                    {RenderByCountrySection(by_country)}
                </div>
            </section>
            <SplitConsumer name="show_compare_feature">
                {(value) => value == 'on' ?
                    <section className="biological-age__overview-container full-width">
                        <div className="biological-age__graph-section">
                            <Link className="btn full-width" to="/dashboard/you/compare">see more comparisons</Link>
                        </div>
                    </section> : ''
                }
            </SplitConsumer>
        </section>
    );
};

export default Comparison;